import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { NavLink } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Page from "../components/Page";
import axios from 'axios';
import { BASE_URL } from "../config";

const paymentData = [
  {
    id: 1,
    date: "28-09-2021",
    amount: "$100.000",
    paymentMethod: "Seller paid to admin",

  },
  {
    id: 2,
    date: "28-09-2021",
    amount: "$100.000",
    paymentMethod: "Seller paid to admin",
  },
  {
    id: 3,
    date: "28-09-2021",
    amount: "$100.000",
    paymentMethod: "Seller paid to admin",
  },
  {
    id: 4,
    date: "28-09-2021",
    amount: "$100.000",
    paymentMethod: "Seller paid to admin",
  },
  {
    id: 5,
    date: "28-09-2021",
    amount: "$100.000",
    paymentMethod: "Seller paid to admin",
  },
];


const PaymentHistory = () => {
  const [creditDetails, setCreditDetails] = useState([]);
  const [debitDetails, setDebitDetails] = useState([]);
  const token = localStorage.getItem('avonToken');

  useEffect(() => {
    fetchCreditDetails();
    fetchDebitDetails();
  }, [])

  const fetchCreditDetails = async () => {
    await axios.get(BASE_URL + `/getcredithistory`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      // setamount(data.amount)
      setCreditDetails(data.data)
    })
  }

  const fetchDebitDetails = async () => {
    await axios.get(BASE_URL + `/getdebithistory`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      // setamount(data.amount)
      setDebitDetails(data?.data)
    })
  }

  

  return (
    <Page title="Payment History">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Payment History</li>
            </ul>
            <div className="col-md-12">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
            {/* <div>
              <Button className="toggle-button" variant="warning">
                Seller Info
              </Button>
            </div> */}
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Payment History</h4>
                <div className="vendor-order-section">
                  <div className="card-block">
                    <div className="order-head-blk">
                      <h5>Payment History</h5>
                    </div>

                    <div className="card-body-blk">
                      <Tabs
                        defaultActiveKey="profile"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                      >
                        <Tab eventKey="home" title="Credit">
                          <Table responsive="md" hover>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Service Detail</th>
                                <th>Amount</th>
                                <th>Payment Method</th>
                                <th>Reference / Transaction Id</th>
                                <th>Payment Status</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {creditDetails && creditDetails?.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.created_at}</td>
                                  <td>{item.service_details}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.payment_method}</td>
                                  <td>{item.transaction_id}</td>
                                  <td>{item.status}</td>
                                  <td><i class="fa fa-eye" style={{ fontSize: "16px" }}
                                  // onClick={() => handleOpenRechargeModal(wallet)}

                                  ></i></td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Tab>
                        <Tab eventKey="profile" title="Debit">
                          <Table responsive="md" hover>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Service Detail</th>
                                <th>Ad/Package</th>
                                <th>Total Clicks</th>
                                <th>Total Review</th>
                                <th>Refrence ID</th>
                                <th>Amount</th>
                                <th>Action</th>

                              </tr>
                            </thead>
                            <tbody>
                              {debitDetails && debitDetails.map((item) => (
                                <tr key={item.id}>
                                  <td>{item.created_at}</td>
                                  <td>{item.service_details}</td>
                                  <td>{item.amount}</td>
                                  <td>{item.paymentMethod}</td>
                                  <i class="fa fa-eye" style={{ fontSize: "16px" }}
                                  // onClick={() => handleOpenRechargeModal(wallet)}
                                  ></i>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};
export default PaymentHistory;
