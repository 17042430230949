import React from 'react';
import { Form, Button, Modal, InputGroup } from "react-bootstrap";

const ViewTickets = ({ showAdd, setShowAdd, ticketDetailsData }) => {
    const handleClose = () => {
        setShowAdd(false);
    };

    console.log(ticketDetailsData, "ticketDetailsData");
    return (
        <>
            <Modal
                size="lg"
                onHide={handleClose}
                className="ticket-modal-block"
                show={showAdd}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <h5 class="modal-title">Ticket Details</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-3'>
                        <h5>Ticket Details</h5>
                        <div className='col-md-4'>
                            <h6>Ticket ID</h6>
                            <p>{ticketDetailsData.code}</p>
                        </div>
                        <div className='col-md-4'>
                            <h6>Date & Time</h6>
                            <p>{ticketDetailsData.created_at}</p>
                        </div>
                        <div className='col-md-4'>
                            <h6>Status</h6>
                            <p>{ticketDetailsData.status}</p>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="success" style={{ backgroundColor: '#555555' }} onClick={() => setShowAdd(false)}>
                        Cancel
                    </Button>
                    <Button variant="warning" onClick={handleSubmit}>
                        Send Ticket
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    )
}

export default ViewTickets;