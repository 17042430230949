import React from "react";
import { Accordion, AccordionItem } from 'react-light-accordion';

const AboutUs = (props) => {

    console.log(props, "accordian");
    return (
        <>
            <div className="container seller-pd-1">
                <h1 className="feedback-head">{props?.homepagewidget?.widget5}</h1>
                <div className="row">
                    <div className="col-md-6" dangerouslySetInnerHTML={{__html:props.aboutUs && props.aboutUs.data.content}}>
                        
                    </div>
                    <div className="col-md-6 ">
                        <div className="about-accordian">
                            <img src={props.aboutUs.image_base_url+props.aboutUs.data.image} />
                            {/* {props?.faq?.data?.map((item, index) => (
                                <Accordion atomic={true}>
                                    <AccordionItem title={item.question}>
                                        <p>{item.answer}</p>
                                    </AccordionItem>
                                </Accordion>
                            ))} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;