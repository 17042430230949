import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Loader from './loader/Loader.component';
import axios from 'axios';
import { BASE_URL } from '../config';
import parse from 'html-react-parser';
const TruncHtml = require('trunc-html');

const ClientSection = ({ isLoading, heading }) => {
  const [image_base_url, setimage_base_url] = useState('')
  const [data, setdata] = useState('');
  const [datas, setdatas] = useState('');

  useEffect(() => {
    getclientdata();
    const timer = setTimeout(() => {
      getclientdata();
    }, 3000);
    // return () => clearTimeout(timer);
  }, []);
  const getclientdata = () => {
    axios.get(BASE_URL + '/getseller').then(({ data }) => {
      setdata(data.data);
      setdatas(data.datas);
      setimage_base_url(data.image_base_url);
    }).catch(({ response }) => {

    })
  }
  console.log("cliets data", datas);
  // const { clients } = useContext(StateContext);
  return (
    <>
      <div className="container-fluid">
        <div className="feature-product-blk">
          <div className="feature-blkpro-header">
            <h4>{heading}</h4>
          </div>
          <div className="row">{isLoading ? <Loader /> :
            <div className="clients-section">
              
              <div className="our-clients-cols">
                {data && data.map((item, i) => (
                  <div className="" key={i}>
                    <img height={80} width={160} src={item.logo ? image_base_url + item.logo : "./assets/images/image-27.png"} alt="" />
                  </div>
                ))}
              </div>
            </div>
          }
          </div>
        </div>
      </div>
      <div className=''>
        <div className='clients-info-section'>
          {datas && datas.map((item, i) => (
            <div className='blog-grid-block mobile-blogs shadow-sm' key={i}>
              <NavLink className="client-read-more" to={"/seller-detail/" + item.slug}><img height={200} width={300} src={item.logo ? image_base_url + item.logo : "./assets/images/t4.jpg"} alt="" /></NavLink>
              <div>
                <h3>{item.name}</h3>
                <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: TruncHtml(item.comp_intr, 150).html }}></div>
                <NavLink className="client-read-more" to={"/seller-detail/" + item.slug}>Read More</NavLink>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ClientSection;
