import React, { useState, useEffect } from "react";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
export const Faqbody = () => {
  const currentUrl = window.location.href;
  const urlSegments = currentUrl.split('/');
  const lastSegment = urlSegments[urlSegments.length - 1];
  let faq_type;
  if (lastSegment === "seller-faq") {
    faq_type = "For Seller";
  } else {
    faq_type = "For Buyers";
  }

  const [faqs, setfaqs] = useState([]);
  const [active, setactive] = useState(0);
  useEffect(() => {
    Fetchfaqs();
  }, [])
  const Fetchfaqs = async () => {
    await axios.post(BASE_URL + "/faq", { faq_type })
      .then(({ data }) => {
        setfaqs(data.data.faqs);
      });
  };
  return (
    <>
      <div className="faq-sb-content">
        <div className="container d-flex justify-content-between gap-lg-4 gap-3 my-lg-5 my-md-4 my-3">
          <div className="left-headings col-lg-3 bg-white">
            <ul className="ps-0 d-flex flex-column ">
              {faqs && faqs.map((data, i) => (
                <Link to="#" onClick={() => setactive(i)} className={i == active ? "active-heaidng" : ""} key={i}>
                  <li>{data.title}</li>
                </Link>
              ))}


            </ul>
          </div>
          {faqs && faqs.map((data, i) => (
            <>
              {i === active && (
                <div className="right-answers col-lg-8 bg-white">
                  <div className="breadcrum ps-3 pt-3">
                    {/* <p>Faq &gt; Type of Issue &gt; Help with your issue</p> */}
                    <h4>{data.title}</h4>
                  </div>

                  <Accordion className="border-0" defaultActiveKey="0">
                  {data?.faqs.length > 0 && data?.faqs.map((item, k) => (
                      <Accordion.Item className="border-0" eventKey={k}>
                      <Accordion.Header className="border-0">{item.question}</Accordion.Header>
                      <Accordion.Body dangerouslySetInnerHTML={{ __html: item.answer }}>
                    
                      </Accordion.Body>
                    </Accordion.Item>
                   ))}                   
                  </Accordion>


                  {/* <div className="accordion border-0 bg-white accordion-flush" id="accordionFlushExample">
                    {data?.faqs.length > 0 && data?.faqs.map((item, k) => (

                      <div className="accordion-item">
                        <h2 className="accordion-header  ">
                          <button
                            className={k === 0 ? "accordion-button collapsed active-answer" : "accordion-button collapsed"}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#flush-collapse" + k + i}
                            aria-expanded="false"
                            aria-controls={"flush-collapse" + k + i}
                          >
                            {item.question}
                          </button>
                        </h2>
                        <div
                          id={"flush-collapse" + k + i}
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body" dangerouslySetInnerHTML={{ __html: item.answer }}>

                          </div>
                        </div>
                      </div>
                    ))}


                  </div> */}

                </div>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
};
