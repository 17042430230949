import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { DOMAIN_NAME,BASE_URL } from "../../config";

const data = [
    {id:1},
    {id:2},
    {id:2},
    {id:3},
]

export default function SellerSlider(){

    const [data, setData] = useState([]);

    useEffect(() => {
        FetchProfileData();
    },[])
    const FetchProfileData = async () => {
        await axios.get(BASE_URL + '/seller/getslider-home', {
          headers: {
            'Accept': 'application/json',
            'domainname':DOMAIN_NAME
          }
        }).then(({ data }) => {
            setData(data.data)
        })}
 
    return(
        <div className="col-md-12">
        <Carousel
            showThumbs={false}
            autoPlay={true}
            interval={2500}
            showStatus={false}

        >
            {data.map((data) => (
                <div><img src={data.image} style={{maxHeight:'500px'}} /></div>
            ))}
        </Carousel>
    </div>
    )
}