
import React, { useEffect, useState } from "react";
import Helmet from 'react-helmet';
const SeoServicecategoriesSoftware = () => {
    return (
        <><Helmet>
            <title>Apna Shahar</title>
            <meta name="description" content="Apna sahar" />
            <meta property="og:url" content="https://test.apnashaher.com/" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="The title is concise and includes the brand name, ApnaShaher, along with a promise of being the ultimate directory for trusted services." />
            <meta property="og:description" content="ApnaShaher are offerings, emphasizing the ability to find trusted services, read reviews, and make informed decisions. " />
            <meta property="og:image" content="https://media.gettyimages.com/photos/bogota-at-sunset-picture-id107069344?s=612x612" />
        </Helmet>
        </>
    );
};

export default SeoServicecategoriesSoftware;