import React from 'react'
import "../../style/CategoryPage.css"
import { useState } from 'react'
import { Link } from 'react-router-dom'

export const SecondCommon = (props) => {
    const [category,setcategory] = useState(props?.category)
    var slug = props?.slug;
    if(slug == "products-page"){
        slug ="category"
    }
  return (
    <>
    <div className="second-common paramedicals">
        <div className="container categories px-4 pt-4 pb-5 bg-white rounded  ">
            <Link to={"/"+slug+"/"+category.slug}>
            <h2>{category.name}({category.product_count})</h2>
            </Link>
            <div className="row ">
                <div className="left col-lg-3">
                    <Link to={"/"+slug+"/"+category.slug}>
                    <img src={category.banner} alt="" />
                    </Link>
                </div>

                <div className=" col-lg-9 right d-flex flex-wrap gap-2 justify-content-center "> 
                    {/* cards  */}
                    {category?.childrens_count > 0 && category?.childrens.map((subcategory,i)=>(
                        <div className="card py-3 px-4 " key={i}>
                        <div className="heading mb-2 d-flex justify-content-between align-items-center">
                            <h4>{subcategory?.name}({subcategory.product_count})</h4>
                            <Link to={"/"+slug+"/"+subcategory.slug}>View All</Link>
                        </div>
                        <div className="content-img d-flex justify-content-between align-items-center">
                            <div className="content">
                                {subcategory?.childrens_count > 0 && subcategory?.childrens.map((subsubcateory,key)=>(
                                   <Link to={"/"+slug+"/"+subcategory.slug}><p key={key}>{subsubcateory.name} ({subsubcateory.product_count})</p></Link>
                                ))}
                                
                                
                            </div>
                            <div className="image">
                            <Link to={"/"+slug+"/"+subcategory.slug}>
                                <img src={subcategory?.banner} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    ))}
                    

                    
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
