import React from "react"
import { Accordion, Form } from 'react-bootstrap';
import SidebarFilterAttributeContainer from './sidebar-filter-attributes-container.component';
import SidebarFilterCategoryItem from './sidebar-filter-category-item.component';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'


const SidebarFilterContainer = ({ categories, attributes, categoryIds, setCategoryIds, attributeIds, setAttributeIds }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }
  return (
    <div>
      <div  className="sidebar-block hide-in-mobile hide-on-768">
        <div className="side-head-blk">FILTER BY</div>
        <div className="side-accordian">
          <Accordion defaultActiveKey="0" >
            <Accordion.Item eventKey="0">
              <Accordion.Header>Category</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {categories.map((category) => (
                    <SidebarFilterCategoryItem key={category.id} category={category} categoryIds={categoryIds} setCategoryIds={setCategoryIds} />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            {attributes.map((attribute) => (
              <SidebarFilterAttributeContainer key={attribute.id} attribute={attribute} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
            ))}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Price</Accordion.Header>
              <Accordion.Body>
                <Form.Label>&#8377; 0 - &#8377; 1,00,000</Form.Label>
                <Form.Range
                  min="0"
                  max="100"
                  step="1"
                // value={selectedRange}
                // onChange={handleRangeChange}
                />
                {/* {priceData.map((price) => 
               <div className='priceVal' key={price.id}>
                 {price.value}
               </div>
              )}     */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div  className="sidebar-block section-768">
        <div className="side-head-blk">FILTER BY</div>
        <div className="side-accordian">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Category</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {categories.map((category) => (
                    <SidebarFilterCategoryItem key={category.id} category={category} categoryIds={categoryIds} setCategoryIds={setCategoryIds} />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            {attributes.map((attribute) => (
              <SidebarFilterAttributeContainer key={attribute.id} attribute={attribute} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
            ))}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Price</Accordion.Header>
              <Accordion.Body>
                <Form.Label>&#8377; 0 - &#8377; 1,00,000</Form.Label>
                <Form.Range
                  min="0"
                  max="100"
                  step="1"
                // value={selectedRange}
                // onChange={handleRangeChange}
                />
                {/* {priceData.map((price) => 
               <div className='priceVal' key={price.id}>
                 {price.value}
               </div>
              )}     */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>


      <div className="hide-in-desktop hide-on-768">
        {/* <div className="side-head-blk" onClick={toggleDrawer} style={{ marginBottom: "20px" }}>FILTER BY</div> */}
        <div className="d-flex justify-content-between"
          style={{ alignItems: "baseline", background: "#ffb800", padding: "10px", marginBottom: "20px" }}
          onClick={toggleDrawer}>
          <h4 style={{ marginBottom: "0px" }}>FILTER BY</h4>
          <i class="fa fa-bars" aria-hidden="true" style={{ color: "#fff", fontSize: "22px" }}></i>
        </div>
      </div>
      <div id ="filterSidebar"  className='hide-in-desktop '>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction='left'
          className='cart-drawer'
          size="300px"
          
        >

          <div className="side-accordian">

            <Accordion  defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Category</Accordion.Header>
                <Accordion.Body>
                  <Form>
                    {categories.map((category) => (
                      <SidebarFilterCategoryItem key={category.id} category={category} categoryIds={categoryIds} setCategoryIds={setCategoryIds} />
                    ))}
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              {attributes.map((attribute) => (
                <SidebarFilterAttributeContainer key={attribute.id} attribute={attribute} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
              ))}
              <Accordion.Item eventKey="1">
                <Accordion.Header>Price</Accordion.Header>
                <Accordion.Body>
                  <Form.Label>&#8377; 0 - &#8377; 1,00,000</Form.Label>
                  <Form.Range
                    min="0"
                    max="100"
                    step="1"
                  // value={selectedRange}
                  // onChange={handleRangeChange}
                  />
                  {/* {priceData.map((price) => 
               <div className='priceVal' key={price.id}>
                 {price.value}
               </div>
              )}     */}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default SidebarFilterContainer;
