import { NavLink } from 'react-router-dom';
import parse from 'html-react-parser';

const BlogItem = ({ blog }) => {
  return (

    <div className="col-md-6 col-lg-3 col-sm-6 col-xs-12" key={blog.id}>
      <div className="blog-grid-block mobile-blogs">
        <NavLink to={`/blog/${blog.slug}`}><img src={blog.image} alt="blog" /></NavLink>
        <div className=''>
          <h3>{blog.title && blog.title.length > 30 ? blog.title.substring(0, 30) + '...' : blog.title}</h3>
          <div className='blog-grid-block-content'>
            {parse(blog.content) && parse(blog.content).length > 70 ? parse(blog.content).substring(0, 70) + '...' : parse(blog.content)}
          </div>
          <NavLink to={`/blog/${blog.slug}`}>Read More</NavLink>
        </div>
      </div>
    </div>
  );
};

export default BlogItem;
