import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, DOMAIN_NAME } from "../../../config";
import { useParams } from "react-router-dom";
import GoToTop from "../../../GoToTop";
const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [footer, setFooter] = useState([]);
  const [footerMenu, setFooterMenu] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    fetchFooter();
    fetchFooterMenu();
  }, []);

  const {
    handleProduct,
    handleGeneralPage,
    handleBlog,
    handleContact,
    handleFaq,
    handleMenu,
    handleAbout,
  } = props;
  console.log(props, "footer function");
  const EmailSubscribe = () => {
    const obj = {
      email: email,
    };
    axios
      .post(BASE_URL + `/seller/subscribe`, obj,{
        headers: {
          Accept: "application/json",
          slug: slug,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status == "200") {
          console.log("set email called");
          setEmail("");
          toast.success("Subscribed Successfully!");
        }
      })
      .catch((error) => {
        console.log(error, "status");
      });
  };

  const fetchFooter = async () => {
    await axios
      .get(BASE_URL + "/seller/getfooter", {
        headers: {
          Accept: "application/json",
          slug: slug,
        },
      })
      .then(({ data }) => {
        setFooter(data);
      })
      .catch(({ response }) => {});
  };

  const fetchFooterMenu = async () => {
    await axios
      .get(BASE_URL + "/seller/footermenu", {
        headers: {
          Accept: "application/json",
          slug: slug,
        },
      })
      .then(({ data }) => {
        setFooterMenu(data);
      })
      .catch(({ response }) => {});
  };

  console.log(footer, "seller footer");
  return (
    <>
      <div className="seller-footer-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <h1>LOGO</h1>
              <p className="seller-mt-1">WORKING HOURS</p>
              {footer.openingtime &&
                footer.openingtime.map((item, i) => (
                  <div className="footer-working-days mt-2">
                   {item.first_day == item.last_day ?(
                      <> {item.first_day}:</>
                   ):(
                    <>{item.first_day} & {item.last_day}:</>
                   )}   {item.status == "open" ? (
                <>{item.in_time} - {item.out_time}</>
                    ):(
                      <>
                      {item.status}
                      </>
                    )} 
                  </div>
                ))}
              {/* <p style={{ color: "#C67A09" }}>Monday - Saturday <br />Sunday and holidays</p> */}
              <p>
                FOLLOW US ON:
                <span>
                  <a href={footer?.shop?.facebook}>
                    <img src="/assets/images/facebook-icon.png" />
                  </a>
                  <a href={footer?.shop?.twitter}>
                    <img src="/assets/images/twitter-icon.png" />
                  </a>
                  <a href={footer?.shop?.linkedin}>
                    <img src="/assets/images/linkedin-icon.png" />
                  </a>
                  <a href={footer?.shop?.instagram}>
                    <img src="/assets/images/instagram-icon.png" />
                  </a>
                  <a href={footer?.shop?.youtube}>
                    <img src="/assets/images/youtube-icon.png" />
                  </a>
                  <a href={footer?.shop?.pinterest}>
                    <img src="/assets/images/pinterest-icon.png" />
                  </a>
                </span>
              </p>
              <p>
                CHAT WITH US ON:{" "}
                <span>
                <a href={"https://api.whatsapp.com/send?phone="+footer?.businessdata?.whatsapp_number} target="_blank">
                  <img src="/assets/images/whatsapp-icons.png" />
                  </a>
                </span>{" "}
              </p>
              <p>
                CALL US ON:{" "}
                <span>
                    {
                        footer?.businessdata?.business_mobilenumber &&(
                        <a
                    href={
                      `tel:` + footer?.businessdata?.business_mobilenumber[0]
                    }
                  >
                    <img src="/assets/images/call-icon.png" />
                  </a>
                        )
                    }
                  
                </span>
              </p>
              <div>
                <h6 className="copy-right-text">
                  Copyright © 2023 a theme by webmingo.com
                </h6>
              </div>
            </div>
            <div className="col-md-8">
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="Enter Your Email"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon2" onClick={EmailSubscribe} style={{cursor: "pointer"}}>
                    Subscribe Now
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="row seller-mt-1">
                <div className="col-md-6">
                  <div className="seller-footer-links">
                    <div>
                      <p onClick={handleMenu}>Home</p>
                      <p onClick={handleAbout}>About Us</p>
                      <p onClick={handleProduct}>PRODUCT & SERVICES</p>
                      <p onClick={handleBlog}>BLOG</p>
                      <p onClick={handleFaq}>FAQ</p>
                      <p onClick={handleContact}>Contact Us</p>
                    </div>
                    <div>
                      {footerMenu?.data?.map((item, index) => (
                        <p onClick={() => handleGeneralPage(index, item.slug)}>
                          {item.menu_name}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="seller-location">
                    <div>
                      <p>CALL US :</p>
                      <p>EMAIL :</p>
                      <p>LOCATION : </p>
                    </div>
                    <div>
                      <p>{footer?.businessdata?.business_mobilenumber[0]}</p>
                      <p>{footer?.businessdata?.business_email[0]}</p>
                      <p>{footer?.businessdata?.full_address}</p>
                    </div>
                  </div>
                  {/* <div className='seller-footer-location'>
                                        <p> <span> </span></p>
                                        <p> <span> </span></p>
                                        <p><span> </span></p>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <GoToTop />
    </>
  );
};

export default Footer;
