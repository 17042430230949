import React, { useRef, useState } from 'react'
import "../../style/SellerDetails.css"
import "../../style/StartupDetail.css"
import { Link } from 'react-router-dom'
import { IMAGE_BASE_URL } from '../../config'

export const StartupdComp3 = (props) => {
    const [rating, setRating] = useState(0);
    const [rateus, setRateus] = useState(false)

    const handleClick = (index) => {
        setRating(index + 1);
    };

    function removeActiveClass() {
        const tabButtons = document.getElementsByClassName("toggle-link");
        const spanElm = document.querySelectorAll(".toggle-link span")

        for (const lis of tabButtons) {
            lis.classList.remove("active-build-ms-stab");
        }

        for (const spans of spanElm) {
            spans.classList.remove("active-build-ms-stab")
        }
    }

    const targetRef = useRef(null)
    const handleLinkTab = (event, _id) => {
        removeActiveClass();
        event.stopPropagation()

        let targetElem = document.getElementById(_id)

        if (_id === "popup_option") {
            let showElem = document.querySelector("#popup_option");
            showElem.style.display = "block";
        }

        const offset = -250;
        const elementPosition = targetElem.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        // const offset = "-150px"
        // targetElem.scrollIntoView({ behavior: 'smooth', top: offset });

        const clickedButton = event.currentTarget;
        clickedButton.classList.add("active-build-ms-stab");

    };


    const stars = [...Array(5)].map((_, index) => (
        <span
            key={index}
            className={index < rating ? "rate-star-golden" : "rate-star"}
            onClick={() => handleClick(index)}
        >
            <i class="bi bi-star-fill"></i>
        </span>
    ));


    const showEditForm = () => {
        document.querySelector(".suggest-edit").style.display = "block"
        document.querySelector("#popup_option").style.display = "none"

    }

    const closePopup = () => {
        document.querySelector(".suggest-edit").style.display = "none"
        document.querySelector("#popup_option").style.display = "none"
    }

    return (
        <>
            <div className="seller-details-comp3 startup-detail-comp3">
                <div className="container d-flex justify-content-between gap-3">
                    {/* left  */}
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 left position-relative">
                        <div className="fix-content">
                            <div className="rounded-div"></div>
                            <div className="left-content">
                                <ul className="ps-0">
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between active-build-ms-stab"
                                        onClick={(event) => handleLinkTab(event, "company_info")}
                                    >
                                        <span>Company Information</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "product_services")}
                                    >
                                        <span>Product & Services</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "highlights_features")}
                                    >
                                        <span>Highlights & Features</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "pitch_deck")}
                                    >
                                        <span>Pitch Deck</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "rate_us")}
                                    >
                                        <span>Rating & Review</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "subscribe")}
                                    >
                                        <span>Subscribe</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    {/* <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "request")}
                                    >
                                        <span>Request Quote</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li> */}
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "contact_info")}
                                    >
                                        <span>Contact Us</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "popup_option")}
                                    >
                                        <span>Suggest an Edit</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>

                    {/* right  */}
                    <div className="col-lg-9 right">

                        {/* 1st right component   */}
                        <div ref={targetRef} id="company_info" className="company-info  px-5 py-4 gap-2">
                            <div className="d-flex flex-lg-row gap-2 flex-column-reverse align-items-center justify-content-center">

                                <div className="left-div col-lg-12">
                                    <h2>Company Information</h2>
                                    <h3>WEB MINGO IT SOLUTIONS PVT LTD</h3>
                                    <p dangerouslySetInnerHTML={{__html:props?.data?.about_startup}}></p>
                                </div>

                                
                            </div>

                            <div className="cards d-flex flex-wrap gap-2 justify-content-between my-3 w-100" style={{ overflowX: "auto" }}>

                                <div className="card text-center px-2 py-2">
                                    <p>ESTABLISHED YEAR</p>
                                    <span>{props?.data?.establish_year}</span>
                                </div>
                                <div className="card text-center px-2 py-2">
                                    <p>MARKET VALUATION</p>
                                    <span style={{ color: "#00DC23" }}> {props?.data?.market_valuation}</span>
                                </div>
                                <div className="card text-center px-2 py-2">
                                    <p>REVENUE</p>
                                    <span style={{ color: "#A2D60D" }}>{props?.data?.revenue} <sup>%</sup></span>
                                </div>
                                <div className="card text-center px-2 py-2">
                                    <p>NET PROFIT</p>
                                    <span>{props?.data?.net_profit} <sup>%</sup></span>
                                </div>

                            </div>

                        </div>

                        {/* 2nd right componnet  */}
                        <div ref={targetRef} id="product_services" className="product-and-services   px-5 py-4 gap-2">
                            <div className='d-flex flex-lg-row flex-column-reverse gap-2 align-items-center justify-content-center'>


                                <div className="left-div col-lg-12">
                                    <h2>Product & Services</h2>
                                    <div dangerouslySetInnerHTML={{__html:props?.data?.about_product_service}}></div>
                                </div>

                               
                            </div>


                        </div>

                        {/*3rd right compnent   */}
                        <div ref={targetRef} id="highlights_features" className="product-and-services    px-5 py-4 gap-2">
                            <div className='d-flex flex-lg-row flex-column-reverse gap-2 align-items-center justify-content-center'>


                                <div className="left-div col-lg-12">
                                    <h2>Highlights & Features</h2>
                                    <div dangerouslySetInnerHTML={{__html:props?.data?.feature_highlight}}></div>
                                </div>

                                
                            </div>


                        </div>


                        {/* 3.1 right Component */}
                        <div ref={targetRef} id="pitch_deck" className="product-and-services pitch-desk  text-center px-5 py-4 gap-2">
                            <h2>Pitch Desk</h2>

                            {/* <p className='mt-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> */}

                            <Link to={IMAGE_BASE_URL+props?.data?.pitchdeck} className="my-3 py-3 btn btn-primary" target='_blank'>Download Pitch Desk</Link>

                        </div>

                        {/* 3.2 right Component */}
                        <div ref={targetRef} id="rate_us" className="review-and-rating rate-us  px-5 py-4 gap-2">
                            <h2>Reviews & Ratings - CPM Systems Private Limited</h2>

                            {rateus === false ?
                                <div className='top-content text-center'>
                                    <div className="my-5" style={{ maxWidth: "200px", margin: "auto" }}>
                                        <img src="/assets/images/stars2.png" alt="" />
                                    </div>
                                    <button onClick={() => setRateus(true)}>Write a review</button>
                                </div>
                                :
                                <div id="" className="user-reviews  my-3">
                                    <h6>Rate Us</h6>
                                    <form action="">
                                        <div className="stars-div my-3">
                                            {stars}
                                        </div>
                                        <div className='my-3'>
                                            <h6>Tell us about your experience</h6>
                                            <textarea className='w-100 rounded px-2 py-2 fs-6' style={{ border: "1px solid #555555" }} name="" id="" cols="30" rows="6" placeholder='Please share your experience with the business. Things you can talk about: service, product and price'></textarea>
                                        </div>
                                        <div className='my-3 '>
                                            <h6>Upload Img</h6>
                                            <div className="position-relative  px-4 py-3 rounded" style={{ border: "1px solid #555555" }} >
                                                <label className="position-absolute fs-6"><i className='fa fa-camera fs-5 pe-3'></i> Upload Img</label>
                                                <input type="file" className='opacity-0' />
                                            </div>
                                        </div>

                                        <button className='blue-bg-button' >Submit Review</button>
                                    </form>
                                </div>
                            }

                        </div>

                        {/* 4th right Component */}
                        <div ref={targetRef} id="subscribe" className="request px-5 py-4 gap-2">
                            <h2>Subscribe</h2>
                            <p className='bold-para mt-4'>Fill the Form & Connect with "Web Mingo IT Solutions Private Limited</p>
                            <form action="">
                                <div className="d-flex my-2 flex-wrap gap-2 align-items-center justify-content-between">
                                    <input type="text" placeholder='Full Name' />
                                    <input type="email" placeholder='Email ID' />
                                </div>
                                <div className="d-flex my-2 flex-wrap gap-2 align-items-center justify-content-between">
                                    <input type="number" placeholder='Mobile Number' />
                                    <select className="form-select rounded-0" aria-label="Default select example">
                                        <option selected>Country</option>
                                        <option value="1">India</option>
                                        <option value="2">USA</option>
                                        <option value="3">UK</option>
                                    </select>
                                </div>
                                <div className="three-inp d-flex my-2 flex-wrap gap-2  align-items-center justify-content-between">
                                    <select className="form-select rounded-0" aria-label="Default select example">
                                        <option selected>State</option>
                                        <option value="1">UP</option>
                                        <option value="2">MP</option>
                                        <option value="3">UK</option>
                                    </select>
                                    <div className='d-flex  flex-wrap gap-1 align-items-center justify-content-between' style={{ width: "48%" }}>

                                        <input type="text" placeholder='City' />
                                        <input type="number" placeholder='Pin Code' />
                                    </div>

                                </div>

                                {/* <div className='my-2'>
                                    <textarea style={{ border: "1px solid #BCBCBC" }} className='w-100 px-2 py-2 fs-6' name="" id="" cols="30" rows="5" placeholder='Type Detail'></textarea>
                                </div> */}

                                {/* <div className="d-flex my-2 pb-2 align-items-center gap-3">
                                    <input type="checkbox" style={{ width: "fit-content" }} />
                                    <p className='mb-0 term-cond'>I Accept & Acknowledge that my contact information will be shared with the registered sellers at APNASHAHER.COM</p>
                                </div> */}

                                <button className='submit-btn mt-lg-4 mt-3'>Submit</button>
                            </form>

                        </div>

                        {/* 5th right Component  */}
                        <div id="contact_info" className="contact-info d-flex align-items-center justify-content-center px-5 py-4 gap-2">
                            <div className="left-div col-lg-8  col-12">
                                <h2>Contact Us</h2>
                                <h3>WEB MINGO IT SOLUTIONS PVT LTD</h3>
                                <div className="cont-details mt-4  d-flex flex-wrap justify-content-between">
                                    <div className="address my-lg-3 my-2">
                                        <h5>Address</h5>
                                        <p>{props?.data?.address}</p>
                                    </div>
                                    <div className="mobile my-lg-3 my-2">
                                        <h5>Mobile</h5>

                                        <p>{props?.data?.mobile_number}</p>
                                       
                                    </div>
                                    <div className="emails my-lg-3 my-2">
                                        <h5>Email</h5>
                                        <p>{props?.data?.email}</p>
                                    </div>
                                    <div className="social-link my-lg-3 my-2">
                                        <h5>Social</h5>
                                        <ul className='d-flex gap-2 ps-0'>
                                            <li>
                                                <Link to={props?.data?.facebook_url}><i class="fa fa-facebook"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={props?.data?.twitter_url}><i class="fa fa-instagram"></i></Link>
                                            </li>
                                            {/* <li>
                                                <Link to={props?.data?.email}><i class="fa fa-whatsapp"></i></Link>
                                            </li> */}
                                            <li>
                                                <Link to={props?.data?.linkedin_url}><i class="fa fa-linkedin"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="right-div col-lg-4 col-12">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50589.9631670406!2d77.33510599757896!3d28.619082772305685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a035689a39%3A0x3d9564cd104c4b57!2sWeb%20Mingo%20IT%20Solutions%20Pvt.%20Ltd.%20-%20Website%20Designing%20%26%20Digital%20Marketing%20Company!5e0!3m2!1sen!2sin!4v1701432480563!5m2!1sen!2sin" width="fit-content" height="fit-content" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div> */}

                        </div>



                    </div>

                </div>


                {/* Popop Option Container */}
                <div id="popup_option">
                    <div className="popup-content px-4 py-4 bg-white">
                        <div className='top-content d-flex justify-content-between pe-2'>
                            <div>
                                <h2>Help us improve this listing</h2>
                                <p>Select from the option below</p>
                            </div>

                            <span onClick={closePopup}>✕</span>
                        </div>
                        <div className='tab-links d-flex gap-3 flex-column justify-content-start'>
                            <button className='border-0 bg-transparent ' onClick={showEditForm} >Edit /Modify Business</button>
                            <Link to="/report-abuse">Report Abuse</Link>
                        </div>
                    </div>
                </div>

                {/* 6th Popup Component  */}
                <div id="suggest_edit" className="suggest-edit">
                    <div className="bg-blur"></div>
                    <div className="suggest-form px-4 py-4 bg-white">
                        <div className="content d-flex  justify-content-between">
                            <div>
                                <h2>Suggest an Edit</h2>
                                <p>Web Mingo Pvt Ltd</p>
                            </div>

                            <span onClick={closePopup}>✕</span>
                        </div>



                        <form action="">
                            <div className="my-2 d-flex gap-2 flex-wrap justify-content-between align-items-center">
                                <input type="text" placeholder='Company Name' />
                                <select className="form-select " aria-label="Default select example">
                                    <option selected>Business Cagetory</option>
                                    <option value="1">IT</option>
                                    <option value="2">Finanace</option>
                                    <option value="3">HealthCse</option>
                                </select>
                            </div>
                            <div className="my-2 full-address">
                                <input type="text" placeholder='Full Address' />
                            </div>
                            <div className="my-2 d-flex flex-wrap gap-2 justify-content-between align-items-center">
                                <select className="form-select " aria-label="Default select example">
                                    <option selected>Country</option>
                                    <option value="1">India</option>
                                    <option value="2">Nepal</option>
                                    <option value="3">USA</option>
                                </select>
                                <select className="form-select " aria-label="Default select example">
                                    <option selected>State</option>
                                    <option value="1">UP</option>
                                    <option value="2">MP</option>
                                    <option value="3">UK</option>
                                </select>
                            </div>
                            <div className="my-2 gap-2 d-flex flex-wrap justify-content-between align-items-center">
                                <input type="text" placeholder='City' />
                                <input type="number" placeholder='Pin Code' />
                            </div>

                            <div className="my-2 gap-2 d-flex flex-wrap justify-content-between align-items-center">
                                <input type="number" placeholder='Mobile Number' />
                                <input type="text" placeholder='Website Url' />
                            </div>

                            <div className="my-3 d-flex flex-column gap-2">
                                <h6>Other</h6>
                                <textarea name="" id="" cols="30" rows="4" placeholder='Details here...'></textarea>
                            </div>

                            <div className="my-2 upload-logo position-relative">
                                <label className='position-absolute' >Upload Logo</label>
                                <input type="file" />
                            </div>

                            <div className="my-3 form-btns d-flex flex-wrap justify-content-between">
                                <button className='cancle-btn' onClick={closePopup}>Cancel</button>
                                <button className='submit-btn'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
