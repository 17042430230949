import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";

const ShopSetting = () => {
  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);

  return (
    <Page title="Shop Setting">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Shop Settings</li>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>
                  Shop Settings
                  <Button
                    style={{ float: "right", backgroundColor: "#176496" }}
                    variant="success"
                  >
                    Visit Shop
                  </Button>
                </h4>

                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Basic Info</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Label>Shop Name *</Form.Label>
                        </div>
                        <div className="col-md-9">
                          <Form.Group className="form-group">
                            <Form.Control type="text" placeholder="My Shop" />
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Label>Shop Logo</Form.Label>
                        </div>
                        <div className="col-md-9">
                          <Form.Group className="form-group">
                            <InputGroup>
                              <Button
                                style={{
                                  backgroundColor: "#17A2B8",
                                  width: "15%",
                                }}
                                variant="success"
                              >
                                Browse
                              </Button>
                              <Form.Control
                                type="text"
                                placeholder="Choose File"
                              />
                            </InputGroup>
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Label>Shop Address *</Form.Label>
                        </div>
                        <div className="col-md-9">
                          <Form.Group className="form-group">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter shop address"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Label>Pickup Points</Form.Label>
                        </div>
                        <div className="col-md-9">
                          <Form.Group className="form-group">
                            <Form.Select>
                              <option>Nothing Selected</option>
                              <option>UPI</option>
                              <option>Online</option>
                            </Form.Select>
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form.Label>Meta Title *</Form.Label>
                        </div>
                        <div className="col-md-9">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="Meta title"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-3">
                          <Form.Label>Meta Description</Form.Label>
                        </div>
                        <div className="col-md-9">
                          <Form.Group className="form-group">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Meta Description"
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                          <Button style={{ float: "right" }} variant="warning">
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Banner Settings</h5>
                  </div>
                  <div className="card-body-blk">
                    <div className="row">
                      <div className="col-md-2">
                        <p style={{ paddingTop: "7px" }}>Banners(1500*450)</p>
                      </div>
                      <div className="col-md-10">
                        <Form.Group className="form-group">
                          <InputGroup>
                            <Button
                              style={{
                                backgroundColor: "#17A2B8",
                                width: "15%",
                              }}
                              variant="success"
                            >
                              Browse
                            </Button>
                            <Form.Control
                              type="text"
                              placeholder="Choose File"
                            />
                             <p className="text-muted">We had to limit height to maintian consistancy. 
                             In some device both side of the banner might be cropped for height limitation. </p>
                          </InputGroup>
                        </Form.Group>
                      </div>

                      <div className="col-md-12">
                        <Button
                          style={{ float: "right", marginTop: "12px" }}
                          variant="warning"
                        >
                         Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Social Media Link</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                 
                      <div className="row">
                        <div className="col-md-2">
                          <Form.Label>Facebook</Form.Label>
                        </div>
                        <div className="col-md-10">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="Facebook"
                            />
                           <p className="text-muted">Insert link with https </p>
                          </Form.Group>
                        </div>
                      </div>
                     
                      <div className="row">
                      <div className="col-md-2">
                          <Form.Label>Twitter</Form.Label>
                        </div>
                        <div className="col-md-10">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="Twitter"
                            />
                             <p className="text-muted">Insert link with https </p>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-2">
                          <Form.Label>Google</Form.Label>
                        </div>
                        <div className="col-md-10">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="Google"
                            />
                              <p className="text-muted">Insert link with https </p>
                          </Form.Group>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-md-2">
                          <Form.Label>YouTube</Form.Label>
                        </div>
                        <div className="col-md-10">
                          <Form.Group className="form-group">
                            <Form.Control
                              type="text"
                              placeholder="YouTube"
                            />
                              <p className="text-muted">Insert link with https </p>
                          </Form.Group>
                        </div>
                        <div className="col-md-12">
                        <Button
                          style={{ float: "right", marginTop: "12px" }}
                          variant="warning"
                        >
                         Save
                        </Button>
                      </div>
                      </div>
                      
                     
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
    </Page>
  );
};

export default ShopSetting;
