import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Table, Badge, Form, Button } from "react-bootstrap";
import EyeIcon from "../components/dashboard/ei_eye.jpg";
import DownloadIcon from "../components/dashboard/Download.svg";

const ordersData = [
  {
    id: 1,
    orderCode: "20220310-10564224",
    productQty: 10,
    customerName: "User 1",
    amount: "$35,867.000",
    delStatus: "Pending",
    payStatus: "Unpaid",
  },
  {
    id: 2,
    orderCode: "20220310-10564224",
    productQty: 10,
    customerName: "User 1",
    amount: "$35,867.000",
    delStatus: "Pending",
    payStatus: "Unpaid",
  },
  {
    id: 3,
    orderCode: "20220310-10564224",
    productQty: 10,
    customerName: "User 1",
    amount: "$35,867.000",
    delStatus: "Delivered",
    payStatus: "Paid",
  },
  {
    id: 4,
    orderCode: "20220310-10564224",
    productQty: 10,
    customerName: "User 1",
    amount: "$35,867.000",
    delStatus: "Pending",
    payStatus: "Unpaid",
  },
  {
    id: 5,
    orderCode: "20220310-10564224",
    productQty: 10,
    customerName: "User 1",
    amount: "$35,867.000",
    delStatus: "Delivered",
    payStatus: "Paid",
  },
];

const VendorOrders = () => {
  return (
    <Page title="Orders">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Orders</li>
              <Button style={{ float: 'right' }} href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Orders</h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk vendor-order-mobile">
                      <h5>Orders</h5>
                      <div className="col-md-3 mobile-margin-2">
                        <Form.Select>
                          <option value="">Filter by Payment Status</option>
                          <option>Paid</option>
                          <option>Unpaid</option>
                        </Form.Select>
                      </div>
                      <div className="col-md-3 mobile-margin-2">
                        <Form.Select>
                          <option value="">Filter by Delivery Status</option>
                          <option>Pending</option>
                          <option>Delivered</option>
                        </Form.Select>
                      </div>

                      <div className="col-md-3">
                        <Form.Control
                          type="text"
                          placeholder="Type order code & hit enter"
                        />
                      </div>
                    </div>
                    <div className="card-body-blk">
                    <div class="table-responsive">
                      <Table responsive="md" hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Order Code</th>
                            <th>Num of Products</th>
                            <th>Customer</th>
                            <th>Amount</th>
                            <th>Delivery Status</th>
                            <th>Payment Status</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ordersData.map((order, index) => (
                            <tr key={order.id}>
                              <td>{order.id}</td>
                              <td>
                                <a href="#">{order.orderCode}</a>
                              </td>
                              <td>{order.productQty}</td>
                              <td>{order.customerName}</td>
                              <td>{order.amount}</td>
                              <td
                                style={{
                                  color:
                                    index % 3 === 0 ? "#FFB800" : "#00B987",
                                }}
                              >
                                {order.delStatus}
                              </td>
                              <td>
                                <Badge bg="success">{order.payStatus}</Badge>
                              </td>
                              <td>
                                <img src={EyeIcon} alt="" />
                                <img
                                  style={{ paddingLeft: "5px" }}
                                  src={DownloadIcon}
                                  alt=""
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorOrders;
