import React, { useEffect, useState,useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../style/SellerDashboard.css"

import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Col,
  Tabs,
  Tab,
  Nav,
  Container,
  Table,
  Button,
} from "react-bootstrap";
import Page from "../components/Page";
import VerfiedImg from "../components/dashboard/verified.jpg";
import EyeIcon from "../components/dashboard/ei_eye.jpg";
import DownloadIcon from "../components/dashboard/Download.svg";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../config";
import { Bar } from "react-chartjs-2";
import { formatDate } from "./FormatDate";
import StateContext from "../context/StateContext";
import { ToastContainer,toast } from "react-toastify";
import { Chat } from "../components/user-dashboard/Chat";
const data1 = {
  labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Weekend Data",
      data: [1, 0.8, 0.6, 1.5, 0.8, 1, 1],
      backgroundColor: [
        "rgba(255, 99, 132, 0.6)",
        "rgba(54, 162, 235, 0.6)",
        "rgba(255, 206, 86, 0.6)",
        "rgba(75, 192, 192, 0.6)",
        "rgba(153, 102, 255, 0.6)",
        "rgba(255, 159, 64, 0.6)",
        "rgba(106, 90, 205, 0.6)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(106, 90, 205, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          display: false,
        },
        gridLines: {
          display: false, // Remove y-axis grid lines
        },
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false, // Remove x-axis grid lines
        },
      },
    ],
  },
};

const VendorDashboard = () => {
  const token = localStorage.getItem("avonToken");
  const [modal, setModal] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [statusData, setStatusData] = useState("");
  const [dashboard, setdashboard] = useState("");
  const [status, setStatus] = useState(false);
  console.log("statusData", statusData);
  const appState = useContext(StateContext);
  const[viewChat, setViewChat] = useState(false)

  useEffect(() => {
    FetchInto();
    FetchStatus();
    getdashboard();
  }, []);

  const FetchInto = async () => {
    await axios.get(BASE_URL + "/introductioncontent").then(({ data }) => {
      setData(data.data);
    });
  };

  const getdashboard = async () => {
    await axios
      .get(BASE_URL + "/getdashboard", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setdashboard(data);
      });
  };

  const FetchStatus = async () => {
    await axios
      .get(BASE_URL + "/get-seller-detail", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setStatusData(data);
        if (data.user === 1) {
          navigate("/", { replace: true });
          alert("You are Ban from Admin Please Contact Admin ");
        } else {
          if (data.data == null) {
            navigate("/vendor-dashboard");
          }
          if (data && data.data && data.data.payment_status == "Active") {
            // navigate('/business-profile')
          }
          setStatus(
            data.user == 1
              ? true
              : statusData && statusData.data == null
              ? true
              : statusData && statusData.data.payment_status == "Pending"
              ? true
              : statusData && statusData.data.payment_status == "Active"
              ? false
              : true
          );
        }
      });
  };

  console.log(dashboard, "dashboard");
  return (
    <Page title="Dashboard">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Vendor Dashboard</li>
              {/* <Button
                className="dashboard-btn"
                href="/dashboard"
                variant="primary"
              >
                Customer Dashboard
              </Button> */}
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid sellerdashboard-content">
            <div className="row">
            {statusData && statusData.data != null && (
              <VendorDashboardSideMenu PurchaseHis={status} />
            )}
              {/* right main content  */}
             {viewChat == false ?(
              <>
              {statusData && statusData?.data?.payment_status == "Active" && (
                <div className="col-lg-9 px-2 right ">
                  {appState?.state?.user?.is_email_verified == 0 &&(
                  <div className="top-alert w-100 mb-3 pe-3">
                            <button className='w-100 fs-6 ' style={{ background: "#FDD" }}>You u have not verified your Email Id, kindly click on Verify Now to verify and get full access to your Dashboard. <Link to="" className='mx-4'>Verify Now <img src="/assets/images/share-icon.png" alt="" width="15px" /></Link> <i className='fa fa-close fs-6'></i></button>
                        </div>
                  )}
                   {statusData && statusData.data && (
                  <div
                    className="card-block"
                    style={{ background: "lightgray" }}
                  >
                    <div className="" style={{ padding: 10 }}>
                     
                        <p>
                          <span style={{ color: "green", fontWeight: "bold" }}>
                            Congratulations!
                          </span>{" "}
                          You are a Premium Member with us, Your FREE Trials
                          Ends in "{formatDate(statusData?.data?.trial_expiry_at)}"
                          <br />
                          {statusData?.data?.package_type === "Directory" && (
                            <span style={{ color: "red", fontWeight: "bold" }}>
                              List your products to #1 Product directory and
                              reach millions of customers <Link to="/packages">"Upgrade Now"!</Link>
                            </span>
                          )}
                        </p>
                     
                    </div>
                    
                  </div>
                  )}
                  {/* Right Section1  */}
                  <div className="hpoints-graph pb-4 d-flex justify-content-between align-items-center">
                    
                    <div className="col-lg-7 d-flex justify-content-between">
                      <div
                        className="card px-3 py-3  rounded-4"
                        style={{ width: "fit-content" }}
                      >
                        <img
                          src="/assets/images/product-d.png"
                          alt=""
                          width={"39px"}
                        />
                        <h5 className="title">Product</h5>
                        <p>Last week added</p>
                        <span className="digit">1.2k</span>
                        <span className="percent">+15.4%</span>
                      </div>
                      <div
                        className="card px-3 py-3  rounded-4"
                        style={{ width: "fit-content" }}
                      >
                        <img
                          src="/assets/images/product-d.png"
                          alt=""
                          width={"39px"}
                        />
                        <h5 className="title">Product</h5>
                        <p>Last week added</p>
                        <span className="digit">1.2k</span>
                        <span className="percent">+15.4%</span>
                      </div>
                      <div
                        className="card px-3 py-3 rounded-4"
                        style={{ width: "fit-content" }}
                      >
                        <img
                          src="/assets/images/product-d.png"
                          alt=""
                          width={"39px"}
                        />
                        <h5 className="title">Product</h5>
                        <p>Last week added</p>
                        <span className="digit">1.2k</span>
                        <span className="percent text-danger">+15.4%</span>
                      </div>

                      <div
                        className="card px-3 py-3 rounded-4"
                        style={{ width: "fit-content" }}
                      >
                        <img
                          src="/assets/images/product-d.png"
                          alt=""
                          width={"39px"}
                        />
                        <h5 className="title">Product</h5>
                        <p>Last week added</p>
                        <span className="digit">1.2k</span>
                        <span className="percent">+15.4%</span>
                      </div>
                    </div>

                    {/* Graph ^^^^^^^^ */}
                    <div className=" mx-3 rounded-4 px-3 py-3 bg-white graph d-flex gap-2">
                      <div className="graph-content">
                        <h4>Weekly Groth</h4>
                        <div className="mt-5 d-flex flex-column gap-2">
                          <span className="view">
                            {" "}
                            <i className="fa fa-eye"></i> 46511k{" "}
                          </span>
                          <span className="bg-lgreen">+15.3%</span>
                        </div>
                      </div>

                      <div className="bar-chart">
                        <Bar data={data1} options={options} />
                      </div>
                    </div>
                  </div>

                  {/* Right Section2  */}

                  <div className="right-sec2 py-4 d-flex justify-content-between ">
                                    <div className="col-lg-8 bg-white px-3 py-3 rounded-4">
                                        <div className="overfx-div">
                                        <div className="top-btns d-flex gap-2 mb-3" >
                                            <button className='active-db-btn'>Recent Inquiry</button>
                                            <button>Follow-Ups</button>
                                            <button>Confirmed</button>
                                            <button>Not Interested</button>
                                        </div>
                                        </div>
                                        <div className="table-div" style={{ overflowX: "auto" }}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date & Time</th>
                                                        <th scope="col">Customer Name</th>
                                                        <th scope="col">Email ID</th>
                                                        <th scope="col">Mobile Number</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Product & Service</th>
                                                        <th scope="col">other1</th>
                                                        <th scope="col">other2</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="chat-box bg-white px-3 py-3 rounded-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3>Chats</h3>
                                            <Link to="#" onClick={() => setViewChat(true)}>View all chats</Link>
                                        </div>
                                        <div className="searchbar py-2 position-relative">
                                            <input type="text" placeholder='Search...' />
                                            <span><i className='fa fa-search'></i></span>
                                        </div>
                                        <div className="users-chat py-2">
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                  {/* Right Section3 ^^^^^^^^^^^^^^^^^^^*/}
                  <div className="right-sec3 bg-white py-4 px-3 rounded-4 ">
                    <div className=" d-flex justify-content-between">
                      <div className="col-lg-4 active-products pe-2">
                        <h3>Active Products</h3>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                        <div className="d-flex gap-3 align-items-center">
                          <img src="/assets/images/product-d-2.png" alt="" />

                          <div className="product-content">
                            <span className="pd-type">Jackets</span>
                            <h5 className="title">
                              Adidas Z.N.E. Fast Release Hoodie
                            </h5>
                            <p className="price">₹254.99</p>
                          </div>
                        </div>
                      </div>


                      {/* ------Analysistic---- -------------------*/}
                      <div className="col-lg-3 analystics">
                        <h3>Analytics</h3>
                        <div className="global-img my-3">
                          <img src="/assets/images/global-map.png" alt="" />
                          <div className=" d-flex gap-5 align-items-center">
                            <div>
                              <span>7.25K</span>
                              <p className="mb-0">Views</p>
                            </div>
                            <div>
                              <span>3K</span>
                              <p className="mb-0">Clients</p>
                            </div>
                          </div>
                        </div>

                        <div className="recent-search my-4">
                          <h3>Recent Searches</h3>
                          <ul className="ps-3">
                            <li>Lucknow, Uttar-Pradesh</li>
                            <li>Bhiwandi, Maharashtra</li>
                            <li>Lucknow, Uttar-Pradesh</li>
                            <li>Bhiwandi, Maharashtra</li>
                            <li>Lucknow, Uttar-Pradesh</li>
                            <li>Lucknow, Uttar-Pradesh</li>
                          </ul>
                        </div>
                      </div>

                      {/* Company Review  */}
                      <div className="col-lg-4 company-reivew bg-white px-3 py-3">
                        <h3>Company Reviews</h3>

                        <div className="reviews-box ">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="stars-box ">
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star"></i>
                              </span>
                            </div>
                            <div
                              className="d-flex gap-2 align-items-center"
                              style={{ width: "65%" }}
                            >
                              <div
                                className="progress w-100"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: "75%" }}
                                ></div>
                              </div>
                              <p className="mb-0">40</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="stars-box ">
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star"></i>
                              </span>
                            </div>
                            <div
                              className="d-flex gap-2 align-items-center"
                              style={{ width: "65%" }}
                            >
                              <div
                                className="progress w-100"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: "50%" }}
                                ></div>
                              </div>
                              <p className="mb-0">20</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="stars-box ">
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star"></i>
                              </span>
                            </div>
                            <div
                              className="d-flex gap-2 align-items-center"
                              style={{ width: "65%" }}
                            >
                              <div
                                className="progress w-100"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: "35%" }}
                                ></div>
                              </div>
                              <p className="mb-0">15</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="stars-box ">
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star"></i>
                              </span>
                            </div>
                            <div
                              className="d-flex gap-2 align-items-center"
                              style={{ width: "65%" }}
                            >
                              <div
                                className="progress w-100"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: "20%" }}
                                ></div>
                              </div>
                              <p className="mb-0">10</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="stars-box ">
                              <span style={{ color: "goldenrod" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star pe-1"></i>
                              </span>
                              <span style={{ color: "#E4E4E4" }}>
                                <i className="fa fa-star"></i>
                              </span>
                            </div>
                            <div
                              className="d-flex gap-2 align-items-center"
                              style={{ width: "65%" }}
                            >
                              <div
                                className="progress w-100"
                                role="progressbar"
                                aria-label="Basic example"
                                aria-valuenow="0"
                                aria-valuemin="0"
                                aria-valuemax="100"
                              >
                                <div
                                  className="progress-bar"
                                  style={{ width: "0%" }}
                                ></div>
                              </div>
                              <p className="mb-0">00</p>
                            </div>
                          </div>
                        </div>

                        <div className="user-reviews mt-3 pt-2">
                          <div className="card border-0 py-2">
                            <div className="d-flex gap-3 my-2 align-items-center">
                              <img
                                src="/assets/images/profile-img.png"
                                alt=""
                                width={"32px"}
                              />
                              <h6 className="user-name mb-0">Morgan</h6>
                            </div>
                            <div className="my-2 d-flex gap-2">
                              <div className="stars-box ">
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star"></i>
                                </span>
                              </div>
                              <p className="mb-0">
                                <strong>| 2 week ago</strong>
                              </p>
                            </div>

                            <p>
                              Lorem ipsum dolor sit, Lorem ipsum dolor sit amet
                              Tempore voluptatum quidem ducimus nobis deserunt?
                              Incidunt nihil temporibus voluptatibus labore
                              autem.
                            </p>
                          </div>
                          <div className="card border-0 py-2">
                            <div className="d-flex gap-3 my-2 align-items-center">
                              <img
                                src="/assets/images/profile-img.png"
                                alt=""
                                width={"32px"}
                              />
                              <h6 className="user-name mb-0">Morgan</h6>
                            </div>
                            <div className="my-2 d-flex gap-2">
                              <div className="stars-box ">
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star pe-1"></i>
                                </span>
                                <span style={{ color: "goldenrod" }}>
                                  <i className="fa fa-star"></i>
                                </span>
                              </div>
                              <p className="mb-0">
                                <strong>| 2 week ago</strong>
                              </p>
                            </div>

                            <p>
                              Lorem ipsum adipisicing elit. Tempore voluptatum
                              quidem ducimus nobis deserunt? Incidunt nihil
                              temporibus voluptatibus labore autem.
                            </p>
                          </div>

                          <div className="text-center">
                            <Link to="/">
                              <button style={{ background: "#E0EBFF" }}>
                                View all review
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="alert-box gap-3 d-flex mt-4 ">
                      <button>
                        Your Last Successful Login was 12-11-2023 3:00 PM |
                        Location: Mumbai, India{" "}
                        <span>
                          <i className="fa fa-close ps-2 fs-6"></i>
                        </span>
                      </button>
                      <button style={{ background: "#FFDDDD" }}>
                        {" "}
                        Your Last Successful Login was 12-11-2023 3:00 PM |
                        Location: Mumbai, India{" "}
                        <i className="fa fa-close ps-2 fs-6"></i>
                      </button>
                    </div>
                  </div>
                </div>
              )}
              </>
             ):(
              <div className="col-lg-9">
 <Chat />
              </div>
             
             )}
              

              <div className={statusData.data == null ? "col-md-12 col-sm-12 col-xs-12" :"col-md-9 col-sm-12 col-xs-12"}>
                
                {statusData && statusData.data == null ? (
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="card-block">
                      <div className="order-head-blk">
                        <h5>{data && data.heading}</h5>
                      </div>
                      <div className="card-body-blk">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data && data.content,
                          }}
                        ></p>
                        <div style={{ textAlign: "right" }}>
                          <Button onClick={() => navigate("/packages")}>
                            Agreed to the Terms
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : statusData &&
                  statusData.data.payment_status == "Pending" ? (
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <div className="card-block">
                      <div className="order-head-blk">
                        <h5>{data && data.heading}</h5>
                      </div>
                      <div className="card-body-blk">
                        <p>
                          {" "}
                          Your Seller Application is pending for approval,
                          please try again later{" "}
                        </p>
                        <div style={{ textAlign: "right" }}>
                          <Button onClick={() => navigate("/")}>
                            Go to Home
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={modal}>
        <Modal.Header>Vendor Dashboard</Modal.Header>
        <Modal.Body>
          {statusData && statusData.data == null ? (
            <div>
              <h6>{data && data.heading}</h6>
              <p dangerouslySetInnerHTML={{ __html: data && data.content }}></p>
            </div>
          ) : statusData && statusData.data.payment_status == "Pending" ? (
            <>
              <h6>
                Your Seller Application is pending for approval, please try
                again later"
              </h6>
            </>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {statusData && statusData.data == null ? (
            <Button onClick={() => navigate("/packages")}>Accept</Button>
          ) : (
            <Button onClick={() => navigate("/")}> Go to Home </Button>
          )}
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </Page>
  );
};
export default VendorDashboard;
