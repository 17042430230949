import React, { useEffect, useState, useRef, useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Form,
  InputGroup,
  Card,
  Modal,
  CardGroup,
} from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from "../components/address-block/more-vertical.svg";
import AddIcon from "../components/address-block/add-circle.svg";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
// import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import "draft-js/dist/Draft.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ReactPaginate from "react-paginate";
import content from "./content";
import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import StateContext from "../context/StateContext";
import Select, { components } from 'react-select';
const ManageProfile = ({ props }) => {
  const [chip, setChip] = useState([]);
  const [body, setbody] = useState("");
  const [desc, setdesc] = useState("");
  const [det, setdet] = useState("");
  const [pol, setpol] = useState("");
  const [othd, setothd] = useState("");
  const body1 = React.createRef();
  const body2 = React.createRef();
  const body3 = React.createRef();
  const body4 = React.createRef();
  const { locationdata } = useContext(StateContext);

  const handlebody = (e) => {
    setbody(e.target.value);
  };

  const [field, setField] = useState();
  const [variantActive, setVariantActive] = useState();
  const [productName, setProductName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [unit, setUnit] = useState("");
  const [minQuan, setMinQuan] = useState([]);
  const [min_quan, setmin_quan] = useState("");
  const [gallaryImg, setGallaryImg] = useState("");
  const [file1, setfile1] = useState("");
  const [file2, setfile2] = useState("");
  const [file3, setfile3] = useState("");
  const [file5, setfile5] = useState("");
  const [filep, setfilep] = useState("");
  const [page, setpage] = useState("");
  const [thumbnailImg, setThumbnailImg] = useState("");
  const [videoProvider, setVideoProvider] = useState("");
  const [videolink, setvideolink] = useState("");
  const [ordertype, setordertype] = useState("ordering");
  const [unitPrice, setUnitPrice] = useState("single");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [tax, setTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [discounttype, setDiscounttype] = useState("amount    ");
  const [quantity, setQuantity] = useState("");
  const [purchaseHigh, setPurchaseHigh] = useState("");
  const [shortDes, setShortDes] = useState("");
  const [productFeatures, setProductFeatures] = useState("");
  const [productDetail, setProductDetail] = useState("");
  const [highlight, sethighlight] = useState("");
  const [features, setfeatures] = useState("");
  const [shippingPolicy, setShippingPolicy] = useState("");
  const [orderDetail, setOrderDetail] = useState("");
  const [freeShipping, setFreeShipping] = useState("");
  const [flatRate, setFlatRate] = useState("");
  const [shipPrice, setShipPrice] = useState("");
  const [Pdf, setPdf] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [metaImg, setMetaImg] = useState("");
  const [range, setrange] = useState([]);
  const [coloractive, setcoloractive] = useState(0);
  const [minqnt, setminqnt] = useState(0);
  const [maxqnt, setmaxqnt] = useState(0);
  const [igst, setigst] = useState("");
  const [sgst, setsgst] = useState("");
  const [cgst, setcgst] = useState("");
  const [vat, setvat] = useState("");
  const [taxtype, settaxtype] = useState("gst");
  const [taxtypes, settaxtypes] = useState("amount");
  const [unitprice, setunitprice] = useState([]);
  const [unitprice2, setunitprice2] = useState("");
  const [unitprice3, setunitprice3] = useState("");
  const [purchaseprice, setpurchaseprice] = useState([]);
  const [variantPrice, setVariantPrice] = useState([]);
  const [variantPrice2, setVariantPrice2] = useState([]);
  const [variantPrice3, setVariantPrice3] = useState([]);
  const [flashdeal, setflashdeal] = useState(0);
  const [filebr, setfilebr] = useState("");
  const [featured, setfeatured] = useState(0);
  const [current_stock, setcurrent_stock] = useState("");
  const [msg1, setmsg1] = useState("");
  const [shippingtype, setshippingtype] = useState("");
  const [show, setShow] = useState(false);
  const [showg, setShowg] = useState(false);
  const [showt, setShowt] = useState(false);
  const [showm, setShowm] = useState(false);
  const [showsel, setShowsel] = useState(true);
  const [showupl, setShowupl] = useState(false);
  const [status, setstatus] = useState("active");
  const [error, setError] = useState();
  const [error1, setError1] = useState();
  const [showbr, setShowbr] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseg = () => setShowg(false);
  const handleShowg = () => setShowg(true);
  const handleCloset = () => setShowt(false);
  const handleShowt = () => setShowt(true);
  const handleClosem = () => setShowm(false);
  const handleShowm = () => setShowm(true);
  const handleClosebr = () => setShowbr(false);
  const handleShowbr = () => setShowbr(true);
  const ref = useRef(null);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const handleGallary = (id, img) => {
    setGallaryImg(id);
    setfile1(IMAGE_BASE_URL + img);
    handleCloseg(false);
  };

  const handleThumbnail = (id, img) => {
    setThumbnailImg(id);
    setfile2(IMAGE_BASE_URL + img);
    handleCloset(false);
  };
  const handlePdf = (e) => {
    setfilep(URL.createObjectURL(e.target.files[0]));
    setPdf(e.target.files[0]);
  };

  const handleuploadImg = (e) => {
    setfile5(URL.createObjectURL(e.target.files[0]));
    const formdata = new FormData();
    formdata.append("aiz_file", e.target.files[0]);
    axios
      .post(BASE_URL + "/uploadimage", formdata, {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.status == true) {
          Fetchgallery();
          toast.success(data.message);
        }
      });
  };

  const handleMetaImg = (id, img) => {
    setMetaImg(id);
    setfile3(IMAGE_BASE_URL + img);
    handleClosem(false);
  };

  const handleChange = (e) => {
    setField("gtc", e.target.checked);
    setVariantActive(!variantActive);
  };

  const handlechangeprice = (e, i) => {
    console.log(i);
    const { value } = e.target;
    const list = [...unitprice];
    list[i] = value;
    console.log(value);
    setunitprice(list);

    let data1 = [];
    let data2 = [];
    let data3 = [];
    if (comb.combinations) {
      comb.combinations.map((item, index) => {
        i == 0 && data1.push(value);
        i == 1 && data2.push(value);
        i == 2 && data3.push(value);
      });
      i == 0 && setVariantPrice(data1);
      i == 1 && setVariantPrice2(data2);
      i == 2 && setVariantPrice3(data3);
    }
  };
  const handlechangepurchaseprice = (e, i) => {
    const { value } = e.target;
    const list = [...unitprice];
    list[i] = value;
    setpurchaseprice(list);
  };

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;

  const [selectedVariant, setSelectedVaraint] = useState([]);
  const [selectedVariantn, setSelectedVaraintn] = useState([]);
  const [selectedColor, setSelectedColor] = useState([]);
  const token = localStorage.getItem("avonToken");
  const [brand, setBrand] = useState();
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [variantValue, setVariantValue] = useState([]);
  const [gallery, setgallery] = useState([]);
  const [maxQuan, setMaxQuan] = useState([]);
  const [busidata, setbusidata] = useState("");
  const { link } = useParams();
  const [purchasepriceusd, setpurchasepriceusd] = useState([]);
  const [unitpriceusd, setunitpriceusd] = useState([]);
  const [variantPriceusd, setVariantPriceusd] = useState([]);
  const [variantPriceusd2, setVariantPriceusd2] = useState([]);
  const [variantPriceusd3, setVariantPriceusd3] = useState([]);
  const [units, setunits] = useState([]);
  const [stockclearnce, setstockclearnce] = useState("");
  const [startdatetime, setstartdatetime] = useState("");
  const [enddatetime, setenddatetime] = useState("");
  const [new_arrivals, setnew_arrivals] = useState(0);
  const [parentcategory, setparentcategory] = useState("");
  const [subcategorys, setsubcategorys] = useState([]);
  useEffect(() => {
    FetchAttribute();
    FetchColor();
    FetchSkuCombination(variantValue, range);
    FetchBrand();
    FetchCategories();
    Fetchgallery();
    FetchProduct();
    getsellerbusinessdata();
    getunits();
  }, []);
  useEffect(() => {
    FetchSkuCombination(variantValue, range);
  }, [variantValue, range]);
  const getunits = async () => {
    await axios.get(BASE_URL + '/units').then(({ data }) => {
      setunits(data.data)
    })
  }
  const handleVariantPriceusd = (price, index, i) => {
    const d = [...variantPriceusd];
    d[index] = price;
    console.log(d);
    setVariantPriceusd(d);
  };
  const handleVariantPriceusd2 = (price, index, i) => {
    const d = [...variantPriceusd2];
    d[index] = price;
    console.log(d);
    setVariantPriceusd2(d);
  };
  const handleVariantPriceusd3 = (price, index, i) => {
    const d = [...variantPriceusd3];
    d[index] = price;
    console.log(d);
    setVariantPriceusd3(d);
  };
  const getsellerbusinessdata = async () => {
    await axios.get(BASE_URL + '/getsellerbusinessdata', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setbusidata(data.data)
    })
  }
  const [attribute, setAttribute] = useState([]);
  const [color, setColor] = useState([]);
  const [NewUnit, setNewUnit] = useState("");
  const [err, seterr] = useState([]);
  const [errattr, seterrattr] = useState([]);
  const [errc, seterrc] = useState([]);
  const [showu, setShowu] = useState(false);
  const [showb, setShowb] = useState(false);
  const [showc, setShowc] = useState(false);
  const [showattr, setShowattr] = useState(false);
  const [brandname, setbrandname] = useState("");
  // const colorName = selectedColor.map((item) => item);
  const [attributename, setattributename] = useState("");
  const [categoryname, setcategoryname] = useState("");
  const [subcategoryId, setsubcategoryId] = useState("");
  const [errb, seterrb] = useState([]);
  const [logo, setlogo] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const toggleSwitch = () => {
    setIsChecked(!isChecked);
  };

  const FetchAttribute = async () => {
    await axios.get(BASE_URL + "/attribute").then(({ data }) => {
      setAttribute(data.data);
    });
  };

  const Fetchgallery = async () => {
    await axios.get(BASE_URL + "/gallery").then(({ data }) => {
      setgallery(data.data.data);
      setpage(data.data.last_page);
    });
  };
  const handlegalleryClick = async (data) => {
    let currentPage = data.selected + 1;
    await axios
      .get(BASE_URL + "/gallery?page=" + currentPage)
      .then(({ data }) => {
        setgallery(data.data.data);
        setpage(data.data.last_page);
      });
  };
  // const FetchCategories = async () => {
  //   await axios.get(BASE_URL + "/categories").then(({ data }) => {
  //     setCategories(data.data.categories);
  //   });
  // };
  const FetchCategories = async () => {
    await axios.get(BASE_URL + "/allcategories").then(({ data }) => {
      setCategories(data.data);
    });
  };
  const FetchsubCategories = async (id) => {
    await axios.get(BASE_URL + "/getsubcategories/" + id).then(({ data }) => {
      setsubcategorys(data.data);
    });
  };
  const FetchBrand = async () => {
    await axios.get(BASE_URL + "/brand").then(({ data }) => {
      setBrand(data.data);
    });
  };

  const FetchColor = async () => {
    await axios.get(BASE_URL + "/color").then(({ data }) => {
      setColor(data.data);
    });
  };
  const [list, setList] = useState();
  const handlechangeChip = (chip, index) => {
    const d = [...variantValue];
    d[index] = chip;
    setVariantValue(d);
    FetchSkuCombination(d, range);
    // setList.push()
  };
  const handlechangeChipvalue = () => {
    FetchSkuCombination(variantValue, range);
    let d = Math.max(...minQuan);
    let t = Math.max(...maxQuan);
    if (d != "-Infinity" || t != "-Infinity") {
      if (minqnt < d || minqnt < t) {
        alert("Min Quantity not be greater than previous value");
      } else if (maxqnt < d || minqnt < t) {
        alert("Max Quantity not be greater than previous");
      } else {
        setmsg1("");
        setMinQuan([...minQuan, ...[minqnt]]);
        setMaxQuan([...maxQuan, ...[maxqnt]]);
        if (range.length < 3) {
          const de = [...range, ...[minqnt + "-" + maxqnt]];
          setrange([...range, ...[minqnt + "-" + maxqnt]]);
          FetchSkuCombination(variantValue, de);
        } else {
          setmsg1("Maximum 3 range you can select");
        }
        handleClose();
      }
    } else {
      setmsg1("");
      setMinQuan([...minQuan, ...[minqnt]]);
      setMaxQuan([...maxQuan, ...[maxqnt]]);
      if (range.length < 3) {
        const def = [...range, ...[minqnt + "-" + maxqnt]];
        setrange([...range, ...[minqnt + "-" + maxqnt]]);
        FetchSkuCombination(variantValue, def);
      } else {
        setmsg1("Maximum 3 range you can select");
      }
      handleClose();
    }
  };

  const handlechangerange = (chip1) => {
    setmsg1("");
    if (chip1.length < 4) {
      setrange(chip1);
    } else {
      setmsg1("Maximum 3 range you can select");
    }

    // setList.push()
  };

  const handleVariantPrice = (price, index) => {
    const d = [...variantPrice];
    d[index] = price;
    setVariantPrice(d);
    // setList.push()
  };
  const AddUnit = async () => {
    const data = {
      'unit': NewUnit
    }
    await axios.post(BASE_URL + `/addunits`, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      getunits();
      seterr("")
      setShowu(false)
      setUnit({ label: NewUnit, value: NewUnit })
      if (data.status == true) {
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status === 'error') {
        seterr(response.data.message)
      }
    })
  }
  const ADDAttribute = async () => {
    const data = {
      'name': attributename
    }
    await axios.post(BASE_URL + `/addattribute`, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchAttribute();
      seterrattr("")
      setShowattr(false)
      // setUnit({label: NewUnit, value: NewUnit})
      // if (data.status == true) {
      //   toast.success(data.message)
      // }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status === 'error') {
        seterrattr(response.data.message)
      }
    })
  }
  const Addcategory = async () => {
    const data = {
      'name': categoryname,
      'parent_id': parentcategory
    }
    await axios.post(BASE_URL + `/addcategory`, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchCategories();
      seterrc("")
      setShowc(false)
      // setUnit({label: NewUnit, value: NewUnit})
      // if (data.status == true) {
      //   toast.success(data.message)
      // }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status === 'error') {
        seterrc(response.data.message)
      }
    })
  }
  const AddBrand = () => {
    const data = {
      "logo": logo,
      "name": brandname
    }

    axios
      .post(BASE_URL + "/addbrand", data, {
        headers: {
          // "content-type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.status == true) {
          seterrb("");
          FetchBrand();
          setBrandId({ label: brandname, value: brandname })
          setShowb(false);
          // toast.success(data.message);
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status === 'error') {
          seterrb(response.data.message)
        }
      });
  }
  const handleVariantPrice2 = (price, index, i) => {
    const d = [...variantPrice2];
    d[index] = price;
    console.log(d);
    setVariantPrice2(d);
  };
  const handleVariantPrice3 = (price, index, i) => {
    const d = [...variantPrice3];
    d[index] = price;
    console.log(d);
    setVariantPrice3(d);
  };
  const [sku, setSKU] = useState([]);
  const handleSKU = (price, index) => {
    const d = [...sku];
    d[index] = price;
    setSKU(d);
    // setList.push()
  };

  const [quan, setQuan] = useState([]);
  const handleQuan = (price, index) => {
    const d = [...quan];
    d[index] = price;
    setQuan(d);
    // setList.push()
  };

  const [minOdQuan, setMinOdQuan] = useState([]);
  const handleMinQuan = (price, index) => {
    const d = [...minQuan];
    d[index] = price;
    setMinOdQuan(d);
    // setList.push()
  };

  const handleMaxQuan = (price, index) => {
    const d = [...maxQuan];
    d[index] = price;
    setMaxQuan(d);

    // setList.push()
  };

  const handlechange = (e) => {
    setChip(e);
    // setList.push()
  };
  const FetchProduct = async () => {
    await axios
      .get(BASE_URL + "/editsellorproduct/" + link, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        let p = data.product;
        setProductName(p.name);
        FetchsubCategories(p.category && p.category.id)
        setCategoryId(p.category && { label: p.category.name, value: p.category.id });
        setsubcategoryId(p.subcategory && { label: p.subcategory.name, value: p.subcategory.id });
        setBrandId(p.brand && { label: p.brand.name, value: p.brand.id });
        setUnit(p.units && { label: p.units.unit, value: p.units.unit });
        setmin_quan(p.min_qty);
        setfile1(p.photo && IMAGE_BASE_URL + p.photo.file_name);
        setfile2(p.thumbnailimg && IMAGE_BASE_URL + p.thumbnailimg.file_name);
        setfile3(p.metaimg && IMAGE_BASE_URL + p.metaimg.file_name);
        setfilep(p.pdf && IMAGE_BASE_URL + p.pdf);
        setChip(p.tags);
        setVideoProvider(p.video_provider);
        setvideolink(p.video_link);
        sethighlight(p.highlight);
        setfeatures(p.features);

        // let s = [];
        // p.colors.map(
        //   (item, i) => (s = [...s, ...[{ label: item, value: item }]])
        // );
        // setSelectedColor(s);

        setordertype(p.order_type);
        setMetaTitle(p.meta_title);
        setshippingtype(p.shipping_type);
        setShipPrice(p.shipping_cost);
        setothd(p.other_detail);
        setpol(p.shipping_policy);
        setdet(p.description);
        setdesc(p.short_description);
        sethighlight(p.highlight);
        setfeatures(p.features);
        setMetaDescription(p.meta_description);
        setcgst(p.cgst_percent ? p.cgst_percent : 0);
        setigst(p.igst_percent ? p.igst_percent : 0);
        setsgst(p.sgst_percent ? p.sgst_percent : 0);
        setvat(p.vat ? p.vat : "");
        setVariantValue(p.choice_options1);
        setunitprice(p.unit_price);
        setunitprice2(p.unit_price2);
        setunitprice3(p.unit_price3);
        setpurchaseprice(JSON.parse(p.purchase_prices));
        setpurchasepriceusd(JSON.parse(p.purchase_price_usd));
        setunitprice(JSON.parse(p.unit_prices));
        setunitpriceusd(JSON.parse(p.unit_price_usd));
        // setpurchaseprice2(p.purchase_price2);
        // setpurchaseprice3(p.purchase_price3);
        setSelectedVaraint(p.attributes);
        setSelectedVaraintn(p.attributes1);
        setMetaImg(p.meta_img);
        setThumbnailImg(p.thumbnail_img);
        setGallaryImg(p.photos);
        if (p.gst_status == "yes") {
          settaxtype("gst");
        } else {
          settaxtype("vat");
        }

        settaxtypes(p.tax_type);
        setstatus(p.status);
        setDiscount(p.discount);
        setDiscounttype(p.discount_type);
        setcurrent_stock(p.current_stock);
        setfeatured(p.featured);
        setnew_arrivals(p.new_arrivals);
        setflashdeal(p.flashdeals);
        if (p.flashdeals == 1) {
          setstockclearnce("flashdeals")
        }
        if (p.stock_clearance_deal == 1) {
          setstockclearnce("stockclearance")
        }
        setstartdatetime(p.start_date_time);
        setenddatetime(p.end_date_time)
        setUnitPrice(p.product_type);
        setrange(p.product_range);
        console.log(p.colors)
        setSelectedColor(p.colors)
        setmin_quan(p.min_qty)
        //   setComb(data.product_stock);

        setcoloractive(p.colors.length > 1 ? 1 : 0);
        FetchSkuCombination(variantValue, range);

      });
  };
  const handledeletevariant = (index) => {
    // const d = comb.filter(function (item, i) {
    //   return i !== index;
    // });
    // setComb(d);
  };

  const attribute_id = selectedVariant.map((item) => item);

  const [comb, setComb] = useState([]);

  const combination = [{}, {}];

  const FetchSkuCombination = (d, range) => {
    const data = {
      colors_active: coloractive,
      colors: selectedColor,
      choice_no: attribute_id,
      choice_options: d,
      range: range,
      id: link,
    };
    axios
      .post(BASE_URL + "/sku_combinationedit", data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setVariantPrice(data.price);
        var sku1 = [];
        var quann1 = [];
        let data1 = [];
        let data2 = [];
        let data3 = [];
        let data11 = [];
        let data21 = [];
        let data31 = [];
        data &&
          data.combinations &&
          data.combinations.map((item, i) => {
            sku1.push(item.join("-"))
            quann1.push(current_stock)
            data1.push(unitprice[0]);
            data2.push(unitprice[1]);
            data3.push(unitprice[2]);
            data11.push(unitpriceusd[0]);
            data21.push(unitpriceusd[1]);
            data31.push(unitpriceusd[2]);
          }

          );
        setSKU(sku1);
        setQuan(quann1)
        setVariantPrice(data1);
        setVariantPrice2(data2);
        setVariantPrice3(data3);
        setComb(data);
        setVariantPriceusd(data11);
        setVariantPriceusd2(data21);
        setVariantPriceusd3(data31);
      });
  };
  const handlechangepriceusd = (e, i) => {
    console.log(i)
    const { value } = e.target;
    const list = [...unitpriceusd];
    list[i] = value;
    console.log(value)
    setunitpriceusd(list);

    let data1 = [];
    let data2 = [];
    let data3 = [];
    if (comb.combinations) {

      comb.combinations.map((item, index) => {
        i == 0 && data1.push(value)
        i == 1 && data2.push(value)
        i == 2 && data3.push(value)
      }
      );
      i == 0 && setVariantPriceusd(data1);
      i == 1 && setVariantPriceusd2(data2);
      i == 2 && setVariantPriceusd3(data3);
    };
  }
  const handlechangepurchasepriceusd = (e, i) => {
    const { value } = e.target;
    const list = [...purchasepriceusd];
    list[i] = value;
    setpurchasepriceusd(list);
  };
  const AddProduct = async () => {
    setError("");
    // var d = [];
    // comb.map((item, index) => d.push(item.pop()));
    const view1 = body1.current.view;
    const view2 = body2.current.view;
    const view3 = body3.current.view;
    const view4 = body4.current.view;
    var shrtdesc = EditorUtils.getHtml(view1.state);
    var prodt = EditorUtils.getHtml(view2.state);
    var shipp = EditorUtils.getHtml(view3.state);
    var othdet = EditorUtils.getHtml(view4.state);
    const formData = new FormData();
    formData.append("name", productName);
    formData.append("category_id", categoryId && categoryId.value);
    formData.append("subcategory_id", subcategoryId && subcategoryId.value);
    formData.append("brand_id", brandId && brandId.value);
    formData.append("current_stock", current_stock);
    // formData.append("min_quan", min_quan);
    formData.append("unit", unit && unit.value);
    formData.append("tags", JSON.stringify(chip));
    formData.append("photos", gallaryImg);
    formData.append("thumbnail_img", thumbnailImg);
    formData.append("min_qty", min_quan);
    formData.append("description", prodt);
    formData.append("video_provider", videoProvider);
    formData.append("videolink", videolink);
    formData.append("unit_price", JSON.stringify(unitprice));
    formData.append("unit_price_usd", JSON.stringify(unitpriceusd));
    formData.append("purchase_price", JSON.stringify(purchaseprice));
    formData.append("purchase_price_usd", JSON.stringify(purchasepriceusd));
    formData.append("product_range", JSON.stringify(range));
    formData.append("tax_type", taxtype);
    formData.append("discount", discount);
    formData.append("discount_type", discounttype);
    formData.append("shipping_type", shippingtype);
    formData.append("cgst", cgst);
    formData.append("igst", igst);
    formData.append("sgst", sgst);
    formData.append("vat", vat);
    formData.append("tax_types", taxtypes);
    formData.append("short_description", shrtdesc);
    // formData.append('product_detail', prodt);
    formData.append("shipping_policy", shipp);
    formData.append("other_detail", othdet);
    formData.append("flat_shipping_cost", shipPrice);
    formData.append("meta_title", metaTitle);
    formData.append("meta_description", metaDescription);
    formData.append("colors_active", selectedColor ? "1" : 0);
    formData.append("choice_no", JSON.stringify(attribute_id));
    formData.append("pdf", Pdf);
    formData.append("colors", JSON.stringify(selectedColor));
    formData.append("choice_options", JSON.stringify(variantValue));
    formData.append("price", JSON.stringify(variantPrice));
    formData.append("price2", JSON.stringify(variantPrice2));
    formData.append("price3", JSON.stringify(variantPrice3));
    formData.append("price_usd1", JSON.stringify(variantPriceusd));
    formData.append("price_usd2", JSON.stringify(variantPriceusd2));
    formData.append("price_usd3", JSON.stringify(variantPriceusd3));
    formData.append("sku", JSON.stringify(sku));
    formData.append("qty", JSON.stringify(quan));
    formData.append("featured", featured);
    formData.append("flashdeals", stockclearnce === "flashdeals" ? 1 : 0);
    formData.append("minqty", JSON.stringify(minQuan));
    formData.append("maxqty", JSON.stringify(maxQuan));
    formData.append("type", unitPrice);
    formData.append("highlight", highlight);
    formData.append("features", features);
    formData.append("order_type", ordertype);
    formData.append("meta_img", metaImg);
    formData.append("new_arrivals", new_arrivals);
    formData.append("start_date_time", startdatetime);
    formData.append("end_date_time", enddatetime);
    formData.append("status", status);
    formData.append("stock_clearance_deal", stockclearnce === "stockclearance" ? 1 : 0);

    await axios
      .post(BASE_URL + "/updatesellorproduct/" + link, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
          timezone: locationdata.timezone,
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.status == true) {
          toast.success(data.message);
          navigate("/vendor-products");
        }
        // if (data.success == true) {
        //     toast.success(data.msgText)
        //     navigate('/thankyou',
        //         {
        //             state:
        //             {
        //                 'pack_name': pack_name,
        //                 'trans_id': transactionId,
        //                 'amount': Total_Amount,
        //                 'company_name': businessName
        //             }
        //         });
        // }
      })
      .catch(({ response }) => {
        if (response.data.code == 403) {
          toast.error(response.data.errors);
          setError1(response.data.errors);
        }
        if (response.data.status_code == 422) {
          console.log(response.data.message);
          setError(response.data.message);
        }
      });
  };
  const handleOnchange = val => {
    setSelectedColor(val.split(","))
    console.log(val)
  }
  function filterdata(type) {
    const data = attribute.filter((e) => e.value == type)
    return data[0];
  }
  const handleselectedattribute = val => {
    setSelectedVaraint(val.split(","))
  }
  const handlecuurentstock = (e) => {
    setcurrent_stock(e.target.value);
    comb &&
      comb.combinations &&
      comb.combinations.map((item, i) => {
        setQuan([...quan, e.target.value]);
      }

      );
  }
  const handleChangeunits = (selectedOption) => {
    setUnit(selectedOption)
  }
  const handleChangebrand = (selectedOption) => {
    setBrandId(selectedOption)
  }

  const handleChangecategory = (selectedOption) => {
    setCategoryId(selectedOption)
    FetchsubCategories(selectedOption && selectedOption.value)
  }
  const handleChangesubcategory = (selectedOption) => {
    setsubcategoryId(selectedOption)

  }
  const handleBrandImg = (id, img) => {
    setlogo(id);
    setfilebr(IMAGE_BASE_URL + img);
    handleClosebr(false);
  };
  var colors123 = [
    "red",
    "blue",
    "green",
    "yellow"
  ];
  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Edit Product</li>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>Edit Product</h4>
                  {/* <Button>Edit</Button> */}
                </div>

                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Information</h5>
                    {error1 ? (
                      <div className="text-danger"> {error1} </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Product Name
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Form.Control
                              placeholder="Enter Product Name"
                              value={productName}
                              onChange={(e) => setProductName(e.target.value)}
                            />
                            {error && error.name ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.name}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Category
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Select
                              value={categoryId}
                              onChange={handleChangecategory}
                              // components={{ NoOptionsMessage }}
                              noOptionsMessage={({ inputValue }) => !inputValue ? "" : (<span>No records found <button type="button" style={{ color: "blue" }} onClick={() => setShowc(true)}>Add New Category</button></span>)}

                              options={categories}
                            />
                            {/* <Form.Select
                              onChange={(e) => setCategoryId(e.target.value)}
                            >
                              <option value="">Select Category</option>
                              {categories &&
                                categories.map((item) => (
                                  <>
                                    <option value={item.id}>{item.name}</option>
                                    {item.childrens &&
                                      item.childrens.map((data) => (
                                        <option value={data.id}>
                                          {"-- " + data.name}
                                        </option>
                                      ))}
                                  </>
                                ))}
                            </Form.Select> */}
                            {/* <Button  className="mt-1 btn-sm" onClick={()=>setShowc(true)}>Add New Category</Button> */}
                            {error && error.category_id ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.category_id}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Sub Category
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Select
                              value={subcategoryId}
                              onChange={handleChangesubcategory}
                              // components={{ NoOptionsMessage }}
                              noOptionsMessage={({ inputValue }) => !inputValue ? "" : (<span>No records found <button type="button" style={{ color: "blue" }} onClick={() => setShowc(true)}>Add New Category</button></span>)}

                              options={subcategorys}
                            />
                            {/* <Form.Select
                              onChange={(e) => setCategoryId(e.target.value)}
                            >
                              <option value="">Select Category</option>
                              {categories &&
                                categories.map((item) => (
                                  <>
                                    <option value={item.id}>{item.name}</option>
                                    {item.childrens &&
                                      item.childrens.map((data) => (
                                        <option value={data.id}>
                                          {"-- " + data.name}
                                        </option>
                                      ))}
                                  </>
                                ))}
                            </Form.Select> */}
                            {/* <Button  className="mt-1 btn-sm" onClick={()=>setShowc(true)}>Add New Category</Button> */}
                            {error && error.subcategory ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.subcategory}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Category
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Form.Select
                              value={categoryId}
                              onChange={(e) => setCategoryId(e.target.value)}
                            >
                              <option value="">Select Category</option>
                              {categories &&
                                categories.map((item) => (
                                  <>
                                    <option value={item.id}>{item.name}</option>
                                    {item.childrens &&
                                      item.childrens.map((data) => (
                                        <option value={data.id}>
                                          {"-- " + data.name}
                                        </option>
                                      ))}
                                  </>
                                ))}
                            </Form.Select>
                            {error && error.category_id ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.category_id}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div> */}
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Brand
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Select
                              value={brandId}
                              onChange={handleChangebrand}
                              // components={{ NoOptionsMessage }}
                              noOptionsMessage={({ inputValue }) => !inputValue ? "" : (<span>No records found <button type="button" style={{ color: "blue" }} onClick={() => setShowb(true)}>Add New Brand</button></span>)}

                              options={brand}
                            />
                            {/* <Form.Select
                              onChange={(e) => setBrandId(e.target.value)}
                            >
                              <option value="" selected disabled>
                                Select Brand
                              </option>
                              {brand &&
                                brand.map((item) => (
                                  <option value={item.id}>{item.name}</option>
                                ))}
                            </Form.Select> */}
                            {/* <Button  className="mt-1 btn-sm" onClick={()=>setShowb(true)}>Add New Brand</Button> */}
                            {error && error.brand_id ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.brand_id}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Unit
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Select
                              value={unit}
                              onChange={handleChangeunits}
                              // components={{ NoOptionsMessage }}
                              noOptionsMessage={({ inputValue }) => !inputValue ? "" : (<span>No records found <button type="button" style={{ color: "blue" }} onClick={() => setShowu(true)}>Add New Unit</button></span>)}

                              options={units}
                            />

                            {error && error.unit ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.unit}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Minimum Quantity
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Form.Control
                              value={min_quan}
                              placeholder="Enter Minimum Quantity"
                              onChange={(e) => setmin_quan(e.target.value)}
                            />
                            {error && error.min_qty ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.min_qty}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Tags
                            </Form.Label>
                          </div>
                          <div className="col-md-10 mobile-margin-2">
                            <Chips
                              value={chip}
                              onChange={handlechange}
                              suggestions={["abc", "cde"]}
                              placeholder="Enter Keyword"
                              fromSuggestionsOnly={false}
                              createChipKeys={[13, 32]}
                            />
                            {error && error.tags ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.tags}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block ">
                  <div className="order-head-blk">
                    <h5>Product Images</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Gallery Images
                            </Form.Label>
                          </div>
                          <div className="col-md-10" onClick={handleShowg}>
                            <Form.Control
                              disabled
                              placeholder="Enter Product Name"
                              type="file"
                              onChange={(e) => e.preventDefault()}
                            />
                            {file1 && (
                              <img
                                style={{
                                  height: 100,
                                  width: 100,
                                  marginTop: 6,
                                }}
                                src={file1}
                              />
                            )}
                            {error && error.photos ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.photos}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Thumbnail Image <span>(290x300)</span>{" "}
                            </Form.Label>
                          </div>
                          <div className="col-md-10" onClick={handleShowt}>
                            <Form.Control
                              disabled
                              onChange={(e) => e.preventDefault()}
                              type="file"
                            />
                            {file2 && (
                              <img
                                style={{
                                  height: 100,
                                  width: 100,
                                  marginTop: 6,
                                }}
                                src={file2}
                              />
                            )}
                            {error && error.thumbnail_img ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.thumbnail_img}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Videos</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Video Provider
                            </Form.Label>
                          </div>
                          <div className="col-md-10 ">
                            <Form.Select
                              value={videoProvider}
                              onChange={(e) => setVideoProvider(e.target.value)}
                            >
                              <option value="">Select Provider</option>
                              <option value="youtube">Youtube</option>
                              <option value="dailymotion">Dailymotion</option>
                              <option value="vimeo">Vimeo</option>
                            </Form.Select>
                            {error && error.video_provider ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.video_provider}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2 ">
                            <Form.Label className="mt-2 product-font">
                              Video Link
                            </Form.Label>
                          </div>
                          <div className="col-md-10 mobile-margin-2">
                            <Form.Control
                              placeholder="Enter Link"
                              type="text"
                              value={videolink}
                              onChange={(e) => setvideolink(e.target.value)}
                            />
                            {error && error.videolink ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.videolink}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Variations</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Color
                            </Form.Label>
                          </div>
                          <div className="col-md-9">
                            <MultiSelect
                              options={color}
                              value={selectedColor}
                              defaultValue={selectedColor}
                              onChange={handleOnchange}
                              labelledBy={"Select Business Category"}
                              hasSelectAll={false}
                              disabled={coloractive == 1 ? false : true}
                            />
                            {error && error.color ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.color}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-1">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              style={{ fontSize: 20 }}
                              checked={coloractive == 1 ? true : false}
                              onChange={(e) =>
                                setcoloractive(e.target.checked ? 1 : 0)
                              }
                            />
                            {error && error.colors ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.colors}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Attribute
                            </Form.Label>
                          </div>
                          <div
                            className="col-md-9"
                            onClick={() =>
                              FetchSkuCombination(variantValue, range)
                            }
                          >

                            <MultiSelect
                              options={attribute}
                              value={selectedVariantn}
                              defaultValue={selectedVariantn}
                              onChange={handleselectedattribute}
                              labelledBy={"Select Business Category"}
                              hasSelectAll={false}
                            />
                            <button style={{ color: "blue" }} type="button" onClick={() => setShowattr(true)}>Add New Attribute</button>
                            {error && error.choice_no ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.choice_no}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-12 mt-2">
                          Choose the attributes of this product and then input
                          values of each attribute
                        </div>
                        {selectedVariant.length ? (
                          <>
                            {selectedVariant &&
                              selectedVariant.map((item, index) => (
                                <div className="row mt-2" key={index}>
                                  <div className="col-md-2">
                                    <Form.Label className="mt-2 product-font">
                                      {filterdata(item) && filterdata(item).label}
                                    </Form.Label>
                                  </div>
                                  <div className="col-md-9 mobile-margin-2">
                                    <Chips
                                      value={variantValue[index]}
                                      key={index}
                                      onChange={(chip) => {
                                        handlechangeChip(chip, index);
                                      }}
                                      suggestions={["abc", "cde"]}
                                      placeholder="Enter Keyword"
                                      fromSuggestionsOnly={false}
                                      createChipKeys={[13, 32]}
                                    />
                                  </div>
                                </div>
                              ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Price & Stock</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                      <div>
                        <div className="hide-in-mobile">
                          <div className="col-md-12 add-product-checkboxes">
                            <div className="">
                              <div style={{ display: "flex" }}>
                                <p className="mt-1">Single Unit</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  style={{ fontSize: 20, marginLeft: 10 }}
                                  checked={unitPrice == "range" ? true : false}
                                  onChange={(e) =>
                                    setUnitPrice(
                                      e.target.checked ? "range" : "single"
                                    )
                                  }
                                />
                                <p className="mt-1">Product Range</p>
                              </div>
                            </div>
                            <div className="">
                              <div style={{ display: "flex" }}>
                                <p className="mt-1">Orders</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={ordertype == "inquiry" ? true : false}
                                  onChange={(e) =>
                                    setordertype(
                                      e.target.checked ? "inquiry" : "ordering"
                                    )
                                  }
                                  style={{ fontSize: 20, marginLeft: 10 }}
                                />
                                <p className="mt-1">Inquiry</p>
                              </div>
                            </div>

                            <div className="">
                              <div style={{ display: "flex" }}>
                                <p className="mt-1">Featured Product</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={featured == 1 ? true : false}
                                  onChange={(e) =>
                                    setfeatured(e.target.checked ? 1 : 0)
                                  }
                                  style={{ fontSize: 20, marginLeft: 10 }}
                                />
                              </div>
                            </div>
                            <div className="">
                              <div style={{ display: "flex" }}>
                                <p className="mt-1">New Arrivals</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={new_arrivals == 1 ? true : false}
                                  onChange={(e) =>
                                    setnew_arrivals(e.target.checked ? 1 : 0)
                                  }
                                  style={{ fontSize: 20, marginLeft: 10 }}
                                />
                              </div>
                            </div>
                            <div className="">
                              <div style={{ display: "flex" }}>
                                <p className="mt-1">Stock Clearance</p>
                                <Form.Check
                                  type="switch"
                                  name="stockcl"
                                  id="custom-switch"
                                  value="stockclearance"
                                  checked={stockclearnce == "stockclearance" ? true : false}
                                  onChange={(e) =>
                                    setstockclearnce(e.target.value)
                                  }
                                  style={{ fontSize: 20, marginLeft: 10 }}
                                />
                              </div>
                            </div>
                            <div className="">
                              <div style={{ display: "flex" }}>
                                <p className="mt-1">Flash Deal</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  value="flashdeals"
                                  checked={stockclearnce == "flashdeals" ? true : false}
                                  onChange={(e) =>
                                    setstockclearnce(e.target.value)
                                  }
                                  style={{ fontSize: 20, marginLeft: 10 }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="hide-in-desktop">
                          <div className="col-md-12 add-product-checkboxes">
                            <div className="d-flex justify-content-between mobile-margin-2" >
                              <div className="">
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <p className="mt-1" style={{ marginBottom: "0.5rem" }}>Product Range</p>
                                    <p className="mt-1" style={{ marginBottom: "0.5rem" }}>Single Unit</p>
                                  </div>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    style={{
                                      fontSize: 20,
                                      marginLeft: 10,
                                      transform: "rotate(-90deg)", // Rotate the switch vertically
                                      transformOrigin: "center", // Set the rotation origin to the center
                                    }}
                                    checked={unitPrice == "range" ? true : false}
                                    onChange={(e) =>
                                      setUnitPrice(
                                        e.target.checked ? "range" : "single"
                                      )
                                    }
                                  />
                                  {/* <p className="mt-1">Product Range</p> */}
                                </div>
                              </div>
                              <div className="">
                                <div style={{ display: "flex" }}>
                                  <div>
                                    <p className="mt-1" style={{ marginBottom: "0.5rem" }}>Orders</p>
                                    <p className="mt-1" style={{ marginBottom: "0.5rem" }}>Inquiry</p>
                                  </div>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    checked={ordertype == "inquiry" ? true : false}
                                    onChange={(e) =>
                                      setordertype(
                                        e.target.checked ? "inquiry" : "ordering"
                                      )
                                    }
                                    style={{
                                      fontSize: 20,
                                      marginLeft: 10,
                                      transform: "rotate(-90deg)", // Rotate the switch vertically
                                      transformOrigin: "center", // Set the rotation origin to the center
                                    }}
                                  />

                                </div>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between">
                              <div style={{ width: "50%" }}>
                                <p style={{ marginBottom: "0.2rem" }}>Featured Product</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={featured == 1 ? true : false}
                                  onChange={(e) =>
                                    setfeatured(e.target.checked ? 1 : 0)
                                  }
                                  style={{ fontSize: 20, }}
                                />
                              </div>
                              <div style={{ width: "30%" }}>
                                <p style={{ marginBottom: "0.2rem" }}>New Arrivals</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={new_arrivals == 1 ? true : false}
                                  onChange={(e) =>
                                    setnew_arrivals(e.target.checked ? 1 : 0)
                                  }
                                  style={{ fontSize: 20, }}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">

                              <div style={{ width: "50%", marginTop: "10px" }}>
                                <p style={{ marginBottom: "0.2rem" }}>Stock Clearance</p>
                                <Form.Check
                                  type="switch"
                                  name="stockcl"
                                  id="custom-switch"
                                  value="stockclearance"
                                  checked={stockclearnce == "stockclearance" ? true : false}
                                  onChange={(e) =>
                                    setstockclearnce(e.target.value)
                                  }
                                  style={{ fontSize: 20, }}
                                />
                              </div>
                              <div style={{ width: "30%", marginTop: "10px" }}>
                                <p style={{ marginBottom: "0.2rem" }}>Flash Deal</p>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  value="flashdeals"
                                  checked={stockclearnce == "flashdeals" ? true : false}
                                  onChange={(e) =>
                                    setstockclearnce(e.target.value)
                                  }
                                  style={{ fontSize: 20, }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {stockclearnce == "flashdeals" && (
                          <>
                            <div className="row mt-2">
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  Start Date & Time
                                </Form.Label>
                              </div>
                              <div className="col-md-4">
                                <Form.Control
                                  placeholder="Enter Start Date & Time"
                                  type="datetime-local"
                                  value={startdatetime}
                                  onChange={(e) => setstartdatetime(e.target.value)}
                                />
                                {error && error.videolink ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.videolink}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  End Date & time
                                </Form.Label>
                              </div>
                              <div className="col-md-4">
                                <Form.Control
                                  placeholder="Enter End Date & time"
                                  type="datetime-local"
                                  value={enddatetime}
                                  onChange={(e) => setenddatetime(e.target.value)}
                                />
                                {error && error.videolink ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.videolink}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {unitPrice == "range" ? (
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2 product-font">
                                Range
                              </Form.Label>
                            </div>
                            <div className="col-md-6" onClick={handleShow}>
                              <Chips
                                value={range}
                                onChange={(chip) => {
                                  handlechangerange(chip);
                                }}
                                // suggestions={["10-20", "20-30"]}
                                placeholder="Enter Range of Quantity"
                                fromSuggestionsOnly={false}
                                maxLength={3}
                                createChipKeys={[13, 32]}
                              />
                              {msg1 && (
                                <div className="text-danger">{msg1}</div>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {busidata && busidata.local_enquiry == "Yes" && range && unitPrice == "range" && (
                          range.map((data, i) => (
                            <>
                              <div className="row mt-2">
                                <div className="col-md-2">
                                  <Form.Label className="mt-2 product-font">
                                    {"Unit Price of (" + (data + " Pcs) in " + busidata.business_currency_code)}
                                  </Form.Label>
                                </div>
                                <div className="col-md-3">
                                  <Form.Control
                                    placeholder={"Unit Price of range " + data}
                                    value={unitprice[i]}
                                    onChange={(e) => handlechangeprice(e, i)}
                                  />
                                  {error && error.unit_price ? (
                                    <div className="text-danger">
                                      {" "}
                                      {error && error.unit_price}{" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <Form.Label className="mt-2 product-font">
                                    {"Purchase Price of (" + (data + " Pcs) in " + busidata.business_currency_code)}
                                  </Form.Label>
                                </div>
                                <div className="col-md-3">
                                  <Form.Control
                                    placeholder={
                                      "Purchase Price of range " + data
                                    }
                                    value={purchaseprice[i]}
                                    onChange={(e) =>
                                      handlechangepurchaseprice(e, i)
                                    }
                                  />
                                  {error && error.purchase_price + i ? (
                                    <div className="text-danger">
                                      {" "}
                                      {error && error.purchase_price + i}{" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          ))
                        )}
                        {busidata && busidata.local_enquiry == "Yes" && unitPrice == "single" && (
                          <>
                            <div className="row mt-2">
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  Unit Price({busidata.business_currency_code})
                                </Form.Label>
                              </div>
                              <div className="col-md-3">
                                <Form.Control
                                  placeholder="Enter Price per unit"
                                  value={unitprice[0]}
                                  onChange={(e) => setunitprice(e.target.value)}
                                />
                                {error && error.unit_price ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.unit_price}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  Purchase Price ({busidata.business_currency_code})
                                </Form.Label>
                              </div>
                              <div className="col-md-3">
                                <Form.Control
                                  placeholder="Purchase Price"
                                  value={purchaseprice[0]}
                                  onChange={(e) =>
                                    setpurchaseprice(e.target.value)

                                  }
                                />
                                {error && error.purchase_price ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.purchase_price}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        {busidata && busidata.business_currency_code != "USD" && busidata.global_enquiry == "Yes" && range && unitPrice == "range" && (
                          range.map((data, i) => (
                            <>
                              <div className="row mt-2">
                                <div className="col-md-2">
                                  <Form.Label className="mt-2 product-font">
                                    {"Unit Price of (" + (data + " Pcs) in " + busidata.business_global_currency)}
                                  </Form.Label>
                                </div>
                                <div className="col-md-3">
                                  <Form.Control
                                    placeholder={"Unit Price of range " + data}
                                    value={unitpriceusd[i]}
                                    onChange={(e) => handlechangepriceusd(e, i)}
                                  />
                                  {error && error.unit_price ? (
                                    <div className="text-danger">
                                      {" "}
                                      {error && error.unit_price}{" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <Form.Label className="mt-2 product-font">
                                    {"Purchase Price of (" + (data + " Pcs) in " + busidata.business_global_currency)}
                                  </Form.Label>
                                </div>
                                <div className="col-md-3">
                                  <Form.Control
                                    placeholder={
                                      "Purchase Price of range " + data
                                    }
                                    value={purchasepriceusd[i]}
                                    onChange={(e) =>
                                      handlechangepurchasepriceusd(e, i)
                                    }
                                  />
                                  {error && error.purchase_price + i ? (
                                    <div className="text-danger">
                                      {" "}
                                      {error && error.purchase_price + i}{" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </>
                          ))
                        )}
                        {busidata && busidata.business_currency_code != "USD" && busidata.global_enquiry == "Yes" && unitPrice == "single" && (
                          <>
                            <div className="row mt-2">
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  Unit Price ({busidata.business_global_currency})
                                </Form.Label>
                              </div>
                              <div className="col-md-3">
                                <Form.Control
                                  placeholder="Unit Price"
                                  value={unitpriceusd[0]}
                                  onChange={(e) => handlechangepriceusd(e, 0)}
                                />
                                {error && error.unit_price ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.unit_price}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  Purchase Price ({busidata.business_global_currency})
                                </Form.Label>
                              </div>
                              <div className="col-md-3">
                                <Form.Control
                                  placeholder="Purchase Price"
                                  value={purchasepriceusd[0]}
                                  onChange={(e) =>
                                    handlechangepurchasepriceusd(e, 0)
                                  }
                                />
                                {error && error.purchase_price ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.purchase_price}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="">
                          <div style={{ display: "flex" }}>
                            <p className="mt-1">GST</p>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              checked={taxtype == "vat" ? true : false}
                              onChange={(e) =>
                                settaxtype(e.target.checked ? "vat" : "gst")
                              }
                              style={{ fontSize: 20, marginLeft: 10 }}
                            />
                            <p className="mt-1">VAT</p>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-1">
                            <Form.Label className="mt-2 product-font">
                              Tax
                            </Form.Label>
                          </div>
                          {taxtype == "gst" ? (
                            <>
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  IGST
                                </Form.Label>
                                <Form.Control
                                  placeholder="IGST"
                                  value={igst}
                                  onChange={(e) => setigst(e.target.value)}
                                />
                                {error && error.igst ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.igst}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  SGST
                                </Form.Label>
                                <Form.Control
                                  placeholder="SGST"
                                  value={sgst}
                                  onChange={(e) => setsgst(e.target.value)}
                                />
                                {error && error.sgst ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.sgst}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-2">
                                <Form.Label className="mt-2 product-font">
                                  CGST
                                </Form.Label>
                                <Form.Control
                                  placeholder="CGST"
                                  value={cgst}
                                  onChange={(e) => setcgst(e.target.value)}
                                />
                                {error && error.cgst ? (
                                  <div className="text-danger">
                                    {" "}
                                    {error && error.cgst}{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          ) : (
                            <div className="col-md-4">
                              <Form.Label className="mt-2 product-font">
                                Vat
                              </Form.Label>
                              <Form.Control
                                placeholder="Vat"
                                value={vat}
                                onChange={(e) => setvat(e.target.value)}
                              />
                              {error && error.vat ? (
                                <div className="text-danger">
                                  {" "}
                                  {error && error.vat}{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          )}

                          <div className="col-md-4">
                            <Form.Label className="mt-2 product-font">
                              Type
                            </Form.Label>
                            <Form.Select
                              value={taxtypes}
                              onChange={(e) => settaxtypes(e.target.value)}
                            >
                              <option value="amount">Flat</option>
                              <option value="percentage">Percent</option>
                            </Form.Select>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Discount
                            </Form.Label>
                          </div>
                          <div className="col-md-6 mobile-margin-2" >
                            <Form.Control
                              placeholder="Discount"
                              value={discount}
                              onChange={(e) => setDiscount(e.target.value)}
                            />
                            {error && error.discount ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.discount}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-4">
                            <Form.Select
                              value={discounttype}
                              onChange={(e) => setDiscounttype(e.target.value)}
                            >
                              <option value="amount">Flat</option>
                              <option value="percentage">Percent</option>
                            </Form.Select>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Quantity
                            </Form.Label>
                          </div>
                          <div className="col-md-6">
                            <Form.Control
                              placeholder="Quantity"
                              value={current_stock}
                              onChange={handlecuurentstock}
                            />
                            {error && error.current_stock ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.current_stock}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Combination</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form className="varient-mobile">
                      <div className="package-table-cell">
                        <div className="row mt-2">
                          <div className="col-md-3">Variant</div>
                          {range && unitPrice == "range" ? (
                            <>
                              {busidata && busidata.local_enquiry == "Yes" && range.map((data, i) => (
                                <>
                                  <div className="col-md-2">
                                    <Form.Label>Price ({busidata.business_currency_code}) ({data} Pcs)</Form.Label>
                                  </div>
                                </>
                              ))}
                              {busidata && busidata.business_currency_code != "USD" && busidata.global_enquiry == "Yes" && range.map((data, i) => (
                                <>
                                  <div className="col-md-2">
                                    <Form.Label>Price ({busidata.business_global_currency}) ({data} Pcs)</Form.Label>
                                  </div>
                                </>
                              ))}
                            </>

                          ) : (
                            <>
                              {busidata && busidata.local_enquiry == "Yes" && unitPrice == "single" && (
                                <div className="col-md-2 varient-head-1">
                                  <Form.Label>Variant Price ({busidata.business_currency_code})</Form.Label>
                                </div>
                              )}
                              {busidata && busidata.business_currency_code != "USD" && busidata.global_enquiry == "Yes" && (
                                <div className="col-md-2 varient-head-1">
                                  <Form.Label>Variant Price ({busidata.business_global_currency})</Form.Label>
                                </div>
                              )}
                            </>

                          )}

                          <div className="col-md-2 varient-head-2">
                            <Form.Label>SKU</Form.Label>
                          </div>
                          {range && unitPrice == "range" ? (
                            <></>
                          ) : (
                            <div className="col-md-2 ">
                              <Form.Label className="varient-head-3">Quantity</Form.Label>
                            </div>
                          )}
                          <div className="col-md-2">
                            <Form.Label></Form.Label>
                          </div>
                        </div>
                      </div>

                      {comb.combinations
                        ? comb.combinations &&
                        comb.combinations.map((item, index) => (
                          <>
                            {unitPrice == "range" ? (
                              <div>
                                <div className="row mt-2 varient-inputs" key={index}>
                                  <div className="col-md-3">
                                    <Form.Label className="mt-2 product-font">
                                      {item.map((k, i) => (
                                        <> <span style={{ color: color[i].value }}>{k}</span> {item.length - 1 != i && "-"} </>
                                      ))}
                                    </Form.Label>
                                  </div>

                                  {busidata && busidata.local_enquiry == "Yes" && range.map((data, i) => (
                                    <div className="col-md-2">
                                      {i == 0 && (
                                        <Form.Control
                                          className="price"
                                          placeholder="Enter Price"
                                          ref={ref}
                                          required
                                          onChange={(e) =>
                                            handleVariantPrice(
                                              e.target.value,
                                              index,
                                              i
                                            )
                                          }
                                          key={unitprice[0]}
                                          value={variantPrice[index]}
                                          defaultValue={unitprice[i]}
                                        />
                                      )}
                                      {i == 1 && (
                                        <Form.Control
                                          className="price"
                                          placeholder="Enter Price"
                                          ref={ref}
                                          required
                                          onChange={(e) =>
                                            handleVariantPrice2(
                                              e.target.value,
                                              index,
                                              i
                                            )
                                          }
                                          key={unitprice[1]}
                                          value={variantPrice2[index]}
                                          defaultValue={unitprice[i]}
                                        />
                                      )}
                                      {i == 2 && (
                                        <Form.Control
                                          className="price"
                                          placeholder="Enter Price"
                                          ref={ref}
                                          required
                                          onChange={(e) =>
                                            handleVariantPrice3(
                                              e.target.value,
                                              index,
                                              i
                                            )
                                          }
                                          key={unitprice[2]}
                                          value={variantPrice3[index]}
                                          defaultValue={unitprice[i]}
                                        />
                                      )}
                                    </div>
                                  ))}
                                  {busidata && busidata.business_currency_code != "USD" && busidata.global_enquiry == "Yes" && range.map((data, i) => (
                                    <div className="col-md-2">
                                      {i == 0 && (
                                        <Form.Control
                                          className="price"
                                          placeholder="Enter Price"
                                          ref={ref}
                                          required
                                          onChange={(e) =>
                                            handleVariantPriceusd(
                                              e.target.value,
                                              index,
                                              i
                                            )
                                          }
                                          key={unitpriceusd[0]}
                                          value={variantPriceusd[index]}
                                          defaultValue={unitpriceusd[i]}
                                        />
                                      )}
                                      {i == 1 && (
                                        <Form.Control
                                          className="price"
                                          placeholder="Enter Price"
                                          ref={ref}
                                          required
                                          onChange={(e) =>
                                            handleVariantPriceusd2(
                                              e.target.value,
                                              index,
                                              i
                                            )
                                          }
                                          key={unitpriceusd[1]}
                                          value={variantPriceusd2[index]}
                                          defaultValue={unitpriceusd[i]}
                                        />
                                      )}
                                      {i == 2 && (
                                        <Form.Control
                                          className="price"
                                          placeholder="Enter Price"
                                          ref={ref}
                                          required
                                          onChange={(e) =>
                                            handleVariantPriceusd3(
                                              e.target.value,
                                              index,
                                              i
                                            )
                                          }
                                          key={unitpriceusd[2]}
                                          value={variantPriceusd3[index]}
                                          defaultValue={unitpriceusd[i]}
                                        />
                                      )}
                                    </div>
                                  ))}

                                  {error && error.price ? (
                                    <div className="text-danger">
                                      {" "}
                                      {error && error.price}{" "}
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="col-md-2">
                                    {/* <Form.Label>SKU</Form.Label> */}

                                    <Form.Control
                                      required
                                      placeholder="Enter SKU"
                                      onChange={(e) =>
                                        handleSKU(e.target.value, index)
                                      }
                                      key={item.join("-")}
                                      value={sku[index]}
                                      defaultValue={item.join("-")}
                                    />
                                    {error && error.sku ? (
                                      <div className="text-danger">
                                        {" "}
                                        {error && error.sku}{" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {unitPrice == "range" ? (
                                    <></>
                                  ) : (
                                    <div className="col-md-2">
                                      <Form.Control
                                        placeholder="Enter Quantity"
                                        required
                                        ref={ref1}
                                        onChange={(e) =>
                                          handleQuan(e.target.value, index)
                                        }
                                        key={current_stock}
                                        value={quan[index]}
                                        defaultValue={current_stock}

                                      />
                                    </div>
                                  )}

                                  <div
                                    className="col-md-1"
                                    onClick={handledeletevariant(index)}
                                    style={{ textAlign: "center" }}
                                  >
                                    {/* <FontAwesomeIcon  icon={faTrash} style={{ fontSize: 25, color: 'red' }} className="mt-2" /> */}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div key={index}>
                                <div className="row mt-2 varient-inputs" key={index}>
                                  <div className="col-md-3">
                                    <Form.Label className="mt-2 product-font">
                                      {item.map((k, i) => (
                                        <> <span style={{ color: color[i].value }}>{k}</span> {item.length - 1 != i && "-"} </>
                                      ))}
                                    </Form.Label>
                                  </div>
                                  {busidata && busidata.local_enquiry == "Yes" && (
                                    <div className="col-md-2">
                                      {/* <Form.Label>Variant Price</Form.Label> */}

                                      <Form.Control
                                        className="price"
                                        placeholder="Enter Price"
                                        ref={ref}
                                        required
                                        onChange={(e) =>
                                          handleVariantPrice(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        key={unitprice}
                                        // value={variantPrice[index]}
                                        defaultValue={unitprice}
                                      />
                                      {error && error.price ? (
                                        <div className="text-danger">
                                          {" "}
                                          {error && error.price}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                  {busidata && busidata.business_currency_code != "USD" && busidata.global_enquiry == "Yes" && (
                                    <div className="col-md-2">
                                      {/* <Form.Label>Variant Price</Form.Label> */}

                                      <Form.Control
                                        className="price"
                                        placeholder="Enter Price"
                                        ref={ref}
                                        required
                                        onChange={(e) =>
                                          handleVariantPriceusd(
                                            e.target.value,
                                            index
                                          )
                                        }
                                        key={unitpriceusd[0]}
                                        value={variantPriceusd[index]}
                                        defaultValue={unitpriceusd[0]}
                                      />
                                      {error && error.price ? (
                                        <div className="text-danger">
                                          {" "}
                                          {error && error.price}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                  <div className="col-md-2">
                                    {/* <Form.Label>SKU</Form.Label> */}

                                    <Form.Control
                                      placeholder="Enter SKU"
                                      required
                                      onChange={(e) =>
                                        handleSKU(e.target.value, index)
                                      }
                                      key={item.join("-")}
                                      value={sku[index]}
                                      defaultValue={item.join("-")}
                                    />
                                    {error && error.sku ? (
                                      <div className="text-danger">
                                        {" "}
                                        {error && error.sku}{" "}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {unitPrice == "range" ? (
                                    <></>
                                  ) : (
                                    <div className="col-md-2">
                                      <Form.Control
                                        required
                                        ref={ref}
                                        placeholder="Enter Quantity"
                                        onChange={(e) =>
                                          handleQuan(e.target.value, index)
                                        }
                                        key={current_stock}
                                        // key={index}
                                        // value={quan[index]}
                                        defaultValue={current_stock}
                                      />
                                      {error && error.qty ? (
                                        <div className="text-danger">
                                          {" "}
                                          {error && error.qty}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}

                                  <div
                                    className="col-md-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    {/* <FontAwesomeIcon icon={faTrash} style={{ fontSize: 25, color: 'red' }} className="mt-2" /> */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))
                        : ""}
                    </Form>
                  </div>
                </div>

                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Details</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Product Highlights
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Form.Control
                              placeholder="Product Highlights"
                              value={highlight}
                              onChange={(e) => sethighlight(e.target.value)}
                            />
                            {error && error.highlight ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.highlight}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        {/* <div className="row mt-2">
                                                    <div className="col-md-2">
                                                        <Form.Label className="mt-2 product-font">Product Short Description</Form.Label>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <Form.Control
                                                            placeholder="Product Short Description"
                                                        />
                                                    </div>

                                                </div> */}
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Product Features
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Form.Control
                              value={features}
                              placeholder="Product Features"
                              onChange={(e) => setfeatures(e.target.value)}
                            />
                            {error && error.features ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.features}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Short Description
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Editor
                              tools={[
                                [Bold, Italic, Underline, Strikethrough],
                                [Subscript, Superscript],
                                [
                                  AlignLeft,
                                  AlignCenter,
                                  AlignRight,
                                  AlignJustify,
                                ],
                                [Indent, Outdent],
                                [OrderedList, UnorderedList],
                                FontSize,
                                FontName,
                                FormatBlock,
                                [Undo, Redo],
                                [Link, Unlink, InsertImage, ViewHtml],
                                [InsertTable],
                                [
                                  AddRowBefore,
                                  AddRowAfter,
                                  AddColumnBefore,
                                  AddColumnAfter,
                                ],
                                [DeleteRow, DeleteColumn, DeleteTable],
                                [MergeCells, SplitCell],
                              ]}
                              contentStyle={{
                                height: 300,
                              }}
                              value={desc}
                              ref={body1}
                              onChange={(e) => setdesc(e.target.value)}
                            />
                            {error && error.short_description ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.short_description}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Product Detail
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Editor
                              tools={[
                                [Bold, Italic, Underline, Strikethrough],
                                [Subscript, Superscript],
                                [
                                  AlignLeft,
                                  AlignCenter,
                                  AlignRight,
                                  AlignJustify,
                                ],
                                [Indent, Outdent],
                                [OrderedList, UnorderedList],
                                FontSize,
                                FontName,
                                FormatBlock,
                                [Undo, Redo],
                                [Link, Unlink, InsertImage, ViewHtml],
                                [InsertTable],
                                [
                                  AddRowBefore,
                                  AddRowAfter,
                                  AddColumnBefore,
                                  AddColumnAfter,
                                ],
                                [DeleteRow, DeleteColumn, DeleteTable],
                                [MergeCells, SplitCell],
                              ]}
                              contentStyle={{
                                height: 300,
                              }}
                              value={det}
                              ref={body2}
                              onChange={(e) => setdet(e.target.value)}
                            />
                            {error && error.description ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.description}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Shipping Policy
                            </Form.Label>
                          </div>
                          <div className="col-md-10">
                            <Editor
                              tools={[
                                [Bold, Italic, Underline, Strikethrough],
                                [Subscript, Superscript],
                                [
                                  AlignLeft,
                                  AlignCenter,
                                  AlignRight,
                                  AlignJustify,
                                ],
                                [Indent, Outdent],
                                [OrderedList, UnorderedList],
                                FontSize,
                                FontName,
                                FormatBlock,
                                [Undo, Redo],
                                [Link, Unlink, InsertImage, ViewHtml],
                                [InsertTable],
                                [
                                  AddRowBefore,
                                  AddRowAfter,
                                  AddColumnBefore,
                                  AddColumnAfter,
                                ],
                                [DeleteRow, DeleteColumn, DeleteTable],
                                [MergeCells, SplitCell],
                              ]}
                              contentStyle={{
                                height: 300,
                              }}
                              value={pol}
                              ref={body3}
                              onChange={(e) => setpol(e.target.value)}
                            />
                            {error && error.shipping_policy ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.shipping_policy}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2 ">
                          <div className="col-md-2 ">
                            <Form.Label className="mt-2 product-font">
                              Other Detail
                            </Form.Label>
                          </div>
                          <div className="col-md-10 mobile-margin-2">
                            <Editor
                              tools={[
                                [Bold, Italic, Underline, Strikethrough],
                                [Subscript, Superscript],
                                [
                                  AlignLeft,
                                  AlignCenter,
                                  AlignRight,
                                  AlignJustify,
                                ],
                                [Indent, Outdent],
                                [OrderedList, UnorderedList],
                                FontSize,
                                FontName,
                                FormatBlock,
                                [Undo, Redo],
                                [Link, Unlink, InsertImage, ViewHtml],
                                [InsertTable],
                                [
                                  AddRowBefore,
                                  AddRowAfter,
                                  AddColumnBefore,
                                  AddColumnAfter,
                                ],
                                [DeleteRow, DeleteColumn, DeleteTable],
                                [MergeCells, SplitCell],
                              ]}
                              contentStyle={{
                                height: 300,
                              }}
                              value={othd}
                              ref={body4}
                              onChange={(e) => setothd(e.target.value)}
                            />
                            {error && error.other_detail ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.other_detail}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className=""></div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Product Shipping Cost</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div className="hide-in-mobile">
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Free Shipping
                            </Form.Label>
                          </div>
                          <div className="col-md-2">
                            <div className="text-muted mt-2">Status</div>
                          </div>
                          <div className="col-md-2">
                            <Form.Check
                              type="switch"
                              name="status"
                              value="free"
                              checked={shippingtype == "free" ? true : false}
                              id="custom-switch"
                              className="mt-2"
                              style={{ fontSize: 20 }}
                              onChange={(e) => setshippingtype(e.target.value)}
                            />
                            {error && error.shipping_type ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.shipping_type}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Flat Rate
                            </Form.Label>
                          </div>
                          <div className="col-md-2">
                            <div className="text-muted mt-2">Status</div>
                          </div>
                          <div className="col-md-2">
                            <Form.Check
                              type="switch"
                              name="status"
                              checked={
                                shippingtype == "flat_rate" ? true : false
                              }
                              onChange={(e) => setshippingtype(e.target.value)}
                              value="flat_rate"
                              id="custom-switch"
                              className="mt-2"
                              style={{ fontSize: 20 }}
                            />
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2"></div>

                          <div className="col-md-2 col-md-offset-2">
                            <div className="text-muted mt-2">
                              Shipping Price
                            </div>
                          </div>
                          <div className="col-md-4 mobile-margin-2">
                            <Form.Control
                              placeholder="Enter Shipping Price"
                              className="mt-2"
                              value={shipPrice}
                              onChange={(e) => setShipPrice(e.target.value)}
                            />
                            {error && error.flat_shipping_cost ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.flat_shipping_cost}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>PDF Specification</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              PDF Specification
                            </Form.Label>
                          </div>
                          <div className="col-md-10 mobile-margin-2">
                            <Form.Control
                              type="file"
                              id="custom-switch"
                              className="mt-2"
                              onChange={handlePdf}
                            />
                            {filep && (
                              <iframe
                                href={filep}
                                target="_blank"
                                src={filep}
                              ></iframe>
                            )}
                            {error && error.pdf ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.pdf}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>SEO Meta Tags</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Meta Title
                            </Form.Label>
                          </div>

                          <div className="col-md-10">
                            <Form.Control
                              id="custom-switch"
                              className="mt-2"
                              value={metaTitle}
                              onChange={(e) => setMetaTitle(e.target.value)}
                            />
                            {error && error.meta_title ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.meta_title}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Meta Description
                            </Form.Label>
                          </div>

                          <div className="col-md-10">
                            <Form.Control
                              as="textarea"
                              value={metaDescription}
                              rows={10}
                              placeholder="Meta Description"
                              onChange={(e) =>
                                setMetaDescription(e.target.value)
                              }
                            />
                            {error && error.meta_description ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.meta_description}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2">
                            <Form.Label className="mt-2 product-font">
                              Status
                            </Form.Label>
                          </div>

                          <div className="col-md-10 mobile-margin-2">
                            <select className="form-control" value={status} onChange={(e) => setstatus(e.target.value)}>
                              <option value="active">Active</option>
                              <option value="block">De-Active</option>
                            </select>
                            {error && error.status ? (
                              <div className="text-danger">
                                {" "}
                                {error && error.status}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                      </div>
                    </Form>
                  </div>
                </div>
                <Button
                  className="mt-2"
                  onClick={AddProduct}
                  style={{ float: "right" }}
                >
                  Save Product
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Quantity Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-2">
              <div className="col-md-2">
                <Form.Label className="mt-2 product-font">
                  Min Quantity
                </Form.Label>
              </div>

              <div className="col-md-10">
                <Form.Control
                  type="number"
                  min="0"
                  pattern="[0-9]"
                  id="custom-switch"
                  className="mt-2"
                  onChange={(e) =>
                    setminqnt(
                      parseInt(e.target.value) ? parseInt(e.target.value) : 0
                    )
                  }
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-2">
                <Form.Label className="mt-2 product-font">
                  Max Quantity
                </Form.Label>
              </div>
              <div className="col-md-10">
                <Form.Control
                  type="number"
                  min="0"
                  id="custom-switch"
                  pattern="[0-9]"
                  className="mt-2"
                  onChange={(e) =>
                    setmaxqnt(
                      parseInt(e.target.value) ? parseInt(e.target.value) : 0
                    )
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                handlechangeChipvalue();
              }}
            >
              Add
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <>
        <Modal
          size="lg"
          show={showg}
          onHide={handleCloseg}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Gallery
              <Button
                variant="primary"
                style={{ marginLeft: 30, marginRight: 12 }}
                className="ml-3"
                onClick={() => {
                  setShowsel(true);
                  setShowupl(false);
                }}
              >
                Select File
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={() => {
                  setShowsel(false);
                  setShowupl(true);
                }}
              >
                Upload New
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showsel == true && (
              <>
                <div className="row">
                  {gallery &&
                    gallery.map((item) => (
                      <div className="col-md-3 mb-3">
                        <img
                          onClick={() => handleGallary(item.id, item.file_name)}
                          style={{
                            border: "1px solid",
                            borderRadius: "5px",
                            height: 150,
                            width: 150,
                          }}
                          variant="top"
                          src={IMAGE_BASE_URL + item.file_name}
                        />
                        <h6>
                          {item.file_original_name.slice(0, 10) +
                            "..." +
                            "." +
                            item.extension}
                        </h6>
                        <p>{Math.round(item.file_size / 1024) + "KB"}</p>
                      </div>
                    ))}
                </div>
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlegalleryClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
            {showupl == true && (
              <>
                <div className="col-md-10">
                  <Form.Control
                    type="file"
                    id="custom-switch"
                    className="mt-2"
                    onChange={(e) => handleuploadImg(e)}
                  />
                  {file5 && (
                    <img
                      style={{ height: 100, width: 100, marginTop: 6 }}
                      src={file5}
                    />
                  )}
                  {error && error.meta_img ? (
                    <div className="text-danger">
                      {" "}
                      {error && error.meta_img}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseg}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleCloseg}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          size="lg"
          show={showt}
          onHide={handleCloset}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Gallery
              <Button
                variant="primary"
                style={{ marginLeft: 30, marginRight: 12 }}
                className="ml-3"
                onClick={() => {
                  setShowsel(true);
                  setShowupl(false);
                }}
              >
                Select File
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={() => {
                  setShowsel(false);
                  setShowupl(true);
                }}
              >
                Upload New
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showsel == true && (
              <>
                <div className="row">
                  {gallery &&
                    gallery.map((item) => (
                      <div className="col-md-3 mb-3">
                        <img
                          onClick={() =>
                            handleThumbnail(item.id, item.file_name)
                          }
                          style={{
                            border: "1px solid",
                            borderRadius: "5px",
                            height: 150,
                            width: 150,
                          }}
                          variant="top"
                          src={IMAGE_BASE_URL + item.file_name}
                        />
                        <h6>
                          {item.file_original_name.slice(0, 10) +
                            "..." +
                            "." +
                            item.extension}
                        </h6>
                        <p>{Math.round(item.file_size / 1024) + "KB"}</p>
                      </div>
                    ))}
                </div>
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlegalleryClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
            {showupl == true && (
              <>
                <div className="col-md-10">
                  <Form.Control
                    type="file"
                    id="custom-switch"
                    className="mt-2"
                    onChange={(e) => handleuploadImg(e)}
                  />
                  {file5 && (
                    <img
                      style={{ height: 100, width: 100, marginTop: 6 }}
                      src={file5}
                    />
                  )}
                  {error && error.meta_img ? (
                    <div className="text-danger">
                      {" "}
                      {error && error.meta_img}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloset}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      <>
        <Modal
          size="lg"
          show={showm}
          onHide={handleClosem}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Gallery
              <Button
                variant="primary"
                style={{ marginLeft: 30, marginRight: 12 }}
                className="ml-3"
                onClick={() => {
                  setShowsel(true);
                  setShowupl(false);
                }}
              >
                Select File
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={() => {
                  setShowsel(false);
                  setShowupl(true);
                }}
              >
                Upload New
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showsel == true && (
              <>
                <div className="row">
                  {gallery &&
                    gallery.map((item) => (
                      <div className="col-md-3 mb-3">
                        <img
                          onClick={() => handleMetaImg(item.id, item.file_name)}
                          style={{
                            border: "1px solid",
                            borderRadius: "5px",
                            height: 150,
                            width: 150,
                          }}
                          variant="top"
                          src={IMAGE_BASE_URL + item.file_name}
                        />
                        <h6>
                          {item.file_original_name.slice(0, 10) +
                            "..." +
                            "." +
                            item.extension}
                        </h6>
                        <p>{Math.round(item.file_size / 1024) + "KB"}</p>
                      </div>
                    ))}
                </div>
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlegalleryClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
            {showupl == true && (
              <>
                <div className="col-md-10">
                  <Form.Control
                    type="file"
                    id="custom-switch"
                    className="mt-2"
                    onChange={(e) => handleuploadImg(e)}
                  />
                  {file5 && (
                    <img
                      style={{ height: 100, width: 100, marginTop: 6 }}
                      src={file5}
                    />
                  )}
                  {error && error.meta_img ? (
                    <div className="text-danger">
                      {" "}
                      {error && error.meta_img}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosem}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleClosem}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showbr}
          onHide={handleClosebr}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Gallery
              <Button
                variant="primary"
                style={{ marginLeft: 30, marginRight: 12 }}
                className="ml-3"
                onClick={() => {
                  setShowsel(true);
                  setShowupl(false);
                }}
              >
                Select File
              </Button>
              <Button
                variant="primary"
                className="ml-3"
                onClick={() => {
                  setShowsel(false);
                  setShowupl(true);
                }}
              >
                Upload New
              </Button>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showsel == true && (
              <>
                <div className="row">
                  {gallery &&
                    gallery.map((item) => (
                      <div className="col-md-3 mb-3">
                        <img
                          onClick={() => handleBrandImg(item.id, item.file_name)}
                          style={{
                            border: "1px solid",
                            borderRadius: "5px",
                            height: 150,
                            width: 150,
                          }}
                          variant="top"
                          src={IMAGE_BASE_URL + item.file_name}
                        />
                        <h6>
                          {item.file_original_name.slice(0, 10) +
                            "..." +
                            "." +
                            item.extension}
                        </h6>
                        <p>{Math.round(item.file_size / 1024) + "KB"}</p>
                      </div>
                    ))}
                </div>
                <ReactPaginate
                  previousLabel={"previous"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  pageCount={page}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlegalleryClick}
                  containerClassName={"pagination justify-content-center"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </>
            )}
            {showupl == true && (
              <>
                <div className="col-md-10">
                  <Form.Control
                    type="file"
                    id="custom-switch"
                    className="mt-2"
                    onChange={(e) => handleuploadImg(e)}
                  />
                  {file5 && (
                    <img
                      style={{ height: 100, width: 100, marginTop: 6 }}
                      src={file5}
                    />
                  )}
                  {error && error.meta_img ? (
                    <div className="text-danger">
                      {" "}
                      {error && error.meta_img}{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosebr}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={handleClosem}>
            Save Changes
          </Button> */}
          </Modal.Footer>
        </Modal>
        <Modal show={showu} onHide={() => setShowu(false)}>
          <Modal.Header closeButton>
            Add Unit
          </Modal.Header>
          <Modal.Body>

            <div className="mt-2 mb-4">
              <Form.Label>Unit</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setNewUnit(e.target.value)}
              />
              {err && err.unit ? <div className="text-danger"> {err && err.unit} </div> : ''}
            </div>

          </Modal.Body>

          <Modal.Footer>
            <Button onClick={AddUnit}>Add</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showb} onHide={() => setShowb(false)}>
          <Modal.Header closeButton>
            Add Brand
          </Modal.Header>
          <Modal.Body>

            <div className="mt-2 mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setbrandname(e.target.value)}
              />
              {errb && errb.name ? <div className="text-danger"> {errb && errb.name} </div> : ''}
            </div>
            <div className="mt-2 mb-4" onClick={handleShowbr}>
              <Form.Label>Logo</Form.Label>
              <Form.Control
                type="file"
                onClick={handleShowbr}
                disabled
                onChange={(e) => { e.preventDefault(); handleShowbr() }}
              // onChange={handlebrandlogo}
              />
              {filebr && (
                <img
                  style={{
                    height: 100,
                    width: 100,
                    marginTop: 6,
                  }}
                  src={filebr}
                />
              )}
              {errb && errb.logo ? <div className="text-danger"> {errb && errb.logo} </div> : ''}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button onClick={AddBrand}>Add</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showc} onHide={() => setShowc(false)}>
          <Modal.Header closeButton>
            Add category
          </Modal.Header>
          <Modal.Body>
            <Form.Label>Parent Category</Form.Label>
            <Form.Select
              onChange={(e) => setparentcategory(e.target.value)}
            >
              <option value="0">No Parent</option>
              {categories &&
                categories.map((item) => (
                  <>
                    <option value={item.value}>{item.label}</option>

                  </>
                ))}
            </Form.Select>
            <div className="mt-2 mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setcategoryname(e.target.value)}
              />
              {errc && errc.name ? <div className="text-danger"> {errc && errc.name} </div> : ''}
            </div>

          </Modal.Body>

          <Modal.Footer>
            <Button onClick={Addcategory}>Add</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showattr} onHide={() => setShowattr(false)}>
          <Modal.Header closeButton>
            Add Attribute
          </Modal.Header>
          <Modal.Body>

            <div className="mt-2 mb-4">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setattributename(e.target.value)}
              />
              {errattr && errattr.name ? <div className="text-danger"> {errattr && errattr.name} </div> : ''}
            </div>

          </Modal.Body>

          <Modal.Footer>
            <Button onClick={ADDAttribute}>Add</Button>
          </Modal.Footer>
        </Modal>
      </>
      <ToastContainer />
    </Page>
  );
};

export default ManageProfile;
