import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import ProductStarRating from "../components/product-star/product-star-rating.component";
import { Link, NavLink } from "react-router-dom";
import { Button, Form, Table } from "react-bootstrap";
import Page from "../components/Page";
import ProductImg from "../components/gallery/image6.svg";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
import { ToastContainer,toast } from "react-toastify";
import ReactStars from "react-stars";
import truncHtml from "trunc-html";
const VendorReviewFeedback = () => {
    const [data,setdata] = useState([]);
    const [readmore,setreadmore] = useState("");
  const token = localStorage.getItem('avonToken');
  useEffect(() => {
    Fetchdata();
  }, [])
  const Fetchdata = async () => {
    await axios
      .get(BASE_URL + "/getsellerreview", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setdata(data.data);

        
        
      });
  };
  const handlepublished = async (id) => {
    await axios.get(BASE_URL + '/updatestatussellerreview/'+id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
        Fetchdata();
      if (data.status == true) {
        toast.success(data.message)

      }
    })
  }
  return (
    <Page title="Product Review">
    <div className="inner-pages-wrapper">
      <section className="breadcrumb-sction">
        <div className="container-fluid">
          <ul className="breadcrumb-block">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>Manage Review & Feedback</li>
          </ul>
          {/* <div className="col-md-12">
          <Button className="dashboard-btn" href="/dashboard" variant="primary">
              Customer Dashboard
            </Button>
            </div> */}
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <VendorDashboardSideMenu />
            <div className="col-md-9">
              <h4>Manage Review & Feedback</h4>

              <section>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-block">
                      <div className="order-head-blk">
                        <h5>Manage Review & Feedback</h5>
                      </div>
                      <div className="card-body-blk">
                        <Table responsive="md">
                          <thead>
                            <tr>
                              <th>Date & Time</th>
                              <th>Rating</th>
                              <th>Feedback</th>
                              <th>Image</th>
                              <th>Published</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.map((item,index)=>(
                              <tr key={index}>
                              <td>
                              {item.created_at}
                              </td>
                              <td > <ReactStars
                               count={5}
                                value={item.rating}
                                size={20}
                                edit={false}
                                color2={'#ffd700'} /></td>
                              <td >
                              <div class="card-text" dangerouslySetInnerHTML={{__html:readmore === index ? item.feedback : truncHtml(item.feedback, 80).html}}></div>
                              {item.feedback.length > 80 &&(<Link to="#" onClick={()=>setreadmore(readmore === index ? "" : index)}>{ readmore === index ? " Read Less " : " Read More"}</Link>)}
                              </td>
                              <td>
                              <img src={IMAGE_BASE_URL+item.image} height={100} />
                              </td>
                              <td>
                              {item.establish_year}
                              </td>
                              
                              <td>
                              <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  style={{ fontSize: 20 }}
                                  checked={item.status == "active" ? true : false}
                                  onChange={(e) => handlepublished(item.ids)}
                                />
                              </td>
                            </tr>
                            ))}
                            
                           
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
    <ToastContainer />
  </Page>
  )
}

export default VendorReviewFeedback