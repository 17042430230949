import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { YearPicker } from 'react-dropdown-date';


const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const VendorHomePageWidget = ({ props }) => {

  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem('avonToken');
  const [data, setdata] = useState('');
  const [widget1, setwidget1] = useState('');
  const [widget2, setwidget2] = useState('');
  const [widget3, setwidget3] = useState('');
  const [widget4, setwidget4] = useState('');
  const [widget5, setwidget5] = useState('');
  const [widget6, setwidget6] = useState('');
  const [widget7, setwidget7] = useState('');
  const [widget8, setwidget8] = useState('');
  const [widget9, setwidget9] = useState('');
  const [widget10, setwidget10] = useState('');
  const [widget11, setwidget11] = useState('');
  const [widget12, setwidget12] = useState('');
  const [resError, setResError] = useState([]);
  const [edit, setEdit] = useState(false);


  useEffect(() => {
    Fetchhomepagewidget();
  }, [])

  const Fetchhomepagewidget = async () => {
    await axios.get(BASE_URL + '/gethomepagewidget', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
        setdata(data.data)
        setwidget1(data.data.widget1)
        setwidget2(data.data.widget2)
        setwidget3(data.data.widget3)
        setwidget4(data.data.widget4)
        setwidget5(data.data.widget5)
        setwidget6(data.data.widget6)
        setwidget7(data.data.widget7)
        setwidget8(data.data.widget8)
        setwidget9(data.data.widget9)
        setwidget10(data.data.widget10)
        setwidget11(data.data.widget11)
        setwidget12(data.data.widget12)
      // console.log("profile", data.data)

    })
  }
  

  
  

 
 
  

  const Updatehomepagewidget = async () => {
    setResError("")
    
    const datas = {
      'widget1': widget1,
      'widget2': widget2,
      'widget3': widget3,
      'widget4': widget4,
      'widget5': widget5,
      'widget6': widget6,
      'widget7': widget7,
      'widget8': widget8,
      'widget9': widget9,
      'widget10': widget10,
      'widget11': widget11,
      'widget12': widget12,
    }    
    
    await axios.post(BASE_URL + '/updatehomepagewidget', datas, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
        Fetchhomepagewidget();
      setEdit(false)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    })
      .catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setResError(response.data.message)
        }
      })
  }
  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Mange HomePage Widget</li>
            </ul>
            <div className="col-md-12 float-right">
              <Button className="" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>
                    HomePage Widget  <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}> <FontAwesomeIcon icon={faEdit} /></Button>
                  </h4>
                </div>



                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>HomePage Widget</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                      <div className="row">
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget1</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget1(e.target.value)}
                            value={widget1}
                            disabled={data && data.widget1 && !edit ? true : false}
                          />
                          {resError && resError.widget1 ? <div className="text-danger"> {resError && resError.widget1} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget2</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget2(e.target.value)}
                            value={widget2}
                            disabled={data && data.widget2 && !edit ? true : false}
                          />
                          {resError && resError.widget2 ? <div className="text-danger"> {resError && resError.widget2} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget3</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget3(e.target.value)}
                            value={widget3}
                            disabled={data && data.widget3 && !edit ? true : false}
                          />
                          {resError && resError.widget3 ? <div className="text-danger"> {resError && resError.widget3} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget4</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget4(e.target.value)}
                            value={widget4}
                            disabled={data && data.widget4 && !edit ? true : false}
                          />
                          {resError && resError.widget4 ? <div className="text-danger"> {resError && resError.widget4} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget5</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget5(e.target.value)}
                            value={widget5}
                            disabled={data && data.widget5 && !edit ? true : false}
                          />
                          {resError && resError.widget5 ? <div className="text-danger"> {resError && resError.widget5} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget6</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget6(e.target.value)}
                            value={widget6}
                            disabled={data && data.widget6 && !edit ? true : false}
                          />
                          {resError && resError.widget6 ? <div className="text-danger"> {resError && resError.widget6} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget7</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget7(e.target.value)}
                            value={widget7}
                            disabled={data && data.widget7 && !edit ? true : false}
                          />
                          {resError && resError.widget1 ? <div className="text-danger"> {resError && resError.widget1} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget8</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget8(e.target.value)}
                            value={widget8}
                            disabled={data && data.widget8 && !edit ? true : false}
                          />
                          {resError && resError.widget8 ? <div className="text-danger"> {resError && resError.widget8} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget9</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget9(e.target.value)}
                            value={widget9}
                            disabled={data && data.widget9 && !edit ? true : false}
                          />
                          {resError && resError.widget9 ? <div className="text-danger"> {resError && resError.widget9} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget10</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget10(e.target.value)}
                            value={widget10}
                            disabled={data && data.widget10 && !edit ? true : false}
                          />
                          {resError && resError.widget10 ? <div className="text-danger"> {resError && resError.widget10} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget11</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget11(e.target.value)}
                            value={widget11}
                            disabled={data && data.widget11 && !edit ? true : false}
                          />
                          {resError && resError.widget11 ? <div className="text-danger"> {resError && resError.widget11} </div> : ''}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Widget12</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Data"
                            onChange={(e) => setwidget12(e.target.value)}
                            value={widget12}
                            disabled={data && data.widget12 && !edit ? true : false}
                          />
                          {resError && resError.widget12 ? <div className="text-danger"> {resError && resError.widget12} </div> : ''}
                        </div>
                        
                        <div className="col-md-12">
                          <Button
                            style={{ float: "right", marginTop: "8px", color: '#fff' }}
                            variant="warning"
                            onClick={Updatehomepagewidget}
                          >
                            Update
                          </Button>
                        </div>
                      </div>

                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>
        <ToastContainer />
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default VendorHomePageWidget;
