import React from "react";
import { Carousel } from 'react-responsive-carousel';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import ReactStars from "react-stars";

const RecentView = () => {
    const recent_options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        loop: true,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 3,
            },
            1000: {
                items: 5.3,
            },
        },
    };
    

    const products = [
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        },
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        },
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        },
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        },
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        },
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        },
        {
            name: 'Black+Decker 4 Inch 820W Small Angle',
            cate1: "/assets/images/cate-1.svg"
        }
    ]


    return (
        <>
            <div className="container mt-3">
                <div className="slider-section">
                    <h6 className="cate-head mb-4 heading-bline position-relative">Recent Viewed</h6>
                    <div className="">
                        <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
                            {products.map((item, index) => (
                                <div className="feature-products-cards">
                                    <img src="/assets/images/hat.png" />
                                    <div className="" style={{ padding: "10px" }}>
                                        <h3 className='product-head'>{item.name}</h3>
                                        <ReactStars
                                            count={5}
                                            size={24}
                                            color2={'#ffd700'} />
                                        <div className="currency-section">
                                            <p className="currency-symbol-offer">$99</p>
                                            <p className="currency-symbol-price">$89</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RecentView;