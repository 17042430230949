import axios from 'axios';
import React,{useState, useEffect} from 'react'
import { BASE_URL } from '../../../config';

export const ApnashaherSecond = () => {
    const[allwidget, setAllwidget] = useState([]);
    
 // Get widget data from api

  const widgetData = async () => {
   
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    widgetData();
  }, []);


  return (
    <>
    <div className="apnashaher-second">
    <div className='empower-bussiness-content  container mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget9}} >
    {/* <div className='empower-bussiness-content mb-4' > */}
                {/* <h1>Empowering businesses through global trade</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.</p> */}
            </div>
    </div>
    </>
  )
}
