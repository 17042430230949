import React from "react";
import { Carousel } from "react-responsive-carousel";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IMAGE_BASE_URL } from "../../../config";
import { Link } from "react-router-dom";
const TruncHtml = require('trunc-html');
const ProfessionalClient = ({heading,data}) => {
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 2.1,
      },

      800: {
        items: 2.2,
      },
      1000: {
        items: 2.4,
      },
      1199: {
        items: 4.5,
      },
      1250: {
        items: 6.5,
      },
    },
  };
  const Categories = [
    {
      name: "Ear buds",
      quantity: "4",
      cate1: "/assets/images/client-logo-1.png",
      cate2: "/assets/images/client-img-1.png",
    },
    {
      name: "New Books",
      quantity: "2",
      cate1: "/assets/images/client-logo-2.png",
      cate2: "/assets/images/client-img-1.png",

    },
    {
      name: "Ear buds",
      quantity: "4",
      cate1: "/assets/images/client-logo-3.png",
      cate2: "/assets/images/client-img-1.png",

    },
    {
      name: "New Books",
      quantity: "2",
      cate1: "/assets/images/client-logo-4.png",
      cate2: "/assets/images/client-img-1.png",

    },
    {
      name: "Ear buds",
      quantity: "4",
      cate1: "/assets/images/client-logo-2.png",
      cate2: "/assets/images/client-img-1.png",

    },
    {
      name: "New Books",
      quantity: "2",
      cate1: "/assets/images/client-logo-4.png", 
      cate2: "/assets/images/client-img-1.png",

    },
    {
      name: "Ear buds",
      quantity: "4",
      cate1: "/assets/images/client-logo-3.png",
      cate2: "/assets/images/client-img-1.png",

    },
  ];

  return (
    <>
      <div className="container my-lg-5 my-md-5 my-4">
        <div className="row">
          <div className="col-md-12">
            <div className="new-arrivals service-nr prf-client">
              <div className="slider-section">
                <h6 className="cate-head mb-3">{heading} </h6>
                <ReactOwlCarousel
                  className="owl-theme owl-feature-slider owl-slider-arrows"
                  {...recent_options}
                >
                
                  <div className="d-flex gap-2 flex-column hr-2row py-1">
                  {/* {data?.data?.map((product) => (
                    
                      <div className="text-center">
                        <div className="prof-img">
                          <img src={IMAGE_BASE_URL+product.logo} />
                        </div>
                     
                      </div>
                     
                      ))} */}
                 
                 
                      {data?.datas?.map((product) => (
                    
                      <div className="card  px-2 py-2 bg-white rounded border-0">
                        <img src={IMAGE_BASE_URL+product.logo} className="card-img-top" alt="..." />
                        <div className="card-body px-1 py-1">
                          <h5 className="card-title mt-0">{product.name}</h5>
                          <p className="card-text mb-2">
                          <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: TruncHtml(product.comp_intr, 150).html }}></div>
                          </p>
                          <Link to={"/seller-detail/" + product.slug} className="btn bg-none px-0 fs-8 text-primary link-underline-primary">
                            Read More
                          </Link>
                        </div>
                      </div>
                      
                   
                  ))}
                  </div>
                </ReactOwlCarousel>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="new-arrivals service-nr prf-client">
              <div className="slider-section">
                <h6 className="cate-head mb-3">Digital Client </h6>
                <ReactOwlCarousel
                  className="owl-theme owl-feature-slider owl-slider-arrows"
                  {...recent_options}
                >
                  {Categories.map((product) => (
                    <div className="d-flex gap-2 flex-column hr-2row py-1">
                      <div className="text-center">
                        <div className="prof-img">
                          <img src={product.cate1} />
                        </div>
                      </div>
                    
                      <div className="card  px-2 py-2 bg-white rounded border-0">
                        <img src={product.cate2} className="card-img-top" alt="..." />
                        <div className="card-body px-1 py-1">
                          <h5 className="card-title mt-0">Name lorem</h5>
                          <p className="card-text mb-2">
                            Some quick examp...
                          </p>
                          <a href="#" className="btn bg-none px-0 fs-8 text-primary link-underline-primary">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </ReactOwlCarousel>
              </div>
            </div>
          </div> */}
        
         
        </div>
      </div>
      {/* <div className="container-fluid mt-6 no-padding">
                <img src="/assets/images/home-section-5.png" width="100%"/>
            </div> */}
    </>
  );
};

export default ProfessionalClient;
