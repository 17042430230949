import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import ProductStarRating from "../components/product-star/product-star-rating.component";
import { NavLink } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Page from "../components/Page";
import ProductImg from "../components/gallery/image6.svg";
import SwitchIcon from "../components/product/ProductSwitch.svg";

const ProductReview = () => {
  return (
    <Page title="Product Review">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Product Reviews</li>
            </ul>
            <div className="col-md-12">
            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
              </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Product Reviews</h4>

                <section>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-block">
                        <div className="order-head-blk">
                          <h5>Product Reviews</h5>
                        </div>
                        <div className="card-body-blk">
                          <Table responsive="md">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Customer</th>
                                <th>Rating</th>
                                <th>Comment</th>
                                <th>Published</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="downprod-img">
                                    <img
                                      src={ProductImg}
                                      alt=""
                                    />
                                  </div>
                                  <div className="downprod-content">
                                    <p>
                                      AgriPro 4 Stroke Water Pump 3 Inch APWP3
                                    </p>
                                    <p>Price: $210.00 <span style={{float: 'right'}}>QTY: 1</span></p>
                               
                                  </div>
                                </td>
                                <td style={{ paddingTop: "22px" }}>User 1</td>
                                <td style={{ paddingTop: "22px" }}>
                                  <ProductStarRating />
                                </td>
                                <td style={{ paddingTop: "22px" }}>
                                  Lorem Ipsum is simply dummy text.
                                </td>
                                <td>
                                  <img style={{paddingTop: '15px'}} src={SwitchIcon} />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="downprod-img">
                                    <img
                                      src={ProductImg}
                                      alt=""
                                    />
                                  </div>
                                  <div className="downprod-content">
                                    <p>
                                      AgriPro 4 Stroke Water Pump 3 Inch APWP3
                                    </p>
                                    <p>Price: $210.00 <span style={{float: 'right'}}>QTY: 1</span></p>
                                  </div>
                                </td>
                                <td style={{ paddingTop: "22px" }}>User 1</td>
                                <td style={{ paddingTop: "22px" }}>
                                  <ProductStarRating />
                                </td>
                                <td style={{ paddingTop: "22px" }}>
                                  Lorem Ipsum is simply dummy text.
                                </td>
                                <td>
                                  <img style={{paddingTop: '15px'}} src={SwitchIcon} />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <div className="downprod-img">
                                    <img
                                      src={ProductImg}
                                      alt=""
                                    />
                                  </div>
                                  <div className="downprod-content">
                                    <p>
                                      AgriPro 4 Stroke Water Pump 3 Inch APWP3
                                    </p>
                                    <p>Price: $210.00 <span style={{float: 'right'}}>QTY: 1</span></p>
                                  </div>
                                </td>
                                <td style={{ paddingTop: "22px" }}>User 1</td>
                                <td style={{ paddingTop: "22px" }}>
                                  <ProductStarRating />
                                </td>
                                <td style={{ paddingTop: "22px" }}>
                                  Lorem Ipsum is simply dummy text.
                                </td>
                                <td>
                                  <img style={{paddingTop: '15px'}} src={SwitchIcon} />
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};
export default ProductReview;
