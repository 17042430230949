import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';



const VendorAddPages = ({ props }) => {



    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
    } = EditorTools;

    const body1 = React.createRef();
    const [aboutUs, setAboutUs] = useState('');
    const [image, setimage] = useState('');
    const [on_heder, setOnHeader] = useState(false);
    const [headerStatus, setheaderStatus] = useState('no');
    const [on_footer, setOnFooter] = useState(false);
    const [footerStatus, setFooterStatus] = useState('no');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [statusD, setStatusD] = useState('block');
    const [slug, setSlug] = useState('');
    const [file, setfile] = useState('');
    const [menuname, setmenuname] = useState('');
    const [error, setError] = useState('');
    const token = localStorage.getItem('avonToken');
    const [stat, setStat] = useState();
    const [statvl, setStatVl] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    console.log(statusD)
    const handletitle = (e) => {
        setTitle(e.target.value);
        var b = e.target.value.toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, '')
            .replace(/[\s_-]+/g, '-')
            .replace(/^-+|-+$/g, '');
        setSlug(b)
    }

    const handleStatus = (e) => {
        // setStat('gtc', e.target.checked)
        setStatVl(!statvl)
        setStatusD(e.target.checked ? "active" : "block")
    }

    const handleOnHeader = (e) => {
        setOnHeader(!on_heder)
        setheaderStatus(e.target.checked ? "yes" : "no")
    }

    const handleOnFooter = (e) => {
        setOnFooter(!on_footer)
        setFooterStatus(e.target.checked ? "yes" : "no")
    }


    const handlechangeimage = (e) => {
        setimage(e.target.files[0])
        setfile(URL.createObjectURL(e.target.files[0]))
    }

    const Updateaboutus = async () => {
        setError("")
        const view1 = body1.current.view;
        var content = EditorUtils.getHtml(view1.state);
        var data = new FormData();
        data.append('image', image);
        data.append('content', content);
        data.append('title', title);
        data.append('slug', slug);
        data.append('menu_name', menuname);
        data.append('on_heder', headerStatus);
        data.append('on_footer', footerStatus);
        data.append('status', statusD);
        await axios.post(BASE_URL + '/addupdatesellerpage', data, {
            headers: {
                // 'content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            if (data.status == true) {
                toast.success(data.message)
                navigate('/other-pages')
            }

        }).catch(({ response }) => {
            console.log(response.data)
            if (response.data.status_code == 422) {
                setError(response.data.message)
            }
        })
    }
    return (
        <Page title="Manage Profile">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Manage Profile</li>
                            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                                Customer Dashboard
                            </Button>

                        </ul>
                    </div>
                </section>

                <section className="mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <VendorDashboardSideMenu />

                            <div className="col-md-9">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4>
                                        Add Page
                                    </h4>
                                </div>
                                <div className="product-card-block">
                                    <div className="card-body-blk">
                                        <div className="col-md-12 mt-2">
                                            <Form.Label>Title</Form.Label>
                                            <Form.Control type="text" onChange={handletitle} value={title} />
                                            {error.title && (
                                                <div className="text-danger">{error.title}</div>
                                            )}
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <Form.Label>Menu Name</Form.Label>
                                            <Form.Control type="text" onChange={(e) => setmenuname(e.target.value)} value={menuname} />
                                            {error.menu_name && (
                                                <div className="text-danger">{error.menu_name}</div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mt-2">
                                                <Form.Label>Content</Form.Label>
                                                <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                                                    height: 300
                                                }}
                                                    value={aboutUs}
                                                    ref={body1}
                                                    onChange={(e) => setAboutUs(e.target.value)}
                                                />
                                                {error.content && (
                                                    <div className="text-danger">{error.content}</div>
                                                )}
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row">
                                                    <div className="col-md-2 mb-2 mt-2">
                                                        <Form.Label className="mt-2">Image</Form.Label>
                                                    </div>
                                                    <div className="col-md-10 mb-2 mt-2">
                                                        <Form.Control type="file" onChange={handlechangeimage} />
                                                        {file && (
                                                            <img src={file} height={100} width={100} />
                                                        )}
                                                        {error.image && (
                                                            <div className="text-danger">{error.image}</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hide-in-mobile">
                                                <div className="row">
                                                    <div className="col-md-3 mt-2">
                                                        <Form.Label>Slug</Form.Label>
                                                        <Form.Control type="text" onChange={(e) => setSlug(e.target.value)} value={slug} />
                                                        {error.content && (
                                                            <div className="text-danger">{error.content}</div>
                                                        )}
                                                    </div>
                                                    <div className="col-md-3 mt-2">

                                                        <Form.Check type="switch" onChange={handleOnHeader} label="On Header" style={{ marginTop: 40 }} />
                                                    </div>
                                                    <div className="col-md-3 mt-2">
                                                        <Form.Check type="switch" onChange={handleOnFooter} label="On Footer" style={{ marginTop: 40 }} />
                                                    </div>
                                                    <div className="col-md-3 mt-2">
                                                        <Form.Label className="" style={{ display: 'flex', marginTop: 40 }}>
                                                            Status
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                // label="Closed?"
                                                                onChange={handleStatus}
                                                                style={{ marginLeft: 10 }}
                                                            />
                                                        </Form.Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hide-in-desktop">
                                                <div className="col-md-3 mt-2">
                                                    <Form.Label>Slug</Form.Label>
                                                    <Form.Control type="text" onChange={(e) => setSlug(e.target.value)} value={slug} />
                                                    {error.content && (
                                                        <div className="text-danger">{error.content}</div>
                                                    )}
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="col-md-3 mt-2">
                                                        <Form.Label>On Header</Form.Label>
                                                        <Form.Check type="switch" onChange={handleOnHeader} />
                                                    </div>
                                                    <div className="col-md-3 mt-2">
                                                        <Form.Label>On Footer</Form.Label>
                                                        <Form.Check type="switch" onChange={handleOnFooter} />
                                                    </div>
                                                    <div className="col-md-3 mt-2">
                                                        <Form.Label className="">Status</Form.Label>
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            // label="Closed?"
                                                            onChange={handleStatus}
                                                            
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <Button
                                                    style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                                    variant="warning"
                                                    onClick={Updateaboutus}
                                                >
                                                    Add
                                                </Button>
                                            </div>
                                            <ToastContainer />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </Page>
    );
};

export default VendorAddPages;
