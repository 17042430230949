import React, { useEffect, useState,useContext } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Axios from 'axios';

import Page from '../../../components/Page';

import 'bootstrap/dist/css/bootstrap.min.css';
import SidebarFilterContainer from '../../../components/sidebar-filter/sidebar-filter-container.component';
import StateContext from '../../../context/StateContext';
import ProductGridContainer from '../../../components/product/product-grid-container.component';
import PaginationLink from '../../../components/common/pagination-link.component';
import Products from './components/Products';
import HeaderFilter from './components/HeaderFilter';


const StockClearence = () => {
  const [searchParams] = useSearchParams();
  const search = searchParams.get('search');
  const [isLoading, setIsLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [attributes, setAttributes] = useState([]);
  const [colors, setColors] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productCount, setProductCount] = useState('0 products');
  const [links, setLinks] = useState({});
  const [categoryIds, setCategoryIds] = useState([]);
  const [attributeIds, setAttributeIds] = useState([]);
  const [sortBy, setSortBy] = useState();
  const { locationdata, setlocationdata,countrycity,setgcurrency, setcountrycity,currencysymbol,setcurrencysymbol } = useContext(StateContext);
const {slug} = useParams();
  useEffect(() => {
    setPageNumber(1);
  }, [categoryIds, attributeIds]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const productRequest = Axios.CancelToken.source();
    async function fetchCategoryProducts() {
      try {
        setIsLoading(true);
        const response = await Axios.post(`/stockclearnaceproduct`, { slug:slug,categoryIds, sortBy, attributeIds, search,"countrycity":countrycity,"countrycityold":locationdata.country_name,locationdata:locationdata.currency,currencysymbol }, { cancelToken: productRequest.token });
        const linkData = response.data;
        const responseData = linkData.data;
        setAttributes(responseData.attributes);
        setColors(responseData.colors);
        setProducts(responseData.products);
        setCategories(responseData.side_panel_categories);
        setProductCount(responseData.productTotal);
        setLinks(linkData.links);
        setgcurrency(responseData.currency.code)
        setcurrencysymbol(responseData.currency.symbol)
        setIsLoading(false);
      } catch (e) {}
    }
    fetchCategoryProducts();
    return () => {
      productRequest.cancel();
    };
  }, [categoryIds, sortBy,currencysymbol, attributeIds, pageNumber, search,countrycity]);

  return (
    <Page title="Shop">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Stock Clearance</li>
            </ul>
          </div>
        </section>
        <section className="shop-main-section">
          <Container fluid>
            <Row>
              <Col md={3} className='mobile-filters'>
                <SidebarFilterContainer categories={categories} colors={colors} attributes={attributes} categoryIds={categoryIds} setCategoryIds={setCategoryIds} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
                <HeaderFilter isLoading={isLoading} title="All Products" products={products} productCount={productCount} setSortBy={setSortBy} />
              </Col>
              <Col md={9}>
                {/* <h1>Hello</h1> */}
                <Products isLoading={isLoading} title="All Products" products={products} productCount={productCount} setSortBy={setSortBy}/>
                {/* <ProductGridContainer isLoading={isLoading} title="All Products" products={products} productCount={productCount} setSortBy={setSortBy} /> */}
                <PaginationLink links={links} setPageNumber={setPageNumber} />

              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Page>
  );
};
export default StockClearence;
