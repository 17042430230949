import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';


const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const ManageProfile = ({ props }) => {

  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem('avonToken');
  const [data, setData] = useState();
  const [category, setCategory] = useState([]);
  const [businessName, setBusinessName] = useState();
  const [businessType, setBusinessType] = useState();
  const [businessNature, setBusinessNature] = useState();
  const [regNo, setRegNo] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [whatsappNo, setWhatsapp] = useState();
  const [address, setAddress] = useState();
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState();
  const [website, setWebsite] = useState();
  const [gstApp, setGstApp] = useState();
  const [field, setField] = useState();
  // console.log(gstApp)
  const [panNo, setPanNo] = useState();
  const [gstNo, setGstNo] = useState();
  const [panImg, setPanImg] = useState('');
  const [regcerImg, setRegCertImg] = useState('');
  const [gstImg, setGstImg] = useState('');
  const [metaTitle, setMetaTitle] = useState();
  const [metaKeyword, setMetaKeyword] = useState();
  const [metaDescription, setMetaDescription] = useState();
  const [aboutUs, setAboutUs] = useState();
  const [slug, setSlug] = useState();
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [youtube, setYoutube] = useState();
  const [linkedin, setLinkedin] = useState();
  const [instagram, setInstagram] = useState();
  const [pinterest, setpinInterest] = useState();
  const [blogUrl, setBlogUrl] = useState();
  const [cod, setCod] = useState();
  const [bankName, setBankName] = useState();
  const [accountName, setAccountName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [branch, setBranch] = useState();
  const [swift, setSwift] = useState();
  const [ifsc, setIfsc] = useState();
  const [error, setError] = useState();
  const [resError, setResError] = useState();
  const [heading, setHeading] = useState();
  const [sub_heading, setSub_Heading] = useState();
  const [image, setImage] = useState();
  const [modal, setModal] = useState(false);
  const [businessSlug, setBusinessSlug] = useState();
  const [sunday, setSunday] = useState();
  const [sundaystart, setSundayStart] = useState();
  const [monday, setMonday] = useState();
  const [mondaystart, setMondayStart] = useState();
  const [tuesday, setTuesday] = useState();
  const [tuesdaystart, setTuesdayStart] = useState();
  const [wednesday, setWednesday] = useState();
  const [wednesdaystart, setWednesdayStart] = useState();
  const [thursday, setThursday] = useState();
  const [thursdaystart, setThursdayStart] = useState();
  const [friday, setFriday] = useState();
  const [fridaystart, setFridayStart] = useState();
  const [saturday, setSaturday] = useState();
  const [saturdaystart, setSaturdayStart] = useState();
  const [sunOn, setSunOn] = useState();
  const [monOn, setMonOn] = useState();
  const [tuesOn, setTuesOn] = useState();
  const [wedOn, setWedOn] = useState();
  const [thursOn, setThursOn] = useState();
  const [friOn, setFriOn] = useState();
  const [satOn, setSatOn] = useState();
  const [sunend, setSunEnd] = useState();
  const [monend, setMonEnd] = useState();
  const [tuesend, setTuesEnd] = useState();
  const [wedend, setWedEnd] = useState();
  const [thursend, setThursEnd] = useState();
  const [friend, setFriEnd] = useState();
  const [satend, setSatEnd] = useState();
  const [body, setbody] = useState('');
  const [desc, setdesc] = useState('');
  const body1 = React.createRef();
  const [statusData, setStatusData] = useState();
  const [status, setStatus] = useState(false)

  console.log('statusData', statusData)

  useEffect(() => {   

    FetchStatus();
  }, [])

  const FetchStatus = async () => {
    
    await axios.get(BASE_URL + '/get-seller-detail', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setStatusData(data) 

      // if(data.data == null){
      //   navigate('/vendor-dashboard')
      // }
      // if(data.data.payment_status == 'Pending'){
      //   navigate('/manage-profile')
      //   }
        setStatus(statusData && statusData.data == null ? true : statusData && statusData.data.payment_status == 'Pending' ? true : statusData && statusData.data.payment_status == 'Active' ? false : true)
    })
  
  }

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell
} = EditorTools;

  const handleSunday = (e) => {
    setField('gtc', e.target.checked)
    setSunday(!sunday)
    console.log("status", sunday)
    setSunOn(sunday == true ? "close" : "open")
  }

  console.log("statusValue",monOn)

  const handleMonday = (e) => {
    setField('gtc', e.target.checked)
    setMonday(!monday)
    setMonOn(monday == true ? "close" : "open")
  }

  const handleTuesday = (e) => {
    setField('gtc', e.target.checked)
    setTuesday(!tuesday)
    setTuesOn(tuesOn == true ? "close" : "open")
  }

  const handleWednesday = (e) => {
    setField('gtc', e.target.checked)
    setWednesday(!wednesday)
    setWedOn(wedOn == true ? "close" : "open")
  }

  const handleThursday = (e) => {
    setField('gtc', e.target.checked)
    setThursday(!thursday)
    setThursOn(thursday == true ? "close" : "open")
  }

  const handleFriday = (e) => {
    setField('gtc', e.target.checked)
    setFriday(!friday)
    setFriOn(friday == true ? "close" : "open")
  }

  const handleSaturday = (e) => {
    setField('gtc', e.target.checked)
    setSaturday(!saturday)
    setSatOn(satOn == true ? "close" : "open")
  }

  const handleChange = (e) => {
    // setField('gtc', e.target.checked)
    setGstApp(!gstApp)
  }

  const handleGST = e => {
    setGstImg(e.target.files[0]);
  }
  const handlePan = e => {
    setPanImg(e.target.files[0]);
  }
  const handleReg = e => {
    setRegCertImg(e.target.files[0]);
  }

  const [keyword, setKeyword] = useState([]);
  const [store, setStore] = useState();
  const [seller, setSeller] = useState();


  useEffect(() => {
    FetchProfileData();
    getCountry();
    FetchBusinessCategory();
    FetchKeywords();
    FetchSlider();
  }, [])

  const FetchKeywords = async () => {
    await axios.get(BASE_URL + '/getmetakeyword').then(({ data }) => {
      setKeyword(data.data)
    })
  }

  const KeyData = keyword.map((item) => (
    item.meta_keyword
  ))

  const [img_Url, setImg_Url] = useState('');

  const FetchProfileData = async () => {
    await axios.get(BASE_URL + '/getbusinessprofile', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      var datasl = [];
      JSON.parse(data.data.business_mobilenumber).map((item, i) => {
        datasl.push({ mobile: item })
      })
      var datas2 = [];
      JSON.parse(data.data.business_email).map((items, i) => {
        datas2.push({ email: items })
      });
      var datas3 = [];
      data.pickupaddress.map((items, i) => {
        getState(items.country);
        getCity(items.state)
        datas3.push({ pickAddress: items.address, pickCountry: items.country, pickstate: items.state, pickCity: items.city, pickPincode: items.pincode, pickLandmark: items.landmark })
      })


      setPickupList(datas3);
      setaddEmail(datas2)
      setaddMobile(datasl)
      setData(data.data)
      setImg_Url(data.image_url)
      setStore(data.store)
      setSeller(data.seller)
      setPickAdd(data.pickupaddress)
      // console.log("profile", data.data)

    })
  }

  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + '/getbusinesscategory', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setCategory(data.data)
      // console.log(data.data)
    })
  }

  const [sliderData, setSliderData] = useState([]);
  const [baseUrl, setBaseUrl] = useState();

  const FetchSlider = async () => {
    await axios.get(BASE_URL + '/getslider', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setSliderData(data.data)
      setBaseUrl(data.image_base_url)
    })
  }

  const [selected, setSelected] = useState([]);
  const [addMobile, setaddMobile] = useState([{ mobile: "" }]);
  // console.log(selected)

  // handle input change
  const handleInputMobileChange = (e, index) => {
    const { mobile, value } = e.target;
    const list = [...addMobile];
    list[index]['mobile'] = value;
    setaddMobile(list);
  };

  // handle click event of the Remove button
  const handleRemoveMobileClick = index => {
    const list = [...addMobile];
    list.splice(index, 1);
    setaddMobile(list);
  };

  // handle click event of the Add button
  const handleAddMobileClick = () => {
    setaddMobile([...addMobile, { mobile: "" }]);
  };

  const [addEmail, setaddEmail] = useState([{ email: "" }]);
  // console.log(addEmail)

  // handle input change
  const handleInputEmailChange = (e, index) => {
    const { email, value } = e.target;
    const list = [...addEmail];
    list[index]['email'] = value;
    setaddEmail(list);
    // console.log(list)
  };

  // handle click event of the Remove button
  const handleRemoveEmailClick = index => {
    const list = [...addEmail];
    list.splice(index, 1);
    setaddEmail(list);
  };

  // handle click event of the Add button
  const handleAddEmailClick = () => {
    setaddEmail([...addEmail, { email: "" }]);
  };

  const getCountry = () => {

    axios.get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };

  const [pickupList, setPickupList] = useState([{ pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  //  console.log([pickupList[0].pickAddress])
  // handle input change
  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...pickupList];
    // const li = [...pickupList.pickAddress]
    // console.log(list[0].pickAddress)
    list[index][name] = value;
    setPickupList(list);
    console.log(e)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...pickupList];
    list.splice(index, 1);
    setPickupList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setPickupList([...pickupList, { pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  };

  // console.log(pickupList[0].pickAddress)

  const [countryName, setCountryName] = useState()
  const handleCountyName = (e) => {
    const DataIdWithName = e;
    const countryData = DataIdWithName.split("_");
    setCountryName(countryData[0])
  }

  console.log('Days', [sundaystart, mondaystart, tuesdaystart])

  const UpdateProfile = async () => {
    const formData = new FormData();
    formData.append('business_category', selected ? selected : data.business_category ? data.business_category : '')
    formData.append('business_nature', businessNature ? businessNature : data.business_nature ? data.business_nature && data.business_nature : '');
    formData.append('website_url', website ? website : data.website_url && data.website_url ? data.website_url && data.website_url : '');
    formData.append('gst_number', gstNo ? gstNo : data.gst_number ? data.gst_number && data.gst_number : '');
    formData.append('reg_certificate', regcerImg);
    formData.append('pannumber', panNo ? panNo : data.pannumber ? data.pannumber && data.pannumber : '');
    formData.append('gst_certificate', gstImg);
    formData.append('business_name', businessName ? businessName : data.business_name ? data.business_name && data.business_name : '');
    formData.append('country', countryName ? countryName : data.country.name ? data.country.name && data.country.name : '');
    formData.append('state', state ? state : data.state.name ? data.state.name && data.state.name : '');
    formData.append('city', city ? city : data.city.name ? data.city.name && data.city.name : '');
    formData.append('pincode', pincode ? pincode : data.pincode ? data.pincode && data.pincode : '');
    formData.append('business_type', businessType ? businessType : data.business_type ? data.business_type && data.business_type : '');
    formData.append('reg_no', regNo ? regNo : data.reg_no ? data.reg_no && data.reg_no : '');
    formData.append('full_address', address ? address : data.full_address ? data.full_address && data.full_address : '');
    formData.append('gst_status', 'yes');
    formData.append('business_mobilenumber', addMobile ? addMobile : data.business_mobilenumber ? data.business_mobilenumber && data.business_mobilenumber : '');
    formData.append('business_email', addEmail ? addEmail : data.business_email ? data.business_email && data.business_email : '');
    formData.append('pancard', panImg);
    formData.append('whatsapp_number', whatsappNo ? whatsappNo : data.whatsapp_number ? data.whatsapp_number && data.whatsapp_number : '');
    formData.append('day', ['sunday','monday','tuesday','wednesday','thursday','friday','saturday']);
    formData.append('in_time', [sundaystart,mondaystart,tuesdaystart,wednesdaystart,thursdaystart,fridaystart,saturdaystart]);
    formData.append('out_time', [sunend,monend,tuesend,wedend,thursend,friend,satend]);
    formData.append('status', [sunOn,monOn,tuesOn,wedOn,thursOn,friOn,satOn]);
    
    await axios.post(BASE_URL + '/updatebusinessprofile', formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProfileData();
      // console.log(data)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    })
      .catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setResError(response.data.message)
          if (response.data.message.business_category) {
            toast.error(response.data.message.business_category[0])
          }
          if (response.data.message.business_category) {
            toast.error(response.data.message.country[0])
          }
        }
      })
  }
  const Handlestore = async () => {
    const view1 = body1.current.view;
    var aboutUsContent = EditorUtils.getHtml(view1.state);
    const formData = new FormData();
    var address = [];
    var country = [];
    var state = [];
    var city = [];
    var pincode = [];
    var landmark = [];
    pickupList.map((item) => {
      address.push(item.pickAddress)
      country.push(item.pickCountry)
      state.push(item.pickstate)
      city.push(item.pickCity)
      pincode.push(item.pickPincode)
      landmark.push(item.pickLandmark)

    })

    let datas = {
      "meta_title": metaTitle,
      "meta_keyword": chip,
      "meta_description": metaDescription,
      "aboutus": aboutUsContent,
      "bank_name": bankName,
      "bank_acc_name": accountName,
      "bank_acc_no": accountNumber,
      "bank_ifsc": ifsc,
      "bank_branch": branch,
      "bank_swiftcode": swift,
      "slug": slug,
      "facebook": facebook,
      "twitter": twitter,
      "youtube": youtube,
      "linkedin": linkedin,
      "instagram": instagram,
      "pinterest": pinterest,
      "blog_url": blogUrl,
      "cod": cod,
      "address": address,
      "landmark": landmark,
      "country": country,
      "state": state,
      "city": city,
      "pincode": pincode
    }
    await axios.post(BASE_URL + '/makestore', datas, {
      headers: {
        // 'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProfileData();
      // console.log(data)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      console.log(response.data)
      if (response.data.status_code == 422) {
        setError(response.data.message)

        if (response.data.message.aboutus) {
          toast.error(response.data.message.aboutus[0])
        }
        if (response.data.message.bank_acc_name) {
          toast.error(response.data.message.bank_acc_name[0])
        }
        if (response.data.message.address) {
          toast.error(response.data.message.address[0])
        }
      }
    })
  }

  const [chip, setChip] = useState();

  const handlechangeChip = (e) => {
    setChip(e)
  }

  const handleImage = e => {
    setImage(e.target.files[0]);
  }

  const handleNewImage = e => {
    setNewImage(e.target.files[0]);
  }

  const [edit, setEdit] = useState(false);
  const [errorImg, setErrorImg] = useState();
  const [pickAd, setPickAdd] = useState([]);


  const AddSlider = async () => {

    const formData = new FormData();
    formData.append('image', image)
    formData.append('heading', heading);
    formData.append('subheading', sub_heading);
    await axios.post(BASE_URL + '/addslider', formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        if (data.message == 'Add Succesfully') {
          toast.success(data.message)
          setModal(false)
          setImage("");
          setHeading("");
          setSub_Heading("");
          FetchSlider();
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setErrorImg(response.data.message.image[0])
          //  alert(response.data.message.image[0])
        }
      })
  }

  const handleDelete = async (id) => {
    await axios.get(BASE_URL + '/deleteslider/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      toast.success(data.message)
      FetchSlider();
    })
  }

  const [editDetail, setEditDetail] = useState();
  const [editModal, setEditModal] = useState();
  const [newHeading, setNewHeading] = useState();
  const [newSubHeading, setNewSubHeading] = useState();
  const [newImage, setNewImage] = useState();

  const EditSlider = async (id) => {

    await axios.get(BASE_URL + '/editslider/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setEditModal(true)
      setEditDetail(data.data)
    })
  }

  const UpdateSlider = async (id) => {
    const formData = new FormData();
    formData.append('image', newImage == null ? editDetail.image : newImage)
    formData.append('heading', newHeading == null ? editDetail.heading : newHeading);
    formData.append('subheading', newSubHeading);
    await axios.post(BASE_URL + '/updateslider/' + id, formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        if (data.message == 'Update Succesfully') {
          toast.success(data.message)
          setEditModal(false);
          setImage("")
          setNewHeading("")
          setNewSubHeading("")
          FetchSlider();
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setErrorImg(response.data.message.image[0])
          //  alert(response.data.message.image[0])
        }
      })
  }


  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Manage Profile</li>
            </ul>
            <div className="col-md-12">
            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
              </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu PurchaseHis={status}  />
              <div className="col-md-9">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>
                    Manage Profile <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}> <FontAwesomeIcon icon={faEdit}  /></Button>
                  </h4>
                  {/* <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}>Edit</Button> */}
                </div>



                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Business Information</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                      <div className="row">
                        {/* <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select Business Category</Form.Label>
                          {data && data.business_category.length && !edit ?
                            <div style={{ border: '1px solid lightgray', padding: 10, background: '#F2F3F8' }}>
                              {data.business_category.map((item) => (
                                <div >{item.name}</div>
                              ))} </div>
                            :
                            <MultiSelect
                              options={category}
                              value={selected}
                              onChange={setSelected}
                              labelledBy={"Select Business Category"}
                              hasSelectAll={false}
                              closeOnChangedValue={selected.length > 2 ? true : false}
                            // isCreatable={true}
                            />
                          }
                          {resError && resError.business_category[0] ? <div className="text-danger"> {resError && resError.business_category[0]} </div> : ''}
                        </div> */}
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={data && data.business_name && !edit ? true : false}
                            placeholder="Enter Business Name"
                            defaultValue={data && data.business_name}
                            onChange={(e) => setBusinessName(e.target.value)}
                          // onClick={disabled == true ? alert('Use Edit Mode') : ''}
                          />
                          {resError && resError.business_name ? <div className="text-danger"> {resError && resError.business_name} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Business Slug</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={data && data.business_name && !edit ? true : false}
                            placeholder="Enter Business Name"
                            defaultValue={data && data.business_slug}
                            onChange={(e) => setBusinessSlug(e.target.value)}
                          // onClick={disabled == true ? alert('Use Edit Mode') : ''}
                          />
                          {resError && resError.business_slug ? <div className="text-danger"> {resError && resError.business_slug} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select Business Type</Form.Label>
                          <Form.Select disabled={data && data.business_type && !edit ? true : false} style={{ borderRadius: '0%' }} onChange={(e) => { setBusinessType(e.target.value) }}>
                            <option value={data && data.business_type ? data && data.business_type : ''}>{data && data.business_type ? data && data.business_type : 'Select Business Type'}</option>
                            <option value="Retailer">Retailer</option>
                            <option value="Wholeseller">Wholeseller</option>
                            <option value="Distributor">Distributor</option>
                            <option value="Manufacturer">Manufacturer</option>
                            <option value="Exporter & Importer">Exporter & Importer</option>
                            <option value="Service Provider">Service Provider</option>
                          </Form.Select>
                          {resError && resError.business_type ? <div className="text-danger"> {resError && resError.business_type} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select Nature of Business</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            onChange={(e) => setBusinessNature(e.target.value)}
                            disabled={data && data.business_nature && !edit ? true : false}

                          >
                            <option value={data && data.business_nature ? data && data.business_nature : ''}>{data && data.business_nature ? data && data.business_nature : 'Select Business Nature'}</option>
                            <option value="Manufacturer">Manufacturer</option>
                            <option value="Trader">Trader</option>
                            <option value="Wholeseller">Wholeseller</option>
                            <option value="Distributor">Distributor</option>
                            <option value="Service Provider">Service Provider</option>
                            <option value="Importer & Exporter">Importer & Exporter</option>
                          </Form.Select>
                          {resError && resError.business_nature ? <div className="text-danger"> {resError && resError.business_nature} </div> : ''}
                        </div>

                        {addEmail.map((x, i) => {
                          // console.log(x)
                          return (
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Business Email Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Email Id "
                                value={x.email}
                                onChange={e => handleInputEmailChange(e, i)}
                                disabled={data && data.business_email && !edit ? true : false}
                                defaultValue={data && data.business_email == null ? ' ' : data && data.business_email}
                              />
                              {resError && resError.business_email ? <div className="text-danger"> {resError && resError.business_email} </div> : ''}

                              <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                                {addEmail.length !== 1 && <div
                                  className="mr10 pointer"
                                  style={{ color: 'red' }}
                                  onClick={() => handleRemoveEmailClick(i)}>Remove</div>}
                                {addEmail.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddEmailClick}> + Add</div>}
                              </div>
                            </div>
                          );
                        })}
                        {addMobile.map((x, i) => {
                          return (
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Business Mobile Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Mobile Number"
                                value={x.mobile}
                                onChange={e => handleInputMobileChange(e, i)}
                                disabled={data && data.business_mobilenumber && !edit ? true : false}
                              />
                              {resError && resError.business_mobilenumber ? <div className="text-danger"> {resError && resError.business_mobilenumber} </div> : ''}

                              <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                                {addMobile.length !== 1 && <div
                                  className="mr10 pointer"
                                  style={{ color: 'red' }}
                                  onClick={() => handleRemoveMobileClick(i)} >Remove</div>}
                                {addMobile.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddMobileClick}> + Add</div>}
                              </div>
                            </div>
                          );
                        })}
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Business Whatsapp Number</Form.Label>
                          <Form.Control
                            type="text"
                            value={data && data.whatsapp_number}
                            placeholder="Enter Whatsapp Number"
                            onChange={(e) => setWhatsapp(e.target.value)}
                            defaultValue={data && data.whatsapp_number == null ? '' : data && data.whatsapp_number}
                            disabled={data && data.whatsapp_number && !edit ? true : false}
                          />
                          {resError && resError.whatsapp_number ? <div className="text-danger"> {resError && resError.whatsapp_number} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Company Website</Form.Label>
                          <Form.Control
                            type="text"
                            
                            placeholder="Enter Address"
                            onChange={(e) => setWebsite(e.target.value)}
                            defaultValue={data && data.website_url ? data && data.website_url : ''}
                            disabled={data && data.website_url && !edit ? true : false}
                          />
                        </div>
                        <div className="col-md-8 mb-2 mt-2">
                          <Form.Label>Enter Address</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Address"
                            onChange={(e) => setAddress(e.target.value)}
                            defaultValue={data && data.full_address == null ? '' : data && data.full_address}
                            disabled={data && data.full_address && !edit ? true : false}
                          />
                          {resError && resError.full_address ? <div className="text-danger"> {resError && resError.full_address} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select Country</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            onChange={(e) => { setCountry(e.target.value); getState(e.target.value) }}
                            onClick={(e) => handleCountyName(e.target.value)}
                            disabled={data && data.country && !edit ? true : false}
                          >
                            <option>{data && data.country == null ? data && data.country.name : 'Select Country'}</option>
                            {fetchCountry && fetchCountry.map((data) => (
                              <option value={data.name + "_" + data.id}>{data.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select State</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            onChange={(e) => { setState(e.target.value); getCity(e.target.value) }}
                            disabled={data && data.state && !edit ? true : false}
                          >
                            <option>{data && data.state ? data && data.state.name : 'Select State'}</option>
                            {/* <option >{data && data.state.name ? 'Select State' : data && data.state.name}</option> */}
                            {fetchState && fetchState.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select City</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            onChange={(e) => setCity(e.target.value)}
                            disabled={data && data.city && !edit ? true : false}

                          >
                            <option>{data && data.city ? data && data.city.name : 'Select State'}</option>
                            {/* <option>{data && data.city.name  ? 'Select City' : data && data.city.name}</option> */}
                            {fetchCity && fetchCity.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </Form.Select>
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address"
                            onChange={(e) => setPincode(e.target.value)}
                            defaultValue={data && data.pincode ? data && data.pincode : ''}
                            disabled={data && data.pincode && !edit ? true : false}

                          />
                        </div>
                      </div>
                      <div>
                        <div className="order-head-blk">
                          <h5>Working Hours</h5>
                        </div>

                        <div className="row mt-4">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2 ">Sunday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleSunday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {sunday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setSundayStart(e.target.value)}>
                                <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setSunEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2">Monday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleMonday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {monday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }}  onClick={(e) => setMondayStart(e.target.value)}>
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setMonEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2">Tuesday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleTuesday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {tuesday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setTuesdayStart(e.target.value)}>
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setTuesEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>

                        <div className="row mt-2">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2">Wednesday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleWednesday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {wednesday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setWednesdayStart(e.target.value)}>
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setWedEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2">Thursday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleThursday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {thursday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setThursdayStart(e.target.value)}>
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setThursEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2">Friday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleFriday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {friday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setFridayStart(e.target.value)}>
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setFriEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>
                        <div className="row mt-2">
                          <div className="col-md-2 center">
                            <Form.Label className="mt-2">Saturday</Form.Label>
                          </div>
                          <div className="col-md-2">
                            {/* label="Closed?" */}
                            <Form.Label className="mt-2" style={{ display: 'flex' }}>
                              Closed?
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                // label="Closed?"
                                onChange={handleSaturday}
                                style={{ marginLeft: 10 }}
                              />

                            </Form.Label>

                          </div>
                          {saturday ?
                            <div className="col-md-3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setSaturdayStart(e.target.value)}>
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                              <Form.Label className="mt-2">to</Form.Label>
                              <Form.Select style={{ width: 100 }} onClick={(e) => setSatEnd(e.target.value)} >
                              <option value="12 AM">12 AM</option>
                                <option value="1 AM">1 AM</option>
                                <option value="2 AM">2 AM</option>
                                <option value="3 AM">3 AM</option>
                                <option value="4 AM">4 AM</option>
                                <option value="5 AM">5 AM</option>
                                <option value="6 AM">6 AM</option>
                                <option value="7 AM">7 AM</option>
                                <option value="8 AM">8 AM</option>
                                <option value="9 AM">9 AM</option>
                                <option value="10 AM">10 AM</option>
                                <option value="11 AM">11 AM</option>
                                <option value="12 PM">12 PM</option>
                                <option value="1 PM">1 PM</option>
                                <option value="2 PM">2 PM</option>
                                <option value="3 PM">3 PM</option>
                                <option value="4 PM">4 PM</option>
                                <option value="5 PM">5 PM</option>
                                <option value="6 PM">6 PM</option>
                                <option value="7 PM">7 PM</option>
                                <option value="8 PM">8 PM</option>
                                <option value="9 PM">9 PM</option>
                                <option value="10 PM">10 PM</option>
                                <option value="11 PM">11 PM</option>
                                <option value="12 PM">12 PM</option>
                              </Form.Select>
                            </div>
                            : ''}
                        </div>


                        <div>
                          <div className="order-head-blk">
                            <h5>Documents</h5>
                          </div>

                          <div className="row">
                            <div className="col-md-4 mb-2 mt-2">

                              <Form.Label>Upload Registration Certificate</Form.Label>
                              {data && data.reg_certificate ?
                                <div>
                                  <img src={img_Url + data.reg_certificate} height="50px" width="50px" />
                                </div>
                                :
                                <input type="file" className="mt-2"
                                  onChange={handleReg}
                                />

                              }
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Upload PAN</Form.Label>
                              {data && data.pancard ?
                                <div>
                                  <img src={img_Url + data.pancard} height="50px" width="50px" />
                                </div> :
                                <input type="file" className="mt-2"
                                  onChange={handlePan}
                                />}
                            </div>
                            {data && data.gst_status == 'yes' ?
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Upload GST Certificate</Form.Label>
                                <div>
                                  <img src={img_Url + data.gst_certificate} height="50px" width="50px" />
                                </div> </div> : ''}
                            {gstApp &&
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Upload GST Certificate</Form.Label>
                                <input type="file" className="mt-2"
                                  onChange={handleGST}
                                />
                              </div>
                            }
                            <div className="col-md-12">
                              <Button
                                style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                variant="warning"
                                onClick={UpdateProfile}
                              >
                                Update
                              </Button>
                            </div>
                          </div>


                        </div>


                      </div>
                      <div>
                        <div className="order-head-blk">
                          <h5>Tax Information</h5>
                        </div>

                        <div className="row">
                          <div className="col-md-4 mb-2 mt-2">
                            <Form.Label>PAN Number</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter PAN Number"
                              onChange={(e) => panNo(e.target.value)}
                              defaultValue={data && data.pannumber ? data && data.pannumber : ''}
                              disabled={data && data.pannumber && !edit ? true : false}
                            />
                            {resError && resError.pannumber ? <div className="text-danger"> {resError && resError.pannumber} </div> : ''}
                          </div>
                          <div className="col-md-4 mb-2 mt-2">
                            <Form.Label>Enter Registration Number</Form.Label>
                            <Form.Control

                              disabled={data && data.reg_no && !edit ? true : false}
                              type="text"
                              placeholder="Enter Registration Number"
                              defaultValue={data ? data.reg_no : ''}
                              onChange={(e) => setRegNo(e.target.value)}
                            />
                          </div>
                          {resError && resError.reg_no ? <div className="text-danger"> {resError && resError.reg_no} </div> : ''}
                          <div className="col-md-3 mb-2 mt-2">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="GST Applicable?"
                              onChange={handleChange}
                              style={{ marginTop: 35, fontSize: 18 }}
                            />
                          </div>
                          {data && data.gst_status == 'yes' ?
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>GST Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter GST Number"
                                // defaultValue={data && data.business_name}
                                onChange={(e) => gstNo(e.target.value)}
                                defaultValue={data && data.gst_number == null ? '' : data && data.gst_number}
                                disabled={data && data.gst_number && !edit ? true : false}
                              />
                            </div> : ''}
                          {gstApp &&
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>GST Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter GST Number"
                                // defaultValue={data && data.business_name}
                                onChange={(e) => gstNo(e.target.value)}
                              />
                            </div>
                          }
                        </div>
                        <div>
                          <div className="order-head-blk">
                            <h5>Documents</h5>
                          </div>

                          <div className="row">
                            <div className="col-md-4 mb-2 mt-2">

                              <Form.Label>Upload Registration Certificate</Form.Label>
                              {data && data.reg_certificate ?
                                <div>
                                  <img src={img_Url + data.reg_certificate} height="50px" width="50px" />
                                </div>
                                :
                                <input type="file" className="mt-2"
                                  onChange={handleReg}
                                />

                              }
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Upload PAN</Form.Label>
                              {data && data.pancard ?
                                <div>
                                  <img src={img_Url + data.pancard} height="50px" width="50px" />
                                </div> :
                                <input type="file" className="mt-2"
                                  onChange={handlePan}
                                />}
                            </div>
                            {data && data.gst_status == 'yes' ?
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Upload GST Certificate</Form.Label>
                                <div>
                                  <img src={img_Url + data.gst_certificate} height="50px" width="50px" />
                                </div> </div> : ''}
                            {gstApp &&
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Upload GST Certificate</Form.Label>
                                <input type="file" className="mt-2"
                                  onChange={handleGST}
                                />
                              </div>
                            }
                            <div className="col-md-12">
                              <Button
                                style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                variant="warning"
                                onClick={UpdateProfile}
                              >
                                Update
                              </Button>
                            </div>
                          </div>


                        </div>


                      </div>


                    </Form>
                  </div>
                </div>

                {/* <div className="product-card-block">
                  <div className="order-head-blk">
                    <h6>Update Email</h6>
                  </div>
                  <div className="card-body-blk">
                    <div className="row">
                      <div className="col-md-2">
                        <p style={{ paddingTop: "7px" }}>Enter Your Email ID</p>
                      </div>
                      <div className="col-md-10">
                        <InputGroup>
                          <Form.Control
                            type="text"

                            // placeholder="Input group example"
                            // aria-label="Input group example"
                            // aria-describedby="btnGroupAddon"
                          />
                          <Button
                            style={{ backgroundColor: "#C3CECF", width: "15%" }}
                            variant="success"
                          >
                            Verify
                          </Button>
                        </InputGroup>
                      </div>

                      <div className="col-md-12">
                        <Button
                          style={{ float: "right", marginTop: "12px" }}
                          variant="warning"
                        >
                          Update Email
                        </Button>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="product-card-block">
                  <div className="order-head-blk mb-3">
                    <h5>Store Setup</h5>
                  </div>
                  <div className="card-body-blk">
                    <div className="row">
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>Meta Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Meta Title "
                          onChange={(e) => setMetaTitle(e.target.value)}
                          defaultValue={store && store.meta_title == null ? "" : store && store.meta_title}
                          disabled={store && store.meta_title && !edit ? true : false}

                        />
                        {error && error.meta_title[0] ?
                          <div className="text-danger">{error && error.meta_title[0]}</div> : ''}
                      </div>
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>Meta Keyword</Form.Label>
                        {store && store.meta_keyword && !edit ?
                          <div style={{ border: '1px solid lightgray', padding: 10, background: '#F2F3F8' }}>

                            <div >{store && store.meta_keyword}</div>
                          </div>
                          :
                          <div>
                            <Chips
                              value={chip}
                              onChange={handlechangeChip}
                              suggestions={KeyData}
                              placeholder="Enter Keyword"
                              fromSuggestionsOnly={false}
                              createChipKeys={[13, 32]}
                              defaultValue={store && store.meta_keyword == null ? "" : store && store.meta_keyword}
                              disabled={store && store.meta_keyword && !edit ? true : false}

                            />
                          </div>
                        }
                        {/* {chip.length == '2' ? <div>bdh</div>:'bd'} */}
                        {error && error.meta_keyword[0] ?
                          <div className="text-danger">{error && error.meta_keyword[0]}</div> : ''}
                      </div>
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>Meta Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Meta Description "
                          onChange={(e) => setMetaDescription(e.target.value)}
                          defaultValue={store && store.meta_description == null ? '' : store && store.meta_description}
                          disabled={store && store.meta_description && !edit ? true : false}
                        />
                        {error && error.meta_description[0] ?
                          <div className="text-danger">{error && error.meta_description[0]}</div> : ''}
                      </div>
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>About Us</Form.Label>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                          height: 100
                        }}

                          value={aboutUs}
                          ref={body1}
                          onChange={(e) => setAboutUs(e.target.value)}
                        />
                        {error && error.shipping_policy ? <div className="text-danger"> {error && error.shipping_policy} </div> : ''}
                        {/* <Form.Control
                          type="text"
                          placeholder="About Us "
                          onChange={(e) => setAboutUs(e.target.value)}
                          defaultValue={store && store.aboutus == null ? '' : store && store.aboutus}
                          disabled={store && store.aboutus && !edit ? true : false}
                        /> */}
                        {error && error.aboutus[0] ?
                          <div className="text-danger">{error && error.aboutus[0]}</div> : ''}

                      </div>

                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Slug</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setSlug(e.target.value)}
                          placeholder="Enter Slug "
                          defaultValue={store && store.slug == null ? '' : store && store.slug}
                          disabled={store && store.slug && !edit ? true : false}
                        />
                        {error && error.slug[0] ?
                          <div className="text-danger">{error && error.slug[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setFacebook(e.target.value)}
                          placeholder="Enter Facebook Url "
                          defaultValue={store && store.facebook == null ? '' : store && store.facebook}
                          disabled={store && store.facebook && !edit ? true : false}
                        />
                        {error && error.facebook[0] ?
                          <div className="text-danger">{error && error.facebook[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setTwitter(e.target.value)}
                          placeholder="Enter Twitter Url "

                          disabled={store && store.twitter && !edit ? true : false}

                          defaultValue={store && store.twitter == null ? '' : store && store.twitter}
                        />
                        {error && error.twitter[0] ?
                          <div className="text-danger">{error && error.twitter[0]}</div> : ''}
                      </div>

                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Youtube</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setYoutube(e.target.value)}
                          placeholder="Enter Youtube Url"
                          defaultValue={store && store.youtube == null ? '' : store && store.youtube}
                          disabled={store && store.youtube && !edit ? true : false}

                        />
                        {error && error.youtube[0] ?
                          <div className="text-danger">{error && error.youtube[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Linkedin</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setLinkedin(e.target.value)}
                          defaultValue={store && store.linkedin == null ? '' : store && store.linkedin}
                          disabled={store && store.linkedin && !edit ? true : false}
                          placeholder="Enter Linkedin Url"
                        />
                        {error && error.linkedin[0] ?
                          <div className="text-danger">{error && error.linkedin[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                          onChange={(e) => setInstagram(e.target.value)}
                          type="text"
                          defaultValue={store && store.instagram == null ? '' : store && store.instagram}
                          disabled={store && store.instagram && !edit ? true : false}
                          placeholder="Enter Instagram Url"
                        />
                        {error && error.instagram[0] ?
                          <div className="text-danger">{error && error.instagram[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Pinterest</Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue={store && store.pinterest == null ? '' : store && store.pinterest}
                          disabled={store && store.pinterest && !edit ? true : false}
                          placeholder="Enter Pinterest Url"
                          onChange={(e) => setpinInterest(e.target.value)}
                        />
                        {error && error.pinterest[0] ?
                          <div className="text-danger">{error && error.pinterest[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Blog Url</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setBlogUrl(e.target.value)}
                          placeholder="Enter Blog Url "
                          disabled={store && store.blog_url && !edit ? true : false}
                          defaultValue={store && store.blog_url == null ? '' : store && store.blog_url}
                        />
                        {error && error.blog_url[0] ?
                          <div className="text-danger">{error && error.blog_url[0]}</div> : ''}
                      </div>

                      <div className="order-head-blk mb-3">
                        <h5>Bank Details</h5>
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Bank Name</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setBankName(e.target.value)}
                          placeholder="Enter Bank Name "
                          disabled={seller && seller.bank_name && !edit ? true : false}
                          defaultValue={seller && seller.bank_name == null ? '' : seller && seller.bank_name}
                        />
                        {error && error.bank_name[0] ?
                          <div className="text-danger">{error && error.bank_name[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Account Holder Name</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setAccountName(e.target.value)}
                          placeholder="Enter Account Holder Name "
                          defaultValue={seller && seller.bank_acc_name == null ? '' : seller && seller.bank_acc_name}
                          disabled={seller && seller.bank_acc_name && !edit ? true : false}
                        />
                        {error && error.bank_acc_name[0] ?
                          <div className="text-danger">{error && error.bank_acc_name[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setAccountNumber(e.target.value)}
                          placeholder="Enter Account Number "
                          defaultValue={seller && seller.bank_acc_no == null ? '' : seller && seller.bank_acc_no}
                          disabled={seller && seller.bank_acc_no && !edit ? true : false}
                        />
                        {error && error.bank_acc_no[0] ?
                          <div className="text-danger">{error && error.bank_acc_no[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Branch Name</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setBranch(e.target.value)}
                          placeholder="Enter Branch Name "
                          defaultValue={seller && seller.bank_branch == null ? '' : seller && seller.bank_branch}
                          disabled={seller && seller.bank_branch && !edit ? true : false}
                        />
                        {error && error.bank_branch[0] ?
                          <div className="text-danger">{error && error.bank_branch[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>IFSC Code</Form.Label>
                        <Form.Control
                          onChange={(e) => setIfsc(e.target.value)}
                          type="text"
                          placeholder="Enter IFSC Code"
                          defaultValue={seller && seller.bank_ifsc == null ? '' : seller && seller.bank_ifsc}
                          disabled={seller && seller.bank_ifsc && !edit ? true : false}
                        />
                        {error && error.bank_ifsc[0] ?
                          <div className="text-danger">{error && error.bank_ifsc[0]}</div> : ''}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Bank Swift Code</Form.Label>
                        <Form.Control
                          onChange={(e) => setSwift(e.target.value)}
                          type="text"
                          placeholder="Enter Swift Code "
                          defaultValue={seller && seller.bank_swiftcode == null ? '' : seller && seller.bank_swiftcode}
                          disabled={seller && seller.bank_swiftcode && !edit ? true : false}
                        />
                        {error && error.bank_swiftcode[0] ?
                          <div className="text-danger">{error && error.bank_swiftcode[0]}</div> : ''}
                      </div>
                      <div className="order-head-blk mb-4">
                        <h5>Pickup Address</h5>
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>COD</Form.Label>
                        <Form.Select
                          onChange={(e) => setCod(e.target.value)}
                          disabled={data && !edit ? true : false}
                        >
                          <option value="">Select Option</option>
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </Form.Select>
                      </div>
                      {pickupList.map((x, i) => {
                        // console.log(x)
                        return (
                          <>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Pickup Address</Form.Label>
                              <Form.Control
                                type="text"
                                name="pickAddress"
                                placeholder="Enter Address "
                                value={x.pickAddress}
                                onChange={e => handleInputChange(e, i)}
                                defaultValue={pickAd.map((item) => item.address)}
                                disabled={data && !edit ? true : false}
                              />
                              {/* {error && error.address[0].map((item) => (
                                 <div className="text-danger">bndj</div>
                              ))} */}
                              {/* {error.message.address ?
                        <div className="text-danger">{error.message.address[0]}</div> : '' } */}
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Pickup LandMark</Form.Label>
                              <Form.Control
                                type="text"
                                name="pickLandmark"
                                placeholder="Enter Address "

                                value={x.pickLandmark}
                                onChange={e => handleInputChange(e, i)}
                                // defaultValue={pickAd.map((item) => item.landmark)}
                                disabled={data && !edit ? true : false}

                              />
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Pickup Country</Form.Label>
                              <Form.Select
                                name="pickCountry"
                                disabled={data && !edit ? true : false}

                                style={{ borderRadius: '0%' }} value={x.pickCountry} onChange={(e) => { handleInputChange(e, i); getState(e.target.value) }}>
                                {/* <option value={pickAd && pickAd.map((item) => item.country) == null ? '' : pickAd && pickAd.map((item) => item.country)}>{pickAd && pickAd.map((item) => item.address)}</option> */}
                                {fetchCountry && fetchCountry.map((data) => (
                                  <option value={data.id}>{data.name}</option>
                                ))}
                              </Form.Select>
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Pickup State</Form.Label>
                              <Form.Select name="pickstate"
                                disabled={data && !edit ? true : false}


                                style={{ borderRadius: '0%' }} value={x.pickstate} onChange={(e) => { handleInputChange(e, i); getCity(e.target.value) }}>
                                {/* <option>{data.state.name ? data.state.name : ''}</option> */}
                                {/* <option value={data.country && data.country.name == null ? '' : data && data.country.name}>{data && data.country.name == null ? 'Select Country' : data && data.country.name}</option> */}
                                {fetchState && fetchState.map((data) => (
                                  <option value={data.id}>{data.name}</option>
                                ))}
                              </Form.Select>

                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Pickup City</Form.Label>
                              <Form.Select name="pickCity"
                                disabled={data && !edit ? true : false}
                                style={{ borderRadius: '0%' }} value={x.pickCity}
                                onChange={e => handleInputChange(e, i)}>
                                {/* <option value={data.country && data.country.name == null ? '' : data && data.country.name}>{data && data.country.name == null ? 'Select Country' : data && data.country.name}</option> */}
                                {fetchCity && fetchCity.map((data) => (
                                  <option value={data.id}>{data.name}</option>
                                ))}
                              </Form.Select>
                            </div>

                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Pincode</Form.Label>
                              <Form.Control
                                type="text"
                                name="pickPincode"
                                disabled={data && !edit ? true : false}
                                placeholder="Enter Address "
                                value={x.pickPincode}
                                onChange={e => handleInputChange(e, i)}
                              />
                            </div>

                            <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                              {pickupList.length !== 1 && <div
                                className="mr10 pointer"
                                style={{ color: 'red' }}
                                onClick={() => handleRemoveClick(i)}>Remove</div>}
                              {pickupList.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddClick}> + Add</div>}
                            </div>

                          </>

                        );
                      })}

                      <div className="col-md-12">
                        <Button
                          style={{ float: "right", marginTop: "8px", color: '#fff' }}
                          variant="warning"
                          onClick={Handlestore}
                        >
                          Update
                        </Button>
                      </div>
                      <ToastContainer />
                      {/* <div className="col-md-6">
                        <Card


                          className="mb-2 address-profile"
                        >

                          <Card.Body>

                            <Card.Text>

                              {addressDetail.map((address) => (
                                <div key={address.id}>
                                  <p><b>Address:</b> {address.fullAddress} <img style={{ float: 'right' }} src={MoreVerticalIcon} alt="" /> </p>
                                  <p><b>Postal Code:</b> {address.postalCode}  </p>
                                  <p><b>City:</b> {address.city} </p>
                                  <p><b>Country:</b> {address.country} </p>
                                  <p><b>Phone:</b> {address.phone} </p>
                                </div>
                              ))}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </div> */}
                      {/* <div className="col-md-6">
                        <Card

                          className="mb-2 add-address-profile"
                        >

                          <Card.Body>
                            <img style={{ paddingLeft: '27px' }} src={AddIcon} alt="" />
                            <Card.Text>
                              Add New Address
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <div className="col-md-12">
                          <Button
                            style={{ float: "right", marginTop: "8px" }}
                            variant="warning"
                          >
                            Update Profile
                          </Button>
                        </div>
                      </div> */}

                    </div>

                  </div>
                </div>
                <div className="product-card-block">

                  <div className="order-head-blk-new mb-3">
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h4>
                        Sliders & Banners
                      </h4>
                      <Button onClick={() => setModal(true)}>Add New Slider</Button>
                    </div>
                    <div className="card-body-blk">
                      <div className="row">
                        {sliderData && sliderData.map((item) => (
                          <div className="col-md-6 mt-8">
                            <div style={{ display: 'flex' }}>
                              <h5>{item.heading}</h5>
                              <div style={{ marginLeft: '50%' }}>
                                <FontAwesomeIcon icon={faEdit} height="20px" width="40px" fontSize={20} onClick={() => EditSlider(item.id)} />
                                <FontAwesomeIcon icon={faTrash} height="20px" width="40px" fontSize={20} color="red" onClick={() => handleDelete(item.id)} />
                              </div>
                            </div>
                            <h6>{item.subheading}</h6>
                            <img src={baseUrl + item.image} height="150px" width="300px" className="mt-2" />
                          </div>

                        ))}
                      </div>
                    </div>


                  </div></div>
              </div>
            </div>
          </div>
          <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
              <h5> Add Slider & Banner </h5></Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setHeading(e.target.value)}
                  placeholder="Enter Heading"
                />
                {/* {error && error.heading ? <div>bdhb</div> : ''} */}
              </div>
              <div className="mt-4">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setSub_Heading(e.target.value)}
                  placeholder="Enter Sub Heading"
                />
              </div>
              <div className="mt-4 mb-4">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleImage}
                  placeholder="Image "
                />
                {errorImg && errorImg ? <div className="text-danger">{errorImg}</div> : ''}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={AddSlider}>Add Slider</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={editModal} onHide={() => setEditModal(false)}>
            <Modal.Header closeButton>
              <h5> Edit Slider & Banner </h5></Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewHeading(e.target.value)}
                  placeholder="Enter Heading"
                  defaultValue={editDetail && editDetail.heading ? editDetail && editDetail.heading : ''}
                />
                {/* {error && error.heading ? <div>bdhb</div> : ''} */}
              </div>
              <div className="mt-4">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewSubHeading(e.target.value)}
                  defaultValue={editDetail && editDetail.subheading ? editDetail && editDetail.subheading : ''}
                  placeholder="Enter Sub Heading"
                />
              </div>
              <div className="mt-4 mb-4">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleNewImage}
                  placeholder="Image "
                />
                {errorImg && errorImg ? <div className="text-danger">{errorImg}</div> : ''}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => UpdateSlider(editDetail.id)}>Add Slider</Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default ManageProfile;
