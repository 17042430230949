import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stepper, Step, StepLabel, Typography, Box, Grid, StepIcon } from '@mui/material';
import { BASE_URL } from '../../../config';
// import StepIcon from '@material-ui/core/StepIcon';
// import  from '@material-ui/core/Grid';


const steps = [
    'Search for matches',
    'Identify the right one',
    'Pay with confidence',
    'Quality Assure',
    'Variety of Products',
];

const descriptions = [
    "Description for Step 1",
    "Description for Step 2",
    "Description for Step 3",
];


function VerticalStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const[allwidget, setAllwidget] = useState([])

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    
  // Get widget data from api
  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    widgetData();
  }, []);


    return (
        <div className='container mt-5'>
            <div className='empower-bussiness-content mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget7}} >
                {/* <h1>Empowering businesses through global trade</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.</p> */}
            </div>
            <div className='row'>
                <div className='col-md-6 buss-stepper'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (

                                <Step key={label}>
                                    {/* <StepLabel onClick={handleStep(index)}> */}
                                    <StepLabel onMouseEnter={handleStep(index)} onMouseLeave={handleStep(index)} >
                                        {label}
                                    </StepLabel>
                                    {activeStep === index && (
                                        <div style={{paddingLeft: "75px"}}>
                                            <p>{descriptions[index]}</p>
                                        </div>
                                    )}
                                </Step>
                            ))}
                        </Stepper>
                        {/* <Box m={2}>
                            <Typography variant="h5">{steps[activeStep]}</Typography>
                        </Box> */}
                    </div>
                </div>
                <div className='col-md-6'  dangerouslySetInnerHTML={{ __html: allwidget.widget8}}>
                    {/* <img src='/assets/images/step-1.png' /> */}
                </div>
            </div>

        </div>
    );
}

export default VerticalStepper;
