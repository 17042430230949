import React, { useState, useEffect } from "react";
import "../../products/HomePage.css"
import { Carousel } from "react-responsive-carousel";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../config";
import axios from "axios";


const Professional = () => {
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: false,
    // opacity:1,
    responsive: {
      0: {
        items: 2,
      },
      400: {
        items: 3,
      },
      490: {
        items: 3.5,
      },
      600: {
        items: 4,
      },
      800: {
        items: 6,
      },
      900: {
        items: 6,
      },
      1080: {
        items: 7,
      },
      1199: {
        items: 8,
      },
      1300: {
        items: 10,
      },
    },
  };

  const [Categoriesdigital, setCategoriesdigital] = useState([]);
  const [Categoriesprofesional, setCategoriesprofesional] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    FetchCategories();
  }, [])

  const FetchCategories = async () => {
    // await axios.get(BASE_URL + "/getcategoriesbytype/digital-services")
    //   .then(({ data }) => {
    //     setCategoriesdigital(data.data);
    //   });
    await axios.get(BASE_URL + "/getcategoriesbytype/professional-services")
      .then(({ data }) => {
        setCategoriesprofesional(data.data);
      });

  };

  // const Categories = [
  //   {
  //     name: "ShipHope",
  //     quantity: "4",
  //     cate1: "/assets/images/company-1.png",
  //   },
  //   {
  //     name: "ByteBit",
  //     quantity: "2",
  //     cate1: "/assets/images/company-2.png",
  //   },
  //   {
  //     name: "Terrestrio",
  //     quantity: "4",
  //     cate1: "/assets/images/company-3.png",
  //   },
  //   {
  //     name: "ZUACT",
  //     quantity: "2",
  //     cate1: "/assets/images/company-4.png",
  //   },
  //   {
  //     name: "Processfuel",
  //     quantity: "4",
  //     cate1: "/assets/images/company-5.png",
  //   },
  //   {
  //     name: "Finance Methods",
  //     quantity: "2",
  //     cate1: "/assets/images/company-6.png",
  //   },
  //   {
  //     name: "ShipHope",
  //     quantity: "4",
  //     cate1: "/assets/images/company-1.png",
  //   },
  // ];

  return (
    <>
      <div className="container mt-lg-5 mt-md-4 mt-3 professional-parent">
        <div className="row">
          <div className="col-md-12">
            <div className="new-arrivals service-nr">
              <div className="slider-section">
                <h6 className="cate-head mb-3">
                  Categories 
                </h6>
                <ReactOwlCarousel
                  className="owl-theme owl-feature-slider owl-slider-arrows"
                  {...recent_options}

                >
                  {Categoriesprofesional.map((product, i) => (
                    <div className="d-flex gap-2 flex-column hr-2row" key={i} onClick={() => navigate("/service-categorys/" + product.slug)}>
                      <div className="text-center h-50">
                        <div className="prof-img">
                          <img src={product.icon} />
                        </div>
                        <h5>{product.name}</h5>

                      </div>

                    </div>
                  ))}
                </ReactOwlCarousel>
              </div>
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="new-arrivals service-nr">
              <div className="slider-section">
                <h6 className="cate-head mb-3">
                  Digital{" "}
                  <span className="fs-6">
                    <Link to="/categorys/digital-services">View All</Link>
                  </span>
                </h6>
                <ReactOwlCarousel
                  className="owl-theme owl-feature-slider owl-slider-arrows my-2"
                  {...recent_options}
                >
                  {Categoriesdigital.map((product) => (
                    <div className="d-flex gap-2 flex-column hr-2row">
                      <div className="text-center ">
                        <div className="prof-img">
                          <img src={product.icon} />
                        </div>
                        <h5>{product.name}</h5>
                      </div>

      
                      <div className="text-center">
                        <div className="prof-img">
                          <img src={product.cate1} />
                        </div>
                        <h5>{product.name}</h5>
                      </div>
                    </div>
                  ))}
                </ReactOwlCarousel>
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-6">
            <div className="new-arrivals">
              <div className="slider-section">
                <h6 className="cate-head mb-3">Digital</h6>
                <ReactOwlCarousel
                  className="owl-theme owl-feature-slider owl-slider-arrows"
                  {...recent_options}
                >
                  {Categories.map((product) => (
                    <div className="text-center">
                      <div className="prof-img">
                        <img src={product.cate1} />
                      </div>
                      <h5>{product.name}</h5>
                    </div>
                  ))}
                </ReactOwlCarousel>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div className="container-fluid mt-6 no-padding">
                <img src="/assets/images/home-section-5.png" width="100%"/>
            </div> */}
    </>
  );
};

export default Professional;
