import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import EyeIcon from '../components/dashboard/dash-eye.png'
import CopyIcon from '../components/dashboard/copy.png';
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const TruncHtml = require('trunc-html');
const VendorProducts = () => {

    const [page, setPage] = useState([]);
    const [imgUrl, setImgUrl] = useState('');
    const token = localStorage.getItem('avonToken');


    useEffect(() => {
        FetchPages();
    }, [])

    const FetchPages = async () => {

        await axios.get(BASE_URL + '/getsellerpage', {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setPage(data.data)
            setImgUrl(data.image_base_url)
        })
    }

    const DeleteFaq = async (id) => {
        await axios.delete(BASE_URL + `/deletesellerpage/${id}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            FetchPages();
            console.log('dbghdb', data)
            if (data.status == true) {
                toast.success(data.message)
            }
        }).catch(({ response }) => {
            // console.log(response.data)
            if (response.data.status == 'error') {
                toast.error(response.message)
            }
        })
    }



    return (
        <Page title="Vendor Products">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Other Pages</li>
                        </ul>
                        <div className="col-md-12 ">
                            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                                Customer Dashboard
                            </Button>
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <VendorDashboardSideMenu />
                            <div className="col-md-9">
                                <h4 className="bold">
                                    Other Pages
                                    <Link to="/vendor-add-page">
                                        <Button style={{ float: "right", backgroundColor: '#00B987' }} variant="success">

                                            Add New Pages
                                        </Button>
                                    </Link>
                                </h4>

                                <div className="vendor-order-section">
                                    <div className="product-card-block">
                                        <div className="order-head-blk">
                                            <h5>Other Pages</h5>

                                        </div>
                                        <div className="card-body-blk">
                                            <Table responsive="md" hover>
                                                <thead>
                                                    <tr>

                                                        <th >Page Name</th>
                                                        <th>URL Slug</th>
                                                        <th>Page Content</th>
                                                        <th>Image</th>
                                                        <th>Status</th>
                                                        <th>Options</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {page && page.map((product) => (
                                                        <tr key={product.id}>
                                                            <td style={{ padding: '13px' }}>{product.title}</td>
                                                            <td style={{ padding: '13px' }}>{product.slug}</td>
                                                            <td style={{ padding: '13px' }}><div dangerouslySetInnerHTML={{ __html: TruncHtml(product.content, 150).html }}></div></td>
                                                            <td style={{ padding: '13px' }}><img src={imgUrl + product.image} style={{ height: 20, width: 20 }} /></td>
                                                            <td style={{ padding: '13px' }}>{product.status}</td>
                                                            <td>
                                                                <Link to="/vendor-view-page" state={product.id} hash="view">  <img style={{ paddingLeft: '5px' }} src={EyeIcon} alt="" /> </Link>
                                                                <Link to="/vendor-edit-page" state={product.id}>  <img style={{ paddingLeft: '5px' }} src={EditIcon} alt="" /> </Link>
                                                                <img style={{ paddingLeft: '5px' }} src={DeleteIcon} alt="" onClick={() => DeleteFaq(product.id)} />
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Page>
    );
};

export default VendorProducts;
