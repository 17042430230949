import React from 'react'
import "../../style/Startup.css"
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";


export const StartupCategory = () => {
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      490: {
        items: 3,
      },
      650: {
        items: 4,
      },
      830: {
        items: 5,
      },
      1080: {
        items: 6,
      },
      1200: {
        items: 7,
      },

      1280: {
        items: 8,
      },
    },
  };
  const Categories = [
    {
      name: 'Ship Hope',
      quantity: "4",
      cate1: "/assets/images/company-1.png"
    },
    {
      name: 'Byte Bit',
      quantity: "2",
      cate1: "/assets/images/company-2.png"
    },
    {
      name: 'terrestrio',
      quantity: "4",
      cate1: "/assets/images/company-3.png"
    },
    {
      name: 'Zuact',
      quantity: "2",
      cate1: "/assets/images/company-4.png"
    },
    {
      name: 'Process fuel',
      quantity: "4",
      cate1: "/assets/images/company-5.png"
    },
    {
      name: 'Finance Method',
      quantity: "2",
      cate1: "/assets/images/company-6.png"
    },
    {
      name: 'Zuact',
      quantity: "2",
      cate1: "/assets/images/company-4.png"
    },
   
   
  ]


  return (
    <>
      <div className="startup-category my-lg-5 my-md-3 my-3">

        <div className="cat-cards px-lg-4 px-3 py-lg-4 py-3 mb-2">
          <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
            {Categories.map((product) => (
              <div className="card border-0 text-center shadow-sm mb-2">
                <div className="logo-div">
                <img src={product.cate1} />
                </div>
                <h5>{product.name}</h5>
               
              </div>
            ))}
          </ReactOwlCarousel>
        </div>

      </div>

    </>
  )
}
