import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import SendWithdrawRequest from "../components/money-modal/withdraw-req.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Table, Button, Row, Col, Card, Badge } from "react-bootstrap";

const moneyData = [
  {
    id: 1,
    date: "28-09-2021",
    amount: "$100.00",
    status: "Pending",
    message: "My Withdraw",
  },
  {
    id: 2,
    date: "28-09-2021",
    amount: "$100.00",
    status: "Pending",
    message: "My Withdraw",
  },
  {
    id: 3,
    date: "28-09-2021",
    amount: "$100.00",
    status: "Pending",
    message: "My Withdraw",
  },
];

const MoneyWithdraw = () => {
  function handleAdd() {
    setShowAdd(true);
  }

  const [showAdd, setShowAdd] = useState(false);
  return (
    <Page title="Money Withdraw">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Money Withdraw</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Money Withdraw</h4>
             
                <div style={{ padding: "8px 0 0 0" }}>
                  <div className="row">
                    <Col xs={12} md={6}>
                      <Card
                      className="money-withdrl-bal"
                       
                      >
                        <Card.Body>
                          <Row>
                            <div className="balance-circle"></div>
                            <Col>
                              <Card.Title
                                style={{ fontSize: "20px" }}
                              >
                                $100.00
                              </Card.Title>
                              <Col>Pending Balance</Col>

                              {/* <Card.Subtitle className="mb-2 text-muted">Products</Card.Subtitle> */}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} md={6}>
                      <Card
                        className="money-withdrl-req mobile-margin-1"
                        onClick={handleAdd}
                      >
                        <Card.Body>
                          <Row>
                            <div className="withdraw-circle"></div>
                            <Col>
                              <Card.Title
                                style={{ fontSize: "17px", paddingTop: '12px' }}
                              >
                                Send A Withdraw Request
                              </Card.Title>
                             

                              {/* <Card.Subtitle className="mb-2 text-muted">Products</Card.Subtitle> */}
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                 
                 
                  </div>
                </div>
               

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h5>Withdraw Request History</h5>
                    </div>
                    <div className="card-body-blk">
                      <Table responsive="md" hover>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {moneyData.map((money) => (
                            <tr key={money.id}>
                              <td>{money.id}</td>
                              <td>{money.date}</td>
                              <td>{money.amount}</td>
                              <td><Badge bg="warning">{money.status}</Badge></td>
                              <td>{money.message}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <SendWithdrawRequest showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default MoneyWithdraw;
