import React from 'react';
import "../../style/Sellerbenefites.css";
import Slider from "react-slick";

export const EdComp4 = () => {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: false,
        arrows: true,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 400,
            breakpoint: 800,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: false
            }
        }]
    };

    return (
        <>
            <div className="seller-testimonial py-4 my-lg-5 my-md-4 my-3">

                <div>

                </div>

                <div className="container text-center my-3">
                    <Slider {...settings} >

                            <div className='investory-slider'>
                                <div className='col-md-6'>
                                    <div className='investor-details'>
                                        <div className='d-flex'>
                                            <img src='/assets/images/comma.png' width="20px" />
                                            <img src='/assets/images/comma.png' width="20px" />
                                        </div>
                                        <p>If you look at the Verified Suppliers, you'll be able to see that they've been verified by independent third parties, and factory visits. It gives you inside look into the supplier.</p>
                                        <div className='user-details-1'>
                                            <img src="/assets/images/user-2.png" width="50px" />
                                            <div>
                                                <h4>Dr. Green</h4>
                                                <p>Owner</p>
                                                
                                            </div>
                                        </div>
                                        <div className='user-details-1'>
                                            <img src='/assets/images/comp.png' width="20px" />
                                            <h5>Sill Farm Supplier</h5>
                                            <img src= "/assets/images/flag.png" width="20px" />
                                            <h5>China</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <img src="/assets/images/investor-1.png" width="100%" />
                                </div>
                            </div>

                            {/* 2nd------ */}
                            <div className='investory-slider'>
                                <div className='col-md-6'>
                                    <div className='investor-details'>
                                        <div className='d-flex'>
                                            <img src='/assets/images/comma.png' width="20px" />
                                            <img src='/assets/images/comma.png' width="20px" />
                                        </div>
                                        <p>If you look at the Verified Suppliers, you'll be able to see that they've been verified by independent third parties, and factory visits. It gives you inside look into the supplier.</p>
                                        <div className='user-details-1'>
                                            <img src="/assets/images/user-2.png" width="50px" />
                                            <div>
                                                <h4>Dr. Green</h4>
                                                <p>Owner</p>
                                                
                                            </div>
                                        </div>
                                        <div className='user-details-1'>
                                            <img src='/assets/images/comp.png' width="20px" />
                                            <h5>Sill Farm Supplier</h5>
                                            <img src= "/assets/images/flag.png" width="20px" />
                                            <h5>China</h5>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <img src="/assets/images/investor-1.png" width="100%" />
                                </div>
                            </div>
                    
                    </Slider>
                </div>
            </div>

        </>
    )
}
