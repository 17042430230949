import React, { useState } from 'react'
import "../../style/SellerDetails.css"
import { Link } from 'react-router-dom'
import { IMAGE_BASE_URL } from '../../config'
export const SdComp2 = (props) => {
    const [isTrusted,setisTrusted] = useState(true)
    const [isVarified,setisVarified] = useState(true)
    return (
        <>

            <div className="seller-detials-comp2 mb-lg-5 mb-4">
                <div className="container d-flex justify-content-between gap-3">
                    <div className="company-logo-div d-flex flex-column gap-lg-3 gap-md-3 gap-2 align-items-center ">
                        <img src={IMAGE_BASE_URL+props?.data?.company_logo} className='logo' alt="" width = "213px"/>
                        <div className="d-flex gap-2 justify-contetn-between">
                            {
                                props?.data?.trust_seal == "Yes" && <img src="/assets/images/verified.png" className='verified-logo' alt="" width="111px" height={"55px"} />
                            }
                            {
                                props?.data?.verified_seller == "Yes" && <img src="/assets/images/verified.png" className='verified-logo' alt="" width="111px" height={"55px"} />  
                            }
                        </div>
                    </div>
                    <div className="company-name pt-4">
                        <h1>{props?.data?.startup_name}</h1>
                        <div className="d-flex gap-3 align-items-center my-lg-3 my-3">
                            <div className="d-flex gap-2 align-items-center">
                                <img src={`https://flagsapi.com/${props?.data?.countries?.code}/flat/64.png`} alt="Country Logo" />
                                <h4 className='mb-0'>{props?.data?.countries?.code}</h4>
                            </div>

                            <p className='mb-0 ps-2'>{props?.data?.address}</p>
                        </div>
                            {props?.data?.business_category.map((item,i)=>(
                                <button className='mx-1'>{item?.category_name}</button>
                            ))}
                        
                    </div>

                    <div className="contact-details d-flex flex-column gap-2 pt-4">
                        <Link to={"tel:"+props?.data?.mobile_number} style={{ backgroundColor: "#1351C1", color: "#fff" }}><span className='pe-2 '><i class="fa fa-phone"></i></span> Call Now</Link>
                        <Link to={"mailto:"+props?.data?.email}><span className='pe-2 '><i class="fa fa-share"></i></span> Send Enquiry</Link>
                        {/* <Link to={"https://api.whatsapp.com/send?phone=" + props?.data?.data?.whatsapp_number} target="_blank"><span className='pe-2 '><i class="fa fa-whatsapp"></i></span> Chat</Link> */}
                    </div>
                </div>
            </div>
        </>
    )
}
