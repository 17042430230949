import React, { useState, useEffect } from "react";
import { Table, Button, Form, Modal, ModalHeader, ModalBody } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL, IMAGE_BASE_URL } from "../../config";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const SellerSubscription = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('avonToken');
  const [paymenthistory, setpaymenthistory] = useState([]);
  const [sellersub, setsellersub] = useState('');
  const [sellerdata, setsellerdata] = useState('');
  const [subject, setsubject] = useState([]);
  const [packages, setpackage] = useState('');
  const [reason, setreason] = useState('');
  const [remark, setremark] = useState('');
  const [image, setimage] = useState('');
  const [file, setfile] = useState('');
  const [refundtype, setrefundtype] = useState('');
  const [error, setError] = useState('');
  const [errormsg, setErrormsg] = useState('');
  const [cancelledex, setcancelledex] = useState('');
  const [isupgrade, setisupgrade] = useState('');
  const [isexpire, setisexpire] = useState('no');
  const [show, setShow] = useState(false);
  useEffect(() => {
    fetchpaymenthistory();
    fetchsellerdata();
    fetchsubject();
  }, [])
  const fetchpaymenthistory = async () => {
    if (token) {
      await axios.get(BASE_URL + '/getpaymenthistory', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {

        setpaymenthistory(data.data)
        // console.log("profile", data.data)

      })
    }

  }
  console.log(paymenthistory, "payment history");
  const fetchsubject = async () => {
    await axios.get(BASE_URL + '/getsubject').then(({ data }) => {
      setsubject(data.data)

    })
  }
  const fetchsellerdata = async () => {
    if (token) {
      await axios.get(BASE_URL + '/getsubscriptiondetail', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {

        setsellersub(data.data)
        setcancelledex(data.expire)
        setpackage(data.data.package)
        setsellerdata(data.seller)
        setisupgrade(data.upgrade)
        setisexpire(data.planexpire)
        // console.log("profile", data.data)

      })

    }

  }
  const handleimage = (e) => {
    setimage(e.target.files[0])
    setfile(URL.createObjectURL(e.target.files[0]))
  }
  const cancelsubscription = async () => {
    setError("")
    setErrormsg("")
    var data = new FormData();
    data.append('image', image);
    data.append('reason', reason);
    data.append('remarks', remark);
    data.append('refund_method', refundtype);
    await axios.post(BASE_URL + '/cancelsubscription', data, {
      headers: {
        // 'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      if (data.status == true) {
        setimage("")
        toast.success(data.message)
        navigate('/cancel-subscription', { state: data })
        // fetchaboutus();

      }
      if (data.status == false) {
        toast.error(data.message)
        setErrormsg(data.message)

      }

    }).catch(({ response }) => {
      console.log(response.data)
      if (response.data.status_code == 422) {
        setError(response.data.message)
      }
    })
  }
  return (
    <>
      <div className="conatiner-fluid subscription-bg-color">
        <div className="container">
          <div className="subscription-content-bg">
            <h2>Subscription Plan <Button className="mobile-margin-3" onClick={() => navigate('/vendor-dashboard')}>Go Back</Button></h2>
            <p>You are now a Premium member at Aladdin Direct INC, a Trusted SEAL will appear in your Company Listing</p>
            <hr />
            <div className="row mt-top-1">
              <div className="col-md-6">
                <div className="mr-right-1">
                  <h4>{packages.package_name}</h4>
                  <p>You can upgrade your Plan to the Next level to get more benefits.</p>
                  <div className="subscription-plan-card">
                    <div className="subscription-plan">
                      <p> <img src="./assets/images/green-dot.png" /> Pro</p>
                      <button>Billed Yearly</button>
                    </div>
                    <p><b><i className="fa fa-inr"></i>{sellersub.subtotal} INR</b> {isexpire == "yes" ? (
                      <span>(Plan Expired On {sellerdata.package_expiryat})</span>
                    ) : (
                      <span>(next renew {sellerdata.package_expiryat})</span>
                    )}
                    </p>
                  </div>
                  <div>
                    {isupgrade == "yes" && <button className="upgrade-plan-btn" onClick={() => navigate('/packages', { state: packages.id })}>Upgrade Now</button>}
                    <button className="upgrade-plan-btn" onClick={() => navigate('/vendor-checkout', { state: packages.id })}> Renew Earlier</button>
                    <button className="cancel-plan-btn mobile-margin-3">Close My Business</button>
                    {(cancelledex == "no" && sellerdata.customer_cancelled == "no") && <Link to="#" onClick={() => setShow(true)}><button className="cancel-plan-btn">Cancel Subscription</button></Link>}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mr-right-1">
                  <h4 className="mobile-margin-1">Payment Method</h4>
                  <p>Your payment method Detail</p>
                  <div className="subscription-plan-card">
                    <div className="">
                      <p> {sellersub.paymentmethod == "online" ? <img src="./assets/images/visa.png" /> : <b>Local Bank Transfer:-</b>}  Last Used: <b>{sellersub.created_at}</b></p>
                    </div>
                    {/* <p><b>$2.99 USD</b> <span>(next renew 2 jan 2021)</span></p> */}
                  </div>
                  <p>Note: we do not save your payment method in our records, its solely at your Banking Institution</p>
                </div>
              </div>
            </div>
            <hr />
            <div className="subscription-details mt-top-1">
              <div>
                <h5>Payment History</h5>
                <p>See history your payment & invoices</p>
              </div>
              <Table responsive="md">
                <thead>
                  <tr>
                    <th>Payment Invoice <img src="./assets/images/swap.png" /></th>
                    <th>Amount <img src="./assets/images/swap.png" /></th>
                    <th>Date <img src="./assets/images/swap.png" /></th>
                    <th>Status <img src="./assets/images/swap.png" /></th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {paymenthistory && paymenthistory.map((item, i) => (
                    <tr>
                      <td>{item.invoice_id}</td>
                      <td><i className="fa fa-inr"></i>{item.subtotal}</td>
                      <td>{item.created_at}</td>
                      <td>{item.paymentstatus}</td>
                      <td><a href={IMAGE_BASE_URL+item.pdf_url} target="_blank" download={item.invoice_id+".pdf"}><img src="./assets/images/subscription-download.png" width="7%" /> Download</a></td>
                    </tr>
                  ))}


                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <ModalHeader closeButton>
          <h6> Oh, we are sad to see you here</h6>
        </ModalHeader>
        <ModalBody>

          <h6>Please let us know, why you want to leave Aladdin Direct INC.</h6>
          {errormsg && <div className="text-danger">{errormsg}</div>}
          <div className="mt-4 mb-4">
            <Form.Label>Reason</Form.Label>
            <Form.Select onChange={(e) => setreason(e.target.value)}>
              <option value="">--Select Reason--</option>
              {subject && subject.map((item, i) => (
                <option value={item.id}>{item.title}</option>
              ))}
            </Form.Select>
            {error.reason && <div className="text-danger">{error.reason}</div>}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Enter Remarks</Form.Label>
            <Form.Control as="textarea" rows={3} onChange={(e) => setremark(e.target.value)} />
            {error.remarks && <div className="text-danger">{error.remarks}</div>}
          </div>
          <div className="mt-4 mb-4">
            {/* <Form.Label>Image</Form.Label> */}
            <Form.Control type="file" onChange={handleimage} />
            {error.image && <div className="text-danger">{error.image}</div>}
          </div>
          <h6>Refund Request</h6>
          <div className="mt-4 mb-4">
            <Form.Label>Please Select your Refund Method</Form.Label>
            <Form.Select onChange={(e) => setrefundtype(e.target.value)}>
              <option value="">--Select Refund--</option>
              <option value="wallet"> I want refund in my Virtual Wallet</option>
              <option value="bank">Refund in my Source Account</option>
            </Form.Select>
            {error.refund_method && <div className="text-danger">{error.refund_method}</div>}
          </div>
        </ModalBody>

        <Modal.Footer>
          <Button onClick={cancelsubscription}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  )
}

export default SellerSubscription;