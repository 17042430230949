import React, { useState } from 'react'

export const TabComp = () => {

const [currTab, setCurrTab]  = useState("startup")


  // left side tab buttons 
  const handleLinkTab = (event, currSec) => {
    removeActiveClass();
    event.stopPropagation();
    setCurrTab(currSec)

    const clickedButton = event.currentTarget;
    clickedButton.classList.add("active-build-ms-stab");
  };

  function removeActiveClass() {
    const tabButtons = document.getElementsByClassName("toggle-link");
    const spanElm = document.querySelectorAll(".toggle-link span");

    for (const lis of tabButtons) {
      lis.classList.remove("active-build-ms-stab");
    }

    for (const spans of spanElm) {
      spans.classList.remove("active-build-ms-stab");
    }
  }

  return (
    <>
      <div className="tab-comp px-lg-5 px-md-4 px-3 my-lg-5 my-md-4 my-3" >

        <div className="main-content d-flex justify-content-between ">
          <div className="left mb-4">
            <div className="fix-content">
              <div className="rounded-div ms-2"></div>
              <div className="left-content">
                <ul className="ps-0">
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between active-build-ms-stab"
                    onClick={(event) => handleLinkTab(event, "startup")}
                  >
                    <span>Build Startup</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "portfolio")}
                  >
                    <span>Balanced Portfolio</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>

                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "goal1")}
                  >
                    <span>Reach Your Goal</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>


                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "goal2")}
                  >
                    <span>Reach Your Goal</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>


                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "hreturn")}
                  >
                    <span>High Returns</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "stability")}
                  >
                    <span>Business Stability</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>

                </ul>
              </div>
            </div>
          </div>
          <div className="right py-lg-5  ">

            {currTab ==='startup' ? 
                // Right Comp1  
                <div className="rsec-1 m-4">
                <img src="https://apnashaher.com/admin-login/public/storage/upload/all/StEvHEOnS3W03Cr0JFAwHCVbskzfHzAaKRiVYxCo.png" alt="" />
                <p className='pe-4 bold-para'>
                  ApnaShaher provides you the opportunity to diversify your investments from traditional fixed income asset classes to help you earn higher returns
                </p>
              </div>
  
            : null
          }
        
          </div>
        </div>

      </div>
    </>
  )
}
