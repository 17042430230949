import React from "react";

const ContactUs = (props) => {
    console.log(props)
    return (
        <>
            <div className="container seller-pd-1">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Contact us</h1>
                        <div className="seller-contact-details">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <p>{props.contactus && props?.contactus?.data.full_address}</p>
                        </div>
                        <div className="seller-contact-details">
                            <i class="fa fa-mobile" aria-hidden="true"></i>
                            <p>{props.contactus && props?.contactus?.data?.business_mobilenumber.join(",")}</p>
                        </div>
                        <div className="seller-contact-details">
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                            <p>{props.contactus && props?.contactus?.data?.whatsapp_number}</p>
                        </div>
                        <div className="seller-contact-details">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <p>{props.contactus && props?.contactus?.data?.business_email.join(",")}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <iframe
                            src={props?.contactus?.data?.map_url}
                            width="600"
                            height="350"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>

                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs;