import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { NavLink } from "react-router-dom";
import { Container, Table, Badge, Button } from "react-bootstrap";
import EyeIcon from "../components/dashboard/dash-eye.png";
import DownloadIcon from "../components/dashboard/Download.svg";

import Page from "../components/Page";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";

const purchaseData = [
    {
        id: 1,
        code: "#1234567890",
        customerName: "Cedric Kelly",
        productQty: 204,
        dateTime: "28-09-2021 10:30",
        orderAmt: "Rs 500",
        paymentStatus: "Paid",
        orderStatus: "Confirmed *",
        mobileNo: 1234567890,
        emailId: 'user@avon.com'
    },
    {
        id: 2,
        code: "#1234567890",
        customerName: "Cedric Kelly",
        productQty: 204,
        dateTime: "28-09-2021 10:30",
        orderAmt: "Rs 500",
        paymentStatus: "Paid",
        orderStatus: "Confirmed *",
        mobileNo: 1234567890,
        emailId: 'user@avon.com'
    },
    {
        id: 3,
        code: "#1234567890",
        customerName: "Cedric Kelly",
        productQty: 204,
        dateTime: "28-09-2021 10:30",
        orderAmt: "Rs 500",
        paymentStatus: "Paid",
        orderStatus: "Confirmed *",
        mobileNo: 1234567890,
        emailId: 'user@avon.com'
    },
    {
        id: 4,
        code: "#1234567890",
        customerName: "Cedric Kelly",
        productQty: 204,
        dateTime: "28-09-2021 10:30",
        orderAmt: "Rs 500",
        paymentStatus: "Paid",
        orderStatus: "Confirmed *",
        mobileNo: 1234567890,
        emailId: 'user@avon.com'
    },
    {
        id: 5,
        code: "#1234567890",
        customerName: "Cedric Kelly",
        productQty: 204,
        dateTime: "28-09-2021 10:30",
        orderAmt: "Rs 500",
        paymentStatus: "Paid",
        orderStatus: "Confirmed *",
        mobileNo: 1234567890,
        emailId: 'user@avon.com'
    },
];



const VendorAllQuery = () => {
    const [data, setData] = useState([]);
    const token = localStorage.getItem('avonToken');

    useEffect(() => {
        FetchData();
    }, [])

    const FetchData = async () => {
        await axios.get(BASE_URL + '/getquery', {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            setData(data.data)
            // setImageUrl(data.image_base_url)
        })
    }
    console.log(data, "data all query");
    return (
        <Page title="Purchase History">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>All Query</li>
                            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                                Customer Dashboard
                            </Button>
                        </ul>
                        {/* <div>
              <Button className="toggle-button" variant="warning">
                Seller Info
              </Button>
            </div> */}
                    </div>
                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <VendorDashboardSideMenu />
                            <div className="col-md-9">
                                <h4>All Query</h4>
                                <div className="vendor-order-section">
                                    <div className="card-block">
                                        <div className="order-head-blk">
                                            <h5>All Query</h5>
                                        </div>
                                        <div className="card-body-blk ">
                                            <div class="table-responsive">

                                                <Table responsive="md" hover style={{ whiteSpace: "nowrap" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Date & Time</th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email</th>
                                                            <th>Image</th>
                                                            <th> Status</th>
                                                            
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((purchase) => (
                                                            <tr key={purchase.id}>
                                                                <td>{purchase.created_at}</td>
                                                                <td>{purchase.name}</td>
                                                                <td>{purchase.mobile_number}</td>
                                                                <td>{purchase.email}</td>
                                                                <td>< img src={IMAGE_BASE_URL + purchase.image} style={{ maxWidth: 50, maxHeight: 50 }} /></td>
                                                                <td>{purchase.status}</td>
                                                                
                                                               
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="card-body-blk">
                    <h5 className="dashboardOrderList">Purchase Order List</h5>
                    <DashboardPurchaseList />
                  </div> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Page>
    );
};
export default VendorAllQuery;
