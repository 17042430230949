import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ImageUploader from './Components/ImageUploader';

const CarForm = () => {
    const [image, setImage] = useState({ preview: "", raw: "" });

    const handleChange = e => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };

    return (
        <div>
            <h1 className='form-heading'>POST YOUR AD</h1>
            <div className='seller-forms'>
                <div>
                    <h5>SELECTED CATEGORY</h5>
                    <p className='form-pd-left form-mt-2'>Cars</p>
                </div>
                <hr />
                <div className='seller-form-pd'>
                    <h4 className='form-mt-3'>INCLUDE SOME DETAILS</h4>
                    <Form.Label className='form-mt-1'>Brand*</Form.Label>
                    <Form.Select aria-label="Default select example">
                        <option>Select the car brand </option>
                        <option value="1">Tata</option>
                        <option value="2">Hundai</option>
                        <option value="3">Honda</option>
                    </Form.Select>
                    <Form.Group className="mb-3 form-mt-2" controlId="formBasicEmail">
                        <Form.Label>Year*</Form.Label>
                        <Form.Control type="text" placeholder="Enter year" />
                    </Form.Group>
                    <div className='fuel-section form-mt-2'>
                        <p>Fuel*</p>
                        <button>CNG & Hybrids</button>
                        <button>Diesel</button>
                        <button>Electric</button>
                        <button>LPG</button>
                        <button>Petrol</button>
                    </div>
                    <div className='fuel-section form-mt-1'>
                        <p>Transmission *</p>
                        <button>Automatic</button>
                        <button>Manual</button>
                    </div>
                    <Form.Group className="mb-3 form-mt-1" controlId="formBasicEmail">
                        <Form.Label>KM driven*</Form.Label>
                        <Form.Control type="text" placeholder="How many km car driven" />
                    </Form.Group>
                    <div className='fuel-section form-mt-1'>
                        <p>No. of Owners*</p>
                        <button>Ist</button>
                        <button>2nd</button>
                        <button>3rd</button>
                        <button>4th</button>
                        <button>4+</button>
                    </div>
                    <Form.Group className="mb-3 form-mt-1" controlId="formBasicEmail">
                        <Form.Label>Ad title*</Form.Label>
                        <Form.Control type="text" placeholder="Ad title " />
                        <Form.Text className="text-muted">
                            Mention the key features of your item (e.g. brand, model, age, type)
                        </Form.Text>
                    </Form.Group>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Description *</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        <small id="emailHelp" class="form-text text-muted">Include condition, features and reason for selling</small>
                    </div>
                </div>
                <hr />
                <div className='price-input-group'>
                    <h2>SET A PRICE</h2>
                    <Form.Label className='form-mt-1'>Price *</Form.Label>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">₹</InputGroup.Text>
                        <Form.Control
                            placeholder="Username"
                            aria-label="Username"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <hr />
                <div className='seller-image-uploader'>
                    <h2>UPLOAD UP TO 5 PHOTOS</h2>
                    <ImageUploader />
                </div>
                <hr />
                <div className='location-section'>
                    <h2>CONFIRM YOUR LOCATION</h2>
                    {/* <div className='button-your-location'>
                        <button className='list-btn' style={{ borderBottom: list ? "3px solid #000" : "" }} onClick={handleList}>LIST</button>
                        <button className='location-btn' style={{ borderBottom: location ? "3px solid #000" : "" }} onClick={handleLocation}>CURRENT LOCATION</button>
                    </div> */}
                    <div>
                        <Form.Label className='form-mt-1'>Country*</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select Country </option>
                            <option value="1">India</option>
                            <option value="2">America</option>
                            <option value="3">Canada</option>
                        </Form.Select>
                    </div>
                    <div>
                        <Form.Label className='form-mt-1'>State*</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select State </option>
                            <option value="1">Delhi</option>
                            <option value="2">Haryana</option>
                            <option value="3">Uttar Pradesh</option>
                        </Form.Select>
                    </div>
                    <div>
                        <Form.Label className='form-mt-1'>City*</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Select City </option>
                            <option value="1">Delhi</option>
                            <option value="2">Haryana</option>
                            <option value="3">Uttar Pradesh</option>
                        </Form.Select>
                    </div>

                </div>
                <hr />
                <div className='review-details-section'>
                    <h2>Review Your Details</h2>
                    <div className='review-uploader'>
                        <div>
                            <label htmlFor="upload-button">
                                {image.preview ? (
                                    <img src={image.preview} alt="dummy" width="300" height="300" />
                                ) : (
                                    <>
                                        <span className="fa-stack fa-2x mt-3 mb-2">
                                            <i className="fas fa-circle fa-stack-2x" />
                                            <i className="fas fa-store fa-stack-1x fa-inverse" />
                                        </span>
                                        {/* <h5 className="text-center">Upload your photo</h5> */}
                                    </>
                                )}
                            </label>
                            <input
                                type="file"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={handleChange}
                            />
                            <br />
                            {/* <button>Upload</button> */}
                        </div>
                        <div>
                            <Form.Group className="mb-3 form-mt-1" controlId="formBasicEmail">
                                <Form.Label className='profile-name'>Name</Form.Label>
                                <Form.Control type="text" placeholder="John cina" />
                            </Form.Group>
                        </div>
                    </div>
                    <div className='your-phone-number'>
                        <p>Your Phone Number</p>
                        <h6>76782626277</h6>
                    </div>
                </div>
                <hr />
                <div className='post-now'>
                    <button>Post Now</button>
                </div>
            </div>
        </div>
    )
}

export default CarForm;