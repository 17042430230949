import React, { useContext, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Container, Col, Form, Dropdown, Button, Tab, Tabs } from 'react-bootstrap';
import DashboardSideMenu from '../components/DashboardSideMenu';
import { NavLink, Link } from 'react-router-dom';
import Axios from 'axios';
import TrackOrder from './TrackOrder';

import Page from '../components/Page';
import ProductGridItem from '../components/product/product-grid-item.component';
import StateContext from '../context/StateContext';
import DispatchContext from '../context/DispatchContext';

const ControlledTabs = () => {
    const [key, setKey] = useState('Active Orders');

    return (
        <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3">
            <Tab eventKey="active-order" title="Active Orders">

            </Tab>
            <Tab eventKey="delivered-order" title="Delivered Orders">

            </Tab>
            <Tab eventKey="cancelled-order" title="Cancelled Orders">

            </Tab>
        </Tabs>
    );
}

const MyOrder = () => {
    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);

    const [products, setProducts] = useState([]);
    const [trackButton, setTrackButton] = useState('Track Package');
    const [requestButton, setRequestButton] = useState('Request Cancellation');
    const [returnButton, setReturnButton] = useState('Return Product');
    const [reviewButton, setReviewButton] = useState('Product Reviews');
    const [feedbackButton, setFeedbackButton] = useState('Leave Seller Feedback');

    useEffect(() => {
        const userRequest = Axios.CancelToken.source();
        async function fetchData() {
            try {
                const response = await Axios.get('/wishlist', {
                    headers: {
                        Authorization: `Bearer ${appState.state.user.token}`,
                    },
                });
                const responseData = response.data.data;
                setProducts(responseData.wishlisted_products);
            } catch (e) {
                if (e.response.status === 401) {
                    appDispatch({ type: 'logout' });
                } else {
                    console.log('Error encountered');
                }
            }
        }
        fetchData();
        return () => {
            userRequest.cancel();
        };
    }, []);
    return (
        <Page title="My Orders">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>My Orders</li>
                            <Button
                style={{ float: "right" }}
                href="/vendor-dashboard"
                variant="primary"
              >
                Seller Dashboard
              </Button>
                        </ul>

                    </div>
                </section>
                <section>
                    <div className="container-fluid">
                        <div className="row">
                            <DashboardSideMenu />
                            <div className="col-md-9">
                                <div className="card-block">
                                    <div className="card-head-blk">
                                        <h4>My Orders</h4>

                                    </div>
                                    <ControlledTabs />
                                    <div className="orders-grid">
                                        <Container>
                                            <Row xs={3} md={3}>
                                                <Col>Order Date & Time</Col>
                                                <Col>Shipping To</Col>
                                                <Col>Order #677-76654372</Col>
                                                <Col>22nd Apr, 2012</Col>
                                                <Col>
                                                    None
                                                </Col>
                                                <Col>
                                                    <a href="#">View Order Detail</a>
                                                    <span> <a href="#">Download Invoice</a> </span>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="card-body-blk">
                                        <Container style={{ border: '1px solid #D3D3D3', paddingBottom: '7px', boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.5)' }}>
                                            <Row>
                                                <p style={{ fontSize: 18 }}>Touch Phone</p>
                                                <img className='order-img' src="assets/images/product-1.jpg" alt="" />
                                                <Col>
                                                    <p className="order-price-detail">Price: $210.00</p>
                                                    <Col>
                                                    <Button href="/TrackOrder" style={{ marginLeft: '7px', borderRadius: 0 }} variant="outline-warning" type="submit">
                                                            {trackButton}
                                                    </Button>
                                                        
                                                    </Col>
                                                </Col>

                                                <Col>
                                                    <Button style={{ borderRadius: 0 }} variant='primary'>{requestButton}</Button>
                                                    <Col>
                                                        <Button style={{ marginTop: '5px', borderRadius: 0 }} variant='outline-secondary'>{returnButton}</Button>
                                                        <Col>
                                                            <Button style={{ marginTop: '5px', borderRadius: 0 }} variant='outline-secondary'>{reviewButton}</Button>
                                                            <Col>
                                                                <Button style={{ marginTop: '5px', borderRadius: 0 }} variant='outline-secondary'>{feedbackButton}</Button>
                                                            </Col>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                            </Row>



                                        </Container>



                                    </div>
                                    <div className="card-body-blk">
                                        <Container style={{ border: '1px solid #D3D3D3', paddingBottom: '7px', boxShadow: '0px 5px 15px 0px rgba(0, 0, 0, 0.5)' }}>
                                            <Row>
                                                <p style={{ fontSize: 18 }}>Touch Phone</p>
                                                <img className='order-img' src="assets/images/product-1.jpg" alt="" />
                                                <Col>
                                                    <p className="order-price-detail">Price: $210.00</p>
                                                    <Col>
                                                        <Button href="/TrackOrder" style={{ marginLeft: '7px', borderRadius: 0 }} variant="outline-warning" type="submit">
                                                            {trackButton}
                                                        </Button>
                                                    </Col>
                                                </Col>

                                                <Col>
                                                    <Button style={{ borderRadius: 0 }} variant='primary'>{requestButton}</Button>
                                                    <Col>
                                                        <Button style={{ marginTop: '5px', borderRadius: 0 }} variant='outline-secondary'>{returnButton}</Button>
                                                        <Col>
                                                            <Button style={{ marginTop: '5px', borderRadius: 0 }} variant='outline-secondary'>{reviewButton}</Button>
                                                            <Col>
                                                                <Button style={{ marginTop: '5px', borderRadius: 0 }} variant='outline-secondary'>{feedbackButton}</Button>
                                                            </Col>
                                                        </Col>
                                                    </Col>
                                                </Col>

                                            </Row>
                                        </Container>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Page>
    );
};
export default MyOrder;
