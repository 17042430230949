import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import DispatchContext from '../context/DispatchContext';
import StateContext from '../context/StateContext';
import Axios from 'axios';

const DashboardSideMenu = () => {
  const [openDashboard, setOpenDashboard] = useState(false);
  const handleOpenMobileDropdown = () => {
    setOpenDashboard(!openDashboard);
  }
  
  const appState = useContext(StateContext);

  const appDispatch = useContext(DispatchContext);
  async function handleLogOut(e) {
    e.preventDefault();
    try {
      await Axios.get('/log-out', {
        headers: {
          Authorization: `Bearer ${appState.state.user.token}`,
        },
      });
      appDispatch({ type: 'logout' });
    } catch (error) {
      appDispatch({ type: 'logout' });
      // console.log('error encountered');
    }
  }
  return (
    <div className="col-md-3">
      <div className="sidebar-dashboard">
        <div className="user-details-side hide-in-mobile">
          <div className="user-img">
            <img src={appState.state.user.profileImage ? appState.state.user.profileImage : 'assets/images/avtar.png'} alt="" />
          </div>
          <div className="user-info">
            <h4 title={appState.state.user.name}>{appState.state.user.name && appState.state.user.name.length > 17 ? appState.state.user.name.slice(0, 17) + "..." : appState.state.user.name}</h4>
            <p title={appState.state.user.mobileNumber}>
              <i className="fa fa-phone"></i> {appState.state.user.mobileNumber}
            </p>
            <p title={appState.state.user.email}>
              <i className="fas fa-envelope-square"></i> {appState.state.user.email && appState.state.user.email.length > 17 ? appState.state.user.email.slice(0, 17) + "...." : appState.state.user.email}
            </p>
          </div>
        </div>
        <div className='hide-in-mobile'>
          <ul>
            {/* <li>
              <NavLink to="/dashboard">
                <i className="fas fa-home"></i> Dashboard
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/my-account">
              <i class="fa fa-home pe-2 fs-5 "></i> My Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/my-favourite">
                <i className="fas fa-heart"></i>My Wishlist
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/my-orders">
                <i className="fas fa-shopping-bag"></i> My Orders
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/my-enquiry">
              <i class="fa fa-home pe-2 fs-5"></i> All Inquiries
              </NavLink>
            </li>
            <li>
              <NavLink to="/live-chat">
              <i class="fa fa-home pe-2 fs-5
                                 "></i> Live Chat
              </NavLink>
            </li>
            <li>
              <NavLink to="/help-support">
              <i class="fa fa-home pe-2 fs-5"></i> Help & Support
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/change-password">
                <i className="fas fa-lock"></i> Change Password
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/setting">
                <i className="fas fa-cogs"></i>Settings
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/address-book">
                <i className="fas fa-book"></i> Address Book
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/log-out" onClick={handleLogOut}>
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </li>
          </ul>
          {/* <div className="last-login">
            <b>Last login:</b> {appState.state.user.lastLogin}
          </div> */}
        </div>

        <div className='dashboard-dropdown-toggle'>
          <div onClick={handleOpenMobileDropdown} className='d-flex justify-content-between' style={{ padding: "15px" }}>
            {/* <h2 >Dashboard Menu</h2> */}
            <i className="fas fa-bars" style={{ fontSize: "28px" }}></i>
          </div>
        </div>
        {
          openDashboard &&
          <div>
             <ul>
            {/* <li>
              <NavLink to="/dashboard">
                <i className="fas fa-home"></i> Dashboard
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/my-account">
              <i class="fa fa-home pe-2 fs-5 "></i> My Profile
              </NavLink>
            </li>
            <li>
              <NavLink to="/my-favourite">
                <i className="fas fa-heart"></i>My Wishlist
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/my-orders">
                <i className="fas fa-shopping-bag"></i> My Orders
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/my-enquiry">
              <i class="fa fa-home pe-2 fs-5"></i> All Inquiries
              </NavLink>
            </li>
            <li>
              <NavLink to="/live-chat">
              <i class="fa fa-home pe-2 fs-5
                                 "></i> Live Chat
              </NavLink>
            </li>
            <li>
              <NavLink to="/help-support">
              <i class="fa fa-home pe-2 fs-5"></i> Help & Support
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/change-password">
                <i className="fas fa-lock"></i> Change Password
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/setting">
                <i className="fas fa-cogs"></i>Settings
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/address-book">
                <i className="fas fa-book"></i> Address Book
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/log-out" onClick={handleLogOut}>
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </li>
          </ul>
            {/* <ul>
              <li>
                <NavLink to="/dashboard">
                  <i className="fas fa-home"></i> <span>Dashboard</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-account">
                  <i className="fas fa-address-book"></i> <span>My Profile</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-favourite">
                  <i className="fas fa-heart"></i>  <span>My Wishlist</span>
                </NavLink>
              </li>

              <li>
                <NavLink to="/my-orders">
                  <i className="fas fa-shopping-bag"></i>  <span>My Orders</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-enquiry">
                  <i className="fas fa-address-book"></i> <span>All Inquiries</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/change-password">
                  <i className="fas fa-lock"></i> <span>Change Password</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/setting">
                  <i className="fas fa-cogs"></i> <span>Setting</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/address-book">
                  <i className="fas fa-book"></i>  <span>Address Book</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/log-out" onClick={handleLogOut}>
                  <i className="fas fa-sign-out-alt"></i> <span>Logout</span>
                </NavLink>
              </li>
            </ul> */}
            <div className="last-login">
              <b>Last login:</b> {appState.state.user.lastLogin}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
export default DashboardSideMenu;
