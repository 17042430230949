import React, { useEffect } from 'react'
import "../style/Investor.css"
import { InvestorBanner } from '../components/investor/InvestorBanner'
import { Highlight } from '../components/investor/Highlight'
import { Risk } from '../components/investor/Risk'
import { AboutComp3 } from '../components/about-us/AboutComp3'
import { Communicate } from '../components/investor/Communicate'
import { TabComp } from '../components/investor/TabComp'
import SeoPartnerandinvester from '../seo page/Seopartner&investerpage'

export const Investor = () => {
  useEffect(() =>{
    window.scrollTo(0,0)
  },[])
  
  return (
    <>
    <SeoPartnerandinvester/>
    <div className="invsetor-parent" style={{background:"#f5f5f5"}}>

    
    <section>
    <InvestorBanner/>
    </section>

    <section>
      <Highlight/>
    </section>

    <section>
      <Risk/>
    </section>
    
    <section>
      <TabComp/>
    </section>

    <section>
      <AboutComp3 imgUrl={"/assets/images/building-2.png"}  />
    </section>

    <section>
      <Communicate />
    </section>
    
    </div>

    </>
  )
}
