import React, { useState } from 'react'
import "../../style/SellerDashboard.css"
import { Link } from 'react-router-dom'


export const Chat = () => {
    const [message, setMessage] = useState('');
    const [selectedEmoji, setSelectedEmoji] = useState('');
    const [files, setFiles] = useState([]);
    const [togglesb, setTogglesb] = useState(false)

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    const handleEmojiSelect = (event, emoji) => {
        event.preventDefault();
        setSelectedEmoji(emoji);
    };

    const handleFileSelect = (event) => {
        const fileList = event.target.files;
        setFiles([...files, ...fileList]);
    };

    const sendMessage = () => {
        // Implement sending message logic here
        console.log('Message:', message);
        console.log('Selected Emoji:', selectedEmoji);
        console.log('Files:', files);
        // You can perform additional actions here, like sending the message to a server
        // Reset form fields after sending the message
        setMessage('');
        setSelectedEmoji('');
        setFiles([]);
    };
    return (
        <>
            <div className="chat">

                <div className="chat-content d-flex justify-content-between position-realtive ">
                    <div className="chat-sidebar  col-md-4 py-4" style={{ opacity: togglesb === true ? 0 : 1 }} >
                        {/* <div className={`chat-sidebar d-${togglesb ===true?"none":"block"} col-md-4 py-4`} style={{opacity: togglesb === true? 0 : 1}} > */}
                        <div className="searchbar">
                            <form action="" className='position-relative'>
                                <input type="text" placeholder='Search ...' className='border-0 w-100 bg-transparent' />
                                <span className='position-absolute '><i className='fa fa-search '></i></span>
                            </form>
                        </div>

                        <div className="users py-3">
                            <div className="user active-user px-3 py-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>
                            <div className="user py-3 px-3 d-flex gap-3 align-items-center ">
                                <div className='user-img position-relative' style={{ width: "fit-content" }}>
                                    <img src="/assets/images/profile-img.png" alt="" className='rounded-circle' height="40px" width="40px" />
                                    <span className="active-symbol"></span>
                                </div>
                                <div className="user-content">
                                    <div className="d-flex justify-content-between align-items-center pb-1">
                                        <h4 className="name mb-0">Morgan</h4>
                                        <p className='mb-0 time'>11:00am</p>
                                    </div>
                                    <p className='mb-0 disc'>Lorem ipsum dolor sit.lorem ...</p>
                                </div>
                            </div>

                        </div>
                        
                    </div>
                    <div className="chat-body col-md-8 px-4">
                        <div className="call-div py-3 d-flex align-items-center justify-content-between">
                            {/* <div className="d-flex align-items-center gap-3"> */}
                            <div>
                                <h4 className='name mb-0'>Morgen</h4>
                                <p className='mb-0'><span className='active-symbol'></span> Active now</p>
                            </div>
                            {/* </div> */}

                            <div className="call-icons d-flex gap-lg-4 gap--3 ">

                                <Link to=""><span><i className='fa fa-video'></i></span></Link>
                                <Link to=""><span><i className='fa fa-phone'></i></span></Link>
                                <button className='bg-transparent py-0 togglebar  ' onClick={() => setTogglesb(!togglesb)}> <img src="/assets/Icons/explore-bar.svg" alt="" /></button>
                            </div>
                        </div>

                        <div className="chat-content my-3">
                            <div className="day-time text-center"> Yesterday 14:46 PM</div>
                            {/* your chat */}
                            <div className="your-chat my-2 d-flex gap-lg-4 gap-md-3 gap-2 w-75">
                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width={"40px"} height={"40px"} />
                                <div className="yr-content">
                                    <p className="mb-0 px-3 my-2 py-2 rounded-5">Hi Tak! How are you?</p>
                                    {/* <img src="/assets/images/chat-img.png" alt="" style={{maxHeight:"200px"}} /> */}
                                </div>
                            </div>

                            {/* my chat  */}
                            <div className="my-chat my-2 pe-3 ms-5">
                                <div className="my-content text-end">
                                    <p className="mb-0 px-3 my-2 py-2 rounded-5">I am Fine! Wha’ts Up?</p>
                                    {/* <img src="/assets/images/chat-img.png" alt="" style={{maxHeight:"200px"}} /> */}
                                </div>
                            </div>

                            {/* your chat */}
                            <div className="your-chat my-2 d-flex gap-lg-4 gap-md-3 gap-2 w-75">
                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width={"40px"} height={"40px"} />
                                <div className="yr-content">
                                    <p className="mb-0 px-3 my-2 py-2 rounded-5">We need to discuss about Bliss</p>
                                </div>
                            </div>

                            {/* my chat  */}
                            <div className="my-chat pe-3 my-2 ms-5">
                                <div className="my-content text-end">
                                    <p className="mb-0 px-3 py-2 rounded-5">Ok! I will call you tommorow</p>
                                    {/* <img src="/assets/images/chat-img.png" alt="" style={{maxHeight:"200px"}} /> */}
                                </div>
                            </div>

                            {/* your chat */}
                            <div className="your-chat my-2 d-flex gap-lg-4 gap-md-3 gap-2 w-75">
                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width={"40px"} height={"40px"} />
                                <div className="yr-content">
                                    <p className="mb-2 px-3 py-2 rounded-5">Hi Tak! How are you?</p>
                                    <img src="/assets/images/chat-img.png" alt="" style={{ maxHeight: "200px" }} />
                                </div>
                            </div>


                        </div>


                        <div className="add-type-text pt-4">
                            <form action="" className='d-flex align-items-center justify-content-between gap-2'>

                                <div class="dropdown dd1">
                                    <button class="btn  dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        🙂  </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#"><button onClick={() => handleEmojiSelect('😊')}>😊</button></a></li>
                                        <li><a class="dropdown-item" href="#"><button onClick={() => handleEmojiSelect('👍')}>👍</button></a></li>
                                        <li><a class="dropdown-item" href="#"><button onClick={() => handleEmojiSelect('👌')}>👌</button></a></li>
                                        <li><a class="dropdown-item" href="#"><button onClick={() => handleEmojiSelect('😂')}>😂</button></a></li>
                                    </ul>
                                </div>
                                {/* <div>
                                    <span>Select an emoji:</span>
                                    <button onClick={() => handleEmojiSelect('😊')}>😊</button>
                                    <button onClick={() => handleEmojiSelect('👍')}>👍</button>
                                </div> */}

                                <textarea
                                    value={message}
                                    onChange={handleInputChange}
                                    placeholder="Type your message..."
                                    rows={1}
                                    style={{ flexGrow: "1", background: "transparent", border: "none" }}
                                //disabled={!selectedEmoji} // Disable typing when an emoji is selected
                                />

                                <div className='d-flex gap-3'>
                                    <div className="add-file position-relative">
                                        <input type="file" multiple />
                                        <img src="/assets/icons/link.png" alt="" />
                                    </div>
                                    {/* <button onClick={sendMessage} disabled={!selectedEmoji}> */}
                                    <div className="position-relative">
                                        <input type="submit" name="" id="" />
                                        <img src="/assets/icons/send1.png" alt="" />
                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}
