import axios from "axios";
import React, { useEffect ,useState} from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { BASE_URL } from "../../../config";
import Helmet from 'react-helmet';

const SecondSection = () => {

  const[allwidget, setAllwidget] = useState([])
  const[sdata, setsdata] = useState([])
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 400,
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  // Get widget data from api
  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data);
    } catch (err) {
      console.log(err);
    }
    try {
      const sData = await axios.get(BASE_URL + "/servicecategoryleftbarsubmenu");
      setsdata(sData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    widgetData();
  }, []);
  var businessnature = [
    "Manufacturer",
    "Exporters & Importers",
    "Suppliers",
    "Distributors",
    "Retailers",
    "Service Providers",
    "Educational Institutes",
  ];
  return (
    <><Helmet>
    <title>Apna sahar</title>
    <meta name="description" content="Apna sahar" />
    <meta property="og:url"              content="https://test.apnashaher.com/" />
    <meta property="og:type"               content="article" />
    <meta property="og:title"              content="The title is concise and includes the brand name, ApnaShaher, along with a promise of being the ultimate directory for trusted services." />
    <meta property="og:description"        content="ApnaShaher are offerings, emphasizing the ability to find trusted services, read reviews, and make informed decisions. " />
    <meta property="og:image"              content="https://media.gettyimages.com/photos/bogota-at-sunset-picture-id107069344?s=612x612" />
</Helmet>
      <div className="container second-section-bg mt-4">
        <div className="second-section-cols">
          <div className="left-content"  >
            <h6 dangerouslySetInnerHTML={{ __html: allwidget.widget2}}>
             
            </h6>
            <hr className="mt-2 mb-1" />

            {/* dropdown ---------------------*/}
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle border-0 w-100 text-start ps-0 d-flex justify-content-between align-items-center pe-2 "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center gap-1">

                <img src="/assets/images/product-1.png" className="mr-2"></img>
                Products
                </div>
                <img src="/assets/Icons/right-arrow.svg" alt="" className="mr-2" />
              </button>
              <ul className="dropdown-menu ps-2 w-100 border-0 rounded-0">
                <Link to="/categorys/products-page">
                  <li>
                    <img src="/assets/images/pro-cat-1.svg" className="mr-2" />
                    All Products
                  </li>
                </Link>
                <Link to="/categorys/flash-deals">
                  <li>
                    <img src="/assets/images/pro-cat-1.svg" className="mr-2" />
                    Flash Deals
                  </li>
                </Link>
                <Link to="/categorys/new-arrival">
                  {" "}
                  <li>
                    <img src="/assets/images/pro-cat-2.svg" className="mr-2" />
                    New Arrivals
                  </li>
                </Link>
                <Link to="/categorys/stock-clearance">
                  <li>
                    <img src="/assets/images/pro-cat-3.svg" className="mr-2" />
                    Stock Clearance
                  </li>
                </Link>
              </ul>
            </div>

            {/* 2nd */}
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle w-100 text-start ps-0 border-0 d-flex justify-content-between align-items-center pe-2 "
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center gap-1">
                <img src="/assets/images/product-1.png" className="mr-2"></img>
                 Services
                </div>
                <img src="/assets/Icons/right-arrow.svg" alt="" className="mr-2" />


              </button>
              <ul className="dropdown-menu ps-2 w-100 border-0 rounded-0">
              <Link to="/service-category">
                  <li>
                    {" "}
                    <img src="/assets/images/pro-cat-1.svg" className="mr-2" />
                    Show All Services
                  </li>
                </Link>
                {sdata && sdata.map((item,i)=>(
                  <Link to={"/service-categorys/"+item.slug}>
                  <li>
                    {" "}
                    <img src={item.icon} height={20} className="mr-2" />
                    {item.name}
                  </li>
                </Link>
                ))}
                
               
              </ul>
            </div>

            {/* 3rd  */}
            <div className="dropdown w-100">
              <button
                className="btn dropdown-toggle w-100 text-start ps-0 border-0 d-flex justify-content-between align-items-center pe-2"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center gap-1">
                <img src="/assets/images/services.png" className="mr-2"></img>
                 Directory
                </div>
                
                <img src="/assets/Icons/right-arrow.svg" alt="" className="mr-2"/>
              </button>
              <ul className="dropdown-menu ps-2 w-100 border-0 rounded-0">
                <Link to ="/startup"> <li>
                <img src="/assets/images/pro-cat-1.svg" className="mr-2" />
                  Startup
                  </li></Link>
                {businessnature && businessnature.map((item,i)=>(
                  <Link to={"/directory-listing/"+item} key={i} >
                  <li>
                    {" "}
                    <img src="/assets/images/pro-cat-1.svg" className="mr-2" />
                    {item}
                  </li>
                </Link>
                ))}
                
               
              </ul>
            </div>

            {/* <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><img src='/assets/images/product-1.png' className='mr-2' />Browse Products</Accordion.Header>
                                <Accordion.Body>
                                    <ul className='accordian-links'>
                                        <Link to="/startup"><li><img src='/assets/images/pro-cat-1.svg' className='mr-2' />All Startup</li></Link>
                                        <Link to="/flash-deals"><li><img src='/assets/images/pro-cat-1.svg' className='mr-2' />Flash Deals</li></Link>
                                        <Link to="/new-arrival"> <li><img src='/assets/images/pro-cat-2.svg' className='mr-2' />New Arrivals</li></Link>
                                        <Link to="/stock-clearance"><li><img src='/assets/images/pro-cat-3.svg' className='mr-2' />Stock Clearance</li></Link>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='py-2'><img src='/assets/images/product-1.png' className='mr-2' />Browse Services</Accordion.Header>
                                <Accordion.Body>
                                    <ul className='accordian-links'>
                                        <Link to="/digital-services"><li> <img src='/assets/images/pro-cat-4.svg' className='mr-2' />Digital Services</li></Link>
                                        <Link to="/professional-services"><li><img src='/assets/images/pro-cat-5.svg' className='mr-2' />Professional Services</li></Link>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><img src='/assets/images/services.png' className='mr-2' />Business Directory</Accordion.Header>
                                <Accordion.Body>
                                    <ul className='accordian-links'>
                                        <Link to="/startup"><li> <img src='/assets/images/pro-cat-1.svg' className='mr-2' />All Startup</li></Link>
                                        <Link to="/Manufacturer"><li> <img src='/assets/images/pro-cat-1.svg' className='mr-2' />Manufactures</li></Link>
                                        <Link to="/export-import"><li><img src='/assets/images/pro-cat-2.svg' className='mr-2' />Export & Importers</li></Link>
                                        <Link to="/digital-services"><li><img src='/assets/images/pro-cat-3.svg' className='mr-2' />Digital Service Providers</li></Link>
                                        <Link to="/professional-services"><li><img src='/assets/images/pro-cat-4.svg' className='mr-2' />Professional Service Providers</li></Link>
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion> */}
            <div className="get-quotes-section mt-4">
              <button className="get-quotes-btn">
              <Link to ="/post-buy-requirement" className="text-white">
                Get a Quote
                </Link>
                </button> 
             
              <button className="sell-on-apnashaher-btn">
              <Link to="/seller-benefits" className="text-white">  Sell on Apnashaher  </Link>
              </button>
            </div>
          </div>
          <div className="hr-slider second-sec-hr-slider">
            <div className="container text-center houzey-mt-1">
              <Slider {...settings}>
                <div>
                  <img
                    src="/assets/images/slider-img-1.png"
                    width="100%"
                    height="413px"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/slider-img-2.jpg"
                    width="100%"
                    height="413px"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/slider-img-1.png"
                    width="100%"
                    height="413px"
                  />
                </div>
                <div>
                  <img
                    src="/assets/images/slider-img-2.jpg"
                    width="100%"
                    height="413px"
                  />
                </div>
              </Slider>
            </div>
          </div>
          <div>
            <h6 dangerouslySetInnerHTML={{ __html: allwidget.widget3}}>
             
            </h6>
            {/* <h6>
              An{" "}
              <Link
                to="/"
                style={{ color: "#D60D0D", textDecoration: "underline" }}
              >
                ECONOMICAL
              </Link>{" "}
              Solution for your Business Promotion
            </h6> */}
            <hr />
            <Link to ="/advertise-with-us">
            <div className="business-promotion">
              <img
                src="/assets/images/img-1.png"
                className="mr-2"
                width="70px"
              />
              <p>Advertise with Us</p>
              <img src="/assets/Icons/right-arrow.svg" className="mr-2" />
            </div>
            </Link>

            <div className="business-promotion mt-2">
              <img
                src="/assets/images/img-2.png"
                className="mr-2"
                width="70px"
              />
              <p>Grow Business Digitally</p>
              <img src="/assets/Icons/right-arrow.svg" className="mr-2" />
            </div>
            <Link to="/seller-benefits">
            <div className="business-promotion mt-2">
              <img
                src="/assets/images/img-3.png"
                className="mr-2"
                width="70px"
              />
              <p>Sellers Benefits</p>
              <img src="/assets/Icons/right-arrow.svg" className="mr-2" />
            </div>
            </Link>
            <h4>Sign up to enjoy exciting Buyers Club benefits</h4>
            <div className="btns pt-ld-0  d-flex gap-2 flex-lg-column flex-row">
              <button className="transparent-btn py-2 mb-2 ">
                <Link to="/login">Sign In</Link>
              </button>
              <button className="blue-btn py-2 text-light  ">
                <Link to="/sign-up" className="text-light">
                  Join for free
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondSection;
