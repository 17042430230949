import React from 'react'
import "../../style/SuccessStory.css"
export const SuccessComp1 = () => {
    return (
        <>
            <div className="success-comp1 py-lg-5 py-md-4 text-center py-3 ">
                <h1>Customers Success Story</h1>
                <p className='summary' style = {{margin:"auto"}}>Meet Sarah, a small business owner who struggled to boost online sales until she discovered our innovative marketing platform.</p>
            </div>
        </>
    )
}
