import React from 'react'

export const AboutComp4 = ({para, mbottom, twhite}) => {
    return (

        <>

            <div className="about-comp4    mx-md-4 mx-3 my-lg-5 my-md-4 my-3">
                <div className={`abs-tcontent text-center ${mbottom}`}>
                    <h3 className={`text-center text-${twhite}`}>We are backed by Industry Experts</h3>
                    <h4 className={`logo text-${twhite}`}>Web Mingo</h4>
                    <p className={`d-${para}`}>trusted by some of the most well known entrepreneur in India</p>
                </div>
                <div className="overf2-div">
                <div className="cards d-flex gap-3 justify-content-between">
                    <div className="card">
                        <img src="/assets/images/user-1.png" alt="" />
                        <div className="card-body">
                            <h5 className='title'>Siddharth Shah</h5>
                            <p>Co-Founder Pharmeasy</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="/assets/images/user-1.png" alt="" />
                        <div className="card-body">
                            <h5 className='title'>Siddharth Shah</h5>
                            <p>Co-Founder Pharmeasy</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="/assets/images/user-1.png" alt="" />
                        <div className="card-body">
                            <h5 className='title'>Siddharth Shah</h5>
                            <p>Co-Founder Pharmeasy</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="/assets/images/user-1.png" alt="" />
                        <div className="card-body">
                            <h5 className='title'>Siddharth Shah</h5>
                            <p>Co-Founder Pharmeasy</p>
                        </div>
                    </div>
                    <div className="card">
                        <img src="/assets/images/user-1.png" alt="" />
                        <div className="card-body">
                            <h5 className='title'>Siddharth Shah</h5>
                            <p>Co-Founder Pharmeasy</p>
                        </div>
                    </div>
                  
                </div>
                </div>

            </div>
        </>
    )
}
