import React, { useContext, useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { Button, Form, Nav, NavDropdown, InputGroup } from "react-bootstrap";
import StateContext from "../context/StateContext";
import CategoryDropdownList from "./header/category-dropdown-list.component";
import FeaturedCategoryListItem from "./header/featured-category-list-item.component";
import SearchBar from "./header/searchbar.component";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCaretDown,
  faCog,
  faL,
  faLocation,
  faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import Menu from "@mui/material/Menu";
import Badge from "react-bootstrap/Badge";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { BASE_URL } from "../config";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import Axios from "axios";
import { Helmet } from "react-helmet";
import DispatchContext from "../context/DispatchContext";
import { useCookies } from "react-cookie";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {  } from "@fortawesome/free-solid-svg-icons";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import HeaderPage from "./HOD/HeaderPage/HeaderPage";

const Header = ({ websiteSetting }) => {
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const appState = useContext(StateContext);
  const { gcurrency, setgcurrency } = useContext(StateContext);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [open, setOpen] = useState(false);


  const handleClose3 = () => setShow1(false);
  const handleOpenCategories = () => setShow1(true);

  const handleClose2 = () => setShow(false);
  const handleOpen = () => setShow(true);
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [categoryID, setCategoryID] = useState("");
  const [language, setlanguage] = useState([]);
  const [currency, setcurrency] = useState([]);
  const [islanguage, setislanguage] = useState();
  const [iscurrency, setiscurrency] = useState("");
  const [header_logo, setheaderlogo] = useState("");
  const [seller, setseller] = useState("");
  const [ban, setban] = useState(1);
  const [locationname, setlocationname] = useState("");
  const [issticky, setissticky] = useState("");
  const [headerdata, setheaderdata] = useState([]);
  const [locationsearch, setlocationsearch] = useState([]);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [activeButton, setActiveButton] = useState();
  const [image_base_url, setimage_base_url] = useState("");
  const token = localStorage.getItem("avonToken");
  const [cookies, setCookie] = useCookies(["cookie-name"]);
  const userName = localStorage.getItem("avonName");
  const { locationdata, currencysymbol, setcurrencysymbol } = useContext(StateContext);
  const [mobileopen, setMobileopen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClickOutside = (event) => {
    // if (menuRef.current && !menuRef.current.contains(event.target)) {
    //   setOpen(false);
    // }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
  };

  function onChange(newName) {
    setCookie("name", newName, { path: "/" });
  }
  const handleSubmit = (event) => {
    localStorage.setItem("token", event.currentTarget.id);
    const data = {
      category_type: event.currentTarget.id,
    };
    axios.post(BASE_URL + "/getmegamenu", data).then((res) => {
      setCategory(res.data);
      setSubCategory(res.data.subcategory);
      setOpen(true);
    });
  };

  const handleCloseMegaMenu = () => {
    setOpen(false);
  };

  useEffect(() => {
    getlanguage();
    getcurrency();
    checksticky();
    getheader();
    getlocation();
    getcurrencybycode();
  }, []);
  useEffect(() => {
    getcurrencybycode();
  }, [gcurrency]);
  const getlocation = () => {
    // axios.get("https://ip-api.io/json/").then(({data})=>{
    //   setlocationdata(data)
    // })
  }
  const getheader = () => {
    axios
      .get(BASE_URL + "/getwebsitedataall")
      .then(({ data }) => {
        setheaderdata(data.data);

        setimage_base_url(data.image_base_url);
      })
      .catch(({ response }) => { });
    if (token) {
      axios.get(BASE_URL + '/get-seller-detail', {
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        console.log(data)
        setseller(data.data)
        setban(data.user)
      }).catch(({ response }) => {
        if (response.status === 401) {
          handleLogOut();
          console.log("------log2", response)
        }

      })
    }
    // if (token) {
    //   axios.get(BASE_URL + '/get-seller-detail', {
    //     headers: {
    //       'Accept': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     }
    //   }).then(({ data }) => {
    //     setseller(data.data)
    //     setban(data.user)
    //   })
    // }
  };
  const getlocationsearch = () => {
    axios
      .post(BASE_URL + "/getlocation", { "search": locationname })
      .then(({ data }) => {
        setlocationsearch(data.data)
      })
      .catch(({ response }) => { });
  };
  function filterdata(type) {
    const data = headerdata.filter((e) => e.type === type);
    return data[0];
  }

  const getlanguage = () => {
    axios.get(BASE_URL + "/getlanguage").then((res) => {
      setlanguage(res.data.data);
    });
  };
  const getcurrency = () => {
    axios.get(BASE_URL + "/getcurrency").then((res) => {
      setcurrency(res.data.data);
    });
  };
  const getcurrencybycode = async () => {
    await axios.get(`${BASE_URL}/getcurrencybycode/${gcurrency ? gcurrency : "INR"}`).then(({ data }) => {
      setcurrencysymbol(data.data.symbol ? data.data.symbol : gcurrency)
    })
  }
  const appDispatch = useContext(DispatchContext);
  var d = "";
  const checksticky = () => {
    axios
      .post(BASE_URL + "/getwebsitedata", { type: "header_stikcy" })
      .then((res) => {
        if (res.data.data.value === "on") {
          d = "sticky";
        }
        setissticky(res.data.data.value);
      });
  };
  const CategoryID = localStorage.getItem("token");
  const handleGetSubCategory = (event) => {
    setCategoryID(event);
    setOpen(true);
    const data = {
      category_type: CategoryID,
      category_id: event,
    };
    axios.post(BASE_URL + "/getmegasubmenu", data).then((res) => {
      setSubCategory(res.data);
    });
  };

  console.log("subCategory", subCategory);
  const handleClose = () => {
    setAnchorEl2(null);
  };

  const { categories } = useContext(StateContext);

  async function handleLogOut() {
    // e.preventDefault();
    try {
      await Axios.get("/log-out", {
        headers: {
          Authorization: `Bearer ${appState.state.user.token}`,
        },
      });
    } catch (error) {
      // console.log('error encountered');
    }
    navigate("/");
    appDispatch({ type: "logout" });
    localStorage.removeItem("avonToken");
    localStorage.removeItem("avonName");
    localStorage.removeItem("avonEmail");
    localStorage.removeItem("avonMobileNumber");
    localStorage.removeItem("avonProfileImage");
    localStorage.removeItem("avonLastLogin");
  }

  const handlesearchsubcategory = (e) => {
    axios
      .post(BASE_URL + "/searchcategory", {
        search: e.target.value,
        category: categoryID,
      })
      .then((res) => {
        setSubCategory(res.data);
      });
  };

  const [drop, setDrop] = useState(false);

  

  return (
    <>
      {/* <div className={"header-section " + d} id="headernew">
        <Helmet>
          {filterdata("header_script") &&
            document.head.insertAdjacentHTML(
              "beforeend",
              filterdata("header_script").value
            )}
          <title>
            {filterdata("meta_title") && filterdata("meta_title").value}
          </title>
          <link
            rel="icon"
            href={
              headerdata &&
              filterdata("meta_image") &&
              image_base_url + filterdata("meta_image").image.file_name
            }
          />
          <link
            rel="apple-touch-icon"
            href={
              headerdata &&
              filterdata("meta_image") &&
              image_base_url + filterdata("meta_image").image.file_name
            }
          />
        </Helmet>
        <section className="top-header-section">
          <div className="container-fluid">
            <div className="row" style={{ padding: 5 }}>
              <div className="col-md-5 col-sm-12 col-xs-12 top-header-mobile-bg">
                <div className="top-header-dropdown">
                  {filterdata("show_language_switcher") &&
                    filterdata("show_language_switcher").value === "on" && (
                      <div className="col-md-3">
                        <select className="language-dropdown dropdown-1">
                          <option value="">Language</option>
                          {language &&
                            language.map((item, i) => (
                              <option value={item.name}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                    )}
                  {filterdata("show_currency_switcher") &&
                    filterdata("show_currency_switcher").value === "on" && (
                      <div className="col-md-3">
                        <select value={gcurrency} className="language-dropdown dropdown-2 currency-dropdown" onChange={(e) => setgcurrency(e.target.value)} >
                          <option value="">Currency</option>
                          {currency &&
                            currency.map((item, i) => (
                              <option value={item.code}>{item.name}</option>
                            ))}
                        </select>
                      </div>
                    )}

                
                  <div className="">
                    <div className="chat-with-us">
                      <button>
                        {filterdata("whatsapp_number") && (
                          <WhatsAppWidget headerTitle="Aladdin Support" phoneNo={filterdata("whatsapp_number").value} />
                        )}
                        {filterdata("whatsapp_number") && (
                          <a href={"https://api.whatsapp.com/send?phone=" + filterdata("whatsapp_number").value} className="whatsapp-icon-color" target="_blank">
                            <img
                              src="../assets/images/whatsapp-icon.svg"
                              style={{ marginRight: 5 }}
                            />
                            Chat Now
                          </a>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7 col-sm-12 col-xs-12">
                <div className="hide-in-mobile">
                  <div className="topmenublk">
                    <ul>
                      <li className="fav-menu-block">
                        {token ?
                          <NavLink to="/my-favourite">
                            <img src="../assets/images/whishlist-icon.svg" />
                            <Badge bg="secondary" className="cart-badge-1">
                              {appState.state.wishlistItemCount}
                            </Badge>
                           
                          </NavLink>
                          :
                          <NavLink to="/wishlist">
                            <img src="../assets/images/whishlist-icon.svg" />
                            <Badge bg="secondary" className="cart-badge-1">
                              {appState.state.wishlistItemCount}
                            </Badge>
                          
                          </NavLink>
                        }
                      </li>
                      <li className="cart-menu-block">
                        <NavLink to="/Cart">
                          <img src="../assets/images/cart-icon.svg" />
                          <Badge bg="secondary" className="cart-badge-1">
                            {appState.state.cartItemCount}
                          </Badge>
                        
                        </NavLink>
                      </li>
                      <li className="cart-menu-block margin-right-1">
                        <NavLink to="/contact-us">
                          <img src="../assets/images/map-icon.png" />
                        </NavLink>
                      </li>
                      {appState.state.user.token ? (
                        <li>
                          <div class="dropdown">
                            <button class="dropbtn user-dropdown">
                              {appState.state.user.name}{" "}
                              <FontAwesomeIcon
                                icon={faCaretDown}
                                style={{ fontSize: 15, marginLeft: 10 }}
                                className="mt-2"
                              />
                            </button>
                            <div class="dropdown-content">
                              {console.log(appState.state.user)}
                              {appState.state.user.step == "complete" && (
                                <>
                                  <Link to="/dashboard">Go to Dashboard</Link>
                                  {ban == 0 && (
                                    <Link to="/vendor-dashboard">
                                      Go to Vendor Dashboard
                                    </Link>
                                  )}
                                </>
                              )}

                              <Link to="#" onClick={handleLogOut}>
                                Logout
                              </Link>
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <Link to="/login" className="header-login">
                            Login
                          </Link>
                          /
                          <Link to="/sign-up" className="header-login">
                            Signup
                          </Link>
                          <img src="../assets/images/user-icon.png" />
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>



             
              <div className="hide-in-desktop">
                <div className="mobile-user-dropdown">
                  <ul className="mobile-wish-cart">
                    <li className="fav-menu-block">
                      <NavLink to="/my-favourite">
                        <img src="../assets/images/whishlist-icon.svg" />
                        <Badge bg="secondary" className="cart-badge-1">
                          {appState.state.wishlistItemCount}
                        </Badge>
                      </NavLink>
                    </li>
                    <li className="cart-menu-block">
                      <NavLink to="/Cart">
                        <img src="../assets/images/cart-icon.svg" />
                        <Badge bg="secondary" className="cart-badge-1">
                          {appState.state.cartItemCount}
                        </Badge>
                      </NavLink>
                    </li>
                    <li className="cart-menu-block margin-right-1">
                      <NavLink to="/contact-us">
                        <img src="../assets/images/map-icon.png" />
                      </NavLink>
                    </li>
                  </ul>
                  <div className="col-md-7">
                    <div className="topmenublk">
                      <ul>
                        {appState.state.user.token ? (
                          <li>
                            <div class="dropdown">
                              <button class="dropbtn user-dropdown">
                                {appState.state.user.name}{" "}
                                <FontAwesomeIcon
                                  icon={faCaretDown}
                                  style={{ fontSize: 15, marginLeft: 10 }}
                                  className="mt-2"
                                />
                              </button>
                              <div class="dropdown-content">
                                {console.log(appState.state.user)}
                                {appState.state.user.step == "complete" && (
                                  <>
                                    <Link to="/dashboard">Go to Dashboard</Link>
                                    {ban == 0 && (
                                      <Link to="/vendor-dashboard">
                                        Go to Vendor Dashboard
                                      </Link>
                                    )}
                                  </>
                                )}
                                <Link to="#" onClick={handleLogOut}>
                                  Logout
                                </Link>
                              </div>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <Link to="/login" className="header-login">
                              Login
                            </Link>
                            /
                            <Link to="/sign-up" className="header-login">
                              Signup
                            </Link>
                            <img src="../assets/images/user-icon.png" />
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </section >


        <div className="header-on-landscape top-header-mobile-bg">
          <div className="header-on-landscape1">
            <div>
              <div className="top-header-dropdown">
                {filterdata("show_language_switcher") &&
                  filterdata("show_language_switcher").value === "on" && (
                    <div className="col-md-3">
                      <select className="language-dropdown dropdown-1">
                        <option value="">Language</option>
                        {language &&
                          language.map((item, i) => (
                            <option value={item.name}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                  )}
                {filterdata("show_currency_switcher") &&
                  filterdata("show_currency_switcher").value === "on" && (
                    <div className="col-md-3">
                      <select value={gcurrency} className="language-dropdown dropdown-2 currency-dropdown" onChange={(e) => setgcurrency(e.target.value)} >
                        <option value="">Currency</option>
                        {currency &&
                          currency.map((item, i) => (
                            <option value={item.code}>{item.name}</option>
                          ))}
                      </select>
                    </div>
                  )}
                <div className="">
                  <div className="chat-with-us">
                    <button>
                      {filterdata("whatsapp_number") && (
                        <a href={"https://api.whatsapp.com/send?phone=" + filterdata("whatsapp_number").value} className="whatsapp-icon-color" target="_blank">
                          <img
                            src="../assets/images/whatsapp-icon.svg"
                            style={{ marginRight: 5 }}
                          />
                          Chat
                        </a>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ gap: "10px" }}>
              <ul className="mobile-wish-cart">
                <li className="fav-menu-block">
                  <NavLink to="/my-favourite">
                    <img src="../assets/images/white-heart.svg" />
                    <Badge bg="secondary" className="cart-badge-1">
                      {appState.state.wishlistItemCount}
                    </Badge>
                  </NavLink>
                </li>
                <li className="cart-menu-block">
                  <NavLink to="/Cart">
                    <img src="../assets/images/white-cart.svg" />
                    <Badge bg="secondary" className="cart-badge-1">
                      {appState.state.cartItemCount}
                    </Badge>
                  </NavLink>
                </li>
                <li className="cart-menu-block margin-right-1">
                  <NavLink to="/Cart">
                    <img src="../assets/images/white-map.svg" />
                  </NavLink>
                </li>
              </ul>
              <div className="topmenublk">
                <ul>
                  {appState.state.user.token ? (
                    <li>
                      <div class="dropdown">
                        <button class="dropbtn user-dropdown">
                          {appState.state.user.name}{" "}
                          <FontAwesomeIcon
                            icon={faCaretDown}
                            style={{ fontSize: 15, marginLeft: 10 }}
                            className="mt-2"
                          />
                        </button>
                        <div class="dropdown-content">

                          {appState.state.user.step == "complete" && (
                            <>
                              <Link to="/dashboard">Go to Dashboard</Link>
                              {ban == 0 && (
                                <Link to="/vendor-dashboard">
                                  Go to Vendor Dashboard
                                </Link>
                              )}
                            </>
                          )}
                          <Link to="#" onClick={handleLogOut}>
                            Logout
                          </Link>
                        </div>
                      </div>
                    </li>
                  ) : (
                    <li style={{ color: "#fff" }}>
                      <Link to="/login" className="header-login">
                        Login
                      </Link>
                      /
                      <Link to="/sign-up" className="header-login">
                        Signup
                      </Link>
                      <img src="../assets/images/white-user.svg" />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>



        <section className="middle-header-section">
          <div className="container-fluid">
            <div className="logo-searchbar-block">
              <div className="hide-in-mobile">
                <div className="header-items header-items">
                  <div className="logo-block">
                    <NavLink to="/">
                      {filterdata("header_logo") && filterdata("header_logo") && (
                        <img
                          src={
                            image_base_url +
                            filterdata("header_logo").image.file_name
                          }
                          alt="logo"
                        />
                      )}
                    </NavLink>
                  </div>
                  <div className="hide-in-mobile">
                    <div style={{ textAlign: 'center' }} width="100%">
                      <SearchBar />
                    </div>
                  </div>

                  <div style={{ textAlign: '-webkit-right' }}>
                    {ban != 0 && (
                      <Button
                        className="sell-aladin-btn"
                        onClick={() => { navigate("/seller-packages"); setOpen(false) }}
                      >
                        Sell on Aladdin Direct{" "}
                        <span>
                          <img src="../assets/images/user-icon.png" />
                        </span>
                      </Button>
                    )}
                  </div>

                </div>
              </div>

              <div className="hide-in-desktop">
                <div >
                  <div className="logo-block">
                    <NavLink to="/">
                      {filterdata("header_logo") && filterdata("header_logo") && (
                        <img
                          src={
                            image_base_url +
                            filterdata("header_logo").image.file_name
                          }
                          alt="logo"
                        />
                      )}
                    </NavLink>
                  </div>
                  <div className="landscape-middle-section">
                    <div className="">
                      <div style={{ textAlign: 'center' }} width="100%">
                        <SearchBar />
                      </div>
                    </div>
                    <div style={{ marginTop: "15px", textAlign: "-webkit-center" }}>
                      {ban != 0 && (
                        <Button
                          className="sell-aladin-btn"
                          onClick={() => navigate("/seller-packages")}
                        >
                          Sell on Aladdin Direct{" "}
                          <span>
                            <img src="../assets/images/user-icon.png" />
                          </span>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"bottom-header-section"}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-2">
                <div className="all-department-menu">
                  <ul className="navbar-nav">
                    <li
                      className="all-categories-link"
                      id="0"
                      onClick={handleSubmit}
                    >
                      <i className="fa fa-align-left"></i>All Categories
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-10 hide-in-desktop" >
                <div className="mainmenu-block">
                  <nav className="navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={() => setMobileopen(!mobileopen)}
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                    {mobileopen && (
                      <div
                        className={mobileopen ? "navbar-collapse collapse show" : "navbar-collapse collapse"}
                        id="navbarSupportedContent"
                      >
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                          <li className="nav-item" >
                            <NavLink className="nav-link" to="/shop" onClick={() => setMobileopen(!mobileopen)} >
                              All Products
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/flash-deals" onClick={() => setMobileopen(!mobileopen)}>
                              Flash Deals
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/new-arrival" onClick={() => setMobileopen(!mobileopen)}>
                              New Arrivals
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/stock-clearance" onClick={() => setMobileopen(!mobileopen)}>
                              Stock Clearance
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/Manufacturer" onClick={() => setMobileopen(!mobileopen)}>
                              Manufacturers
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/export-import" onClick={() => setMobileopen(!mobileopen)}>
                              Exporters & Importers
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink className="nav-link" to="/digital-services" onClick={() => setMobileopen(!mobileopen)}>
                              Digital Service
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              className="nav-link"
                              to="/professional-services"
                              onClick={() => setMobileopen(!mobileopen)}
                            >
                              Professional Services
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    )}
                  </nav>
                </div>
              </div>
              <div className="col-md-10 hide-in-mobile hide-on-1024" >
                <div className="mainmenu-block">
                  <nav className="navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                    
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                    <div
                      className="navbar-collapse collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item" >
                          <NavLink className="nav-link" to="/shop"  >
                            All Products
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/flash-deals" >
                            Flash Deals
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/new-arrival" >
                            New Arrivals
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/stock-clearance" >
                            Stock Clearance
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/Manufacturer" >
                            Manufacturers
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/export-import" >
                            Exporters & Importers
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/digital-services" >
                            Digital Service
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink
                            className="nav-link"
                            to="/professional-services"
                          >
                            Professional Services
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>

            

              <div className="col-md-10 media-1024" >
                <div className="mainmenu-block">
                  <nav className="navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                    
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                    <div
                      className="navbar-collapse collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item" >
                          <NavLink className="nav-link" to="/shop"  >
                            All Products
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/flash-deals" >
                            Flash Deals
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/new-arrival" >
                            New Arrivals
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/stock-clearance" >
                            Stock Clearance
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/Manufacturer" >
                            Manufacturers
                          </NavLink>
                        </li>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/export-import" >
                            Exporters & Importers
                          </NavLink>
                        </li>
                        <li style={{ marginTop: "8px" }}>
                          <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <button className="dropdown-button" style={{ marginBottom: "600" }}>More <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                            {isDropdownVisible && (
                              <div className="dropdown-menu">

                                <ul>
                                  <li className="nav-item">
                                    <NavLink className="nav-link" to="/digital-services" >
                                      Digital Service
                                    </NavLink>
                                  </li>
                                  <li className="nav-item">
                                    <NavLink
                                      className="nav-link"
                                      to="/professional-services"
                                    >
                                      Professional Services
                                    </NavLink>
                                  </li>
                                </ul>

                              </div>
                            )}
                          </div>
                        </li>

                      </ul>
                    </div>
                  </nav>
                </div>
              </div>


            </div>
          </div>
          <div ref={menuRef}>
            {open && (
              <div class="onclick-dropdown">
                <div className="" style={{ padding: "12px 25px 10px 5px" }}>
                  <div className="row dropdown-top-border">
                    <div className="col-md-4 dropdown-service-list">
                      <div className="dropdown-right-border">
                        <ul>
                          {category?.data?.map((item, index) => (
                            <li
                              onClick={() => {
                                navigate("/category/" + item.slug);
                                setOpen(false);
                              }}
                              id={item.id}
                              style={{
                                color:
                                  activeButton == index ? "#f8bb21" : "black",
                              }}
                              onMouseOver={() => {
                                handleGetSubCategory(item.id);
                                setActiveButton(index);
                              }}
                            >
                              <img src={item.icon} />
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8 dropdown-right-section">
                      <div>
                        <div className="cross-btn">
                          <h1>All Categories</h1>
                          <img
                            src="../assets/images/cross-icon.png"
                            onClick={handleCloseMegaMenu}
                          />
                        </div>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Type here to Search category..."
                            aria-label="Recipient's username"
                            onChange={handlesearchsubcategory}
                            aria-describedby="basic-addon2"
                          />
                        =
                        </InputGroup>
                        <div className="row sub-dropdown-filter">
                          {subCategory?.data?.map((item, index) => (
                            <div className="col-md-4" key={index}>
                              <div
                                className="sub-category-mr"
                                onClick={() => {
                                  navigate("/category/" + item.slug);
                                  setOpen(false);
                                }}
                              >
                                <img src={item.icon} width="30px" /> {item.name}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <ul></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="city-bg-img">
                    <div className="dropdown-contact-section">
                      <div>
                        <div>
                          <button className="chat-btn">
                            {filterdata("whatsapp_number") && (
                              <a href={"https://api.whatsapp.com/send?phone=" + filterdata("whatsapp_number").value} className="whatsapp-icon-color" target="_blank">
                                Chat with us{" "}
                                <img src="../assets/images/wa-icon.png" />
                              </a>
                            )}
                          </button>
                        </div>
                        <div>
                          {ban != 0 && (
                            <button className="sell-aladdin-btn" onClick={() => { navigate("/seller-packages"); setOpen(false) }}>
                              Sell on Aladdin Direct{" "}
                              <img src="../assets/images/user-icon.png" />
                            </button>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>A few reasons you'll love Online Business Services</p>
                        <h2>Call Us On: +91-999999999*</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid mobile-hidden mt-2 all-services-768">
            <div className="row" >
              <div className="col-md-3">
                <div className="all-department-menu">
                  <ul className="navbar-nav">
                   
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                       
                        data-toggle="dropdown"
                      >
                        <i className="fas fa-align-left"></i> All Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="mainmenu-block">
                  <nav className="navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      onClick={handleOpen}
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className={"bottom-header-768"}>
          <div className="container-fluid">
            <div className="row" style={{ background: "#ffb800" }}>
              <div className="col-md-6">
                <div className="all-department-menu">
                  <ul className="navbar-nav">
                    <li
                      className="all-categories-link"
                      id="0"
                      onClick={handleSubmit}
                    >
                      <i className="fa fa-align-left"></i>All Categories
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6" >
                <div className="mainmenu-block">
                  <nav className="navbar navbar-expand-lg" style={{ marginBottom: "10px", }}>
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={handleOpenCategories}
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      style={{ background: "#fff" }}
                    >
                      <i className="fas fa-bars"></i>
                    </button>

                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div ref={menuRef}>
            {open && (
              <div class="onclick-dropdown">
                <div className="" style={{ padding: "12px 25px 10px 5px" }}>
                  <div className="row dropdown-top-border">
                    <div className="col-md-4 dropdown-service-list">
                      <div className="dropdown-right-border">
                        <ul>
                          {category?.data?.map((item, index) => (
                            <li
                              onClick={() => {
                                navigate("/category/" + item.slug);
                                setOpen(false);
                              }}
                              id={item.id}
                              style={{
                                color:
                                  activeButton == index ? "#f8bb21" : "black",
                              }}
                              onMouseOver={() => {
                                handleGetSubCategory(item.id);
                                setActiveButton(index);
                              }}
                            >
                              <img src={item.icon} />
                              {item.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-8 dropdown-right-section">
                      <div>
                        <div className="cross-btn">
                          <h1>All Categories</h1>
                          <img
                            src="../assets/images/cross-icon.png"
                            onClick={handleCloseMegaMenu}
                          />
                        </div>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Type here to Search category..."
                            aria-label="Recipient's username"
                            onChange={handlesearchsubcategory}
                            aria-describedby="basic-addon2"
                          />
                         
                        </InputGroup>
                        <div className="row sub-dropdown-filter">
                          {subCategory?.data?.map((item, index) => (
                            <div className="col-md-4" key={index}>
                              <div
                                className="sub-category-mr"
                                onClick={() => {
                                  navigate("/category/" + item.slug);
                                  setOpen(false);
                                }}
                              >
                                <img src={item.icon} width="30px" /> {item.name}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div>
                          <ul></ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="city-bg-img">
                    <div className="dropdown-contact-section">
                      <div>
                        <div>
                          <button className="chat-btn">
                            {filterdata("whatsapp_number") && (
                              <a href={"https://api.whatsapp.com/send?phone=" + filterdata("whatsapp_number").value} className="whatsapp-icon-color" target="_blank">
                                Chat with us{" "}
                                <img src="../assets/images/wa-icon.png" />
                              </a>
                            )}
                          </button>
                        </div>
                        <div>
                          {ban != 0 && (
                            <button className="sell-aladdin-btn" onClick={() => { navigate("/seller-packages"); setOpen(false) }}>

                              Sell on Aladdin Direct{" "}
                              <img src="../assets/images/user-icon.png" />

                            </button>
                          )}
                        </div>
                      </div>
                      <div>
                        <p>A few reasons you'll love Online Business Services</p>
                        <h2>Call Us On: +91-999999999*</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="container-fluid mobile-hidden mt-2">
            <div className="row  all-services-768">
              <div className="col-md-3">
                <div className="all-department-menu">
                  <ul className="navbar-nav">
                  
                    <li className="nav-item dropdown">
                      <Link
                        className="nav-link dropdown-toggle"
                       
                        data-toggle="dropdown"
                      >
                        <i className="fas fa-align-left"></i> All Services
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="mainmenu-block">
                  <nav className="navbar navbar-expand-lg">
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-bs-toggle="collapse"
                      onClick={handleOpen}
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>


        <Offcanvas
          show={show1}
          onHide={handleClose3}
          style={{ width: 300 }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span style={{ marginLeft: 10 }}>hello anas</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{ borderTop: "2px solid lightgray ", padding: 0 }}
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 all-categories-768">
              <li className="nav-item" >
                <NavLink className="nav-link" to="/shop"  >
                  All Products
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/flash-deals" >
                  Flash Deals
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/new-arrival" >
                  New Arrivals
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/stock-clearance" >
                  Stock Clearance
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Manufacturer" >
                  Manufacturers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/export-import" >
                  Exporters & Importers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/digital-services" >
                  Digital Service
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/professional-services"
                >
                  Professional Services
                </NavLink>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas
          show={show}
          onHide={handleClose2}
          style={{ width: 300 }}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <span style={{ marginLeft: 10 }}>Service Section</span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body
            style={{ borderTop: "2px solid lightgray ", padding: 0 }}
          >
            <div className="mt-1" style={{ padding: 10 }}>
              <FontAwesomeIcon icon={faCog} style={{ fontSize: 25 }} />{" "}
              <span style={{ fontSize: 20, marginLeft: 10, fontWeight: "400" }}>
                Service 1{" "}
              </span>
            </div>
            <div
              style={{ borderTop: "2px solid lightgray ", marginBottom: 10 }}
            ></div>
            <div className="mt-1" style={{ padding: 10 }}>
              <FontAwesomeIcon icon={faCog} style={{ fontSize: 25 }} />{" "}
              <span style={{ fontSize: 20, marginLeft: 10, fontWeight: "400" }}>
                Service 1{" "}
              </span>
            </div>
            <div
              style={{ borderTop: "2px solid lightgray ", marginBottom: 10 }}
            ></div>
            <div className="mt-1" style={{ padding: 10 }}>
              <FontAwesomeIcon icon={faCog} style={{ fontSize: 25 }} />{" "}
              <span style={{ fontSize: 20, marginLeft: 10, fontWeight: "400" }}>
                Service 1{" "}
              </span>
            </div>
            <div
              style={{ borderTop: "2px solid lightgray ", marginBottom: 10 }}
            ></div>
            <div className="mt-1" style={{ padding: 10 }}>
              <FontAwesomeIcon icon={faCog} style={{ fontSize: 25 }} />{" "}
              <span style={{ fontSize: 20, marginLeft: 10, fontWeight: "400" }}>
                Service 1{" "}
              </span>
            </div>
            <div
              style={{ borderTop: "2px solid lightgray ", marginBottom: 10 }}
            ></div>
          </Offcanvas.Body>
        </Offcanvas>
        <Menu
          id="customized-menu"
          anchorEl={anchorEl2}
          anchorOrigin={{ vertical: "bottom" }}
          transformOrigin={{ vertical: "top" }}
          getContentAnchorEl={null}
          open={Boolean(anchorEl2)}
          onClose={handleClose}
          className="drop-position"
        >
          <div className="drop-width" style={{ left: 0 }}>
            <div className="col-md-12 col-xs-12 col-sm-12 row">
              {categories.map((category) => (
                <div className="col-md-3" style={{ textAlign: "center" }}>
                  <div className="mt-4" style={{ marginBottom: 30 }}>
                    <div className={`mt-1 dropHead`}> {category.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Menu>

      </div > */}
        <HeaderPage />
    </>
  );
};
export default Header;
