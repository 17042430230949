import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { NavLink } from "react-router-dom";
import { Container, Table, Badge, Button } from "react-bootstrap";
import EyeIcon from "../components/dashboard/dash-eye.png";
import DownloadIcon from "../components/dashboard/Download.svg";

import Page from "../components/Page";

const purchaseData = [
  {
    id: 1,
    code: "#1234567890",
    customerName: "Cedric Kelly",
    productQty: 204,
    dateTime: "28-09-2021 10:30",
    orderAmt: "Rs 500",
    paymentStatus: "Paid",
    orderStatus: "Confirmed *",
    mobileNo: 1234567890,
    emailId: 'user@avon.com'
  },
  {
    id: 2,
    code: "#1234567890",
    customerName: "Cedric Kelly",
    productQty: 204,
    dateTime: "28-09-2021 10:30",
    orderAmt: "Rs 500",
    paymentStatus: "Paid",
    orderStatus: "Confirmed *",
    mobileNo: 1234567890,
    emailId: 'user@avon.com'
  },
  {
    id: 3,
    code: "#1234567890",
    customerName: "Cedric Kelly",
    productQty: 204,
    dateTime: "28-09-2021 10:30",
    orderAmt: "Rs 500",
    paymentStatus: "Paid",
    orderStatus: "Confirmed *",
    mobileNo: 1234567890,
    emailId: 'user@avon.com'
  },
  {
    id: 4,
    code: "#1234567890",
    customerName: "Cedric Kelly",
    productQty: 204,
    dateTime: "28-09-2021 10:30",
    orderAmt: "Rs 500",
    paymentStatus: "Paid",
    orderStatus: "Confirmed *",
    mobileNo: 1234567890,
    emailId: 'user@avon.com'
  },
  {
    id: 5,
    code: "#1234567890",
    customerName: "Cedric Kelly",
    productQty: 204,
    dateTime: "28-09-2021 10:30",
    orderAmt: "Rs 500",
    paymentStatus: "Paid",
    orderStatus: "Confirmed *",
    mobileNo: 1234567890,
    emailId: 'user@avon.com'
  },
];

const VendorPurchaseHistory = () => {
  return (
    <Page title="Purchase History">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Purchase History</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
            {/* <div>
              <Button className="toggle-button" variant="warning">
                Seller Info
              </Button>
            </div> */}
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Purchase History</h4>
                <div className="vendor-order-section">
                  <div className="card-block">
                    <div className="order-head-blk">
                      <h5>Purchase History</h5>
                    </div>
                    <div className="card-body-blk ">
                      <div class="table-responsive">
                      
                          <Table responsive="md" hover style={{whiteSpace: "nowrap"}}>
                            <thead>
                              <tr>
                                <th>Date & Time</th>
                                <th>Order ID</th>
                                <th>Customer Name</th>
                                <th>Mobile & Email ID</th>
                                <th>Number Of Products</th>
                                <th>Order Amount</th>
                                <th>Payment Status</th>
                                <th>Delivery Status</th>
                                <th>Options</th>
                              </tr>
                            </thead>
                            <tbody>
                              {purchaseData.map((purchase) => (
                                <tr key={purchase.id}>
                                  <td>{purchase.dateTime}</td>
                                  <td><a href="#"  >{purchase.code}</a></td>
                                  <td>{purchase.customerName}</td>
                                  <td>{purchase.mobileNo}<td>{purchase.emailId}</td></td>
                                  <td>{purchase.productQty}</td>
                                  <td>{purchase.orderAmt}</td>
                                  {/* <td><Badge bg="success">{purchase.paymentStatus}</Badge></td> */}
                                  <td ><button className="paid-btn">{purchase.paymentStatus}</button></td>
                                  <td>{purchase.orderStatus}</td>
                                  <td className=""><img src={EyeIcon} alt="" /><img style={{paddingLeft: '2px'}} src={DownloadIcon} alt="" /></td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                          </div>
                    </div>
                  </div>
                </div>

                {/* <div className="card-body-blk">
                    <h5 className="dashboardOrderList">Purchase Order List</h5>
                    <DashboardPurchaseList />
                  </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};
export default VendorPurchaseHistory;
