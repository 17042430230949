import React, { useState } from "react";
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';
import { NavLink } from "react-router-dom";
import SellerBlogComponent from './SellerBlogComponent'
import axios from "axios";
import { BASE_URL,DOMAIN_NAME } from "../../config";
import { useEffect } from "react";
const SellerContactUs = (props) => {
const [contactus,setcontactus] = useState("");
useEffect(()=>{
    getcontactus();
},[])
    const getcontactus=async()=>{
        await axios.get(BASE_URL+'/seller/getheader',{
            headers: {
              'Accept': 'application/json',
              'domainname': DOMAIN_NAME
            }
          }).then(({data})=>{
            setcontactus(data.data)
          }).catch(({response})=>{
          
          })
    }
  return (
    <div style={{background:'#F5F5F5'}}>
            <SellerHeader />
            <section className="breadcrumb-sction mt-2">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/seller-website">Home</NavLink>
              </li>
              <li>Contact Us</li>
            </ul>
          </div>
        </section>
            <div className="col-md-12 card-body-blk">
            <div className="container seller-pd-1">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Contact us</h1>
                        <div className="seller-contact-details">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                            <p>{contactus?.full_address}</p>
                        </div>
                        <div className="seller-contact-details">
                            <i class="fa fa-mobile" aria-hidden="true"></i>
                            <p>{contactus?.business_mobilenumber && contactus?.business_mobilenumber.join(",")}</p>
                        </div>
                        <div className="seller-contact-details">
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                            <p>{contactus?.whatsapp_number}</p>
                        </div>
                        <div className="seller-contact-details">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <p>{contactus?.business_email && contactus?.business_email.join(",")}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <iframe
                            src={contactus?.map_url}
                            width="600"
                            height="350"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                        ></iframe>

                    </div>
                </div>
            </div>             
            </div>
            <SellerFooter />
        </div>
  )
}

export default SellerContactUs
