import React from 'react'
import "../style/NewLeads.css"
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";


export const FollowupHistory = () => {
    return (
        <>
            <div className="new-leads followup-history py-4" style={{ background: "#0000001a" }}>

            <div className="main-cont d-flex justify-content-between">

<VendorDashboardSideMenu />


{/* rght side Cont  */}
<div className="right px-lg-5 px-md-4 px-3 col-md-9" >
    <div className="d-flex justify-content-between align-items-center w-100">
        <h2>View Follow-up History</h2>
        <h2> January 2023</h2>
    </div>


    <div className="art-1 my-lg-5  my-4">
        <div className="d-flex justify-content-between align-items-center">
            <h6>Purchase History</h6>
            <h6>Subscription Expiring:  <span>December 31st 2023</span></h6>
        </div>

        <div className="cards-div mb-4">
            <div className="card-cont  my-3">
                <div className="top-cont d-flex justify-content-between align-items-center">
                    <div>
                        <h4>Date & Time: </h4>
                        <p className="mb-0">04 Aug 2022 | 3:00 PM</p>
                    </div>

                    <div className='text-end'>
                        <h4>Next Follow-up</h4>
                        <p className="mb-0">Date & Time: 04 Aug 2022 | 3:00 PM</p>
                    </div>
                    
                </div>
                <div className='cont-body'>
                    <p className='mb-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
            </div>
            <div className="card-cont  my-3">
                <div className="top-cont d-flex  justify-content-between align-items-center">
                    <div>
                        <h4>Date & Time: </h4>
                        <p className="mb-0">04 Aug 2022 | 3:00 PM</p>
                    </div>

                    <div className='text-end'>
                        <h4>Next Follow-up</h4>
                        <p className="mb-0">Date & Time: 04 Aug 2022 | 3:00 PM</p>
                    </div>
                    
                </div>
                <div className='cont-body'>
                    <p className='mb-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
            </div>
            <div className="card-cont  my-3">
                <div className="top-cont d-flex  justify-content-between align-items-center">
                    <div>
                        <h4>Date & Time: </h4>
                        <p className="mb-0">04 Aug 2022 | 3:00 PM</p>
                    </div>

                    <div className='text-end'>
                        <h4>Next Follow-up</h4>
                        <p className="mb-0">Date & Time: 04 Aug 2022 | 3:00 PM</p>
                    </div>
                    
                </div>
                <div className='cont-body'>
                    <p className='mb-1'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
            </div>
        </div>
    </div>

   
</div>

</div>
            </div>
        </>
    )
}
