import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink } from "react-router-dom";
import { Table, Button, Form, Modal, ModalHeader, ModalBody } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import EyeIcon from '../components/dashboard/dash-eye.png'
import CopyIcon from '../components/dashboard/copy.png';
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const productsData = [
  {
    id: 1,
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor....",

  },
  {
    id: 2,
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor....",
  },
  {
    id: 3,
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor....",
  },
  {
    id: 4,
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor....",
  },
  {
    id: 5,
    question: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ?",
    answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor....",
  },
];

const VendorProducts = () => {

  const [show, setShow] = useState(false);
  const [newQues, setNewQues] = useState('')
  const [newAns, setNewAns] = useState('')
  const token = localStorage.getItem('avonToken');
  const [checked, setChecked] = useState("block");
  const toggleChecked = (e) => setChecked(e.target.checked ? "active" : "block");
  const [err, setErr] = useState([]);
  const [faqData, setFaqData] = useState([]);
  const [ques, setQues] = useState('');
  const [ans, setAns] = useState('');
  const [edit, setEdit] = useState(false);
  const [idNum, setIdNum] = useState('');
  const [viewData, setViewData] = useState('')

  useEffect(() => {

    FetchFaq();
  }, [])

  const FetchFaq = async () => {

    await axios.get(BASE_URL + '/getsellerfaq', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setFaqData(data.data)
    })

  }

  

  const ViewFaq = async (id) => {
    await axios.get(BASE_URL + `/editsellerfaq/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
    setViewData(data.data)
    })
  }

  const AddFaq = async () => {
    const data = {
      'status': checked,
      'question': newQues,
      'answer': newAns
    }

    await axios.post(BASE_URL + '/addupdatesellerfaq', data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchFaq();
      console.log('dbghdb', data)
      if (data.status == true) {
        setShow(false)
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        setErr(response.data.message)
      }
    })
  }

  const DeleteFaq = async (id) => {
    await axios.delete(BASE_URL + `/deletesellerfaq/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchFaq();
      console.log('dbghdb', data)
      if (data.status == true) {
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        toast.error(response.message)
      }
    })
  }

  const UpdateFaq = async () => {
    const data = {
      'status': checked,
      'question': ques,
      'answer': ans,
      'id': idNum
    }
    await axios.post(BASE_URL + `/addupdatesellerfaq`, data, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchFaq();
      console.log('dbghdb', data)
      if (data.status == true) {
        setEdit(false)
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        toast.error(response.message)
      }
    })
  }

  const [viewFaq, setViewFaq] = useState(false);


  return (
    <Page title="Vendor Products">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>FAQ</li>
            </ul>
            <div className="col-md-12 float-right">
              <Button className="" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4 className="bold">
                  FAQ

                  <Button style={{ float: "right", backgroundColor: '#00B987' }} variant="success" onClick={() => {setShow(true);setChecked("block")}}>

                    Add New FAQs
                  </Button>
                </h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h5>FAQ's</h5>

                    </div>
                    {viewFaq ?
                      <div className="card-body-blk">
                        <div style={{ textAlign: 'right' }}>
                          <Button onClick={() => setViewFaq(false)}>Back</Button>
                        </div>
                        <h6>{viewData.question}</h6>
                        <div className="mt-2">{viewData.answer}</div>
                      </div>
                      :

                      <div className="card-body-blk">
                        <Table responsive="md" hover>
                          <thead>
                            <tr>
                              <th className="col-md-4">Question</th>
                              <th className="col-md-4">Answers</th>
                              <th className="col-md-2">Status</th>
                              <th className="col-md-2">Options</th>

                            </tr>
                          </thead>
                          <tbody>
                            {faqData && faqData.map((product) => (
                              <tr key={product.id}>
                                <td style={{ padding: '13px' }}>{product.question}</td>
                                <td style={{ padding: '13px' }}>{product.answer}</td>
                                <td style={{ padding: '13px' }}>{product.status.charAt(0).toUpperCase() + product.status.slice(1)}</td>
                                <td>
                                  <img style={{ paddingLeft: '5px', cursor:'pointer' }} src={EyeIcon} alt="" onClick={() => {setViewFaq(true); ViewFaq(product.id)}} />
                                  <img style={{ paddingLeft: '5px', cursor:'pointer' }} src={EditIcon} alt="" onClick={() => { setEdit(true); setQues(product.question); setAns(product.answer); setIdNum(product.id);setChecked(product.status) }} />
                                  <img style={{ paddingLeft: '5px', cursor:'pointer' }} src={DeleteIcon} alt="" onClick={() => DeleteFaq(product.id)} />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    }
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <ModalHeader closeButton>
          Add FAQ
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "10px" }}>
          Not Active
            <Form.Switch
              type="switch"
              checked={checked=="active" ? true : false}
              onChange={toggleChecked}
            />
            Active
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setNewQues(e.target.value)}
            />
            {err && err.question ? <div className="text-danger"> {err && err.question} </div> : ''}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label className="bold">Answer</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setNewAns(e.target.value)}
            />
            {err && err.question ? <div className="text-danger"> {err && err.answer} </div> : ''}


          </div>
        </ModalBody>

        <Modal.Footer>
          <Button onClick={AddFaq}>Add</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={edit} onHide={() => setEdit(false)}>
        <ModalHeader closeButton>
          Update FAQ
        </ModalHeader>
        <ModalBody>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: "10px" }}>
        Not Active
            <Form.Switch
              type="switch"
              checked={checked=="active" ? true : false}
              onChange={toggleChecked}
            />
            Active
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Question</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setQues(e.target.value)}
              defaultValue={ques}
            />
            {err && err.question ? <div className="text-danger"> {err && err.question} </div> : ''}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label className="bold">Answer</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setAns(e.target.value)}
              defaultValue={ans}
            />
            {err && err.question ? <div className="text-danger"> {err && err.answer} </div> : ''}


          </div>
        </ModalBody>

        <Modal.Footer>
          <Button onClick={UpdateFaq}>Update</Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default VendorProducts;
