import React from 'react'
import "../style/SellerDashboard.css"
import { Header } from '../components/seller-dashboard/Header'
import { UsermainContent } from '../components/user-dashboard/UsermainContent'
export const UserDashboard = () => {
  return (
    <>
    <section>
        <Header/>
    </section>

    <section>
        <UsermainContent/>
    </section>
    </>
  )
}
