import React from 'react'
import { Link } from 'react-router-dom'

export const Communicate = () => {
    return (
        <>
            <div className="communicate mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3 pb-lg-5 pb-4">

                <div className="top-content">
                    <h3>Communications Channel</h3>
                    <p >
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem, neque? Adipisci dolores ex perferendis laborum consequatur pariatur, voluptatum harum sit, debitis, totam illo.
                    </p>
                </div>

                <div className="overf-div">
                    <div className="cards d-flex justify-content-between align-items-center flex-wrap">

                        <Link to="">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex gap-lg-4 gap-md-3 gap-2 align-items-center">
                                    <img src="/assets/icons/message.png" alt="" /> &nbsp;&nbsp;
                                    <p className='mb-0 bold-para'>SMS</p>
                                </div>

                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" /></svg>
                            </div>
                        </Link>

                        <Link to="">
                            <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex gap-lg-4 gap-md-3 gap-2 align-items-center">
                                <img src="/assets/icons/email-1.png" alt="" />&nbsp;&nbsp;
                                <p className='mb-0 bold-para'>Email</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" /></svg>
                            </div>
                        </Link>

                        <Link to="">
                            <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex gap-lg-4 gap-md-3 gap-2 align-items-center">
                                <img src="/assets/icons/whatsapp-1.png" alt="" />&nbsp;&nbsp;
                                <p className='mb-0 bold-para'>Whatsapp</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" /></svg>
                            </div>
                        </Link>
                        <Link to="">
                            <div className="d-flex align-items-center justify-content-between w-100">
                            <div className="d-flex gap-lg-4 gap-md-3 gap-2 align-items-center">
                                <img src="/assets/icons/voice-message-1.png" alt="" />&nbsp;&nbsp;
                                <p className='mb-0 bold-para'>Voice</p>
                                </div>
                                <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" /></svg>
                            </div>
                        </Link>

                    </div>
                </div>

            </div>

        </>
    )
}
