import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, Form, ProgressBar } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Page from "../components/Page";

function TrackOrder() {
  return (
    <Page title="Track Order">
      <section className="breadcrumb-sction">
        <div className="container-fluid">
          <ul className="breadcrumb-block">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>Track Order</li>
          </ul>
        </div>
      </section>
      <div className="trackorder-section">
        <Container>
          <div className="justify-content-center">
            <Col>
              <div className="trackorderblk">
                <p>To track your order, please enter your Order ID in the box below. This was given to you on your receipt and in the confirmation email you should have received.</p>
                <Form>
                  <div className="row justify-content-center">

                    <div className="col-md-12">
                      <Button variant="primary" type="submit">
                        Track Order
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
            </Col>
            <div className="order-track-status">
              <Container style={{ borderBottom: '1px solid grey' }}>
                <Row>
                  <Col md={4}>Order Placed<Col style={{ fontWeight: 'bold' }}>11th Sept, 2017</Col></Col>
                  <Col md={4}>Order Number<Col><a>#677-76654372</a></Col></Col>
                  <Col md={4}><Col><a href="#">Printable Order Summary</a></Col></Col>
                </Row>
              </Container>
              <div className="card-body-blk">

                <Row>
                  <p style={{ fontSize: 18 }}>Touch Phone</p>
                  <img className='order-img' src="assets/images/product-1.jpg" alt="" />
                  <Col>
                    <p>Price: $210.00</p>

                  </Col>

                  <Col>
                    <Button variant='primary'>View Seller Info</Button>
                    <Col>
                      <Button style={{ marginTop: '5px' }} variant='outline-secondary'>Return or Replace Items</Button>
                      <Col>
                        <Button style={{ marginTop: '5px' }} variant='outline-secondary'>Get Product Support</Button>

                      </Col>
                    </Col>
                  </Col>

                </Row>




              </div>
              <ProgressBar style={{ height: '35px', marginTop: '20px' }}>

                <ProgressBar style={{ fontSize: '15px' }} label="Order Placed" animated striped variant="success" now={40} key={1} />
                <ProgressBar style={{ fontSize: '15px' }} label="Order Shipped" animated variant="warning" now={22} key={2} />
                <ProgressBar style={{ fontSize: '15px' }} label="Order Arriving Soon" animated striped variant="danger" now={13} key={3} />
                <ProgressBar style={{ fontSize: '15px' }} label="Order Delivered" animated striped now={0} key={4} />

              </ProgressBar>

            </div>

          </div>
        </Container>
      </div>
    </Page>
  );
}
export default TrackOrder;
