import React, { useState, useEffect } from 'react'
import "../style/BuildmyStartup.css"
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import { BuildmsComp2 } from '../components/build-my-startup/BuildmsComp2'
import { Services } from '../components/build-my-startup/Services'
import { BuildmsForm } from '../components/build-my-startup/BuildmsForm'
import { BuildsmBenefits } from '../components/build-my-startup/BuildsmBenefits'
import { RecentStartup } from '../components/build-my-startup/RecentStartup'
import { BuildmsCart } from '../components/build-my-startup/BuildmsCart'
import axios from 'axios';
import { BASE_URL } from '../config'
import Loader from '../components/loader/Loader.component'
export const BuildmyStartup = () => {
  const [widget, setwidget] = useState("")
  const [reportdata, setreportdata] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    widgetData();
    getstartupreportData();
  }, []);
  const widgetData = async () => {
    setIsLoading(true);
    try {
      const wgtData = await axios.get(BASE_URL + "/startupwidget");
      setwidget(wgtData.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const getstartupreportData = async () => {
    setIsLoading(true);
    try {
      const Data = await axios.get(BASE_URL + "/getstartupreport");
      setreportdata(Data.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className='pb-2' style={{ background: "#F5F5F5" }}>
      <div dangerouslySetInnerHTML={{ __html: widget.widget1 }}></div>
        {/* <section>
        <Sellerbanner/>
    </section>

    <section>
        <BuildmsComp2/>
    </section>

    <section>
        <Services/>
    </section> */}

        <section>
          <BuildmsForm data={widget.widget2} />
        </section>
        <div dangerouslySetInnerHTML={{ __html: widget.widget3 }}></div>
        {/* <section>
        <BuildsmBenefits/>
    </section>

    <section>
        <BuildmsComp2/>
    </section> */}

        <section>
          <BuildmsCart data={reportdata} />
        </section>
        <div dangerouslySetInnerHTML={{ __html: widget.widget4 }}></div>
        {/* <section>
        <RecentStartup/>
    </section> */}


      </div>
    </>
  )
}
