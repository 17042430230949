import React from 'react'
import "../style/SellerDashboard.css"
import { MainContent } from '../components/seller-dashboard/MainContent'
import { Header } from '../components/seller-dashboard/Header'


export const SellerDashboard = () => {
  return (
    <>
      <section>
        <Header />

      </section>
      <section>
        <MainContent />

      </section>

    </>
  )
}
