import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import ProductStarRating from "../components/product-star/product-star-rating.component";
import { Link, NavLink } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Page from "../components/Page";
import ProductImg from "../components/gallery/image6.svg";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import axios from "axios";
import { BASE_URL } from "../config";
const ManageStartupVendor = () => {
  const [data,setdata] = useState([]);
  const token = localStorage.getItem('avonToken');
  useEffect(() => {
    FetchStartup();
  }, [])
  const FetchStartup = async () => {
    await axios
      .get(BASE_URL + "/getstartupseller", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setdata(data.data);

        
        
      });
  };
  return (
    <Page title="Product Review">
    <div className="inner-pages-wrapper">
      <section className="breadcrumb-sction">
        <div className="container-fluid">
          <ul className="breadcrumb-block">
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>Manage Startup</li>
          </ul>
          {/* <div className="col-md-12">
          <Button className="dashboard-btn" href="/dashboard" variant="primary">
              Customer Dashboard
            </Button>
            </div> */}
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="row">
            <VendorDashboardSideMenu />
            <div className="col-md-9">
              <h4>Manage Startup</h4>

              <section>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-block">
                      <div className="order-head-blk">
                        <h5>Manage Startup</h5>
                      </div>
                      <div className="card-body-blk">
                        <Table responsive="md">
                          <thead>
                            <tr>
                              <th>Date & Time</th>
                              <th>Startup Name</th>
                              <th>Founder Name</th>
                              <th>Business Category</th>
                              <th>Established Year</th>
                              <th>Listing Type</th>
                              <th>Listing Status</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data && data.map((item,i)=>(
                              <tr>
                              <td>
                              {item.created_at}
                              </td>
                              <td > {item.startup_name}</td>
                              <td >
                              {item.founder_name}
                              </td>
                              <td style={{ paddingTop: "22px" }}>
                              {item.business_category.map((item,k)=>(
                                <>{item.category_name}</>
                              ))}
                              </td>
                              <td>
                              {item.establish_year}
                              </td>
                              <td>
                              {item.listing_type}
                              </td>
                              <td>
                              {item.listing_status}
                              </td>
                              <td>
                              {item.status}
                              </td>
                              <td>
                                <Link to={"/manage-startup/"+item.ids}><i className="fa fa-edit"></i></Link>
                              </td>
                            </tr>
                            ))}
                            
                           
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Page>
  )
}

export default ManageStartupVendor