import React, { useEffect } from 'react'
import "../style/Blogs1.css"
import SeoOurteam from '../seo page/SeoOurteam'

export const OurTeam = () => {
    useEffect(() =>{
        window.scrollTo(0,0)
    },[])
    return (
        <>
        <SeoOurteam/>
            <div className="our-team">
                <div className="px-lg-5 px-md-4 px-3 py-3">
                    <div className="top-content text-center">
                        <h3>MEET OUR <span>BEST TEAM</span></h3>
                        <p>Cras eu dignissim mauris. Duis imperdiet erat sapien, molestie aliquet arcu tincidunt id. Mauris sit amet quam mi. Duis porttitor lectus quis turpis malesuada, eu luctus elit dignissim.</p>
                        <div className="bottom-line"></div>
                    </div>

                    <div className="cards-container d-flex flex-wrap gap-3 justify-content-between">
                        <div class="card">
                            <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                <img src="/assets/images/our-team1.png" class="card-img-top" alt="..." />
                            </div>

                            <div class="card-body text-center">
                                <h5 class="card-title">Alleah Janette</h5>
                                <p className='bold-para'>Chief Creative Officer</p>
                                <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                            </div>
                        </div>
                      
                        <div class="card">
                            <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                <img src="/assets/images/our-team1.png" class="card-img-top" alt="..." />
                            </div>

                            <div class="card-body text-center">
                                <h5 class="card-title">Alleah Janette</h5>
                                <p className='bold-para'>Chief Creative Officer</p>
                                <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                            </div>
                        </div>
                      
                        <div class="card">
                            <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                <img src="/assets/images/our-team1.png" class="card-img-top" alt="..." />
                            </div>

                            <div class="card-body text-center">
                                <h5 class="card-title">Alleah Janette</h5>
                                <p className='bold-para'>Chief Creative Officer</p>
                                <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                            </div>
                        </div>
                        <div class="card">
                            <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                <img src="/assets/images/our-team1.png" class="card-img-top" alt="..." />
                            </div>

                            <div class="card-body text-center">
                                <h5 class="card-title">Alleah Janette</h5>
                                <p className='bold-para'>Chief Creative Officer</p>
                                <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                            </div>
                        </div>
                        <div class="card">
                            <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                <img src="/assets/images/our-team1.png" class="card-img-top" alt="..." />
                            </div>

                            <div class="card-body text-center">
                                <h5 class="card-title">Alleah Janette</h5>
                                <p className='bold-para'>Chief Creative Officer</p>
                                <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                            </div>
                        </div>
                        <div class="card">
                            <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                <img src="/assets/images/our-team1.png" class="card-img-top" alt="..." />
                            </div>

                            <div class="card-body text-center">
                                <h5 class="card-title">Alleah Janette</h5>
                                <p className='bold-para'>Chief Creative Officer</p>
                                <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                            </div>
                        </div>
                      
                        
                    </div>
                </div>
            </div>
        </>
    )
}
