import React, { useEffect, useState, useReducer } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

// Components
import Header from './components/Header';
import Footer from './components/Footer';
import FlashMessage from './components/flash-message/FlashMessage';
import FooterPage from './components/HOD/FooterPage/FooterPage';
// Pages
import ProductsPage from './pages/ProductsPage';
import About from './pages/About';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import LoginOTP from './pages/LoginOTP';
import GetOTP from './pages/GetOTP';
import LoginPassword from './pages/LoginPassword';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Contact from './pages/Contact';
import CompareProducts from './pages/CompareProducts';
import Shop from './pages/Shop';
import Category from './pages/Category';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import Success from './pages/Success';
import Faq from './pages/Faq';
import Dashboard from './pages/Dashboard';
import MyAccount from './pages/MyAccount';
import Setting from './pages/Setting';
import Orders from './pages/Orders';
import MyFavourite from './pages/MyFavourite';
import ChangePassword from './pages/ChangePassword';
import AddressBook from './pages/AddressBook';
import TrackOrder from './pages/TrackOrder';
import VendorDashboard from './pages/VendorDashboard';
import VendorPurchaseHistory from './pages/VendorPurchaseHistory';
import ProductDownload from './pages/ProductDownload';
import ProductWishlist from './pages/ProductWishlist';
import VendorProducts from './pages/VendorProducts';
import ProductBulkUpload from './pages/ProductBulkUpload';
import VendorOrders from './pages/VendorOrders';
import ProductReview from './pages/ProductReview';
import ShopSetting from './pages/ShopSetting';
import PaymentHistory from './pages/PaymentHistory';
import Conversations from './pages/Conversations';
import MoneyWithdraw from './pages/MoneyWithdraw';
import VendorPackage from './pages/VendorPackage';
import Wallet from './pages/Wallet';
import SupportTicket from './pages/SupportTicket';
import ManageProfile from './pages/ManageProfile';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import RefundPolicy from './pages/RefundPolicy';
import Legal from './pages/Legal';
import SiteMap from './pages/SiteMap';
import Features from './pages/Features';
import NewArrivals from './pages/SellerDetails/NewArrivals/NewArrivals';
import Pricing from './pages/Pricing';
import Team from './pages/Team';
import Gallery from './pages/Gallery';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import Deal from './pages/Deal';
import VendorCheckout from './pages/VendorCheckout';
import AddProductPage from './pages/AddProductPage';
import StateContext from './context/StateContext';
import DispatchContext from './context/DispatchContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import EditVendorProduct from './pages/EditVendorProduct';
import Manufacturer from './pages/Manufactures';
import ExportImport from './pages/ExportImport';
import BusinessProfile from './pages/VendorBusinessProfile';
import VendorTax from './pages/VendorTax';
import VendorStoreSetup from './pages/VendorStoreSetup';
import VendorSliders from './pages/VendorSliders';
import VendorPickupAddress from './pages/VendorPickupAddress';
import VendorAbout from './pages/VendorAboutUs';
import VendorFAQ from './pages/VendorFAQ';
import VendorBlogs from './pages/VendorBlogs';
import VendorBrandSponser from './pages/VendorBrandSponser';
import VendorOtherPages from './pages/VendorOtherPages';
import SellerWebsite from './pages/SellerWebsite/SellerHome';
import { useLocation } from 'react-router-dom';
import SellerAboutPage from './pages/SellerWebsite/SellerFAQ';
import SellerTermsCondition from './pages/SellerWebsite/SellerTnC';
import SellerBlog from './pages/SellerWebsite/SellerBlog';
import SellerProductList from './pages/SellerWebsite/SellerProductList';
import { ThankYou } from './pages/thankyou';
import DigitalServices from './pages/DigitalServices';
import BuySell from './pages/BuySell';
import ProfServices from './pages/ProfServices';
import SellerDetailsPage from './pages/SellerDetails/SellerDetailsPage';
import Manufactures from './pages/Manufactures';
import CarForm from './pages/Forms/CarForm';
import PropertiesForm from './pages/Forms/PropertiesForm';
import ForSaleHouseApartments from './pages/Forms/Property/ForSaleHouseApartments';
import ForRentHouseApartments from './pages/Forms/Property/ForRentHouseApartments';
import LandPlots from './pages/Forms/Property/LandPlots';
import ForRentShopOffice from './pages/Forms/Property/ForRentShopOffice';
import ForSaleShopOffice from './pages/Forms/Property/ForSaleShopOffice';
import GuestHouses from './pages/Forms/Property/GuestHouses';
import MobileForm from './pages/Forms/Electronics/MobileForm';
import Tablets from './pages/Forms/Electronics/Tablets';
import Accessories from './pages/Forms/Electronics/Accesseries';
import VendorInquiry from './pages/VendorEnquiry';
import VendorAddBlog from './pages/VendorAddBlog';
import VendorEditBlog from './pages/VendorEditBlog';
import VendorBlogDetails from './pages/VendorBlogDetail';
import VendorEditPages from './pages/VendorEditPages';
import VendorAddPage from './pages/VendorAddPage';
import SellerBlogDetail from './pages/SellerWebsite/SellerBlogDetails';
import MyEnquiry from './pages/MyEnquiry';
import AddNewService from './pages/AddNewService';
import ServiceDetails from './pages/ServiceDetails';


// Styles
import './App.css';
import SellerSubscription from './pages/SellerWebsite/SellerSubscription';
import CancelSubscription from './pages/SellerWebsite/CancelSubscription';
import VendorViewPages from './pages/VendorViewPage';
import ListingPolicy from './pages/ListingPolicy';
import { CookiesProvider } from 'react-cookie';
import DynamicPage from './pages/SellerWebsite/DynamicPage';
import FlashDeal from './pages/SellerDetails/FlashDeal/FlashDeal';
import StockClearence from './pages/SellerDetails/StockClearlence/StockClearence';
import AdminLogin from './pages/AdminLogin';
import { exportPDF } from '@progress/kendo-drawing';
import VerifyEmail from './pages/VerifyEmail';
import BrandProduct from './pages/BrandProduct';
import VendorHomePageWidget from './pages/VendorHomePageWidget';
import SellerContactUs from './pages/SellerWebsite/SellerContactUs';
import VendorOurFeature from './pages/VendorOurFeature';
import WistList from './pages/WishList';
import Wishlist from './pages/WishList';
import VendorAllQuery from './pages/VendorAllQuery';
import ProductComparison from './pages/CompareProducts';
import HomePage from './components/apna-shaher/HomePage';
import Services from './components/Services/Services';
import { Startup } from './pages/Startup';
import { CategoryPage } from './pages/CategoryPage';
import { Faqsellerbuyer } from './pages/Faqsellerbuyer';
import { Sellerbenefites } from './pages/Sellerbenefites';
import { Advertisewithus } from './pages/Advertisewithus';
import { Directory } from './pages/Directory';
import { PostbuyRequirement } from './pages/PostbuyRequirement';
import { Feedback } from './pages/Feedback';
import { Reportabuse } from './pages/Reportabuse';
import { OpenStartup } from './components/startup/OpenStartup';
import { StartupForm } from './components/startup/StartupForm';
import { BuildmyStartup } from './pages/BuildmyStartup';
import { SellerDetails } from './pages/SellerDetails';
import VendorContactUs from './pages/VendorContactUs';
import VendorProductAnalytic from './pages/VendorProductAnalytic';
import VendorServices from './pages/VendorServices';
import VendorServiceAnalytics from './pages/VendorServiceAnalytics';
import VendorBulkUploadServices from './pages/VendorBulkUploadServices';
import VendorLiveChat from './pages/VendorLiveChat';
import VendorLearn from './pages/VendorLearn';
import VendorDigitalMarketing from './pages/VendorDigitalMarketing';
import VendorSetting from './pages/VendorSetting';
import LiveChat from './pages/LiveChat';
import HelpSupport from './pages/HelpSupport';
import { StartupDetail } from './pages/StartupDetail';
import { SellerDashboard } from './pages/SellerDashboard';
import { UserDashboard } from './pages/UserDashboard';
import { ManageServices } from './pages/ManageServices';
import { DirectoryListing } from './pages/DirectoryListing';
import ManageStartupVendor from './pages/ManageStartupVendor';
import EditStartupVendor from './pages/EditStartupVendor';
import VendorReviewFeedback from './pages/VendorReviewFeedback';
import ManageQuote from './pages/ManageQuote';
import ManageEditRequest from './pages/ManageEditRequest';
import { ServiceListing } from './pages/ServiceListing';
import { ServiceCategory } from './pages/ServiceCategory';
import { SuccessStory } from './pages/SuccessStory';
import { Blogs1 } from './pages/Blogs1';
import { MediaPress } from './pages/MediaPress';
import { OurTeam } from './components/OurTeam';
import AboutUs from './pages/SellerDetails/Components/AboutUs';
import { Aboutus } from './pages/Aboutus';
import { AddnewService } from './components/manage-services/AddnewService';
import { EditService } from './components/manage-services/EditService';
import { Events } from './pages/Events';
import VendorAccount from './pages/VendorAccount';
import VendorAccountSetting from './pages/VendorAccountSetting';
import VendorServiceEnquiry from './pages/VendorServiceEnquiry';
import EventDetail from './pages/EventDetail';
import { Investor } from './pages/Investor';
import { JoinOurTeam } from './pages/JoinOurTeam';
import { JobDescription } from './pages/JobDescription';
import { NewLeads } from './pages/NewLeads';
import { DigitalMarketingHistory } from './pages/DigitalMarketingHistory';
import { SocailMedia } from './pages/SocailMedia';
import { LeadPurchageHistory } from './pages/LeadPurchageHistory';
import { FollowupHistory } from './pages/FollowupHistory';
import { Graphic } from './pages/Graphic';
Axios.defaults.baseURL = 'https://apnashaher.com/admin-login/api';


// Axios.defaults.baseURL = 'http://127.0.0.1:8000/api'

const App = () => {
  

  const [isLoading, setIsLoading] = useState(true);
  const [aboutUs, setAboutUs] = useState({});
  const [clientId, setclientId] = useState('261071017149-kc52f9d86e69hhadpuhugs4rt55oegfp.apps.googleusercontent.com');
  const [blogs, setBlogs] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [featuredCategories, setFeaturedCategories] = useState([]);
  const [galleries, setGalleries] = useState([]);
  const [legalPolicy, setLegalPolicy] = useState({});
  const [privacyPolicy, setPrivacyPolicy] = useState({});
  const [refundPolicy, setRefundPolicy] = useState({});
  const [teams, setTeams] = useState([]);
  const [termsAndCondition, setTermsAndCondition] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const [flashDeal1, setFlashDeal1] = useState({});
  const [flashDeal2, setFlashDeal2] = useState({});
  const [flashDeal3, setFlashDeal3] = useState({});
  const [flashDealPotrait, setFlashDealPotrait] = useState({});
  const [flashDealSuper, setFlashDealSuper] = useState({});
  const [flashDealSuperProducts, setFlashDealSuperProducts] = useState([]);
  const [topSellingProducts, setTopSellingProducts] = useState([]);
  const [websiteSetting, setWebsiteSetting] = useState({});
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [homepageSetting, setHomepageSetting] = useState({});
  const [recentlyViewedProducts, setRecenltyViewedProducts] = useState([]);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countrycity, setcountrycity] = useState([]);
  const [locationdata, setlocationdata] = useState("");
  const [timezone, settimezone] = useState("");
  const [gcurrency, setgcurrency] = useState("");
  const [currencysymbol,setcurrencysymbol] = useState("");
  const [alldata,setalldata] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    
    fetchData();

    return () => {
      // indexRequest.cancel();
    };
  }, [currencysymbol,countrycity]);
useEffect(()=>{
  getlocation();
},[])

  const fetchData=async()=> {
    const indexRequest = Axios.CancelToken.source();
    try {
      const response = await Axios.get('/index', {
        cancelToken: indexRequest.token,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          "countrycity":countrycity,
          "localcurrency":locationdata.currency,
          "currencysymbol":currencysymbol
        },
      });
      const responseData = response.data.data;
      setalldata(responseData);
      setAboutUs(responseData.about_us);
      setBlogs(responseData.blogs);
      setBrands(responseData.brands);
      setCategories(responseData.categories);
      setFaqs(responseData.faqs);
      setFeaturedCategories(responseData.featured_categories);
      setGalleries(responseData.galleries);
      setLegalPolicy(responseData.legal);
      setPrivacyPolicy(responseData.privacy_policy);
      setRefundPolicy(responseData.sale_and_refund);
      setTeams(responseData.teams);
      setTermsAndCondition(responseData.terms_and_condition);
      setTestimonials(responseData.testimonials);
      setFlashDeal1(responseData.flash_deal_1);
      setFlashDeal2(responseData.flash_deal_2);
      setFlashDeal3(responseData.flash_deal_3);
      setFlashDealPotrait(responseData.flash_deal_potrait);
      setFlashDealSuper(responseData.flash_deal_super);
      setclientId(responseData.GOOGLE_CLIENT_ID)
      console.log("099988989======",responseData.GOOGLE_CLIENT_ID)
      setFlashDealSuperProducts(responseData.flash_deal_super_products);
      setTopSellingProducts(responseData.top_selling_products);
      setWebsiteSetting(responseData.website_setting);
      setFeaturedProducts(responseData.featured_products);
      setHomepageSetting(responseData.homepage_setting);
      setCountries(responseData.countries);
      dispatch({ type: 'setWishlistCount', value: responseData.wishlistCount });
      setIsLoading(false);
    } catch (e) {
      console.log('error');
    }
  }

  const initialState = {
    flashMessages: [],
    loggedIn: Boolean(localStorage.getItem('avonToken')),
    wishlistItemCount: 0,
    cartItemCount: 0,
    user: {
      token: localStorage.getItem('avonToken'),
      name: localStorage.getItem('avonName'),
      email: localStorage.getItem('avonEmail'),
      step: localStorage.getItem('step'),
      mobileNumber: localStorage.getItem('avonMobileNumber'),
      profileImage: localStorage.getItem('avonProfileImage'),
      lastLogin: localStorage.getItem('avonLastLogin'),
      is_email_verified: localStorage.getItem('is_email_verified'),
    },
  };

  function ourReducer(state, action) {
    // console.log(action);
    switch (action.type) {
      case 'login':
        return { flashMessages: state.flashMessages, loggedIn: true, user: action.data, wishlistItemCount: state.wishlistItemCount, cartItemCount: state.cartItemCount };
      case 'logout':
        return { flashMessages: state.flashMessages, loggedIn: false, user: {}, wishlistItemCount: 0, cartItemCount: state.cartItemCount };
      case 'flashMessage':
        return { flashMessages: state.flashMessages.concat(action.value), loggedIn: state.loggedIn, user: state.user, wishlistItemCount: state.wishlistItemCount, cartItemCount: state.cartItemCount };
      case 'setUser':
        return { flashMessages: state.flashMessages, loggedIn: state.loggedIn, user: action.data, wishlistItemCount: state.wishlistItemCount, cartItemCount: state.cartItemCount };
      case 'setWishlistCount':
        return { flashMessages: state.flashMessages, loggedIn: state.loggedIn, user: state.user, wishlistItemCount: action.value, cartItemCount: state.cartItemCount };
      default:
        return;
    }
  }
  // const state = useContext(StateContext);
  const [state, dispatch] = useReducer(ourReducer, initialState);
  const domainName =  window.location.hostname;
  // console.log(domainName)
  const deviceids = localStorage.getItem("deviceid");
 function  generateString(length) {
    let result = ' ';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
  if(!deviceids){
    localStorage.setItem("deviceid",generateString(10));
  }
  const [deviceid,setdeviceid] = useState(deviceids?deviceids:"");
  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem('avonToken', state.user.token);
      localStorage.setItem('avonName', state.user.name);
      localStorage.setItem('avonEmail', state.user.email);
      localStorage.setItem('avonMobileNumber', state.user.mobileNumber);
      localStorage.setItem('avonProfileImage', state.user.profileImage);
      localStorage.setItem('avonLastLogin', state.user.lastLogin);
      localStorage.setItem('step', state.user.step);
      localStorage.setItem('is_email_verified', state.user.is_email_verified);
    } else {
      localStorage.removeItem('avonToken');
      localStorage.removeItem('avonName');
      localStorage.removeItem('avonEmail');
      localStorage.removeItem('avonMobileNumber');
      localStorage.removeItem('avonProfileImage');
      localStorage.removeItem('avonLastLogin');
      localStorage.removeItem('is_email_verified');
    }
  }, [state.loggedIn]);
  const getlocation = ()=>{
    Axios.get("https://ipapi.co/json/").then(({data})=>{
      setlocationdata(data)
      setcountrycity(data.country_name)
      settimezone(data.timezone)
      setgcurrency(data.currency)
      fetchData();
    })
  }
  Axios.defaults.headers.common['Authorization'] = state.user.token;
  // Axios.defaults.headers.common['timezone'] = locationdata.timezone;
  const location = useLocation();
  // console.log(location)

  return (
    <CookiesProvider>
    <GoogleOAuthProvider clientId={clientId}>
    <div className="main-wrapper">
      <div className="inner-wrapper">
        <StateContext.Provider value={{alldata, deviceid,setdeviceid,currencysymbol,setcurrencysymbol,gcurrency,setgcurrency,countrycity, setcountrycity,locationdata, setlocationdata,state, categories, testimonials, faqs, blogs, brands, homepageSetting, featuredProducts, topSellingProducts, recentlyViewedProducts, setRecenltyViewedProducts, productSuggestions, setProductSuggestions, countries, flashDeal1, flashDeal2, flashDeal3, flashDealPotrait, flashDealSuper, flashDealSuperProducts }}>
          <DispatchContext.Provider value={dispatch}>
            <FlashMessage messages={state.flashMessages} />
            { (domainName =="localhost" || domainName =="test.apnashaher.com" || domainName =="www.test.apnashaher.com") ? <Header featuredCategories={featuredCategories} websiteSetting={websiteSetting} /> : domainName !="test.apnashaher.com" ? null  : 
            location.pathname == '/seller-website' ? null : location.pathname == '/seller-about-us' ? null : location.pathname == '/seller-terms-and-condition' ? null :
            location.pathname == '/seller-blogs' ? null : location.pathname == '/seller-product-list' ? null : <Header featuredCategories={featuredCategories} websiteSetting={websiteSetting} />
            // domainName =="test.apnashaher.com" || domainName =="localhost" ? <Header featuredCategories={featuredCategories} websiteSetting={websiteSetting}  /> : null
          }
            <Routes>
          {

          }
              <Route path="/" exact element={domainName=="test.apnashaher.com" || domainName=="www.test.apnashaher.com" || domainName =="localhost" ? <HomePage isLoading={isLoading} />:<SellerWebsite isLoading={isLoading}  />} />
              <Route path="/login" exact element={<Login />} />
              <Route path="/adminlogin/:link" exact element={<AdminLogin />} />
              <Route path="/sign-up" exact element={<SignUp />} />
              <Route path="/shop" exact element={<Shop />} />
              <Route path="/products-page" exact element={<ProductsPage />} />
              <Route path="/services" exact element={<Services />} />
              <Route path="/flash-deals" exact element={<FlashDeal />} />
              <Route path="/flash-deals/:slug" exact element={<FlashDeal />} />
              <Route path="/wishlist" exact element={<Wishlist />} />
              <Route path="/stock-clearance" exact element={<StockClearence />} />
              <Route path="/stock-clearance/:slug" exact element={<StockClearence />} />
              <Route path="/service-categorys/:slug" exact element={<DigitalServices />} />
              <Route path="/buy-sell" exact element={<BuySell />} />
              <Route path="/car-from" exact element={<CarForm />} />
              <Route path="/seller-dashboard" exact element={<SellerDashboard />} />
              <Route path="/user-dashboard" exact element={<UserDashboard />} />
              

              <Route path="/products-comparison" exact element={<ProductComparison isLoading={isLoading} />} />
              <Route path="/property-house-sale" exact element={<ForSaleHouseApartments />} />
              <Route path="/property-house-rent" exact element={<ForRentHouseApartments />} />
              <Route path="/property-shop-rent" exact element={<ForRentShopOffice />} />
              <Route path="/property-shop-sale" exact element={<ForSaleShopOffice/>} />
              <Route path="/land-plots" exact element={<LandPlots />} />
              <Route path="/guest-house" exact element={<GuestHouses />} />
              <Route path="/mobile-form" exact element={<MobileForm />} />
              <Route path="/tablet-form" exact element={<Tablets />} />
              <Route path="/accesseries-form" exact element={<Accessories />} />
              <Route path="/export-import" exact element={<ExportImport />} />
              <Route path="/seller-details/:slug" exact element={<SellerDetailsPage />} />
              <Route path="/subscription" exact  element={state.loggedIn && state.user.step === "complete" ? <SellerSubscription /> : <Navigate to="/" replace />} />
              <Route path="/cancel-subscription" exact  element={state.loggedIn && state.user.step === "complete" ? <CancelSubscription /> : <Navigate to="/" replace />} />
              <Route path="/professional-services" exact element={<ProfServices />} />
              <Route path="/category/:slug" exact element={<Category />} />
              <Route path="/brand/:slug" exact element={<BrandProduct />} />
              <Route path="/product/:slug" exact element={<ProductDetails />} />
              <Route path="/new-arrival" exact element={<NewArrivals />} />
              <Route path="/new-arrival/:slug" exact element={<NewArrivals />} />
              <Route path="/contact-us" exact element={<Contact websiteSetting={websiteSetting} />} />
              <Route path="/faq" exact element={<Faq isLoading={isLoading} />} />
              <Route path="/about-us" exact element={<About isLoading={isLoading} aboutUs={aboutUs} />} />
              <Route path="/compare-products" exact element={<CompareProducts isLoading={isLoading} />} />
              <Route path="/gallery" exact element={<Gallery galleries={galleries} isLoading={isLoading} />} />
              <Route path="/team" exact element={<Team teams={teams} isLoading={isLoading} />} />
              <Route path="/blogs" exact element={<Blog />} />
              <Route path="/blog/:slug" exact element={<BlogDetail />} />
              <Route path="/page/:slug" exact element={<Privacy />} />
              <Route path="/deal/:slug" exact element={<Deal />} />
              <Route path="/thankyou" exact element={<ThankYou />} />
              <Route path="/dashboard" exact element={state.loggedIn && state.user.step === "complete" ? <Dashboard /> : <Navigate to="/" replace />} />
              <Route path="/my-account" exact element={state.loggedIn && state.user.step === "complete" ? <MyAccount /> : <Navigate to="/" replace />} />
              <Route path="/vendor-account" exact element={state.loggedIn && state.user.step === "complete" ? <VendorAccount /> : <Navigate to="/" replace />} />
              <Route path="/setting" exact element={state.loggedIn && state.user.step === "complete" ? <Setting /> : <Navigate to="/" replace />} />
              <Route path="/vendor-setting" exact element={state.loggedIn && state.user.step === "complete" ? <VendorAccountSetting /> : <Navigate to="/" replace />} />
              <Route path="/live-chat" exact element={state.loggedIn && state.user.step === "complete" ? <LiveChat /> : <Navigate to="/" replace />} />
              <Route path="/help-support" exact element={state.loggedIn && state.user.step === "complete" ? <HelpSupport /> : <Navigate to="/" replace />} />
              <Route path="/my-orders" exact element={state.loggedIn && state.user.step === "complete" ? <Orders /> : <Navigate to="/" replace />} />
              <Route path="/my-favourite" exact element={state.loggedIn && state.user.step === "complete" ? <MyFavourite /> : <Navigate to="/" replace />} />
              <Route path="/change-password" exact element={state.loggedIn && state.user.step === "complete" ? <ChangePassword /> : <Navigate to="/" replace />} />
              <Route path="/address-book" exact element={state.loggedIn && state.user.step === "complete" ? <AddressBook /> : <Navigate to="/" replace />} />
              <Route path="/vendor-dashboard" exact element={state.loggedIn && state.user.step === "complete" ? <VendorDashboard /> : <Navigate to="/" replace />} />
              <Route path="/purchase-history" exact element={state.loggedIn && state.user.step === "complete" ? <VendorPurchaseHistory /> : <Navigate to="/" replace />} />
              <Route path="/all-query" exact element={state.loggedIn && state.user.step === "complete" ? <VendorAllQuery /> : <Navigate to="/" replace />} />
              <Route path="/product-download" exact element={state.loggedIn && state.user.step === "complete" ? <ProductDownload /> : <Navigate to="/" replace />} />
              <Route path="/wishlist" exact element={state.loggedIn && state.user.step === "complete" ? <ProductWishlist /> : <Navigate to="/" replace />} />
              <Route path="/vendor-products" exact element={state.loggedIn && state.user.step === "complete"  ? <VendorProducts /> : <Navigate to="/" replace />} />
              <Route path="/bulk-upload" exact element={state.loggedIn && state.user.step === "complete" ? <ProductBulkUpload /> : <Navigate to="/" replace />} />
              <Route path="/vendor-orders" exact element={state.loggedIn && state.user.step === "complete" ? <VendorOrders /> : <Navigate to="/" replace />} />
              <Route path="/product-review" exact element={state.loggedIn && state.user.step === "complete" ? <ProductReview /> : <Navigate to="/" replace />} />
              <Route path="/shop-setting" exact element={state.loggedIn && state.user.step === "complete" ? <ShopSetting /> : <Navigate to="/" replace />} />
              <Route path="/payment-history" exact element={state.loggedIn && state.user.step === "complete" ? <PaymentHistory /> : <Navigate to="/" replace />} />
              <Route path="/money-withdraw" exact element={state.loggedIn && state.user.step === "complete" ? <MoneyWithdraw /> : <Navigate to="/" replace />} />
              <Route path="/packages" exact element={state.loggedIn && state.user.step === "complete" ? <VendorPackage /> : <Navigate to="/" replace />} />
              <Route path="/seller-packages" exact element={<VendorPackage />} />
              <Route path="/conversations" exact element={state.loggedIn && state.user.step === "complete" ? <Conversations /> : <Navigate to="/" replace />} />
              <Route path="/wallet" exact element={state.loggedIn && state.user.step === "complete" ? <Wallet /> : <Navigate to="/" replace />} />
              <Route path="/support-ticket" exact element={state.loggedIn && state.user.step === "complete" ? <SupportTicket /> : <Navigate to="/" replace />} />
              <Route path="/manage-profile" exact element={state.loggedIn && state.user.step === "complete" ? <ManageProfile /> : <Navigate to="/" replace />} />
              <Route path="/forgot-password" exact element={<ForgotPassword />} />
              <Route path="/vendor-checkout" exact element={state.loggedIn && state.user.step === "complete" ? <VendorCheckout /> : <Navigate to="/" replace />} />
              <Route path="/my-enquiry" exact element={state.loggedIn && state.user.step === "complete" ? <MyEnquiry /> : <Navigate to="/" replace />} />
              <Route path="/reset-password/:slug" exact element={<ResetPassword />} />
              <Route path="/add-new-product" exact element={<AddProductPage />} />
              <Route path="/edit-product/:link" exact element={<EditVendorProduct />} />
              <Route path="/LoginOTP" exact element={<LoginOTP />} />
              <Route path="/GetOTP" exact element={<GetOTP />} />
              <Route path="/LoginPassword" exact element={<LoginPassword />} />
              <Route path="/Cart" exact element={<Cart />} />
              <Route path="/Checkout" exact element={<Checkout />} />
              <Route path="/Success" exact element={<Success />} />
              <Route path="/TrackOrder" exact element={<TrackOrder />} />
              <Route path="/Features" exact element={<Features />} />
              <Route path="/Pricing" exact element={<Pricing />} />
              <Route path="/site-map" exact element={<SiteMap />} />
              <Route path="/Manufacturer" exact element={<Manufacturer />} />
              <Route path="/business-profile" exact element={<BusinessProfile />} />
              <Route path="/homepage-widget" exact element={<VendorHomePageWidget />} />
              <Route path="/our-feature" exact element={<VendorOurFeature />} />
              <Route path="/tax-and-bank-info" exact element={<VendorTax />} />
              <Route path="/store-setup" exact element={<VendorStoreSetup />} />
              <Route path="/sliders-and-banners" exact element={<VendorSliders />} />
              <Route path="/pickup-address" exact element={<VendorPickupAddress />} />
              <Route path="/vendor-about" exact element={<VendorAbout />} />
              <Route path="/vendor-Faqs" exact element={<VendorFAQ />} />
              <Route path="/vendor-blogs" exact element={<VendorBlogs />} />
              <Route path="/startup" exact element={<Startup />} />
              <Route path="/categorys/:slug" exact element={<CategoryPage />} />
              <Route path = "/seller-faq" exact element = {<Faqsellerbuyer/>} />
              <Route path = "/customer-faq" exact element = {<Faqsellerbuyer/>} />
              <Route path="/brand-and-sponsors" exact element={<VendorBrandSponser />} />
              <Route path="/other-pages" exact element={<VendorOtherPages />} />
              <Route path="/seller-benefits" exact element={<Sellerbenefites />} />
              <Route path="/advertise-with-us" exact element={<Advertisewithus />} />
              <Route path="/directory" exact element={<Directory />} />
              <Route path="/post-buy-requirement" exact element={<PostbuyRequirement />} />
              <Route path="/feedback" exact element={<Feedback />} />
              <Route path="/report-abuse" exact element={<Reportabuse />} />
              <Route path="/open-startup" exact element={<OpenStartup />} />
              <Route path="/manage-startup" exact element={<ManageStartupVendor />} />
              <Route path="/manage-startup/:slug" exact element={<EditStartupVendor />} />
              <Route path="/startup-form" exact element={<StartupForm/>} />
              <Route path="/vendor-review-feedback" exact element={<VendorReviewFeedback/>} />
              <Route path="/manage-quote" exact element={<ManageQuote/>} />
              <Route path="/manage-edit-request" exact element={<ManageEditRequest/>} />
              <Route path="/build-my-startup" exact element={<BuildmyStartup/>} />
              <Route path="/seller-detail/:slug" exact element={<SellerDetails/>} />
              <Route path="/startup-detail/:slug" exact element={<StartupDetail/>} />
              <Route path="/manage-services" exact element={<ManageServices/>} />
              <Route path="/add-services" exact element={<AddnewService/>} />
              <Route path="/edit-services/:slug" exact element={<EditService/>} />
              <Route path="/directory-listing" exact element={<DirectoryListing/>} />
              <Route path="/directory-listing/:slug" exact element={<DirectoryListing/>} />
              <Route path="/service-listing" exact element={<ServiceListing/>} />
              <Route path="/service-category" exact element={<ServiceCategory/>} />
              <Route path="/success-story" exact element={<SuccessStory/>} />
              <Route path="/blogs1" exact element={<Blogs1/>} />
              <Route path="/media-press" exact element={<MediaPress/>} />
              <Route path="/our-team" exact element={<OurTeam/>} />
              <Route path="/about-us-new" exact element={<Aboutus/>} />
              <Route path="/events" exact element={<Events/>} />
              <Route path="/event-detail" exact element={<EventDetail/>} />
              <Route path="/partners-and-investors" exact element={<Investor/>} />
              <Route path="/join-our-team" exact element={<JoinOurTeam/>} />
              <Route path="/job-description" exact element={<JobDescription/>} />
              <Route path="/new-leads" exact element={<NewLeads/>} />
              <Route path="/digital-marketing-history" exact element={<DigitalMarketingHistory/>} />
              <Route path="/social-media" exact element={<SocailMedia/>} />
              <Route path="/lead-purchage-history" exact element={<LeadPurchageHistory/>} />
              <Route path="/followup-history" exact element={<FollowupHistory/>} />
              <Route path="/graphic" exact element={<Graphic/>} />
              
              <Route path="/seller-website" exact element={<SellerWebsite />} />
              <Route path="/seller/about-us" exact element={<SellerAboutPage />} />
              <Route path="/seller-terms-and-condition" exact element={<SellerTermsCondition />} />
              <Route path="/seller/blogs" exact element={<SellerBlog />} />
              <Route path="/seller/contact-us" exact element={<SellerContactUs />} />
              <Route path="/seller/product-list" exact element={<SellerProductList />} />
              <Route path="/seller-blog/:slug" exact element={<SellerBlogDetail />} />
              <Route path="/vendor-inquiries" exact element={<VendorInquiry />} />
              <Route path="/vendor-services-enquiry" exact element={<VendorServiceEnquiry />} />
              <Route path="/seller/:link" exact element={<DynamicPage />} />
              <Route path="*" element={<p>There's nothing here: 404!</p>} />
              <Route path="/vendor-add-new-blog" exact element={state.loggedIn && state.user.step === "complete" ? <VendorAddBlog /> : <Navigate to="/" replace />} />
              <Route path="/vendor-edit-blog" exact element={state.loggedIn && state.user.step === "complete" ? <VendorEditBlog /> : <Navigate to="/" replace />} />
              <Route path="/vendor-blog/:slug" exact element={state.loggedIn && state.user.step === "complete" ? <VendorBlogDetails /> : <Navigate to="/" replace />} />
              <Route path="/vendor-edit-page" exact element={state.loggedIn && state.user.step === "complete" ? <VendorEditPages /> : <Navigate to="/" replace />} />
              <Route path="/vendor-add-page" exact element={state.loggedIn && state.user.step === "complete" ? <VendorAddPage /> : <Navigate to="/" replace />} />
              <Route path="/vendor-view-page" exact element={state.loggedIn && state.user.step === "complete" ? <VendorViewPages /> : <Navigate to="/" replace />} />
              <Route path="/vendor-view-page" exact element={state.loggedIn && state.user.step === "complete" ? <VendorViewPages /> : <Navigate to="/" replace />} />
              <Route path="/vendor-contact-us" exact element={state.loggedIn && state.user.step === "complete" ? <VendorContactUs /> : <Navigate to="/" replace />} />
              <Route path="/vendor-product-analytics" exact element={state.loggedIn && state.user.step === "complete" ? <VendorProductAnalytic /> : <Navigate to="/" replace />} />
              <Route path="/service-detail/:slug" exact element={<ServiceDetails />} />
              <Route path="/add-new-service" exact element={<AddNewService />} />
              <Route path="/vendor-services" exact element={<VendorServices />} />
              <Route path="/vendor-bulk-upload-services" exact element={<VendorBulkUploadServices />} />
              <Route path="/vendor-services-download" exact element={<VendorServices />} />
              <Route path="/vendor-services-analytics" exact element={<VendorServiceAnalytics />} />
              <Route path="/vendor-live-chat" exact element={<VendorLiveChat />} />
              <Route path="/vendor-learn" exact element={<VendorLearn />} />
              <Route path="/vendor-digital-marketing" exact element={<VendorDigitalMarketing />} />
              <Route path="/vendor-settings" exact element={<VendorSetting />} />
              <Route path="/verify-email/:slug" element={<VerifyEmail />} />
            </Routes>
            {(domainName =="localhost" || domainName =="test.apnashaher.com" || domainName =="www.test.apnashaher.com") ? <FooterPage websiteSetting={websiteSetting} /> : domainName !="test.apnashaher.com" ? null  :location.pathname == '/seller-website' ? null : location.pathname == '/seller-about-us' ? null : location.pathname == '/seller-terms-and-condition' ? null :
             location.pathname == '/seller-blogs' ? null : location.pathname == '/seller-product-list' ? null : <FooterPage websiteSetting={websiteSetting} /> }
          </DispatchContext.Provider>
        </StateContext.Provider>
      </div>
    </div>
    </GoogleOAuthProvider>
    </CookiesProvider>
  );
};

export default App