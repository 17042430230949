import React, { useEffect, useState } from "react";
import "../../style/ManageServices.css";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../../config";
import Page from "../Page";
import { NavLink, useNavigate } from "react-router-dom";
import VendorDashboardSideMenu from "../../components/VendorDashboardSideMenu";
import { ToastContainer, toast } from "react-toastify";
export const AddnewService = () => {
  const [categoriesservices, setCategoriesservices] = useState([]);
  const [subcategorysservices, setsubcategorysservices] = useState([]);
  const [service_imagep, setservice_imagep] = useState("");
  const [customerror, setcustomerror] = useState([]);
  const [customerrorf, setcustomerrorf] = useState([]);
  const [gl_img1p, setgl_img1p] = useState("");
  const [gl_img2p, setgl_img2p] = useState("");
  const [gl_img3p, setgl_img3p] = useState("");
  const [gl_img4p, setgl_img4p] = useState("");
  const [gl_img5p, setgl_img5p] = useState("");
  const navigate = useNavigate();
  const [data, setdata] = useState({
    category_id: "",
    subcategory_id: "",
    service_name: "",
    status: "",
    slug: "",
    service_title: "",
    about_statup: "",
    short_descriptions: "",
    service_image: "",
    gl_img1: "",
    gl_img2: "",
    gl_img3: "",
    gl_img4: "",
    gl_img5: "",
    priority_delivery: "no",
  });
  const ref1 = React.createRef();
  const ref5 = React.createRef();
  const ref2 = Array(3)
    .fill(0)
    .map((_, i) => React.createRef(null));
  const ref3 = Array(3)
    .fill(0)
    .map((_, i) => React.createRef(null));
  const [inputpackage, setInputpackage] = useState([
    {
      package_name: "",
      package_mrp: "",
      package_discount: 0,
      package_price: "",
      status: "",
      offer: "",
      delivery_time: "",
      one_day_delivery_charge: "",
      no_of_revision_allowed: "",
      no_of_word: "",
      short_description: "",
      whats_includeed: "",
      package_id: 0,
    },
  ]);
  const [inputfeature, setinputfeature] = useState([
    { feature_name: "", basic: "", standard: "", premium: "", feature_id: 0 },
  ]);
  const [error, seterror] = useState([]);
  var token = localStorage.getItem("avonToken");
  useEffect(() => {
    FetchCategoriesservices();
  }, []);

  const FetchCategoriesservices = async () => {
    await axios.get(BASE_URL + "/allcategoriesservices").then(({ data }) => {
      setCategoriesservices(data.data);
    });
  };
  const FetchsubCategoriesservices = async (id) => {
    await axios
      .get(BASE_URL + "/getsubcategoriesservices/" + id)
      .then(({ data }) => {
        setsubcategorysservices(data.data);
      });
  };
  const handledata = (e) => {
    const { name, value, files, checked } = e.target;
    if (files && files[0]) {
      setdata((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
      if (name === "service_image") {
        setservice_imagep(URL.createObjectURL(files[0]));
      }
      if (name === "gl_img1") {
        setgl_img1p(URL.createObjectURL(files[0]));
      }
      if (name === "gl_img2") {
        setgl_img2p(URL.createObjectURL(files[0]));
      }
      if (name === "gl_img3") {
        setgl_img3p(URL.createObjectURL(files[0]));
      }
      if (name === "gl_img4") {
        setgl_img4p(URL.createObjectURL(files[0]));
      }
      if (name === "gl_img5") {
        setgl_img5p(URL.createObjectURL(files[0]));
      }
    } else {
      setdata((prevData) => ({
        ...prevData,
        [name]: name == "priority_delivery" ? (checked ? "yes" : "no") : value,
      }));
      if (name === "service_name") {
        setdata((prevData) => ({
          ...prevData,
          ["slug"]: value
            .toLowerCase()
            .trim()
            .replace(/[^\w\s-]/g, "")
            .replace(/[\s_-]+/g, "-")
            .replace(/^-+|-+$/g, ""),
        }));
      }
    }
  };

  const handlePackageChange = (event, index) => {
    const { name, value } = event.target;
    console.log(name, value)
    const newInputFields = [...inputpackage];
    if (name == "package_mrp") {
      newInputFields[index]["package_mrp"] = value;
      newInputFields[index]["package_price"] =
        value - (value * newInputFields[index]["package_discount"]) / 100;
    } else if (name == "package_discount") {
      if (value <= 100) {
        newInputFields[index]["package_discount"] = value;
        newInputFields[index]["package_price"] =
          newInputFields[index]["package_mrp"] -
          (newInputFields[index]["package_mrp"] * value) / 100;
      } else {
        toast.error("Discount not be greater than 100%");
      }
    } else {
      newInputFields[index][name] = value;
    }

    setInputpackage(newInputFields);
  };
  const handleFeatureChange = (event, index) => {
    const { name, value, checked } = event.target;
    const newInputFields = [...inputfeature];
    newInputFields[index][name] =
      name == "basic" || name == "standard" || name == "premium"
        ? checked
          ? "yes"
          : "no"
        : value;
    setinputfeature(newInputFields);
  };

  const handleAddPackage = () => {
    if (inputpackage.length < 3) {
      setInputpackage([
        ...inputpackage,
        {
          package_name: "",
          package_mrp: "",
          package_discount: "",
          package_price: "",
          status: "",
          offer: "",
          delivery_time: "",
          no_of_revision_allowed: "",
          no_of_word: "",
          one_day_delivery_charge: "",
          short_description: "",
          whats_includeed: "",
          package_id: 0,
        },
      ]);
    } else {
      alert("You Can Maximum 3 packages Add");
    }
  };

  const handleRemovePackage = (index) => {
    const newInputFields = [...inputpackage];
    newInputFields.splice(index, 1);
    setInputpackage(newInputFields);
  };
  const handleAddFeature = () => {
    setinputfeature([
      ...inputfeature,
      { feature_name: "", basic: "", standard: "", premium: "", feature_id: 0 },
    ]);
  };
  const validate = () => {
    const newErrors = {
    package_name: [],
    package_mrp: [],
    package_discount: [],
    package_price: [],
    status: [],
    offer: [],
    delivery_time: [],
    no_of_revision_allowed: [],
    no_of_word: [],
    one_day_delivery_charge: [],
    short_description: [],
    whats_includeed: [],
   };
   const newErrors1 = {
    feature_name:[]
   }
    let status = true;
    inputpackage.forEach((form, index) => {
      if (!form.package_name) {
        status = false;
        newErrors.package_name[index] = "The package name field is required.";
      }
      if (!form.package_mrp) {
        status = false;
        newErrors.package_mrp[index] = "The package mrp field is required.";
      }
      if (!form.package_discount) {
        status = false;
        newErrors.package_discount[index] = "The package discount field is required.";
      }
      if (!form.package_price) {
        status = false;
        newErrors.package_price[index] = "The package price field is required.";
      }
      if (!form.status) {
        status = false;
        newErrors.status[index] = "The status field is required.";
      }
      if (!form.offer) {
        status = false;
        newErrors.offer[index] = "The offer field is required.";
      }
      if (!form.delivery_time) {
        status = false;
        newErrors.delivery_time[index] = "The delivery time field is required.";
      }
      if (!form.no_of_revision_allowed) {
        status = false;
        newErrors.no_of_revision_allowed[index] = "The No.Of Revision field is required.";
      }
      if (!form.no_of_word) {
        status = false;
        newErrors.no_of_word[index] = "The No.Of word field is required.";
      }
      if (!form.one_day_delivery_charge) {
        status = false;
        newErrors.one_day_delivery_charge[index] = "The delivery charge field is required.";
      }
      if (!form.no_of_revision_allowed) {
        status = false;
        newErrors.no_of_revision_allowed[index] = "The revision field is required.";
      }
      // if (!form.short_description) {
      //   status = false;
      //   newErrors.short_description[index] = "The short description field is required.";
      // }
      // if (!form.whats_includeed) {
      //   status = false;
      //   newErrors.whats_includeed[index] = "The whats include field is required.";
      // }
    });
    inputfeature.forEach((form, index) =>{
      if (!form.feature_name) {
        status = false;
        newErrors1.feature_name[index] = "The feature name field is required.";
      }
    });
    setcustomerrorf(newErrors1)
    setcustomerror(newErrors);
    return status;
  };
  const handleRemoveFeature = (index) => {
    const newInputFields = [...inputfeature];
    newInputFields.splice(index, 1);
    setinputfeature(newInputFields);
  };
  const handleSubmit = () => {
    seterror("");
    if (validate()) {
      const formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
        if (key === "about_statup") {
          const view1 = ref1.current.view;
          var aboutUsContent = EditorUtils.getHtml(view1.state);
          formData.append("about_statup", aboutUsContent);
        }
        if (key === "short_descriptions") {
          const view5 = ref5.current.view;
          var shortdesc = EditorUtils.getHtml(view5.state);
          formData.append("short_descriptions", shortdesc);
        }
      });

      {
        inputpackage &&
          inputpackage.map((item, i) => {
            formData.append("package_name[]", item.package_name);
            formData.append("package_mrp[]", item.package_mrp);
            formData.append("package_status[]", item.status);
            formData.append("package_discount[]", item.package_discount);
            formData.append("package_price[]", item.package_price);
            formData.append("offer[]", item.offer);
            formData.append("delivery_time[]", item.delivery_time);
            formData.append(
              "no_of_revision_allowed[]",
              item.no_of_revision_allowed
            );
            formData.append("no_of_word[]", item.no_of_word);
            formData.append(
              "one_day_delivery_charge[]",
              item.one_day_delivery_charge
            );

            var viewab = ref2[i].current.view;
            var about = EditorUtils.getHtml(viewab.state);
            formData.append("short_description[]", about);
            var viewincl = ref3[i].current.view;
            var include = EditorUtils.getHtml(viewincl.state);
            formData.append("whats_includeed[]", include);
            formData.append("package_id[]", item.package_id);
          });
      }
      {
        inputfeature &&
          inputfeature.map((item, i) => {
            formData.append("feature_name[]", item.feature_name);
            formData.append("basic[]", item.basic);
            formData.append("standard[]", item.standard);
            formData.append("premium[]", item.premium);
            formData.append("feature_id[]", item.feature_id);
          });
      }

      axios
        .post(BASE_URL + `/addsellerservices`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((res) => {
          if (res.status == "201") {
            toast.success("Added Successfully!");
            navigate("/manage-services");
          }
        })
        .catch(({ response }) => {
          seterror(response.data.errors);
        });
    }
  };
  // Editor textbox data
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;

  return (
    <>
      <Page title="Add Services">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Manage Services</li>
            </ul>
            {/* <div className="col-md-12">
          <Button className="dashboard-btn" href="/dashboard" variant="primary">
              Customer Dashboard
            </Button>
            </div> */}
          </div>
        </section>
        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <div className="add-service">
                  {/* Section1 */}
                  <div className="add-sec1">
                    <form className="my-4 addserviceform">
                      {/* part1---- */}
                      <div className="fpart1 bg-white px-3 py-3 rounded">
                        <div className="div">
                          <h4>Add New Service</h4>
                        </div>

                        <div className="row mb-2">
                          <div className="col-md-4">
                            <label htmlFor="">Select Category</label>
                            <select
                              className="form-control"
                              aria-label="Default select example"
                              name="category_id"
                              onChange={(e) => {
                                handledata(e);
                                FetchsubCategoriesservices(e.target.value);
                              }}
                            >
                              <option value="">Select Category</option>
                              {categoriesservices &&
                                categoriesservices.map((item, i) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </select>
                            {error && error.category_id && (
                              <div className="text-danger">
                                {error.category_id}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="">Sub Category</label>
                            <select
                              className="form-control"
                              name="subcategory_id"
                              onChange={handledata}
                              aria-label="Default select example"
                            >
                              <option value="">Sub Category</option>
                              {subcategorysservices &&
                                subcategorysservices.map((item, i) => (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                            </select>
                            {error && error.subcategory_id && (
                              <div className="text-danger">
                                {error.subcategory_id}
                              </div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="">Service Name</label>
                            <input
                              type="text"
                              onChange={handledata}
                              name="service_name"
                              className="form-control"
                            />
                            {error && error.service_name && (
                              <div className="text-danger">
                                {error.service_name}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-2">
                          <div className="col-md-3">
                            <label>Priority Delivery</label>
                            {/* <input type="radio" /> */}
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              style={{ fontSize: 20 }}
                              value="yes"
                              name="priority_delivery"
                              checked={
                                data.priority_delivery == "yes" ? true : false
                              }
                              onChange={handledata}
                            />
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="">Status</label>
                            <select
                              className="form-control"
                              name="status"
                              onChange={handledata}
                              aria-label="Default select example"
                            >
                              <option value="">Status</option>
                              <option value="active">Active</option>
                              <option value="block">Block</option>
                            </select>
                            {error && error.status && (
                              <div className="text-danger">{error.status}</div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="">Slug URL</label>
                            <input
                              type="text"
                              name="slug"
                              value={data?.slug}
                              onChange={handledata}
                              placeholder="Slug URL"
                              className="form-control"
                            />
                            {error && error.slug && (
                              <div className="text-danger">{error.slug}</div>
                            )}
                          </div>
                          <div className="col-md-4">
                            <label htmlFor="">Service Title </label>
                            <input
                              type="text"
                              className="form-control"
                              name="service_title"
                              onChange={handledata}
                              placeholder="Service Title"
                            />
                            {error && error.service_title && (
                              <div className="text-danger">
                                {error.service_title}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" mt-4">
                          <Form.Label>Short Description</Form.Label>
                          <Editor
                            tools={[
                              [Bold, Italic, Underline, Strikethrough],
                              [Subscript, Superscript],
                              [
                                AlignLeft,
                                AlignCenter,
                                AlignRight,
                                AlignJustify,
                              ],
                              [Undo, Redo],
                              [Indent, Outdent],
                              [OrderedList, UnorderedList],
                              FontSize,
                              FontName,
                              FormatBlock,
                            ]}
                            Style={{
                              height: "80px",
                            }}
                            ref={ref5}
                            name="short_descriptions"
                            placeholder="Short Description"
                            onChange={handledata}
                          />
                          {error && error.short_descriptions && (
                            <div className="text-danger">
                              {error.short_descriptions}
                            </div>
                          )}
                        </div>
                        <div className=" mt-4">
                          <Form.Label>About Startup</Form.Label>
                          <Editor
                            tools={[
                              [Bold, Italic, Underline, Strikethrough],
                              [Subscript, Superscript],
                              [
                                AlignLeft,
                                AlignCenter,
                                AlignRight,
                                AlignJustify,
                              ],
                              [Undo, Redo],
                              [Indent, Outdent],
                              [OrderedList, UnorderedList],
                              FontSize,
                              FontName,
                              FormatBlock,
                            ]}
                            Style={{
                              height: "80px",
                            }}
                            ref={ref1}
                            name="about_startup"
                            placeholder="About Startup"
                            onChange={handledata}
                          />
                          {error && error.about_startup && (
                            <div className="text-danger">
                              {error.about_startup}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="scat-img my-lg-4 my-3 px-3 pt-lg-5 pt-md-4 pt-3 pb-lg-5 pb-4  bg-white rounded ">
                        <h4>Service Catalogue Images</h4>
                        <div className="browse-scat-img mb-3 d-flex justify-content-between align-items-center">
                          <label htmlFor="">Service Image</label>
                          <div class="input-group rounded">
                            <span
                              class="input-group-text"
                              id="inputGroup-sizing-default"
                            >
                              Browse
                            </span>
                            <input
                              type="file"
                              name="service_image"
                              onChange={handledata}
                              class="form-control rounded"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-default"
                            />
                            {service_imagep && (
                              <img src={service_imagep} height={100} />
                            )}
                          </div>
                          {error && error.service_image && (
                            <div className="text-danger">
                              {error.service_image}
                            </div>
                          )}
                        </div>

                        <div className="sgallery d-flex justify-content-between align-items-center">
                          <label htmlFor="">Service Gallery</label>
                          <div className="add-img-div-parent d-flex justify-content-between align-items-center position-relative">
                            <div className="add-img-div">
                              <input
                                type="file"
                                name="gl_img1"
                                onChange={handledata}
                              />
                              <div className="">
                                <img
                                  src="/assets/images/plus-lteal.png"
                                  alt=""
                                />
                                <p>Add Img</p>
                              </div>
                              {gl_img1p && <img src={gl_img1p} height={100} />}
                              {error && error.gl_img1 && (
                                <div className="text-danger">
                                  {error.gl_img1}
                                </div>
                              )}
                            </div>
                            <div className="add-img-div">
                              <input
                                type="file"
                                name="gl_img2"
                                onChange={handledata}
                              />
                              <div className="">
                                <img
                                  src="/assets/images/plus-lteal.png"
                                  alt=""
                                />
                                <p>Add Img</p>
                              </div>
                              {gl_img2p && <img src={gl_img2p} height={100} />}
                              {error && error.gl_img2 && (
                                <div className="text-danger">
                                  {error.gl_img2}
                                </div>
                              )}
                            </div>
                            <div className="add-img-div">
                              <input
                                type="file"
                                name="gl_img3"
                                onChange={handledata}
                              />
                              <div className="">
                                <img
                                  src="/assets/images/plus-lteal.png"
                                  alt=""
                                />
                                <p>Add Img</p>
                              </div>
                              {gl_img3p && <img src={gl_img3p} height={100} />}
                              {error && error.gl_img3 && (
                                <div className="text-danger">
                                  {error.gl_img3}
                                </div>
                              )}
                            </div>

                            <div className="add-img-div">
                              <input
                                type="file"
                                name="gl_img4"
                                onChange={handledata}
                              />
                              <div className="">
                                <img
                                  src="/assets/images/plus-lteal.png"
                                  alt=""
                                />
                                <p>Add Img</p>
                              </div>
                              {gl_img4p && <img src={gl_img4p} height={100} />}
                              {error && error.gl_img4 && (
                                <div className="text-danger">
                                  {error.gl_img4}
                                </div>
                              )}
                            </div>
                            <div className="add-img-div">
                              <input
                                type="file"
                                name="gl_img5"
                                onChange={handledata}
                              />
                              <div className="">
                                <img
                                  src="/assets/images/plus-lteal.png"
                                  alt=""
                                />
                                <p>Add Img</p>
                              </div>
                              {gl_img5p && <img src={gl_img5p} height={100} />}
                              {error && error.gl_img5 && (
                                <div className="text-danger">
                                  {error.gl_img5}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="packages-detail">
                        <h2>Package Detail</h2>
                        <div className="add-details mt-lg-4 mt-3  px-3 py-4 bg-white rounded">
                          <h4>Add Service Type & their Details</h4>
                          {inputpackage.map((form, index) => (
                            <div key={form.id} className="form">
                              <div className="row ">
                                <div className="col-md-4">
                                  <label>Package Name </label>
                                  <input
                                    type="text"
                                    placeholder="Package Name"
                                    name="package_name"
                                    value={form?.package_name}
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                 
                                  {customerror?.package_name?.[index] && (
                                    <div className="text-danger">
                                      {customerror.package_name[index]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label> Package MRP </label>
                                  <input
                                    type="number"
                                    name="package_mrp"
                                    value={form?.package_mrp}
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                  {customerror?.package_mrp?.[index] && (
                                    <div className="text-danger">
                                      {customerror.package_mrp[index]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label> Discount(%) </label>
                                  <input
                                    type="number"
                                    name="package_discount"
                                    max="100"
                                    value={form?.package_discount}
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                  {customerror?.package_discount?.[index] && (
                                    <div className="text-danger">
                                      {customerror.package_discount[index]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label> Package Price </label>
                                  <input
                                    type="number"
                                    name="package_price"
                                    readOnly
                                    value={form?.package_price}
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                   {customerror?.package_price?.[index] && (
                                    <div className="text-danger">
                                      {customerror.package_price[index]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4">
                                  <label> Status </label>
                                  <select
                                    name="status"
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="active">Active</option>
                                    <option value="block">Block</option>
                                  </select>
                                  {customerror?.status?.[index] && (
                                    <div className="text-danger">
                                      {customerror.status[index]}
                                    </div>
                                  )}
                                </div>

                                <div className="col-md-4">
                                  <label> Offer </label>

                                  <input
                                    type="text"
                                    placeholder="Write Offer Here.."
                                    name="offer"
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                  {customerror?.offer?.[index] && (
                                    <div className="text-danger">
                                      {customerror.offer[index]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-4">
                                  <label> Delivered in (Days) </label>
                                  <input
                                    type="text"
                                    name="delivery_time"
                                    placeholder="4 Days"
                                    className="form-control"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                   {customerror?.delivery_time?.[index] && (
                                    <div className="text-danger">
                                      {customerror.delivery_time[index]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label> Priority Delivery Charge </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    name="one_day_delivery_charge"
                                    
                                    placeholder="Priority Delivery Charge (Number Only)"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                  {customerror?.one_day_delivery_charge?.[index] && (
                                    <div className="text-danger">
                                      {customerror.one_day_delivery_charge[index]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label>No.Of Revision Allowed </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="No.Of Revision Allowed (Number Only)"
                                    name="no_of_revision_allowed"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                  {customerror?.no_of_revision_allowed?.[index] && (
                                    <div className="text-danger">
                                      {customerror.no_of_revision_allowed[index]}
                                    </div>
                                  )}
                                </div>
                                <div className="col-md-4">
                                  <label> No. Of Word </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="No. Of Word (Number Only)"
                                    name="no_of_word"
                                    onChange={(e) =>
                                      handlePackageChange(e, index)
                                    }
                                  />
                                  {customerror?.no_of_word?.[index] && (
                                    <div className="text-danger">
                                      {customerror.no_of_word[index]}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="short-disc">
                                <Form.Label>Short Description</Form.Label>
                                <Editor
                                  tools={[
                                    [Bold, Italic, Underline, Strikethrough],
                                    [Subscript, Superscript],
                                    [
                                      AlignLeft,
                                      AlignCenter,
                                      AlignRight,
                                      AlignJustify,
                                    ],
                                    [Undo, Redo],
                                    [Indent, Outdent],
                                    [OrderedList, UnorderedList],
                                    FontSize,
                                    FontName,
                                    FormatBlock,
                                  ]}
                                  Style={{
                                    height: "80px",
                                  }}
                                  ref={ref2[index]}
                                  name="short_description"
                                  placeholder="Short Description"
                                  onChange={(e) =>
                                    handlePackageChange(e, index)
                                  }
                                />
                                {customerror?.short_description?.[index] && (
                                    <div className="text-danger">
                                      {customerror.short_description[index]}
                                    </div>
                                  )}
                              </div>

                              <div className="what-include">
                                <Form.Label>What's Included</Form.Label>
                                <Editor
                                  tools={[
                                    [Bold, Italic, Underline, Strikethrough],
                                    [Subscript, Superscript],
                                    [
                                      AlignLeft,
                                      AlignCenter,
                                      AlignRight,
                                      AlignJustify,
                                    ],
                                    [Undo, Redo],
                                    [Indent, Outdent],
                                    [OrderedList, UnorderedList],
                                    FontSize,
                                    FontName,
                                    FormatBlock,
                                  ]}
                                  Style={{
                                    Height: "80px",
                                  }}
                                  name="whats_includeed"
                                  ref={ref3[index]}
                                  placeholder="What's Included"
                                  onChange={(e) =>
                                    handlePackageChange(e, index)
                                  }
                                />
                                {customerror?.whats_includeed?.[index] && (
                                    <div className="text-danger">
                                      {customerror.whats_includeed[index]}
                                    </div>
                                  )}
                              </div>
                              {inputpackage.length - 1 === index && (
                                <>
                                  {inputpackage.length < 3 && (
                                    <button
                                      type="button"
                                      onClick={handleAddPackage}
                                    >
                                      Add more package{" "}
                                      <img
                                        src="/assets/images/plus-lteal.png"
                                        alt=""
                                      />{" "}
                                    </button>
                                  )}

                                  {inputpackage.length > 1 && (
                                    <button
                                      type="button"
                                      onClick={handleRemovePackage}
                                    >
                                      Delete{" "}
                                      <span>
                                        <i className="fa fa-trash text-danger"></i>
                                      </span>{" "}
                                    </button>
                                  )}
                                </>
                              )}

                              {inputpackage.length != 1 &&
                                inputpackage.length - 1 !== index && (
                                  <button
                                    type="button"
                                    onClick={handleRemovePackage}
                                  >
                                    Delete{" "}
                                    <span>
                                      <i className="fa fa-trash text-danger"></i>
                                    </span>{" "}
                                  </button>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Features */}
                      {inputfeature &&
                        inputfeature.map((item, i) => (
                          <div className="features flex-wrap gap-2 px-3 py-4 rounded bg-white my-4 d-flex justify-content-between align-items-center ">
                            <div className="d-flex flex-column w-25">
                              <label>Features</label>
                              <input
                                type="text"
                                name="feature_name"
                                className="feature-inp w-100"
                                onChange={(e) => handleFeatureChange(e, i)}
                                placeholder="Feature Name"
                              />
                              {customerrorf?.feature_name?.[i] && (
                                    <div className="text-danger">
                                      {customerrorf.feature_name[i]}
                                    </div>
                                  )}
                            </div>
                            {inputpackage.length >= 1 && (
                              <div className="basic d-flex flex-column  form-check form-switch">
                                <label>Basic</label>
                                {/* <input type="radio" /> */}
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="yes"
                                  role="switch"
                                  name="basic"
                                  onChange={(e) => handleFeatureChange(e, i)}
                                  id="flexSwitchCheckChecked"
                                />
                              </div>
                            )}
                            {inputpackage.length >= 2 && (
                              <div className="standard d-flex flex-column form-check form-switch">
                                <label>Standard</label>
                                {/* <input type="radio" /> */}
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name="standard"
                                  value="yes"
                                  onChange={(e) => handleFeatureChange(e, i)}
                                  role="switch"
                                  id="flexSwitchCheckChecked"
                                />
                              </div>
                            )}
                            {inputpackage.length >= 3 && (
                              <div className="basic d-flex flex-column form-check form-switch">
                                <label>Premium</label>
                                {/* <input type="radio" /> */}
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value="yes"
                                  name="premium"
                                  onChange={(e) => handleFeatureChange(e, i)}
                                  role="switch"
                                  id="flexSwitchCheckChecked"
                                />
                              </div>
                            )}

                            <div className="">
                              {inputfeature.length - 1 === i && (
                                <>
                                  <button
                                    type="button"
                                    onClick={handleAddFeature}
                                  >
                                    Add New{" "}
                                    <img
                                      src="/assets/images/plus-lteal.png"
                                      alt=""
                                    />{" "}
                                  </button>
                                  {inputfeature.length > 1 && (
                                    <button
                                      type="button"
                                      onClick={handleRemoveFeature}
                                    >
                                      Delete{" "}
                                      <span>
                                        <i className="fa fa-trash text-danger"></i>
                                      </span>{" "}
                                    </button>
                                  )}
                                </>
                              )}
                              {inputfeature.length != 1 &&
                                inputfeature.length - 1 !== i && (
                                  <button
                                    type="button"
                                    onClick={handleRemoveFeature}
                                  >
                                    Delete{" "}
                                    <span>
                                      <i className="fa fa-trash text-danger"></i>
                                    </span>{" "}
                                  </button>
                                )}
                            </div>
                          </div>
                        ))}

                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="px-4 py-2 save-sd-btn rounded"
                        style={{ backgroundColor: "#FFB800" }}
                      >
                        Save
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </Page>
    </>
  );
};
