import React from 'react';
import { Button } from 'react-bootstrap';
import ReactStars from 'react-stars';

export default function SellerProductServices({data}) {
    const ratingChanged = (newRating) => {
        console.log(newRating)
    }
    return (
        <div className='row ' style={{ marginLeft: 0, marginRight: 0 }}>
            {/* <div className='seller-product-heading'>Product and Services</div> */}
            {data.map((item,i) => (
                <div className='col-md-3 center-align mt-4 mb-4' key={i}>
                    <div className='card seller-product-width'>
                        <img src={item.thumbnail} />
                        <div style={{ padding: 10 , paddingLeft:15, paddingRight:15}}>
                            <h4 style={{textAlign:'left'}} title={item.name}>{item.name.length > 20 ? item.name.slice(0,16)+"....": item.name}</h4>
                            <div className="rating-section">
                                <p>{item.rating}</p>
                                <ReactStars
                                    count={5}
                                    onChange={ratingChanged}
                                    size={24}
                                    color2={'#ffd700'}
                                    className="rating-stars"
                                />
                            
                            </div>
                            <div style={{fontSize:22, textAlign:"left"}}> ${item.base_discounted_price} <span style={{marginLeft:15, color:'gray', textDecoration:'line-through'}}>${item.base_price}    </span>
                                    </div>
                                    <Button className='mt-2' style={{width:'100%'}}>Send Enquiry</Button>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}