import React from "react";

const ProductsAndServices = (props) => {
    console.log(props, "hello products");
    return (
        <>
            <div className="container seller-second-section "  style={{marginTop: "30px"}}>
                {/* <h2>{props?.homepagewidget?.widget2}</h2> */}
                <div className="row">
                    {props.product.map((item, index) => (
                        <div className="col-md-3 seller-product-section" onClick={() => props.handleProductDetails(item)}>
                            <div className="digital-product-section-border">
                                <img src={item.thumbnail} height={170} />
                                <div className="paddind-1">
                                    <p>{item.name}</p>
                                    <div>
                                        <button className="product-rating-btn">{item.rating}</button>
                                        <img src="/assets/images/star-icon.png" />
                                    </div>
                                </div>
                                <div className="price-section paddind-1">
                                    <div>
                                        <h3>{item.base_discounted_price}<del>{item.base_price}</del></h3>
                                    </div>
                                </div>
                                <div className="send-enquiry-btn">
                                    <button>Send Enquire</button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className="load-more-btn">
                    <button>Load More</button>
                </div> */}
            </div>
        </>
    )
}

export default ProductsAndServices;