import React, { useState, useEffect } from "react";
import Category from "./Components/Category";
import Adds from "../products/Components/Adds";
import BannerPage from "../apna-shaher/components/Banner";
import SecondSection from "../products/Components/SecondSection";
import ThirdSection from "../products/Components/ThirdSection";
import Professional from "./Components/Professional";
import Digital from "./Components/Digital";
import Discover from "./Components/Discover";
import ProfessionalClient from "./Components/ProfessionalClient";
import ProfessionalServices from "./Components/ProfessionalServices";
import RecentView from "../products/Components/RecentView";
import ServicesAdds from "./Components/ServicesAdds";
import ServicesAdvertize from "./Components/ServicesAdvertize";
import { Itservices } from "./Components/Itservices";
import axios from "axios";
import { BASE_URL } from "../../config";
import { BannerNew } from "../apna-shaher/components/BannerNew";
import SeoServicePage from "../../seo page/SeoServicepage";
const Services = () => {
  const [pdata, setpdata] = useState([]);
  const [premdata, setpremdata] = useState([]);
  const [searchdata, setsearchdata] = useState([]);
  const [sellerdata, setsellerdata] = useState([]);
  const [servicedata, setservicedata] = useState([]);




  useEffect(() => {
    window.scrollTo(0, 0);
    getservices();
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      getsellerservicedata();
    }, 3000);
    return () => clearTimeout(timer);
  }, [])

  const getservices = () => {
    axios
      .get(BASE_URL + "/popularcategoriesservices")
      .then(({ data }) => {
        setpdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/premiumcategoriesservices")
      .then(({ data }) => {
        setpremdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/servicecategorysearch")
      .then(({ data }) => {
        setsearchdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/getcategorywithservice")
      .then(({ data }) => {
        setservicedata(data.data);


      })
      .catch(({ response }) => { });

  }
  const getsellerservicedata = () => {
    axios
      .get(BASE_URL + "/getsellerservicedata")
      .then(({ data }) => {
        setsellerdata(data);


      })
      .catch(({ response }) => { });
  }


  return (
    <>
    <SeoServicePage/>
      <div className="show-desktop-banner">
        <BannerPage data={searchdata} />
      </div>
      
      <div className="show-mobile-banner">
        <BannerNew />
      </div>
      {/* <SecondSection />
            <ThirdSection /> */}
      <Professional />

      <Digital data={pdata} heading="Popular Categories" />
      {servicedata && servicedata.map((item, i) => (
        <>
          <ServicesAdds />
          <Itservices heading={item.name} data={item} />
        </>
      ))}
      {/* <ServicesAdds />
            <Itservices heading = {"IT Services"}/>
            <Itservices heading = {"Customer Services"}/>
            <Itservices heading = {"Graphic Design and Multimedia Services"}/>
            <Discover />
            <Itservices heading = {"Media and Entertainment Services"}/>
            <Itservices heading = {"E-commerce Services"}/>
            <Itservices heading = {"Statistics Services"}/>
            <ServicesAdds />
            <Itservices heading = {"Gaming and Casino Management Services"}/> */}
      <ServicesAdvertize />
      <ProfessionalServices data={premdata} />
      <Itservices heading={"Animation Services"} />
      {/* <Discover /> */}
      {/* <Adds /> */}
      <ServicesAdds />
      <ProfessionalClient data={sellerdata} heading="Preferred Sellers" />
      {/* <Itservices heading={"Recently Viewed"} /> */}
      <RecentView />
      {/* <Category />
           <Adds />
           <Category /> */}
    </>
  )
}

export default Services;