import React, { useState,useEffect,useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../config';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import StateContext from '../context/StateContext';
import DispatchContext from '../context/DispatchContext';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const VerifyEmail = () => {
    console.log(window.location.pathname);
  let machineId = localStorage.getItem('MachineId');
  const appDispatch = useContext(DispatchContext);
  let { slug } = useParams();
  const navigate = useNavigate();
  const [urlMsg, setUrlMsg] = useState();
  const [trueCon, setTrueCon] = useState();
  const appState = useContext(StateContext);
  useEffect(()=>{
    axios.post(`${BASE_URL}/email-verification`,{'token':slug}).then((res)=>{
      if (res.data.status === true) {
        setTrueCon(res.data.message)
        if(appState?.state?.user?.token){
          appDispatch({
            type: 'setUser',
            data: {
              profileImage: appState?.state?.user?.profileImage,
              step: appState.state.user.step,
              token: appState.state.user.token,
              name: appState.state.user.name,
              email: appState.state.user.email,
              mobileNumber: appState.state.user.mobileNumber,
              lastLogin: appState.state.user.lastLogin,
              is_email_verified: 1,
            },
          });
          localStorage.setItem("is_email_verified",1)
          navigate("/dashboard",{
            state:{
              "message":res.data.message
            }
          })

        }
      }
    }).catch((error)=>{
      if (error?.response?.data?.status === false) {
        setUrlMsg(error.response.data.message)
      }
      if (error?.response?.data?.errors) {
        setUrlMsg(error.response.data.errors.token)
      }
    })
  },[])


    return (
        <>
            <div className={`col-md-12 col-xs-12 col-sm-12 center`}>
                <div className='card' >
                  <div className='ptb-100'>
                {trueCon ?
                    <div className={`verify-success-text`}>
                 
                    <h4>Your email Id is successfully verified, please <span className='verify-link-text' style={{color:"blue", cursor: "pointer"}} onClick={() => navigate('/login')}>click here</span> to login to your Dashboard</h4>
                      
                    </div> 
                    : <div className='verify-success-text'>
                      {urlMsg} <br />
                      <span className='verify-link-text' onClick={() => navigate('/login')}>click here</span> to login to your Dashboard
                      
                      </div>}
  
                      </div>
                </div>
            </div>
        </>
    )
}

export default VerifyEmail;