import React from 'react';
const TruncHtml = require('trunc-html');

const BlogDetails = (props) => {
    console.log(props, "blog data");
    return (
        <>
            <section className="about-cont-block blog_area single-post-area">
                <div className="container-fluid" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <div className="col-lg-9 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    {/* <img src="/assets/images/blog-details.jpg" /> */}
                                    <img src={props?.blogs?.image_base_url + props.blogDetailsData.image} height={200} width="100%" />
                                    {/* <img className="img-fluid" src={props?.blogs?.image_base_url + props?.blogs?.data?.image} alt="" /> */}
                                </div>
                                <div className="blog_details">
                                    <h2>{props.blogDetailsData.title}</h2>
                                    <p>{props.blogDetailsData.content}</p>
                                </div>
                            </div>
                            <div className="comments-area">
                                <h4>22 Comments</h4>

                                {/* // comments.map((comment) => <BlogComment comment={comment} key={comment.id}/> */}
                                <div className="comment-list">
                                    <div className="single-comment justify-content-between d-flex">
                                        <div className="user justify-content-between d-flex">
                                            <div className="thumb">

                                                <img src="/assets/images/t1.jpg" alt="" />
                                            </div>
                                            <div className="desc">
                                                <p className="comment">Lorem ipsum dolor sit amit </p>
                                                <div className="d-flex justify-content-between">
                                                    <div className="d-flex align-items-center">
                                                        <h5>
                                                            <a href="#">Anas Mansoori</a>
                                                        </h5>
                                                        <p className="date">22-02-2023</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="comment-form">
                                <h4>Leave a Reply</h4>
                                <form className="form-contact comment_form" action="#" id="commentForm"
                                // onSubmit={handleSubmit}
                                >
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <textarea className="form-control w-100" name="comment" id="comment" cols="30" rows="9" placeholder="Write Comment"
                                                // onChange={(e) => setComment(e.target.value)} value={comment}
                                                ></textarea>
                                                {/* <span className="text-danger">{errors.comment}</span> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control" name="name" id="name" type="text" placeholder="Name"
                                                // onChange={(e) => setName(e.target.value)} value={name} 
                                                />
                                                {/* <span className="text-danger">{errors.name}</span> */}
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <input className="form-control" name="email" id="email" type="email" placeholder="Email"
                                                // onChange={(e) => setEmail(e.target.value)} value={email} 
                                                />
                                                {/* <span className="text-danger">{errors.email}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="blog_right_sidebar">
                                <aside className="single_sidebar_widget popular_post_widget">
                                    <h3 className="widget_title">Recent Post</h3>
                                    <div className="media post_item">
                                        <img src="/assets/images/t1.jpg" alt="" />
                                        {/* <img src={blog.image} alt="post" /> */}
                                        <div className="media-body">
                                            <h3>testing</h3>
                                            {/* <Link to={`/blog/${blog.slug}`}>
                                                <h3>{parse(blog.content)}</h3>
                                            </Link> */}
                                            <p>hello testing content</p>
                                        </div>
                                    </div>
                                    {/* {isLoading ? <Loader /> : recentBlogs.map((recentBlog) => <BlogRecent blog={recentBlog} key={recentBlog.id} />)} */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogDetails;