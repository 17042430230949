import React, { useContext, useState, useEffect } from "react";
import Helmet from "react-helmet";
import Page from "../components/Page";
import BlogSection from "../components/BlogSection";
import ClientSection from "../components/ClientSection";
import BrandSection from "../components/BrandSection";
import FeaturedProductSection from "../components/FeaturedProductSection";
import RecentlyViewedProductSection from "../components/RecentlyViewedProductSection";
import CategorySection from "../components/CategorySection";
import SubCategorySection from "../components/SubCategorySection";
import ElectronicsSubCategory from "../components/ElectronicsSubCategory";
import DealSection from "../components/DealSection";
import OurFeatureSection from "../components/OurFeatureSection";
import TopSellingProductSection from "../components/TopSellingProductSection";
import TrendingBannerSection from "../components/TrendingBannerSection";
import FeaturedCategorySection from "../components/FeaturedCategorySection";
import TwoBannerSection from "../components/TwoBannerSection";
import ProductSuggestionSection from "../components/ProductSuggestionSection";
import TwoFeaturedCategorySection from "../components/TwoFeaturedCategorySection";
import BannerSection from "../components/BannerSection";
import Loader from "../components/loader/Loader.component";
import StateContext from "../context/StateContext";
import axios from "axios";
import { BASE_URL } from "../config";
// import BannerPage from "../components/products/Components/Banner";
import BannerPage from "../components/apna-shaher/components/Banner";
import SecondSection from "../components/products/Components/SecondSection";
import ThirdSection from "../components/products/Components/ThirdSection";
import HomeCategories from "../components/products/Components/HomeCategories";
import NewArrivals from "../components/products/Components/NewArrivals";
import Discover from "../components/products/Components/Discover";
import HomeInterior from "../components/products/Components/HomeInterior";
import RecentView from "../components/products/Components/RecentView";
import { BannerNew } from "../components/apna-shaher/components/BannerNew";
import SeoProductpage from "../seo page/SeoProductpage";



const Home = ({ isLoading }) => {
  const [metaData, setmetaData] = useState([]);
  const [featuredcategoryproduct, setfeaturedcategoryproduct] = useState([]);
  const [featuredcategorysubproduct, setfeaturedcategorysubproduct] = useState(
    []
  );

  const [image_base_url, setimage_base_url] = useState("");
  useEffect(() => {
    getheader();
    getfeaturedcategoryproduct();
    getfeaturedcategorysubcategoryproduct();
  }, [isLoading]);
  const getheader = () => {
    axios
      .get(BASE_URL + "/getwebsitedataall")
      .then(({ data }) => {
        setmetaData(data.data);
        setimage_base_url(data.image_base_url);
      })
      .catch(({ response }) => { });
  };
  function filterdata(type) {
    const data = metaData.filter((e) => e.type === type);
    return data[0];
  }
  const getfeaturedcategoryproduct = () => {
    axios
      .get(BASE_URL + "/featuredcategoryproduct", {
        headers: {
          "countrycity": countrycity,
          "countrycityold": locationdata.country_name
        }
      })
      .then(({ data }) => {
        setfeaturedcategoryproduct(data.data);
      })
      .catch(({ response }) => { });
  };
  const getfeaturedcategorysubcategoryproduct = () => {
    axios
      .get(BASE_URL + "/featuredcategorysubcategoryproduct", {
        headers: {
          "countrycity": countrycity,
          "countrycityold": locationdata.country_name
        }
      })
      .then(({ data }) => {
        setfeaturedcategorysubproduct(data.data);
      })
      .catch(({ response }) => { });
  };
  const {
    alldata,
    currencysymbol,
    homepageSetting,
    categories,
    topSellingProducts,
    recentlyViewedProducts,
    productSuggestions,
    countrycity,
    locationdata,
  } = useContext(StateContext);
  return (
    <Page title="Home">
      <SeoProductpage/>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {filterdata("meta_title") && filterdata("meta_title").value}
        </title>
        <link rel="canonical" href="http://mysite.com/example" />
        <meta
          name="description"
          content={
            filterdata("meta_description") &&
            filterdata("meta_description").value
          }
        />
        <meta
          name="keywords"
          content={
            filterdata("meta_keywords") && filterdata("meta_keywords").value
          }
        />
        <meta
          name="title"
          content={filterdata("meta_title") && filterdata("meta_title").value}
        />
      </Helmet>
      <section className="banner-section">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className="show-desktop-banner">
              <BannerPage />

            </div>
            <div className="show-mobile-banner">
              <BannerNew />

            </div>

          </>
        )}
      </section>
      {/* <section className="instrusction-block">
        <SecondSection /> 
      </section>
      <section className="instrusction-block">
        <ThirdSection />
      </section> */}
      <section className="instrusction-block">
        <HomeCategories />
      </section>

      <section className="instrusction-block">
        <NewArrivals />
      </section>
      {/* <section className="category-small-section">
        <CategorySection isLoading={isLoading} categories={categories} />
      </section>
      <section className="black-friday-sales-section">
        <DealSection isLoading={isLoading} />
      </section>
      <section className="new-league-section">
        {isLoading ? (
          <Loader />
        ) : (
          <TrendingBannerSection homepageSetting={homepageSetting} />
        )}
      </section> */}
      <section className="feature-product-section slider-section">
        <FeaturedProductSection currencysymbol={currencysymbol} isLoading={isLoading} />
      </section>

      <section className="electronic-cosmatic-section">
        {isLoading ? (
          <Loader />
        ) : (
          <TwoBannerSection homepageSetting={homepageSetting} />
        )}
      </section>
      <section className="instrusction-block">
        <Discover />
      </section>

      {/* {featuredcategorysubproduct &&
        featuredcategorysubproduct.map((item, i) => (
          <>
            {item.products_count >= 4 && (
              <>

                <section className="feature-product-section slider-section">
                  <SubCategorySection isLoading={isLoading} category={item} />
                </section>
              </>
            )}
          </>
        ))} */}
      <section className="feature-product-section slider-section">
        <RecentlyViewedProductSection
          isLoading={isLoading}
          recentlyViewedProducts={recentlyViewedProducts}
        />
      </section>

      {/* <section className="feature-product-section slider-section">
        <FeaturedCategorySection isLoading={isLoading} category={homepageSetting.featured_category_1} image={homepageSetting.featured_category_1_image} />
      </section> */}
      {/* <section className="feature-product-section slider-section">
        <TopSellingProductSection
          isLoading={isLoading}
          topSellingProducts={topSellingProducts}
        />
      </section> */}
      <section className="instrusction-block">
        <HomeInterior />
      </section>
      {featuredcategoryproduct &&
        featuredcategoryproduct.map((item, i) => (
          <>
            {item.products_count >= 4 && (
              <section
                className="feature-product-section slider-section"
                key={i}
              >
                <FeaturedCategorySection
                  isLoading={isLoading}
                  category={item}
                  image={item.banner}
                />
              </section>
            )}
          </>
        ))}



      <section className="home-smartphone-section feature-product-section">
        <TwoFeaturedCategorySection isLoading={isLoading} />
      </section>
      <section className="feature-product-section slider-section">
        <BrandSection isLoading={isLoading} />
      </section>
      {/* <section className=" slider-section ">
        <BlogSection isLoading={isLoading} />
      </section> */}
      <section className="feature-product-section slider-section">
        <ClientSection isLoading={isLoading} heading="Preferred Sellers" />
      </section>
      <section className="feature-product-section slider-section">
        <RecentView isLoading={isLoading} />
      </section>
    </Page>
  );
};

export default Home;
