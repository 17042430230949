import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "../../style/SellerDashboard.css"

export const Sidebar = () => {

    const[toggleSbar, setToggleSbar] = useState(true)

    return (
        <>
         

            {/*ON toggle width */}
            <div className="seller-dashboard-sidebar .show-on-hover bg-white" style={{ maxWidth:  `${toggleSbar ===true ? "360px":"80px" }`  }}>
                <div className="seller-img-div position-relative d-flex gap-4 align-items-cener rounded px-3 py-3 bg-dark text-white">
                    <div className="rounded-circle px-3 py-3 bg-white">
                        <img src="/assets/images/user-golden-img.png" alt="" />
                    </div>
                    {
                        toggleSbar && <div className="seller-name-title d-flex flex-column justify-content-center">
                        <h4>John Deo</h4>
                        <p className='mb-0 fs-6 fw-medium'>Seller</p>
                    </div>
                    }
                    
                </div>

                {/* .tab links  */}
                <div className="seller-tab-links pt-2">
                    
                    <div class="accordion accordion-flush border-0 pt-4" id="accordionFlushExample">
                    <div class="accordion-item">
                            <h2 class="accordion-header ">
                                <Link to =" ">
                                <button class="accordion-button active-accordion no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                 <span><i class="fa fa-home pe-2 fs-5 "></i> {toggleSbar && "My Profile" } </span> 
                                </button> </Link>
                                
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header  ">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <span><i class="fa fa-building pe-2 fs-5"></i>{toggleSbar && "Business Setup" } </span>
                                </button>
                            </h2> 
                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                 <ul>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span> Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span> Exapmesfsfe1</li></Link>  
                                 </ul>
                                     </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> {toggleSbar && "Products Catalogue"}  
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                <ul>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li ><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> {toggleSbar && "Website & Store Setup"} 
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    
                                <ul>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span>{toggleSbar && "All Inquiries"}
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> {toggleSbar && "Live Chat"}
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span> {toggleSbar && "Subscription"}
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> {toggleSbar && " Wallet"}
                                </button>
                            </h2>
                            <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    
                                <ul>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span>  {toggleSbar && "Edit Reequest"} 
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> {toggleSbar && "Rating & Review"}
                                </button>
                            </h2>
                            <div id="flush-collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    
                                <ul>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span>    {toggleSbar &&  "help & Support "} 
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span> {toggleSbar && "Digital Marketing"}
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                      {toggleSbar && "Setting"}
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span> {toggleSbar && "Logout"} 
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        </div>
                </div>
            </div>




               {/* <div className="seller-dashboard-sidebar bg-white" style={{ maxWidth: "360px" }}>
                <div className="seller-img-div  d-flex gap-4 align-items-cener rounded px-3 py-3 bg-dark text-white">
                    <div className="rounded-circle px-3 py-3 bg-white">
                        <img src="/assets/images/user-golden-img.png" alt="" />
                    </div>
                    <div className="seller-name-title d-flex flex-column justify-content-center">
                        <h4>John Deo</h4>
                        <p className='mb-0 fs-6 fw-medium'>Seller</p>
                    </div>
                </div>

                <div className="seller-tab-links pt-2">

                    
                    <div class="accordion accordion-flush border-0 pt-4" id="accordionFlushExample">
                    <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button active-accordion no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                                 <span><i class="fa fa-home pe-2 fs-5 "></i></span> My Profile
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                <span><i class="fa fa-building pe-2 fs-5"></i></span> Business Setup
                                </button>
                            </h2> 
                            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                 <ul>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span> Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span> Exapmesfsfe1</li></Link>  
                                 </ul>
                                     </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span>    Products Catalogue
                                </button>
                            </h2>
                            <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                <ul>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li ><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> Website & Store Setup
                                </button>
                            </h2>
                            <div id="flush-collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    
                                <ul>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li> <span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span>All Inquiries
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span> Live Chat
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span> Subscription
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span>  Wallet
                                </button>
                            </h2>
                            <div id="flush-collapseEight" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    
                                <ul>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span>   Edit Reequest 
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                                <span><i class="fa fa-home pe-2 fs-5
                                 "></i></span>Rating & Review
                                </button>
                            </h2>
                            <div id="flush-collapseTen" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div class="accordion-body">
                                    
                                <ul>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>
                                    <Link to =""> <li><span><i class="fa fa-home pe-2 fs-5 "></i></span>Exapmesfsfe1</li></Link>  
                                 </ul>
                                    </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span>    help & Support  
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span>Digital Marketing
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                      Setting
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <Link to =" ">
                                <button class="accordion-button no-arrow collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseTwelve">
                                <span><i class="fa fa-home pe-2 fs-5"></i></span>  Logout 
                              
                                </button> </Link>
                            </h2>
                           
                        </div>
                        </div>
                </div>
            </div> */}
        </>
    )
}
