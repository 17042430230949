import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from "../components/address-block/more-vertical.svg";
import AddIcon from "../components/address-block/add-circle.svg";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faL, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";

const addressDetail = [
  {
    id: "1",
    fullAddress: "My address house no 278",
    postalCode: 226780,
    city: "Lucknow",
    country: "India",
    phone: "+91 0000000000",
  },
];

const ManageProfile = ({ props }) => {
  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem("avonToken");
  const [data, setData] = useState();

  const [gstApp, setGstApp] = useState();
  const [field, setField] = useState();
  const [panImg, setPanImg] = useState("");
  const [companyimage, setcompanyimage] = useState("");
  const [companyimagefile, setcompanyimagefile] = useState("");
  const [regcerImg, setRegCertImg] = useState("");
  const [gstImg, setGstImg] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [aboutUs, setAboutUs] = useState("");
  const [slug, setSlug] = useState();
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [pinterest, setpinInterest] = useState("");
  const [blogUrl, setBlogUrl] = useState();
  const [cod, setCod] = useState("");
  const [error, setError] = useState("");
  const [sunday, setSunday] = useState(false);
  const [sundaystart, setSundayStart] = useState();
  const [monday, setMonday] = useState();
  const [mondaystart, setMondayStart] = useState();
  const [tuesday, setTuesday] = useState();
  const [tuesdaystart, setTuesdayStart] = useState();
  const [wednesday, setWednesday] = useState();
  const [wednesdaystart, setWednesdayStart] = useState();
  const [thursday, setThursday] = useState();
  const [thursdaystart, setThursdayStart] = useState();
  const [friday, setFriday] = useState();
  const [fridaystart, setFridayStart] = useState();
  const [saturday, setSaturday] = useState();
  const [saturdaystart, setSaturdayStart] = useState();
  const [sunOn, setSunOn] = useState();
  const [monOn, setMonOn] = useState();
  const [tuesOn, setTuesOn] = useState();
  const [wedOn, setWedOn] = useState();
  const [thursOn, setThursOn] = useState();
  const [friOn, setFriOn] = useState();
  const [satOn, setSatOn] = useState();
  const [sunend, setSunEnd] = useState();
  const [monend, setMonEnd] = useState();
  const [tuesend, setTuesEnd] = useState();
  const [wedend, setWedEnd] = useState();
  const [thursend, setThursEnd] = useState();
  const [friend, setFriEnd] = useState();
  const [satend, setSatEnd] = useState();
  const [body, setbody] = useState("");
  const [desc, setdesc] = useState("");
  const [domain, setdomain] = useState("");
  const [subdomain, setsubdomain] = useState("");
  const body1 = React.createRef();
  const [statusData, setStatusData] = useState("");
  const [status, setStatus] = useState(false);
  const [subdomainstatus, setsubdomainstatus] = useState(false);
  const [chip, setChip] = useState([]);
  const [edit, setEdit] = useState(false);
  const [errorImg, setErrorImg] = useState("");
  const [keywordtotal, setkeywordtotal] = useState("");
  console.log("statusData", statusData);

  useEffect(() => {
    FetchStatus();
  }, []);

  const FetchStatus = async () => {
    await axios
      .get(BASE_URL + "/get-seller-detail", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setStatusData(data);

        // if(data.data == null){
        //   navigate('/vendor-dashboard')
        // }
        // if(data.data.payment_status == 'Pending'){
        //   navigate('/manage-profile')
        //   }
        setStatus(
          statusData && statusData.data == null
            ? true
            : statusData && statusData.data.payment_status == "Pending"
              ? true
              : statusData && statusData.data.payment_status == "Active"
                ? false
                : true
        );
      });
  };

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;

  const handleSunday = (e) => {
    setField("gtc", e.target.checked);
    setSunday(!sunday);
    setSunOn(sunday == true ? "close" : "open");
  };

  console.log("statusValue", monOn);

  const handleMonday = (e) => {
    setField("gtc", e.target.checked);
    setMonday(!monday);
    setMonOn(monday == true ? "close" : "open");
  };

  const handleTuesday = (e) => {
    setField("gtc", e.target.checked);
    setTuesday(!tuesday);
    setTuesOn(tuesOn == true ? "close" : "open");
  };

  const handleWednesday = (e) => {
    setField("gtc", e.target.checked);
    setWednesday(!wednesday);
    setWedOn(wedOn == true ? "close" : "open");
  };

  const handleThursday = (e) => {
    setField("gtc", e.target.checked);
    setThursday(!thursday);
    setThursOn(thursday == true ? "close" : "open");
  };

  const handleFriday = (e) => {
    setField("gtc", e.target.checked);
    setFriday(!friday);
    setFriOn(friday == true ? "close" : "open");
  };

  const handleSaturday = (e) => {
    setField("gtc", e.target.checked);
    setSaturday(!saturday);
    setSatOn(satOn == true ? "close" : "open");
  };

  const handleChange = (e) => {
    // setField('gtc', e.target.checked)
    setGstApp(!gstApp);
  };

  const handleGST = (e) => {
    setGstImg(e.target.files[0]);
  };
  const handlePan = (e) => {
    setPanImg(e.target.files[0]);
  };
  const handleReg = (e) => {
    setRegCertImg(e.target.files[0]);
  };

  const [keyword, setKeyword] = useState([]);
  const [store, setStore] = useState();
  const [seller, setSeller] = useState();

  useEffect(() => {
    FetchProfileData();
    FetchKeywords();
  }, []);

  const FetchKeywords = async () => {
    await axios.get(BASE_URL + "/getmetakeyword").then(({ data }) => {
      setKeyword(data.data);
    });
  };

  const KeyData = keyword.map((item) => item.meta_keyword);
  const [img_Url, setImg_Url] = useState("");

  const [workinghour, setworkinghour] = useState([]);

  const FetchProfileData = async () => {
    await axios
      .get(BASE_URL + "/getstore", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.data) {
          setkeywordtotal(data.keywordtotal);
          if (!data.data.facebook) {
            setEdit(true)
          }

          setMetaTitle(data.data.meta_title);
          if (data.data.meta_keyword.length > 0) {
            setChip(data.data.meta_keyword);
          }
          setcompanyimagefile(IMAGE_BASE_URL+data.data.comp_intr_image);
          setMetaDescription(data.data.meta_description);
          setAboutUs(data.data.comp_intr ? data.data.comp_intr : "");
          setFacebook(data.data.facebook);
          setTwitter(data.data.twitter);
          setYoutube(data.data.youtube);
          setLinkedin(data.data.linkedin);
          setInstagram(data.data.instagram);
          setpinInterest(data.data.pinterest);
          setBlogUrl(data.data.blog_url);
          setworkinghour(data.workinghour);
          if (data.workinghour) {
            const s = data.workinghour.filter((e) => e.day == "sunday");
            if (s.length > 0) {
              setSunday(s[0].status == "open" ? true : false);
              setSunOn(s[0].status);
              setSundayStart(s[0].in_time);
              setSunEnd(s[0].out_time);
            }
            const m = data.workinghour.filter((e) => e.day == "monday");
            if (m.length > 0) {
              setMonday(m[0].status == "open" ? true : false);
              setMonOn(m[0].status);
              setMondayStart(m[0].in_time);
              setMonEnd(m[0].out_time);
            }
            const t = data.workinghour.filter((e) => e.day == "tuesday");
            if (t.length > 0) {
              setTuesday(t[0].status == "open" ? true : false);
              setTuesOn(t[0].status);
              setTuesdayStart(t[0].in_time);
              setTuesEnd(t[0].out_time);
            }
            const w = data.workinghour.filter((e) => e.day == "wednesday");
            if (w.length > 0) {
              setWednesday(w[0].status == "open" ? true : false);
              setWedOn(w[0].status);
              setWednesdayStart(w[0].in_time);
              setWedEnd(w[0].out_time);
            }
            const th = data.workinghour.filter((e) => e.day == "thursday");
            if (th.length > 0) {
              setThursday(th[0].status == "open" ? true : false);
              setThursOn(th[0].status);
              setThursdayStart(th[0].in_time);
              setThursEnd(th[0].out_time);
            }
            const fr = data.workinghour.filter((e) => e.day == "friday");
            if (fr.length > 0) {
              setFriday(fr[0].status == "open" ? true : false);
              setFriOn(fr[0].status);
              setFridayStart(fr[0].in_time);
              setFriEnd(fr[0].out_time);
            }
            const sa = data.workinghour.filter((e) => e.day == "saturday");
            if (sa.length > 0) {
              setSaturday(sa[0].status == "open" ? true : false);
              setSatOn(sa[0].status);
              setSaturdayStart(sa[0].in_time);
              setSatEnd(sa[0].out_time);
            }
          }
          if (data.data.domain_name) {
            if (data.data.is_domain === "yes") {
              setdomain(data.data.domain_name);
            } else {
              setsubdomain(data.data.domain_name.split(".")[0]);
            }
          } else {
            setsubdomain(data.business.business_name.replace(/ +/g, ""));
          }

          setsubdomainstatus(data.data.is_domain == "no" ? true : false);
        } else {
          setEdit(true);
        }
      });
  };

  const Handlestore = async () => {
    const view1 = body1.current.view;
    var aboutUsContent = EditorUtils.getHtml(view1.state);
    let datas = {
      meta_title: metaTitle,
      companyimage: companyimage,
      meta_keyword: chip,
      meta_description: metaDescription,
      comp_intr: aboutUsContent,
      // "slug": slug,
      facebook: facebook,
      twitter: twitter,
      youtube: youtube,
      linkedin: linkedin,
      instagram: instagram,
      pinterest: pinterest,
      blog_url: blogUrl,
      is_domain: subdomainstatus == true ? "no" : "yes",
      domain_name:
        subdomainstatus == true ? subdomain + ".aladdindirect.online" : domain,
      cod: cod,
      day: [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ],
      in_time: [
        sundaystart,
        mondaystart,
        tuesdaystart,
        wednesdaystart,
        thursdaystart,
        fridaystart,
        saturdaystart,
      ],
      out_time: [sunend, monend, tuesend, wedend, thursend, friend, satend],
      status: [sunOn, monOn, tuesOn, wedOn, thursOn, friOn, satOn],
    };
    var form_data = new FormData();

for ( var key in datas ) {
  if(key == "meta_keyword" || key == "day" || key == "in_time" || key == "out_time" || key == "status"){
    form_data.append(key, JSON.stringify(datas[key]));
  }else{
    form_data.append(key, datas[key]);
  }
    
}
    await axios
      .post(BASE_URL + "/makestore", form_data, {
        headers: {
          // 'content-type': 'multipart/form-data',
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        FetchProfileData();
        // console.log(data)
        if (data.status == "success") {
          toast.success(data.message);
        }
      })
      .catch(({ response }) => {
        console.log(response.data);
        if (response.data.status_code == 422) {
          setError(response.data.message);

          // if (response.data.message.aboutus) {
          //   toast.error(response.data.message.aboutus[0])
          // }
          // if (response.data.message.bank_acc_name) {
          //   toast.error(response.data.message.bank_acc_name[0])
          // }
          // if (response.data.message.address) {
          //   toast.error(response.data.message.address[0])
          // }
        }
      });
  };
  function checkopenclose(day) {

    if (workinghour.length > 0) {
      const day1 = workinghour.filter((e) => e.day === day);
      if (day1.length > 0) {
        // if(day==="sunday"){
        //   setSunday(day1[0].status === "open" ? true : false);
        // }
        // if(day==="monday"){
        //   setMonday(day1[0].status === "open" ? true : false);
        // }
        // if(day==="tuesday"){
        //   setTuesday(day1[0].status === "open" ? true : false);
        // }
        // if(day==="wednesday"){
        //   setWednesday(day1[0].status === "open" ? true : false);
        // }
        // if(day==="thursday"){
        //   setThursday(day1[0].status === "open" ? true : false);
        // }
        // if(day==="friday"){
        //   setFriday(day1[0].status === "open" ? true : false);
        // }
        // if(day==="saturday"){
        //   setSaturday(day1[0].status === "open" ? true : false);
        // }
        // switch (day) {
        //   case "sunday":
        //     setSunday(day1[0].status === "open" ? true : false);
        //     break;
        //   case "monday":
        //     setMonday(day1[0].status === "open" ? true : false);
        //     break;
        //   case "tuesday":

        //      setTuesday(day1[0].status === "open" ? true : false);
        //     break;
        //   case "wednesday":
        //     setWednesday(day1[0].status === "open" ? true : false);
        //     break;
        //   case "thursday":
        //     setThursday(day1[0].status === "open" ? true : false);
        //     break;
        //   case "friday":
        //     setFriday(day1[0].status === "open" ? true : false);
        //     break;
        //   case "saturday":
        //     setSaturday(day1[0].status === "open" ? true : false);
        // }
        return day1[0].status === "open" ? true : false;

        // setSunday(!sunday);
      } else {
        return false;
      }
      // return;
    }
  };

  const checkopenclose1 = (day) => {
    if (workinghour.length > 0) {
      const day1 = workinghour.filter((e) => e.day === day);
      if (day1) {
        // if(day==="sunday"){
        //   setSunday(day1[0].status === "open" ? true : false);
        // }
        return day1[0];
      } else {
        return "";
      }
    }
  };
  const handlechangeChip = (e) => {
    console.log(e)
    if (chip.length < keywordtotal) {
      setChip(e);
    } else {
      alert("You reach Maximum limit to add keyword!")
    }

  };

  const datatime = () => {
    let items = [];
    items.push(<option value="">
      Select
    </option>)
    for (let i = 1; i < 13; i++) {
      items.push(
        <option value={i + " AM"}>
          {i + " AM"}
        </option>
      );
    }
    for (let i = 1; i < 13; i++) {
      items.push(
        <option value={i + " PM"}>
          {i + " PM"}
        </option>
      );
    }
    return items;
  };
const handleimagecompany=(e)=>{
  setcompanyimage(e.target.files[0])
  setcompanyimagefile(URL.createObjectURL(e.target.files[0]))
}
  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Store & Website Setup</li>
              <Button
                className="dashboard-btn"
                href="/dashboard"
                variant="primary"
              >
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu PurchaseHis={status} />
              <div className="col-md-9">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>
                    Store & Website Setup{" "}
                    <Button
                      onClick={() => {
                        setEdit(true);
                        toast.success("You can now edit your details");
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </h4>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk mb-3">
                    <h5>Store & Website Setup</h5>
                  </div>
                  <div className="card-body-blk">
                    <div className="row">
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>Meta Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Meta Title "
                          value={metaTitle}
                          onChange={(e) => setMetaTitle(e.target.value)}
                          disabled={
                            data && data.meta_title && !edit ? true : false
                          }
                        />
                        {error && error.meta_title ? (
                          <div className="text-danger">
                            {error && error.meta_title}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12 mb-2 mt-2">
                        <div className="d-flex justify-content-between">
                          <Form.Label>Meta Keyword</Form.Label>
                          <div>({chip.length}/{keywordtotal})</div>
                        </div>
                        {data && data.meta_keyword && !edit ? (
                          <div>
                            <div
                              style={{
                                border: "1px solid lightgray",
                                padding: 10,
                                background: "#F2F3F8",
                              }}
                            >
                              <div>{data && data.meta_keyword.join(",")}</div>
                            </div>
                            <div style={{ textAlign: "end" }} className="mt-1">
                              ({chip.length}/{keywordtotal})
                            </div>
                          </div>
                        ) : (
                          <div>
                            <Chips
                              value={chip}
                              onChange={handlechangeChip}
                              // suggestions={KeyData}
                              placeholder="Enter Keyword"
                              fromSuggestionsOnly={false}
                              createChipKeys={[13, 32]}
                              disabled={
                                data && data.meta_keyword && !edit
                                  ? true
                                  : false
                              }
                            />

                          </div>
                        )}

                        {error && error.meta_keyword ? (
                          <div className="text-danger">
                            {error && error.meta_keyword}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>Meta Description</Form.Label>
                        <Form.Control
                          as="textarea" rows={3}
                          type="text"
                          placeholder="Enter Meta Description "
                          onChange={(e) => setMetaDescription(e.target.value)}
                          value={metaDescription}
                          disabled={
                            data && data.meta_description && !edit
                              ? true
                              : false
                          }
                        />
                        {error && error.meta_description ? (
                          <div className="text-danger">
                            {error && error.meta_description}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <Form.Label>Company Introduction Image</Form.Label>
                            <Form.Control
                              type="file"
                              onChange={handleimagecompany}
                              // placeholder="Enter Your Domain Name"
                              // defaultValue={data && data.facebook == null ? '' : data && data.facebook}
                              disabled={
                                data && data.domain && !edit ? true : false
                              }
                            />
                            {error && error.domain &&(
                              <div className="text-danger">
                                {error && error.domain}
                              </div>
                                )}
                                {companyimagefile &&(
                                  <img height="100px" width="100px" src={companyimagefile} />
                                )}
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>Company Introduction</Form.Label>
                        <Editor
                          tools={[
                            [Bold, Italic, Underline, Strikethrough],
                            [Subscript, Superscript],
                            [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                            [Indent, Outdent],
                            [OrderedList, UnorderedList],
                            FontSize,
                            FontName,
                            FormatBlock,
                            [Undo, Redo],
                            [Link, Unlink, InsertImage, ViewHtml],
                            [InsertTable],
                            [
                              AddRowBefore,
                              AddRowAfter,
                              AddColumnBefore,
                              AddColumnAfter,
                            ],
                            [DeleteRow, DeleteColumn, DeleteTable],
                            [MergeCells, SplitCell],
                          ]}
                          contentStyle={{
                            height: 300,
                          }}
                          value={aboutUs}
                          ref={body1}
                          onChange={(e) => setAboutUs(e.target.value)}
                        />
                        {error && error.comp_intr ? (
                          <div className="text-danger">
                            {" "}
                            {error && error.comp_intr}{" "}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <Form.Control
                          type="text"
                          placeholder="About Us "
                          onChange={(e) => setAboutUs(e.target.value)}
                          defaultValue={data && data.aboutus == null ? '' : data && data.aboutus}
                          disabled={data && data.aboutus && !edit ? true : false}
                        /> */}
                        {error && error.aboutus ? (
                          <div className="text-danger">
                            {error && error.aboutus}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      

                      {/* <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Slug</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setSlug(e.target.value)}
                          placeholder="Enter Slug "
                          defaultValue={data && data.slug == null ? '' : data && data.slug}
                          disabled={data && data.slug && !edit ? true : false}
                        />
                        {error && error.slug[0] ?
                          <div className="text-danger">{error && error.slug[0]}</div> : ''}
                      </div> */}
                      {statusData && statusData.data && statusData.data.personal_domain == "Yes" && (
                        <>
                          <div className="col-md-4 mb-2 mt-2">
                            {subdomainstatus == false ? (
                              <>
                                <Form.Label>Domain Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={domain}
                                  onChange={(e) => setdomain(e.target.value)}
                                  placeholder="Enter Your Domain Name"
                                  // defaultValue={data && data.facebook == null ? '' : data && data.facebook}
                                  disabled={
                                    data && data.domain && !edit ? true : false
                                  }
                                />
                                {error && error.domain ? (
                                  <div className="text-danger">
                                    {error && error.domain}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                <Form.Label>SubDomain Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={(e) => setsubdomain(e.target.value)}
                                  value={subdomain}
                                  placeholder="Enter Your SubDomain Name"
                                  disabled={
                                    data && data.subdomain && !edit ? true : false
                                  }
                                />
                                {subdomain}.aladdindirect.online
                                {error && error.subdomain ? (
                                  <div className="text-danger">
                                    {error && error.subdomain}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-md-4 mb-2 mt-2">
                            <Button
                              className="btn btn-sm"
                              onClick={() => setsubdomainstatus(!subdomainstatus)}
                            >
                              {" "}
                              {subdomainstatus == true
                                ? "Continue with Domain"
                                : "Continue with Sub Domain"}
                            </Button>
                          </div>

                        </>
                      )}


                      <div className="col-md-4 mb-2 mt-2"></div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setFacebook(e.target.value)}
                          placeholder="Enter Facebook Url "
                          value={facebook}
                          disabled={
                            data && data.facebook && !edit ? true : false
                          }
                        />
                        {error && error.facebook ? (
                          <div className="text-danger">
                            {error && error.facebook}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setTwitter(e.target.value)}
                          value={twitter}
                          placeholder="Enter Twitter Url "
                          disabled={
                            data && data.twitter && !edit ? true : false
                          }
                        />
                        {error && error.twitter ? (
                          <div className="text-danger">
                            {error && error.twitter}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Youtube</Form.Label>
                        <Form.Control
                          type="text"
                          value={youtube}
                          onChange={(e) => setYoutube(e.target.value)}
                          placeholder="Enter Youtube Url"
                          disabled={
                            data && data.youtube && !edit ? true : false
                          }
                        />
                        {error && error.youtube ? (
                          <div className="text-danger">
                            {error && error.youtube}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Linkedin</Form.Label>
                        <Form.Control
                          type="text"
                          value={linkedin}
                          onChange={(e) => setLinkedin(e.target.value)}
                          disabled={
                            data && data.linkedin && !edit ? true : false
                          }
                          placeholder="Enter Linkedin Url"
                        />
                        {error && error.linkedin ? (
                          <div className="text-danger">
                            {error && error.linkedin}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                          onChange={(e) => setInstagram(e.target.value)}
                          type="text"
                          value={instagram}
                          disabled={
                            data && data.instagram && !edit ? true : false
                          }
                          placeholder="Enter Instagram Url"
                        />
                        {error && error.instagram ? (
                          <div className="text-danger">
                            {error && error.instagram}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Pinterest</Form.Label>
                        <Form.Control
                          type="text"
                          value={pinterest}
                          disabled={
                            data && data.pinterest && !edit ? true : false
                          }
                          placeholder="Enter Pinterest Url"
                          onChange={(e) => setpinInterest(e.target.value)}
                        />
                        {error && error.pinterest ? (
                          <div className="text-danger">
                            {error && error.pinterest}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Blog Url</Form.Label>
                        <Form.Control
                          type="text"
                          value={blogUrl}
                          onChange={(e) => setBlogUrl(e.target.value)}
                          placeholder="Enter Blog Url "
                          disabled={
                            data && data.blog_url && !edit ? true : false
                          }
                        />
                        {error && error.blog_url ? (
                          <div className="text-danger">
                            {error && error.blog_url}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <ToastContainer />
                    </div>

                    {workinghour.length > 0 && edit == false ? (
                      <div className="col-md-12">
                        <div className="order-head-blk mb-3">
                          <h5>Working Hours</h5>
                        </div>
                        <div className="col-md-4 row">
                          {workinghour &&
                            workinghour.map((item) => (
                              <div className="flex">
                                <h6 className="col-md-3">{item.day}</h6>
                                <h6 className="col-md-3">{item.status}</h6>
                                <h6 className="col-md-3">{item.in_time}</h6>
                                <h6 className="col-md-3">{item.out_time}</h6>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="mobile-margin-1">
                          <h5>Working Hours</h5>
                        </div>

                        {checkopenclose("sunday")}
                        <div className="hide-in-mobile">
                          <div className="row mt-4">
                            <div className="col-md-2 col-sm-4 col-xs-4">
                              <Form.Label className="mt-2 ">Sunday</Form.Label>
                            </div>
                            <div className="col-md-2 col-sm-4 col-xs-4" >
                              {/* label="Closed?" */}

                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  // label="Closed?"
                                  checked={sunday}
                                  onChange={handleSunday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>

                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3 col-sm-4 col-xs-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px",
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("sunday")
                                      ? checkopenclose1("sunday").in_time
                                      : ""
                                  }
                                  onChange={(e) => setSundayStart(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("sunday")
                                      ? checkopenclose1("sunday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setSunEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Sunday</Form.Label>
                          <div className="mobile-working-hours ">

                            <div className="col-md-2 col-sm-4 col-xs-4" >
                              {/* label="Closed?" */}

                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  // label="Closed?"
                                  checked={sunday}
                                  onChange={handleSunday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>

                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3 col-sm-4 col-xs-4"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px",
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("sunday")
                                      ? checkopenclose1("sunday").in_time
                                      : ""
                                  }
                                  onChange={(e) => setSundayStart(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("sunday")
                                      ? checkopenclose1("sunday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setSunEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>


                        {checkopenclose("monday")}
                        <div className="hide-in-mobile">
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2">Monday</Form.Label>
                            </div>
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={monday}
                                  onChange={handleMonday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px",
                                }}
                              >
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("monday")
                                      ? checkopenclose1("monday").in_time
                                      : ""
                                  }
                                  onChange={(e) => setMondayStart(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("monday")
                                      ? checkopenclose1("monday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setMonEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Monday</Form.Label>
                          <div className="mobile-working-hours ">
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={monday}
                                  onChange={handleMonday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px",
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("monday")
                                      ? checkopenclose1("monday").in_time
                                      : ""
                                  }
                                  onChange={(e) => setMondayStart(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("monday")
                                      ? checkopenclose1("monday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setMonEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="hide-in-mobile">
                          {checkopenclose("tuesday")}
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2">Tuesday</Form.Label>
                            </div>
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={tuesday}
                                  onChange={handleTuesday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("tuesday")
                                      ? checkopenclose1("tuesday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setTuesdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("tuesday")
                                      ? checkopenclose1("tuesday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setTuesEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Tuesday</Form.Label>
                          <div className="mobile-working-hours ">
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={tuesday}
                                  onChange={handleTuesday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("tuesday")
                                      ? checkopenclose1("tuesday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setTuesdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("tuesday")
                                      ? checkopenclose1("tuesday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setTuesEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>


                        <div className="hide-in-mobile">
                          {checkopenclose("wednesday")}
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2">Wednesday</Form.Label>
                            </div>
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={wednesday}
                                  onChange={handleWednesday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("wednesday")
                                      ? checkopenclose1("wednesday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setWednesdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("wednesday")
                                      ? checkopenclose1("wednesday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setWedEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Wednesday</Form.Label>
                          <div className="mobile-working-hours ">
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={wednesday}
                                  onChange={handleWednesday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("wednesday")
                                      ? checkopenclose1("wednesday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setWednesdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("wednesday")
                                      ? checkopenclose1("wednesday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setWedEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>


                        <div className="hide-in-mobile">
                          {checkopenclose("thursday")}
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2">Thursday</Form.Label>
                            </div>
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={thursday}
                                  onChange={handleThursday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("thursday")
                                      ? checkopenclose1("thursday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setThursdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("thursday")
                                      ? checkopenclose1("thursday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setThursEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Thursday</Form.Label>
                          <div className="mobile-working-hours ">
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={thursday}
                                  onChange={handleThursday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("thursday")
                                      ? checkopenclose1("thursday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setThursdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("thursday")
                                      ? checkopenclose1("thursday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setThursEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="hide-in-mobile">
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2">Friday</Form.Label>
                            </div>
                            {checkopenclose("friday")}
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={friday}
                                  onChange={handleFriday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("friday")
                                      ? checkopenclose1("friday").in_time
                                      : ""
                                  }
                                  onChange={(e) => setFridayStart(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  defaultValue={
                                    checkopenclose1("friday")
                                      ? checkopenclose1("friday").out_time
                                      : ""
                                  }
                                  style={{ width: 100 }}
                                  onChange={(e) => setFriEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Friday</Form.Label>
                          <div className="mobile-working-hours ">
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={friday}
                                  onChange={handleFriday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("friday")
                                      ? checkopenclose1("friday").in_time
                                      : ""
                                  }
                                  onChange={(e) => setFridayStart(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  defaultValue={
                                    checkopenclose1("friday")
                                      ? checkopenclose1("friday").out_time
                                      : ""
                                  }
                                  className="work-hour-width"
                                  onChange={(e) => setFriEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>

                        <div className="hide-in-mobile">
                          {checkopenclose("saturday")}
                          <div className="row mt-2">
                            <div className="col-md-2">
                              <Form.Label className="mt-2">Saturday</Form.Label>
                            </div>
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={saturday}
                                  onChange={handleSaturday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("saturday")
                                      ? checkopenclose1("saturday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setSaturdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  style={{ width: 100 }}
                                  defaultValue={
                                    checkopenclose1("saturday")
                                      ? checkopenclose1("saturday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setSatEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-12">
                              <Button
                                style={{
                                  float: "right",
                                  marginTop: "8px",
                                  color: "#fff",
                                }}
                                variant="warning"
                                onClick={Handlestore}
                              >
                                Update
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="hide-in-desktop">
                          <Form.Label className="mt-2 ">Saturday</Form.Label>
                          <div className="mobile-working-hours ">
                            <div className="col-md-2">
                              {/* label="Closed?" */}
                              <Form.Label
                                className="mt-2"
                                style={{ display: "flex" }}
                              >
                                Closed?
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  checked={saturday}
                                  onChange={handleSaturday}
                                  style={{ marginLeft: 10 }}
                                />
                              </Form.Label>
                            </div>
                            {edit == true ? (
                              <div
                                className="col-md-3"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  gap: "5px"
                                }}
                              >
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("saturday")
                                      ? checkopenclose1("saturday").in_time
                                      : ""
                                  }
                                  onChange={(e) =>
                                    setSaturdayStart(e.target.value)
                                  }
                                >
                                  {datatime()}
                                </Form.Select>
                                <Form.Label className="mt-2">to</Form.Label>
                                <Form.Select
                                  className="work-hour-width"
                                  defaultValue={
                                    checkopenclose1("saturday")
                                      ? checkopenclose1("saturday").out_time
                                      : ""
                                  }
                                  onChange={(e) => setSatEnd(e.target.value)}
                                >
                                  {datatime()}
                                </Form.Select>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-md-12">
                              <Button
                                style={{
                                  color: "#fff",
                                }}
                                variant="warning"
                                onClick={Handlestore}
                              >
                                Update
                              </Button>
                            </div>
                          
                        </div>


                        {/* <div>
                          <div className="order-head-blk">
                            <h5>Documents</h5>
                          </div>

                          <div className="row">
                            <div className="col-md-4 mb-2 mt-2">

                              <Form.Label>Upload Registration Certificate</Form.Label>
                              {data && data.reg_certificate ?
                                <div>
                                  <img src={img_Url + data.reg_certificate} height="50px" width="50px" />
                                </div>
                                :
                                <input type="file" className="mt-2"
                                  onChange={handleReg}
                                />

                              }
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Upload PAN</Form.Label>
                              {data && data.pancard ?
                                <div>
                                  <img src={img_Url + data.pancard} height="50px" width="50px" />
                                </div> :
                                <input type="file" className="mt-2"
                                  onChange={handlePan}
                                />}
                            </div>
                            {data && data.gst_status == 'yes' ?
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Upload GST Certificate</Form.Label>
                                <div>
                                  <img src={img_Url + data.gst_certificate} height="50px" width="50px" />
                                </div> </div> : ''}
                            {gstApp &&
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Upload GST Certificate</Form.Label>
                                <input type="file" className="mt-2"
                                  onChange={handleGST}
                                />
                              </div>
                            }
                            <div className="col-md-12">
                              <Button
                                style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                variant="warning"
                                onClick={UpdateProfile}
                              >
                                Update
                              </Button>
                            </div>
                          </div>


                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default ManageProfile;
