import React, { useState, useRef } from 'react';
export const OtpInput = ({ length = 5, onComplete }) => {
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const inputRefs = Array(length).fill(0).map((_, i) => React.createRef(null));
  
    const handleInputChange = (e, index) => {
      const value = e.target.value;
  console.log(value)
      if (isNaN(value)) {
        return;
      }
      
      setOtp((prevOtp) => {
        const newOtp = [...prevOtp];
        newOtp[index] = value;
  
        if (index < length - 1 && value !== '') {
          // Move focus to the next input
          inputRefs[index + 1].current.focus();
        }
  
        return newOtp;
      });
      const newOtp1 = [...otp];
        newOtp1[index] = value;
      onComplete(newOtp1.join(''));
      // Check if all inputs are filled
    //   if (!otp.includes('') && onComplete) {
    //     onComplete(otp.join(''));
    //   }
    };
  
    const handleKeyDown = (e, index) => {
      if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
        // Move focus to the previous input on Backspace
        inputRefs[index - 1].current.focus();
      }
    };
  
    return (
      <div className='flex mx-3'>
        {otp.map((digit, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength="1"
            value={digit}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            className='small-input form-control'
          />
        ))}
      </div>
    );
  };
  