import React, { useState } from 'react'
import "../../style/JoinOurTeam.css"

import { FormCom } from './FormCom'

export const JotCom2 = () => {
    const [showForm, setShowForm] = useState(false);
    
    return (
        <>
            <div className="jot-com2 my-lg-5 my-md-4 my-3 me-lg-5 me-md-4 me-3">

                <div className="main d-flex justify-content-between">
                    <div className="left">
                        <img src="/assets/images/join-our-team2.png" alt="" />
                    </div>

                    <div className="right">
                        <h3>Be part of our creative community</h3>
                        <p>We love what we do and we do it with passion.
                            We value the experimentation of the message and
                            smart incentives.</p>

                        <button onClick={() => setShowForm(true)}>Join Us</button>
                    </div>

                </div>
                {showForm === true ?
                    <FormCom  />
                    :
                    null}


            </div>

        </>
    )
}
