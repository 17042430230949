import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { NavLink, useNavigate, Link, useLoaderData, useLocation } from "react-router-dom";
import { Table, Badge, Form, Button } from "react-bootstrap";
import LeftCheckIcon from "../components/deal/leftcheckicon.svg";
import axios from "axios";
import { BASE_URL } from '../config'
import Loader from "../components/loader/Loader.component";
const VendorPackage = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem('avonToken')
  const [packagesData, setPackagesData] = useState([])
  const [showproduct, setshowproduct] = useState(false)
  const [showservices, setshowservices] = useState(false)
  const [showdirectory, setshowdirectory] = useState(true)
  const [showstartup, setshowstartup] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const {state} = useLocation();
  useEffect(() => {
    FetchPackages();
  }, [])

  const FetchPackages = async () => {
    await axios.get(BASE_URL + '/package?id='+state).then(({ data }) => {
      setPackagesData(data.data);
      setIsLoading(false)
    });
  }

  const userredirect = (id) => {
    if (token) {
      navigate('/vendor-checkout', ({ state: id }))
    } else {

    }
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <Page title="Packages">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Vendor Package</li>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              {/* <VendorDashboardSideMenu /> */}
              <div className="col-md-12">
                <div className="vendor-package-section">
                  <div className="package-card-block">
                    
                  
                    <h4 className="text-center form-mt-2">
                    Reach Millions of Customer Globally
                    </h4>
                    <div style={{marginLeft:"40%"}}>
                    <button style={{height:40,borderRadius:5,color:"white"}} className={showdirectory == true ? "mx-2 btn btn-success" : "mx-2 btn-primary"} onClick={()=>{setshowproduct(false);setshowservices(false);setshowdirectory(true);setshowstartup(false)}}>Directory</button>
                    <button style={{height:40,borderRadius:5,color:"white"}} className={showproduct == true ? "mx-2 btn btn-success" : "mx-2 btn-primary"} onClick={()=>{setshowproduct(true);setshowservices(false);setshowdirectory(false);setshowstartup(false)}}>Products</button>
                    <button style={{height:40,borderRadius:5,color:"white"}} className={showservices == true ? "mx-2 btn btn-success" : "mx-2 btn-primary"} onClick={()=>{setshowproduct(false);setshowservices(true);setshowdirectory(false);setshowstartup(false)}}>Services</button>
                    <button style={{height:40,borderRadius:5,color:"white"}} className={showstartup == true ? "mx-2 btn btn-success" : "mx-2 btn-primary"} onClick={()=>{setshowproduct(false);setshowservices(false);setshowdirectory(false);setshowstartup(true)}}>Startup</button>
                    </div>
                    <p className="text-center text-muted">
                      {showproduct === true &&(
                        <>
                        List your products with Deals, Stock Clearance and Much more and reach Millions of Customers Globally
                        </>
                      )}
                      {showservices === true &&(
                        <>
                        List your Business with us, and reach Millions of Customers searching for your services
                        </>
                      )}
                      {showdirectory === true &&(
                        <>
                        List your business and reach millions of customers globally
                        </>
                      )}
                      {showstartup === true &&(
                        <>
                        Start Your Startup
                        </>
                      )}
                      
                    </p>
                    {showproduct === true &&(
                      <div className="package-table form-mt-3">
                      <Table responsive="md" bordered>
                        <thead>
                          <tr style={{ backgroundColor: "#fff" }}>
                            <th style={{ fontWeight: 500 }} className="package-table-cell">
                              <h3>Select Packages</h3>
                            </th>
                            {packagesData && packagesData?.product?.map((item) => (
                              <th>
                                <div style={{ border: "1px solid #EEEEEE" }} className="">
                                  <h4 style={{ paddingTop: "25px" }} className="text-center">
                                    {item.package_name}
                                  </h4>
                                  <p className="package-short-desc">{item.short_description}</p>
                                  {item.mrp == 0 ?(
                                     <h3 className="text-center">Free</h3>
                                  ):(
                                    
                                  <h3 className="text-center">Rs. {item.offered_price}<del>Rs. {item.mrp}</del></h3>
                                  
                                  )}
                                  

                                  <h6 style={{ textDecoration: 'line-through', color: 'red' }} className="text-center"></h6>
                                  <div style={{ textAlign: 'center' }} className="package-cards-btn">
                                    <Link to='/vendor-checkout' state={item.id}> <Button style={{ borderRadius: 0 }}>
                                    {item.mrp == 0 ? "SELECT": "SELECT & PAY"}
                                    </Button></Link>
                                  </div>
                                  <p className="trial-time mt-2">
                                    {item.traial_day} day free trial
                                  </p>
                                </div>
                              </th>
                            ))}

                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Package Detail</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell" >Directory</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item?.getdirectory?.package_name}</td>
                            ))}
                          </tr>

                          <tr >
                            <td className="package-table-cell" >Validity</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.validity} Days</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Free Trial</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.traial_day} Days</td>
                            ))}
                          </tr>

                          
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Add on features</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell">Normal Listing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.normal_listing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Premium Listing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.premium_listing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Post Products</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.post_products} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Flash Deals</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.flash_deals} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Stock Clearance Sale</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.stock_clearance_sale} Posts / Month</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Trust Seal</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.trust_seal == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Verified Tag</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.verified_tag == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Preferred Seller</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.prefereed_seller == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Global Inquiry</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.global_inquiry == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Free Website</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.free_website == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Personal Domain</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.personal_domain == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Keywords</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.keywords} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Graphic Designing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.graphic_designing} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Content Writing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.content_writing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Catalogue Desgning</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.catalogue_designing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Digital Marketing</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Facebook</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.facebook} Keywords</td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell">Instagram</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.instagram} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Linkedin</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.linkedin} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Youtube</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.youtube} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Twitter</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.twitter} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Banner Ads Credit</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.banner_ads_credit} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Keywords</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.keywords} Posts / Month</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Analytics Insight</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.analytics_insight == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Support</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Premium Support</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.prem_support == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Dedicated Account Manager</td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.ded_acnt_manager == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell"></td>
                            {packagesData && packagesData?.product?.map((item) => (
                              <td style={{ textAlign: 'center' }}>
                                <Link to={token ? '/vendor-checkout' : '/login'} state={item.id}>
                                  <Button
                                    className="package-details-button"
                                    style={{
                                      borderRadius: 0,
                                      backgroundColor: "#0085FF",
                                    }}
                                  >
                                    {item.mrp == 0 ? "SELECT": "SELECT & PAY"}
                                  </Button>
                                </Link>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    )}
                    {showservices === true &&(
                      <div className="package-table form-mt-3">
                      <Table responsive="md" bordered>
                        <thead>
                          <tr style={{ backgroundColor: "#fff" }}>
                            <th style={{ fontWeight: 500 }} className="package-table-cell">
                              <h3>Select Packages</h3>
                            </th>
                            {packagesData && packagesData?.services?.map((item) => (
                              <th>
                                <div style={{ border: "1px solid #EEEEEE" }} className="">
                                  <h4 style={{ paddingTop: "25px" }} className="text-center">
                                    {item.package_name}
                                  </h4>
                                  <p className="package-short-desc">{item.short_description}</p>
                                  {item.mrp == 0 ?(
                                     <h3 className="text-center">Free</h3>
                                  ):(
                                    
                                  <h3 className="text-center">Rs. {item.offered_price}<del>Rs. {item.mrp}</del></h3>
                                  
                                  )}

                                  <h6 style={{ textDecoration: 'line-through', color: 'red' }} className="text-center"></h6>
                                  <div style={{ textAlign: 'center' }} className="package-cards-btn">
                                    <Link to='/vendor-checkout' state={item.id}> <Button style={{ borderRadius: 0 }}>
                                    {item.mrp == 0 ? "SELECT": "SELECT & PAY"}
                                    </Button></Link>
                                  </div>
                                  <p className="trial-time mt-2">
                                    {item.traial_day} day free trial
                                  </p>
                                </div>
                              </th>
                            ))}

                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Package Detail</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell" >Directory</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item?.getdirectory?.package_name}</td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell" >Validity</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.validity} Days</td>
                            ))}
                          </tr>

                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Free Trial</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.traial_day} Days</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Add on features</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell">Normal Listing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.normal_listing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Premium Listing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.premium_listing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Catalogue Creation</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.catalouge_creation} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Skills Can be Added</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.skill_can_be_added} Posts / Month</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Trust Seal</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.trust_seal == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Verified Tag</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.verified_tag == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Preferred Seller</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.prefereed_seller == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Global Inquiry</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.global_inquiry == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Free Website</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.free_website == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Personal Domain</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.personal_domain == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Keywords</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.keywords} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Content Writing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.content_writing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Catalogue Desgning</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.catalogue_designing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Digital Marketing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell">Facebook</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.facebook} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Instagram's</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.instagram} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Linkedin</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.linkedin} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Youtube</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.youtube} Posts / Month</td>
                            ))}
                          </tr>

                          <tr>
                            <td  className="package-table-cell">Twitter</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.twitter} Posts / Month</td>
                            ))}
                          </tr>
                          <tr >
                            <td  className="package-table-cell">Banner Ads Credit</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.banner_ads_credit} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Keywords</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.keywords} Posts / Month</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Analytics Insight</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.analytics_insight == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Support</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Premium Support</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.premium_support == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Dedicated Account Manager</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.dedicated_account_manager == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell"></td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>
                                <Link to={token ? '/vendor-checkout' : '/login'} state={item.id}>
                                  <Button
                                    className="package-details-button"
                                    style={{
                                      borderRadius: 0,
                                      backgroundColor: "#0085FF",
                                    }}
                                  >
                                   {item.mrp == 0 ? "SELECT": "SELECT & PAY"}
                                  </Button>
                                </Link>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    )}
                    {showdirectory === true &&(
                      <div className="package-table form-mt-3">
                      <Table responsive="md" bordered>
                        <thead>
                          <tr style={{ backgroundColor: "#fff" }}>
                            <th style={{ fontWeight: 500 }} className="package-table-cell">
                              <h3>Select Packages</h3>
                            </th>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <th>
                                <div style={{ border: "1px solid #EEEEEE" }} className="">
                                  <h4 style={{ paddingTop: "25px" }} className="text-center">
                                    {item.package_name}
                                  </h4>
                                  <p className="package-short-desc">{item.short_description}</p>
                                  {item.mrp == 0 ?(
                                     <h3 className="text-center">Free</h3>
                                  ):(
                                    
                                  <h3 className="text-center">Rs. {item.offered_price}<del>Rs. {item.mrp}</del></h3>
                                  
                                  )}

                                  <h6 style={{ textDecoration: 'line-through', color: 'red' }} className="text-center"></h6>
                                  <div style={{ textAlign: 'center' }} className="package-cards-btn">
                                    <Link to='/vendor-checkout' state={item.id}> <Button style={{ borderRadius: 0 }}>
                                      {item.mrp == 0 ? "SELECT": "SELECT & PAY"}
                                    </Button></Link>
                                  </div>
                                  <p className="trial-time mt-2">
                                    {item.traial_day} day free trial
                                  </p>
                                </div>
                              </th>
                            ))}

                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Package Detail</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell" >Validity</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.validity} Days</td>
                            ))}
                          </tr>

                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Free Trial</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.traial_day} Days</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Add on features</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>

                          <tr>
                            <td className="package-table-cell">Normal Listing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.normal_listing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Premium Listing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.premium_listing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Custom Banner</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.custom_banner == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Trust Seal</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.trust_seal == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Verified Tag</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.verified_tag == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Keywords</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.keywords} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Preferred Seller</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.prefereed_seller == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Content Writing</td>
                            {packagesData && packagesData?.services?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.content_writing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Digital Marketing</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Facebook</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.facebook} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Linkedin</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.linkedin} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Instagram</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.instagram} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Youtube</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.youtube} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Twitter</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.twitter} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Banner Ads Credit</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.banner_ads_credit} Posts / Month</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Keywords</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center', fontWeight: 500 }}>{item.keywords} Posts / Month</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell">Analytics Insight</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.analytics_insight == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>

                          <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                            <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Support</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ border: 'none' }}></td>
                            ))}
                          </tr>
                          <tr >
                            <td className="package-table-cell">Premium Support</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.prem_support == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr>
                            <td className="package-table-cell">Dedicated Account Manager</td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>{item.ded_acnt_manager == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />}</td>
                            ))}
                          </tr>
                          <tr style={{ borderBottom: '1px solid black' }}>
                            <td className="package-table-cell"></td>
                            {packagesData && packagesData?.directory?.map((item) => (
                              <td style={{ textAlign: 'center' }}>
                                <Link to={token ? '/vendor-checkout' : '/login'} state={item.id}>
                                  <Button
                                    className="package-details-button"
                                    style={{
                                      borderRadius: 0,
                                      backgroundColor: "#0085FF",
                                    }}
                                  >
                                   {item.mrp == 0 ? "SELECT": "SELECT & PAY"}
                                  </Button>
                                </Link>
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    )}
                    {showstartup === true &&(
                      <div className="package-table form-mt-3">
                      <div className="card-body-blk">
    <p>
        <p><span style={{"font-weight": "bolder"}}>Aladdin Direct Inc.&nbsp;</span>is one of the biggest platforms to showcase your products &amp; services, it's very hard to promote the business and get more leads hence we at&nbsp;<span style={{"font-weight": "bolder"}}>Aladdin Direct Inc&nbsp;</span>have
            bought a solution for you.</p>
        <p>What are the benefits of being a Seller at&nbsp;<span style={{"font-weight": "bolder"}}>Aladdin Direct Inc</span></p>
        <ol>
            <li>Showcase unlimited products*</li>
            <li>No need to hire any team member to manage your Social Media</li>
            <li>Keep the worry away of promoting your business, as we are here to take care of</li>
            <li>Get unlimited Support from us</li>
            <li>Boost your Sales with Aladdin Direct Inc</li>
        </ol>
        <p>There are a lot to go with us, Follow the simple steps to become a registered &amp; verified Seller with&nbsp;<span style={{"font-weight": "bolder"}}>Aladdin Direct Inc</span></p>
        <ol>
            <li>Click on Become a Seller Button</li>
            <li>Select The required Package that suits your Business requirement</li>
            <li>Checkout with Updating Billing Information and Make a Purchase</li>
            <li>Our Team will check and verify your Business, after verification you will get a Confirmation Email</li>
            <li>Once verified, Login to your Seller Dashboard and Setup Your Business Profile</li>
            <li>Setup your Website &amp; Store, Update the contents and required Image</li>
            <li>Manage your Products &amp; Services, Start Uploading them to make your business a Brand</li>
            <li>for any help &amp; Support reach our Toll-Free Numbers in the Help &amp; Support Section</li>
        </ol>
        <p>You're done, Now you are a Part of the Biggest Online Business &amp; Trade Platform<span style={{"font-weight": "bolder"}}>&nbsp;"ALADDIN DIRECT INC"</span></p>
    </p>
    <div style={{"text-align": "right"}} ><button type="button" onClick={()=>navigate('/startup-form')} className="btn btn-primary" fdprocessedid="urb37gf">Add Startup</button></div>
</div>
                    </div>
                    )}
                    
                    {/* <div className="package-table">
                      <Table responsive="md" bordered>
                        <thead>
                          <tr style={{ backgroundColor: "#EEEEEE" }}>
                            <th style={{ fontWeight: 500 }}>
                              Customer Service
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Service</td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>{" "}
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr></tr>
                        </tbody>
                      </Table>
                    </div> */}
                    <p style={{ padding: '8px 0 30px 0' }} className="text-center">
                      *Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Donec eu justo dui. Etiam finibus diam a fringilla
                      sodales.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorPackage;
