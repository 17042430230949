import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';


const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const ManageProfile = ({ props }) => {

  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem('avonToken');
  const [data, setData] = useState();
  const [category, setCategory] = useState([]);
  const [regNo, setRegNo] = useState('');
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [gstApp, setGstApp] = useState('no');
  const [field, setField] = useState();
  // console.log(gstApp)
  const [panNo, setPanNo] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [panImg, setPanImg] = useState('');
  const [regcerImg, setRegCertImg] = useState('');
  const [gstImg, setGstImg] = useState('');
  const [bankName, setBankName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [branch, setBranch] = useState('');
  const [swift, setSwift] = useState('');
  const [ifsc, setIfsc] = useState('');
  const [error, setError] = useState();
  const [resError, setResError] = useState('');
  const [sunday, setSunday] = useState();
  const [sundaystart, setSundayStart] = useState();
  const [monday, setMonday] = useState();
  const [mondaystart, setMondayStart] = useState();
  const [tuesday, setTuesday] = useState();
  const [tuesdaystart, setTuesdayStart] = useState();
  const [wednesday, setWednesday] = useState();
  const [thursday, setThursday] = useState();
  const [friday, setFriday] = useState();
  const [saturday, setSaturday] = useState();
  const [sunOn, setSunOn] = useState();
  const [monOn, setMonOn] = useState();
  const [tuesOn, setTuesOn] = useState();
  const [wedOn, setWedOn] = useState();
  const [thursOn, setThursOn] = useState();
  const [friOn, setFriOn] = useState();
  const [satOn, setSatOn] = useState();
  const [statusData, setStatusData] = useState();
  const [status, setStatus] = useState(false)

  console.log('statusData', statusData)

  useEffect(() => {

    FetchStatus();
  }, [])

  const FetchStatus = async () => {

    await axios.get(BASE_URL + '/get-seller-detail', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setStatusData(data)

      // if(data.data == null){
      //   navigate('/vendor-dashboard')
      // }
      // if(data.data.payment_status == 'Pending'){
      //   navigate('/manage-profile')
      //   }
      setStatus(statusData && statusData.data == null ? true : statusData && statusData.data.payment_status == 'Pending' ? true : statusData && statusData.data.payment_status == 'Active' ? false : true)
    })

  }

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell
  } = EditorTools;

  const handleSunday = (e) => {
    setField('gtc', e.target.checked)
    setSunday(!sunday)
    console.log("status", sunday)
    setSunOn(sunday == true ? "close" : "open")
  }

  console.log("statusValue", monOn)

  const handleMonday = (e) => {
    setField('gtc', e.target.checked)
    setMonday(!monday)
    setMonOn(monday == true ? "close" : "open")
  }

  const handleTuesday = (e) => {
    setField('gtc', e.target.checked)
    setTuesday(!tuesday)
    setTuesOn(tuesOn == true ? "close" : "open")
  }

  const handleWednesday = (e) => {
    setField('gtc', e.target.checked)
    setWednesday(!wednesday)
    setWedOn(wedOn == true ? "close" : "open")
  }

  const handleThursday = (e) => {
    setField('gtc', e.target.checked)
    setThursday(!thursday)
    setThursOn(thursday == true ? "close" : "open")
  }

  const handleFriday = (e) => {
    setField('gtc', e.target.checked)
    setFriday(!friday)
    setFriOn(friday == true ? "close" : "open")
  }

  const handleSaturday = (e) => {
    setField('gtc', e.target.checked)
    setSaturday(!saturday)
    setSatOn(satOn == true ? "close" : "open")
  }

  const handleChange = (e) => {
    // setField('gtc', e.target.checked)
    setGstApp(e.target.checked ? "yes" : "no")
  }

  const handleGST = e => {
    setGstImg(e.target.files[0]);
  }
  const handlePan = e => {
    setPanImg(e.target.files[0]);
  }
  const handleReg = e => {
    setRegCertImg(e.target.files[0]);
  }

  const [keyword, setKeyword] = useState([]);
  const [store, setStore] = useState();
  const [seller, setSeller] = useState();


  useEffect(() => {
    FetchProfileData();
    getCountry();
    FetchBusinessCategory();
    FetchKeywords();
    FetchSlider();
  }, [])

  const FetchKeywords = async () => {
    await axios.get(BASE_URL + '/getmetakeyword').then(({ data }) => {
      setKeyword(data.data)
    })
  }

  const KeyData = keyword.map((item) => (
    item.meta_keyword
  ))

  const [img_Url, setImg_Url] = useState('');

  const FetchProfileData = async () => {
    await axios.get(BASE_URL + '/gettaxbankdetail', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      if (data.data) {
        setData(data.data)
        setImg_Url(data.image_url)
        setBankName(data.data.bank_name)
        setGstNo(data.data.gst_number)
        setPanNo(data.data.pannumber)
        setAccountName(data.data.bank_acc_name)
        setAccountNumber(data.data.bank_acc_no)
        setBranch(data.data.bank_branch)
        setIfsc(data.data.bank_ifsc)
        setSwift(data.data.bank_swiftcode)
        setRegNo(data.data.reg_no)
        setGstApp(data.data.gst_status)
      } else {
        setEdit(true);
      }



    })
  }

  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + '/getbusinesscategory', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setCategory(data.data)
      // console.log(data.data)
    })
  }

  const [sliderData, setSliderData] = useState([]);
  const [baseUrl, setBaseUrl] = useState();

  const FetchSlider = async () => {
    await axios.get(BASE_URL + '/getslider', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setSliderData(data.data)
      setBaseUrl(data.image_base_url)
    })
  }

  const [selected, setSelected] = useState([]);
  const [addMobile, setaddMobile] = useState([{ mobile: "" }]);
  // console.log(selected)

  // handle input change
  const handleInputMobileChange = (e, index) => {
    const { mobile, value } = e.target;
    const list = [...addMobile];
    list[index]['mobile'] = value;
    setaddMobile(list);
  };

  // handle click event of the Remove button
  const handleRemoveMobileClick = index => {
    const list = [...addMobile];
    list.splice(index, 1);
    setaddMobile(list);
  };

  // handle click event of the Add button
  const handleAddMobileClick = () => {
    setaddMobile([...addMobile, { mobile: "" }]);
  };

  const [addEmail, setaddEmail] = useState([{ email: "" }]);
  // console.log(addEmail)

  // handle input change
  const handleInputEmailChange = (e, index) => {
    const { email, value } = e.target;
    const list = [...addEmail];
    list[index]['email'] = value;
    setaddEmail(list);
    // console.log(list)
  };

  // handle click event of the Remove button
  const handleRemoveEmailClick = index => {
    const list = [...addEmail];
    list.splice(index, 1);
    setaddEmail(list);
  };

  // handle click event of the Add button
  const handleAddEmailClick = () => {
    setaddEmail([...addEmail, { email: "" }]);
  };

  const getCountry = () => {

    axios.get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };

  const [pickupList, setPickupList] = useState([{ pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  //  console.log([pickupList[0].pickAddress])
  // handle input change
  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...pickupList];
    // const li = [...pickupList.pickAddress]
    // console.log(list[0].pickAddress)
    list[index][name] = value;
    setPickupList(list);
    console.log(e)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...pickupList];
    list.splice(index, 1);
    setPickupList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setPickupList([...pickupList, { pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  };

  // console.log(pickupList[0].pickAddress)

  const [countryName, setCountryName] = useState()
  const handleCountyName = (e) => {
    const DataIdWithName = e;
    const countryData = DataIdWithName.split("_");
    setCountryName(countryData[0])
  }

  console.log('Days', [sundaystart, mondaystart, tuesdaystart])

  const UpdateTaxBank = async () => {
    const formData = new FormData();
    formData.append('gst_number', gstNo);
    formData.append('reg_certificate', regcerImg);
    formData.append('pannumber', panNo);
    formData.append('gst_certificate', gstImg);
    formData.append('bank_name', bankName);
    formData.append('bank_acc_name', accountName)
    formData.append('bank_acc_no', accountNumber)
    formData.append('bank_branch', branch)
    formData.append('bank_ifsc', ifsc)
    formData.append('bank_swiftcode', swift)
    formData.append('reg_no', regNo);
    formData.append('gst_status', gstApp);
    formData.append('pancard', panImg);

    await axios.post(BASE_URL + '/updatetaxbankdetail', formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProfileData();
      // console.log(data)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    })
      .catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setResError(response.data.message)
          if (response.data.message.business_category) {
            toast.error(response.data.message.business_category[0])
          }
          if (response.data.message.business_category) {
            toast.error(response.data.message.country[0])
          }
        }
      })
  }

  const [chip, setChip] = useState();



  const [edit, setEdit] = useState(false);
  const [errorImg, setErrorImg] = useState();
  const [pickAd, setPickAdd] = useState([]);




  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Tax & Bank Detail</li>
            </ul>
            <div className="col-md-12 ">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu PurchaseHis={status} />
              <div className="col-md-9">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>
                    Tax & Bank Detail <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}> <FontAwesomeIcon icon={faEdit} /></Button>
                  </h4>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Tax Information</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">
                    <Form>
                      <div>
                        <div className="mt-2">
                          <div className="row">
                            <div className="col-md-4 col-sm-12 col-xs-12 col-12 mb-2 mt-3">
                              <Form.Label>PAN Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter PAN Number"
                                onChange={(e) => setPanNo(e.target.value.toUpperCase())}
                                value={panNo}
                                disabled={data && data.pannumber && !edit ? true : false}
                              />
                              {resError && resError.pannumber ? <div className="text-danger"> {resError && resError.pannumber} </div> : ''}
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12 col-12 mb-2 mt-3">
                              <Form.Label>Enter Registration Number</Form.Label>
                              <Form.Control
                                disabled={data && data.reg_no && !edit ? true : false}
                                type="text"
                                placeholder="Enter Registration Number"
                                value={regNo}
                                onChange={(e) => setRegNo(e.target.value)}
                              />
                              {resError && resError.reg_no && <div className="text-danger"> {resError.reg_no} </div>}
                            </div>
                            <div className="col-md-4 col-sm-12 col-xs-12 col-12 mb-2 mt-5">
                              <div>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label="GST Applicable?"
                                  checked={gstApp == "yes" ? true : false}
                                  onChange={handleChange}
                                  className="tax-switch"

                                />
                              </div>
                            </div>

                            {/* <div className="col-md-3 mb-2 mt-2">
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              label="GST Applicable?"
                              onChange={handleChange}
                              style={{ marginTop: 35, fontSize: 18 }}
                            />
                          </div> */}
                            {/* {data && data.gst_status == 'yes' ?
                            <div className="col-md-4 mb-2 mt-4">
                              <Form.Label>GST Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter GST Number"
                                onChange={(e) => setGstNo(e.target.value)}
                                value={gstNo}
                                disabled={data && data.gst_number && !edit ? true : false}
                              />
                            </div> : ''} */}
                            {gstApp == "yes" &&
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>GST Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter GST Number"
                                  value={gstNo}
                                  disabled={data && data.gst_number && !edit ? true : false}
                                  // defaultValue={data && data.business_name}
                                  onChange={(e) => setGstNo(e.target.value)}
                                />
                                {resError && resError.gst_number && <div className="text-danger"> {resError.gst_number} </div>}
                              </div>
                            }
                          </div>
                        </div>
                        <div>
                          <div className="mt-3">
                            <h5>Documents</h5>
                          </div>

                          <div className="row">
                            <div className="col-md-4 mb-2 mt-2">

                              <Form.Label>Upload Registration Certificate</Form.Label>
                              {data && data.reg_certificate && !edit ?
                                <div>
                                  <img src={img_Url + data.reg_certificate} height="50px" width="50px" />
                                </div>
                                :
                                <>
                                  <input type="file" className="mt-2"
                                    onChange={handleReg}
                                  />
                                  {resError && resError.reg_certificate && <div className="text-danger"> {resError.reg_certificate} </div>}
                                </>


                              }
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Upload PAN</Form.Label>
                              {data && data.pancard && !edit ?
                                <div>
                                  <img src={img_Url + data.pancard} height="50px" width="50px" />
                                </div> :
                                <>
                                  <input type="file" className="mt-2"
                                    onChange={handlePan}
                                  />
                                  {resError && resError.pancard && <div className="text-danger"> {resError.pancard} </div>}
                                </>
                              }
                            </div>
                            
                            <div className="col-md-4 mb-2 mt-2">

                              {data && gstApp == "yes" && (data.gst_certificate && !edit ?
                                <div>
                                  <Form.Label>Upload GST Certificate</Form.Label>
                                  <img src={img_Url + data.gst_certificate} height="50px" width="50px" />
                                </div> :
                                <>
                                  <input type="file" className="mt-2"
                                    onChange={handleGST}
                                  />
                                  {resError && resError.gst_certificate && <div className="text-danger"> {resError.gst_certificate} </div>}
                                </>
                              )}
                            </div>

                            {/* <div className="col-md-12">
                              <Button
                                style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                variant="warning"
                                onClick={UpdateProfile}
                              >
                                Update
                              </Button>
                            </div> */}
                          </div>


                        </div>


                      </div>


                    </Form>
                  </div>



                  <div className="mt-2" style={{ paddingLeft: "20px" }}>
                    <h5>Bank Details</h5>
                  </div>
                  <div className="card-body-blk mobile-pd">

                    <Form>
                      <div>
                        {/* <div className="order-head-blk">
                          <h5>Tax Information</h5>
                        </div> */}

                        <div className="">
                          <div className="row">
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Bank Name</Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => setBankName(e.target.value)}
                                placeholder="Enter Bank Name "
                                value={bankName}
                                disabled={data && data.bank_name && !edit ? true : false}

                              />
                              {resError && resError.bank_name && <div className="text-danger"> {resError.bank_name} </div>}
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Account Holder Name</Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => setAccountName(e.target.value)}
                                placeholder="Enter Account Holder Name "
                                value={accountName}
                                disabled={data && data.bank_acc_name && !edit ? true : false}
                              />
                              {resError && resError.bank_acc_name && <div className="text-danger"> {resError.bank_acc_name} </div>}
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Account Number</Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => setAccountNumber(e.target.value)}
                                placeholder="Enter Account Number "
                                value={accountNumber}
                                disabled={data && data.bank_acc_no && !edit ? true : false}
                              />
                              {resError && resError.bank_acc_no && <div className="text-danger"> {resError.bank_acc_no} </div>}
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Branch Name</Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => setBranch(e.target.value)}
                                placeholder="Enter Branch Name "
                                value={branch}
                                disabled={data && data.bank_branch && !edit ? true : false}
                              />
                              {resError && resError.bank_branch && <div className="text-danger"> {resError.bank_branch} </div>}
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>IFSC Code</Form.Label>
                              <Form.Control
                                onChange={(e) => setIfsc(e.target.value.toUpperCase())}
                                type="text"
                                value={ifsc}
                                placeholder="Enter IFSC Code"
                                disabled={data && data.bank_ifsc && !edit ? true : false}
                              />
                              {resError && resError.bank_ifsc && <div className="text-danger"> {resError.bank_ifsc} </div>}
                            </div>
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Bank Swift Code</Form.Label>
                              <Form.Control
                                onChange={(e) => setSwift(e.target.value)}
                                type="text"
                                placeholder="Enter Swift Code "
                                value={swift}
                                disabled={data && data.bank_swiftcode && !edit ? true : false}
                              />
                              {resError && resError.bank_swiftcode && <div className="text-danger"> {resError.bank_swiftcode} </div>}
                            </div>
                            <div className="col-md-12">
                              <Button
                                className="mobile-margin-4"
                                style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                variant="warning"
                                onClick={UpdateTaxBank}
                              >
                                Update
                              </Button>
                            </div>
                          </div>

                        </div>
                      </div>


                    </Form>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default ManageProfile;
