import { useState } from "react";

const ProductStarRating = () => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            key={index}
            className={index <= (hover || rating) ? "on" : "off"}
            onClick={() => setRating(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="star">
              <i style={{color: '#FFB800'}} className="fa fa-star"></i>
            </span>
            {/* <span className="star">&#9733;</span> */}
          </button>
        );
      })}
    </div>
  );
};
export default ProductStarRating;





// const ProductStarRating = ({ rating }) => {
//   const getRating = () => {
//     let stars = [];
//     for (let index = 1; index <= rating; index++) {
//       stars.push(<i className="fas fa-star" key={index}></i>);
//     }
//     return stars;
//   };
//   return <div className="star-rate-show">{getRating()}</div>;
// };

// export default ProductStarRating;
