import React, { useState, useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import Dropdown from 'react-bootstrap/Dropdown';
import Slider from "react-slick";
import Form from 'react-bootstrap/Form';
import { ButtonGroup } from "react-bootstrap";
import { BASE_URL } from "../config";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-stars";
import SeoServicecategoriesSoftware from "../seo page/SeoServiceCategoriesItsoftware";
const DigitalServices = () => {
    const [subcategorydata, setsubcategorydata] = useState([]);
    const [servicesdata, setservicesdata] = useState([]);
    const navigate = useNavigate();
    const { slug } = useParams();
    useEffect(() => {
        getservices();
    }, [])
    const getservices = () => {
        axios
            .get(BASE_URL + "/getsubcategoryservice/" + slug)
            .then(({ data }) => {
                setsubcategorydata(data?.data[0].childrens);
                setservicesdata(data.services)


            })
            .catch(({ response }) => { });

    }
    const slider = [
        { id: 1 }, { id: 2 }, { id: 3 }
    ];

    const Category = [
        {
            img: '/assets/images/category-icon.png',
            name: 'For Children',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'Realistic',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'Comic & Cartoon',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'Anime & mango',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'Flat',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'Line Art',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'For Children',
        },
        {
            img: '/assets/images/category-icon.png',
            name: 'Realistic',
        }
    ]

    const Products = [
        {
            productImg: '/assets/images/product-img.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img-2.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img-3.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img-4.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img-2.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img-4.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: '/assets/images/product-img-3.png',
            sellerImg: '/assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        }
    ]


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        slidesToShow: subcategorydata && subcategorydata.length < 6 ? subcategorydata.length : 6,
        slidesToScroll: 1
    };

    return (
        <div>
            <SeoServicecategoriesSoftware/>
            <div className="menu-cont digital-services-parent">
                <div className="row services-banner">
                    <div className="col-md-6">
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            interval={2500}
                            showStatus={false}
                            showArrows={false}
                            showIndicators={false}
                            infiniteLoop={true}
                        >
                            {slider.map((data) => (
                                <div><img src="/assets/images/banner_interiordesigners 1.png" height={230} /></div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-md-6 row cards ">
                        <div className="col-md-3 col-sm-3 col-6">
                            <div className="banner-card-img"><img src="/assets/images/Frame 2.png" /></div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-6">

                            <div className="banner-card-img"><img src="/assets/images/Frame 3.png" /></div>
                        </div>
                        <div className="col-md-3  col-sm-3 col-6">

                            <div className="banner-card-img"><img src="/assets/images/Frame 4.png" /></div>
                        </div>
                        <div className="col-md-3  col-sm-3 col-6">
                            <div className="banner-card-img"><img src="/assets/images/Frame 5.png" /></div>
                        </div>
                    </div>
                </div>
                <div className="digital-category">
                    <div className="register-prof-section">
                        <h1>Select Category</h1>
                        <button>Register as a Professional</button>
                    </div>
                    <div className="houzey-mt-1 hide-in-mobile" id="our-client-slider" >
                        <Slider {...settings}>
                            {subcategorydata.map((item, index) => (
                                <div className="digi-service-card cursor-pointer container-fluid">
                                    <div className="Data-And-Form-Container">
                                        <div className="Form-Content-Container">
                                            <img src={item.icon} />
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className="overf-drpsec">
                    <div className="dropdown-section">
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                                Service Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                                Rating
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                                Budgets
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="ad-verified-btn">
                            <button >Ad Verified</button>
                        </div>
                        <div className="ad-verified-btn">
                            <button>Ad Trust</button>
                        </div>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Premium Services"
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Priority Delivery"
                        />
                        <div className="view-all-services-btn">
                            <button>View All Service Providers</button>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row">
                        {servicesdata.map((item, index) => (
                            <div className="col-md-3 digital-product-section" key={index}>
                                <div className="digital-product-section-border">
                                    <img src={item.service_image} onClick={() => navigate("/service-detail/" + item.slug)} />
                                    <div className="paddind-1">
                                        <div className="seller-section">
                                            <img src={item.user_image} height={50} width={50} onClick={() => navigate("/seller-detail/" + item.business_slug)} />
                                            <div>
                                                <h6>{item.seller_name}</h6>
                                                {/* <h5>{item.rating}</h5> */}
                                            </div>
                                        </div>
                                        <p>{item.service_name}</p>
                                        <div>
                                            <ReactStars
                                                count={5}
                                                value={item.rating}
                                                size={20}
                                                edit={false}
                                                color2={"#ffd700"}
                                            />
                                            <button>{item.rating}</button>
                                            <label>{item.total_rating}</label>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="price-section paddind-1">
                                        <div>
                                            <h4>Starting At</h4>
                                            <h3>{item?.packages[0]?.package_price}<del>{item?.packages[0]?.package_mrp}</del></h3>
                                        </div>
                                        <div>
                                            <img src="/assets/images/grey-heart.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default DigitalServices;