import { useContext } from 'react';
import ReactOwlCarousel from 'react-owl-carousel';
import {Carousel} from 'react-responsive-carousel';

import StateContext from '../../context/StateContext';

import TestimonialItem from './testimonial-item.component';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const TestimonialContainer = () => {
  const { testimonials } = useContext(StateContext);
  const testimonial_options = {
    margin: 0,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 2,
      },
    },
  };
  return (
    <>
    <Carousel autoPlay className='owl-testimonial-slider about-us-slider' {...testimonial_options}>
    {testimonials.map((testimonial) => (
        <TestimonialItem key={testimonial.id} testimonial={testimonial} />
      ))}
    </Carousel>
    {/* <ReactOwlCarousel className="owl-theme owl-testimonial-slider" {...testimonial_options}>
      {testimonials.map((testimonial) => (
        <TestimonialItem key={testimonial.id} testimonial={testimonial} />
      ))}
    </ReactOwlCarousel> */}
    </>
  );
};

export default TestimonialContainer;
