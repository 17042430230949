import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import CopyIcon from '../components/dashboard/copy.png';
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
const productsData = [
  {
    id: 1,
    name: "Black+Decker 4 Inch 820W Small Angle Grinder, G720R",
    category: "Angle Grinders",
    currentQty: 1,
    basePrice: 1523,
  },
  {
    id: 2,
    name: "Black+Decker 4 Inch 820W Small Angle Grinder, G720R",
    category: "Angle Grinders",
    currentQty: 1,
    basePrice: 1523,
  },
  {
    id: 3,
    name: "Black+Decker 4 Inch 820W Small Angle Grinder, G720R",
    category: "Angle Grinders",
    currentQty: 1,
    basePrice: 1523,
  },
  {
    id: 4,
    name: "Black+Decker 4 Inch 820W Small Angle Grinder, G720R",
    category: "Angle Grinders",
    currentQty: 1,
    basePrice: 1523,
  },
  {
    id: 5,
    name: "Black+Decker 4 Inch 820W Small Angle Grinder, G720R",
    category: "Angle Grinders",
    currentQty: 1,
    basePrice: 1523,
  },
];

const VendorProducts = () => {

  const [data, setData] = useState();
  const [dashboard, setdashboard] = useState("");
  const [page, setpage] = useState("");
  const token = localStorage.getItem('avonToken');

  useEffect(() => {
    FetchProducts();
    getdashboard();
    // DeleteProducts();
  }, [])
  const navigate = useNavigate();
  const pageclick = async (data) => {
    let currentPage = Number(data.selected) + 1;
    await axios.get(BASE_URL + '/sellorproduct?page=' + currentPage, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setData(data.data);
      setpage(data.data.last_page)
    })
  }
  const getdashboard = async () => {
    await axios.get(BASE_URL + '/getdashboard', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setdashboard(data.data)
    })
  }


  const FetchProducts = async () => {
    await axios.get(BASE_URL + '/sellorproduct', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setData(data.data)
      setpage(data.data.last_page)
    })
  }

  const DeleteProducts = async (id) => {
    await axios.get(BASE_URL + '/deletesellorproduct/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProducts();
      if (data.status == true) {
        toast.success(data.message)

      }
    })
  }

  const handlepublished = async (status, id) => {
    await axios.post(BASE_URL + '/updatepublished', { id, status }, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProducts();
      if (data.status == true) {
        toast.success(data.message)

      }
    })
  }

  const handlefeatured = async (status, id) => {
    await axios.post(BASE_URL + '/updatefeatured', { id, status }, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProducts();
      if (data.status == true) {
        toast.success(data.message)

      }
    }).catch(({ response }) => {
      if (response.data.code == 403) {
        toast.error(response.data.errors)
      }
    })
  }

  const dublicateproduct = async (id) => {
    await axios.get(BASE_URL + '/duplicateproductseller/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProducts();
      if (data.status == true) {
        toast.success(data.message)

      }
    })
  }
  const checkavailabletoadd = () => {
    if (dashboard.total_product >= dashboard.total_product_limit) {
      toast.error("You reached Maximum Limit to Add product")
    } else {
      navigate("/add-new-product")
    }
  }

  return (
    <Page title="Vendor Products">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>All Products</li>

            </ul>
            <div className="col-md-12 ">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>
                  Products
                  <Button onClick={checkavailabletoadd} style={{ float: "right", backgroundColor: '#00B987' }} variant="success">
                    Add New Product
                  </Button>

                </h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="vendor-product">
                      <h5>All Products</h5>
                      <b style={{ color: "red" }}>You added {dashboard.total_product} product out of {dashboard.total_product_limit}</b>
                      <div className="col-md-3 mobile-margin-1">
                        <Form.Control
                          type="text"
                          placeholder="Search Product"
                        />
                      </div>
                    </div>
                    <div className="card-body-blk">
                      <Table responsive="md" hover>
                        <thead>
                          <tr>
                            {/* <th>#</th> */}
                            <th>Name</th>
                            <th>Category</th>
                            <th>Current Qty</th>
                            <th>Base Price</th>
                            <th>Cart Type</th>
                            <th>Published</th>
                            {/* <th>Flash Deals</th> */}
                            <th>Featured</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.data.map((product) => (
                            <tr key={product.id}>
                              {/* <td style={{padding: '13px'}}>{product.name}</td> */}
                              <td style={{ padding: '13px' }} >
                                <a href="#">{product.name}</a>
                              </td>
                              <td style={{ padding: '13px' }}>{product.category.name}</td>
                              <td style={{ padding: '13px' }}>{product.min_qty}</td>
                              <td style={{ padding: '13px' }}>{product.unit_price + " " + product.unit}</td>
                              <td style={{ padding: '13px' }}>Cart Type</td>
                              <td style={{ padding: '13px' }}>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  style={{ fontSize: 20 }}
                                  checked={product.published == 1 ? true : false}
                                  onChange={(e) => handlepublished(e.target.checked ? 1 : 0, product.id)}
                                />

                              </td>
                              {/* <td style={{padding: '13px'}}>
                              <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  style={{ fontSize: 20 }}
                                  checked={product.flashdeals == 1 ? true : false}
                                  onChange={(e)=>handleflashdeals(e.target.checked ? 1 : 0,product.id )}
                              />
                              
                              </td> */}
                              <td style={{ padding: '13px' }}>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  style={{ fontSize: 20 }}
                                  checked={product.featured == 1 ? true : false}
                                  onChange={(e) => handlefeatured(e.target.checked ? 1 : 0, product.id)}
                                />
                              </td>
                              <td>
                                <Link to={"/edit-product/" + product.id} state={product.id}><img src={EditIcon} alt="" /> </Link>
                                <img style={{ paddingLeft: '5px' }} onClick={() => dublicateproduct(product.id)} src={CopyIcon} alt="" />
                                <img style={{ paddingLeft: '5px' }} onClick={() => DeleteProducts(product.id)} src={DeleteIcon} alt="" />
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </Table>
                      <div className='mt-3'>
                        <ReactPaginate
                          previousLabel={"previous"}
                          nextLabel={"next"}
                          breakLabel={"..."}
                          pageCount={page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={3}
                          onPageChange={pageclick}
                          containerClassName={"pagination"}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                        />
                      </div>
                    </div>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorProducts;
