import React, { useEffect, useState } from "react";
import "../../style/Startup.css"
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../../config";
import truncHtml from "trunc-html";
import { Link } from "react-router-dom";



export const AllStartup = () => {
    const [companyData, setCompanyData] = useState([])
    const [readmore, setreadmore] = useState("")


    const startupData = async () => {
        try {
            const compData = await axios.get(BASE_URL + "/startup");
            setCompanyData(compData.data.data)
        }
        catch (err) {
            console.log(err)
        }

    }

    // console.log("startups:",companyData)

    useEffect(() => {
        startupData()
    }, [])

    return (
        <>
            <div className="startup-container my-lg-5 my-md-4 my-3 ">

                {/* Search Filter   */}
                <div className="overf-drpsec ">
                    <div className="search-filter d-flex justify-content-between align-items-center  px-lg-5 px-md-4 px-3 py-lg-4 py-md-4 py-3 my-4">
                        {/* left   */}
                        <div className="left d-flex flex-wrap gap-3  align-items-center justify-content-between">
                            <input type="text" placeholder="Search Startup" className="px-3 py-2" />
                            {/* <p className="mb-0"><strong>or</strong></p> */}
                            <p className="mb-0 ml-3"> <strong>Filter By</strong></p>
                            <select class="form-select border-0 ml-3" aria-label="Default select example">
                                <option selected>Category</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                            <select class="form-select border-0 ml-3" aria-label="Default select example">
                                <option selected>Established Year</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                            <select class="form-select border-0 ml-3" aria-label="Default select example">
                                <option selected>Market Valuation</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>

                        {/* right   */}
                        <div className="right ">
                            <select class="form-select border-0" aria-label="Default select example">
                                <option selected>Sort By</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>

                    </div>
                </div>

                {/* Startup Details  */}

                <div className="startup-details container my-5">
                    {/* capany 1  */}
                    {companyData.map((elem, index) => (

                        <div className="company-content d-flex gap-3 my-3 px-3 py-3 bg-white rounded shadow-sm" key={index}>
                            {/* left  */}
                            <div className="left-img">
                                <img src={IMAGE_BASE_URL + elem.company_banner} alt="" width="300px" />
                            </div>

                            {/* right */}
                            <div className="right-content w-100 ml-3">
                                <div className="title-btns d-flex justify-content-between mb-lg-4 mb-md-3 mb-2">
                                    <div className="title-cat">
                                        <h3>{elem.startup_name}</h3>
                                        <div className="d-flex gap-2 aign-items-center ">
                                            {elem.business_category.map((cat, i) => (
                                                <button className="mr-2" key={i}>{cat.category_name}</button>
                                            ))}
                                            {/* <button>Finance</button>
                                <button>Startup</button> */}
                                        </div>
                                    </div>

                                    <div className="d-flex gap-lg-3 gap-2" gap-3>
                                        {elem.pitchdeck && (
                                            <a href={IMAGE_BASE_URL + elem.pitchdeck}>Pich Deek</a>
                                        )}

                                        <Link to={"/startup-detail/" + elem.redirect}>View Details</Link>
                                    </div>
                                </div>

                                <p> <div dangerouslySetInnerHTML={{ __html: readmore === index ? elem.about_startup : truncHtml(elem.about_startup, 100).html }}></div>{elem?.about_startup.length > 100 && (<Link to="#" onClick={() => setreadmore(readmore === index ? "" : index)}>{readmore === index ? "Read Less" : "Read More"}</Link>)}</p>

                                <div className="right-bottom d-flex flex-wrap justify-content-between">
                                    <div className="d-flex flex-wrap gap-lg-2 gap-md-2 gap-2">
                                        <div className="establish mr-2">
                                            <h5>Established Year</h5>
                                            <p className="m-0">{elem.establish_year}</p>
                                        </div>
                                        <div className="valuation ">
                                            <h5>Market Valuation</h5>
                                            <p className="m-0">$ {elem.market_valuation}</p>
                                        </div>

                                        <div className="revenue ">
                                            <h5>Revenue</h5>
                                            <p className="m-0">$1{elem.revenue}</p>
                                        </div>
                                        <div className="net-profit ">
                                            <h5>Net Profit</h5>
                                            <p className="m-0">${elem.net_profit}</p>
                                        </div>
                                    </div>
                                    <div>
                                        {elem.user_id && (
                                            <a href="" className="claim-listing">Claim Listing</a>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}


                    {/* capany 2  */}
                    {/* <div className="company-content d-flex gap-3 my-3 px-3 py-3 bg-white rounded">
                
                <div className="left-img">
                    <img src="/assets/images/company-img.png" alt="" width="300px" />
                </div>

                
                <div className="right-content w-100">
                    <div className="title-btns d-flex justify-content-between mb-lg-4 mb-md-3 mb-2">
                        <div className="title-cat">
                            <h3>Web Mingo IT Solutions Private Limited</h3>
                            <div className="d-flex gap-2 aign-items-center">
                                <button>IT Industry</button>
                                <button>Finance</button>
                                <button>Startup</button>
                            </div>
                        </div>

                        <div className="d-flex gap-lg-3 gap-2" gap-3>
                            <a href="">Pich Deek</a>
                            <a href="">View Details</a>
                        </div>
                    </div>

                    <p> Adipisci, Lorem ipsum dolor  ipsum dolor sit amet consectetur adipisicing elit. Qui cupiditate, atque quia voluptatem maiores voluptates. Similique repellat corrupti Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nihil velit iure repudiandae! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ratione ducimus aperiam ad!officiis doloremque. Cumque, non. quisquam... <a href="">Read More</a></p>

                    <div className="right-bottom d-flex flex-wrap justify-content-between">
                        <div className="d-flex flex-wrap gap-lg-4 gap-md-3 gap-2">
                            <div className="establish">
                                <h5>Established Yea</h5>
                                <p>2021</p>
                            </div>
                            <div className="valuation">
                                <h5>Market Valuation</h5>
                                <p>$1,234,515.0</p>
                            </div>

                            <div className="revenue">
                                <h5>Revenue</h5>
                                <p>$1,234,515.00</p>
                            </div>
                            <div className="net-profit">
                                <h5>Net Profit</h5>
                                <p>$1,234,515.00</p>
                            </div>
                        </div>
                        <div>
                            <a href="" className="claim-listing">Claim Listing</a>
                        </div>
                    </div>
                </div>
            </div> */}





                </div>
            </div>
        </>
    );
};
