import React, { useState, useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BASE_URL } from "../../../config";
import axios from "axios";
import { Link } from "react-router-dom";
const HomeCategories = () => {
    const [Categories, setCategories] = useState([]);

    useEffect(() => {
        FetchCategories();
    }, [])


    const FetchCategories = async () => {
        await axios.get(BASE_URL + "/getcategoriesbytype/products-page")
            .then(({ data }) => {
                setCategories(data.data);

            });
    };
    const recent_options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        loop: true,
        responsive: {
            0: {
                items: 3,
            },
            460: {
                items: 4.2,
            },
            575: {
                items: 3.8,
            },
            768: {
                items: 5,
            },
            1080: {
                items: 6.3,
            },
            1200: {
                items: 8,
            },
        },
    };
    // const Categories = [
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     },
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     },
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     },
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     },
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     },
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     },
    //     {
    //         name: 'categories 1',
    //         cate1: "/assets/images/cate-1.svg"
    //     }
    // ]

    return (
        <>
            <div className="container mt-3">
                <div className="slider-section categories-slider product-categories">
                    <h6 className="cate-head mb-4 heading-bline position-relative">Categories</h6>
                    <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
                        {Categories.map((product) => (
                            <>
                                <Link to={"/category/"+product.slug}><img src={product.icon} /><h5>{ product.name}</h5></Link>


                            </>


                        ))}
                    </ReactOwlCarousel>
                </div>
            </div>
        </>
    )
}

export default HomeCategories;