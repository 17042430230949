import axios from 'axios';
import React, { useState,useEffect } from 'react';
import CountUp from 'react-countup';
import { BASE_URL } from '../../../config';

const ThirdSection = () => {
const[allwidget, setAllwidget] = useState([])


const widgetData = async () => {
    try{
        const wgtData = await axios.get(BASE_URL +  "/homepagewidget");
        setAllwidget(wgtData.data.data)
    }catch (err) {
        console.log(err);
    }
}

useEffect(() => {
    widgetData();
  }, []);

//   console.log(allwidget, "section three");

    return (
        <>
            <div className='container mt-5 mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget4}} >
                {/* <div className='third-section'>
                    <div>
                        <h2>Explore millions of Products & Services offering for your needs</h2>
                    </div>
                    <div>
                        <div className='d-flex justify-content-between'>
                            <div className='counter-part w-50'>
                                <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                <p>Products</p>
                            </div>
                            <div className='counter-part w-50'>
                                <CountUp delay={5} end={20} suffix="K+" />
                                <p>Services</p>
                            </div>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='counter-part w-50'>
                                <CountUp delay={5} end={500} suffix="+" />
                                <p>Sellers already Registered</p>
                            </div>
                            <div className='counter-part w-50'>
                                <CountUp delay={5} end={100} suffix="K+" />
                                <p>Customer already viewed</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default ThirdSection;