import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { Table, Badge, Form, Button } from "react-bootstrap";
import LeftCheckIcon from "../components/deal/leftcheckicon.svg";
import axios from "axios";
import { BASE_URL } from '../config'

const ProductComparison = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('avonToken')
    const [packagesData, setPackagesData] = useState([])

    useEffect(() => {
        FetchPackages();
    }, [])

    const FetchPackages = async () => {
        await axios.get(BASE_URL + '/package').then(({ data }) => {
            setPackagesData(data.data)
        });
    }

    const userredirect = (id) => {
        if (token) {
            navigate('/vendor-checkout', ({ state: id }))
        } else {

        }
    }

    const comparisonData = [
        {
            productImage: '/assets/images/image-6.png',
            name: "iphone 6",
            color: "red",
            price: "10000",
            discounted: "15000",
            shortDisp: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu justo dui. Etiam finibus diam a fringilla sodales."
        },
        {
            productImage: '/assets/images/image-6.png',
            name: "iphone 6",
            color: "red",
            price: "10000",
            discounted: "15000",
            shortDisp: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu justo dui. Etiam finibus diam a fringilla sodales."
        },
        {
            productImage: '/assets/images/image-6.png',
            name: "iphone 6",
            color: "red",
            price: "10000",
            discounted: "15000",
            shortDisp: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu justo dui. Etiam finibus diam a fringilla sodales."
        }
    ]

    return (
        <Page title="Packages">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Compare Products</li>
                        </ul>
                    </div>
                </section>

                <section>
                    <div className="container-fluid">
                        <div className="row">
                            {/* <VendorDashboardSideMenu /> */}
                            <div className="col-md-12">
                                <div className="vendor-package-section">
                                    <div className="package-card-block">
                                        <h4 className="text-center form-mt-2">
                                            Compare Products
                                        </h4>
                                        {/* <p className="text-center text-muted">
                      Choose a seller plan to launch your new business growth
                    </p> */}
                                        <div className="package-table form-mt-3">
                                            <Table responsive="md" bordered>
                                                <thead>
                                                    <tr style={{ backgroundColor: "#fff" }}>
                                                        <th style={{ fontWeight: 500 }} className="package-table-cell">
                                                            <h3>My Product</h3>
                                                        </th>
                                                        {comparisonData && comparisonData.map((item) => (
                                                            <th>
                                                                <div style={{ border: "1px solid #EEEEEE" }} className="">
                                                                    <h4 style={{ paddingTop: "25px" }} className="text-center">
                                                                        {item.name}
                                                                    </h4>
                                                                    <img src={item.productImage} />
                                                                    <p className="package-short-desc">{item.short_description}</p>
                                                                    <h3 className="text-center">Rs. {item.price}<del>Rs. {item.discounted}</del></h3>

                                                                    <h6 style={{ textDecoration: 'line-through', color: 'red' }} className="text-center"></h6>
                                                                    <p style={{ padding: "10px" }}>{item.shortDisp}</p>
                                                                </div>
                                                            </th>
                                                        ))}

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                                                        <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">Product Detail</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ border: 'none' }}></td>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td className="package-table-cell" >Reviews</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center', fontWeight: 500 }}>Lorem ipsum dolor Days</td>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td className="package-table-cell">Delivery</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center', fontWeight: 500 }}>Lorem ipsum dolor</td>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td className="package-table-cell">Seller</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center', fontWeight: 500 }}>Lorem ipsum dolor</td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td className="package-table-cell">Variants</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center', fontWeight: 500 }}>Lorem ipsum dolor</td>
                                                        ))}
                                                    </tr>
                                                    <tr style={{ border: 'none', backgroundColor: "#EEEEEE" }}>
                                                        <td style={{ border: 'none', fontWeight: 600 }} className="package-table-cell">General Features</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ border: 'none' }}></td>
                                                        ))}
                                                    </tr>

                                                    <tr>
                                                        <td className="package-table-cell">Type</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center' }}>
                                                                {/* {item.graphic_designing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />} */}
                                                                Lorem ipsum dolor
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr >
                                                        <td className="package-table-cell">Color</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center' }}>
                                                                {/* {item.content_writing == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />} */}
                                                                Lorem ipsum dolor
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr >
                                                        <td className="package-table-cell">Ideal For</td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center' }}>
                                                                {/* {item.professional_website == 'Yes' ? <img src="/assets/images/tick.png" alt="Yes" /> : <img src="/assets/images/cross.png" alt="No" />} */}
                                                                Lorem ipsum dolor
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr style={{ borderBottom: '1px solid black' }}>
                                                        <td className="package-table-cell"></td>
                                                        {packagesData && packagesData.map((item) => (
                                                            <td style={{ textAlign: 'center' }}>
                                                                <Link to={token ? '/vendor-checkout' : '/login'} state={item.id}>
                                                                    <Button
                                                                        className="package-details-button"
                                                                        style={{
                                                                            borderRadius: 0,
                                                                            backgroundColor: "#0085FF",
                                                                        }}
                                                                    >
                                                                        SELECT & PAY
                                                                    </Button>
                                                                </Link>
                                                            </td>
                                                        ))}
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        {/* <div className="package-table">
                      <Table responsive="md" bordered>
                        <thead>
                          <tr style={{ backgroundColor: "#EEEEEE" }}>
                            <th style={{ fontWeight: 500 }}>
                              Customer Service
                            </th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Customer Service</td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                            <td style={{ paddingLeft: "20px" }}>
                              Dedicated Account Manager
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>{" "}
                            <td>
                              <div style={{ margin: "10px 0 0 40px" }}>
                                <Button
                                  style={{
                                    borderRadius: 0,
                                    backgroundColor: "#0085FF",
                                  }}
                                >
                                  SELECT & PAY
                                </Button>
                              </div>
                              <div
                                style={{ padding: "10px 0 10px 0" }}
                                className="text-center"
                              >
                                <a href="#" className="text-center">
                                  Get More Information
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr></tr>
                          <tr></tr>
                        </tbody>
                      </Table>
                    </div> */}
                                        <p style={{ padding: '8px 0 30px 0' }} className="text-center">
                                            *Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Donec eu justo dui. Etiam finibus diam a fringilla
                                            sodales.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Page>
    );
};

export default ProductComparison;
