import React from "react";
import "../../style/Faqsellerbuyer.css";
export const Faqbanner = () => {
  return (
    <>
      <div className="faq-sb-banner">
        <div className="container">
        <h5>ApnaShaher Help Center | 24x7 Customer Care Support</h5>
        </div>
        <div className="banner-bg-img">
          {/* <h3>Customer FAQ Need more help contact our Support team</h3> */}
        </div>
      </div>
    </>
  );
};
