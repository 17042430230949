import React from 'react';
import { Link } from 'react-router-dom';

export const JotBanner = () => {
  return (
    <>
    <div className="jot-banner my-lg-5 my-md-4 my-3 mx-lg-5 mx-md-4 mx-3">
        <div className="m-content positon-relative">
            <Link to="#"><button >See Current Opening</button></Link>
        </div>

    </div>
    
    </>
  )
}
