import React from "react";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { Form } from "react-bootstrap";

const AddNewService = () => {
    return (
        <>
            <section>
                <div className="container-fluid">
                    <div className="row">
                        <VendorDashboardSideMenu
                        // PurchaseHis={status}
                        />
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <h4>Add New Service</h4>

                            <div className="add-new-services">
                                <h4>Add New Service</h4>
                                <hr />
                                <div className="add-new-form-pd">
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Control type="text" placeholder="Service Name" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Slug URL</Form.Label>
                                        <Form.Control type="text" placeholder="Slug URL" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Tittle</Form.Label>
                                        <Form.Control type="text" placeholder="Service Tittle" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Active</option>
                                            <option value="1">Offline</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Short Discription</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                </div>
                            </div>

                            {/* Product Image */}
                            <div className="add-new-services" style={{ marginTop: "15px" }}>
                                <h4>Product Images</h4>
                                <hr />
                                <div className="add-new-form-pd">
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Image</Form.Label>
                                        <Form.Control type="file" placeholder="Service Name" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Gallary</Form.Label>
                                        <Form.Control type="file" placeholder="Service Name" />
                                    </div>
                                </div>
                            </div>

                            {/* Add Service Type & their Details */}

                            <div className="add-new-services" style={{ marginTop: "15px" }}>
                                <h4>Add Service Type & their Details</h4>
                                <hr />
                                <div className="add-new-form-pd">
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Basic</option>
                                            <option value="1">Standard</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Enter Price</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Price" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Active</option>
                                            <option value="1">Offline</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Offer</Form.Label>
                                        <Form.Control type="text" placeholder="Save up to 20% with Subscribe to Save" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Number of words</Form.Label>
                                        <Form.Control type="text" placeholder="Number of words" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Delivery Time</Form.Label>
                                        <Form.Control type="text" placeholder="4 days" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>One Day Delivery Charge</Form.Label>
                                        <Form.Control type="text" placeholder="$7" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Short Discription</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>What's Included</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                </div>
                            </div>
                            <div className="add-new-services" style={{ marginTop: "15px" }}>
                                <h4>Add Service Type & their Details</h4>
                                <hr />
                                <div className="add-new-form-pd">
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Basic</option>
                                            <option value="1">Standard</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Enter Price</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Price" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Active</option>
                                            <option value="1">Offline</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Offer</Form.Label>
                                        <Form.Control type="text" placeholder="Save up to 20% with Subscribe to Save" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Number of words</Form.Label>
                                        <Form.Control type="text" placeholder="Number of words" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Delivery Time</Form.Label>
                                        <Form.Control type="text" placeholder="4 days" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>One Day Delivery Charge</Form.Label>
                                        <Form.Control type="text" placeholder="$7" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Short Discription</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>What's Included</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                </div>
                            </div>
                            <div className="add-new-services" style={{ marginTop: "15px" }}>
                                <h4>Add Service Type & their Details</h4>
                                <hr />
                                <div className="add-new-form-pd">
                                    <div className="services-form-inputs">
                                        <Form.Label>Service Name</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Basic</option>
                                            <option value="1">Standard</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Enter Price</Form.Label>
                                        <Form.Control type="text" placeholder="Enter Price" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select aria-label="Default select example">
                                            <option>Active</option>
                                            <option value="1">Offline</option>
                                        </Form.Select>
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Offer</Form.Label>
                                        <Form.Control type="text" placeholder="Save up to 20% with Subscribe to Save" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Number of words</Form.Label>
                                        <Form.Control type="text" placeholder="Number of words" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Delivery Time</Form.Label>
                                        <Form.Control type="text" placeholder="4 days" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>One Day Delivery Charge</Form.Label>
                                        <Form.Control type="text" placeholder="$7" />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>Short Discription</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                    <div className="services-form-inputs">
                                        <Form.Label>What's Included</Form.Label>
                                        <Form.Control as="textarea" rows={3} />
                                    </div>
                                </div>
                            </div>


                            <div className="add-new-services" style={{ marginTop: "15px", padding: 10, marginBottom:100 }}>
                                <div className="row">
                                    <div className="col-md-4">Features</div>
                                    <div className="col-md-2">Basic</div>
                                    <div className="col-md-2">Standard</div>
                                    <div className="col-md-2">Premium</div>
                                    <div className="col-md-3"></div>
                                </div>
                                <hr />
                                <div className="row ">
                                    <div className="col-md-4"><input className="featured-input" /></div>
                                    <div className="col-md-2">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            style={{ fontSize: 20 }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            style={{ fontSize: 20 }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            style={{ fontSize: 20 }}
                                        />
                                    </div>
                                    <div className="col-md-2">
                                        Add New
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddNewService;