import React from 'react';
import { Link } from 'react-router-dom';

const ServicesAdds = () => {
    return (
        <>
            <div className='container  mt-4 mb-lg-5 mb-4'>
                <div className="row">
                    <div className="col-md-6">
                        <div className="elec-cos-blk" style={{ backgroundImage: `url(${"/assets/images/adds-1.png"})`}}>
                            <div className="elctro-blk">
                                <h4>Professional</h4>
                                <p>Starting At <span>$125.001</span></p>
                                <Link className='btn btn-shop-white' to="#">View All Products</Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="elec-cos-blk" style={{ backgroundImage: `url(${"/assets/images/adds-2.png"})`}}>
                            <div className="elctro-blk cosmatic-blk">
                                <h4>Digital</h4>
                                <p>Starting At <span>$125.001</span></p>
                                <Link className='btn btn-shop-white' to="#">View All Products</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ServicesAdds;