import React from 'react'
import { useNavigate } from 'react-router-dom'

export const ServicecatComp = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="servicecat-comp my-lg-4 my-3" >
                
                <div className="cards d-flex gap-2 flex-wrap px-lg-4 px-md-3 px-md-3 px-2 justify-content-center ">
                    
                    {props?.data?.map((item,k)=>(
                         <div class="card " key={k} onClick={()=>navigate("/service-categorys/"+item.slug)}>
                         <div className="cat-logo d-flex align-items-center justify-content-center">
                         <img src={item.icon} class="card-img-toxp" alt="..." />
                         </div>
                             <div class="card-body  py-1">
                                 <h5 class="card-title">{item.name}</h5>
                             </div>
                     </div>
                    ))}
                   
                    
                
                </div>
            </div>

        </>
    )
}
