import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import ProductGridItem from '../components/product/product-grid-item.component';
import axios from 'axios';
import { BASE_URL } from '../config';
import { useContext } from 'react';
import StateContext from '../context/StateContext';

const Wishlist = () => {
    const [products, setProducts] = useState([])
    const { deviceid } = useContext(StateContext);
    useEffect(() => {
        getWishListData();
    }, [])

    const getWishListData = () => {
        const device_id = {
            device_id: deviceid,
        }
        axios.post(`${BASE_URL}/wishlist-unauth`, device_id)
            .then(function (res) {
                setProducts(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    }

    
    return (
        <>
            <div>
                <div className="row">
                    <div className="card-block">
                        <div className="card-head-blk">
                            <h4>My WishList</h4>
                        </div>
                        <div className="card-body-blk">
                            <div className="shop-product-blk">
                                <Row>
                                    {products?.wishlisted_products?.map((product) => (
                                        <ProductGridItem key={product.id} product={product} wishlist={true} setProducts={setProducts} />
                                    ))}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Wishlist;