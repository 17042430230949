import React, { useEffect } from 'react'
import "../style/JoinOurTeam.css"
import { JotCom2 } from './join-our-team/JotCom2'
import { CurrOpening } from './join-our-team/CurrOpening'
import { JotBanner } from './join-our-team/JotBanner'
import SeoJoinourteam from '../seo page/SeoJoinourteam'

export const JoinOurTeam = () => {

  useEffect(() => {
      window.scrollTo(0,0)
  },[])
  return (
    <>
    <SeoJoinourteam/>
    <div className="join-our-team-parent">
      <section>
        <JotBanner/>
      </section>
    <section>
      <JotCom2/>
    </section>

    <section>
      <CurrOpening/>
    </section>

    </div>
    </>
  )
}
