import React, { useState } from 'react'
import "../style/NewLeads.css"
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";

export const LeadPurchageHistory = () => {
    const [togglePopup, setTogglePopup] = useState(false)
    const [actBtn, setActBtn] = useState(1);
    const [currTab, setCurrtab] = useState("recent-purchaged")

    const chageTab = (btnId, comp) => {
        setActBtn(btnId);
        setCurrtab(comp)
    }

    const isActive = (btnId) => {
        return actBtn === btnId ? 'active-tbtn' : '';
    };

    return (
        <>
            <div className="new-leads nleadpurch-history py-4" style={{ background: "#0000001a" }}>

                <div className="main-cont d-flex justify-content-between">

                    <VendorDashboardSideMenu />


                    {/* rght side Cont  */}
                    <div className="right px-lg-5 px-md-4 px-3 col-md-9" >
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <h2>Leads Purchase History</h2>
                            <h2> January 2023</h2>
                        </div>


                        <div className="art-1 my-lg-5  my-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6>Purchase History</h6>
                                <h6>Subscription Expiring:  <span>December 31st 2023</span></h6>
                            </div>

                            <div className=" cards d-flex justify-content-between align-items-center">
                                <div>
                                    <h5>Lead Purchased</h5>
                                    <span>30</span>
                                </div>

                                <div>
                                    <h5>Package Leads</h5>
                                    <span> 150/130</span>
                                </div>
                                <div>
                                    <h5>Total Leads</h5>
                                    <span>170</span>
                                </div>
                                <div>
                                    <h5>Subscription</h5>
                                    <span>Bronze</span>
                                </div>

                            </div>
                        </div>

                        <div className="art-2 my-lg-5 my-4">
                            <div className='tcontent d-flex justify-content-between align-items-center'>
                                <div className="d-flex gap-lg-3 gap-md-3 gap-2 align-items-center">
                                    <p>From</p>
                                    <input type="date" />
                                    <p>To</p>
                                    <input type="date" />
                                </div>
                                <div>
                                    <button>Export</button>
                                </div>
                            </div>
                            <div className="overf-div">
                                <div className="table-div">
                                    <div className="tab-btns d-flex gap-1">
                                        <button className={` ${isActive(1)}`} onClick={() => chageTab(1, "recent-purchaged")}>Recently Purchased</button>
                                        <button className={`${isActive(2)} `} onClick={() => chageTab(2, "followups")}>Followups</button>
                                        <button className={`${isActive(3)} `} onClick={() => chageTab(3, "rejected")}>Rejected</button>
                                        <button className={`${isActive(4)} `} onClick={() => chageTab(4, "closed")}>Closed</button>
                                    </div>
                                    {currTab === "recent-purchaged" ?

                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date  & Time</th>
                                                    <th scope="col">Lead ID</th>
                                                    <th scope="col" >Customer Name</th>
                                                    <th scope="col">Business Category</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col">Email id</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>

                                                    <td>2021-09-28 00:45:08</td>
                                                    <td >LID45446</td>
                                                    <td>John Lex</td>
                                                    <td>Social Media</td>
                                                    <td>90-0000-000</td>
                                                    <td>user@gmail.com</td>
                                                    <td>Delhi</td>
                                                    <td className='status'><span>Active</span></td>




                                                    <td className='action d-flex'>
                                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                            <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                            <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                        </svg></button>

                                                        <button onClick={() => setTogglePopup(true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_33_1159)">
                                                                    <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                    <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_33_1159">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>

                                                    <td>2021-09-28 00:45:08</td>
                                                    <td >LID45446</td>
                                                    <td>John Lex</td>
                                                    <td>Social Media</td>
                                                    <td>90-0000-000</td>
                                                    <td>user@gmail.com</td>
                                                    <td>Delhi</td>
                                                    <td className='status'><span>Active</span></td>




                                                    <td className='action d-flex'>
                                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                            <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                            <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                        </svg></button>

                                                        <button onClick={() => setTogglePopup(true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_33_1159)">
                                                                    <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                    <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_33_1159">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>

                                                    <td>2021-09-28 00:45:08</td>
                                                    <td >LID45446</td>
                                                    <td>John Lex</td>
                                                    <td>Social Media</td>
                                                    <td>90-0000-000</td>
                                                    <td>user@gmail.com</td>
                                                    <td>Delhi</td>
                                                    <td className='status'><span>Active</span></td>




                                                    <td className='action d-flex'>
                                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                            <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                            <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                        </svg></button>

                                                        <button onClick={() => setTogglePopup(true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_33_1159)">
                                                                    <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                    <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_33_1159">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>

                                                    <td>2021-09-28 00:45:08</td>
                                                    <td >LID45446</td>
                                                    <td>John Lex</td>
                                                    <td>Social Media</td>
                                                    <td>90-0000-000</td>
                                                    <td>user@gmail.com</td>
                                                    <td>Delhi</td>
                                                    <td className='status'><span>Active</span></td>




                                                    <td className='action d-flex'>
                                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                            <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                            <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                        </svg></button>

                                                        <button>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_33_1159)">
                                                                    <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                    <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_33_1159">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>

                                                    <td>2021-09-28 00:45:08</td>
                                                    <td >LID45446</td>
                                                    <td>John Lex</td>
                                                    <td>Social Media</td>
                                                    <td>90-0000-000</td>
                                                    <td>user@gmail.com</td>
                                                    <td>Delhi</td>
                                                    <td className='status'><span>Active</span></td>




                                                    <td className='action d-flex'>
                                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                            <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                            <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                        </svg></button>

                                                        <button onClick={() => setTogglePopup(true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_33_1159)">
                                                                    <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                    <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_33_1159">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                                <tr>

                                                    <td>2021-09-28 00:45:08</td>
                                                    <td >LID45446</td>
                                                    <td>John Lex</td>
                                                    <td>Social Media</td>
                                                    <td>90-0000-000</td>
                                                    <td>user@gmail.com</td>
                                                    <td>Delhi</td>
                                                    <td className='status'><span>Active</span></td>




                                                    <td className='action d-flex'>
                                                        <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                            <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                            <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                            <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                        </svg></button>

                                                        <button onClick={() => setTogglePopup(true)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <g clip-path="url(#clip0_33_1159)">
                                                                    <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                    <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_33_1159">
                                                                        <rect width="16" height="16" fill="white" />
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>





                                            </tbody>
                                        </table> :
                                        currTab === "followups" ?
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date  & Time</th>
                                                        <th scope="col">Lead ID</th>
                                                        <th scope="col" >Customer Name</th>
                                                        <th scope="col">Business Category</th>
                                                        <th scope="col">Mobile Number</th>
                                                        <th scope="col">Email id</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>

                                                        <td>2021-09-28 00:45:08</td>
                                                        <td >LID45446</td>
                                                        <td>John Lex</td>
                                                        <td>Social Media</td>
                                                        <td>90-0000-000</td>
                                                        <td>user@gmail.com</td>
                                                        <td>Delhi</td>
                                                        <td className='status'><span>Active</span></td>




                                                        <td className='action d-flex'>
                                                            <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                            </svg></button>

                                                            <button onClick={() => setTogglePopup(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_33_1159)">
                                                                        <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                        <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_1159">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td>2021-09-28 00:45:08</td>
                                                        <td >LID45446</td>
                                                        <td>John Lex</td>
                                                        <td>Social Media</td>
                                                        <td>90-0000-000</td>
                                                        <td>user@gmail.com</td>
                                                        <td>Delhi</td>
                                                        <td className='status'><span>Active</span></td>




                                                        <td className='action d-flex'>
                                                            <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                            </svg></button>

                                                            <button onClick={() => setTogglePopup(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_33_1159)">
                                                                        <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                        <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_1159">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td>2021-09-28 00:45:08</td>
                                                        <td >LID45446</td>
                                                        <td>John Lex</td>
                                                        <td>Social Media</td>
                                                        <td>90-0000-000</td>
                                                        <td>user@gmail.com</td>
                                                        <td>Delhi</td>
                                                        <td className='status'><span>Active</span></td>




                                                        <td className='action d-flex'>
                                                            <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                            </svg></button>

                                                            <button onClick={() => setTogglePopup(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_33_1159)">
                                                                        <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                        <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_1159">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td>2021-09-28 00:45:08</td>
                                                        <td >LID45446</td>
                                                        <td>John Lex</td>
                                                        <td>Social Media</td>
                                                        <td>90-0000-000</td>
                                                        <td>user@gmail.com</td>
                                                        <td>Delhi</td>
                                                        <td className='status'><span>Active</span></td>




                                                        <td className='action d-flex'>
                                                            <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                            </svg></button>

                                                            <button>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_33_1159)">
                                                                        <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                        <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_1159">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td>2021-09-28 00:45:08</td>
                                                        <td >LID45446</td>
                                                        <td>John Lex</td>
                                                        <td>Social Media</td>
                                                        <td>90-0000-000</td>
                                                        <td>user@gmail.com</td>
                                                        <td>Delhi</td>
                                                        <td className='status'><span>Active</span></td>




                                                        <td className='action d-flex'>
                                                            <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                            </svg></button>

                                                            <button onClick={() => setTogglePopup(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_33_1159)">
                                                                        <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                        <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_1159">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr>

                                                        <td>2021-09-28 00:45:08</td>
                                                        <td >LID45446</td>
                                                        <td>John Lex</td>
                                                        <td>Social Media</td>
                                                        <td>90-0000-000</td>
                                                        <td>user@gmail.com</td>
                                                        <td>Delhi</td>
                                                        <td className='status'><span>Active</span></td>




                                                        <td className='action d-flex'>
                                                            <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                            </svg></button>

                                                            <button onClick={() => setTogglePopup(true)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                    <g clip-path="url(#clip0_33_1159)">
                                                                        <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                        <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_33_1159">
                                                                            <rect width="16" height="16" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                        </td>
                                                    </tr>





                                                </tbody>
                                            </table>
                                            : currTab === "rejected" ?
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Date  & Time</th>
                                                            <th scope="col">Lead ID</th>
                                                            <th scope="col" >Customer Name</th>
                                                            <th scope="col">Business Category</th>
                                                            <th scope="col">Mobile Number</th>
                                                            <th scope="col">Email id</th>
                                                            <th scope="col">Location</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>

                                                            <td>2021-09-28 00:45:08</td>
                                                            <td >LID45446</td>
                                                            <td>John Lex</td>
                                                            <td>Social Media</td>
                                                            <td>90-0000-000</td>
                                                            <td>user@gmail.com</td>
                                                            <td>Delhi</td>
                                                            <td className='status'><span>Active</span></td>

                                                            <td className='action d-flex'>
                                                                <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                </svg></button>

                                                                <button onClick={() => setTogglePopup(true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <g clip-path="url(#clip0_33_1159)">
                                                                            <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                            <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_33_1159">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>2021-09-28 00:45:08</td>
                                                            <td >LID45446</td>
                                                            <td>John Lex</td>
                                                            <td>Social Media</td>
                                                            <td>90-0000-000</td>
                                                            <td>user@gmail.com</td>
                                                            <td>Delhi</td>
                                                            <td className='status'><span>Active</span></td>




                                                            <td className='action d-flex'>
                                                                <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                </svg></button>

                                                                <button onClick={() => setTogglePopup(true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <g clip-path="url(#clip0_33_1159)">
                                                                            <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                            <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_33_1159">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>2021-09-28 00:45:08</td>
                                                            <td >LID45446</td>
                                                            <td>John Lex</td>
                                                            <td>Social Media</td>
                                                            <td>90-0000-000</td>
                                                            <td>user@gmail.com</td>
                                                            <td>Delhi</td>
                                                            <td className='status'><span>Active</span></td>




                                                            <td className='action d-flex'>
                                                                <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                </svg></button>

                                                                <button onClick={() => setTogglePopup(true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <g clip-path="url(#clip0_33_1159)">
                                                                            <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                            <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_33_1159">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>2021-09-28 00:45:08</td>
                                                            <td >LID45446</td>
                                                            <td>John Lex</td>
                                                            <td>Social Media</td>
                                                            <td>90-0000-000</td>
                                                            <td>user@gmail.com</td>
                                                            <td>Delhi</td>
                                                            <td className='status'><span>Active</span></td>




                                                            <td className='action d-flex'>
                                                                <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                </svg></button>

                                                                <button>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <g clip-path="url(#clip0_33_1159)">
                                                                            <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                            <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_33_1159">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>2021-09-28 00:45:08</td>
                                                            <td >LID45446</td>
                                                            <td>John Lex</td>
                                                            <td>Social Media</td>
                                                            <td>90-0000-000</td>
                                                            <td>user@gmail.com</td>
                                                            <td>Delhi</td>
                                                            <td className='status'><span>Active</span></td>




                                                            <td className='action d-flex'>
                                                                <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                </svg></button>

                                                                <button onClick={() => setTogglePopup(true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <g clip-path="url(#clip0_33_1159)">
                                                                            <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                            <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_33_1159">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                        <tr>

                                                            <td>2021-09-28 00:45:08</td>
                                                            <td >LID45446</td>
                                                            <td>John Lex</td>
                                                            <td>Social Media</td>
                                                            <td>90-0000-000</td>
                                                            <td>user@gmail.com</td>
                                                            <td>Delhi</td>
                                                            <td className='status'><span>Active</span></td>




                                                            <td className='action d-flex'>
                                                                <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                </svg></button>

                                                                <button onClick={() => setTogglePopup(true)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                        <g clip-path="url(#clip0_33_1159)">
                                                                            <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                            <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                        </g>
                                                                        <defs>
                                                                            <clipPath id="clip0_33_1159">
                                                                                <rect width="16" height="16" fill="white" />
                                                                            </clipPath>
                                                                        </defs>
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>





                                                    </tbody>
                                                </table>
                                                :
                                                currTab === "closed" ?
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Date  & Time</th>
                                                                <th scope="col">Lead ID</th>
                                                                <th scope="col" >Customer Name</th>
                                                                <th scope="col">Business Category</th>
                                                                <th scope="col">Mobile Number</th>
                                                                <th scope="col">Email id</th>
                                                                <th scope="col">Location</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Action </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>

                                                                <td>2021-09-28 00:45:08</td>
                                                                <td >LID45446</td>
                                                                <td>John Lex</td>
                                                                <td>Social Media</td>
                                                                <td>90-0000-000</td>
                                                                <td>user@gmail.com</td>
                                                                <td>Delhi</td>
                                                                <td className='status'><span>Active</span></td>

                                                                <td className='action d-flex'>
                                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                        <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                        <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                    </svg></button>

                                                                    <button onClick={() => setTogglePopup(true)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <g clip-path="url(#clip0_33_1159)">
                                                                                <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                                <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_33_1159">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>2021-09-28 00:45:08</td>
                                                                <td >LID45446</td>
                                                                <td>John Lex</td>
                                                                <td>Social Media</td>
                                                                <td>90-0000-000</td>
                                                                <td>user@gmail.com</td>
                                                                <td>Delhi</td>
                                                                <td className='status'><span>Active</span></td>


                                                                <td className='action d-flex'>
                                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                        <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                        <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                    </svg></button>

                                                                    <button onClick={() => setTogglePopup(true)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <g clip-path="url(#clip0_33_1159)">
                                                                                <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                                <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_33_1159">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>2021-09-28 00:45:08</td>
                                                                <td >LID45446</td>
                                                                <td>John Lex</td>
                                                                <td>Social Media</td>
                                                                <td>90-0000-000</td>
                                                                <td>user@gmail.com</td>
                                                                <td>Delhi</td>
                                                                <td className='status'><span>Active</span></td>




                                                                <td className='action d-flex'>
                                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                        <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                        <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                    </svg></button>

                                                                    <button onClick={() => setTogglePopup(true)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <g clip-path="url(#clip0_33_1159)">
                                                                                <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                                <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_33_1159">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>2021-09-28 00:45:08</td>
                                                                <td >LID45446</td>
                                                                <td>John Lex</td>
                                                                <td>Social Media</td>
                                                                <td>90-0000-000</td>
                                                                <td>user@gmail.com</td>
                                                                <td>Delhi</td>
                                                                <td className='status'><span>Active</span></td>




                                                                <td className='action d-flex'>
                                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                        <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                        <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                    </svg></button>

                                                                    <button>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <g clip-path="url(#clip0_33_1159)">
                                                                                <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                                <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_33_1159">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>2021-09-28 00:45:08</td>
                                                                <td >LID45446</td>
                                                                <td>John Lex</td>
                                                                <td>Social Media</td>
                                                                <td>90-0000-000</td>
                                                                <td>user@gmail.com</td>
                                                                <td>Delhi</td>
                                                                <td className='status'><span>Active</span></td>




                                                                <td className='action d-flex'>
                                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                        <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                        <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                    </svg></button>

                                                                    <button onClick={() => setTogglePopup(true)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <g clip-path="url(#clip0_33_1159)">
                                                                                <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                                <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_33_1159">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                            <tr>

                                                                <td>2021-09-28 00:45:08</td>
                                                                <td >LID45446</td>
                                                                <td>John Lex</td>
                                                                <td>Social Media</td>
                                                                <td>90-0000-000</td>
                                                                <td>user@gmail.com</td>
                                                                <td>Delhi</td>
                                                                <td className='status'><span>Active</span></td>




                                                                <td className='action d-flex'>
                                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                                        <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                                        <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                                    </svg></button>

                                                                    <button onClick={() => setTogglePopup(true)}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                            <g clip-path="url(#clip0_33_1159)">
                                                                                <path d="M12.444 13.3333H2.66623V3.55551H8.54179L9.43068 2.66663H2.66623C2.43048 2.66663 2.20439 2.76028 2.03769 2.92698C1.87099 3.09367 1.77734 3.31977 1.77734 3.55551V13.3333C1.77734 13.569 1.87099 13.7951 2.03769 13.9618C2.20439 14.1285 2.43048 14.2222 2.66623 14.2222H12.444C12.6798 14.2222 12.9059 14.1285 13.0726 13.9618C13.2392 13.7951 13.3329 13.569 13.3329 13.3333V6.66663L12.444 7.55552V13.3333Z" fill="#176496" />
                                                                                <path d="M14.9011 2.5955L13.4033 1.09773C13.3369 1.03107 13.2579 0.97819 13.171 0.942107C13.084 0.906025 12.9908 0.887451 12.8967 0.887451C12.8025 0.887451 12.7093 0.906025 12.6224 0.942107C12.5354 0.97819 12.4565 1.03107 12.39 1.09773L6.29667 7.22661L5.80334 9.36439C5.78232 9.46802 5.78453 9.57502 5.80981 9.67769C5.83509 9.78036 5.8828 9.87615 5.94953 9.95817C6.01625 10.0402 6.10033 10.1064 6.1957 10.1521C6.29108 10.1977 6.39538 10.2217 6.50112 10.2222C6.55577 10.2282 6.61091 10.2282 6.66556 10.2222L8.82112 9.74662L14.9011 3.60884C14.9678 3.54237 15.0207 3.4634 15.0567 3.37645C15.0928 3.28951 15.1114 3.1963 15.1114 3.10217C15.1114 3.00804 15.0928 2.91483 15.0567 2.82789C15.0207 2.74094 14.9678 2.66197 14.9011 2.5955ZM8.3589 8.92439L6.73223 9.28439L7.11001 7.67106L11.6967 3.05328L12.95 4.30661L8.3589 8.92439ZM13.4522 3.80439L12.1989 2.55106L12.8878 1.84884L14.15 3.11106L13.4522 3.80439Z" fill="#176496" />
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_33_1159">
                                                                                    <rect width="16" height="16" fill="white" />
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                </td>
                                                            </tr>





                                                        </tbody>
                                                    </table>

                                                    : null

                                    }

                                    :

                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                {/* popup form^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^  */}

                {togglePopup === true ?
                    <div className="change-lead">
                        <div className="fcontent">
                            <div className=" top-cont d-flex justify-content-between algin-items-center">
                                <h6>Change Leads</h6>

                                <button onClick={() => setTogglePopup(false)}>╳</button>
                            </div>

                            <div className="cont-body">
                                <form action="">
                                    <div className="my-3 d-flex align-items-center justify-content-between">
                                        <label htmlFor="">Select Status</label>
                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>Active</option>
                                            <option value="1">Pending</option>
                                            <option value="1">Progress</option>

                                        </select>

                                    </div>
                                    <div className="my-3 d-flex align-items-center justify-content-between">
                                        <label htmlFor="">Enter Remark</label>
                                        <input type="text" placeholder='Add Remark' />
                                    </div>
                                    <div className="my-3 d-flex align-items-center justify-content-between">
                                        <label htmlFor="">Next Follow-Update</label>
                                        <input type="date" />
                                    </div>

                                    <div className="my-3 d-flex gap-2 justify-content-end">
                                        <button className='cancel' onClick={() => setTogglePopup(false)} >Cancel</button>
                                        <button>Submit</button>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                    : null}



            </div>
        </>


    )
}
