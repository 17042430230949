import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import CopyIcon from '../components/dashboard/copy.png';
import EyeIcon from '../components/dashboard/dash-eye.png'
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const VendorBlogDetails = () => {

    const location = useLocation();
    console.log(location)

  const [blog, setBlog] = useState([]);
  const [baseUrl, setBaseUrl] = useState();

  const token = localStorage.getItem('avonToken');

  
  useEffect(() => {
    FetchBlogs();
  }, [])

  const FetchBlogs = async () => {
    if(location.state){
    await axios.get(BASE_URL+`/editsellerblog/${location.state}` , {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setBlog(data.data)
      setBaseUrl(data.image_base_url)
    })
}
  }

  return (
    <Page title="Vendor Products">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Add Blogs</li>
            </ul>
            <div className="col-md-12">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4 className="bold">
                  Blog
                  <Link to="/vendor-blogs">
                    <Button style={{ float: "right", backgroundColor: '#00B987' }} variant="success">

                      Back
                    </Button>
                  </Link>
                </h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="card-body-blk">
                        <h1 className="center">{blog.title}</h1>
                        <div className="center">
                        <img src={baseUrl+blog.image} />
                        </div>
                        <p>{blog.content}</p>
                     </div>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorBlogDetails;
