import React from "react";
import { Carousel } from 'react-responsive-carousel';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const NewArrivals = () => {
    const recent_options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        loop: true,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 2.8,
            },
            1000: {
                items: 5.4,
            },
        },
    };
    const Categories = [
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/product-2.png"
        },
        {
            name: 'New Collection Books',
            quantity: "2",
            cate1: "/assets/images/product-3.png"
        },
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/product-2.png"
        },
        {
            name: 'New Collection Books',
            quantity: "2",
            cate1: "/assets/images/product-3.png"
        },
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/product-2.png"
        },
        {
            name: 'New Collection Books',
            quantity: "2",
            cate1: "/assets/images/product-3.png"
        },
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/product-2.png"
        }
    ]

    return (
        <>
            <div className="container mt-3  ">
                <div className="new-arrivals products-nr">
                    <div className="slider-section new-arrival-slider position-relative">
                        <h6 className="cate-head mb-4 heading-bline position-relative">New Arrivals</h6>
                        <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows mt-5 mb-2 " {...recent_options}>
                            {Categories.map((product) => (
                                <div className="text-center shadow-sm">
                                    <img src={product.cate1} />
                                    <h5>{product.name}</h5>
                                    <h4>{product.quantity}</h4>
                                </div>
                            ))}
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-lg-6 mt-md-4 mt-3 px-0">
                <img src="/assets/images/home-section-5.png" width="100%"/>
            </div>
        </>
    )
}

export default NewArrivals;