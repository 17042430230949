import ReactOwlCarousel from 'react-owl-carousel';
import ProductItem from '../product/product-item.component';
import { Carousel } from 'react-responsive-carousel';

const ProductCarouselContainer = ({ products }) => {
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };
  return (
    <>
    {/* <Carousel {...recent_options}>
    {products.map((product) => (
        <ProductItem product={product} key={product.id} className1="item" className2="product-block product-margin-carousel" />
      ))}
    </Carousel> */}
    <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
      {products.map((product) => (
        <ProductItem product={product} key={product.id} className1="item" className2="product-block product-margin-carousel" />
      ))}
    </ReactOwlCarousel>
    </>
  );
};

export default ProductCarouselContainer;
