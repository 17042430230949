import ReactOwlCarousel from 'react-owl-carousel';
import BrandItem from './brand-item.component';
import { Carousel } from 'react-responsive-carousel';

const BrandItemContainer = ({ brands }) => {
  const brand_options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 3,
        autoplay: true,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
    },
  };
  return (
    <>
    {/* <Carousel>
  
      {brands.map((brand) => (
        <BrandItem brand={brand} key={brand.id} />
      ))}
   
    
    </Carousel> */}
    
    <ReactOwlCarousel className="owl-theme owl-feature-slider" {...brand_options}>
      {brands.map((brand) => (
        <BrandItem brand={brand} key={brand.id} />
      ))}
    </ReactOwlCarousel>
    </>
  );
};

export default BrandItemContainer;
