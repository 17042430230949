import React from "react";
import "../../style/Startup.css";
import { Link } from "react-router-dom";
export const RegisterMarketplace = () => {
  return (
    <>
      <div className="registor-marketplace my-4 pt-2">
        <div className="container">
          <div className="top-content text-center my-4">
            <h6>More Businesses for Sale than Any Other Curated Marketplace</h6>
            <h3>Register For Our Marketplace Today</h3>
            <p>
              Gain access to the full Empire Flippers marketplace by creating
              your account and signing in
            </p>
          </div>

          <div className="cards row gap-lg-4 gap-md-3 gap-2 justify-content-center ">
            <div className="col-lg-5 col-md-5 col-12 d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/icons/user-yellow.png" alt="" width={"120px"}/>
              <h5>I want to list my STARTUP</h5>
              <Link to="/startup-form" className="bg-blue">
                Start Now
              </Link>
            </div>
            <div className="col-lg-5 col-md-5 col-12 d-flex flex-column justify-content-center align-items-center">
              <img src="/assets/icons/read-more.png" alt="" width = "120px" />
              <h5> Have an idea and want to Build my STARTUP</h5>
              <Link to="/build-my-startup" className="bg-yellow">
                Start Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
