import React from "react";

const data = [
    {id:1},
    {id:2},
    {id:2},
    {id:3},
]

export default function SellerFeatures(){

    return(
        <div className="seller-features-container">
            <div className="seller-features-heading">Our Features</div>
            <div className="seller-features-subheading mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
            </div>
            <div className="col-md-12 row">
                {data.map((i) => (
                    <div className="col-md-3 mt-8">
                        <div className="seller-feature-box">
                <div><img src="assets/images/feature-logo.png" /></div>
                <div className="mt-4 font22">Features Tittle One</div>
                <div className="feature-content mt-4">
                Lorem ipsum dolor sit amet, con adipiscing elit lorem.
                    </div>
                    <div className="mt-4">
                        <a> Know more</a>
                    </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}