import React, { useState } from 'react';

const ImageUploader = () => {
    const [image1, setImage1] = useState({ preview: "", raw: "" });
    const [image2, setImage2] = useState({ preview2: "", raw2: "" });
    const [image3, setImage3] = useState({ preview: "", raw: "" });
    const [image4, setImage4] = useState({ preview: "", raw: "" });
    const [image5, setImage5] = useState({ preview: "", raw: "" });

    const handleChange1 = e => {
        if (e.target.files.length) {
            setImage1({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0]
            });
        }
    };
    const handleChange2 = e => {
        console.log("clicked");
        if (e.target.files.length) {
            setImage2({
                preview2: URL.createObjectURL(e.target.files[0]),
                raw2: e.target.files[0]
            });
        }
    };
    // const handleChange2 = e => {
    //     if (e.target.files.length) {
    //         setImage2({
    //             preview2: URL.createObjectURL(e.target.files[0]),
    //             raw2: e.target.files[0]
    //         });
    //     }
    // };
    // const handleChange3 = e => {
    //     if (e.target.files.length) {
    //         setImage3({
    //             preview: URL.createObjectURL(e.target.files[0]),
    //             raw: e.target.files[0]
    //         });
    //     }
    // };
    // const handleChange4 = e => {
    //     if (e.target.files.length) {
    //         setImage4({
    //             preview: URL.createObjectURL(e.target.files[0]),
    //             raw: e.target.files[0]
    //         });
    //     }
    // };
    return (
        <div className='d-flex'>
            <div>
                <label htmlFor="upload-button">
                    {image1.preview ? (
                        <img src={image1.preview} alt="dummy" width="300" height="300" />
                    ) : (
                        <>
                            <span className="fa-stack fa-2x mt-3 mb-2">
                                <i className="fas fa-circle fa-stack-2x" />
                                <i className="fas fa-camera fa-stack-1x fa-inverse" />
                            </span>
                            <p className="text-center">Add Photo</p>
                        </>
                    )}
                </label>
                <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={handleChange1}
                />
            </div>
            <div>
                <label htmlFor="upload-button">
                    {image2.preview2 ? (
                        <img src={image2.preview2} alt="dummy" width="300" height="300" />
                    ) : (
                        <>
                            <span className="fa-stack fa-2x mt-3 mb-2">
                                <i className="fas fa-circle fa-stack-2x" />
                                <i className="fas fa-camera fa-stack-1x fa-inverse" />
                            </span>
                            <p className="text-center">Add Photo</p>
                        </>
                    )}
                </label>
                <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    onChange={handleChange2}
                />
            </div>
            {/* <label htmlFor="upload-button">
                {image3.preview ? (
                    <img src={image3.preview} alt="dummy" width="300" height="300" />
                ) : (
                    <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fas fa-camera fa-stack-1x fa-inverse" />
                        </span>
                        <h5 className="text-center">Add Photo</h5>
                    </>
                )}
            </label> */}
            {/* <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange3}
            />
             <label htmlFor="upload-button">
                {image4.preview ? (
                    <img src={image4.preview} alt="dummy" width="300" height="300" />
                ) : (
                    <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fas fa-camera fa-stack-1x fa-inverse" />
                        </span>
                        <h5 className="text-center">Add Photo</h5>
                    </>
                )}
            </label>
            <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange4}
            />
             <label htmlFor="upload-button">
                {image5.preview ? (
                    <img src={image5.preview} alt="dummy" width="300" height="300" />
                ) : (
                    <>
                        <span className="fa-stack fa-2x mt-3 mb-2">
                            <i className="fas fa-circle fa-stack-2x" />
                            <i className="fas fa-camera fa-stack-1x fa-inverse" />
                        </span>
                        <h5 className="text-center">Add Photo</h5>
                    </>
                )}
            </label>
            <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleChange5}
            /> */}
        </div>
    )
}

export default ImageUploader;