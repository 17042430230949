import { useContext, useState } from 'react';
import { Form, Button, Modal, FormCheck } from 'react-bootstrap';
import Axios from 'axios';
import StateContext from '../../context/StateContext';
import DispatchContext from '../../context/DispatchContext';

const AddAddress = ({ showAdd, setShowAdd }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const { countries } = appState;
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [landmark, setLandmark] = useState('');
  const [phone, setPhone] = useState('');
  const [emailId, setEmailId] = useState('');
  const [isDefault, setIsDefault] = useState('no');
  const [errors, setErrors] = useState([]);
  const [buttonText, setButtonText] = useState('Submit');
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const handleClose = () => {
    setShowAdd(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setButtonDisabled(true);
    try {
      setErrors([]);
      const response = await Axios.post(
        '/add-address',
        {
          address,
          firstName,
          lastName,
          city,
          state,
          country,
          zipcode,
          landmark,
          phone,
          emailId,
          isDefault,
        },
        {
          headers: {
            Authorization: `Bearer ${appState.state.user.token}`,
          },
        }
      );
      const responseData = response.data.data;
      // console.log(responseData);
      // setAddresses([...addresses, responseData.address]);
      setErrors([]);
      setButtonDisabled(false);
      setButtonText('Submit');
      appDispatch({ type: 'flashMessage', value: 'Address Added' });
      setShowAdd(false);
    } catch (error) {
      setButtonDisabled(false);
      setButtonText('Submit');
      if (error.response.status === 422) {
        const response = await error.response.data;
        setErrors(response.errors);
      } else {
        // console.log('Error encountered');
      }
    }
  }

  console.log("countries", countries)
  return (
    <Modal onHide={handleClose} className="address-model-block" show={showAdd} animation={false} centered>
      <Modal.Header>
        <Modal.Title style={{width: "240px"}}>Billing Address</Modal.Title>
        {/* <Form.Check label="Shipping" /> */}
        <h4 style={{paddingLeft: '50px'}} class="modal-title w-100 text-center">Shipping Address</h4>
        {/* <Modal.Title style={{float: 'right'}}>Shipping Address</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className='col-md-3'>
              <Form.Group className="form-group">

                <Form.Control type="text" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                <span className="text-danger">{errors.firstName}</span>
              </Form.Group>
            </div>
            <div className='col-md-3'>
              <Form.Group className="form-group">

                <Form.Control type="text" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                <span className="text-danger">{errors.lastName}</span>
              </Form.Group>
            </div>
            <div className='col-md-3'>
              <Form.Group className="form-group">

                <Form.Control type="text" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                <span className="text-danger">{errors.firstName}</span>
              </Form.Group>
            </div>
            <div className='col-md-3'>
              <Form.Group className="form-group">

                <Form.Control type="text" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)} value={lastName} />
                <span className="text-danger">{errors.lastName}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
                <Form.Control type="text" placeholder="Mobile Number" onChange={(e) => setPhone(e.target.value)} value={phone} />
                <span className="text-danger">{errors.phone}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
             
                <Form.Control type="text" placeholder="Email Id" onChange={(e) => setEmailId(e.target.value)} value={emailId} />
                <span className="text-danger">{errors.emailId}</span>
              </Form.Group>
            </div>
          
            <div className="col-md-3">
              <Form.Group className="form-group">
                <Form.Control type="text" placeholder="Mobile Number" onChange={(e) => setPhone(e.target.value)} value={phone} />
                <span className="text-danger">{errors.phone}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
             
                <Form.Control type="text" placeholder="Email Id" onChange={(e) => setEmailId(e.target.value)} value={emailId} />
                <span className="text-danger">{errors.emailId}</span>
              </Form.Group>
            </div>

            <div className="col-md-6">
              <Form.Group className="form-group">
                
                <Form.Control type="text" placeholder="Landmark" onChange={(e) => setLandmark(e.target.value)} value={landmark} />
                <span className="text-danger">{errors.landmark}</span>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="form-group">
                
                <Form.Control type="text" placeholder="Landmark" onChange={(e) => setLandmark(e.target.value)} value={landmark} />
                <span className="text-danger">{errors.landmark}</span>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="form-group">
                <Form.Control as="textarea" rows={3} type="text" placeholder="Enter Full Address" onChange={(e) => setAddress(e.target.value)} value={address} />
                <span className="text-danger">{errors.address}</span>
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group className="form-group">
                
                <Form.Control as="textarea" rows={3} type="text" placeholder="Enter Full Address" onChange={(e) => setAddress(e.target.value)} value={address} />
                <span className="text-danger">{errors.address}</span>
              </Form.Group>
            </div>
          
            <div className="col-md-3">
              <Form.Group className="form-group">
                
                <Form.Select onChange={(e) => setCountry(e.target.value)} value={country}>
                  <option value="">Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
                <span className="text-danger">{errors.country}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
            
                <Form.Control type="text" placeholder="State" onChange={(e) => setState(e.target.value)} value={state} />
                <span className="text-danger">{errors.state}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
                
                <Form.Select onChange={(e) => setCountry(e.target.value)} value={country}>
                  <option value="">Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
                <span className="text-danger">{errors.country}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
            
                <Form.Control type="text" placeholder="State" onChange={(e) => setState(e.target.value)} value={state} />
                <span className="text-danger">{errors.state}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
             
                <Form.Control type="text" placeholder="City" onChange={(e) => setCity(e.target.value)} value={city} />
                <span className="text-danger">{errors.city}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
                
                <Form.Control type="text" placeholder="Zip / Postal Code" onChange={(e) => setZipcode(e.target.value)} value={zipcode} />
                <span className="text-danger">{errors.zipcode}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
             
                <Form.Control type="text" placeholder="City" onChange={(e) => setCity(e.target.value)} value={city} />
                <span className="text-danger">{errors.city}</span>
              </Form.Group>
            </div>
            <div className="col-md-3">
              <Form.Group className="form-group">
                
                <Form.Control type="text" placeholder="Zip / Postal Code" onChange={(e) => setZipcode(e.target.value)} value={zipcode} />
                <span className="text-danger">{errors.zipcode}</span>
              </Form.Group>
            </div>
            
          </div>
          <div className="default-address">
           <Form.Check label="Set as default address" />   
            
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" type="submit" disabled={buttonDisabled} onClick={handleSubmit}>
          Add Address
        </Button>
        <Button variant="primary" onClick={() => setShowAdd(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAddress;
