export const maskNumber = (input) => {
    const visibleDigits1 = 2;
    const maskedDigits1 = input.length - visibleDigits1 - 6; // 5 is the number of masked digits between the first and second visible segments
    const visibleDigits2 = 2;
    const maskedDigits2 = input.length - visibleDigits2 - 5; // 3 is the number of masked digits between the second and third visible segments
  
    const stars1 = '*'.repeat(maskedDigits1);
    const stars2 = '*'.repeat(maskedDigits2);
  
    const firstSegment = input.slice(0, visibleDigits1);
    const secondSegment = stars1 + input.slice(visibleDigits1, visibleDigits1 + 1) + stars2;
    const thirdSegment = input.slice(-2);
  
    return `${firstSegment}${secondSegment}${thirdSegment}`;
  };

  export const maskEmail = (input) => {
    // Your custom logic to mask the email (e.g., hide part of the address)
    const [username, domain] = input.split('@');
    const maskedUsername = username.slice(0, 1) + '*'.repeat(username.length - 2);
    return `${maskedUsername}@${domain}`;
  };