import React, { useEffect, useRef, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import Accordion from "react-bootstrap/Accordion";
import "./headerPage.css";
import { Link, useNavigate } from "react-router-dom";
import { MobileHeader } from "./MobileHeader";
//  import {faPhoneVolume} from "FontAwesomeIcon"
import StateContext from "../../../context/StateContext";
import DispatchContext from "../../../context/DispatchContext";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../../../config";
import { ToastContainer, toast } from "react-toastify";
const HeaderPage = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isOpenExplore, setIsOpenExplore] = useState(false);
  const [isShipOpen, setShipOpen] = useState(false);
  const [selectedShip, setSelectedship] = useState(null);
  const [isHelpOpen, setIsHelpOpen] = useState(false);
  const [selectedItemHelp, setSelectedItemHelp] = useState(null);
  const [userDropdownOpen, setuserDropdownOpen] = useState(false);
  const [selectedUserDropdown, setSelectedUserDropdown] = useState(null);
  const [logged, setLogged] = useState(null);
  const token = localStorage.getItem("avonToken");
  const options = ["English - INR", "English - USD"];
  const [seller, setseller] = useState("");
  const [ban, setban] = useState(1);
  const [headershow, setheadershow] = useState(1);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const pageLocation = window.location.pathname;
  const [headerdata, setheaderdata] = useState([]);
  const [servicedata, setservicedata] = useState([]);
  const [showNotif, setShowNotif] = useState("-100%");
  useEffect(() => {
    getheader();
    // if(token){
    //   setLogged(2)
    // }else{
    //   setLogged(null);
    // }

    // pageLocation === "/dashboard"
    //   ? setLogged(1)
    //   : pageLocation === "/vendor-dashboard"
    //   ? setLogged(2)
    //   : setLogged(null);
  }, [token]);

  console.log("pageLocation", pageLocation)
  useEffect(() => {
    getheader1();
    getservices();
    checkheader();
  }, []);
  const getheader1 = () => {
    axios
      .get(BASE_URL + "/getwebsitedataall")
      .then(({ data }) => {
        setheaderdata(data.data);
      })
      .catch(({ response }) => { });
  };
  const getservices = () => {
    axios
      .get(BASE_URL + "/servicecategoryheader")
      .then(({ data }) => {
        setservicedata(data.data);
      })
      .catch(({ response }) => { });
  };

  useEffect(() => {
    checkheader();
  }, [pageLocation, appState]);


  const checkheader = () => {
    if (
      pageLocation == "/dashboard" ||
      pageLocation == "/my-account" ||
      pageLocation == "/my-favourite" ||
      pageLocation == "/my-enquiry" ||
      pageLocation == "/live-chat" ||
      pageLocation == "/help-support" ||
      pageLocation == "/setting"
    ) {
      setheadershow(2);
      if (!appState?.state?.user?.token) {
        toast.error("You are not authorize!");
        navigate("/");
      }
    } else if (
      pageLocation == "/vendor-dashboard" ||
      pageLocation == "/business-profile" ||
      pageLocation == "/tax-and-bank-info" ||
      pageLocation == "/vendor-contact-us" ||
      pageLocation == "/homepage-widget" ||
      pageLocation == "/sliders-and-banners" ||
      pageLocation == "/other-pages" ||
      pageLocation == "/our-feature" ||
      pageLocation == "/brand-and-sponsors" ||
      pageLocation == "/vendor-Faqs" ||
      pageLocation == "/vendor-blogs" ||
      pageLocation == "/vendor-products" ||
      pageLocation == "/bulk-upload" ||
      pageLocation == "/product-download" ||
      pageLocation == "/vendor-product-analytics" ||
      pageLocation == "/vendor-services" ||
      pageLocation == "/vendor-bulk-upload-services" ||
      pageLocation == "vendor-services-download" ||
      pageLocation == "/vendor-services-analytics" ||
      pageLocation == "/vendor-inquiries" ||
      pageLocation == "/manage-startup" ||
      pageLocation == "/vendor-review-feedback" ||
      pageLocation == "/manage-quote" ||
      pageLocation == "/manage-edit-request" ||
      pageLocation == "/wallet" ||
      pageLocation == "/payment-history" ||
      pageLocation == "/subscription" ||
      pageLocation == "/product-review" ||
      pageLocation == "/vendor-Faqs" ||
      pageLocation == "/manage-services" ||
      pageLocation == "/add-services"
    ) {
      setheadershow(3);
      if (
        appState?.state?.user?.is_email_verified == 0 ||
        appState?.state?.user?.is_email_verified == 2
      ) {
        // navigate("/dashboard");
        // toast.error("Your Email is not verified please verify your email!");
      }
      if (!appState?.state?.user?.token) {
        navigate("/");
        toast.error("You are not authorize!");
      }
    } else {
      setheadershow(1);
    }
    if (
      appState?.state?.user?.token &&
      appState?.state?.user?.is_email_verified == 1
    ) {
      if (seller && seller?.customer_cancelled == "yes") {
        if (
          pageLocation == "/vendor-services" ||
          pageLocation == "/vendor-bulk-upload-services" ||
          pageLocation == "vendor-services-download" ||
          pageLocation == "/vendor-services-analytics" ||
          pageLocation == "/vendor-products" ||
          pageLocation == "/bulk-upload" ||
          pageLocation == "/product-download" ||
          pageLocation == "/vendor-product-analytics" ||
          pageLocation == "/vendor-services" ||
          pageLocation == "/vendor-bulk-upload-services" ||
          pageLocation == "vendor-services-download" ||
          pageLocation == "/vendor-services-analytics" ||
          pageLocation == "/manage-services" ||
          pageLocation == "/add-services"
        ) {
          navigate("/");
          toast.error(
            "You subscription is cancelled so you don't access seller dashboard!"
          );
        }
      }
      if (seller && seller?.package_type === "Product") {
        if (
          pageLocation === "/vendor-services" ||
          pageLocation === "/vendor-bulk-upload-services" ||
          pageLocation === "vendor-services-download" ||
          pageLocation === "/vendor-services-analytics" ||
          pageLocation === "/manage-services" ||
          pageLocation === "/add-services"
        ) {
          navigate("/dashboard");
          toast.error("You are not authorize to access this!");
        }
      }
      if (seller && seller?.package_type == "Directory") {
        if (
          pageLocation === "/vendor-products" ||
          pageLocation === "/bulk-upload" ||
          pageLocation === "/product-download" ||
          pageLocation === "/vendor-product-analytics" ||
          pageLocation === "/vendor-services" ||
          pageLocation === "/vendor-bulk-upload-services" ||
          pageLocation === "vendor-services-download" ||
          pageLocation === "/vendor-services-analytics" ||
          pageLocation === "/manage-services" ||
          pageLocation === "/add-services"
        ) {
          navigate("/dashboard");
          toast.error("You are not authorize to access this!");
        }
      }
      if (seller && seller?.package_type == "Services") {
        if (
          pageLocation == "/vendor-products" ||
          pageLocation == "/bulk-upload" ||
          pageLocation == "/product-download" ||
          pageLocation == "/vendor-product-analytics"
        ) {
          navigate("/dashboard");
          toast.error("You are not authorize to access this!");
        }
      }
    }
  };
  const getheader = () => {
    if (token) {
      axios
        .get(BASE_URL + "/get-seller-detail", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data }) => {
          setseller(data.data);
          setban(data.user);
          if (data.user == 1) {
            setLogged(1);
          } else if (data.user == 0) {
            setLogged(2);
          } else {
            setLogged(null);
          }
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            handleLogOut();
            console.log("------log2", response);
          }
        });
    }

    // if (token) {
    //   axios.get(BASE_URL + '/get-seller-detail', {
    //     headers: {
    //       'Accept': 'application/json',
    //       'Authorization': 'Bearer ' + token
    //     }
    //   }).then(({ data }) => {
    //     setseller(data.data)
    //     setban(data.user)
    //   })
    // }
  };
  function filterdata(type) {
    const data = headerdata.filter((e) => e.type === type);
    return data[0];
  }
  // useEffect(() => {
  //     function handleOutsideClick(event) {
  //         if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {

  //         }
  //     }
  //     document.addEventListener('click', handleOutsideClick);
  //     return () => {
  //         document.removeEventListener('click', handleOutsideClick);
  //     };
  // }, []);

  const toggleDropdown = () => {
    setIsOpen(true);
  };
  const toggleCloseDropdown = () => {
    setIsOpen(false);
  };

  const selectItem = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
  };

  const toggleDropdownShip = () => {
    setShipOpen(!isOpen);
  };

  const toggleCloseDropdownShip = () => {
    setShipOpen(false);
  };

  const closeHelpDropdown = () => {
    setIsHelpOpen(false);
  };

  const toggleExploreUs = () => {
    setIsOpenExplore(!isOpenExplore);
  };

  const toggleCloseExploreUs = () => {
    setIsOpenExplore(false);
  };

  const selectItemShip = (item) => {
    setSelectedship(item);
    setShipOpen(false);
  };

  const toggleDropdownHelp = () => {
    setIsHelpOpen(!isOpen);
  };
  const toggleUserDropdown = () => {
    setuserDropdownOpen(true);
  };
  const toggleCloseUserDropdown = () => {
    setuserDropdownOpen(false);
  };

  const selectUserDropDown = (item) => {
    setSelectedUserDropdown(item);
    setuserDropdownOpen(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      toggleCloseDropdown();
      closeHelpDropdown();
      toggleCloseExploreUs();
      toggleCloseUserDropdown();
      toggleCloseDropdownShip();
    }
  };

  const toggleCloseAllDropdown = () => {
    toggleCloseDropdown();
    closeHelpDropdown();
    toggleCloseExploreUs();
    toggleCloseUserDropdown();
    toggleCloseDropdownShip();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  async function handleLogOut() {
    // e.preventDefault();
    try {
      await axios.get(BASE_URL + "/log-out", {
        headers: {
          Authorization: `Bearer ${appState.state.user.token}`,
        },
      });
    } catch (error) {
      // console.log('error encountered');
    }
    navigate("/");
    appDispatch({ type: "logout" });
    localStorage.removeItem("avonToken");
    localStorage.removeItem("avonName");
    localStorage.removeItem("avonEmail");
    localStorage.removeItem("avonMobileNumber");
    localStorage.removeItem("avonProfileImage");
    localStorage.removeItem("avonLastLogin");
    setLogged(null);
  }
  var businessnature = [
    "Manufacturer",
    "Exporters & Importers",
    "Suppliers",
    "Distributors",
    "Retailers",
    "Service Providers",
    "Educational Institutes",
  ];
  return (
    <>
      {headershow == 1 && (
        <div className="container-fluid header-container">
          <div className="header-section d-md-flex justify-content-between">
            <div
              className="d-flex"
              style={{ gap: "25px", alignItems: "center" }}
            >
              <div>
                <Link to="/" target="_blank">
                  {filterdata("header_logo") && filterdata("header_logo") && (
                    <img
                      src={
                        IMAGE_BASE_URL +
                        filterdata("header_logo").image.file_name
                      }
                      alt="logo"
                    />
                  )}
                </Link>
              </div>
              <div>
                <div className="custom-dropdown" style={{ border: "none" }}>
                  <div className="dropdown-header" onClick={toggleExploreUs}>
                    <img src="/assets/Icons/explore-bar.svg" className="mr-1" />
                    {selectedItemHelp || "Explore Us"}
                  </div>
                  {isOpenExplore && (
                    <ul className="dropdown-options1" ref={dropdownRef}>
                      <Accordion className="header-accordian">
                        <Accordion.Item eventKey="0" flush>
                          <Accordion.Header className="py-1">
                            Products
                          </Accordion.Header>
                          <Accordion.Body>
                            <Link
                              to="/categorys/products-page"
                              onClick={toggleCloseAllDropdown}
                            >
                              <li>All Products</li>
                            </Link>
                            <Link
                              to="/categorys/flash-deals"
                              onClick={toggleCloseAllDropdown}
                            >
                              <li>Flash Deals</li>
                            </Link>
                            <Link
                              to="/categorys/new-arrival"
                              onClick={toggleCloseAllDropdown}
                            >
                              {" "}
                              <li>New Arrivals</li>
                            </Link>
                            <Link
                              to="/categorys/stock-clearance"
                              onClick={toggleCloseAllDropdown}
                            >
                              <li>Stock Clearance</li>
                            </Link>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header className="py-1">
                            {" "}
                            Services
                          </Accordion.Header>
                          <Accordion.Body>
                            <Link
                              to={"/service-category"}
                              onClick={toggleCloseAllDropdown}
                            >
                              <li>Show All Services</li>
                            </Link>
                            {servicedata &&
                              servicedata.map((item, i) => (
                                <Link
                                  to={"/service-categorys/" + item.slug}
                                  onClick={toggleCloseAllDropdown}
                                >
                                  <li>{item.name}</li>
                                </Link>
                              ))}


                            {/* <Link to="/digital-services" onClick={toggleCloseAllDropdown}><li>Service Providers</li></Link> */}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header className="py-1">
                            Directory
                          </Accordion.Header>
                          <Accordion.Body>
                            <Link to="/startup" onClick={toggleCloseAllDropdown}>
                              <li>Startup</li>
                            </Link>
                            {businessnature &&
                              businessnature.map((item, i) => (
                                <Link to={"/directory-listing/" + item} onClick={toggleCloseAllDropdown}>
                                  <li>{item}</li>
                                </Link>
                              ))}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      {/* <li className='mt-1 mb-2 all-startup' >
                                        All Startups
                                        </li> */}
                    </ul>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center mid-para">
              <p>
                Your business is just one{" "}
                <a href="#" style={{ color: "red" }}>
                  Click
                </a>{" "}
                away from getting global exposure.
              </p>
            </div>
            <div className="header-right-section">
              <div className="custom-dropdown">
                <div
                  className="dropdown-header d-flex align-items-center"
                  onClick={toggleUserDropdown}
                >
                  {token ? (
                    <div className="d-flex ">
                      <img
                        src={
                          appState?.state?.user?.profileImage
                            ? appState?.state?.user?.profileImage
                            : "/assets/images/user.jpg"
                        }
                        width="25px"
                      />
                      <img
                        src="/assets/Icons/explore-bar.svg"
                        alt=""
                        width="13px"
                        height="10px"
                        className="bg-white rounded-circle"
                        style={{ marginLeft: "-0.6rem", marginTop: "0.8rem" }}
                      />
                    </div>
                  ) : (
                    <>
                      <img src="/assets/Icons/user.svg" width="15px" />
                      <img
                        src="/assets/Icons/down-arrow.svg"
                        className="ml-1"
                      />
                    </>
                  )}
                </div>
                {userDropdownOpen &&
                  (logged === 1 ? (
                    <ul className="dropdown-options" ref={dropdownRef}>
                      <div className="d-flex justify-content-between px-3 pt-3  align-items-center ">
                        <div className="user-profile d-flex gap-3 align-items-center ">
                          <img
                            src={
                              appState?.state?.user?.profileImage
                                ? appState?.state?.user?.profileImage
                                : "/assets/images/user.jpg"
                            }
                            alt=""
                            width={"50px"}
                          />
                          <div className="right-content">
                            <h5 className="mb-0">{appState.state.user.name}</h5>
                            <p
                              className="ph_number mb-0"
                              style={{ fontWeight: "500" }}
                            >
                              <i class="fa fa-phone"></i>{" "}
                              {appState.state.user.mobileNumber}
                            </p>
                          </div>
                        </div>
                        <div>
                          <a href="">
                            {" "}
                            <img src="" alt="" />{" "}
                          </a>
                        </div>
                      </div>

                      <h6> For Buyer </h6>
                      <li>
                        <Link to="/my-account" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/login-icon.svg"
                            className="mr-3"
                          />
                          My Account
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-enquiry" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/post-buy.svg"
                            className="mr-3"
                          />
                          All Inquiries
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/post-buy-requirement"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/search-dictory.svg"
                            className="mr-3"
                          />
                          Post Buy Requirements
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/seller-benefits"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Sell on APNASHAHER.COM
                        </Link>
                      </li>

                      {/* <li>
                      <Link to="/log-out" onClick={toggleCloseAllDropdown}>
                        <img
                          src="/assets/Icons/buyer-faq.svg"
                          className="mr-3"
                        />
                        Logout
                      </Link>
                    </li> */}

                      <h6>Seller</h6>
                      {/* {Buyeroptions?.map((option, index) => ( */}
                      <li>
                        <Link
                          to="/vendor-dashboard"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/login-icon.svg"
                            className="mr-3"
                          />
                          Seller Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link to="/all-query" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/post-buy.svg"
                            className="mr-3"
                          />
                          All Inquiries
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/search-dictory.svg"
                            className="mr-3"
                          />
                          Post Product / Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />{" "}
                          Customer Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/"
                          onClick={() => {
                            toggleCloseAllDropdown();
                            handleLogOut();
                          }}
                        >
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Logout
                        </Link>
                      </li>
                    </ul>
                  ) : logged === 2 ? (
                    <ul className="dropdown-options" ref={dropdownRef}>
                      <div className="user-profile d-flex gap-3 align-items-center px-3 pt-3">
                        <img
                          src={
                            appState?.state?.user?.profileImage
                              ? appState?.state?.user?.profileImage
                              : "/assets/images/user.jpg"
                          }
                          alt=""
                          width={"50px"}
                        />
                        <div className="right-content">
                          <h5 className="mb-0">{appState.state.user.name}</h5>
                          <p
                            className="ph_number mb-0"
                            style={{ fontWeight: "500" }}
                          >
                            <i class="fa fa-phone"></i>{" "}
                            {appState.state.user.mobileNumber}
                          </p>
                        </div>
                      </div>

                      <h6> For Buyer </h6>
                      <li>
                        <Link to="/my-account" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/login-icon.svg"
                            className="mr-3"
                          />
                          My Account
                        </Link>
                      </li>
                      <li>
                        <Link to="/my-enquiry" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/post-buy.svg"
                            className="mr-3"
                          />
                          All Inquiries
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/post-buy-requirement"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/search-dictory.svg"
                            className="mr-3"
                          />
                          Post Buy Requirements
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/seller-benefits"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Sell on APNASHAHER.COM
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/log-out"
                          onClick={() => {
                            toggleCloseAllDropdown();
                            handleLogOut();
                          }}
                        >
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Logout
                        </Link>
                      </li>

                      <h6>Seller</h6>
                      {/* {Buyeroptions?.map((option, index) => ( */}
                      <li>
                        <Link
                          to="/vendor-dashboard"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/login-icon.svg"
                            className="mr-3"
                          />
                          Seller Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link to="/all-query" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/post-buy.svg"
                            className="mr-3"
                          />
                          All Inquiries
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/search-dictory.svg"
                            className="mr-3"
                          />
                          Post Product / Services
                        </Link>
                      </li>
                      <li>
                        <Link to="/" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />{" "}
                          Customer Dashboard
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/"
                          onClick={() => {
                            toggleCloseAllDropdown();
                            handleLogOut();
                          }}
                        >
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Logout
                        </Link>
                      </li>
                    </ul>
                  ) : (
                    <ul className="dropdown-options" ref={dropdownRef}>
                      <h6>For Buyer</h6>
                      {/* {Buyeroptions?.map((option, index) => ( */}
                      <li>
                        <Link to="/login" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/login-icon.svg"
                            className="mr-3"
                          />
                          Login / Signup
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/post-buy-requirement"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/post-buy.svg"
                            className="mr-3"
                          />
                          Post Buy Requirements
                        </Link>
                      </li>
                      <li>
                        <Link to="/directory" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/search-dictory.svg"
                            className="mr-3"
                          />
                          Search Directory
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/customer-faq"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Buyers FAQ
                        </Link>
                      </li>

                      {/* ))} */}
                      <h6>For Seller</h6>
                      <li>
                        <Link to="/login" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/login-icon.svg"
                            className="mr-3"
                          />
                          Login / Signup
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/seller-benefits"
                          onClick={toggleCloseAllDropdown}
                        >
                          <img
                            src="/assets/Icons/sell-apna.svg"
                            className="mr-3"
                          />
                          Sell on APNASHAHER
                        </Link>
                      </li>

                      {/* <li>

                      <Link to="/seller-benefits" onClick={toggleCloseAllDropdown}>
                        <img
                          src="/assets/Icons/contact-apna.svg"
                          className="mr-3"
                        />
                        Sellers Benefits
                      </Link>
                    </li> */}
                      <li>
                        <Link to="/contact-us" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Contact APNASHAHER
                        </Link>
                      </li>
                      <li>
                        <Link to="/seller-faq" onClick={toggleCloseAllDropdown}>
                          <img
                            src="/assets/Icons/buyer-faq.svg"
                            className="mr-3"
                          />
                          Seller FAQ
                        </Link>
                      </li>
                    </ul>
                  ))}
              </div>

              <div className="custom-dropdown">
                <div className="dropdown-header" onClick={toggleDropdownHelp}>
                  {selectedItemHelp || "Help"}
                  <img src="/assets/Icons/down-arrow.svg" className="ml-1" />
                </div>
                {isHelpOpen && (
                  <ul className="dropdown-options2" ref={dropdownRef}>
                    <li>
                      <Link to="/contact-us" onClick={toggleCloseAllDropdown}>
                        <img
                          src="/assets/Icons/contact-us.svg"
                          className="mr-3"
                        />
                        Contact Us
                      </Link>
                    </li>
                    <Link
                      to="/advertise-with-us"
                      onClick={toggleCloseAllDropdown}
                    >
                      <li>
                        <img src="/assets/Icons/advert.svg" className="mr-3" />
                        Advertise with Us
                      </li>
                    </Link>

                    <Link to="/feedback">
                      <li>
                        <img
                          src="/assets/Icons/feedback.svg"
                          className="mr-3"
                        />
                        Share your Feedback
                      </li>
                    </Link>

                    <Link to="/report-abuse" onClick={toggleCloseAllDropdown}>
                      <li>
                        <img src="/assets/Icons/report.svg" className="mr-3" />
                        Report Abuse
                      </li>
                    </Link>
                  </ul>
                )}
              </div>

              <div className="custom-dropdown">
                <div
                  className="dropdown-header"
                  onClick={toggleDropdown}
                  ref={dropdownRef}
                >
                  {selectedItem || "English - INR"}
                  <img src="/assets/Icons/down-arrow.svg" className="ml-1" />
                </div>
                {isOpen && (
                  <ul
                    className="dropdown-options currency-drop py-2"
                    style={{ width: "150px" }}
                    ref={dropdownRef}
                  >
                    {options.map((option, index) => (
                      <li key={index} onClick={() => selectItem(option)}>
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="custom-dropdown">
                <div className="dropdown-header" onClick={toggleDropdownShip}>
                  Ship:
                  <img src="/assets/images/flag-new.png" className="ml-1" />
                </div>
                {isShipOpen && (
                  <ul
                    className="dropdown-options shipped-dropdown"
                    ref={dropdownRef}
                  >

                    <h6 className="p-0 mt-2 mb-3">Specify your location</h6>
                    <Form.Select
                      aria-label="Default select example"
                      className="my-2"
                    >
                      <option>Select Your location</option>
                      <option value="1">
                        India{" "}
                        <img
                          src="/assets/images/flag-new.png"
                          className="ml-1"
                        />
                      </option>
                      <option value="2">America</option>
                      <option value="3">UAE</option>
                    </Form.Select>
                    <div className="or-block2 my-2">
                      <span>OR</span>
                    </div>
                    <Form.Control
                      className=""
                      type="text"
                      placeholder="Enter City Name to Search"
                    />
                    <button className="">Save</button>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="mobile-header-parent">
        {headershow == 1 && (
          <MobileHeader />
        )}

      </div>
      {(headershow == 2 || headershow == 3) && (
        <div className="seller-dashb-header">
          <nav className="navbar d-flex justify-content-between align-items-center navbar-expand-lg navbar-light bg-light px-5">
            <div className="d-flex align-items-center gap-5">
              <Link className="navbar-brand" to="/" target="_blank">
                {filterdata("header_logo") && filterdata("header_logo") && (
                  <img
                    src={
                      IMAGE_BASE_URL + filterdata("header_logo").image.file_name
                    }
                    alt="logo"
                  />
                )}
              </Link>
              <img src="/assets/Icons/explore-bar.svg" alt="" width={"20px"} />
            </div>
            {/* <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div className="header-mid-cont d-flex align-items-center gap-3">
              <div className="d-flex gap-2 align-items-center">
                <img src="/assets/Icons/sunny.png" alt="" />
                <h6 className="mb-0">42°C</h6>
              </div>
              
              <select className="select-cont form-select bg-transparent border-0" aria-label="Default select example" style={{width:"fit-content"}}>
                    <option selected>Lucknow</option>
                    <option value="1">Noida</option>
                    <option value="2">Delhi</option>
                    <option value="3">Banglore</option>
              </select>

              <p className="mb-0">Monday, Jan 12th, 03:30 PM</p>

            </div>

            <div className="" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto d-flex align-items-center">
                <li className="nav-item active seller-d-link">
                  {headershow == 2 && (
                    <Link className="nav-link" to="/vendor-dashboard">
                      Business Dashboard
                      <span>
                        <i class="fa fa-arrow-up-right-from-square"></i>
                      </span>
                    </Link>
                  )}
                  {headershow == 3 && (
                    <Link className="nav-link" to="/dashboard">
                      Customer Dashboard
                      <span>
                        <i class="fa fa-arrow-up-right-from-square"></i>
                      </span>
                    </Link>
                  )}
                </li>
                {headershow == 3 && (
                  <li className="nav-item more-icons">
                    <Link className="nav-link py-0" to="#">
                      <div class="dropdown">
                        <button
                          class="btn border-0 py-0 dropdown-toggle bb-transparent text-dark"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src="/assets/icons/more-icons.png"
                            alt=""
                            className="rounded-circle"
                            width={"24px"}
                            height={"24px"}
                          />
                        </button>
                        <ul class="dropdown-menu micon-parent pt-0 pb-0">
                          <li className="d-flex micon">
                            <Link class="dropdown-item text-center" to="#">
                              <span>
                                <img
                                  src="/assets/icons/enquiry.png"
                                  alt=""
                                  width={"24px"}
                                />
                              </span>
                              <p>Inquiries</p>
                            </Link>
                            {seller && seller?.package_type == "Product" && (
                              <Link class="dropdown-item text-center" to="#">
                                <span>
                                  <img
                                    src="/assets/icons/catalogue.png"
                                    alt=""
                                    width={"24px"}
                                  />
                                </span>
                                <p>Catalogue</p>
                              </Link>
                            )}
                            {seller && seller?.package_type == "Services" && (
                              <Link class="dropdown-item text-center" to="#">
                                <span>
                                  <img
                                    src="/assets/icons/catalogue.png"
                                    alt=""
                                    width={"24px"}
                                  />
                                </span>
                                <p>Manage Services</p>
                              </Link>
                            )}
                          </li>
                          <li className="d-flex micon">
                            <Link class="dropdown-item text-center" to="#">
                              <span>
                                <img
                                  src="/assets/icons/carbon_review.png"
                                  alt=""
                                  width={"24px"}
                                />
                              </span>
                              <p>Reviews & Rating</p>
                            </Link>
                            <Link class="dropdown-item text-center" to="#">
                              <span>
                                <img
                                  src="/assets/icons/wallet.png"
                                  alt=""
                                  width={"24px"}
                                />
                              </span>
                              <p>Wallet</p>
                            </Link>
                          </li>
                          <li className="d-flex micon">
                            <Link class="dropdown-item text-center" to="#">
                              <span>
                                <img
                                  src="/assets/icons/subscription.png"
                                  alt=""
                                  width={"24px"}
                                />
                              </span>
                              <p>Subscription</p>
                            </Link>
                            <Link class="dropdown-item text-center" to="#">
                              <span>
                                <img
                                  src="/assets/icons/help-support.png"
                                  alt=""
                                  width={"24px"}
                                />
                              </span>
                              <p>Help & Support</p>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </li>
                )}

                <li className="nav-item">
                  <Link className="nav-link py-0" to="#">
                    <div class="dropdown">
                      <button
                        class="btn border-0 py-0 dropdown-toggle bg-white text-dark"
                        onClick={() => setShowNotif("0%")}
                      >
                        <i class="fa fa-bell"></i>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <Link class="dropdown-item" to="/my-account">
                            Profile
                          </Link>
                        </li>
                        {headershow == 2 && (
                          <li>
                            <Link class="dropdown-item" to="/customer-faq">
                              FAQ
                            </Link>
                          </li>
                        )}
                        {headershow == 3 && (
                          <li>
                            <Link class="dropdown-item" to="/seller-faq">
                              FAQ
                            </Link>
                          </li>
                        )}

                        {headershow == 3 && (
                          <li>
                            <Link class="dropdown-item" to="/dashboard">
                              User Dashboard
                            </Link>
                          </li>
                        )}
                        {headershow == 2 && (
                          <li>
                            <Link class="dropdown-item" to="/vendor-dashboard">
                              Seller Dashboard
                            </Link>
                          </li>
                        )}

                        <li>
                          <Link
                            class="dropdown-item"
                            to="/"
                            onClick={() => {
                              toggleCloseAllDropdown();
                              handleLogOut();
                            }}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link className="nav-link py-0" to="#">
                    <div class="dropdown">
                      <button
                        class="btn rounded-circle border-0  py-0 dropdown-toggle bg-white text-dark"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          className="rounded-circle"
                          src={
                            appState?.state?.user?.profileImage
                              ? appState?.state?.user?.profileImage
                              : "/assets/images/user.jpg"
                          }
                          alt=""
                          width="45px"
                        />
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                          <Link class="dropdown-item" to="/my-account">
                            Profile
                          </Link>
                        </li>
                        {headershow == 2 && (
                          <li>
                            <Link class="dropdown-item" to="/customer-faq">
                              FAQ
                            </Link>
                          </li>
                        )}
                        {headershow == 3 && (
                          <li>
                            <Link class="dropdown-item" to="/seller-faq">
                              FAQ
                            </Link>
                          </li>
                        )}

                        {headershow == 3 && (
                          <li>
                            <Link class="dropdown-item" to="/dashboard">
                              User Dashboard
                            </Link>
                          </li>
                        )}
                        {headershow == 2 && (
                          <li>
                            <Link class="dropdown-item" to="/vendor-dashboard">
                              Seller Dashboard
                            </Link>
                          </li>
                        )}

                        <li>
                          <Link
                            class="dropdown-item"
                            to="/"
                            onClick={() => {
                              toggleCloseAllDropdown();
                              handleLogOut();
                            }}
                          >
                            Logout
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div
            className="notification"
            style={{ maxWidth: "450px", right: showNotif }}
          >
            <div className="notif-content px-3 py-3">
              <div className="top-cont d-flex justify-content-between">
                <h6>Notification</h6>
                <button
                  className="close-notif"
                  onClick={() => setShowNotif("-100%")}
                >
                  ☓
                </button>
              </div>

              <div className="notif-body">
                <Link
                  to=""
                  className="notif1 py-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src="/assets/images/avatar.png"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                      className="rounded-cricle"
                    />
                    <div>
                      <h5 className="mb-0">lorem ipsum</h5>
                      <p className="mb-0">High fived your workout</p>
                      <p className="time-ago mb-0">0 min</p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="notif-dot"></span>
                    <span>
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </div>
                </Link>
                <Link
                  to=""
                  className="notif1 py-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src="/assets/images/avatar.png"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                      className="rounded-cricle"
                    />
                    <div>
                      <h5 className="mb-0">lorem ipsum</h5>
                      <p className="mb-0">High fived your workout</p>
                      <p className="time-ago mb-0">0 min</p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="notif-dot"></span>
                    <span>
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </div>
                </Link>
                <Link
                  to=""
                  className="notif1 py-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src="/assets/images/avatar.png"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                      className="rounded-cricle"
                    />
                    <div>
                      <h5 className="mb-0">lorem ipsum</h5>
                      <p className="mb-0">High fived your workout</p>
                      <p className="time-ago mb-0">0 min</p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="notif-dot"></span>
                    <span>
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </div>
                </Link>
                <Link
                  to=""
                  className="notif1 py-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src="/assets/images/avatar.png"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                      className="rounded-cricle"
                    />
                    <div>
                      <h5 className="mb-0">lorem ipsum</h5>
                      <p className="mb-0">High fived your workout</p>
                      <p className="time-ago mb-0">0 min</p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="notif-dot"></span>
                    <span>
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </div>
                </Link>
                <Link
                  to=""
                  className="notif1 py-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src="/assets/images/avatar.png"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                      className="rounded-cricle"
                    />
                    <div>
                      <h5 className="mb-0">lorem ipsum</h5>
                      <p className="mb-0">High fived your workout</p>
                      <p className="time-ago mb-0">0 min</p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="notif-dot"></span>
                    <span>
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </div>
                </Link>
                <Link
                  to=""
                  className="notif1 py-3 d-flex justify-content-between align-items-center"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img
                      src="/assets/images/avatar.png"
                      alt=""
                      width={"40px"}
                      height={"40px"}
                      className="rounded-cricle"
                    />
                    <div>
                      <h5 className="mb-0">lorem ipsum</h5>
                      <p className="mb-0">High fived your workout</p>
                      <p className="time-ago mb-0">0 min</p>
                    </div>
                  </div>

                  <div className="d-flex gap-3 align-items-center">
                    <span className="notif-dot"></span>
                    <span>
                      <i className="fa fa-chevron-right"></i>
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default HeaderPage;
