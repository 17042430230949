import React from 'react';
import CountUp from 'react-countup';

const ProductsCard = () => {
    return (
        <>
            <div className='container pd-cards'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-1.png" />
                            <h6>Browse Products</h6>
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-2.png" />
                            <h6>Browse Services</h6>
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-3.png" />
                            <h6>Browse Directory</h6>
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-4.png" />
                            <h6>Browse Startup</h6>
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductsCard;