import React, { useContext,useState,useEffect } from "react";

import "../style/CategoryPage.css";
import BannerPage from "../components/apna-shaher/components/Banner";
import { SecondCommon } from "../components/category-page/SecondCommon";
import TwoBannerSection from "../components/TwoBannerSection";
import StateContext from "../context/StateContext";
import Discover from "../components/products/Components/Discover";
import { BASE_URL } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/Loader.component";
import { BannerNew } from "../components/apna-shaher/components/BannerNew";
export const CategoryPage = () => {
const {slug} = useParams();
    const { homepageSetting } = useContext(StateContext);
  const [categories,setCategories] = useState([]);
const [loading,setloading] = useState(true)
  useEffect(()=>{
    FetchCategories();
  },[slug])
    const FetchCategories = async () => {
      setCategories("");
      setloading(true)
      await axios.get(BASE_URL + "/getcategoriesbytype/"+slug)
        .then(({ data }) => {
          setCategories(data.data);
          setloading(false)
        });
    };
    if(loading){
return <Loader />
    }

  return (
    <div className="category-pare-parent">
      <section className="banner-page">
      <div className="show-desktop-banner">
              <BannerPage />

            </div>
            <div className="show-mobile-banner">
              <BannerNew />

            </div>
      </section>
      {categories && categories.length > 0 ?(
        <>
        {categories && categories?.map((category,index)=>(
<>
      <section className="second-common-section" key={index}>
        <SecondCommon slug={slug} category={category} />
      </section>
      {(index +1)%2 == 0 &&(
        <section  className="electronic-cosmatic-section ">
        <TwoBannerSection homepageSetting={homepageSetting}/>
      </section>
      )}
      
</>
))}
        </>
      ):(
        <>
         <section className="second-common-section" style={{marginLeft:"35%","margin-top":"100px"}}>
          <h3>
          {slug == "stock-clearance" && "No Clearance Sale Found"}
          {slug == "flash-deals" && "No Deal Found"}
          {slug == "new-arrival" && "No New Arrival Found"}
          {slug == "products-page" && "No Category Found"}

          </h3>
         
         </section>
        
        </>
      )}

      

      {/* <section  className="electronic-cosmatic-section ">
        <TwoBannerSection homepageSetting={homepageSetting}/>
      </section>

      <section className="second-common-section">
        <SecondCommon />
      </section>

        <section className="discover-sec">
            <Discover/>
        </section>

        <section  className="electronic-cosmatic-section  mt-5 mb-4">
        <TwoBannerSection homepageSetting={homepageSetting}/>
      </section>

      <section className="second-common-section">
        <SecondCommon />
      </section>

      <section className="second-common-section">
        <SecondCommon />
      </section> */}

    </div>
  );
};
