import { useContext, useState } from "react";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";

const SendWithdrawRequest = ({ showAdd, setShowAdd }) => {
  const handleClose = () => {
    setShowAdd(false);
  };

  //   async function handleSubmit(e) {
  //     e.preventDefault();
  //     setButtonDisabled(true);
  //     try {
  //       setErrors([]);
  //       const response = await Axios.post(
  //         '/add-address',
  //         {
  //           address,
  //           firstName,
  //           lastName,
  //           city,
  //           state,
  //           country,
  //           zipcode,
  //           landmark,
  //           phone,
  //           emailId,
  //           isDefault,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${appState.state.user.token}`,
  //           },
  //         }
  //       );
  //       const responseData = response.data.data;
  //       console.log(responseData);
  //       // setAddresses([...addresses, responseData.address]);
  //       setErrors([]);
  //       setButtonDisabled(false);
  //       setButtonText('Submit');
  //       appDispatch({ type: 'flashMessage', value: 'Address Added' });
  //       setShowAdd(false);
  //     } catch (error) {
  //       setButtonDisabled(false);
  //       setButtonText('Submit');
  //       if (error.response.status === 422) {
  //         const response = await error.response.data;
  //         setErrors(response.errors);
  //       } else {
  //         console.log('Error encountered');
  //       }
  //     }
  //   }

  return (
    <Modal
      size="md"
      onHide={handleClose}
      className="withdraw-modal-block"
      show={showAdd}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <h5 class="modal-title">Send A Withdraw Request</h5>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-md-4">
              <Form.Label>Amount *</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Control required type="text" placeholder="My Shop" />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Label>Message</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Control as="textarea" placeholder="My Shop" rows={5} />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" type="submit">
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SendWithdrawRequest;
