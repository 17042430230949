import React, { useState } from "react";



export default function SellerFAQ({data,homepagewidget}) {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div>
            <div className="col-md-12 mt-8 mb-8">
                <div className="seller-product-heading">{homepagewidget?.widget8}</div>
                <div className="blog-sub-heading mt-4">{homepagewidget?.widget9}</div>
                <div className="row faqbox" style={{marginLeft:0, marginRight:0}}>
                    {data.map((item, i) => (
                        <div className="col-md-6">
                            <div className="faq-container">
                            <div className="fax-container mt-2 mb-2" key={i}>
                                <div>
                               {item.question}
                                </div>
                                <button onClick={() => handleClick(i)}>
                                    {activeIndex === i ? 
                                     <img src="assets/images/minimise.png" /> : <img src='./assets/images/collapse.png' />}
                                </button>
                            </div>
                            <div>
                            {activeIndex === i &&
                                    <div>{item.answer}</div>}
                            </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        </div>
    )
}