import { Col, Form, Row } from "react-bootstrap";
import Loader from "../../../../components/loader/Loader.component";
import ProductGridItem from "../../../../../src/components/product/product-grid-item.component";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../../src/config";
import StateContext from "../../../../context/StateContext";
import { Link } from "react-router-dom";

const NewProduct = ({
    isLoading,
    title,
    products,
    productCount,
    setSortBy,
}) => {
    const [locationname, setlocationname] = useState("");
    const [locationsearch, setlocationsearch] = useState([]);
    const [newProducts, setNewProducts] = useState([]);
    const [popularcity, setpopularcity] = useState([]);
    const [drop, setDrop] = useState(false);
    const { locationdata, setlocationdata, countrycity, setcountrycity, currencysymbol } = useContext(StateContext);
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };


    useEffect(() => {
        getpopularcity();
        getNewProducts();
    }, [locationdata]);
    function setSort(e) {
        setSortBy(e.target.value);
    }
    const [toggleViewMode, setToggleViewMode] = useState(false);
    const listToggle = () => {
        setToggleViewMode(!toggleViewMode);
    };
    const getlocationsearch = () => {
        axios
            .post(BASE_URL + "/getlocation", { search: locationname })
            .then(({ data }) => {
                setlocationsearch(data.data);
            })
            .catch(({ response }) => { });
    };
    // const getlocation = ()=>{
    //   axios.get("https://ipapi.co/json/").then(({data})=>{
    //     setlocationdata(data);
    //     getpopularcity(data.country_name)
    //   })
    // }

    const getNewProducts = () => {
        axios
            .post(BASE_URL + "/stockclearnaceproduct",{})
            .then(({ data }) => {
                setNewProducts(data.data.products);
            })
            .catch(({ response }) => { });
    };
    console.log(newProducts, "new arrivals");
    const getpopularcity = () => {
        axios
            .post(BASE_URL + "/getpopularcity", { name: locationdata.country_name })
            .then(({ data }) => {
                setpopularcity(data.data);
            })
            .catch(({ response }) => { });
    };

    const topThreeCity = popularcity.slice(0, 2)
    const otherCities = popularcity.slice(3);
    const topTwoCity = popularcity.slice(0, 1)
    const MobileMoreCities = popularcity.slice(1);

    return (
        <div className="leftside-block">
            <div className="hide-in-mobile">
                <div className="shopsearch-grid-blk">
                    <Row>
                        <Col md={7}>
                            <div
                                key={toggleViewMode}
                                onClick={listToggle}
                                className="gridlist-item-blk"
                            >
                                <div className="shopheader-blk">
                                    <div className="search">
                                        <div
                                            onClick={(e) => {
                                                setDrop(!drop);
                                            }}
                                            className=""
                                        >
                                            <div className="select-location">
                                                <button onClick={() => setDrop(!drop)} style={{ border: '1px solid lightgray', borderRadius: 5 }}>
                                                    <img
                                                        src="../assets/images/location-icon.png"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Select Location
                                                    <span>
                                                        <img src="../assets/images/search-icon.png" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="search-content"
                                            style={{ display: drop ? "block" : "none" }}
                                        >
                                            <div className="prefer-location center">
                                                <div className="prefer-location-button">
                                                    <input
                                                        placeholder="search country or city"
                                                        className="location-input"
                                                        onChange={(e) => setlocationname(e.target.value)}
                                                    />
                                                    <span onClick={getlocationsearch}><img src="../assets/images/search-icon.png" /></span>
                                                </div>
                                            </div>
                                            <div className="prefer-location-container">
                                                {locationsearch &&
                                                    locationsearch.map((item, i) => (
                                                        <div className="pop-locations" key={i} onClick={() => setcountrycity(item.cnname)}>
                                                            <img
                                                                src="../assets/images/location-icon.png"
                                                                style={{ marginRight: "5px" }}
                                                            />{" "}
                                                            {item.cnname}
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="pop-location">
                                                Popoular Location in {locationdata.country_name}
                                            </div>
                                            <div className="prefer-location-container">
                                                {popularcity &&
                                                    popularcity.map((item, i) => (
                                                        <div className="pop-locations" key={i} onClick={() => setcountrycity(item.name)}>
                                                            <img
                                                                src="../assets/images/location-icon.png"
                                                                style={{ marginRight: "5px" }}
                                                            />{" "}
                                                            {item.name}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hide-in-mobile">
                                    <div className="pop-cities">
                                        <div className="pop-city" onClick={() => setcountrycity(locationdata.country_name)}>
                                            All {locationdata.country_name}
                                        </div>
                                        {topThreeCity &&
                                            topThreeCity.map((item, i) => (
                                                <div className="pop-city" key={i} onClick={() => setcountrycity(item.name)}>
                                                    {item.name}

                                                </div>
                                            ))}
                                        <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <button className="dropdown-button">More <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                                            {isDropdownVisible && (
                                                <div className="dropdown-menu">
                                                    {otherCities.map((item, index) => (
                                                        <ul>
                                                            <li>{item.name}</li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="hide-in-desktop">
                                    <div className="pop-cities">
                                        <div className="pop-city" onClick={() => setcountrycity(locationdata.country_name)}>
                                            All {locationdata.country_name}
                                        </div>
                                        {topTwoCity &&
                                            topTwoCity.map((item, i) => (
                                                <div className="pop-city" key={i} onClick={() => setcountrycity(item.name)}>
                                                    {item.name}

                                                </div>
                                            ))}
                                        <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <button className="dropdown-button">More <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                                            {isDropdownVisible && (
                                                <div className="dropdown-menu">
                                                    {MobileMoreCities.map((item, index) => (
                                                        <ul>
                                                            <li>{item.name}</li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="hide-in-mobile">
                                <div className="sortby-block">
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => setSort(e)}
                                    >
                                        <option >Sort By</option>
                                        <option value="1">Popularity</option>
                                        <option value="2">Price - Low to High</option>
                                        <option value="3">Price - High to Low</option>
                                        <option value="4">Newest First</option>
                                    </Form.Select>
                                    <a href="" className="gridlist-btn active" style={{ marginLeft: 10 }}>
                                        <i className="bi bi-grid"></i>
                                    </a>
                                    <a href="" className="gridlist-btn">
                                        <i className="bi bi-list-task"></i>
                                    </a>
                                    <span>{productCount}</span>
                                    {toggleViewMode}
                                </div>
                            </div>
                            <div className="hide-in-desktop">
                                <div className="sortby-block">
                                    <div>
                                        <Form.Select
                                            aria-label="Default select example"
                                            onChange={(e) => setSort(e)}
                                        >
                                            <option >Sort By</option>
                                            <option value="1">Popularity</option>
                                            <option value="2">Price - Low to High</option>
                                            <option value="3">Price - High to Low</option>
                                            <option value="4">Newest First</option>
                                        </Form.Select>
                                    </div>
                                    <div>
                                        <a href="" className="gridlist-btn active" style={{ marginLeft: 10 }}>
                                            <i className="bi bi-grid"></i>
                                        </a>
                                        <a href="" className="gridlist-btn">
                                            <i className="bi bi-list-task"></i>
                                        </a>
                                        <span>{productCount}</span>
                                        {toggleViewMode}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="shop-product-blk">
                <Row>
                    {newProducts.map((product, index) => (
                        <div className="col-md-4 col-sm-4 col-xs-6 col-6">
                            <div className="product-block product-shoppage">
                                <Link to={`/product/${product.slug}`}>
                                    <div className="product-img-blk">
                                        <span className="sale-blk">Sale</span>
                                        <img src={product.thumbnail} alt={product.name} />
                                    </div>
                                    <div className="product-cont-blk">
                                        <h6 style={{ textAlign: 'center', color: 'black', fontSize: '15px', fontWeight: '600', fontFamily: 'Helvetica', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{product.name}</h6>
                                        <div className='' style={{ alignItems: 'baseline', display: 'flex', justifyContent: 'center', fontFamily: "sans-serif", gap: "10px" }}>
                                            <p style={{ fontSize: '18px' }}> {currencysymbol}{product.saleprice1} </p>
                                            {<span style={{ }}><del style={{ color: 'grey' }}>{currencysymbol}{product.unit_price}</del></span>}

                                            {
                                                product.discount_type == "percentage" && (
                                                    <span style={{ paddingLeft: '20px', color: 'green', fontSize: '17px' }}>{product.discount}%</span>
                                                )
                                            }
                                            {
                                                product.discount_type == "amount" && (
                                                    <span style={{ color: 'green', fontSize: '17px' }}>{Math.round(((product.unit_price - product.saleprice1) / product.saleprice1) * 100)}%</span>
                                                )
                                            }
                                        </div>

                                        {
                                            product.discount_type == "percentage" && (
                                                <p style={{ color: 'green', fontSize: '14px', fontFamily: "sans-serif", marginTop: "-6px" }}>You Saved {currencysymbol}{Math.round(product.unit_price - product.saleprice1)}</p>

                                            )
                                        }
                                        {
                                            product.discount_type == "amount" && (
                                                <p style={{ color: 'green', fontSize: '14px', fontFamily: "sans-serif", marginTop: "-6px" }}>You Saved {currencysymbol}{Math.round(product.unit_price - product.saleprice1)}</p>

                                            )
                                        }
                                    </div>
                                </Link>

                                {/* {wishlist ? (
                                <span
                                    className="btn btn-danger"
                                    onClick={() => {
                                        removeFromWishlist(product.id);
                                    }}
                                >
                                    Remove
                                </span>
                            ) : (
                                ''
                            )} */}
                            </div>
                        </div>
                    ))}
                </Row>
            </div>
        </div>
    );
};

export default NewProduct;
