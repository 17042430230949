import React from 'react';
import { Link, useLocation } from 'react-router-dom';


export const ThankYou = () => {
    const state = useLocation();
   const data = state.state;
   console.log(data)
  return (
    <div className='thankyou-section'>
        <div className='text-center'>
            {/* <img src={logo}/> */}
        </div>
        <hr className='thankyou-hr'/>
        <h4>Hello {data.company_name}</h4>
        <h1>Thank You!</h1>
        <p className='thankyou-pera'>Thank You for Investing with Us. For Investing with Apna Shaher, Please find the Transaction Detail below</p>
        <hr />
        <div>
        <div className='thankyou-content'>
                <p>Package Name:</p>
                <h6>{data && data.pack_name}</h6>
            </div>
            {data.amount != 0 &&(
                <div className='thankyou-content'>
                <p>Transaction ID :</p>
                <h6>#{data && data.trans_id}</h6>
            </div>
            )}
            
            <div className='thankyou-content'>
                <p>Company Name :</p>
                <h6>{data && data.company_name}</h6>
            </div>
            {data.amount != 0 &&(
            <div className='thankyou-content'>
                <p>Total Amount :</p>
                <h6>Rs. {data && data.amount}</h6>
            </div>
             )}
        </div>
        <hr />
        <p>For more query Contact Us on: 1234567890  or email us on 123@dummy.com</p>
        <div className='thankyou-btn'>
            <Link to="/vendor-dashboard"><button>Go To Dashboard</button></Link>
        </div>
    </div>
  )
}
