import React, { useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import ReactStars from 'react-stars';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { BASE_URL } from '../config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const slider = [
    { id: 1 }, { id: 2 }, { id: 3 }
]

const Manufactures = () => {
    const ratingChanged = (newRating) => {
        console.log(newRating)
    }

    useEffect(() => {
        getCountry();
        ManufacturerData();
    }, [])



    const [callbackName, setCallbackName] = useState('');
    const [callbackMobile, setCallbackMobile] = useState();
    const [callbackEmail, setCallbackEmail] = useState('');
    const [interested, setInterested] = useState();
    const [details, setDetails] = useState('');
    const [sellerName, setSellerName] = useState('');
    const [selleremail, setSellerEmail] = useState('');
    const [sellermobile, setSellerMobile] = useState('');
    const [businessNature, setBusinessNature] = useState('');
    const [fetchCountry, setFetchedCountry] = useState([]);
    const [fetchState, setFetchedState] = useState([]);
    const [fetchCity, setFetchedCity] = useState([]);
    const [country, setCountry] = useState([]);
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [sellerDetails, setSellerDetails] = useState("");
    const [data, setData] = useState([]);
    const [datas, setDatas] = useState([]);
    const [errors, setError] = useState([])
    const [err, setErr] = useState([])
    const [filteractive, setfilteractive] = useState("");
    console.log('callbackName', callbackName)
    const [categorys,setcategorys] = useState([])
    const getCountry = () => {

        axios.get(`${BASE_URL}/country`)
            .then(function (res) {
                setFetchedCountry(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    }
    const getState = (name) => {

        axios.get(`${BASE_URL}/state/${name}`)
            .then(function (res) {
                setFetchedState(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    }
    const getCity = (id) => {
        axios.get(`${BASE_URL}/city/${id}`)
            .then(function (res) {
                setFetchedCity(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    };


    const Addfreecallback = async () => {
        const data = {
            "name": callbackName,
            "email": callbackEmail,
            "mobile_number": callbackMobile,
            "interested_in": "yes",
            "details": details
        }
        await axios.post(BASE_URL + '/addfreecallback', data).then(({ data }) => {
            console.log('get data', data)
            if (data.status == true) {
                toast.success(data.message)
                document.getElementById("formdata").reset();
            }

        }).catch(({ response }) => {
            setError(response.data.message)
        })
    }

    const Addsellercallback = async () => {
        const data = {
            "name": sellerName,
            "email": selleremail,
            "mobile_number": sellermobile,
            "interested_in": "yes",
            "nature_of_business": businessNature,
            "country": country,
            "state": state,
            "city": city,
            "details": sellerDetails
        }
        await axios.post(BASE_URL + '/addsellercallback', data).then(({ data }) => {
            console.log('get data', data)
            if (data.status == true) {
                toast.success(data.message)
                document.getElementById("sellerform").reset();
            }

        }).catch(({ response }) => {
            setErr(response.data.message)
            console.log(response.data.message)
        })
    }

    const ManufacturerData = async () => {
        const data = {
            "business_nature":"Exporter & Importer"
        }
        await axios.post(BASE_URL + '/getsellerdata', data).then(({ data }) => {
            setData(data.data)
            setDatas(data.data)
            setcategorys(data.category)
        }).catch(({ response }) => {
            console.log(response.data.message)
        })
    }
    const filterdata =(item)=>{
        console.log(item)
      const d=  (new Date().toLocaleString('en-us', {weekday: 'long'})).toLowerCase()
        const e = item.filter((e)=> e.day == d );
        if(e.length > 0){
            console.log(e[0])
            return e[0];
        }
    }
    const filteradverified=()=>{
        var d =  datas.filter((e)=>e.verified_seller=="Yes")
        setData(d)
      
      
      }
      const filtertrusted=()=>{
          var d =  datas.filter((e)=>e.trust_seal=="Yes")
          setData(d)
      }
      function cahechcategory(category,id) {
        return category == id;
      }
    const filtercategory=(id)=>{
        var d =  datas.filter((e)=>e.category.length > 0 && (e.category).find((category)=>cahechcategory(category,id)))
        console.log(d,"=====---")
        setData(d)
    }
    const filtertolowhigh=async()=>{
        const data = {
            "business_nature":"Exporter & Importer"
        }
        await axios.post(BASE_URL + '/getsellerdataasc', data).then(({ data }) => {
            setData(data.data)
            setDatas(data.data)
            setcategorys(data.category)
        }).catch(({ response }) => {
            console.log(response.data.message)
        })
    }
    const filterhightolow=async()=>{
        const data = {
            "business_nature":"Exporter & Importer"
        }
        await axios.post(BASE_URL + '/getsellerdatadesc', data).then(({ data }) => {
            setData(data.data)
            setDatas(data.data)
            setcategorys(data.category)
        }).catch(({ response }) => {
            console.log(response.data.message)
        })
    }
    return (
        <div>
            <div className="menu-cont">
                <div className="row">
                    <div className="col-md-6">
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            interval={2500}
                            showStatus={false}
                            showArrows={false}
                            showIndicators={false}
                            infiniteLoop={true}
                        >
                            {slider.map((data) => (
                                <div><img src="assets/images/banner_interiordesigners 1.png" height={230} /></div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-md-6 row">
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 2.png" /></div>
                        </div>
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 3.png" /></div>
                        </div>
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 4.png" /></div>
                        </div>
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 5.png" /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="manufactures-section">
                <div className="dropdown-section">
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Category
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {categorys && categorys.map((item,i)=>(
                            <Dropdown.Item onClick={()=>filtercategory(item.name)} href={"#/action-"+item.id}>{item.name}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Rating
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1"><ReactStars
                                                count={5}
                                                edit={false}
                                                value={5}
                                                // onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            /></Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><ReactStars
                                                count={5}
                                                edit={false}
                                                value={4}
                                                // onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            /></Dropdown.Item>
                            <Dropdown.Item href="#/action-3"><ReactStars
                                                count={5}
                                                edit={false}
                                                value={3}
                                                // onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            /></Dropdown.Item>
                                            <Dropdown.Item href="#/action-3"><ReactStars
                                                count={5}
                                                edit={false}
                                                value={2}
                                                // onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            /></Dropdown.Item>
                                            <Dropdown.Item href="#/action-3"><ReactStars
                                                count={5}
                                                edit={false}
                                                value={1}
                                                // onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            /></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Popularity
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1" onClick={()=>filterhightolow()} >High to Low</Dropdown.Item>
                            <Dropdown.Item href="#/action-2" onClick={()=>filtertolowhigh()}>Low to High</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                   <div className="ad-verified-btn">
                    <button onClick={()=>{filteradverified();setfilteractive("verify")}} className={filteractive == "verify" && "btn btn-primary"}>Ad Verified</button>
                   </div>
                   <div className="ad-verified-btn">
                    <button onClick={()=>{filtertrusted();setfilteractive("trust")}} className={filteractive == "trust" && "btn btn-primary"}>Ad Trust</button>
                   </div>
                </div>
                {/* Cards Section */}
                <div>
                    <div className="row">
                        <div className="col-md-9">
                            <div>
                                {data && data.map((item,i) => (
                                <div className="row partners-cards" key={i}>
                                    <div className="col-md-3">
                                        <Link to={"/seller-detail/"+item.business_slug}> <img src={item.logo} /></Link>
                                    </div>
                                    <div className="col-md-9">

                                        <Link to={"/seller-detail/"+item.business_slug}><h6><img src="assets/images/thums-up.png" />{item.business_name}</h6></Link>
                                        <div className="rating-section">
                                            <p>3.9</p>
                                            <ReactStars
                                                count={5}
                                                onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            />
                                           {item.trust_seal=="Yes" &&(
                                                <div><img src="/assets/images/trust.png" /></div>
                                            )}
                                            {item.verified_seller =="Yes" &&(
                                                <div><img src="/assets/images/verify.png" /></div>
                                            )}
                                        </div>
                                        <h2>{item.full_address}</h2>
                                        <div className="shop-details">
                                        {filterdata(item.business_opening) &&(
                                                <h3><span>{filterdata(item.business_opening).status}</span>{filterdata(item.business_opening).status =="open" &&(
                                                        <>
                                                        {filterdata(item.business_opening).in_time} to {filterdata(item.business_opening).out_time}
                                                        </>
                                                )} </h3>
                                            )}
                                            <h3 className="opened-year">{item.estblish_year && (new Date().getUTCFullYear()-item.estblish_year)+" Years in Business"}</h3>
                                        </div>
                                        <div className="shop-types">
                                            {item.category.map((data) => (
                                                <button>{data}</button>
                                            ))}
                                        </div>
                                        <div>
                                            {/* <p><img src="assets/images/sms-icon.png" />
                                                “Very good shop for shopping they charged reasonable price i purchased lots of things
                                                from this shop like washing machine, microwave oven, fridge and tv. and all products ... more
                                            </p> */}
                                        </div>
                                        <div className="jio-mart-partner">
                                            <div className="shop-button-section">
                                            <button><img src="/assets/images/shop-btn-sms.png" /><a style={{color:"white"}} href={"sms:"+item.whatsapp_number}>Chat Now</a></button>
                                            <button className="send-email-btn"><a href={"mailto:"+item.business_email}><img src="/assets/images/email-icon.png" />Send Email</a></button>
                                                <button><a style={{color:"white"}} href={"tel:"+item.business_mobilenumber}>Show Contact</a></button>
                                            </div>
                                            <div>
                                                {/* <h3 className="growth-pera"><img src="assets/images/growth-icon.png" />100 Customer Inquired this profile in last 30 Days</h3> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 ))}
                            </div>
                            <ToastContainer />

                            {/* <div>
                                <div className="row partners-cards">
                                    <div className="col-md-3">
                                    <Link to="/seller-details"><img src="assets/images/jio-mart.png" /></Link>
                                    </div>
                                    <div className="col-md-9">
                                        <Link to="/seller-details"><h6><img src="assets/images/thums-up.png" />Shakti Electronics & Appliances (JioMart Digital Partner)</h6></Link>
                                        <div className="rating-section">
                                            <p>3.9</p>
                                            <ReactStars
                                                count={5}
                                                onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            />
                                            <div><img src="assets/images/turst-verified.png" /></div>
                                            <div><img src="assets/images/india-flag.png" /></div>
                                        </div>
                                        <h2>Jawahar Nagar-goregaon West</h2>
                                        <div className="shop-details">
                                            <h3><span>Open</span>  until 9:30 pm</h3>
                                            <h3 className="opened-year">43 Years in Business</h3>
                                        </div>
                                        <div className="shop-types">
                                            <button>Electronic Goods Showrooms</button>
                                            <button>Electronic Goods Showrooms</button>
                                        </div>
                                        <div>
                                            <p><img src="assets/images/sms-icon.png" />
                                                “Very good shop for shopping they charged reasonable price i purchased lots of things
                                                from this shop like washing machine, microwave oven, fridge and tv. and all products ... more
                                            </p>
                                        </div>
                                        <div className="jio-mart-partner">
                                            <div className="shop-button-section">
                                                <button><img src="assets/images/shop-btn-sms.png" />Chat Now</button>
                                                <button className="send-email-btn"><img src="assets/images/email-icon.png" />Send Email</button>
                                                <button>Show Contact</button>
                                            </div>
                                            <div>
                                                <h3 className="growth-pera"><img src="assets/images/growth-icon.png" />100 Customer Inquired this profile in last 30 Days</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div>
                                <div className="row partners-cards">
                                    <div className="col-md-3">
                                    <Link to="/seller-website"> <img src="assets/images/jio-mart.png" /> </Link>
                                    </div>
                                    <div className="col-md-9">
                                    <Link to="/seller-website">  <h6><img src="assets/images/thums-up.png" />Shakti Electronics & Appliances (JioMart Digital Partner)</h6> </Link>
                                        <div className="rating-section">
                                            <p>3.9</p>
                                            <ReactStars
                                                count={5}
                                                onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            />
                                            <div><img src="assets/images/turst-verified.png" /></div>
                                            <div><img src="assets/images/india-flag.png" /></div>
                                        </div>
                                        <h2>Jawahar Nagar-goregaon West</h2>
                                        <div className="shop-details">
                                            <h3><span>Open</span>  until 9:30 pm</h3>
                                            <h3 className="opened-year">43 Years in Business</h3>
                                        </div>
                                        <div className="shop-types">
                                            <button>Electronic Goods Showrooms</button>
                                            <button>Electronic Goods Showrooms</button>
                                        </div>
                                        <div>
                                            <p><img src="assets/images/sms-icon.png" />
                                                “Very good shop for shopping they charged reasonable price i purchased lots of things
                                                from this shop like washing machine, microwave oven, fridge and tv. and all products ... more
                                            </p>
                                        </div>
                                        <div className="jio-mart-partner">
                                            <div className="shop-button-section">
                                                <button><img src="assets/images/shop-btn-sms.png" />Chat Now</button>
                                                <button className="send-email-btn"><img src="assets/images/email-icon.png" />Send Email</button>
                                                <button>Show Contact</button>
                                            </div>
                                            <div>
                                                <h3 className="growth-pera"><img src="assets/images/growth-icon.png" />100 Customer Inquired this profile in last 30 Days</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div>
                                <div className="row partners-cards">
                                    <div className="col-md-3">
                                    <Link to="/seller-website"> <img src="assets/images/jio-mart.png" /> </Link>
                                    </div>
                                    <div className="col-md-9">
                                    <Link to="/seller-website">   <h6><img src="assets/images/thums-up.png" />Shakti Electronics & Appliances (JioMart Digital Partner)</h6> </Link>
                                        <div className="rating-section">
                                            <p>3.9</p>
                                            <ReactStars
                                                count={5}
                                                onChange={ratingChanged}
                                                size={24}
                                                color2={'#ffd700'}
                                                className="rating-stars"
                                            />
                                            <div><img src="assets/images/turst-verified.png" /></div>
                                            <div><img src="assets/images/india-flag.png" /></div>
                                        </div>
                                        <h2>Jawahar Nagar-goregaon West</h2>
                                        <div className="shop-details">
                                            <h3><span>Open</span>  until 9:30 pm</h3>
                                            <h3 className="opened-year">43 Years in Business</h3>
                                        </div>
                                        <div className="shop-types">
                                            <button>Electronic Goods Showrooms</button>
                                            <button>Electronic Goods Showrooms</button>
                                        </div>
                                        <div>
                                            <p><img src="assets/images/sms-icon.png" />
                                                “Very good shop for shopping they charged reasonable price i purchased lots of things
                                                from this shop like washing machine, microwave oven, fridge and tv. and all products ... more
                                            </p>
                                        </div>
                                        <div className="jio-mart-partner">
                                            <div className="shop-button-section">
                                                <button><img src="assets/images/shop-btn-sms.png" />Chat Now</button>
                                                <button className="send-email-btn"><img src="assets/images/email-icon.png" />Send Email</button>
                                                <button>Show Contact</button>
                                            </div>
                                            <div>
                                                <h3 className="growth-pera"><img src="assets/images/growth-icon.png" />100 Customer Inquired this profile in last 30 Days</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>
                        <div className="col-md-3">
                            <div className="manufactures-froms">
                                <form id="formdata">
                                    <h1>Get a Hassle Free Callback from Experts!</h1>
                                    <Form.Group className="mb-3">

                                        <Form.Control placeholder="Full Name" onChange={(e) => setCallbackName(e.target.value)} />
                                        {errors && errors.name ? <div className="text-danger">{errors.name}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="number" placeholder="Mobile Number" onChange={(e) => setCallbackMobile(e.target.value)} />
                                        {errors && errors.mobile_number ? <div className="text-danger">{errors.mobile_number}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Email Id" onChange={(e) => setCallbackEmail(e.target.value)} />
                                        {errors && errors.email ? <div className="text-danger">{errors.email}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Select onChange={(e) => setInterested(e.target.value)}>
                                            <option value="interested">Interested in</option>
                                            <option>Interested in</option>
                                            <option>Interested in</option>
                                            <option>Interested in</option>
                                        </Form.Select>

                                    </Form.Group>
                                    <FloatingLabel
                                        controlId="floatingTextarea"
                                        label="Enter Detail"
                                        className="mb-3"
                                    >
                                        <Form.Control as="textarea" placeholder="Leave a comment here" onChange={(e) => setDetails(e.target.value)} />
                                        {errors && errors.details ? <div className="text-danger">{errors.details}</div> : ''}
                                    </FloatingLabel>
                                </form>

                                <button className="form-submit-btn" onClick={Addfreecallback}>SUBMIT</button>
                            </div>
                            <div className="manufactures-froms">
                                <h1>Want to be a Seller? Quick Callback from Experts</h1>
                                <Form id="sellerform">
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="text" placeholder="Full Name" onChange={(e) => setSellerName(e.target.value)} />
                                        {err && err.name ? <div className="text-danger">{err.name}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="number" placeholder="Mobile Number" onChange={(e) => setSellerMobile(e.target.value)} />
                                        {err && err.mobile_number ? <div className="text-danger">{err.mobile_number}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control type="email" placeholder="Email Id" onChange={(e) => setSellerEmail(e.target.value)} />
                                        {err && err.email ? <div className="text-danger">{err.email}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Select onChange={(e) => setBusinessNature(e.target.value)}>
                                            <option value="">Nature of Business</option>
                                            <option value="Manufacturer">Manufacturer</option>
                                            <option value="Trader">Trader</option>
                                            <option value="Wholeseller">Wholeseller</option>
                                            <option value="Distributor">Distributor</option>
                                            <option value="Service Provider">Service Provider</option>
                                            <option value="Importer & Exporter">Importer & Exporter</option>
                                        </Form.Select>
                                        {err && err.nature_of_business ? <div className="text-danger">{err.nature_of_business}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Select onChange={(e) => { setCountry(e.target.value); getState(e.target.value) }}>
                                            <option>Select Country</option>
                                            {fetchCountry && fetchCountry.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))}
                                        </Form.Select>
                                        {err && err.country ? <div className="text-danger">{err.country}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Select onChange={(e) => { setState(e.target.value); getCity(e.target.value) }}>
                                            <option>Select State</option>
                                            {fetchState && fetchState.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))}
                                        </Form.Select>
                                        {err && err.state ? <div className="text-danger">{err.state}</div> : ''}
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Select onChange={(e) => setCity(e.target.value)}>
                                            <option>Select City</option>
                                            {fetchCity && fetchCity.map((data) => (
                                                <option value={data.id}>{data.name}</option>
                                            ))}
                                        </Form.Select>
                                        {err && err.city ? <div className="text-danger">{err.city}</div> : ''}

                                    </Form.Group>
                                    <FloatingLabel
                                        controlId="floatingTextarea"
                                        label="Detail"
                                        className="mb-3"
                                    >
                                        <Form.Control as="textarea" placeholder="Leave a comment here" onChange={(e) => setSellerDetails(e.target.value)} />
                                    </FloatingLabel>
                                    {err && err.details ? <div className="text-danger">{err.details}</div> : ''}
                                </Form>
                                <button className="form-submit-btn" onClick={Addsellercallback}>SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Manufactures;