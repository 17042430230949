import React, { useState } from 'react'


export const EdComp2 = () => {
    const [actBtn, setActBtn] = useState(0);
    const [currTab, setCurrtab] = useState("intro")

    const chageTab = (btnId, comp) => {
        setActBtn(btnId);
        setCurrtab(comp)
    }


    const isActive = (btnId) => {
        return actBtn === btnId ? 'active-btn' : '';
    };
    return (
        <>
            <div className="event-detail-comp2 my-lg-5 my-md-4 my-3 ">
                <div className="container">

                    <div className="overf-div">
                        <div className="tab-btns d-flex align-items-center gap-lg-3 gap-2 mb-lg-5 mb-md-4 mb-3">
                            <button className={`ps-0 ${isActive(0)}`} onClick={() => chageTab(0, "intro")}>Introduction</button>
                            <button className={`${isActive(1)} `} onClick={() => chageTab(1, "gallery")}>Gallery</button>
                            <button className={` ${isActive(2)}`} onClick={() => chageTab(2, "videos")}>Video's</button>
                            <button className={` ${isActive(3)}`} onClick={() => chageTab(3, "join")}>Join Now</button>
                        </div>
                    </div>


                    <div className="tab-content ">
                        {currTab === "intro" ?

                            //Introudction
                            <div className="intro ">
                                <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>

                                <div className="intro-cards d-flex justify-content-between align-items-center">
                                    <div className="location com-card d-flex gap-lg-3 gap-2">
                                        <img src="/assets/icons/location-white.png" alt="" />
                                        <div className="">

                                            <h5>Venue</h5>
                                            <p>Sector 14, Noida New DelhiIndia</p>
                                        </div>
                                    </div>

                                    <div className="location com-card d-flex gap-lg-3 gap-2">
                                        <img src="/assets/icons/calendar-white.png" alt="" />
                                        <div className="">

                                            <h5>Venue</h5>
                                            <p>Sector 14, Noida New DelhiIndia</p>
                                        </div>
                                    </div>

                                    <div className="location com-card d-flex gap-lg-3 gap-2">
                                        <img src="/assets/icons/attachment-white.png" alt="" />
                                        <div className="">

                                            <h5>Venue</h5>
                                            <p>Sector 14, Noida New DelhiIndia</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            :
                            currTab === "gallery" ?
                                //  Gallery
                                < div className="  gallery d-flex flex-wrap gap-3">
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-1.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-2.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-3.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-4.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-5.png" alt="" />

                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-6.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-4.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-5.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-6.png" alt="" />
                                    </div>
                                    <div className="img-card">
                                        <img src="/assets/images/gallery-4.png" alt="" />
                                    </div>



                                </div>

                                :
                                currTab === "videos" ?
                                    // Video
                                    <div className=" videos d-flex flex-wrap gap-3">
                                        <video src="/assets/videos/cartoon.mp4" controls />
                                        <video src="/assets/videos/cartoon.mp4" width="320" height="240" controls />
                                        <video src="/assets/videos/cartoon.mp4" width="320" height="240" controls />
                                        <video src="/assets/videos/cartoon.mp4" width="320" height="240" controls />
                                        <video src="/assets/videos/cartoon.mp4" width="320" height="240" controls />
                                        <video src="/assets/videos/cartoon.mp4" width="320" height="240" controls />
                                    </div>

                                    :
                                    currTab === "join" ?

                                        // Join Now 
                                        <div className="join-now">
                                            <h3>Apply & Reserve your Seat</h3>
                                            <p className='bold-para'>Lorem Ispum dolor sit amet</p>

                                            <form action="">
                                                <div className="mt-3 d-flex justify-content-between align-items-center ">
                                                    <div>
                                                        <input type="text" placeholder='Full Name' />
                                                        <p className='mb-0'>Full Name is Required*</p>
                                                    </div>
                                                    <div>
                                                        <input type="email" placeholder='Email Id' />
                                                        <p className='mb-0'>Email is Required*</p>
                                                    </div>

                                                </div>
                                                <div className=" my-3 d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <input type="number" placeholder='Mobile Number' />
                                                        <p className='mb-0'>Number is Required*</p>
                                                    </div>
                                                    <div>
                                                        <input type="text" placeholder='Full Address' />
                                                        <p className='mb-0'>Full Address Required*</p>
                                                    </div>
                                                </div>

                                                <div className="dd-input my-3 d-flex justify-content-between align-items-center">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <select class="form-select" aria-label="Default select example">
                                                                <option selected>Open this select menu</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                            <p className="mb-0">Lorem, ipsum.</p>
                                                        </div>

                                                        <div>
                                                            <select class="form-select" aria-label="Default select example">
                                                                <option selected>Open this select menu</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </select>
                                                            <p className='mb-0'>Lorem, ipsum.</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between align-items-center">

                                                        <div>
                                                            <input type="text" placeholder='City' />
                                                            <p className="mb-0">Lorem, ipsum.</p>
                                                        </div>
                                                        <div>
                                                            <input type="number" placeholder='Pin Code' />
                                                            <p className="mb-0">Lorem, ipsum.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='my-3 '>
                                                    <textarea name="" id="" cols="30" width="100%" rows="8" placeholder='Detail Here...'></textarea>
                                                    <p>Lorem, ipsum.</p>
                                                </div>

                                                <div className="my-4 pt-5">
                                                    <input type="submit" value="Submit" />
                                                </div>

                                            </form>
                                        </div>
                                        : null

                        }










                    </div>

                </div>

            </div >

        </>

    )
}
