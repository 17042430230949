import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const TruncHtml = require('trunc-html');


const Blogs = (props) => {
    const navigate = useNavigate();
    console.log(props, "blog data anas");
    return (
        <>
            <div className="container seller-blog-section" style={{ marginTop: "30px" }}>
                <div >
                    <h2>{props?.homepagewidget?.widget6}</h2>
                    <p>{props?.homepagewidget?.widget7}</p>
                    <div className="row">
                        {props?.blogs?.data?.map((item, index) => (
                            <div className="col-md-4 col-sm-6 col-xs-12 seller-product-section">
                                <div className="digital-product-section-border " onClick={() => props.handleBlogDetails(index)}>
                                    <img src={props?.blogs?.image_base_url + item.image} height={200} width="100%" />
                                    <div className="blog-info">
                                        {/* <h6>{item.title}</h6> */}
                                        <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: TruncHtml(item.content, 200).html }}></div>
                                        <button>Read more  <img src="/assets/images/arrow-grey.png" /></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Blogs;