import React from 'react'
import "../style/EventDetail.css"

import { Sdbanner } from '../components/seller-detail/Sdbanner';
import { EdComp2 } from '../components/event-detail/EdComp2';
import { EdComp3 } from '../components/event-detail/EdComp3';
import { SellertestimonialPost } from './SellertestimonialPost';
import { EdComp4 } from '../components/event-detail/EdComp4';


const EventDetail = () => {

  return (
    <>
    <div className="event-detail" style={{background:"#f5f5f5"}}>
        <section>
            <Sdbanner/>
        </section>

        <section>
            <EdComp2/>
        </section>

        <section>
            <EdComp3/>
        </section>

        <section>
            <EdComp4/>
        </section>
    </div>
    </>
  )
}
export default EventDetail;
