import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal, Table } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';


const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const ManageProfile = ({ props }) => {

  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem('avonToken');
  const [data, setData] = useState();
  console.log(data, "data")
  const [category, setCategory] = useState([]);
  const [businessName, setBusinessName] = useState();
  const [businessType, setBusinessType] = useState();
  const [businessNature, setBusinessNature] = useState();
  const [regNo, setRegNo] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [whatsappNo, setWhatsapp] = useState();
  const [address, setAddress] = useState();
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState();
  const [website, setWebsite] = useState();
  const [gstApp, setGstApp] = useState();
  const [field, setField] = useState();
  // console.log(gstApp)
  const [panNo, setPanNo] = useState();
  const [gstNo, setGstNo] = useState();
  const [panImg, setPanImg] = useState('');
  const [regcerImg, setRegCertImg] = useState('');
  const [gstImg, setGstImg] = useState('');
  const [metaTitle, setMetaTitle] = useState();
  const [metaKeyword, setMetaKeyword] = useState();
  const [metaDescription, setMetaDescription] = useState();
  const [aboutUs, setAboutUs] = useState();
  const [slug, setSlug] = useState();
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [youtube, setYoutube] = useState();
  const [linkedin, setLinkedin] = useState();
  const [instagram, setInstagram] = useState();
  const [pinterest, setpinInterest] = useState();
  const [blogUrl, setBlogUrl] = useState();
  const [cod, setCod] = useState();
  const [bankName, setBankName] = useState();
  const [accountName, setAccountName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [branch, setBranch] = useState();
  const [swift, setSwift] = useState();
  const [ifsc, setIfsc] = useState();
  const [error, setError] = useState();
  const [resError, setResError] = useState();
  const [heading, setHeading] = useState();
  const [sub_heading, setSub_Heading] = useState();
  const [image, setImage] = useState();
  const [modal, setModal] = useState(false);
  const [businessSlug, setBusinessSlug] = useState();
  const [sunday, setSunday] = useState();
  const [sundaystart, setSundayStart] = useState();
  const [monday, setMonday] = useState();
  const [mondaystart, setMondayStart] = useState();
  const [tuesday, setTuesday] = useState();
  const [tuesdaystart, setTuesdayStart] = useState();
  const [wednesday, setWednesday] = useState();
  const [wednesdaystart, setWednesdayStart] = useState();
  const [thursday, setThursday] = useState();
  const [thursdaystart, setThursdayStart] = useState();
  const [friday, setFriday] = useState();
  const [fridaystart, setFridayStart] = useState();
  const [saturday, setSaturday] = useState();
  const [saturdaystart, setSaturdayStart] = useState();
  const [sunOn, setSunOn] = useState();
  const [monOn, setMonOn] = useState();
  const [tuesOn, setTuesOn] = useState();
  const [wedOn, setWedOn] = useState();
  const [thursOn, setThursOn] = useState();
  const [friOn, setFriOn] = useState();
  const [satOn, setSatOn] = useState();
  const [sunend, setSunEnd] = useState();
  const [monend, setMonEnd] = useState();
  const [tuesend, setTuesEnd] = useState();
  const [wedend, setWedEnd] = useState();
  const [thursend, setThursEnd] = useState();
  const [friend, setFriEnd] = useState();
  const [satend, setSatEnd] = useState();
  const [body, setbody] = useState('');
  const [desc, setdesc] = useState('');
  const body1 = React.createRef();
  const [statusData, setStatusData] = useState();
  const [status, setStatus] = useState(false)

  console.log('statusData', statusData)

  useEffect(() => {

    FetchStatus();
  }, [])

  const FetchStatus = async () => {

    await axios.get(BASE_URL + '/get-seller-detail', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setStatusData(data)

      // if(data.data == null){
      //   navigate('/vendor-dashboard')
      // }
      // if(data.data.payment_status == 'Pending'){
      //   navigate('/manage-profile')
      //   }
      setStatus(statusData && statusData.data == null ? true : statusData && statusData.data.payment_status == 'Pending' ? true : statusData && statusData.data.payment_status == 'Active' ? false : true)
    })

  }

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell
  } = EditorTools;

  const handleSunday = (e) => {
    setField('gtc', e.target.checked)
    setSunday(!sunday)
    console.log("status", sunday)
    setSunOn(sunday == true ? "close" : "open")
  }

  console.log("statusValue", monOn)

  const handleMonday = (e) => {
    setField('gtc', e.target.checked)
    setMonday(!monday)
    setMonOn(monday == true ? "close" : "open")
  }

  const handleTuesday = (e) => {
    setField('gtc', e.target.checked)
    setTuesday(!tuesday)
    setTuesOn(tuesOn == true ? "close" : "open")
  }

  const handleWednesday = (e) => {
    setField('gtc', e.target.checked)
    setWednesday(!wednesday)
    setWedOn(wedOn == true ? "close" : "open")
  }

  const handleThursday = (e) => {
    setField('gtc', e.target.checked)
    setThursday(!thursday)
    setThursOn(thursday == true ? "close" : "open")
  }

  const handleFriday = (e) => {
    setField('gtc', e.target.checked)
    setFriday(!friday)
    setFriOn(friday == true ? "close" : "open")
  }

  const handleSaturday = (e) => {
    setField('gtc', e.target.checked)
    setSaturday(!saturday)
    setSatOn(satOn == true ? "close" : "open")
  }

  const handleChange = (e) => {
    // setField('gtc', e.target.checked)
    setGstApp(!gstApp)
  }

  const handleGST = e => {
    setGstImg(e.target.files[0]);
  }
  const handlePan = e => {
    setPanImg(e.target.files[0]);
  }
  const handleReg = e => {
    setRegCertImg(e.target.files[0]);
  }

  const [keyword, setKeyword] = useState([]);
  const [store, setStore] = useState();
  const [seller, setSeller] = useState();


  useEffect(() => {
    FetchProfileData();
    getCountry();
    FetchBusinessCategory();
    FetchKeywords();
    FetchSlider();
  }, [])

  const FetchKeywords = async () => {
    await axios.get(BASE_URL + '/getmetakeyword').then(({ data }) => {
      setKeyword(data.data)
    })
  }

  const KeyData = keyword.map((item) => (
    item.meta_keyword
  ))

  const [img_Url, setImg_Url] = useState('');

  const FetchProfileData = async () => {
    await axios.get(BASE_URL + '/getbusinessprofile', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      var datasl = [];
      if(data.data.business_mobilenumber.length > 0){
       
        data.data.business_mobilenumber.map((item, i) => {
          datasl.push({ mobile: item })
        })
      }
      var datas2 = [];
      if(data.data.business_email.length > 0){
       
        data.data.business_email.map((items, i) => {
          datas2.push({ email: items })
        });
      }
      var datas3 = [];
if(data.pickupaddress.length >0){
  data.pickupaddress.map((items, i) => {
    getState(items.country.id);
    getCity(items.state.id)
    setCod(items.cod)
    datas3.push({ pickAddress: items.address, pickCountry: items.country.id, pickstate: items.state.id, pickCity: items.city.id, pickPincode: items.pincode, pickLandmark: items.landmark })
  })
  setPickupList(datas3);
}else{
  setPickupList([...pickupList, { pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
}
        
       
    
      setaddEmail(datas2)
      setaddMobile(datasl)
      setData(data.data)
      setImg_Url(data.image_url)
      setStore(data.store)
      setSeller(data.seller)
      setPickAdd(data.pickupaddress)
      // console.log("profile", data.data)

    })
  }

  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + '/getbusinesscategory', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setCategory(data.data)
      // console.log(data.data)
    })
  }

  const [sliderData, setSliderData] = useState([]);
  const [baseUrl, setBaseUrl] = useState();

  const FetchSlider = async () => {
    await axios.get(BASE_URL + '/getslider', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setSliderData(data.data)
      setBaseUrl(data.image_base_url)
    })
  }

  const [selected, setSelected] = useState([]);
  const [addMobile, setaddMobile] = useState([{ mobile: "" }]);
  // console.log(selected)

  // handle input change
  const handleInputMobileChange = (e, index) => {
    const { mobile, value } = e.target;
    const list = [...addMobile];
    list[index]['mobile'] = value;
    setaddMobile(list);
  };

  // handle click event of the Remove button
  const handleRemoveMobileClick = index => {
    const list = [...addMobile];
    list.splice(index, 1);
    setaddMobile(list);
  };

  // handle click event of the Add button
  const handleAddMobileClick = () => {
    setaddMobile([...addMobile, { mobile: "" }]);
  };

  const [addEmail, setaddEmail] = useState([{ email: "" }]);
  // console.log(addEmail)

  // handle input change
  const handleInputEmailChange = (e, index) => {
    const { email, value } = e.target;
    const list = [...addEmail];
    list[index]['email'] = value;
    setaddEmail(list);
    // console.log(list)
  };

  // handle click event of the Remove button
  const handleRemoveEmailClick = index => {
    const list = [...addEmail];
    list.splice(index, 1);
    setaddEmail(list);
  };

  // handle click event of the Add button
  const handleAddEmailClick = () => {
    setaddEmail([...addEmail, { email: "" }]);
  };

  const getCountry = () => {

    axios.get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const getCitys = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        return res.data.data;
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const [pickupList, setPickupList] = useState([{ pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  //  console.log([pickupList[0].pickAddress])
  // handle input change
  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...pickupList];
    // const li = [...pickupList.pickAddress]
    // console.log(list[0].pickAddress)
    list[index][name] = value;
    setPickupList(list);
    console.log(e)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...pickupList];
    list.splice(index, 1);
    setPickupList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setPickupList([...pickupList, { pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  };

  // console.log(pickupList[0].pickAddress)

  const [countryName, setCountryName] = useState()
  const handleCountyName = (e) => {
    const DataIdWithName = e;
    const countryData = DataIdWithName.split("_");
    setCountryName(countryData[0])
  }

  console.log('Days', [sundaystart, mondaystart, tuesdaystart])

  const UpdateProfile = async () => {
    const formData = new FormData();
    formData.append('business_category', selected ? selected : data.business_category ? data.business_category : '')
    formData.append('business_nature', businessNature ? businessNature : data.business_nature ? data.business_nature && data.business_nature : '');
    formData.append('website_url', website ? website : data.website_url && data.website_url ? data.website_url && data.website_url : '');
    formData.append('gst_number', gstNo ? gstNo : data.gst_number ? data.gst_number && data.gst_number : '');
    formData.append('reg_certificate', regcerImg);
    formData.append('pannumber', panNo ? panNo : data.pannumber ? data.pannumber && data.pannumber : '');
    formData.append('gst_certificate', gstImg);
    formData.append('business_name', businessName ? businessName : data.business_name ? data.business_name && data.business_name : '');
    formData.append('country', countryName ? countryName : data.country.name ? data.country.name && data.country.name : '');
    formData.append('state', state ? state : data.state.name ? data.state.name && data.state.name : '');
    formData.append('city', city ? city : data.city.name ? data.city.name && data.city.name : '');
    formData.append('pincode', pincode ? pincode : data.pincode ? data.pincode && data.pincode : '');
    formData.append('business_type', businessType ? businessType : data.business_type ? data.business_type && data.business_type : '');
    formData.append('reg_no', regNo ? regNo : data.reg_no ? data.reg_no && data.reg_no : '');
    formData.append('full_address', address ? address : data.full_address ? data.full_address && data.full_address : '');
    formData.append('gst_status', 'yes');
    formData.append('business_mobilenumber', addMobile ? addMobile : data.business_mobilenumber ? data.business_mobilenumber && data.business_mobilenumber : '');
    formData.append('business_email', addEmail ? addEmail : data.business_email ? data.business_email && data.business_email : '');
    formData.append('pancard', panImg);
    formData.append('whatsapp_number', whatsappNo ? whatsappNo : data.whatsapp_number ? data.whatsapp_number && data.whatsapp_number : '');
    formData.append('day', ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']);
    formData.append('in_time', [sundaystart, mondaystart, tuesdaystart, wednesdaystart, thursdaystart, fridaystart, saturdaystart]);
    formData.append('out_time', [sunend, monend, tuesend, wedend, thursend, friend, satend]);
    formData.append('status', [sunOn, monOn, tuesOn, wedOn, thursOn, friOn, satOn]);

    await axios.post(BASE_URL + '/updatebusinessprofile', formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProfileData();
      // console.log(data)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    })
      .catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setResError(response.data.message)
          if (response.data.message.business_category) {
            toast.error(response.data.message.business_category[0])
          }
          if (response.data.message.business_category) {
            toast.error(response.data.message.country[0])
          }
        }
      })
  }
  const Handlestore = async () => {
    var address = [];
    var country = [];
    var state = [];
    var city = [];
    var pincode = [];
    var landmark = [];
    pickupList.map((item) => {
      address.push(item.pickAddress)
      country.push(item.pickCountry)
      state.push(item.pickstate)
      city.push(item.pickCity)
      pincode.push(item.pickPincode)
      landmark.push(item.pickLandmark)

    })

    let datas = {
      "cod": cod,
      "address": address,
      "landmark": landmark,
      "country": country,
      "state": state,
      "city": city,
      "pincode": pincode
    }
    await axios.post(BASE_URL + '/addpickupaddress', datas, {
      headers: {
        // 'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProfileData();
      // console.log(data)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      console.log(response.data)
      if (response.data.status_code == 422) {
        setError(response.data.message)

        if (response.data.message.aboutus) {
          toast.error(response.data.message.aboutus[0])
        }
        if (response.data.message.bank_acc_name) {
          toast.error(response.data.message.bank_acc_name[0])
        }
        if (response.data.message.address) {
          toast.error(response.data.message.address[0])
        }
      }
    })
  }

  const [chip, setChip] = useState();

  const handlechangeChip = (e) => {
    setChip(e)
  }

  const handleImage = e => {
    setImage(e.target.files[0]);
  }

  const handleNewImage = e => {
    setNewImage(e.target.files[0]);
  }

  const [edit, setEdit] = useState(false);
  const [errorImg, setErrorImg] = useState();
  const [pickAd, setPickAdd] = useState([]);


  const AddSlider = async () => {

    const formData = new FormData();
    formData.append('image', image)
    formData.append('heading', heading);
    formData.append('subheading', sub_heading);
    await axios.post(BASE_URL + '/addslider', formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        if (data.message == 'Add Succesfully') {
          toast.success(data.message)
          setModal(false)
          setImage("");
          setHeading("");
          setSub_Heading("");
          FetchSlider();
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setErrorImg(response.data.message.image[0])
          //  alert(response.data.message.image[0])
        }
      })
  }

  const handleDelete = async (id) => {
    await axios.get(BASE_URL + '/deleteslider/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      toast.success(data.message)
      FetchSlider();
    })
  }

  const [editDetail, setEditDetail] = useState();
  const [editModal, setEditModal] = useState();
  const [newHeading, setNewHeading] = useState();
  const [newSubHeading, setNewSubHeading] = useState();
  const [newImage, setNewImage] = useState();

  const EditSlider = async (id) => {

    await axios.get(BASE_URL + '/editslider/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setEditModal(true)
      setEditDetail(data.data)
    })
  }

  const UpdateSlider = async (id) => {
    const formData = new FormData();
    formData.append('image', newImage == null ? editDetail.image : newImage)
    formData.append('heading', newHeading == null ? editDetail.heading : newHeading);
    formData.append('subheading', newSubHeading);
    await axios.post(BASE_URL + '/updateslider/' + id, formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        if (data.message == 'Update Succesfully') {
          toast.success(data.message)
          setEditModal(false);
          setImage("")
          setNewHeading("")
          setNewSubHeading("")
          FetchSlider();
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setErrorImg(response.data.message.image[0])
          //  alert(response.data.message.image[0])
        }
      })
  }


  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Pickup Address</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>

            </ul>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu PurchaseHis={status} />
              <div className="col-md-9">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>
                    Pickup Address <Button onClick={() => { setEdit(true) }}> <FontAwesomeIcon icon={faEdit} /></Button>
                  </h4>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk mb-3">
                    <h5>Pickup Address</h5>
                  </div>
                  <div className="card-body-blk">

                    {data ?
                      <div className="purchase-table">
                        <div className="purchase-list-table">
                          <Table responsive="md">
                            <thead className='purchase-heading'>
                              <tr>

                                <th>COD</th>
                                <th>Pickup Address</th>
                                <th>Pickup Landmark</th>
                                <th>Pickup Country</th>
                                <th>Pickup State</th>
                                <th>Pickup City</th>
                                <th>Pincode</th>
                              </tr>
                            </thead>
                            <tbody>
                              {pickupList.map((x, i) => (
                                <tr>
                                  <td>{cod}</td>
                                  <td>{x.pickAddress}</td>
                                  <td>{x.pickLandmark}</td>
                                  <td>{x.pickCountry}</td>
                                  <td>{x.pickstate}</td>
                                  <td>{x.pickCity}</td>
                                  <td>{x.pickPincode}</td>
                                </tr>
                              ))}




                            </tbody>
                          </Table>
                        </div>
                      </div>
                      :
                      edit && 
                      <div className="row">
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>COD</Form.Label>
                          <Form.Select
                          value={cod}
                            onChange={(e) => setCod(e.target.value)}
                            disabled={data && !edit ? true : false}
                          >
                            <option value="">Select Option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </Form.Select>
                        </div>
                        {pickupList.map((x, i) => {
                          // console.log(x)
                          return (
                            <>
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Pickup Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="pickAddress"
                                  placeholder="Enter Address "
                                  value={x.pickAddress}
                                  onChange={e => handleInputChange(e, i)}
                                  defaultValue={pickAd.map((item) => item.address)}
                                  disabled={data && !edit ? true : false}
                                />
                                {/* {error && error.address[0].map((item) => (
                                 <div className="text-danger">bndj</div>
                              ))} */}
                                {/* {error.message.address ?
                        <div className="text-danger">{error.message.address[0]}</div> : '' } */}
                              </div>
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Pickup LandMark</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="pickLandmark"
                                  placeholder="Enter Address "

                                  value={x.pickLandmark}
                                  onChange={e => handleInputChange(e, i)}
                                  // defaultValue={pickAd.map((item) => item.landmark)}
                                  disabled={data && !edit ? true : false}

                                />
                              </div>
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Pickup Country</Form.Label>
                                <Form.Select
                                  name="pickCountry"
                                  disabled={data && !edit ? true : false}

                                  style={{ borderRadius: '0%' }} value={x.pickCountry} onChange={(e) => { handleInputChange(e, i); getState(e.target.value) }}>
                                  {/* <option value={pickAd && pickAd.map((item) => item.country) == null ? '' : pickAd && pickAd.map((item) => item.country)}>{pickAd && pickAd.map((item) => item.address)}</option> */}
                                  {fetchCountry && fetchCountry.map((data) => (
                                    <option value={data.id}>{data.name}</option>
                                  ))}
                                </Form.Select>
                              </div>
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Pickup State</Form.Label>
                                <Form.Select name="pickstate"
                                  disabled={data && !edit ? true : false}


                                  style={{ borderRadius: '0%' }} value={x.pickstate} onChange={(e) => { handleInputChange(e, i); getCity(e.target.value) }}>
                                  {/* <option>{data.state.name ? data.state.name : ''}</option> */}
                                  {/* <option value={data.country && data.country.name == null ? '' : data && data.country.name}>{data && data.country.name == null ? 'Select Country' : data && data.country.name}</option> */}
                                  {fetchState && fetchState.map((data) => (
                                    <option value={data.id}>{data.name}</option>
                                  ))}
                                </Form.Select>

                              </div>
                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Pickup City</Form.Label>
                                <Form.Select name="pickCity"
                                  disabled={data && !edit ? true : false}
                                  style={{ borderRadius: '0%' }} value={x.pickCity}
                                  onChange={e => handleInputChange(e, i)}>
                                  {/* <option value={data.country && data.country.name == null ? '' : data && data.country.name}>{data && data.country.name == null ? 'Select Country' : data && data.country.name}</option> */}
                                  {fetchCity && fetchCity.map((data) => (
                                    <option value={data.id}>{data.name}</option>
                                  ))}
                                </Form.Select>
                              </div>

                              <div className="col-md-4 mb-2 mt-2">
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="pickPincode"
                                  disabled={data && !edit ? true : false}
                                  placeholder="Enter Pincode"
                                  value={x.pickPincode}
                                  onChange={e => handleInputChange(e, i)}
                                />
                              </div>

                              <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                                {pickupList.length !== 1 && <div
                                  className="mr10 pointer"
                                  style={{ color: 'red' }}
                                  onClick={() => handleRemoveClick(i)}>Remove</div>}
                                {pickupList.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddClick}> + Add</div>}
                              </div>

                            </>

                          );
                        })}

                        <div className="col-md-12">
                          <Button
                            style={{ float: "right", marginTop: "8px", color: '#fff' }}
                            variant="warning"
                            onClick={Handlestore}
                          >
                            Add Address
                          </Button>
                        </div>
                        <ToastContainer />
                      </div>
                     }
                     {edit &&
                     <div className="row">
                     <div className="col-md-4 mb-2 mt-2">
                       <Form.Label>COD</Form.Label>
                       <Form.Select
                        value={cod}
                         onChange={(e) => setCod(e.target.value)}
                         disabled={data && !edit ? true : false}
                       >
                         <option value="">Select Option</option>
                         <option value="yes">Yes</option>
                         <option value="no">No</option>
                       </Form.Select>
                     </div>
                     {pickupList.map((x, i) => {
                       // console.log(x)
                       return (
                         <>
                           <div className="col-md-4 mb-2 mt-2">
                             <Form.Label>Pickup Address</Form.Label>
                             <Form.Control
                               type="text"
                               name="pickAddress"
                               placeholder="Enter Address "
                               value={x.pickAddress}
                               onChange={e => handleInputChange(e, i)}
                               defaultValue={pickAd.map((item) => item.address)}
                               disabled={data && !edit ? true : false}
                             />
                             {/* {error && error.address[0].map((item) => (
                              <div className="text-danger">bndj</div>
                           ))} */}
                             {/* {error.message.address ?
                     <div className="text-danger">{error.message.address[0]}</div> : '' } */}
                           </div>
                           <div className="col-md-4 mb-2 mt-2">
                             <Form.Label>Pickup LandMark</Form.Label>
                             <Form.Control
                               type="text"
                               name="pickLandmark"
                               placeholder="Enter Address "

                               value={x.pickLandmark}
                               onChange={e => handleInputChange(e, i)}
                               // defaultValue={pickAd.map((item) => item.landmark)}
                               disabled={data && !edit ? true : false}

                             />
                           </div>
                           <div className="col-md-4 mb-2 mt-2">
                             <Form.Label>Pickup Country</Form.Label>
                             <Form.Select
                               name="pickCountry"
                               disabled={data && !edit ? true : false}

                               style={{ borderRadius: '0%' }} value={x.pickCountry} onChange={(e) => { handleInputChange(e, i); getState(e.target.value) }}>
                               {/* <option value={pickAd && pickAd.map((item) => item.country) == null ? '' : pickAd && pickAd.map((item) => item.country)}>{pickAd && pickAd.map((item) => item.address)}</option> */}
                               {fetchCountry && fetchCountry.map((data) => (
                                 <option value={data.id}>{data.name}</option>
                               ))}
                             </Form.Select>
                           </div>
                           <div className="col-md-4 mb-2 mt-2">
                             <Form.Label>Pickup State</Form.Label>
                             <Form.Select name="pickstate"
                               disabled={data && !edit ? true : false}


                               style={{ borderRadius: '0%' }} value={x.pickstate} onChange={(e) => { handleInputChange(e, i); getCity(e.target.value) }}>
                               {/* <option>{data.state.name ? data.state.name : ''}</option> */}
                               {/* <option value={data.country && data.country.name == null ? '' : data && data.country.name}>{data && data.country.name == null ? 'Select Country' : data && data.country.name}</option> */}
                               {fetchState && fetchState.map((data) => (
                                 <option value={data.id}>{data.name}</option>
                               ))}
                             </Form.Select>

                           </div>
                           <div className="col-md-4 mb-2 mt-2">
                             <Form.Label>Pickup City</Form.Label>
                             <Form.Select name="pickCity"
                               disabled={data && !edit ? true : false}
                               style={{ borderRadius: '0%' }} value={x.pickCity}
                               onChange={e => handleInputChange(e, i)}>
                               {/* <option value={data.country && data.country.name == null ? '' : data && data.country.name}>{data && data.country.name == null ? 'Select Country' : data && data.country.name}</option> */}
                               {fetchCity && fetchCity.map((data) => (
                                 <option value={data.id}>{data.name}</option>
                               ))}
                             </Form.Select>
                           </div>

                           <div className="col-md-4 mb-2 mt-2">
                             <Form.Label>Pincode</Form.Label>
                             <Form.Control
                               type="text"
                               name="pickPincode"
                               disabled={data && !edit ? true : false}
                               placeholder="Enter Address "
                               value={x.pickPincode}
                               onChange={e => handleInputChange(e, i)}
                             />
                           </div>

                           <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                             {pickupList.length !== 1 && <div
                               className="mr10 pointer"
                               style={{ color: 'red' }}
                               onClick={() => handleRemoveClick(i)}>Remove</div>}
                             {pickupList.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddClick}> + Add</div>}
                           </div>

                         </>

                       );
                     })}

                     <div className="col-md-12">
                       <Button
                         style={{ float: "right", marginTop: "8px", color: '#fff' }}
                         variant="warning"
                         onClick={Handlestore}
                       >
                         Update
                       </Button>
                     </div>
                     <ToastContainer />
                   </div>
}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
              <h5> Add Slider & Banner </h5></Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setHeading(e.target.value)}
                  placeholder="Enter Heading"
                />
                {/* {error && error.heading ? <div>bdhb</div> : ''} */}
              </div>
              <div className="mt-4">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setSub_Heading(e.target.value)}
                  placeholder="Enter Sub Heading"
                />
              </div>
              <div className="mt-4 mb-4">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleImage}
                  placeholder="Image "
                />
                {errorImg && errorImg ? <div className="text-danger">{errorImg}</div> : ''}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={AddSlider}>Add Slider</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={editModal} onHide={() => setEditModal(false)}>
            <Modal.Header closeButton>
              <h5> Edit Slider & Banner </h5></Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewHeading(e.target.value)}
                  placeholder="Enter Heading"
                  defaultValue={editDetail && editDetail.heading ? editDetail && editDetail.heading : ''}
                />
                {/* {error && error.heading ? <div>bdhb</div> : ''} */}
              </div>
              <div className="mt-4">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewSubHeading(e.target.value)}
                  defaultValue={editDetail && editDetail.subheading ? editDetail && editDetail.subheading : ''}
                  placeholder="Enter Sub Heading"
                />
              </div>
              <div className="mt-4 mb-4">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleNewImage}
                  placeholder="Image "
                />
                {errorImg && errorImg ? <div className="text-danger">{errorImg}</div> : ''}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => UpdateSlider(editDetail.id)}>Add Slider</Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default ManageProfile;
