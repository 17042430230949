import React,{useState,useEffect} from 'react'
import "../style/SellerDetails.css"
import "../style/StartupDetail.css"
import { Sdbanner } from '../components/startup-details/Sdbanner'
import { SdComp2 } from '../components/startup-details/SdComp2'
import { StartupdComp3 } from '../components/startup-detail/StartupdComp3'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../config'
import Loader from '../components/loader/Loader.component'
export const StartupDetail = () => {
  const {slug} = useParams();
  const [data,setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(()=>{
    fetchdata();
  },[slug])
  const fetchdata = async () => {
    await axios.get(BASE_URL + '/startupdetail/'+slug).then(({ data }) => {
      setdata(data.data)
      setIsLoading(false)
    }).catch(({ response }) => {
      setIsLoading(false)
    })
}
if (isLoading) {
  return <Loader />;
}
  return (
    <>
    <section>
        <Sdbanner data={data}/>
    </section>

    <section>
        <SdComp2 data={data} />
    </section>

    <section>
        <StartupdComp3 data={data}/>
    </section>


    
    </>
    
    
  )
}
