import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { BASE_URL, DOMAIN_NAME } from "../../config";
import axios from "axios";
import { Link } from "react-router-dom";

export default function BlogComponent({ data }) {

    const [blog, setBlog] = useState([]);
    const [blogImage, setBlogImage] = useState();
    const token = localStorage.getItem('avonToken');

    useEffect(() => {
        FetchBlog();
    }, [])

    const FetchBlog = async () => {

        await axios.get(BASE_URL + '/seller/blogs', {
            headers: {
                'Accept': 'application/json',
                'domainname':DOMAIN_NAME
            }
        }).then(({ data }) => {
            setBlog(data.data)
            setBlogImage(data.image_base_url)
        })

    }

    return (
        <div className='row' style={{ marginLeft: 0, marginRight: 0 }}>
            {blog && blog.map((i) => (
                <div className='col-md-3 center-align mt-4 mb-4'>
                    <div className='card seller-product-width'>
                        <img src={blogImage+i.image} />
                        <div style={{ padding: 10, paddingLeft: 15, paddingRight: 15 }}>
                            <div className="blog-heading">
                               {i.title.length > 20 ? i.title.substring(0,20)+'...' : i.title}
                            </div>
                            <div className="blog-text mt-2">
                            {i.content.length > 55 ? i.content.substring(0,55)+'...' : i.content}
                            </div>
                            <div className="blog-read-more">
                                <span style={{ fontSize: 16 }}>Read More</span> <Link to={`/seller-blog/${i.slug}`}> <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 18, paddingTop: 10 }} /> </Link>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}