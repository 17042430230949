import React, { useState } from 'react'
import { Link } from 'react-router-dom'


export const Header = () => {
    const[showNotif, setShowNotif] = useState("-100%")

    
    return (
        <div className='seller-dashb-header posittion-relative'>

            <nav className="navbar d-flex justify-content-between align-items-center navbar-expand-lg navbar-light bg-light px-5">

                <div className="d-flex align-items-center gap-5">
                    <Link className="navbar-brand" to="#">
                        <img src="/assets/Icons/apna-logo.svg" alt="" />
                    </Link>
                    {/* <img src="/assets/Icons/explore-bar.svg" alt="" width={"20px"} /> */}
                </div>
                {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button> */}

                <div className="" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto d-flex flex-row align-items-center">
                        <li className="nav-item active seller-d-link">
                            <Link className="nav-link" to="#">Seller Dashboard<span><i class="fa fa-arrow-up-right-from-square"></i></span></Link>
                        </li>
                       
                        <li className="nav-item more-icons">
                            <Link className="nav-link py-0" to="#"><div class="dropdown">
                                <button class="btn border-0 py-0 dropdown-toggle bb-transparent text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="/assets/icons/more-icons.png" alt="" className='rounded-circle' width={"24px"} height={"24px"}/>
                                </button>
                                <ul class="dropdown-menu micon-parent pt-0 pb-0">
                                    <li className='d-flex micon'>
                                        <Link class="dropdown-item text-center" to="#">
                                            <span><img src="/assets/icons/enquiry.png" alt="" width={"24px"}/></span>
                                                <p>Inquiries</p>
                                        </Link>
                                        <Link class="dropdown-item text-center" to="#">
                                            <span><img src="/assets/icons/catalogue.png" alt="" width={"24px"}/></span>
                                                <p>Catalogue</p>
                                        </Link>
                                    </li>
                                    <li className='d-flex micon'>
                                        <Link class="dropdown-item text-center" to="#">
                                            <span><img src="/assets/icons/carbon_review.png" alt="" width={"24px"}/></span>
                                                <p>Reviews & Rating</p>
                                        </Link>
                                        <Link class="dropdown-item text-center" to="#">
                                            <span><img src="/assets/icons/wallet.png" alt="" width={"24px"} /></span>
                                                <p>Wallet</p>
                                        </Link>
                                    </li>
                                    <li className='d-flex micon'>
                                        <Link class="dropdown-item text-center" to="#">
                                            <span><img src="/assets/icons/subscription.png" alt="" width={"24px"} /></span>
                                                <p>Subscription</p>
                                        </Link>
                                        <Link class="dropdown-item text-center" to="#">
                                            <span><img src="/assets/icons/help-support.png" alt="" width={"24px"} /></span>
                                                <p>Help & Support</p>
                                        </Link>
                                    </li>
                                  
                                </ul>
                            </div></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link py-0" to="#">
                                <button class="btn border-0 py-0  text-dark"  onClick={() => setShowNotif("0%")}>
                                <i class="fa fa-bell"></i>
                                </button>
                               
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link className="nav-link py-0" to="#"><div class="dropdown">
                                <button class="btn rounded-circle border-0  py-0 dropdown-toggle text-dark" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt=""  width = "45px"/>
                                </button>
                                <ul class="dropdown-menu">
                                    <li><Link class="dropdown-item" to="#">Action</Link></li>
                                    <li><Link class="dropdown-item" to="#">Another action</Link></li>
                                    <li><Link class="dropdown-item" to="#">Something  here</Link></li>
                                </ul>
                            </div></Link>
                        </li>


                    </ul>

                </div>


            </nav>

            {/* Notification  */}

            <div className="notification" style={{maxWidth:"450px" , right:showNotif }}>
                <div className="notif-content px-3 py-3">
                    <div className="top-cont d-flex justify-content-between"> 
                    <h6>Notification</h6>
                    <button className='close-notif' onClick={() => setShowNotif("-100%")}>☓</button>
                    </div> 

                    <div className="notif-body">
                        <Link to = "" className="notif1 py-3 d-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3 align-items-center'>
                                <img src="/assets/images/avatar.png" alt="" width={"40px"} height={"40px"} className='rounded-cricle'/>
                                <div>
                                    <h5 className='mb-0'>lorem ipsum</h5>
                                    <p className='mb-0'>High fived your workout</p>
                                    <p className="time-ago mb-0">0 min</p>
                                </div>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <span className='notif-dot'></span>
                                    <span><i className="fa fa-chevron-right"></i></span>
                                </div>
                        </Link>
                        <Link to = "" className="notif1 py-3 d-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3 align-items-center'>
                                <img src="/assets/images/avatar.png" alt="" width={"40px"} height={"40px"} className='rounded-cricle'/>
                                <div>
                                    <h5 className='mb-0'>lorem ipsum</h5>
                                    <p className='mb-0'>High fived your workout</p>
                                    <p className="time-ago mb-0">0 min</p>
                                </div>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <span className='notif-dot'></span>
                                    <span><i className="fa fa-chevron-right"></i></span>
                                </div>
                        </Link>
                        <Link to = "" className="notif1 py-3 d-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3 align-items-center'>
                                <img src="/assets/images/avatar.png" alt="" width={"40px"} height={"40px"} className='rounded-cricle'/>
                                <div>
                                    <h5 className='mb-0'>lorem ipsum</h5>
                                    <p className='mb-0'>High fived your workout</p>
                                    <p className="time-ago mb-0">0 min</p>
                                </div>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <span className='notif-dot'></span>
                                    <span><i className="fa fa-chevron-right"></i></span>
                                </div>
                        </Link>
                        <Link to = "" className="notif1 py-3 d-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3 align-items-center'>
                                <img src="/assets/images/avatar.png" alt="" width={"40px"} height={"40px"} className='rounded-cricle'/>
                                <div>
                                    <h5 className='mb-0'>lorem ipsum</h5>
                                    <p className='mb-0'>High fived your workout</p>
                                    <p className="time-ago mb-0">0 min</p>
                                </div>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <span className='notif-dot'></span>
                                    <span><i className="fa fa-chevron-right"></i></span>
                                </div>
                        </Link>
                        <Link to = "" className="notif1 py-3 d-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3 align-items-center'>
                                <img src="/assets/images/avatar.png" alt="" width={"40px"} height={"40px"} className='rounded-cricle'/>
                                <div>
                                    <h5 className='mb-0'>lorem ipsum</h5>
                                    <p className='mb-0'>High fived your workout</p>
                                    <p className="time-ago mb-0">0 min</p>
                                </div>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <span className='notif-dot'></span>
                                    <span><i className="fa fa-chevron-right"></i></span>
                                </div>
                        </Link>
                        <Link to = "" className="notif1 py-3 d-flex justify-content-between align-items-center">
                                <div className='d-flex gap-3 align-items-center'>
                                <img src="/assets/images/avatar.png" alt="" width={"40px"} height={"40px"} className='rounded-cricle'/>
                                <div>
                                    <h5 className='mb-0'>lorem ipsum</h5>
                                    <p className='mb-0'>High fived your workout</p>
                                    <p className="time-ago mb-0">0 min</p>
                                </div>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <span className='notif-dot'></span>
                                    <span><i className="fa fa-chevron-right"></i></span>
                                </div>
                        </Link>
                    </div>
                </div>
            </div>

        </div>
    )
}
