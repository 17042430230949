import React,{useState,useEffect} from "react";
import Header from "./SellerHeader";
import Footer from './SellerFooter';
import SellerSlider from "./SellerHompageSlider";
import SellerProductServices from "./SellerProductServices";
import SellerFeatures from "./SellerFeatures";
import SellerBlogComponent from './SellerBlogComponent';
import SellerFAQ from "./SellerFAQs";
import SellerProductList from './SellerProductList';
import SellerBrand from './SellerBrand';
import { DOMAIN_NAME, BASE_URL, IMAGE_BASE_URL } from "../../config";
import { NavLink } from "react-router-dom";
import axios from "axios";


const datas = [
    {id:1},{id:2},{id:3}
]

// const faqData = [
//     { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }
// ]


export default function Home() {
    const [data, setData] = useState();
  const [baseImage, setBaseImage] = useState();
  const [faqData, setfaqData] = useState([]);
  const [homepagewidget, sethomepagewidget] = useState("");
  const [compnayIntro, setCompanyIntro] = useState({})
  const [products, setProducts] = useState([]);
  useEffect(() => {
    FetchAboutUs();
  }, [])

  const FetchAboutUs = async () => {
    await axios.get(BASE_URL + '/seller/about-us', {
      headers: {
        'Accept': 'application/json',
        'domainname': DOMAIN_NAME
      }
    }).then(({ data }) => {
      setBaseImage(data.image_base_url)
      setData(data.data)
    //   console.log(data.data.image)
    })
    await axios.get(BASE_URL + '/seller/faqs', {
        headers: {
          'Accept': 'application/json',
          'domainname': DOMAIN_NAME
        }
      }).then(({ data }) => {
        setfaqData(data.data)
      //   console.log(data.data.image)
      })
      await axios.get(BASE_URL + '/seller/homepagewidget', {
        headers: {
            'Accept': 'application/json',
            'domainname': DOMAIN_NAME
        }
    }).then(({ data }) => {
        sethomepagewidget(data?.data)
    }).catch(({ response }) => {

    })
    await axios.get(BASE_URL + '/seller/companyintro', {
        headers: {
            'Accept': 'application/json',
            'domainname': DOMAIN_NAME
        }
    }).then(({ data }) => {
        setCompanyIntro(data?.data[0])
    }).catch(({ response }) => {

    })
    await axios.get(BASE_URL + '/seller/product', {
        headers: {
            'Accept': 'application/json',
            'domainname': DOMAIN_NAME
        }
    }).then(({ data }) => {
        setProducts(data.data)
    }).catch(({ response }) => {

    })
  }

  const Base = baseImage && baseImage;
  const image = data && data.image;

    return (
        <>
            <Header />
            <SellerSlider />
            {/*  */}
            <div className="mt-8 mb-4">
                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div className="col-md-6 center">
                        <img src="assets/images/seller-frame.png" />
                    </div>
                    <div className="col-md-6">
                        <div className="seller-banner-heading">
                        {homepagewidget?.widget1}
                        </div>
                        <img src={IMAGE_BASE_URL+compnayIntro?.comp_intr_image} />
                        <div className="seller-banner-content mt-4" dangerouslySetInnerHTML={{ __html: compnayIntro?.comp_intr }} >
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className=' mt-8 mb-8' style={{ marginLeft: 0, marginRight: 0 }}>
            <div className='seller-product-heading'>{homepagewidget?.widget2}</div>
            <SellerProductServices data={products} />
            </div>
            <SellerFeatures homepagewidget={homepagewidget} />
            <div className="mt-8 mb-4">
                <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
                    <div className="col-md-6 mt-4">
                        <div className="seller-banner-heading seller-home-about">
                        {homepagewidget?.widget5}
                        </div>
                        <div className="seller-about-content mt-4 seller-home-about" dangerouslySetInnerHTML={{ __html: data && data.content }}>
                        </div>
                        {/* <div className="seller-about-content mt-4 seller-home-about">
                        Lorem ipsum dolor sit amQuisque volutpat  mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra.Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat  mattis eros. Nullam malesuada erat ut turpis. Suspendisse urna nibh, viverra.
                        </div> */}
                    </div>
                    <div className="col-md-6 center">
                        {/* <img src="assets/images/seller-frame.png" /> */}
        <img src={Base + image} />
                    </div>
                </div>
            </div>           
            <div className="col-md-12 mt-8 mb-8 seller-blog-container">
                <div className="seller-product-heading">{homepagewidget?.widget6}</div>
                <div className="blog-sub-heading mt-4">{homepagewidget?.widget7}</div>
                <SellerBlogComponent data={datas} />
            </div>
            <SellerFAQ data={faqData} homepagewidget={homepagewidget} />
            <SellerBrand homepagewidget={homepagewidget} />
            <Footer />
        </>
    )
}