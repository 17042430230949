import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';


const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const ManageProfile = ({ props }) => {

  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell
} = EditorTools;

const body1 = React.createRef();
const [aboutUs, setAboutUs] = useState('');
const [image, setimage] = useState('');
const [file, setfile] = useState('');
const [error, setError] = useState('');
const token = localStorage.getItem('avonToken');
const handlechangeimage=(e)=>{
setimage(e.target.files[0])
setfile(URL.createObjectURL(e.target.files[0]))
}
useEffect(()=>{
  fetchaboutus();
},[])
const Updateaboutus= async()=>{
  setError("")
  const view1 = body1.current.view;
    var content = EditorUtils.getHtml(view1.state);
  var data = new FormData();
  data.append('image',image);
  data.append('content',content);
  await axios.post(BASE_URL + '/updateaboutus',data ,{
    headers: {
      // 'content-type': 'multipart/form-data',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then(({ data }) => {
    if(data.status=="success"){
      setimage("")
      
      toast.success(data.message)
      fetchaboutus();

    }
    
  }).catch(({ response }) => {
    console.log(response.data)
    if (response.data.status_code == 422) {
      setError(response.data.message)

      // if (response.data.message.aboutus) {
      //   toast.error(response.data.message.aboutus[0])
      // }
      // if (response.data.message.bank_acc_name) {
      //   toast.error(response.data.message.bank_acc_name[0])
      // }
      // if (response.data.message.address) {
      //   toast.error(response.data.message.address[0])
      // }
    }
  })
}
const fetchaboutus = async () => {
  await axios.get(BASE_URL + '/getaboutus', {
    headers: {
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  }).then(({ data }) => {
    if(data.data){
      setAboutUs(data.data.content)
      setfile(data.data.image ? data.image_url+data.data.image : "")
    }
    
    // console.log("profile", data.data)

  })
}
  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Manage Profile</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>

            </ul>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu  />
              <div className="col-md-9">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>
                    About Us 
                  </h4>
                  {/* <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}>Edit</Button> */}
                </div>
     

                <div className="product-card-block">
                  {/* <div className="order-head-blk mb-3">
                    <h5>Store Setup</h5>
                  </div> */}
                  <div className="card-body-blk">
                    <div className="row">
                                          
                      <div className="col-md-12 mb-2 mt-2">
                        <Form.Label>About Us</Form.Label>
                        <Editor tools={[[Bold, Italic, Underline, Strikethrough], [Subscript, Superscript], [AlignLeft, AlignCenter, AlignRight, AlignJustify], [Indent, Outdent], [OrderedList, UnorderedList], FontSize, FontName, FormatBlock, [Undo, Redo], [Link, Unlink, InsertImage, ViewHtml], [InsertTable], [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter], [DeleteRow, DeleteColumn, DeleteTable], [MergeCells, SplitCell]]} contentStyle={{
                          height: 300
                        }}

                          value={aboutUs}
                          ref={body1}
                          onChange={(e) => setAboutUs(e.target.value)}
                        />
                        {error.content &&(
                          <div className="text-danger">{error.content}</div>
                        )}

                      </div> 
                      <ToastContainer />
                    </div>

                  </div>
                </div>
                <div className="product-card-block">
                  {/* <div className="order-head-blk mb-3">
                    <h5>Store Setup</h5>
                  </div> */}
                  <div className="card-body-blk">
                    <div className="row">
                                          
                      <div className="col-md-2 mb-2 mt-2">
                        <Form.Label className="mt-2">About Us Image</Form.Label>
                      </div> 

                      <div className="col-md-10 mb-2 mt-2">
                        <Form.Control type="file" onChange={handlechangeimage} />                   
                        {error.image &&(
                          <div className="text-danger">{error.image}</div>
                        )}
                        {
                          file && <img src={file} height={100} width={100} />
                        }
                        
                      </div>
                      <div className="col-md-12">
                              <Button
                                style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                variant="warning"
                                onClick={Updateaboutus}
                              >
                                Update
                              </Button>
                            </div>
                     
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
    </Page>
  );
};

export default ManageProfile;
