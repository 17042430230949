import React, { useState, useEffect } from "react";
import "../style/ManageServices.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { Header } from "../components/manage-services/Header";
import { MainContent } from "../components/manage-services/MainContent";
import Page from "../components/Page";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { AddnewService } from "../components/manage-services/AddnewService";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
export const ManageServices = () => {
  const [isaddService, setIsaddService] = useState(false);
  const [data, setdata] = useState([]);
  const token = localStorage.getItem("avonToken");
  const navigate = useNavigate();
  useEffect(() => {
    Fetchdata();
  }, []);
  const Fetchdata = async () => {
    await axios
      .get(BASE_URL + "/servicessellers", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setdata(data.data);
      });
  };
  const deletedata = async (i) => {
    await axios
      .get(BASE_URL + "/deleteservicesellers/"+i, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        Fetchdata();
      });
  };
  return (
    <Page title="Manage Services">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Manage Services</li>
            </ul>
            {/* <div className="col-md-12">
          <Button className="dashboard-btn" href="/dashboard" variant="primary">
              Customer Dashboard
            </Button>
            </div> */}
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Manage Services</h4>

                <section>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-block">
                        <div className="order-head-blk">
                          <h5>Manage Services</h5>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 pe-4 right">
                          {isaddService === true ? (
                            <AddnewService />
                          ) : (
                            <>
                              <div className="heading-div d-flex justify-content-between align-items-center">
                                <h2>Manage Services</h2>
                                <button
                                  className="px-4 py-2 rounded border-0 btn btn-primary"
                                  onClick={() => navigate("/add-services")}
                                >
                                  <img
                                    src="/assets/images/plus-circle-white.png"
                                    alt=""
                                    className="pe-3"
                                  />
                                  Add New Services
                                </button>
                              </div>

                              <div className="table-div py-3 my-4 px-3 bg-white">
                                <h4>Services</h4>
                                <div className="mng-overflow">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">Image</th>
                                        <th scope="col">Service Category</th>
                                        <th scope="col">Service Name</th>
                                        <th>Cost</th>
                                        <th>Status</th>
                                        <th className="text-cetner">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data &&
                                        data.map((item, i) => (
                                          <tr key={i}>
                                            <td>{item.created_at}</td>
                                            <td>
                                              <img
                                                src={IMAGE_BASE_URL+item.service_image}
                                                alt=""
                                                style={{
                                                  maxWidth: "61px",
                                                  maxHeight: "41px",
                                                }}
                                              />
                                            </td>
                                            <td>{item?.category?.name}/{item?.subcategory?.name}</td>
                                            <td>{item.service_name}</td>
                                            <td><i className="fa fa-inr"></i>{item?.package[0].package_price}</td>
                                            <td className="status">
                                              <span>{item.status == "active"? "Active" :"Block"}</span>
                                            </td>
                                            <td className="d-flex gap-2 actions">
                                              {/* <button
                                                className="rounded-circle "
                                                style={{
                                                  backgroundColor:
                                                    "rgba(30, 224, 172, 0.20)",
                                                }}
                                              >
                                                <img
                                                  src="/assets/images/eye-lgreen.png"
                                                  alt="icon"
                                                />{" "}
                                              </button> */}
                                              <button
                                                className="rounded-circle "
                                                onClick={() => navigate("/edit-services/"+item.ids)}
                                                style={{
                                                  backgroundColor:
                                                    "rgba(23, 162, 184, 0.40)",
                                                }}
                                              >
                                                <img
                                                  src="/assets/images/edit-lblue.png"
                                                  alt="icon"
                                                />{" "}
                                              </button>
                                              <button
                                                className="rounded-circle "
                                                onClick={()=>deletedata(item.ids)}
                                                style={{
                                                  backgroundColor:
                                                    "rgba(236, 28, 36, 0.40)",
                                                }}
                                              >
                                                <img
                                                  src="/assets/images/delete-lred.png"
                                                  alt="icon"
                                                />{" "}
                                              </button>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
    // <MainContent/>
  );
};
