import React,{useState,useEffect,useRef } from "react";
import "../style/Reportabuse.css";
import axios from "axios";
import { BASE_URL } from "../config";
import { ToastContainer,toast } from "react-toastify";
import { Modal,Button } from "react-bootstrap";
import { OtpInput } from "./OtpInput";

export const Reportabuse = () => {
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [error, seterror] = useState([]);
  const [otp, setotp] = useState("");
  const [id, setid] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setdata] = useState({
    name: null,
    email: null,
    email_confirmation: null,
    mobile_number: null,
    address: null,
    company_name: null,
    state: null,
    city: null,
    pincode: null,
    evidence_url: null,
    describe_abuse: null,
    notified_complaint: [],
  });
  const handledata = (e) => {
    const { name, value,checked  } = e.target;
   setdata((prevData) => ({
  ...prevData,
  [name]: name === "mobile_number" ? Number(value) : (name === "notified_complaint" ? 
    (checked ? [...prevData.notified_complaint, value] : prevData.notified_complaint.filter((item) => item !== value)) 
    : value),
}));
  };
  useEffect(()=>{
    window.scrollTo(0,0)
    getState(101);
  },[])
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const handleSubmit=()=>{
    seterror("");
    axios
      .post(BASE_URL + `/addreportabuse`, data)
      .then((res) => {
        if (res.status === 201) {
          setid(res.data.data);
          toast.success("Otp Send Successfully On Your Email!");
        //  toast.success("Send Successfully!");
         openModal();
         
        document.getElementsByClassName("reportform")[0].reset();
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
        
      });
  }
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleOtpComplete = (otp) => {
    setotp(otp)
    console.log('OTP entered:', otp);
    // You can perform any action with the completed OTP
  };
  const verifyemail=()=>{
    axios
      .post(BASE_URL + `/verifyemailreportabuse`, {"otp":otp,"enc_id":id,"email":data.email})
      .then((res) => {
        if (res.data.status === true) {
          toast.success("Mail verified Successfully!");
          toast.success("Report Send Successfully!");
          document.getElementsByClassName("reportform")[0].reset();
           closeModal();
          setdata({
            name: null,
            email: null,
            email_confirmation: null,
            mobile_number: null,
            address: null,
            company_name: null,
            state: null,
            city: null,
            pincode: null,
            evidence_url: null,
            describe_abuse: null,
            notified_complaint: [],
          });
        
        }
        
        if(res.data.status === false){
          seterror({"otp":res.data.message});
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
        
      });
  }
  const resendemail=()=>{
    axios
      .post(BASE_URL + `/resendemailreportabuse`, {"enc_id":id,"email":data.email})
      .then((res) => {
        if (res.data.status === true) {
          toast.success("Otp Re-Send Successfully On Email!");
        }
        
        if(res.data.status === false){
          seterror({"otp":res.data.message});
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
        
      });
  }
  return (
    <>
      <div className="report-abuse">
        <div className="container d-flex justify-content-between px-lg-5 px-3 py-lg-5 py-4 bg-white my-lg-5 my-md-4 my-3">
          <div className="col-lg-5 left">
            <h3>Report Abuse</h3>
            <h6>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt.
            </h6>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
              incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit.
            </p>
          </div>

          <div className="col-lg-6 right-form bg-white shadow-sm p-3">
            <h4>
            Submit an Report Abuse
            </h4>
         

            <form action="" className="reportform">
              
              <div className="my-3 d-flex gap-3 justify-content-between align-items-center">
               <> <input type="text" placeholder="Full Name" name="name" onChange={handledata} />
                {error && error.name &&(
                <span className="text-danger">{error.name}</span>
              )}</>
              <>
              <input type="email" placeholder="Email Address" name="email" onChange={handledata} />
                {error && error.email &&(
                <div className="text-danger">{error.email}</div>
              )}
              </>
              </div>
              
              
              
             
              <div className="my-3 d-flex gap-3 justify-content-between align-items-center">
                <>
                <input type="email" placeholder="Confirm Email Address" name="email_confirmation" onChange={handledata} />
                {error && error.email_confirmation &&(
                <div className="text-danger">{error.email_confirmation }</div>
              )}
              </>
              <>
                <input type="number" placeholder="Mobile Number" name="mobile_number" onChange={handledata} />
                {error && error.mobile_number &&(
                <div className="text-danger">{error.mobile_number}</div>
              )}
              </>
              </div>
              <div className="my-3 d-flex gap-3 justify-content-between align-items-center">
                <>
                <input type="text" placeholder="Company" name="company_name" onChange={handledata} />
                {error && error.company_name &&(
                <div className="text-danger">{error.company_name}</div>
              )}</>
              <>
                <input type="text" placeholder="Full Address" name="address" onChange={handledata} />
                {error && error.address &&(
                <div className="text-danger">{error.address}</div>
              )}
              </>
              </div>      

              <div className=" my-3 d-flex gap-3 justify-content-between align-items-center">
                <select class="form-select" aria-label="Default select example" name="state" onChange={(e) => { handledata(e); getCity(e.target.value) }}>
                  <option value="">State</option>
                  {fetchState && fetchState.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                </select>
                {error && error.state &&(
                <div className="text-danger">{error.state}</div>
              )}
                <select class="form-select" name="city"  onChange={handledata} aria-label="Default select example">
                  <option value="">City</option>
                  {fetchCity && fetchCity.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                </select>
                {error && error.city &&(
                <div className="text-danger">{error.city}</div>
              )}
              </div>
                
              <div className="my-3 d-flex gap-3 justify-content-between align-items-center">
                <input type="number" placeholder="Pin Code" name="pincode" onChange={handledata} />
                {error && error.pincode &&(
                <div className="text-danger">{error.pincode}</div>
              )}
                <input type="text" placeholder="Evidence URL" name="evidence_url" onChange={handledata} />
                {error && error.evidence_url &&(
                <div className="text-danger">{error.evidence_url}</div>
              )}
              </div>    
              <div class="form-floating">
                <textarea
                  className="border-0 px-3 py-3 w-100"
                  id=""
                  cols="60"
                  rows="6"
                  name="describe_abuse"
                   onChange={handledata}
                  placeholder="Describe the Abuse"
                ></textarea>
                {error && error.describe_abuse &&(
                <div className="text-danger">{error.describe_abuse}</div>
              )}
              </div>
              <div className="">
                <p className="m-0">Because ApnaShaher does not have the ability to remove content from a website, it is our practice to forward abuse complaints to entities like the hosting provider and/or website owner to follow up. Please specify:</p>
              </div>
              <div className="my-3 d-flex  flex-column  gap-0">
                <div className="lform-content col-lg-12 col-md-12 col-sm-12 col-12">
                    <h5 className="m-0">Who should be notified?</h5>
                    {/* <p className="m-0">Pease select at least one</p> */}
                    <p className="small-para"><strong>Note: </strong>The hosting provider may have their own policies for how they notify the website owner of a complaint.</p>
                </div>
                <div className="rform-content col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="d-flex gap-2   ">
                        <input className="mt-1 ml-2" type="checkbox" value="Include my name and contact information with the report to the website hosting provider" name="notified_complaint" onChange={handledata} />
                        <p className="mb-0 small-para ml-2">Include my name and contact information with the report to the website hosting provider.</p>
                    </div>
                    <div className="d-flex gap-2 ">
                        <input className="mt-1 ml-2" type="checkbox" name="notified_complaint" value="Please forward my report to the website owner" onChange={handledata} />
                        <p className="mb-0 small-para ml-2">Please forward my report to the website owner.</p>
                    </div>
                    <div className="d-flex gap-2 ">
                        <input className="mt-1 ml-2" type="checkbox" name="notified_complaint" value="Please forward my report to the website hosting provider" onChange={handledata} />
                        <p className="mb-0 small-para ml-2">Please forward my report to the website hosting provider.</p>
                    </div>
                    {error && error.notified_complaint &&(
                <div className="text-danger">{error.notified_complaint}</div>
              )}
                </div>
              </div>
            
              <div className="my-3 d-flex">
                <button type="button" onClick={handleSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
     
      <Modal show={isModalOpen} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Verify Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">Email address</label>
              <input type="email" class="form-control" id="email" name="email" value={data?.email} readOnly />
              {error && error.email &&(
                <span className="text-danger">{error.email}</span>
              )}
            </div>
            <OtpInput onComplete={handleOtpComplete} />
            {error && error.otp &&(
                <span className="text-danger">{error.otp}</span>
              )}
          </form>
        </Modal.Body>
        <Modal.Footer>
        
        <Button variant="secondary" onClick={resendemail}>
            Re-Send Mail
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={verifyemail}>
           Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
};
