import React, { useState, useContext } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import DispatchContext from '../context/DispatchContext';
import "bootstrap/dist/css/bootstrap.min.css";

import Page from "../components/Page";
import { useGoogleLogin } from '@react-oauth/google';
import { BASE_URL } from '../config';
const SignUp = () => {
  const states = useLocation();
  const path = window.location.origin
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [buttonText, setButtonText] = useState("Register");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const appDispatch = useContext(DispatchContext);
  const navigate = useNavigate();
  const login = useGoogleLogin({
    onSuccess: response => {
      Axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          "Authorization": `Bearer ${response.access_token}`
        }
      }).then(({ data }) => {
        Axios.post(BASE_URL + '/googlelogin', { 'email': data.email, 'name': data.given_name, 'google_id': data.sub }).then(({ data }) => {
          appDispatch({
            type: 'login',
            data: {
              token: data.data.token,
              name: data.data.user.name,
              email: data.data.user.email,
              mobileNumber: data.data.user.mobile_number,
              profileImage: data.data.user.profile_image,
              lastLogin: data.data.user.last_login,
            },
          });
          setEmail('');
          setPassword('');
          setErrors([]);
          setButtonText('Submitted!');
          appDispatch({ type: 'flashMessage', value: 'Login Successful' });
          appDispatch({ type: 'setWishlistCount', value: data.data.wishlistCount });
          if(states?.state?.redirectpath){
            navigate(states?.state?.redirectpath);
          }else{
            if(states.state){
              if(data.data.user.packageid){
                navigate('/subscription');
              }else{
                navigate('/vendor-checkout',{state:states.state}); 
              }
            
            }else{
              navigate('/dashboard');
            }
          }
         
        }).catch(({ response }) => {
          setName(data.name);
          setEmail(data.email);
        });

      })


    },
  });
  async function handleSubmit(e) {
    e.preventDefault();
    setButtonDisabled(true);
    setButtonText("Submitting...");
    try {
      const response = await Axios.post("/signup", {
        name,
        email,
        mobile_number: mobileNumber,
        gender,
        password,
        'url': path + '/verify-email/',  
        password_confirmation: confirmPassword,
      });
      const responseData = response.data;
      console.log(responseData);
      setName("");
      setEmail("");
      setMobileNumber("");
      setGender("");
      setPassword("");
      setConfirmPassword("");
      setErrors([]);
      setButtonText("Submitted!");
      appDispatch({
        type: 'login',
        data: {
          token: responseData.data.token,
          name: responseData.data.user.name,
          email: responseData.data.user.email,
          mobileNumber: responseData.data.user.mobile_number,
          profileImage: responseData.data.user.profile_image,
          lastLogin: responseData.data.user.last_login,
        },
      });
      if(states?.state?.redirectpath){
        navigate(states?.state?.redirectpath);
      }else{
        if(states.state){
          if(responseData.data.user.packageid){
            navigate('/subscription');
          }else{
            navigate('/vendor-checkout',{state:states.state}); 
          }
        }else{
          navigate('/dashboard');
        }
      }
      
      // navigate(`/login`,{state:states.state});
    } catch (e) {
      setButtonDisabled(false);
      setButtonText("Register");
      if (e.response.status === 422) {
        const response = await e.response.data;
        setErrors(response.errors);
      } else {
        console.log("Error encountered");
      }
    }
  }
  return (
    <Page title="Sign Up">
      <div className="login-signup-section">
        <Container>
          <div className="heading-title-section">

          </div>
          <Row className="justify-content-center">
            <Col md="4" className="login-768">
              <div className="login-inner-block">
                <div className="heading-title-blk">
                  <h3>Register Free</h3>
                </div>
                <div className="googleButton " style={{ cursor: "pointer" }} onClick={() => login()} >
                  <div className="GoogleIconCont" ><img src="/Icons/google.png" className="GoogleIcon" /></div>
                  <div className="googleText">Register with google</div>
                </div>
                <div className="or-block">
                    <span>OR</span>
                  </div>
                <div className="form-block">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter Name" onChange={(e) => setName(e.target.value)} value={name} />
                      <span className="text-danger">{errors.name}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email Id</Form.Label>
                      <Form.Control type="text" placeholder="Enter email Id" onChange={(e) => setEmail(e.target.value)} value={email} />
                      <span className="text-danger">{errors.email}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Mobile Number</Form.Label>
                      <Form.Control type="text" placeholder="Mobile Number" onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} />
                      <span className="text-danger">{errors.mobile_number}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Gender</Form.Label>
                      <Form.Control as="select" className="form-select" onChange={(e) => setGender(e.target.value)}>
                        <option value="">Select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Control>
                      <span className="text-danger">{errors.gender}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Create a Password</Form.Label>
                      <Form.Control type="password" placeholder="Create a Password" onChange={(e) => setPassword(e.target.value)} value={password} />
                      {/* <div className="input-text">Password should not be less then minimum 8 Alpha Numeric characters</div> */}
                      <div className="input-text">The password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.</div>
                      <span className="text-danger">{errors.password}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control type="password" placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                      <span className="text-danger">{errors.password_confirmation}</span>
                    </Form.Group>
                    <Button variant="primary full-btn" type="submit" disabled={buttonDisabled}>
                      {buttonText}
                    </Button>
                    <div className="login-bottom-blk">
                      <p>
                        By Logging In, you agree the following <NavLink to="/page/terms">Terms & Conditions</NavLink> and the <NavLink to="/page/privacy-policy">Privacy Policy</NavLink>
                      </p>
                      <h6>
                        Have an account? <NavLink to="/login" state={states.state}>Click here</NavLink>
                      </h6>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Page>
  );
};
export default SignUp;
