import { useContext, useEffect, useState } from "react";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import axios from 'axios';
import { BASE_URL } from "../../config";
import { toast } from "react-toastify";

const WalletRecharge = ({ showAdd, setShowAdd, rechargeId }) => {
  const handleClose = () => {
    setShowAdd(false);
  };

  const [bankTranfer, setBankTransfer] = useState(false);
  const [payment, setPayment] = useState(false);
  const [amountError, setAmountError] = useState([])
  const [paymentError, setPaymentError] = useState([])
  const [bankDetails, setBankDetails] = useState({})
  const [amount, setAmount] = useState({});
  const [paymentMethod, setPaymentMenthod] = useState("");
  const [paymentProof, setPaymentProof] = useState("");
  const [referenceId, setReferenceId] = useState("");


  const token = localStorage.getItem('avonToken');

  useEffect(() => {
    fetchbankDetails();
  }, [])

  const handleFileChange = (event) => {
    // Assuming you want to upload a single file
    setPaymentProof(event.target.files[0]);
  };

  const fetchbankDetails = async () => {
    await axios.get(BASE_URL + `/getbankdetail`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setBankDetails(data.data);
    })

  }
  console.log(bankDetails, "bankDetails");

  const handleSubmit = () => {
    const formData = new FormData();
    const paymentMethod = payment;
    const paymentMethod1 = payment;
    console.log(paymentMethod)
    formData.append('amount', amount);
    if (paymentMethod.toLowerCase() !== 'upi') {
      formData.append('reference_id', referenceId);
      formData.append('payment_proof', paymentProof);
    }
    if (paymentMethod !== 'Debit Card/Credit Card') {
      formData.append('reference_id', referenceId);
      formData.append('payment_proof', paymentProof);
    }
    if (paymentMethod !== 'Debit Card/Credit Card') {
      formData.append('reference_id', referenceId);
      formData.append('payment_proof', paymentProof);
    }
    formData.append('payment_method', payment);
    console.log(formData, "formdata");
    axios
      .post(BASE_URL + `/walletrecharge`, formData, {
        headers: {
          Accept: "application/json",
          'Authorization': 'Bearer ' + token
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status == "200") {
          toast.success("Added Succesfully");
          setShowAdd(false);
          console.log("set email called");
        }
      })
      .catch(({ response }) => {
        console.log(response?.data?.message, "waleet errro");
        setPaymentError(response?.data?.message?.payment_proof);
        // setAmountError(response?.data?.message?.amount[1]);
        // setSubjectError(response?.data?.message?.subject);
      });
  };
  console.log(paymentError, "amount error");

  return (
    <Modal
      size="lg"
      onHide={handleClose}
      className="recharge-modal-block"
      show={showAdd}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <h5 class="modal-title">Recharge Wallet</h5>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-md-4">
              <Form.Label>Amount *</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Control required type="text" placeholder="Enter Amount"
                  onChange={(e) => setAmount(e.target.value)}
                />

              </Form.Group>
              {/* <small style={{color: "red", position: "relative", top: "-20px"}}>{amountError[1] ? amountError[1] : ""}</small> */}
            </div>
            <div className="col-md-4">
              <Form.Label>Payment Method *</Form.Label>
            </div>

            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Select onChange={(e) => setPayment(e.target.value)}>
                  <option value="NetBanking">NetBanking</option>
                  <option value="UPI">UPI</option>
                  <option value="Debit Card/Credit Card">Debit Card/Credit Card</option>
                  <option value="Direct Bank Transfer">Direct Bank Transfer</option>

                </Form.Select>
              </Form.Group>

            </div>  </div>

          {payment == 'Direct Bank Transfer' ?
            <div className="mt-3">
              <div className='kyc-verification'>
                <h3>Pay Offline</h3>
                <h6>Account Details</h6>
                <p>You can transfer the money using following methods <b>RTGS, NEFT</b>and <b>IMPS</b></p>
                <div className="row">
                  <div className='col-md-6'>
                    <p className='details-name'>Admin Bank Name</p>
                    <p>{bankDetails.bank_name}</p>
                    <hr />
                  </div>
                  <div className='col-md-6'>
                    <p className='details-name'>IFSC Code</p>
                    <p>{bankDetails.ifsc_code}</p>
                    <hr />
                  </div>
                </div>
                <div className="row">
                  <div className='col-md-6'>
                    <p className='details-name'>Admin Account Number</p>
                    <p>{bankDetails.account_number}</p>
                    <hr />
                  </div>
                  {/* <div className='col-md-6'>
                    <p className='details-name'>Amount To be Paid</p>
                    <p>Amount</p>
                    <hr />
                    <p className='payment-info'>Processing Time : 24 Hours after payment</p>
                  </div> */}
                </div>
                <div className="row">
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Please Enter transaction's Refrence Id</Form.Label>
                      <Form.Control type="email" placeholder="Enter Refrence Id"
                        onChange={(e) => setReferenceId(e.target.value)}
                      />

                    </Form.Group>

                  </div>
                  <div className='col-md-6'>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Upload Screenshot</Form.Label>
                      <Form.Control type="file" placeholder="Enter Refrence Id"
                        onChange={handleFileChange}
                      />
                    </Form.Group>
                    <small style={{ color: "red", position: "relative", top: "-20px" }}>{paymentError[0] ? paymentError[0] : ""}</small>
                  </div>
                </div>

              </div>
            </div>
            : ""
          }



        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" type="submit" onClick={handleSubmit}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WalletRecharge;
