import React from 'react'
import "../../style/PostbuyRequirement.css"

export const PostbuyComp3 = (props) => {
  return (
    <>
    <div className="post-buy-comp-3rd">
      <div className="container" dangerouslySetInnerHTML={{__html:props?.data.widget4}}>
          
      </div>
    </div>
    </>
  )
}
