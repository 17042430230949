import React,{useState,useEffect} from "react";
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import "../style/Advertisewithus.css"
import { AdvertiseComp2 } from '../components/advertise-with-us/AdvertiseComp2'
import { Whysell } from '../components/seller-benefites/Whysell'
import { AdvertiseComp4 } from '../components/advertise-with-us/AdvertiseComp4'
import { AdvertiseComp5 } from '../components/advertise-with-us/AdvertiseComp5'
import { Sbsection4 } from '../components/seller-benefites/Sbsection4'
import { Advertisehelp } from '../components/advertise-with-us/Advertisehelp'
import axios from "axios";
import { BASE_URL } from "../config";
import Loader from "../components/loader/Loader.component";
import SeoSellerBenifitPage from "../seo page/SeoSellerbenifit";

export const Advertisewithus = () => {
  const[widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0,0)
    widgetData();
  }, []);
    const widgetData = async () => {
        try {
          const wgtData = await axios.get(BASE_URL + "/advertisewithuswidget");
          setwidget(wgtData.data.data);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      if (isLoading) {
        return <Loader />;
      }
  return (
    <>
    <SeoSellerBenifitPage/>
      <div style={{background:"#F5F5F5"}}>
    <section dangerouslySetInnerHTML={{__html:widget?.widget1}}></section>
    {/* <section>
    <Sellerbanner/>
   </section>

   <section>
    <AdvertiseComp2/>
   </section>

   <section className="why-sell-parent">
      <Whysell/>
    </section>

    <section>
        <AdvertiseComp4/>
    </section>

    <section> 
        <AdvertiseComp5/>
    </section>

    <section> 
        <Sbsection4/>
    </section> */}

    <section>
        <Advertisehelp data={widget}/>
    </section>

    </div>
    </>
  )
}
