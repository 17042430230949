import React, { useState, useEffect } from "react";
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';
import { DOMAIN_NAME, BASE_URL } from "../../config";
import { NavLink } from "react-router-dom";
import axios from "axios";

const datas = [
  { id: 1 }, { id: 2 }, { id: 3 }
]

export default function SellerFAQ() {
  const [data, setData] = useState();
  const [baseImage, setBaseImage] = useState();
  const [activeIndex, setActiveIndex] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    FetchProfileData();
    FetchFaq();
  }, [])

  const FetchProfileData = async () => {
    await axios.get(BASE_URL + '/seller/about-us', {
      headers: {
        'Accept': 'application/json',
        'domainname': DOMAIN_NAME
      }
    }).then(({ data }) => {
      setBaseImage(data.image_base_url)
      setData(data.data)
      console.log(data.data.image)
    })
  }

  const Base = baseImage && baseImage;
  const image = data && data.image;

  const FetchFaq = async () => {
    await axios.get(BASE_URL + '/seller/faqs', {
      headers: {
        'Accept': 'application/json',
        'domainname': DOMAIN_NAME
      }
    }).then(({ data }) => {
      setFaqs(data.data)
      console.log(data.data)
    })
  }

  return (
    <div>
      <SellerHeader />
      <section className="breadcrumb-sction mt-2">
        <div className="container-fluid">
          <ul className="breadcrumb-block">
            <li>
              <NavLink to="/seller-website">Home</NavLink>
            </li>
            <li>About</li>
          </ul>
        </div>
      </section>
      <div className="col-md-12">
        <img src={Base + image} style={{ width: '100%', maxHeight: '500px' }} />
      </div>
      <div className="col-md-12 mt-8 mb-8">
        <div className="row faqbox" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="col-md-6">
            <div className="product-details-main-block">
              <div className="card-block">
                <div className="card-body-blk-con">
                  <div className='seller-info-container'>
                    <span className='seller-card-about'>Welcome to our Store</span>
                  </div>
                </div>
                <div className='seller-block card-body-blk'>
                  <div className='mt-2'>
                    <div className="seller-store-content" dangerouslySetInnerHTML={{ __html: data && data.content }}>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            {faqs && faqs.map((data) => (
              <div className="faq-container">
                <div className="fax-container mt-2 mb-2" key={data.id}>
                  <div>
                    {data.question}
                  </div>
                  <button onClick={() => handleClick(data.id)}>
                    {activeIndex === data.id ?
                      <img src="/assets/images/minimise.png" /> : <img src='/assets/images/collapse.png' />}
                  </button>
                </div>
                <div>
                  {activeIndex === data.id &&
                    <div>
                    {data.answer}
                    </div>}
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
      <SellerFooter />
    </div>
  )
}