import React from 'react';
import ReactStars from 'react-stars';

const HomeInterior = () => {
    return (
        <>
            <div className='container mt-3'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='home-interior-section'>
                            <div className="feature-header">
                                <h6 className="cate-head">Home & Interior</h6>
                                <p style={{ textDecoration: "underline" ,cursor:"pointer"}}>View All</p>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 poster-img'>
                                    <img src="./assets/images/home-interior-1.png" width="100%" height="100%" />
                                </div>
                                <div className=' col-lg-8 col-md-8 col-sm-12 col-12'>
                                    <div className='row'>
                                        <div className='col-6 px-1'>
                                            <div className="feature-products-cards ">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className=' col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='home-interior-section'>
                            <div className="feature-header">
                                <h6 className="cate-head">Smart Phones</h6>
                                <p style={{ textDecoration: "underline",cursor:"pointer" }}>View All</p>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 poster-img'>
                                    <img src="./assets/images/home-interior-2.png" width="100%" height="100%" />
                                </div>
                                <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                                    <div className='row'>
                                        <div className=' col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='row'>
                                        <div className='col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6 px-1'>
                                            <div className="feature-products-cards">
                                                <img src="/assets/images/hat.png" width="100%" />
                                                <div className="" style={{ padding: "10px" }}>
                                                    <h3 className='product-head'>Black+Decker 4 Inch 820W Small Angle</h3>
                                                    <ReactStars
                                                        count={5}
                                                        size={24}
                                                        color2={'#ffd700'} />
                                                    <div className="currency-section">
                                                        <p className="currency-symbol-offer">$99</p>
                                                        <p className="currency-symbol-price">$89</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeInterior;