import axios from 'axios';
import React,{useState, useEffect} from 'react';
import { BASE_URL } from '../../../config';

const EmpowerBussiness = () => {

    const[allwidget, setAllwidget] = useState([])
    const widgetData = async () => {
        try {
          const wgtData = await axios.get(BASE_URL + "/homepagewidget");
          setAllwidget(wgtData.data.data);
        } catch (err) {
          console.log(err);
        }
      };
    
      useEffect(() => {
        widgetData();
      }, []);


    return (
        <>
            <div className='container'>
                <div className='empower-bussiness'>
                    <div className='empower-bussiness-content' dangerouslySetInnerHTML={{ __html: allwidget.widget5}} >
                        {/* <h1>Empowering businesses through global trade</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat.</p> */}
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6'>
                            <img src='/assets/images/buss-1.png' />
                        </div>
                        <div className='col-md-6'>
                            <img src='/assets/images/buss-2.png'  className='mb-1'/>
                            <img src='/assets/images/buss-3.png' className='mt-4'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmpowerBussiness;