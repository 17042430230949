import { useContext, useState } from "react";
import StateContext from "../../context/StateContext";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";

const AddShopAddress = ({ showAdd, setShowAdd }) => {
  const appState = useContext(StateContext);

  const { countries } = appState;
  const [country, setCountry] = useState("");
  const handleClose = () => {
    setShowAdd(false);
  };

  //   async function handleSubmit(e) {
  //     e.preventDefault();
  //     setButtonDisabled(true);
  //     try {
  //       setErrors([]);
  //       const response = await Axios.post(
  //         '/add-address',
  //         {
  //           address,
  //           firstName,
  //           lastName,
  //           city,
  //           state,
  //           country,
  //           zipcode,
  //           landmark,
  //           phone,
  //           emailId,
  //           isDefault,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${appState.state.user.token}`,
  //           },
  //         }
  //       );
  //       const responseData = response.data.data;
  //       console.log(responseData);
  //       // setAddresses([...addresses, responseData.address]);
  //       setErrors([]);
  //       setButtonDisabled(false);
  //       setButtonText('Submit');
  //       appDispatch({ type: 'flashMessage', value: 'Address Added' });
  //       setShowAdd(false);
  //     } catch (error) {
  //       setButtonDisabled(false);
  //       setButtonText('Submit');
  //       if (error.response.status === 422) {
  //         const response = await error.response.data;
  //         setErrors(response.errors);
  //       } else {
  //         console.log('Error encountered');
  //       }
  //     }
  //   }

  return (
    <Modal
      size="md"
      onHide={handleClose}
      className="shop-modal-block"
      show={showAdd}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <h5 class="modal-title">New Address</h5>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            <div className="col-md-4">
              <Form.Label>Address</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Control
                  as="textarea"
                  placeholder="Enter address"
                  rows={3}
                />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Label>Country</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Select
                  onChange={(e) => setCountry(e.target.value)}
                  value={country}
                >
                  <option value="">Country</option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Label>City</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Control type="text" placeholder="Enter city" />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Label>Postal Code</Form.Label>
            </div>
            <div className="col-md-8">
            <Form.Group className="form-group">
                <Form.Control type="text" placeholder="Enter postal code" />
              </Form.Group>
            </div>
            <div className="col-md-4">
              <Form.Label>Mobile Number</Form.Label>
            </div>
            <div className="col-md-8">
            <Form.Group className="form-group">
                <Form.Control type="text" placeholder="Enter mobile number" />
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" type="submit">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddShopAddress;
