import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DashboardSideMenu from "../components/DashboardSideMenu";
import { NavLink } from "react-router-dom";
import { Button, Row, Table } from "react-bootstrap";

import Page from "../components/Page";
import DashboardOrderList from "../components/dashboard/dashboard-order-list.component";
import DashboardPurchaseList from "../components/dashboard/dashboard-purchase-list.component";
import axios from "axios";
import { BASE_URL } from "../config";
import { Link } from "react-router-dom";
const MyEnquiry = () => {
  const token = localStorage.getItem('avonToken');
  const [ordersData, setordersData] = useState([]);
  useEffect(() => {
    fetchenquiry();
  }, []);
  const fetchenquiry = async () => {
    await axios.get(BASE_URL + '/getproductcustomerenquiry', {
      headers: {
        // 'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setordersData(data.data)
    }).catch(({ response }) => {

    })
  }

  console.log("ordersData", ordersData);
  return (
    <Page title="Dashboard">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Dashboard</li>
              <Button
                style={{ float: "right" }}
                href="/vendor-dashboard"
                variant="primary"
              >
                Seller Dashboard
              </Button>
            </ul>
          </div>

          {/* <div style={{float: 'right'}}>
              <Button href="/vendor-dashboard" className="toggle-button" variant="warning">
                Seller Info
              </Button>
            </div> */}
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <DashboardSideMenu />
              <div className="col-md-9">
                <div className="card-block">
                  <div className="card-head-blk">
                    <h4>My Enquiry</h4>
                  </div>

                  <div className="card-body-blk">
                    <h5 className="dashboardOrderList">Order Enquiry List</h5>
                    <div className="purchase-table">
                      <div className="purchase-list-table">
                        <Table responsive="md">
                          <thead className='purchase-heading'>
                            <tr>
                              <th>#</th>
                              <th>Product Image</th>
                              <th>Product Name</th>
                              <th>Variant</th>
                              <th>Price</th>
                              <th>Quantity range</th>
                              <th>Seller Name</th>
                              <th>Product Link</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ordersData.map((purchase, i) =>
                              <tr key={i}>
                                <td>{++i}</td>
                                <td><img src={purchase.photos} height={50} /></td>
                                <td>{purchase.product.name}</td>
                                <td>{purchase.productoption.variant}</td>
                                <td >{purchase.productoption.price}</td>
                                <td>{purchase.quantity}</td>
                                <td>{purchase.seller.name}</td>
                                <td><Link to={"/product/" + purchase.product.slug}>Go To</Link></td>
                              </tr>

                            )}
                          </tbody>
                        </Table>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  )
}

export default MyEnquiry
