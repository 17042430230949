import React, { useState } from "react";
import "../style/Feedback.css";
import { Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../config";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const Feedback = () => {
  const [rating, setRating] = useState(5);
  const [feedback, setfeedback] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errors, seterrors] = useState([]);
  const token = localStorage.getItem("avonToken");
  const navigate = useNavigate();
  const handleClick = (index) => {
    setRating(index + 1);
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    setSelectedFiles(files);
  };
  const stars = [...Array(5)].map((_, index) => (
    <span
      key={index}
      className={index < rating ? "star-golden" : "star"}
      onClick={() => handleClick(index)}
    >
      <i class="bi bi-star-fill"></i>
    </span>
  ));
  const handleSubmit = () => {
    seterrors("");
    if (!token) {
      navigate("/logins");
    }
    const formData = new FormData();

    // Append each selected file to FormData
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.append("files[]", selectedFiles[i]);
    }
    formData.append("rating", rating);
    formData.append("feedback", feedback);

    axios
      .post(BASE_URL + "/addfeedback", formData, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data && data.status === 201) {
          document.getElementsByClassName("feedbackform")[0].reset();
          setfeedback("");
          setSelectedFiles("");
          toast.success("Feedback Send Successfully!");
        }
      })
      .catch(({ response }) => {
        if(response && response.data){
          seterrors(response.data.errors);
        }
       
      });
  };
  return (
    <>
      <div className="feedback my-lg-5 my-md-4 my-3">
        <div className="container text-center w-75 px-3 py-5 bg-white">
          <h3>
            Submit your #ApnaShaher story and we’ll share it with the world!
          </h3>
          <Form className="feedbackform">
            <div className="feedback-form">
              <div className="star-rating  d-flex justify-content-center gap-2 my-3">
                {stars}
              </div>
              {errors.rating && (
                <div className="text-danger">{errors.rating}</div>
              )}

              <div className="my-3">
                <textarea
                  className="w-100 px-3 py-3"
                  name=""
                  id=""
                  onChange={(e) => setfeedback(e.target.value)}
                  cols="30"
                  rows="6"
                  placeholder="Share your experience here"
                ></textarea>
                {errors.feedback && (
                  <div className="text-danger">{errors.feedback}</div>
                )}
              </div>

              <div className="my-3 upload-document position-relative py-2 px-2">
                <div className="position-absolute ">
                  <span>
                    <i class="bi bi-camera"></i>
                  </span>{" "}
                  <label> Add Photo and video </label>
                </div>
                <input
                  type="file"
                  className="border-0 text-white w-100"
                  placeholder="Add Photo and video"
                  onChange={handleFileChange}
                  style={{ color: "#F5F5F5" }}
                  multiple
                />
                {errors.files && (
                  <div className="text-danger">{errors.files}</div>
                )}
              </div>

              <div className="my-2 text-start ">
                <button type="button" onClick={handleSubmit}>
                  Post Now
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
