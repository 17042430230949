import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";

export const Itservices = (props) => {
  const navigate = useNavigate();
  return (
    <div className="it-services mt-3">
      <div className="container">
        <div className="main-cont my-lg-4 my-3">
          <div className="d-flex top-heading justify-content-between  align-items-center">
            <h6 className="">{props.heading}</h6>
            <Link to={"/service-category/" + props?.data?.slug}>View more</Link>
          </div>
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <div
              className="scards d-flex  gap-lg-3 gap-md-3 gap-2  "
              style={{ minWidth: "992px" }}
            >
              {props?.data?.services &&
                props?.data?.services.map((item, i) => (
                  <div
                    class="card mr-3"
                    key={i}
                    
                  >
                    <img
                      src={item.service_image}
                      className="card-img-top rounded-top-3"
                      alt="..."
                      width="328px"
                      onClick={() => navigate("/service-detail/" + item.slug)}
                    />
                    <div class="card-body px-0 pb-0 bg-white">
                      <div className="  px-2 pb-0">
                        <div className="sprovider d-flex gap-3 pb-2">
                          <img
                            src={item.user_image}
                            alt=""
                            width={"31px"}
                            height={"31px"}
                            onClick={()=> navigate("/seller-detail/" + item.business_slug)}
                          />
                          <div className="d-flex align-items-center ml-1">
                            <h5 class="card-title ">{item.seller_name}</h5>
                            {/* <p className="mb-0">{item.service_title}</p> */}
                          </div>
                        </div>
                        <div className="hpara">{item.service_name}</div>
                        <div className="sp-rating d-flex gap-2 py-2">
                          <span className="star">
                            <ReactStars
                              count={5}
                              value={item.rating}
                              size={20}
                              edit={false}
                              color2={"#ffd700"}
                            />
                            {/* <i className="fa fa-star fs-5" style={{ color: "#FFCE31" }}></i> */}
                          </span>
                          <span className="rating-digit ml-2">{item.rating}</span>
                          <p className="rating-people mb-0 mt-0 ml-2 align-item-center">
                            ({item.total_rating})
                          </p>
                        </div>
                      </div>
                      <div className="price-cont  px-2  d-flex align-items-center justify-content-between">
                        <div>
                          <span>Starting At</span>
                          <div className="d-flex gap-2">
                            <p className="act-price">
                            ₹ {item?.packages[0]?.package_price}
                            </p>
                            <p className="dprice ml-2">
                            ₹ {item?.packages[0]?.package_mrp}
                            </p>
                          </div>
                        </div>
                        <div>
                          <span className="heart">
                            <i className=" fa fa-heart"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
