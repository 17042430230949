import React, { useState } from 'react'
import "../style/NewLeads.css"
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";

export const Graphic = () => {
    const [actBtn, setActBtn] = useState(1);
    const [currTab, setCurrtab] = useState("init-grph")

    const chageTab = (btnId, comp) => {
        setActBtn(btnId);
        setCurrtab(comp)
    }

    const isActive = (btnId) => {
        return actBtn === btnId ? 'active-tab' : '';
    };

    return (
        <>
            <div className="new-leads graphic py-4" style={{ background: "#0000001a" }}>

                <div className="main-cont d-flex justify-content-between">

                    <VendorDashboardSideMenu />


                    {/* rght side Cont  */}
                    <div className="right px-lg-5 px-md-4 px-3 col-md-9" >
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <h2>Social Media Summary</h2>

                        </div>


                        <div className="art-2 my-lg-5 my-4">
                            <div>
                                <h6>Graphics List</h6>
                            </div>

                            <div className="graphics-div px-lg-5 px-md-4 px-3 pb-5">

                                <div className="tab-btns d-flex gap-1 py-lg-4 py-3 ">
                                    <button className={`${isActive(1)} `} onClick={() => chageTab(1, "init-grph")}>New Graphics</button>
                                    <button className={`${isActive(2)} `} onClick={() => chageTab(2, "approved-grph")}>Approved</button>
                                </div>

                                {/* Initial Graphics  */}
                                {currTab === "init-grph" ?
                                    <div className="initial-graphic d-flex alingn-itmes-center flex-wrap gap-lg-5 gap-md-4 gap-3">
                                        {/* card1  */}
                                        <div className="pg-card">
                                            <img src="/assets/images/graphic-1.png" alt="" />
                                            <div className="card-cont d-flex flex-column gap-3 mt-3">
                                                <button>Send Changes</button>
                                                <button className='bg-lgreen'>Approve</button>
                                            </div>
                                        </div>
                                        <div className="pg-card">
                                            <img src="/assets/images/graphic-1.png" alt="" />
                                            <div className="card-cont d-flex flex-column gap-3 mt-3">
                                                <button>Send Changes</button>
                                                <button className='bg-lgreen'>Approve</button>
                                            </div>
                                        </div>
                                        <div className="pg-card">
                                            <img src="/assets/images/graphic-1.png" alt="" />
                                            <div className="card-cont d-flex flex-column gap-3 mt-3">
                                                <button>Send Changes</button>
                                                <button className='bg-lgreen'>Approve</button>
                                            </div>
                                        </div>



                                    </div>
                                    :
                                    currTab === "approved-grph" ?
                                        <div className="initial-graphic d-flex alingn-itmes-center flex-wrap gap-lg-5 gap-md-4 gap-3">
                                            {/* card1  */}
                                            <div className="pg-card">
                                                <img src="/assets/images/graphic-1.png" alt="" />
                                                <div className="card-cont d-flex flex-column gap-3 mt-3">
                                                    <button className='bg-lgreen'>Download</button>
                                                </div>
                                            </div>
                                            <div className="pg-card">
                                                <img src="/assets/images/graphic-1.png" alt="" />
                                                <div className="card-cont d-flex flex-column gap-3 mt-3">
                                                <button className='bg-lgreen'>Download</button>
                                                </div>
                                            </div>
                                            <div className="pg-card">
                                                <img src="/assets/images/graphic-1.png" alt="" />
                                                <div className="card-cont d-flex flex-column gap-3 mt-3">
                                                <button className='bg-lgreen'>Download</button>
                                                </div>
                                            </div>



                                        </div>
                                        : null
                                }


                                {/* Approved  */}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

