import React,{useState, useEffect} from 'react';
import axios from 'axios';
import { BASE_URL } from '../../../config';

const OurCompetitors = () => {
    const[allwidget, setAllwidget] = useState([])


    
// Get data to api
    const widgetData = async () => {
        try{
            const wgtData = await axios.get(BASE_URL +  "/homepagewidget");
            setAllwidget(wgtData.data.data)
        }catch (err) {
            console.log(err);
        }
    }
    
    useEffect(() => {
        widgetData();
      }, []);

    return (
        <>
        <div dangerouslySetInnerHTML={{ __html: allwidget.widget11}}>
       

        </div>
           
        </>
    )
}

export default OurCompetitors;