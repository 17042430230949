import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { Form,FloatingLabel } from "react-bootstrap";
import axios from "axios";
import { BASE_URL,DOMAIN_NAME,IMAGE_BASE_URL } from "../../config";
import { ToastContainer,toast } from "react-toastify";
import { useEffect } from "react";
export default function SellerFAQ({data,homepagewidget}) {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [image, setImage] = useState("");
    const [feedback, setFeedback] = useState("");
    const [error, seterror] = useState([]);
    const [testimonial, settestimonial] = useState("");
    const [testimonials, settestimonials] = useState([]);
    const [forwardactive,setforwardactive] = useState(false)
    const [backwardactive,setbackwardactive] = useState(false)
    const handleImage = e => {
        setImage(e.target.files[0]);
    }
    useEffect(()=>{
        gettestimonial();
    },[])
    const gettestimonial=async()=>{
        await axios.get(BASE_URL + '/seller/gettestimonial', {
            headers: {
                'Accept': 'application/json',
                'domainname': DOMAIN_NAME
            }
        }).then(({ data }) => {
            settestimonials(data?.data)
            settestimonial(data?.data[0])
            if(data?.data?.length > 1){
                setforwardactive(true)
            }
        }).catch(({ response }) => {

        })
    }
    const submitEnquiry = () => {
        seterror("")
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile_number', mobileNumber);
        formData.append('image', image);
        formData.append('country_code', "+91");
        formData.append('feedback', feedback);
        axios.post(BASE_URL + `/seller/addquery`, formData,{
            headers: {
                'domainname': DOMAIN_NAME
            }
        })
            .then((res) => {
                console.log(res);
                if (res.status == '200') {
                    console.log("set enqiury called");
                    toast.success("Query Send Successfully")
                    document.getElementById("reset").reset();
                    setName("");
                    setEmail("");
                    setMobileNumber("");
                    setFeedback("");
                }
            })
            .catch(({response}) => {
                seterror(response.data.message)
                console.log(error, "status");
            });
    }
    const backtestimonial=()=>{
        var g1 = testimonial;
        let index1 = testimonials.indexOf(g1);
        if(index1 === 1){
            setbackwardactive(false)
        }
        if(testimonials.length >  testimonials.length -index1 ){
            setforwardactive(true)
            settestimonial((testimonials.slice(index1-1,index1))[0]);
        }
    }
    const forwardtestimonial=()=>{
        var g1 = testimonial;
        let index1 = testimonials.indexOf(g1);
        console.log(index1)
        if(testimonials.length - index1 === 2){
    setforwardactive(false)
        }
        if(testimonials.length >= index1+2){
            setbackwardactive(true)
            settestimonial((testimonials.slice(index1+1,index1+2))[0]);
        }
    }
    return (
        <div>
            <div className="col-md-12 mt-8 mb-8">
                <div className="seller-product-heading">{homepagewidget?.widget10}</div>
                <div className="row faqbox" style={{marginLeft:0, marginRight:0}}>
                    <img src="assets/images/brand-img.png" style={{width:"200px"}} />    
                    <img src="assets/images/brand-img.png" style={{width:"200px"}} />    
                    <img src="assets/images/brand-img.png" style={{width:"200px"}} />    
                    <img src="assets/images/brand-img.png" style={{width:"200px"}} />    
                    <img src="assets/images/brand-img.png" style={{width:"200px"}} />    
                </div>

            </div>
            <div className="col-md-12 row" style={{backgroundImage:`url('assets/images/Rectangle 588.png')`}}>
                <div className="col-md-6 query-cont">
                    <h1 style={{width:'50%'}}>
                    {homepagewidget?.widget11}
                    </h1>
                    <div className="query-form mt-4">
                    <Form id="reset">
                        <div className="seller-subscribe-card">
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="text" placeholder="Full Name"
                                    value={name} onChange={(e) => setName(e.target.value)}
                                />
                                {error && error.name &&(
                                    <span className="text-danger">{error.name}</span>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="email" placeholder="Email"
                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                />
                                {error && error.email &&(
                                    <span className="text-danger">{error.email}</span>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="number" placeholder="Phone Number"
                                    value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)}
                                />
                                {error && error.mobile_number &&(
                                    <span className="text-danger">{error.mobile_number}</span>
                                )}
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Control type="file" placeholder="Upload Image"
                                    onChange={handleImage}
                                />
                                {error && error.image &&(
                                    <span className="text-danger">{error.image}</span>
                                )}
                            </Form.Group>
                            <FloatingLabel
                                controlId="floatingTextarea"
                                label="Feedback"
                                className="mb-3"
                            >
                                <Form.Control as="textarea" placeholder="Leave a comment here"
                                    value={feedback} onChange={(e) => setFeedback(e.target.value)}
                                />
                                {error && error.feedback &&(
                                    <span className="text-danger">{error.feedback}</span>
                                )}
                            </FloatingLabel>
                            <button type="button" className="get-in-touch-btn" onClick={submitEnquiry}>GET IN TOUCH</button>
                        </div>
                        </Form>
                        {/* <input placeholder="First Name" />
                        <input placeholder="Last Name" className="mt-2" />
                        <input placeholder="Email" className="mt-2" />
                        <input placeholder="Phone Number" className="mt-2" />
                        <textarea placeholder="Message" className="mt-2" />
                        <Button className="mt-2" style={{width:'100%'}}>Get in Touch</Button> */}
                    </div>
                </div>
                <div className="col-md-6 center seller-feed" style={{backgroundImage:`url('assets/images/banner-seller.png')`}}>
                    <h1 className="mt-8" style={{width:'50%', textAlign:'left',color:"#000"}}>
                    {homepagewidget?.widget12}
                    </h1> 
                    <img src="/assets/images/double-quotes.png" />
                                <p style={{color:"#000"}}>{testimonial?.feedback}</p>
                                <div className="seller-testimonials">
                                    <div className="testimonils-section">
                                        <div>
                                            <img height={192} width={192} src={IMAGE_BASE_URL+testimonial?.image} />
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                            <h6 style={{color:"#000"}}>{testimonial?.name}</h6>
                                            {/* <p>Writer</p> */}
                                        </div>
                                    </div>
                                    <div>{
                                        testimonials.length > 1 &&(
                                            <>
                                            {backwardactive ? (
                                            <img src="/assets/images/r1.png" onClick={backtestimonial} />
                                        ) :(
                                    <img src="/assets/images/l1.png" onClick={backtestimonial} />
                                        )}
                                        {forwardactive ? (
                                            <img src="/assets/images/l2.png" onClick={forwardtestimonial} />
                                        ) :(
                                        <img src="/assets/images/r2.png" onClick={forwardtestimonial} />
                                        )}
                                            </>
                                        )
                                        
                                        }
                                        
                                        
                                        
                                        
                                    </div>
                                </div>

                </div>
            </div>
        </div>
    )
}