import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Footer from "./Components/Footer";
import axios from "axios";
import { BASE_URL, DOMAIN_NAME } from "../../config";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ContactUs from "./Components/ContactUs";
import SellerPage from "./SellerPages";
import ProductsAndServices from "./Components/ProductsAndServices";
import FeedBacks from "./Components/FeedBacks";
import SendEnquiry from "./Components/SendEnquiry";
import AboutUs from "./Components/AboutUs";
import Blogs from "./Components/Blogs";
import Faq from "./Components/Faq";
import GeneralPage from "./Components/GeneralPage";
import BlogDetails from "./Components/BlogDetails";
import ProductsDetails from "./Components/ProductDetails";
import FeaturesDeatils from "./Components/FeatureDetails";
const TruncHtml = require('trunc-html');

const SellerDetailsPage = () => {
    const [homeMenus, setHomeMenus] = useState(true);
    const [productMenu, setProductMenu] = useState(false);
    const [aboutMenu, setAboutMenu] = useState(false);
    const [blogMenu, setBlogMenu] = useState(false);
    const [blogDetails, setBlogDetails] = useState(false);
    const [blogDetailsData, setBlogDetailsData] = useState(false);
    const [productDetails, setProductDeatils] = useState(false);
    const [productDetailsData, setProductDeatilsData] = useState(false);
    const [featureDetails, setFeatureDeatils] = useState(false);
    const [featureDetailsData, setFeatureDeatilsdata] = useState(false);
    const [faqMenu, setFaqMenu] = useState(false);
    const [contactMenu, setContactMenu] = useState(false);
    const [headerMenu, setHeadermenu] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [image, setImage] = useState("");
    const [feedback, setFeedback] = useState("");
    const [homepagewidget, sethomepagewidget] = useState("");
    const { slug } = useParams();
    const [menu, setmenu] = useState([]);
    const [product, setProducts] = useState([]);
    const [features, setFeautures] = useState([]);
    const [aboutUs, setAboutUs] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [faq, setFaq] = useState([]);
    const [brands, setBrands] = useState([]);
    const [footer, setFooter] = useState([]);
    const [slider, setSlider] = useState([]);
    const [compnayIntro, setCompanyIntro] = useState({})
    const [contactus, setcontactus] = useState("")
    const [pagedata, setpagedata] = useState("")
    const [pageact, setpageact] = useState("")
    const [generalpage, setgeneralpage] = useState(false)

    useEffect(() => {
        fetcheader();
        fetchProducts();
        fetchFeatures();
        fetchAboutUs();
        fetchBlogs();
        fetchFaqs();
        fetchBrands();
        fetchSliders();
        fetchCompanyIntro();
    }, []);


    const handleImage = e => {
        setImage(e.target.files[0]);
    }

    const handleProduct = () => {
        window.scrollTo(0, 700);
        setProductMenu(true)
        setFaqMenu(null);
        setBlogMenu(null)
        setProductDeatils(null)
        setBlogDetails(null)
        setContactMenu(null);
        setAboutMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setFeatureDeatils(null)
        setpageact("")
    }

    const handleMenu = () => {
        window.scrollTo(0, 700);
        setHomeMenus(true)
        setProductMenu(null)
        setFaqMenu(null);
        setProductDeatils(null)
        setBlogDetails(null)
        setBlogMenu(null)
        setContactMenu(null);
        setAboutMenu(null)
        setgeneralpage(null)
        setFeatureDeatils(null)
        setpageact("")
    }

    const handleAbout = () => {
        window.scrollTo(0, 700);
        setAboutMenu(true)
        setFaqMenu(null);
        setBlogMenu(null)
        setProductDeatils(null)
        setBlogDetails(null)
        setContactMenu(null);
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setFeatureDeatils(null)
        setpageact("")
    }

    const handleContact = () => {
        window.scrollTo(0, 700);
        setContactMenu(true);
        setFaqMenu(null);
        setBlogMenu(null)
        setProductDeatils(null)
        setBlogDetails(null)
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setFeatureDeatils(null)
        setpageact("")
    }

    const handleBlog = (data) => {
        console.log(data, "blog data");
        window.scrollTo(0, 700);
        setBlogMenu(true)
        setFaqMenu(null);
        setProductDeatils(null)
        setBlogDetails(null)
        setContactMenu(null);
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setFeatureDeatils(null)
        setpageact("")
    }

    const handleBlogDetails = (data) => {
        console.log(data, "blog details data");
        setBlogDetailsData(data);
        window.scrollTo(0, 700);
        setBlogDetails(true)
        setProductDeatils(null)
        setBlogMenu(null)
        setFaqMenu(null);
        setContactMenu(null);
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setFeatureDeatils(null)
        setgeneralpage(null)
        setpageact("")
    }
    console.log(blogDetailsData, "blogsdetailsDta");

    const handleProductDetails = (data) => {
        setProductDeatilsData(data);
       
        window.scrollTo(0, 600);
        setProductDeatils(true)
        setBlogDetails(null)
        setFeatureDeatils(null)
        setBlogMenu(null)
        setFaqMenu(null);
        setContactMenu(null);
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setpageact("")
    }

    console.log(productDetailsData, "products onclick data");

    const handleFeatureDetails = (data) => {
        setFeatureDeatilsdata(data)
        console.log(data, "featureDetails");
        window.scrollTo(0, 400);
        setFeatureDeatils(true)
        setProductDeatils(null)
        setBlogDetails(null)
        setBlogMenu(null)
        setFaqMenu(null);
        setContactMenu(null);
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setpageact("")
    }

    const handleFaq = () => {
        window.scrollTo(0, 700);
        setFaqMenu(true);
        setFeatureDeatils(null)
        setBlogMenu(null)
        setProductDeatils(null)
        setBlogDetails(null)
        setContactMenu(null);
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(null)
        setpageact("")
    }


    const submitEnquiry = () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile_number', mobileNumber);
        formData.append('image', image);
        formData.append('country_code', "+91");
        formData.append('feedback', feedback);
        
        axios.post(BASE_URL + `/seller/addquery`, formData,)
            .then((res) => {
                console.log(res);
                if (res.status == '200') {
                    console.log("set enqiury called");
                    alert("Query Added Successfully")
                }
            })
            .catch((error) => {
                console.log(error, "status");
            });
    }

    const fetchProducts = async () => {
        await axios.get(BASE_URL + '/seller/product', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setProducts(data.data)
        }).catch(({ response }) => {

        })
    }

    const fetchSliders = async () => {
        await axios.get(BASE_URL + '/seller/getslider-home', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setSlider(data)
        }).catch(({ response }) => {

        })
    }

    const fetcheader = async () => {
        await axios.get(BASE_URL + '/seller/headermenu', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setmenu(data.data)
        }).catch(({ response }) => {

        })
        await axios.get(BASE_URL + '/seller/getheader', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setcontactus(data)
        }).catch(({ response }) => {

        })
    }

    const fetchFeatures = async () => {
        await axios.get(BASE_URL + '/seller/sellerfeature', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setFeautures(data)
        }).catch(({ response }) => {

        })
    }

    const fetchAboutUs = async () => {
        await axios.get(BASE_URL + '/seller/about-us', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setAboutUs(data)
        }).catch(({ response }) => {

        })
    }

    const fetchBlogs = async () => {
        await axios.get(BASE_URL + '/seller/blogs', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setBlogs(data)
        }).catch(({ response }) => {

        })
    }

    const fetchBrands = async () => {
        await axios.get(BASE_URL + '/seller/brandsponsor', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setBrands(data)
        }).catch(({ response }) => {

        })
    }
    const fetchFaqs = async () => {
        await axios.get(BASE_URL + '/seller/faqs', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setFaq(data)
        }).catch(({ response }) => {

        })
    }


    const fetchCompanyIntro = async () => {
        await axios.get(BASE_URL + '/seller/companyintro', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setCompanyIntro(data?.data[0])
        }).catch(({ response }) => {

        })
        await axios.get(BASE_URL + '/seller/homepagewidget', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            sethomepagewidget(data?.data)
        }).catch(({ response }) => {

        })
    }

    const handleGeneralPage = async (index, slug1) => {
        setHeadermenu(slug1);
        window.scrollTo(0, 700);
        setBlogMenu(null)
        setFaqMenu(null);
        setContactMenu(null);
        setAboutMenu(null)
        setProductMenu(null)
        setHomeMenus(null)
        setgeneralpage(true)
        setpageact(index)
        await axios.get(BASE_URL + '/seller/getpage/' + slug1, {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setpagedata(data?.data[0])
        }).catch(({ response }) => {

        })
    }

    const faqQuestions1 = faq?.data?.slice(0, 3);
    const faqQuestions2 = faq?.data?.slice(3, 6);
    console.log(slider, "slider data vendor");
    return (
        <div>
            <div className="seller-mr-1">
                <Carousel
                    showThumbs={false}
                >
                    {slider?.data?.map((item, index) => (
                        <div className="seller-deatils-banner" style={{ backgroundImage: `url(${item.image})`, width: '100%', height: '450px', backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                           <h1>{item.heading}</h1>
                           <p>{item.subheading}</p>
                        </div>
                    ))}
                </Carousel>
                <div className="seller-details-header">
                    <ul>
                        <li onClick={handleMenu} className={homeMenus ? 'active-class' : ""}>Home</li>
                        <li onClick={handleAbout} className={aboutMenu ? 'active-class' : ""}>About Us</li>
                        <li onClick={handleProduct} className={productMenu ? 'active-class' : ""}>Products & Services</li>
                        <li onClick={handleBlog} className={blogMenu ? 'active-class' : ""}>Blogs</li>
                        <li onClick={handleFaq} className={faqMenu ? 'active-class' : ""}>Faq</li>
                        <li onClick={handleContact} className={contactMenu ? 'active-class' : ""}>Contact Us</li>
                        {
                            menu && menu.map((item, index) => (
                                <li key={index} onClick={(e) => handleGeneralPage(index, item.slug)} className={pageact === index ? 'active-class' : ""}>{item.menu_name}</li>
                            ))
                        }
                        <a href={"https://api.whatsapp.com/send?phone=" + contactus?.data?.whatsapp_number} target="_blank"><li><img src="/assets/images/whatsapp-icon.png" />Chat With Us</li></a>
                        <a href={"tel:" + contactus?.data?.business_mobilenumber[0]} target="_blank"><li><img src="/assets/images/white-call.png" />Call Us Now</li></a>
                        <a href={"mailto:" + contactus?.data?.business_email.join(",")} target="_blank"><li><img src="/assets/images/white-mail.png" />Send Us Email</li></a>
                    </ul>
                </div>
            </div>
            {homeMenus ? <SellerPage handleBlogDetails={handleBlogDetails} handleProductDetails={handleProductDetails} handleFeatureDetails={handleFeatureDetails} /> : ""}
            {productMenu ? <ProductsAndServices product={product} handleProductDetails={handleProductDetails} homepagewidget={homepagewidget} /> : ""}
            {aboutMenu ? <AboutUs faq={faq} aboutUs={aboutUs} homepagewidget={homepagewidget} /> : ""}
            {contactMenu ? <ContactUs contactus={contactus} homepagewidget={homepagewidget} /> : ""}
            {blogMenu ? <Blogs blogs={blogs} handleBlogDetails={handleBlogDetails} homepagewidget={homepagewidget} /> : ""}
            {blogDetails ? <BlogDetails blogs={blogs} homepagewidget={homepagewidget} blogDetailsData={blogDetailsData}/> : ""}
            {productDetails ? <ProductsDetails  homepagewidget={homepagewidget} productDetailsData={productDetailsData}/> : ""}
            {featureDetails ? <FeaturesDeatils  homepagewidget={homepagewidget} featureDetailsData={featureDetailsData} blogs={blogs} /> : ""}
            {generalpage ? <GeneralPage data={pagedata} /> : ""}
            {faqMenu ? <Faq
                faqQuestions1={faqQuestions1}
                faqQuestions2={faqQuestions2}
                homepagewidget={homepagewidget}
            /> : ""}
            {/* {headerMenu === 'product-services' ? <ProductsAndServices product={product} /> : headerMenu === 'send-inquiry' ? <SendEnquiry /> : headerMenu === 'feedback' ? <FeedBacks /> : headerMenu === 'about-us' ? <AboutUs faq={faq} /> : headerMenu === 'contact-us' ? <ContactUs /> : headerMenu === 'listing-policy' ? 'hello Listing policy' : headerMenu === 'listing-policys' ? "thank you" : <SellerPage />} */}

            <div>
                <Footer
                    handleProduct={handleProduct}
                    handleBlog={handleBlog}
                    handleContact={handleContact}
                    handleFaq={handleFaq}
                    handleMenu={handleMenu}
                    handleAbout={handleAbout}
                    handleGeneralPage={handleGeneralPage}
                />
            </div>
        </div>
    )
}

export default SellerDetailsPage;