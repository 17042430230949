import React, { useState } from 'react'
import "../../style/SellerDashboard.css"
import { Bar, Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { Sidebar } from '../seller-dashboard/Sidebar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'
import CustomCalendar from './calendar/Calendar';
import { Chat } from './Chat';
export const UsermainContent = () => {
    const [date, setDate] = useState(new Date());
    const[viewChat, setViewChat] = useState(false)

    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
    };

    // pi chart data 
    const data = {
        labels: ['Purple', 'Orange', 'White'],
        datasets: [
            {
                label: 'My Pie Chart',
                data: [32, 60, 8],
                backgroundColor: [
                    '#6654E1',
                    '#FF8E5B',
                    'rgb(128, 0, 128)',
                ],
                borderColor: [
                    '#6654E1',
                    '#FF8E5B',
                    'rgb(0, 0, 0)',
                ],
                borderWidth: 1
            }
        ]
    };

    //   pi chart option 
    const options = {
        title: {
            display: true,
            text: '32%',
            fontSize: 16
        },
        legend: {
            display: true,
            position: 'right'
        },
        cutoutPercentage: 50
    };

    return (
        <>
            <div className="sellerdashboard-content user-das-content">
                {/* <div className="container"> */}
                <div className=" d-flex gap-4  flex-row px-5 py-4">
                    {/* left sidebar  */}
                    <div className="col-lg-3">
                        {/* <Sidebar /> */}
                    </div>

                    {/* right main content  */}
                    <div className="col-lg-9 px-2 right ">
                        {viewChat === false ? 
                        <div className="hide-on-chat ">


                            <div className="top-alert w-100 mb-3 pe-3">
                                <button className='w-100 fs-6 ' style={{ background: "#FDD" }}>You u have not verified your Email Id, kindly click on Verify Now to verify and get full access to your Dashboard. <Link to="" className='mx-4'>Verify Now <img src="/assets/images/share-icon.png" alt="" width="15px" /></Link> <i className='fa fa-close fs-6'></i></button>
                            </div>

                            {/* Right Section1  */}
                            <div className="hpoints-graph  pb-4 d-flex justify-content-between align-items-center">
                                <div className="col-lg-8 d-flex justify-content-between">
                                    <div className="card px-3 py-3  border-0 rounded-4" style={{ background: "#FFB5B5" }} >
                                        <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                        <h5 className="title">Wishlist</h5>
                                        <p>Last week </p>
                                        <span className="digit">02</span>
                                        <span className="percent">₹1200</span>
                                    </div>
                                    <div className="card px-3 py-3  border-0 rounded-4" style={{ background: "#CEC7FF" }} >
                                        <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                        <h5 className="title">Inquiries</h5>
                                        <p>Last week</p>
                                        <span className="digit">1.2k</span>
                                        <span className="percent">+15.4%</span>
                                    </div>
                                    <div className="card px-3 py-3  border-0 rounded-4" style={{ background: "#FFE4C5" }} >
                                        <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                        <h5 className="title">Help & Support</h5>
                                        <p>Live Chat</p>
                                        <p>Raise Ticket</p>
                                        <p>FAQ</p>
                                    </div>


                                    <div className="card px-3 py-3 border-0 rounded-4" style={{ background: "#CBD7FF" }} >
                                        <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                        <h5 className="title">Settings</h5>
                                        <Link to="">Edit Profile</Link>
                                        <Link to="">Change Password</Link>
                                        <Link to="">Deactivate account</Link>
                                    </div>
                                </div>

                                {/* Graph ^^^^^^^^ */}
                                <div className=" mx-3 rounded-4 px-4 py-3 bg-white graph d-flex gap-4">
                                    <div className="graph-content">
                                        <div className='position-relative user-photo'>
                                            <img src="/assets/images/profile-img.png" alt="" width={"86px"} />
                                            <span className='position-absolute d-flex'> <input type="file" style={{ width: "15px", zIndex: 15, opacity: 0, position: "relative" }} /> <i className='fa fa-camera fs-5 position-absolute start-0'></i></span>

                                        </div>

                                        <h5 className='name fs-5 mt-2'>John Dexchlo</h5>

                                    </div>

                                    <div className="pie-chart">
                                        <p className='fs-5 '>Profile status</p>
                                        <img src="/assets/images/profile-pi-chart.png" alt="" />
                                        {/* <Pie data={data} options={options} /> */}

                                    </div>
                                </div>
                            </div>

                            {/* Right Section2  */}

                            <div className="right-sec2 py-4 d-flex justify-content-between ">
                                <div className="col-lg-8 bg-white px-3 py-3 rounded-4">
                                    <div className="top-btns d-flex gap-2 mb-3" >
                                        <button className='active-db-btn'>Recent Inquiry</button>
                                        <button>Follow-Ups</button>
                                        <button>Confirmed</button>
                                        <button>Not Interested</button>
                                    </div>
                                    <div className="table-div" style={{ overflowX: "auto" }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date & Time</th>
                                                    <th scope="col">Customer Name</th>
                                                    <th scope="col">Email ID</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Product & Service</th>
                                                    <th scope="col">other1</th>
                                                    <th scope="col">other2</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>2008/11/28  10:3</td>
                                                    <td>Cedric Kelly</td>
                                                    <td>cedric@gmail.com</td>
                                                    <td>+91-898989899</td>
                                                    <td>Delhi</td>
                                                    <td>Perfume</td>
                                                    <td>other1</td>
                                                    <td>other 2 se</td>
                                                </tr>
                                                <tr>
                                                    <td>2008/11/28  10:3</td>
                                                    <td>Cedric Kelly</td>
                                                    <td>cedric@gmail.com</td>
                                                    <td>+91-898989899</td>
                                                    <td>Delhi</td>
                                                    <td>Perfume</td>
                                                    <td>other1</td>
                                                    <td>other 2 se</td>
                                                </tr>
                                                <tr>
                                                    <td>2008/11/28  10:3</td>
                                                    <td>Cedric Kelly</td>
                                                    <td>cedric@gmail.com</td>
                                                    <td>+91-898989899</td>
                                                    <td>Delhi</td>
                                                    <td>Perfume</td>
                                                    <td>other1</td>
                                                    <td>other 2 se</td>
                                                </tr>
                                                <tr>
                                                    <td>2008/11/28  10:3</td>
                                                    <td>Cedric Kelly</td>
                                                    <td>cedric@gmail.com</td>
                                                    <td>+91-898989899</td>
                                                    <td>Delhi</td>
                                                    <td>Perfume</td>
                                                    <td>other1</td>
                                                    <td>other 2 se</td>
                                                </tr>
                                                <tr>
                                                    <td>2008/11/28  10:3</td>
                                                    <td>Cedric Kelly</td>
                                                    <td>cedric@gmail.com</td>
                                                    <td>+91-898989899</td>
                                                    <td>Delhi</td>
                                                    <td>Perfume</td>
                                                    <td>other1</td>
                                                    <td>other 2 se</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div className="chat-box bg-white px-3 py-3 rounded-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h3>Chats</h3>
                                        <Link to="" onClick={() => setViewChat(true)}>View all chats</Link>
                                    </div>
                                    <div className="searchbar py-2 position-relative">
                                        <input type="text" placeholder='Search...' />
                                        <span><i className='fa fa-search'></i></span>
                                    </div>
                                    <div className="users-chat py-2">
                                        <div className="d-flex py-2 justify-content-between align-items-center">
                                            <div className="d-flex gap-3 align-items-center">
                                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                            </div>
                                            <div className="available pe-3">
                                                <p className='mb-0 text-secondary'>11:00 am</p>
                                                <span className=''></span>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2 justify-content-between align-items-center">
                                            <div className="d-flex gap-3 align-items-center">
                                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                            </div>
                                            <div className="available pe-3">
                                                <p className='mb-0 text-secondary'>11:00 am</p>
                                                <span className=''></span>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2 justify-content-between align-items-center">
                                            <div className="d-flex gap-3 align-items-center">
                                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                            </div>
                                            <div className="available pe-3">
                                                <p className='mb-0 text-secondary'>11:00 am</p>
                                                <span className=''></span>
                                            </div>
                                        </div>
                                        <div className="d-flex py-2 justify-content-between align-items-center">
                                            <div className="d-flex gap-3 align-items-center">
                                                <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                            </div>
                                            <div className="available pe-3">
                                                <p className='mb-0 text-secondary'>11:00 am</p>
                                                <span className=''></span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            {/* Right Section3 ^^^^^^^^^^^^^^^^^^^*/}
                            <div className="right-sec3 bg-white py-4 px-3 rounded-4 ">

                                <div className=" d-flex justify-content-between">
                                    {/* WishList ------------ */}
                                    <div className="col-lg-5 active-products wishlist pe-2">
                                        <div className="flex justify-content-between align-items-center">

                                            <h3>Wishlist</h3>
                                            <Link to="" className='fs-6 text-secondary link-underline-secondary'>View All</Link>
                                        </div>

                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/jacket.png" alt="" />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between">
                                                    <div>
                                                        <span className="pd-type">Jackets</span>
                                                        <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>

                                                    <div className='text-danger position-absolute end-0 fs-6 fw-medium'>X</div>

                                                </div>
                                                <div className="d-flex py-2 justify-content-between align-items-center">
                                                    <p className="price mb-0"> <span>₹</span> 254.99</p>
                                                    <Link to="" className='fs-5 text-secondary'> Contact Seller</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/jacket.png" alt="" />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between">
                                                    <div>
                                                        <span className="pd-type">Jackets</span>
                                                        <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>

                                                    <div className='text-danger position-absolute end-0 fs-6 fw-medium'>X</div>

                                                </div>
                                                <div className="d-flex py-2 justify-content-between align-items-center">
                                                    <p className="price mb-0"><span>₹</span> 254.99</p>
                                                    <Link to="" className='fs-5 text-secondary'> Contact Seller</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/jacket.png" alt="" />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between">
                                                    <div>
                                                        <span className="pd-type">Jackets</span>
                                                        <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>

                                                    <div className='text-danger position-absolute end-0 fs-6 fw-medium'>X</div>

                                                </div>
                                                <div className="d-flex py-2 justify-content-between align-items-center">
                                                    <p className="price mb-0"><span>₹</span> 254.99</p>
                                                    <Link to="" className='fs-5 text-secondary'> Contact Seller</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ------Calendar---- -------------------*/}
                                    <div className="col-lg-6 analystics">
                                        {/* <h3>Calendar</h3> */}

                                        <CustomCalendar />

                                    </div>

                                </div>

                                {/* Recent Product &services search */}
                                <div className=" mt-lg-5 mt-3 mb-lg-4 mb-3  d-flex justify-content-between">

                                    {/* left  */}
                                    <div className="col-lg-5">
                                        <h3>Recent Product Search</h3>
                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/jacket.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between">
                                                    <div>
                                                        <span className="pd-type">Jackets</span>
                                                        <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>
                                                    <div className='text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/jacket.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between ">
                                                    <div>
                                                        <span className="pd-type">Jackets</span>
                                                        <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>

                                                    <div className='text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    {/* right ----- */}
                                    <div className="col-lg-6">
                                        <h3>Recent Services Search</h3>
                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/rec-service.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between">
                                                    <div>
                                                        <span className="pd-type">UI/UX</span>
                                                        <h5 className="title">Graphics Designer</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>
                                                    <div className='text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>
                                                </div>

                                            </div>
                                        </div>


                                        <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                            <img src="/assets/images/jacket.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                            <div className="product-content">
                                                <div className="d-flex gap-3 justify-contnet-between ">
                                                    <div>
                                                        <span className="pd-type">UI/UX</span>
                                                        <h5 className="title">Graphics Designer</h5>
                                                        <div className="stars-box ">
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                            <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                        </div>
                                                    </div>

                                                    <div className='text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="alert-box gap-3 d-flex mt-4 ">
                                    <button>Your Last Successful Login was 12-11-2023 3:00 PM | Location: Mumbai, India <span><i className='fa fa-close ps-2 fs-6'></i></span></button>
                                    <button style={{ background: "#FFDDDD" }}> Your Last Successful Login was 12-11-2023 3:00 PM | Location: Mumbai, India <i className='fa fa-close ps-2 fs-6'></i></button>
                                </div>
                            </div>
                        </div>
                            :
                            <div className="chat-div">
                                <Chat/>
                            </div>

}
                    </div>
                </div>

                {/* </div> */}
            </div>
        </>
    )
}
