import React, { useState, useEffect } from "react";
import "../style/Blogs1.css";
import { Link } from "react-router-dom";
import Axios from "axios";
import Loader from "../components/loader/Loader.component";
import parse from "html-react-parser";
import SeoBlogpage from "../seo page/SeoBlogpage";
export const Blogs1 = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogslatests, setblogslatests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const blogRequest = Axios.CancelToken.source();
    async function fetchBlogs() {
      try {
        setIsLoading(true);
        const response = await Axios.get(`/blogs`, {
          cancelToken: blogRequest.token,
        });
        const responseData = response.data.data;
        setBlogs(responseData.blogs);
        setblogslatests(responseData.blogslatest);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchBlogs();
    //   return () => {
    //     blogRequest.cancel();
    //   };

   window.scrollTo(0,0)

  }, []);
  const searchblog=(d)=>{
    Axios
          .post("/searchblog",{"search":d})
          .then(({ data }) => {
            setblogslatests(data.data);
          })
          .catch(({ response }) => { });
    
  }
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
    <SeoBlogpage/>
      <div className="blogs-parent" style={{ backgroundColor: "#E7E7E7" }}>
        <div className="blogs-body px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3 d-flex justify-content-between">
          {/* left  */}
          <div className=" left">
            {/* Cart   */}
            {blogs &&
              blogs.map((blog, i) => (
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3"
                  key={i}
                >
                  <img src={blog.image} alt="" />
                  <div className="card-rc ml-4">
                    <h3>
                      {blog.title && blog.title.length > 30
                        ? blog.title.substring(0, 40) + "..."
                        : blog.title}
                    </h3>
                    <p className="m-0 mb-3">
                      {" "}
                      {parse(blog.content) && parse(blog.content).length > 100
                        ? parse(blog.content).substring(0, 180) + "..."
                        : <>{parse(blog.content.substring(0, 180))}
                     <span className="mb-3"> <Link to={`/blog/${blog.slug}`}>read more</Link></span></>}
                    </p>
                    <div className="d-flex gap-lg-5 gap-md-4 gap-3">
                      <div className="aurther-name">
                        {blog.writer_name=== ""?<h5 className="mb-0">Admin</h5>:<h5 className="mb-0">{blog.writer_name}</h5>}
                        
                        <p className="mb-0">Auther</p>
                      </div> &nbsp; &nbsp; 
                      <p className="date">{blog.created_at}</p>&nbsp; &nbsp; 
                      <p className="view">
                        <span>
                          <i className="fa fa-eye"></i>
                        </span>{" "}
                        {blog.comments_count}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Right ********************* */}
          <div className=" bg-white right px-lg-4 px-3 py-lg-4 py-3">
            <div className="search">
              <h4>Search</h4>
              <form action="">
                <input type="text" placeholder="Search" onChange={(e)=>searchblog(e.target.value)} />
                <span>
                  <i className="fa fa-search"></i>
                </span>
              </form>
            </div>

            <h4>Recent Post</h4>
            <div className="recent-posts">
              {blogslatests &&
                blogslatests.map((blog, i) => (
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" key={i}>
                    <img src={blog.image} alt="" />
                    <div className="ml-3">
                      <p className="bold-para font-weight-bold mb-0">{blog.title && blog.title.length > 30
                        ? blog.title.substring(0, 25) + "..."
                        : blog.title}</p>
                      <p className="mb-0">{blog.created_at}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
