import React, { useEffect, useState, useRef } from "react";
import {
  NavLink,
  useLocation,
  useParams,
  Link,
  useNavigate,
} from "react-router-dom";
import { Accordion, Button, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Page from "../components/Page";
import axios from "axios";
import { BASE_URL } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../components/loader/Loader.component";
import Select from "react-select";
function Checkout() {
  const states = useLocation();
  const [data, setData] = useState();
  const [offlineMode, setOfflineMode] = useState(true);
  const [bankData, setBankData] = useState({});
  const [transactionId, setTransactionId] = useState();
  const scollToRef = useRef();
  const [screenshot, setScreenshot] = useState("");
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [regNo, setRegNo] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [remaks, setRemarks] = useState("");
  const [countryId, setCountryId] = useState("");
  const [error, seterror] = useState("");
  const [seller, setseller] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [businessNature, setBusinessNature] = useState("");
  const [taxinvoice, settaxinvoice] = useState("no");
  const [gst_number, setgst_number] = useState("");
  const [natureofbusinessdropdown, setnatureofbusinessdropdown] = useState([]);
  const [businesstypedropdown, setbusinesstypedropdown] = useState([]);
  const [category, setcategory] = useState([]);
  const [selected, setselected] = useState([]);
  const token = localStorage.getItem("avonToken");
  const [categorys, setCategorys] = useState([]);
  console.log(token);

  const handleScreenshot = (e) => {
    setScreenshot(e.target.files[0]);
  };

  const navigate = useNavigate();

  useEffect(() => {
    FetchData();
    FetchBankDetails();
    getCountry();
    getsellerdetail();
  }, []);
  const natureofbusines = (data) => {
    var d = [];
    if (data == "Services") {
      d = ["Service Providers"];
    } else if (data == "Product") {
      d = [
        "Manufacturer",
        "Exporters & Importers",
        "Suppliers",
        "Distributors",
      ];
    } else if (data == "Directory") {
      d = [
        "Manufacturer",
        "Exporters & Importers",
        "Suppliers",
        "Distributors",
        "Retailers",
        "Service Providers",
        "Educational Institutes",
      ];
    }
    setnatureofbusinessdropdown(d);
  };
  const getbusinesstypedropdown = (packagetype, data) => {
    var x = [];
    console.log(packagetype,data)
    if (packagetype == "Directory" && data == "Manufacturer") {
      getcategory(1)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Directory" && data == "Exporters & Importers") {
      getcategory(1)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Directory" && data == "Suppliers") {
      getcategory(1)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
      ]
    }
    if (packagetype == "Directory" && data == "Distributors") {
      getcategory(1)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
      ]
    }
    if (packagetype == "Directory" && data == "Retailers") {
      getcategory(1)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
      ]
    }
    if (packagetype == "Directory" && data == "Service Providers") {
      getcategory(2)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
       "NGO's / Trust",  
       "Microfinance Company",  
      ]
    }
    if (packagetype == "Directory" && data == "Educational Institutes") {
      getcategory(5)
      setCategorys("");
      setselected("");
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
       "NGO's / Trust",  
      ]
    }
    if (packagetype == "Product" && data == "Manufacturer") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Product" && data == "Exporters & Importers") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Product" && data == "Suppliers") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
      ]
    }
    if (packagetype == "Product" && data == "Distributors") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
      ]
    }
    if (packagetype === "Services" && data === "Service Providers") {
      x = [
       "Individual / Freelancer", 
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
      ]
    }
    

    setbusinesstypedropdown(x);
  };
  const getsellerdetail = () => {
    if (token) {
      axios
        .get(BASE_URL + "/get-seller-detail", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .then(({ data }) => {
          setseller(data.data);
        });
    }
  };
  const getcategory = (data) => {
    axios
      .post(`${BASE_URL}/getcategory`,{"category_type":data})
      .then(function (res) {
        setcategory(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCountry = () => {
    axios
      .get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getState = (name) => {
    axios
      .get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCity = (id) => {
    axios
      .get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const FetchData = async () => {
    if (states) {
      await axios
        .get(BASE_URL + "/package/" + states.state, {})
        .then(({ data }) => {
          setData(data.data);
          natureofbusines(data?.data?.package_type);
          if(data?.data?.package_type == "Product"){
            getcategory(1)
          }
          if(data?.data?.package_type == "Services"){
            getcategory(2)
          }
          if(data?.data?.package_type == "Directory"){
            getcategory(1)
          }
          
          
          setIsLoading(false);
        });
    }
  };
  const handleChangeCategory = (selectedOption) => {
    setselected(selectedOption);
    var d = [];
    selectedOption.map((item) => {
      d.push(item.value);
    });
    setCategorys(d);
  };
  const FetchBankDetails = async () => {
    await axios.get(BASE_URL + "/getbankdetail").then(({ data }) => {
      setBankData(data.data);
    });
  };

  const pack_name = data && data.package_name;

  const Payment = async () => {
    // const country_data_id = country

    // const countryData = country_data_id.split("_");
    // setCountryId(country_data_id)
    const formData = new FormData();
    formData.append("refrence_id", transactionId);
    formData.append("package_id", states.state);
    formData.append("remarks", remaks);
    formData.append("payment_image", screenshot);
    formData.append("business_name", businessName);
    formData.append("business_type", businessType);
    formData.append("reg_no", regNo);
    formData.append("full_address", address);
    formData.append("country", country);
    formData.append("state", state);
    formData.append("city", city);
    formData.append("pincode", pincode);
    formData.append("business_nature", businessNature);
    formData.append("gst_number", gst_number);
    formData.append("taxinvoice", taxinvoice);
    formData.append("paymentmethod", "offline");
    formData.append("business_category", categorys.length > 0 ? categorys :"");
    let url = "/makesellor";
    if (seller && seller.package_id) {
      url = "/renewsellorplan";
    }
    await axios
      .post(BASE_URL + url, formData, {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.success == true) {
          toast.success(data.msgText);
          navigate("/thankyou", {
            state: {
              pack_name: pack_name,
              trans_id: transactionId,
              amount: Math.ceil(Total_Amount),
              company_name: data.businessname,
            },
          });
        }
      })
      .catch(({ response }) => {
        if (response.data.code == "422") {
          seterror(response.data.errors);
        }
      });
  };

  const mrp = data && data.mrp;
  const finalPrice = data && data.offered_price;

  const discount = mrp - finalPrice;
  const [couponCode, setCouponCode] = useState();
  const [couponData, setCouponData] = useState({});

  const ApplyCoupon = async () => {
    const data = {
      coupon_code: couponCode,
      package_id: states.state,
    };
    axios
      .post(BASE_URL + "/applycoupononsellor", data, {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setCouponData(data.data);
        if (data.success == true) {
          toast.success("Coupon Applied Successfully");
          setCouponData(data.data);
          document.getElementById.remove("coupon");
        }
      })
      .catch(({ response }) => {
        console.log(response.data);
        if (response.data.status_code == 422) {
          toast.error(response.data.message.coupon_code[0]);
        }
      });
  };
  const Total_Amount =
    Number(data && data.offered_price) -
    Number(
      couponData.coupon_discount_amount ? couponData.coupon_discount_amount : 0
    ) +
    (Number(data && data.offered_price) * Number(data && data.vat)) / 100;
  if (isLoading) {
    return <Loader />;
  }

  return (
    <Page title="Checkout">
      <ToastContainer />
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Checkout</li>
            </ul>
          </div>
        </section>
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className={mrp != 0 ? "col-md-9" : "col-md-12"}>
                <div className="card-block">
                  <div className="card-head-blk">
                    <h4>Checkout</h4>
                  </div>
                  <div className="card-body-blk row">
                    <div className="col-md-3">
                      <h2>{data && data.package_name}</h2>
                      <p className="text-muted">
                        {data && data.short_description}
                      </p>
                    </div>
                    <div className="col-md-9 ">
                      {mrp == 0 ? (
                        <div
                          style={{ display: "flex", justifyContent: "right" }}
                          className="vendor-checkout-section"
                        >
                          <h4 className="mt-2" style={{ marginLeft: "2%" }}>
                            Free
                          </h4>
                        </div>
                      ) : (
                        <div
                          style={{ display: "flex", justifyContent: "right" }}
                          className="vendor-checkout-section"
                        >
                          <h4
                            className="text-muted mt-2"
                            style={{
                              textDecoration: "line-through",
                              marginLeft: "2%",
                            }}
                          >
                            <i className="fa fa-inr"></i> {mrp}
                          </h4>
                          <h4 className="mt-2" style={{ marginLeft: "2%" }}>
                            <i className="fa fa-inr"></i> {finalPrice}
                          </h4>
                          <h4
                            className="success mt-2"
                            style={{ color: "green", marginLeft: "2%" }}
                          >
                            {" "}
                            You saved <i className="fa fa-inr"></i> {discount}
                          </h4>
                        </div>
                      )}
                    </div>
                    {/* <div className="col-md-1">
                                        <h5 className="text-muted mt-2" style={{textDecoration:'line-through'}}>$ {finalPrice}</h5>
                                        </div>
                                        <div className="col-md-4">
                                        <h5 className="success mt-2" style={{color:'green'}}> You saved $ {discount}</h5>
                                        </div> */}

                    {/* <p>Discount : {discount}</p>
                                        <p>Final Price : {finalPrice}</p> */}
                  </div>
                  {/* <div className="package-border"> </div> */}
                  {seller && seller.package_id ? (
                    <></>
                  ) : (
                    <div className="card-body-blk">
                      <div className="checkoutblock">
                        <Form>
                          <div className="row">
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Business Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Business Name"
                                  onChange={(e) =>
                                    setBusinessName(e.target.value)
                                  }
                                />
                                {error && error.business_name && (
                                  <div className="text-danger">
                                    {error.business_name}
                                  </div>
                                )}
                              </Form.Group>
                            </div>

                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Select Nature of Business</Form.Label>
                              <Form.Select
                                style={{ borderRadius: "0%" }}
                                value={businessNature}
                                onChange={(e) => {
                                  setBusinessNature(e.target.value);
                                  getbusinesstypedropdown(data?.package_type,e.target.value);
                                }}
                              >
                                <option value="">
                                  Select Nature of Business
                                </option>
                                {natureofbusinessdropdown &&
                                  natureofbusinessdropdown?.map((item, i) => (
                                    <option value={item}>{item}</option>
                                  ))}
                              </Form.Select>
                              {error && error.business_nature ? (
                                <div className="text-danger">
                                  {" "}
                                  {error.business_nature}{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Business Type</Form.Label>
                                <Form.Select
                                  onChange={(e) =>
                                    setBusinessType(e.target.value)
                                  }
                                >
                                  <option value="">Select Business Type</option>
                                  {businesstypedropdown &&
                                  businesstypedropdown?.map((item, i) => (
                                    <option value={item}>{item}</option>
                                  ))}
                                </Form.Select>
                                {error && error.business_type && (
                                  <div className="text-danger">
                                    {error.business_type}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                            <Form.Label>Business Category</Form.Label>
                            <Select
                            value={selected}
                            options={category}
                            isMulti
                            name="business_category"
                            onChange={handleChangeCategory}
                          />
                          {error && error.business_category && (
                                  <div className="text-danger">
                                    {error.business_category}
                                  </div>
                                )}
                            </div>

                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Registration Number</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Registration No."
                                  onChange={(e) => setRegNo(e.target.value)}
                                />
                                {error && error.reg_no && (
                                  <div className="text-danger">
                                    {error.reg_no}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Full Address</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Full Address"
                                  onChange={(e) => setAddress(e.target.value)}
                                />
                                {error && error.full_address && (
                                  <div className="text-danger">
                                    {error.full_address}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Select Country</Form.Label>
                                <Form.Select
                                  onChange={(e) => {
                                    setCountry(e.target.value);
                                    getState(e.target.value);
                                  }}
                                >
                                  <option>Select Country</option>
                                  {fetchCountry &&
                                    fetchCountry.map((data) => (
                                      <option value={data.id}>
                                        {data.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {error && error.country && (
                                  <div className="text-danger">
                                    {error.country}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Select State</Form.Label>
                                <Form.Select
                                  onChange={(e) => {
                                    setState(e.target.value);
                                    getCity(e.target.value);
                                  }}
                                >
                                  <option>Select State</option>
                                  {fetchState &&
                                    fetchState.map((data) => (
                                      <option value={data.id}>
                                        {data.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {error && error.state && (
                                  <div className="text-danger">
                                    {error.state}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>City</Form.Label>
                                <Form.Select
                                  onChange={(e) => setCity(e.target.value)}
                                >
                                  <option>Select City</option>
                                  {fetchCity &&
                                    fetchCity.map((data) => (
                                      <option value={data.id}>
                                        {data.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {error && error.city && (
                                  <div className="text-danger">
                                    {error.city}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Group className="form-group">
                                <Form.Label>Pincode</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Pincode"
                                  onChange={(e) => setPincode(e.target.value)}
                                />
                                {error && error.pincode && (
                                  <div className="text-danger">
                                    {error.pincode}
                                  </div>
                                )}
                              </Form.Group>
                            </div>
                            <div className="col-md-4">
                              <Form.Label>Required Tax Invoice</Form.Label>
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                style={{ fontSize: 20 }}
                                checked={taxinvoice == "yes" ? true : false}
                                onChange={(e) =>
                                  settaxinvoice(
                                    taxinvoice == "yes" ? "no" : "yes"
                                  )
                                }
                              />
                              {error && error.taxinvoice && (
                                <div className="text-danger">
                                  {error.taxinvoice}
                                </div>
                              )}
                            </div>

                            {taxinvoice == "yes" && (
                              <div className="col-md-4">
                                <Form.Group className="form-group">
                                  <Form.Label>Gst Number</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Gst Number"
                                    onChange={(e) =>
                                      setgst_number(e.target.value)
                                    }
                                  />
                                  {error && error.gst_number && (
                                    <div className="text-danger">
                                      {error.gst_number}
                                    </div>
                                  )}
                                </Form.Group>
                              </div>
                            )}
                          </div>
                          {mrp == 0 && (
                            <Button
                              variant="primary"
                              className="btn btn-primary"
                              style={{ marginLeft: "50%", marginTop: "50px" }}
                              onClick={Payment}
                            >
                              Submit
                            </Button>
                          )}
                        </Form>
                      </div>
                    </div>
                  )}
                </div>
                {mrp != 0 && (
                  <div className="card-block">
                    <div className="card-head-blk">
                      <h4>Payment</h4>
                    </div>
                    <div className="card-body-blk">
                      <div className="checkoutblock">
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <input
                                type="radio"
                                id="offline"
                                checked
                                name={<h1>Offline</h1>}
                                value="offline"
                                onClick={() => {
                                  setOfflineMode(true);
                                  scollToRef.current.scrollIntoView();
                                }}
                              />
                              <label
                                for="offline"
                                style={{ fontSize: 20, marginLeft: 20 }}
                              >
                                Offline
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <input
                                type="radio"
                                id="Online"
                                name={<h1>Online</h1>}
                                value="Online"
                              />
                              <label
                                for="Online"
                                style={{ fontSize: 20, marginLeft: 20 }}
                              >
                                Online
                              </label>
                            </div>
                          </div>
                        </div>
                        <div ref={scollToRef}>
                          {offlineMode && (
                            <div className="col-md-12 mt-4 row">
                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div className="kyc-verification mt-4   ">
                                  <h3>Pay Offline</h3>
                                  <h6>Account Details</h6>
                                  <p>
                                    You can transfer the money using following
                                    methods <b>RTGS, NEFT</b>and <b>IMPS</b>
                                  </p>
                                  <div className="details-verify">
                                    <p className="details-name">Name</p>
                                    <p>{bankData && bankData.bank_name}</p>
                                    <hr />
                                  </div>
                                  <div className="details-verify">
                                    <p className="details-name">IFSC Code</p>
                                    <p>{bankData && bankData.ifsc_code}</p>
                                    <hr />
                                  </div>
                                  <div className="details-verify">
                                    <p className="details-name">
                                      Account Number
                                    </p>
                                    <p>{bankData && bankData.account_name}</p>
                                    <hr />
                                  </div>
                                  <div className="details-verify">
                                    <p className="details-name">
                                      Amount To be Paid
                                    </p>
                                    <p>
                                      &#8377;{" "}
                                      {Math.ceil(
                                        Number(data && data.offered_price) -
                                          Number(
                                            couponData.coupon_discount_amount
                                              ? couponData.coupon_discount_amount
                                              : 0
                                          ) +
                                          (Number(data && data.offered_price) *
                                            Number(data && data.vat)) /
                                            100
                                      )}
                                    </p>
                                    <hr />
                                    <p className="payment-info">
                                      Processing Time : 24 Hours after payment
                                    </p>
                                  </div>
                                  <div className="payment-mr">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>
                                        Please Enter transaction's Refrence Id
                                      </Form.Label>
                                      <Form.Control
                                        type="email"
                                        placeholder="Enter Refrence Id"
                                        onChange={(e) =>
                                          setTransactionId(e.target.value)
                                        }
                                      />
                                      {error && error.refrence_id && (
                                        <div className="text-danger">
                                          {error.refrence_id}
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div className="payment-mr">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Remarks</Form.Label>
                                      <Form.Control
                                        type="text"
                                        placeholder="Remark"
                                        onChange={(e) =>
                                          setRemarks(e.target.value)
                                        }
                                      />
                                      {error && error.remarks && (
                                        <div className="text-danger">
                                          {error.remarks}
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div className="payment-mr">
                                    <Form.Group
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Upload Screenshot</Form.Label>
                                      <Form.Control
                                        type="file"
                                        onChange={handleScreenshot}
                                      />
                                      {error && error.payment_image && (
                                        <div className="text-danger">
                                          {error.payment_image}
                                        </div>
                                      )}
                                    </Form.Group>
                                  </div>
                                  <div
                                    className="mt-2"
                                    style={{
                                      display: "flex",
                                      justifyContent: "right",
                                    }}
                                  >
                                    <Link to="/">
                                      <Button
                                        variant="dark"
                                        className="cancel-btn"
                                      >
                                        Cancel
                                      </Button>
                                    </Link>
                                    <Button
                                      variant="primary"
                                      style={{ marginLeft: "5%" }}
                                      onClick={Payment}
                                    >
                                      Submit
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {mrp != 0 && (
                <div className="col-md-3">
                  <div className="card-block">
                    <div className="card-head-blk">
                      <h4>Cart Review</h4>
                    </div>
                    <div className="card-body-blk">
                      <ul className="cartreview-block">
                        <li>
                          <p>Coupon Code</p>
                          <form id="coupon">
                            <div className="coupon-code">
                              <input
                                type="text"
                                placeholder="Enter Code"
                                onChange={(e) => setCouponCode(e.target.value)}
                              />
                              <div
                                className="addcode-btn"
                                onClick={ApplyCoupon}
                              >
                                apply
                              </div>
                            </div>
                          </form>
                        </li>
                        <li>
                          <b>MRP:</b>{" "}
                          <span>
                            <i className="fa fa-inr"></i> {data && data.mrp}
                          </span>
                        </li>
                        <li>
                          <b>Pre-Discount:</b>{" "}
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                            {Number(data && data.mrp) -
                              Number(data && data.offered_price)}
                          </span>
                        </li>
                        <li>
                          <b>Coupon Discount:</b>{" "}
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                            {couponData.coupon_discount_amount
                              ? couponData.coupon_discount_amount
                              : 0}
                          </span>
                        </li>
                        <li>
                          <b>Subtotal:</b>{" "}
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                            {Number(data && data.mrp) -
                              (Number(data && data.mrp) -
                                Number(data && data.offered_price)) -
                              Number(
                                couponData.coupon_discount_amount
                                  ? couponData.coupon_discount_amount
                                  : 0
                              )}
                          </span>
                        </li>
                        <li>
                          <b>Taxes (Incl.):</b>{" "}
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                            {Math.ceil(
                              (Number(data && data.offered_price) *
                                Number(data && data.vat)) /
                                100
                            )}
                          </span>
                        </li>
                        <li>
                          <b>Total:</b>{" "}
                          <span>
                            <i className="fa fa-inr"></i>{" "}
                            {Math.ceil(
                              Number(data && data.offered_price) -
                                Number(
                                  couponData.coupon_discount_amount
                                    ? couponData.coupon_discount_amount
                                    : 0
                                ) +
                                (Number(data && data.offered_price) *
                                  Number(data && data.vat)) /
                                  100
                            )}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
}
export default Checkout;
