import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { NavLink } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import Page from "../components/Page";
import DownloadIcon from '../components/dashboard/Download.svg';


const ProductDownload = () => {
  return (
    <Page title="Product Download">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Downloads</li>
            
            </ul>
            <div className="col-md-12">
            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
              </div> 
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9 col-sm-12 col-xs-12">
                <h4>Download Your Product</h4>

                <section>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                      <div className="card-block">
                        <div className="order-head-blk">
                          <h5>Download Your Product</h5>
                        </div>
                        <div className="card-body-blk">
                          <Table responsive="md">
                            <thead>
                              <tr>
                                <th>Product</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Options</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="downprod-img">
                                    <img
                                      src="assets/images/product-1.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="downprod-content">
                                    <h6>Professional Pixel Camera</h6>
                                    <p>Price: $210.00</p>
                                    <p>QTY: 1</p>
                                  </div>
                                </td>
                                <td style={{paddingTop: '28px'}}>Electronic</td>
                                <td style={{paddingTop: '28px'}}>Mobile</td>
                                <td><img style={{paddingTop: '17px'}} src={DownloadIcon} alt="" /></td>
                              </tr>
                              <tr>
                                <td>
                                <div className="downprod-img">
                                    <img
                                      src="assets/images/product-1.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="downprod-content">
                                    <h6>Professional Pixel Camera</h6>
                                    <p>Price: $210.00</p>
                                    <p>QTY: 1</p>
                                  </div>
                                </td>
                                <td style={{paddingTop: '28px'}}>Electronic</td>
                                <td style={{paddingTop: '28px'}}>Mobile</td>
                                <td><img style={{paddingTop: '17px'}} src={DownloadIcon} alt="" /></td>
                              </tr>
                              <tr>
                                <td>
                                <div className="downprod-img">
                                    <img
                                      src="assets/images/product-1.jpg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="downprod-content">
                                    <h6>Professional Pixel Camera</h6>
                                    <p>Price: $210.00</p>
                                    <p>QTY: 1</p>
                                  </div>
                                </td>
                                <td style={{paddingTop: '28px'}}>Electronic</td>
                                <td style={{paddingTop: '28px'}}>Mobile</td>
                                <td><img style={{paddingTop: '17px'}} src={DownloadIcon} alt="" /></td>
                              </tr>
                              {/* {productData.map((product) => (
                                <tr key={product.id}>
                                  <td>
                                    <div className="cartpro-img">
                                      <img
                                        src="assets/images/product-1.jpg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="cartpro-content">
                                      <h6>{product.name}</h6>
                                      <p>Price: {product.price}</p>
                                      <p>Qty: {product.qty}</p>
                                    </div>
                                  </td>
                                </tr>
                              ))} */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};
export default ProductDownload;
