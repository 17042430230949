import React, { useState, useContext, useEffect } from "react";
import "../../style/Startup.css";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL } from "../../config";
import DispatchContext from "../../context/DispatchContext";
import { useNavigate } from "react-router-dom";
import { YearPicker } from "react-dropdown-date";
import Select from "react-select";
import StateContext from "../../context/StateContext";
export const StartupForm = () => {
  const appState = useContext(StateContext);
  const [data, setdata] = useState({
    business_category: "",
    name: "",
    mobile_number: "",
    email: "",
    entity_type: "",
    founder_name: "",
    company_name: "",
    cin_no: "",
    pan_number: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pin_code: "",
    market_valuation: "",
    current_revenue: "",
    net_profit: "",
    website_url: "",
    facebook: "",
    twitter: "",
    instagram: "",
    youtube: "",
    linkedIn: "",
    video: "",
    gender: "",
    password_confirmation: "",
    password: "",
  });
  const [error, seterror] = useState([]);
  const appDispatch = useContext(DispatchContext);
  const [logo, setlogo] = useState("");
  const [logop, setlogop] = useState("");
  const [banner, setbanner] = useState("");
  const [bannerp, setbannerp] = useState("");
  const [pitchdeck, setpitchdeck] = useState("");
  const [pitchdeckp, setpitchdeckp] = useState("");
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [estblish_year, setEstblish_year] = useState("");
  const [category, setCategory] = useState([]);
  const [aboutus, setAboutUs] = useState("");
  const [aboutusPs, setAboutUsps] = useState("");
  const [feature_highlight, setfeaturehighlight] = useState("");
  const [inputFields, setInputFields] = useState([{ document_title: '', document: null,document_url:"" }]);
  const ref1 = React.createRef();
  const ref2 = React.createRef();
  const ref3 = React.createRef();
  const navigate = useNavigate();
  const handleInput = (e) => {
    const { name, value } = e.target;
    setdata({
      ...data,
      [name]: name === "mobile_number" ? Number(value) : value,
    });
  };
  const handleLogo = (e) => {
    setlogo(e.target.files[0]);
    setlogop(URL.createObjectURL(e.target.files[0]));
  };
  const handlebanner = (e) => {
    setbanner(e.target.files[0]);
    setbannerp(URL.createObjectURL(e.target.files[0]));
  };
  const handlepitchdeck = (e) => {
    setpitchdeck(e.target.files[0]);
    setpitchdeckp(URL.createObjectURL(e.target.files[0]));
  };
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;
  useEffect(() => {
    getCountry();
    FetchBusinessCategory();
  }, []);
  const handleInputChange = (index, event) => {
    const { name, value, files } = event.target;
    const newInputFields = [...inputFields];
    newInputFields[index][name] = name === 'document' ? files[0] : value;
    setInputFields(newInputFields);
  };

  const handleAddField = () => {
    setInputFields([...inputFields, { title: '', file: null,document_url:"" }]);
  };

  const handleRemoveField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields.splice(index, 1);
    setInputFields(newInputFields);
  };
  const getCountry = () => {
    axios
      .get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getState = (name) => {
    axios
      .get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCity = (id) => {
    axios
      .get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + "/startupcategory").then(({ data }) => {
      setCategory(data.data);
      // console.log(data.data)
    });
  };
  const handleChangeCategory = (selectedOption) => {
    var d = [];
    selectedOption.map((item) => {
      d.push(item.value);
    });
    console.log(d);
    setdata({
      ...data,
      ["business_category"]: d,
    });
  };
  const handleStartupData = () => {
    seterror("");

    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });
    const view1 = ref1.current.view;
    var aboutUsContent = EditorUtils.getHtml(view1.state);
    formData.append("about_product_service", aboutUsContent);
    const view2 = ref2.current.view;
    var aboutUsprod = EditorUtils.getHtml(view2.state);
    formData.append("about_startup", aboutUsprod);
    const view3 = ref3.current.view;
    var featurehighlight = EditorUtils.getHtml(view3.state);
    formData.append("feature_highlight", featurehighlight);
    formData.append("logo", logo);
    formData.append("banner", banner);
    formData.append("pitchdeck", pitchdeck);
    formData.append("established_year", estblish_year);
    {inputFields && inputFields.map((item,i)=>{
      formData.append("document_title[]", item.document_title);
      formData.append("document[]", item.document);
    })}
    axios
      .post(BASE_URL + appState?.state?.user?.token ? `/addstartupsauth` : `/addstartups`, formData, {
        headers: {
          Authorization: `Bearer ${appState?.state?.user?.token}`,
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then((res) => {
        if (res.status == "201") {
          if(!appState?.state?.user?.token){
            appDispatch({
              type: "login",
              data: {
                token: res.data.data.token,
                name: res.data.data.user.name,
                email: res.data.data.user.email,
                mobileNumber: res.data.data.user.mobile_number,
                profileImage: res.data.data.user.profile_image,
                step: res.data.data.user.step,
                lastLogin: res.data.data.user.last_login,
              },
            });
            navigate("/dashboard");
          }else{
            navigate("/dashboard");
          }
          
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
      });
  };

  return (
    <>
      <div className="startup-form my-lg-5 my-4">
        <div className="container">
          <h3 className="my-3 text-center fs-2">
            {" "}
            Fill in the form to List your Startup at{" "}
            <span style={{ color: "#D60D0D" }}>#1 Business</span> Directory{" "}
            <span>APNASHAHER.COM</span> and reach millions of customers
          </h3>

          <div className="">
            <form>
              <div className="form-group  row">
              {!appState.state?.user?.token &&(
                <div className="form-group px-0 row">
                
                <div className="col-md-4">
                <input
                  type="text"
                  onChange={handleInput}
                  name="name"
                  className="form-control"
                  placeholder="Enter Full Name"
                />
                {error && error.name && (
                  <div className="text-danger">{error.name}</div>
                )}
                </div>
                <div className="col-md-4">
                <input
                  type="number"
                  onChange={handleInput}
                  name="mobile_number"
                  className="form-control"
                  placeholder="Mobile Number"
                />
                {error && error.mobile_number && (
                  <div className="text-danger">{error.mobile_number}</div>
                )}
                </div>
                <div className="col-md-4 mb-2">
                <input
                  type="email"
                  onChange={handleInput}
                  name="email"
                  className="form-control"
                  placeholder="Email Id"
                />
                {error && error.email && (
                  <div className="text-danger">{error.email}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <Form.Group>
                  
                  <Form.Control
                    as="select"
                    className="form-select"
                    name="gender"
                    onChange={handleInput}
                  >
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Control>
                  {error && error.gender && (
                    <div className="text-danger">{error.gender}</div>
                  )}
                </Form.Group>
                </div>
                <Form.Group className="col-md-4 mt-2">
                  
                  <Form.Control
                    type="password"
                    placeholder="Create a Password"
                    className="form-control"
                    name="password"
                    onChange={handleInput}
                  />
                 
                  {error && error.password && (
                    <div className="text-danger">{error.password}</div>
                  )}
                </Form.Group>
                <Form.Group className="col-md-4 mt-2">
                  
                  <Form.Control
                    type="password"
                    placeholder="Confirm Password"
                    name="password_confirmation"
                    className="form-control"
                    onChange={handleInput}
                  />
                  {error && error.password && (
                    <div className="text-danger">
                      {error.password_confirmation}
                    </div>
                  )}
                </Form.Group>

                </div>
              )}
                
               

                <div className="col-md-4">
                  <Select
                    options={category}
                    isMulti
                    className="form-control"
                    name="business_cagegotry"
                    onChange={handleChangeCategory}
                  />
                  {error && error.business_category && (
                    <div className="text-danger">
                      {error.business_category}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                <Form.Select  name="entity_type" onChange={handleInput}>
                            <option value="">Select Entity Type</option>
                            <option value="Individual">Individual</option>
                            <option value="Sole Proprietorships">Sole Proprietorships</option>
                            <option value="Partnerships Firm">Partnerships Firm</option>
                            <option value="Private Limited Company">Private Limited Company</option>
                            <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>
                            <option value="Corporations">Corporations</option>
                            <option value="Others">Others</option>
                          </Form.Select>
                {error && error.entity_type && (
                  <div className="text-danger">{error.entity_type}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="founder_name"
                  className="form-control"
                  placeholder="Founder Name"
                />
                {error && error.founder_name && (
                  <div className="text-danger">{error.founder_name}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                 
                  name="company_name"
                  className="company-input form-control"
                  placeholder="Startup / Company Name"
                />
                {error && error.company_name && (
                  <div className="text-danger">{error.company_name}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="cin_no"
                  className="form-control"
                  placeholder="CIN/Registration Number"
                />
                {error && error.cin_no && (
                  <div className="text-danger">{error.cin_no}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="pan_number"
                  className="form-control"
                  placeholder="PAN Number"
                />
                {error && error.pan_number && (
                  <div className="text-danger">{error.pan_number}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  className="address-input form-control"
                 
                  name="address"
                  placeholder="Address"
                />
                {error && error.address && (
                  <div className="text-danger">{error.address}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                  <Form.Select
                    style={{ borderRadius: "0%" }}
                    name="country"
                    onChange={(e) => {
                      handleInput(e);
                      getState(e.target.value);
                    }}
                  >
                    <option value="">Select Country</option>
                    {fetchCountry &&
                      fetchCountry.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                  </Form.Select>
                  {error && error.country && (
                    <div className="text-danger">{error.country}</div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <Form.Select
                    style={{ borderRadius: "0%" }}
                    name="state"
                    onChange={(e) => {
                      handleInput(e);
                      getCity(e.target.value);
                    }}
                  >
                    <option value="">Select State</option>
                    {fetchState &&
                      fetchState.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                  </Form.Select>
                  {error && error.state && (
                    <div className="text-danger">{error.state}</div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <Form.Select
                    style={{ borderRadius: "0%" }}
                    name="city"
                    onChange={handleInput}
                  >
                    <option value="">Select City</option>
                    {fetchCity &&
                      fetchCity.map((data) => (
                        <option value={data.id}>{data.name}</option>
                      ))}
                  </Form.Select>
                  {error && error.city && (
                    <div className="text-danger">{error.city}</div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="pin_code"
                  className="form-control"
                  placeholder="Pin Code"
                />
                {error && error.pin_code && (
                  <div className="text-danger">{error.pin_code}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                  <YearPicker
                    className={"form-control"}
                    defaultValue={"Select Establish Year"}
                    start={1900}
                    value={estblish_year}
                    end={new Date().getFullYear()}
                    reverse
                    required={true}
                    name={"established_year"}
                    onChange={(year) => {
                      setEstblish_year(year);
                    }}
                    id={"year"}
                    classes={"form-control"}
                  />
                  {error && error.established_year && (
                    <div className="text-danger">{error.established_year}</div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="market_valuation"
                  className="form-control"
                  placeholder="Market Valuation"
                />
                {error && error.market_valuation && (
                  <div className="text-danger">{error.market_valuation}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="current_revenue"
                  className="form-control"
                  placeholder="Current Revenue"
                />
                {error && error.current_revenue && (
                  <div className="text-danger">{error.current_revenue}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="net_profit"
                  className="form-control"
                  placeholder="Net Profit"
                />
                {error && error.net_profit && (
                  <div className="text-danger">{error.net_profit}</div>
                )}
                </div>
              </div>
              <div className="col-md-12 my-3 about-startup">
                <Form.Label>About Startup</Form.Label>
                <Editor
                  tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [
                      AddRowBefore,
                      AddRowAfter,
                      AddColumnBefore,
                      AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                  ]}
                  contentStyle={{
                    height: "300px",
                  }}
                  name="about_startup"
                  placeholder="About Startup"
                  ref={ref1}
                  value={aboutus}
                  onChange={(e) => setAboutUs(e.target.value)}
                />
              </div>

              <div className="col-12 my-3 about-products">
                <Form.Label>About Products & Services</Form.Label>
                <Editor
                  tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [
                      AddRowBefore,
                      AddRowAfter,
                      AddColumnBefore,
                      AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                  ]}
                  contentStyle={{
                    height: "300px",
                  }}
                  name="about_product_service"
                  placeholder="About Products & Services"
                  ref={ref2}
                  value={aboutusPs}
                  onChange={(e) => setAboutUsps(e.target.value)}
                />
              </div>
              <div className="col-12 my-3 about-products">
                <Form.Label>Features & Highlights</Form.Label>
                <Editor
                  tools={[
                    [Bold, Italic, Underline, Strikethrough],
                    [Subscript, Superscript],
                    [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                    [Indent, Outdent],
                    [OrderedList, UnorderedList],
                    FontSize,
                    FontName,
                    FormatBlock,
                    [Undo, Redo],
                    [Link, Unlink, InsertImage, ViewHtml],
                    [InsertTable],
                    [
                      AddRowBefore,
                      AddRowAfter,
                      AddColumnBefore,
                      AddColumnAfter,
                    ],
                    [DeleteRow, DeleteColumn, DeleteTable],
                    [MergeCells, SplitCell],
                  ]}
                  contentStyle={{
                    height: "300px",
                  }}
                  name="feature_highlight"
                  placeholder="Features & Highlights"
                  ref={ref3}
                  value={feature_highlight}
                  onChange={(e) => setfeaturehighlight(e.target.value)}
                />
              </div>

              <div className="row">
              <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="website_url"
                  className="form-control"
                  placeholder="Website URL"
                />
                {error && error.website_url && (
                  <div className="text-danger">{error.website_url}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="facebook"
                  className="form-control"
                  placeholder="facebook"
                />
                {error && error.facebook && (
                  <div className="text-danger">{error.facebook}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="twitter"
                  className="form-control"
                  placeholder="Twitter"
                />
                {error && error.twitter && (
                  <div className="text-danger">{error.twitter}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="instagram"
                  className="form-control"
                  placeholder="Instagram"
                />
                {error && error.instagram && (
                  <div className="text-danger">{error.instagram}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="youtube"
                  className="form-control"
                  placeholder="Youtube"
                />
                {error && error.youtube && (
                  <div className="text-danger">{error.youtube}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="linkedIn"
                  className="form-control"
                  placeholder="LinkedIn"
                />
                {error && error.linkedIn && (
                  <div className="text-danger">{error.linkedIn}</div>
                )}
                </div>
                <div className="col-md-4 mt-2">
                  <label className="">Upload Logo</label>
                  <input type="file" className="form-control" onChange={handleLogo} />
                  {logop && <img src={logop} height={60} width={60} />}
                  {error && error.business_cagegotry && (
                    <div className="text-danger">
                      {error.business_cagegotry}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <label className="">Upload Pitch Deck</label>
                  <input type="file" className="form-control" onChange={handlepitchdeck} />
                  {pitchdeckp && (
                    <a href={pitchdeckp} target="_blank"  ><img className="mt-2" height={60} width={60} src="/assets/images/pdfdownloadicon.jpg" /></a>
                    
                  )}
                  {error && error.business_cagegotry && (
                    <div className="text-danger">
                      {error.business_cagegotry}
                    </div>
                  )}
                </div>
                <div className="col-md-4 mt-2">
                  <label className="">
                    Upload Banner Image
                  </label>
                  <input type="file" className="form-control" onChange={handlebanner} />
                  {bannerp && <img src={bannerp} height={60} width={60} />}
                  {error && error.business_cagegotry && (
                    <div className="text-danger">
                      {error.business_cagegotry}
                    </div>
                  )}
                  <label
                    className="position-absolute "
                    style={{ top: "3.6rem", color: "#A0041E" }}
                  >
                    Size: 1400x900
                  </label>
                </div>
                <div className="col-md-4 mt-2">
                <input
                  type="text"
                  onChange={handleInput}
                  name="video"
                  className="form-control"
                  placeholder="Link Video as a Banner"
                />
                {error && error.video && (
                  <div className="text-danger">{error.video}</div>
                )}
                </div>
              </div>
              <div className="form-group row mt-3">
                <h3>Documents</h3>
      {inputFields.map((input, index) => (
        <div className="form-group row" key={index}>
          <div className="col-md-4">
            <input
            type="text"
            placeholder="Document Title"
            name="document_title"
            className="form-control"
            value={input.document_title}
            onChange={(e) => handleInputChange(index, e)}
          /></div>
          <div className="col-md-4">
          <input
            type="file"
            name="document"
            className="form-control"
            accept=".pdf"
            onChange={(e) => handleInputChange(index, e)}
          />
  {input.document_url && (
                    <a href={input.document_url} target="_blank"  ><img className="mt-2" height={60} width={60} src="/assets/images/pdfdownloadicon.jpg" /></a>
                    
                  )}
          </div>
          <div className="col-md-2">
            {inputFields.length - 1 === index &&(
              <button type="button" style={{background:"#1e7e34", marginTop:"0px"}} onClick={handleAddField}>Add</button>
            )}
            {(inputFields.length >1) &&(
            <button type="button" style={{background:"#bd2130"}} className="btn btn-danger" onClick={() => handleRemoveField(index)}>Remove</button>
            )}
            {(inputFields.length != 1 && inputFields.length - 1 !== index) &&(
            <button type="button" style={{background:"#bd2130"}} className="btn btn-danger" onClick={() => handleRemoveField(index)}>Remove</button>
            )}
          
          
          </div>
          
          
         
        </div>
      ))}
      
               </div>

              <div className="d-flex gap-3 align-items-center my-3 px-2 ">
                <input type="checkbox" name="term_condition" />
                <p className="mb-0">
                  I Accept & Acknowledge that the information shared above can
                  be used on APNASHAHER.COM Portal for Listing Purpose and
                  Contact Details can be shared to the Interested Investors, and
                  the Users
                </p>
                {error && error.business_cagegotry && (
                  <div className="text-danger">{error.business_cagegotry}</div>
                )}
              </div>
              

              <button type="button" onClick={handleStartupData}>
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
