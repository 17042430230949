import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { YearPicker } from 'react-dropdown-date';
import Select from 'react-select';

const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const VendorContactUs = ({ props }) => {

  function handleAdd() {
    setShowAdd(true);
  }
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem('avonToken');
  const [aboutUs, setAboutUs] = useState("");
  const [data, setData] = useState('');
  const [category, setCategory] = useState([]);
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [businessNature, setBusinessNature] = useState('');
  const [whatsappNo, setWhatsapp] = useState('');
  const [address, setAddress] = useState('');
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState('');
  const [edit, setEdit] = useState(false);
  const [resError, setResError] = useState('');
  const [businessSlug, setBusinessSlug] = useState('');
  const [logo, setlogo] = useState('');
  const [file, setfile] = useState('');
  const [fileb, setfileb] = useState('');
  const [website, setWebsite] = useState('');
  const [currn, setcurrn] = useState('');
  const [currnid, setcurrnid] = useState('');
  const [currnname, setcurrnname] = useState('');
  const [currnsymbol, setcurrnsymbol] = useState('');
  const [currncode, setcurrncode] = useState('');
  const [map_url, setmap_url] = useState('');
  const [addMobile, setaddMobile] = useState([{ mobile: "" }]);
  const body1 = React.createRef();
  const body2 = React.createRef();
  const [companyimage, setcompanyimage] = useState("");
  const [companyimagefile, setcompanyimagefile] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [pinterest, setpinInterest] = useState("");
  const [productserviecs, setproductserviecs] = useState("");
  const [blogUrl, setBlogUrl] = useState();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [keywordtotal, setkeywordtotal] = useState("");
  const [banner, setbanner] = useState("");
  const [chip, setChip] = useState([]);
  const [statusData, setStatusData] = useState("");
  const [domain, setdomain] = useState("");
  const [subdomain, setsubdomain] = useState("");
  const [selected, setselected] = useState([]);
  const [subdomainstatus, setsubdomainstatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [categorys, setCategorys] = useState([])
  const [keyword, setKeyword] = useState([]);
  useEffect(() => {
    FetchProfileData();
    getCountry();
    FetchBusinessCategory();
    FetchStatus();
    FetchKeywords();
  }, [])
  const FetchKeywords = async () => {
    await axios.get(BASE_URL + "/getmetakeyword").then(({ data }) => {
      setKeyword(data.data);
    });
  };

  const KeyData = keyword.map((item) => item.meta_keyword);
  
  const FetchStatus = async () => {
    await axios
      .get(BASE_URL + "/get-seller-detail", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setStatusData(data);

        // if(data.data == null){
        //   navigate('/vendor-dashboard')
        // }
        // if(data.data.payment_status == 'Pending'){
        //   navigate('/manage-profile')
        //   }
        setStatus(
          statusData && statusData.data == null
            ? true
            : statusData && statusData.data.payment_status == "Pending"
              ? true
              : statusData && statusData.data.payment_status == "Active"
                ? false
                : true
        );
      });
  };
  
  const FetchProfileData = async () => {
    await axios.get(BASE_URL + '/getbusinessprofile', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      if (data.data) {
        let d = data.data;
        var datasl = [];

        data.data.business_mobilenumber && data.data.business_mobilenumber.length > 0 ? data.data.business_mobilenumber.map((item, i) => {
          datasl.push({ mobile: item })
        }) : datasl.push({ mobile: "" })
        var datas2 = [];
        data.data.business_email && data.data.business_email.length > 0 ? data.data.business_email.map((items, i) => {
          datas2.push({ email: items })
        }) : datas2.push({ email: "" })
        setaddEmail(datas2)
        setaddMobile(datasl)
        setBusinessName(d.business_name)
        setBusinessSlug(d.business_slug)
        setBusinessType(d.business_type)
        setBusinessNature(d.business_nature)
        setWhatsapp(d.whatsapp_number)
        setWebsite(d.website_url)
        setEstblish_year(d.estblish_year)
        setCountry(d.country.id)
        getState(d.country.id)
        setState(d.state.id)
        getCity(d.state.id)
        setCity(d.city.id)
        setPincode(d.pincode)
        setAddress(d.full_address)
        setcurrncode(d.business_currency_code)
        setcurrnid(d.business_currency_id)
        setcurrnname(d.business_currency_name)
        setcurrnsymbol(d.business_currency_symbol)
        setmap_url(d.map_url)
        setselected(d.business_category);
        setfile(data.image_url+d.logo)
        setFacebook(data?.shop?.facebook);
        setTwitter(data?.shop?.twitter);
        setYoutube(data?.shop?.youtube);
        setLinkedin(data?.shop?.linkedin);
        setInstagram(data.shop.instagram);
        setpinInterest(data?.shop?.pinterest);
        setBlogUrl(data?.shop?.blog_url);
        setcompanyimagefile(IMAGE_BASE_URL+data.shop.comp_intr_image);
        setfileb(IMAGE_BASE_URL+d.banner);
        setAboutUs(data.shop.comp_intr ? data.shop.comp_intr : "");
        setproductserviecs(data.shop.productserviecs ? data.shop.productserviecs : "");
        setMetaTitle(data.shop.meta_title);
          if (data.shop.meta_keyword.length > 0) {
            setChip(data.shop.meta_keyword);
          }
          setMetaDescription(data.shop.meta_description);
          setkeywordtotal(data.keywordtotal);
      }else{
        setEdit(true);
      }
      setData(data.data)
      // console.log("profile", data.data)

    })
  }

  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + '/getbusinesscategory', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setCategory(data.data)
      // console.log(data.data)
    })
  }

  // console.log(selected)

  // handle input change
  const handleInputMobileChange = (e, index) => {
    const { mobile, value } = e.target;
    const list = [...addMobile];
    list[index]['mobile'] = value;
    setaddMobile(list);
  };

  // handle click event of the Remove button
  const handleRemoveMobileClick = index => {
    const list = [...addMobile];
    list.splice(index, 1);
    setaddMobile(list);
  };

  // handle click event of the Add button
  const handleAddMobileClick = () => {
    setaddMobile([...addMobile, { mobile: "" }]);
  };

  const [addEmail, setaddEmail] = useState([{ email: "" }]);
  // console.log(addEmail)

  // handle input change
  const handleInputEmailChange = (e, index) => {
    const { email, value } = e.target;
    const list = [...addEmail];
    list[index]['email'] = value;
    setaddEmail(list);
    // console.log(list)
  };

  // handle click event of the Remove button
  const handleRemoveEmailClick = index => {
    const list = [...addEmail];
    list.splice(index, 1);
    setaddEmail(list);
  };

  // handle click event of the Add button
  const handleAddEmailClick = () => {
    setaddEmail([...addEmail, { email: "" }]);
  };

  const getCountry = () => {

    axios.get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const getcurrencybycountry = (id) => {
    axios.get(`${BASE_URL}/getcurrencybycountry/${id}`)
      .then(function (res) {
        setcurrnname(res.data.data.name)
        setcurrnid(res.data.data.id)
        setcurrnsymbol(res.data.data.symbol)
        setcurrncode(res.data.data.code)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const [pickupList, setPickupList] = useState([{ pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  //  console.log([pickupList[0].pickAddress])
  // handle input change
  const handleInputChange = (e, index) => {

    const { name, value } = e.target;
    const list = [...pickupList];
    // const li = [...pickupList.pickAddress]
    // console.log(list[0].pickAddress)
    list[index][name] = value;
    setPickupList(list);
    console.log(e)
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...pickupList];
    list.splice(index, 1);
    setPickupList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setPickupList([...pickupList, { pickAddress: "", pickCountry: "", pickstate: "", pickCity: "", pickPincode: "", pickLandmark: "" }]);
  };

  // console.log(pickupList[0].pickAddress)

  const [countryName, setCountryName] = useState()
  const handleCountyName = (e) => {
    const DataIdWithName = e;
    const countryData = DataIdWithName.split("_");
    setCountryName(countryData[0])
  }

  const [estblish_year, setEstblish_year] = useState('')

  const UpdateProfile = async () => {
    setResError("")
    var mobile = []
    addMobile.map((item) => {
      mobile.push(item.mobile)
    })
    var email = []
    addEmail.map((item) => {
      email.push(item.email)
    })
    
    const datas = {
      'business_name': businessName,
      'business_slug': businessSlug,
      'business_type': businessType,
      'business_nature': businessNature,
      'business_mobilenumber': mobile,
      'business_email': email,
      'whatsapp_number': whatsappNo,
      'website_url': website,
      'estblish_year': estblish_year,
      'country': country,
      'state': state,
      'city': city,
      'pincode': pincode,
      'full_address' : address,
      'logo' : logo
    }    
    const formdata = new FormData();
    formdata.append('business_mobilenumber', JSON.stringify(mobile));
    formdata.append('business_email', JSON.stringify(email));
    formdata.append('whatsapp_number', whatsappNo);
    formdata.append('website_url', website);
    formdata.append('country', country);
    formdata.append('state', state);
    formdata.append('city', city);
    formdata.append('pincode', pincode);
    formdata.append('full_address', address);
    formdata.append('map_url', map_url);
    formdata.append('facebook', facebook);
    formdata.append('twitter', twitter);
    formdata.append('youtube', youtube);
    formdata.append('linkedin', linkedin);
    formdata.append('instagram', instagram);
    formdata.append('pinterest', pinterest);
    formdata.append('blog_url', blogUrl);
    await axios.post(BASE_URL + '/updatecontactus', formdata, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchProfileData();
      setEdit(false)
      if (data.status == 'success') {
        toast.success(data.message)
      }
    })
      .catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setResError(response.data.message)
          if (response.data.message.business_category) {
            toast.error(response.data.message.business_category[0])
          }
          if (response.data.message.business_category) {
            toast.error(response.data.message.country[0])
          }
        }
      })
  }
const handlelogo=(e)=>{
setlogo(e.target.files[0]);
setfile(URL.createObjectURL(e.target.files[0]));
}
const handlebanner=(e)=>{
  setbanner(e.target.files[0]);
  setfileb(URL.createObjectURL(e.target.files[0]));
  }
  const handleChangeCategory=(selectedOption)=>{
    setselected(selectedOption)
    var d = []
    selectedOption.map((item)=>{
        d.push(item.value)
    });
   setCategorys(d)
  }
const handleimagecompany=(e)=>{
  setcompanyimage(e.target.files[0])
  setcompanyimagefile(URL.createObjectURL(e.target.files[0]))
}
const handlechangeChip = (e) => {
  console.log(e)
  if (chip.length < keywordtotal) {
    setChip(e);
  } else {
    alert("You reach Maximum limit to add keyword!")
  }

};
  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Contact Us</li>
            </ul>
            <div className="col-md-12">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h4>
                  Contact Us  <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}> <FontAwesomeIcon icon={faEdit} /></Button>
                  </h4>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Contact Us</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                      <div className="row">
                        {addEmail.map((x, i) => {
                          // console.log(x)
                          return (
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Business Email Id</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Email Id "
                                value={x.email}
                                onChange={e => handleInputEmailChange(e, i)}
                                disabled={data && data.business_email && !edit ? true : false}
                                defaultValue={data && data.business_email == null ? ' ' : data && data.business_email}
                              />
                              {resError && resError.business_email ? <div className="text-danger"> {resError && resError.business_email} </div> : ''}

                              <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                                {addEmail.length !== 1 && <div
                                  className="mr10 pointer"
                                  style={{ color: 'red' }}
                                  onClick={() => handleRemoveEmailClick(i)}>Remove</div>}
                                {addEmail.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddEmailClick}> + Add</div>}
                              </div>
                            </div>
                          );
                        })}
                        {addMobile.map((x, i) => {
                          return (
                            <div className="col-md-4 mb-2 mt-2">
                              <Form.Label>Business Mobile Number</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Mobile Number"
                                value={x.mobile}
                                onChange={e => handleInputMobileChange(e, i)}
                                disabled={data && data.business_mobilenumber && !edit ? true : false}
                              />
                              {resError && resError.business_mobilenumber ? <div className="text-danger"> {resError && resError.business_mobilenumber} </div> : ''}

                              <div className="btn-box" style={{ display: 'flex', float: 'right' }}>
                                {addMobile.length !== 1 && <div
                                  className="mr10 pointer"
                                  style={{ color: 'red' }}
                                  onClick={() => handleRemoveMobileClick(i)} >Remove</div>}
                                {addMobile.length - 1 === i && <div className="pointer" style={{ color: 'blue', marginLeft: 10 }} onClick={handleAddMobileClick}> + Add</div>}
                              </div>
                            </div>
                          );
                        })}
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Business Whatsapp Number</Form.Label>
                          <Form.Control
                            type="text"
                            value={whatsappNo}
                            placeholder="Enter Whatsapp Number"
                            onChange={(e) => setWhatsapp(e.target.value)}
                            disabled={data && data.whatsapp_number && !edit ? true : false}
                          />
                          {resError && resError.whatsapp_number ? <div className="text-danger"> {resError && resError.whatsapp_number} </div> : ''}
                        </div>
                        
                       
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Company Website</Form.Label>
                          <Form.Control
                            type="text"

                            placeholder="Enter Company URL"
                            onChange={(e) => setWebsite(e.target.value)}
                            value={website}
                            disabled={data && data.website_url && !edit ? true : false}
                          />
                          {resError && resError.website_url ? <div className="text-danger"> {resError && resError.website_url} </div> : ''}
                        </div>
                        
                        
                        {statusData && statusData.data && statusData.data.personal_domain == "Yes" && (
                        <>
                          <div className="col-md-4 mb-2 mt-2">
                            {subdomainstatus == false ? (
                              <>
                                <Form.Label>Domain Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={domain}
                                  onChange={(e) => setdomain(e.target.value)}
                                  placeholder="Enter Your Domain Name"
                                  // defaultValue={data && data.facebook == null ? '' : data && data.facebook}
                                  disabled={
                                    data && data.domain && !edit ? true : false
                                  }
                                />
                                {resError && resError.domain ? (
                                  <div className="text-danger">
                                    {resError && resError.domain}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <>
                                <Form.Label>SubDomain Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  onChange={(e) => setsubdomain(e.target.value)}
                                  value={subdomain}
                                  placeholder="Enter Your SubDomain Name"
                                  disabled={
                                    data && data.subdomain && !edit ? true : false
                                  }
                                />
                                {subdomain}.apnashaher.com
                                {resError && resError.subdomain ? (
                                  <div className="text-danger">
                                    {resError && resError.subdomain}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-md-4 mb-2 mt-2">
                            <Button
                              className="btn btn-sm"
                              onClick={() => setsubdomainstatus(!subdomainstatus)}
                            >
                              {" "}
                              {subdomainstatus == true
                                ? "Continue with Domain"
                                : "Continue with Sub Domain"}
                            </Button>
                          </div>

                        </>
                      )}
                        <div className="col-md-8 mb-2 mt-2">
                          <Form.Label>Enter Address</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Address"
                            onChange={(e) => setAddress(e.target.value)}
                            value={address}
                            disabled={data && data.full_address && !edit ? true : false}
                          />
                          {resError && resError.full_address ? <div className="text-danger"> {resError && resError.full_address} </div> : ''}
                        </div>
                        
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select Country</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            value={country}
                            onChange={(e) => { setCountry(e.target.value); getState(e.target.value); }}
                            onClick={(e) => handleCountyName(e.target.value)}
                            disabled={data && data.country && !edit ? true : false}
                          >
                            <option value="">Select Country</option>
                            {fetchCountry && fetchCountry.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </Form.Select>
                          {resError && resError.country ? <div className="text-danger"> {resError && resError.country} </div> : ''}
                        </div>
                        {/* <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Currency</Form.Label>
                          <Form.Control
                            type="text"
                            value={currnname}
                            // placeholder="Enter Whatsapp Number"
                            disabled={true}
                          />
                          {resError && resError.whatsapp_number ? <div className="text-danger"> {resError && resError.whatsapp_number} </div> : ''}
                        </div> */}
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select State</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            value={state}
                            onChange={(e) => { setState(e.target.value); getCity(e.target.value) }}
                            disabled={data && data.state && !edit ? true : false}
                          >
                            <option value="">Select State</option>
                            {fetchState && fetchState.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </Form.Select>
                          {resError && resError.state ? <div className="text-danger"> {resError && resError.state} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select City</Form.Label>
                          <Form.Select style={{ borderRadius: '0%' }}
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            disabled={data && data.city && !edit ? true : false}
                          >
                            <option value="">Select City</option>
                            {fetchCity && fetchCity.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </Form.Select>
                          {resError && resError.city ? <div className="text-danger"> {resError && resError.city} </div> : ''}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Pin Code</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Address"
                            onChange={(e) => setPincode(e.target.value)}
                            value={pincode}
                            disabled={data && data.pincode && !edit ? true : false}

                          />
                          {resError && resError.pincode ? <div className="text-danger"> {resError && resError.pincode} </div> : ''}
                        </div>
                        <div className="col-md-8 mb-2 mt-2">
                          <Form.Label>Google Map URL(Embed)</Form.Label>
                          <Form.Control
                            type="text"
                            as="textarea" rows={2}
                            placeholder="Enter Address"
                            onChange={(e) => setmap_url(e.target.value)}
                            value={map_url}
                            disabled={data && data.map_url && !edit ? true : false}
                          />
                          {resError && resError.map_url ? <div className="text-danger"> {resError && resError.map_url} </div> : ''}
                        </div>
                        <h3>Seo Setting</h3>
                        <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setFacebook(e.target.value)}
                          placeholder="Enter Facebook Url "
                          value={facebook}
                          disabled={
                            data && data.facebook && !edit ? true : false
                          }
                        />
                        {resError && resError.facebook ? (
                          <div className="text-danger">
                            {resError && resError.facebook}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Twitter</Form.Label>
                        <Form.Control
                          type="text"
                          onChange={(e) => setTwitter(e.target.value)}
                          value={twitter}
                          placeholder="Enter Twitter Url "
                          disabled={
                            data && data.twitter && !edit ? true : false
                          }
                        />
                        {resError && resError.twitter ? (
                          <div className="text-danger">
                            {resError && resError.twitter}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Youtube</Form.Label>
                        <Form.Control
                          type="text"
                          value={youtube}
                          onChange={(e) => setYoutube(e.target.value)}
                          placeholder="Enter Youtube Url"
                          disabled={
                            data && data.youtube && !edit ? true : false
                          }
                        />
                        {resError && resError.youtube ? (
                          <div className="text-danger">
                            {resError && resError.youtube}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Linkedin</Form.Label>
                        <Form.Control
                          type="text"
                          value={linkedin}
                          onChange={(e) => setLinkedin(e.target.value)}
                          disabled={
                            data && data.linkedin && !edit ? true : false
                          }
                          placeholder="Enter Linkedin Url"
                        />
                        {resError && resError.linkedin ? (
                          <div className="text-danger">
                            {resError && resError.linkedin}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                          onChange={(e) => setInstagram(e.target.value)}
                          type="text"
                          value={instagram}
                          disabled={
                            data && data.instagram && !edit ? true : false
                          }
                          placeholder="Enter Instagram Url"
                        />
                        {resError && resError.instagram ? (
                          <div className="text-danger">
                            {resError && resError.instagram}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Pinterest</Form.Label>
                        <Form.Control
                          type="text"
                          value={pinterest}
                          disabled={
                            data && data.pinterest && !edit ? true : false
                          }
                          placeholder="Enter Pinterest Url"
                          onChange={(e) => setpinInterest(e.target.value)}
                        />
                        {resError && resError.pinterest ? (
                          <div className="text-danger">
                            {resError && resError.pinterest}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="col-md-4 mb-2 mt-2">
                        <Form.Label>Blog Url</Form.Label>
                        <Form.Control
                          type="text"
                          value={blogUrl}
                          onChange={(e) => setBlogUrl(e.target.value)}
                          placeholder="Enter Blog Url "
                          disabled={
                            data && data.blog_url && !edit ? true : false
                          }
                        />
                        {resError && resError.blog_url ? (
                          <div className="text-danger">
                            {resError && resError.blog_url}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                        
                        <div className="col-md-12">
                          <Button
                            style={{ float: "right", marginTop: "8px", color: '#fff' }}
                            variant="warning"
                            onClick={UpdateProfile}
                          >
                            Update
                          </Button>
                        </div>
                      </div>

                    </Form>
                  </div>
                </div>

                {/* <div className="product-card-block">
                  <div className="order-head-blk">
                    <h6>Update Email</h6>
                  </div>
                  <div className="card-body-blk">
                    <div className="row">
                      <div className="col-md-2">
                        <p style={{ paddingTop: "7px" }}>Enter Your Email ID</p>
                      </div>
                      <div className="col-md-10">
                        <InputGroup>
                          <Form.Control
                            type="text"

                            // placeholder="Input group example"
                            // aria-label="Input group example"
                            // aria-describedby="btnGroupAddon"
                          />
                          <Button
                            style={{ backgroundColor: "#C3CECF", width: "15%" }}
                            variant="success"
                          >
                            Verify
                          </Button>
                        </InputGroup>
                      </div>

                      <div className="col-md-12">
                        <Button
                          style={{ float: "right", marginTop: "12px" }}
                          variant="warning"
                        >
                          Update Email
                        </Button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

        </section>
        <ToastContainer />
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default VendorContactUs;
