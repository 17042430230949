import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';



const VendorViewPages = ({ props }) => {



    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        Subscript,
        Superscript,
        AlignLeft,
        AlignCenter,
        AlignRight,
        AlignJustify,
        Indent,
        Outdent,
        OrderedList,
        UnorderedList,
        Undo,
        Redo,
        FontSize,
        FontName,
        FormatBlock,
        Link,
        Unlink,
        InsertImage,
        ViewHtml,
        InsertTable,
        AddRowBefore,
        AddRowAfter,
        AddColumnBefore,
        AddColumnAfter,
        DeleteRow,
        DeleteColumn,
        DeleteTable,
        MergeCells,
        SplitCell
    } = EditorTools;

    const body1 = React.createRef();
    const [aboutUs, setAboutUs] = useState('');
    const [image, setimage] = useState('');
    const [on_heder, setOnHeader] = useState('');
    const [on_footer, setOnFooter] = useState('');
    const [title, setTitle] = useState('');
    const [type, setType] = useState('');
    const [statusD, setStatusD] = useState('');
    const [slug, setSlug] = useState('');
    const [file, setfile] = useState('');
    const [error, setError] = useState('');
    const token = localStorage.getItem('avonToken');
    const [stat, setStat] = useState();
    const [statvl, setStatVl] = useState();
    const location = useLocation();
    console.log(location)
    const handleStatus = (e) => {
        setStat('gtc', e.target.checked)
        setStatVl(!statvl)
        setStatusD(statvl == true ? "active" : "no-active")
    }

    const handlechangeimage = (e) => {
        setimage(e.target.files[0])
        setfile(URL.createObjectURL(e.target.files[0]))
    }
    useEffect(() => {
        fetchaboutus();
    }, [])

    const fetchaboutus = async () => {
        await axios.get(BASE_URL + `/editsellerpage/${location.state}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {

            setAboutUs(data.data.content)
            setfile(data.data.image ? data.image_base_url + data.data.image : "")
            setOnHeader(data.data.on_heder)
            setOnFooter(data.data.on_footer)
            setType(data.data.type)
            setTitle(data.data.title)
            setSlug(data.data.slug)
            setStatusD(data.data.status)

        })
    }
    return (
        <Page title="Manage Profile">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Manage Profile</li>
                            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                                Customer Dashboard
                            </Button>

                        </ul>
                    </div>
                </section>

                <section className="mt-4">
                    <div className="container-fluid">
                        <div className="row">
                            <VendorDashboardSideMenu />

                            <div className="col-md-9">
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <h4>
                                        About Page
                                    </h4>
                                    <NavLink to="/other-pages"><Button>Back</Button></NavLink>
                                </div>


                                <div className="product-card-block">
                                    {/* <div className="order-head-blk mb-3">
                    <h5>Store Setup</h5>
                  </div> */}
                                    <div className="card-body-blk">
                                        <div className="col-md-12 mt-4">
                                            <h5>Title</h5>
                                            <div >{title}</div>


                                        </div>
                                        <div className="row">

                                            <div className="col-md-12 mt-4">
                                                <h5>About Page</h5>
                                                <div dangerouslySetInnerHTML={{ __html: aboutUs }} />
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <div className="row">

                                                    <div className="col-md-4 mb-2 mt-2">
                                                        <h5 className="mt-2">About Us Image</h5>
                                                    </div>

                                                    <div className="col-md-8 mb-2 mt-2">
                                                        {
                                                            file && <img src={file} height={100} width={100} />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hide-in-mobile">
                                                <div className="row">
                                                    <div className="col-md-3 mt-4">
                                                        <h5>Slug</h5>
                                                        <div>{slug}</div>
                                                    </div>
                                                    <div className="col-md-3 col-sm-4 col-xs-4 mt-4">
                                                        <Form.Check type="checkbox" checked={on_heder == 'yes' ? true : false} label="On Header" style={{ marginTop: 40 }} />
                                                        {error.content && (
                                                            <div className="text-danger">{error.content}</div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-3 col-sm-4 col-xs-4 mt-4">

                                                        <Form.Check type="checkbox" checked={on_heder == 'yes' ? true : false} label="On Header" style={{ marginTop: 40 }} />
                                                        {error.content && (
                                                            <div className="text-danger">{error.content}</div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-3 col-sm-4 col-xs-4 mt-4">
                                                        <Form.Label className="" style={{ display: 'flex', marginTop: 40 }}>
                                                            Status
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                // label="Closed?"
                                                                onChange={handleStatus}
                                                                checked={statusD == 'active' ? true : false}

                                                                style={{ marginLeft: 10 }}
                                                            />
                                                        </Form.Label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="hide-in-desktop">
                                                <div className="col-md-3 mt-4">
                                                    <h5>Slug</h5>
                                                    <div>{slug}</div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <div className="col-md-3 col-sm-4 col-xs-4">
                                                        <Form.Check type="checkbox" checked={on_heder == 'yes' ? true : false} label="On Header" style={{ marginTop: 40 }} />
                                                        {error.content && (
                                                            <div className="text-danger">{error.content}</div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-3 col-sm-4 col-xs-4">

                                                        <Form.Check type="checkbox" checked={on_heder == 'yes' ? true : false} label="On Header" style={{ marginTop: 40 }} />
                                                        {error.content && (
                                                            <div className="text-danger">{error.content}</div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-3 col-sm-4 col-xs-4">
                                                        <Form.Label className="" style={{ display: 'flex', marginTop: 40 }}>
                                                            Status
                                                            <Form.Check
                                                                type="switch"
                                                                id="custom-switch"
                                                                // label="Closed?"
                                                                onChange={handleStatus}
                                                                checked={statusD == 'active' ? true : false}

                                                                style={{ marginLeft: 10 }}
                                                            />
                                                        </Form.Label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-12">
                                                <Button
                                                    style={{ float: "right", marginTop: "8px", color: '#fff' }}
                                                    variant="warning"
                                                    onClick={Updateaboutus}
                                                >
                                                    Update
                                                </Button>
                                            </div> */}
                                            <ToastContainer />
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

        </Page>
    );
};

export default VendorViewPages;
