import React from 'react'
import "../style/Blogs1.css";
import { Link } from 'react-router-dom';

export const Events = () => {
  return (
    <>
      <div className="blogs-parent events" style={{ backgroundColor: "#E7E7E7" }}>
            <h2 className='px-lg-5 px-md-4 px-3 pt-lg-5 pt-md-4 pt-3'>Events</h2>
        <div className="blogs-body px-lg-5 px-md-4 px-3 py-md-4 py-3 d-flex justify-content-between">
          {/* left  */}
          <div className=" left">
            {/* Cart   */}
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3  align-items-center"
                >
                  <img src="/assets/images/investor-1.png" alt="" />
                  <div className="card-rc">
                    <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt accusantium velit veniam error maxime accusamus. Dolorum, sapiente? A, facilis, corporis expedita pariatur modi necessitatibus hic accusantium, consequuntur qui corrupti ducimus doloremque nulla quos?
                      <Link to="">read more</Link>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                     
                      <p className="location">
                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                        Delhi, India</p>
                      <p className="view">
                        <span>
                          <i className="fa fa-calendar pe-2"></i>
                        </span>{" "}
                        19 Feb 2022
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3  align-items-center"
                >
                  <img src="/assets/images/investor-1.png" alt="" />
                  <div className="card-rc">
                    <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt accusantium velit veniam error maxime accusamus. Dolorum, sapiente? A, facilis, corporis expedita pariatur modi necessitatibus hic accusantium, consequuntur qui corrupti ducimus doloremque nulla quos?
                      <Link to="">read more</Link>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                     
                      <p className="location">
                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                        Delhi, India</p>
                      <p className="view">
                        <span>
                          <i className="fa fa-calendar pe-2"></i>
                        </span>{" "}
                        19 Feb 2022
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3  align-items-center"
                >
                  <img src="/assets/images/investor-1.png" alt="" />
                  <div className="card-rc">
                    <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt accusantium velit veniam error maxime accusamus. Dolorum, sapiente? A, facilis, corporis expedita pariatur modi necessitatibus hic accusantium, consequuntur qui corrupti ducimus doloremque nulla quos?
                      <Link to="">read more</Link>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                     
                      <p className="location">
                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                        Delhi, India</p>
                      <p className="view">
                        <span>
                          <i className="fa fa-calendar pe-2"></i>
                        </span>{" "}
                        19 Feb 2022
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3  align-items-center"
                >
                  <img src="/assets/images/investor-1.png" alt="" />
                  <div className="card-rc">
                    <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt accusantium velit veniam error maxime accusamus. Dolorum, sapiente? A, facilis, corporis expedita pariatur modi necessitatibus hic accusantium, consequuntur qui corrupti ducimus doloremque nulla quos?
                      <Link to="">read more</Link>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                     
                      <p className="location">
                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                        Delhi, India</p>
                      <p className="view">
                        <span>
                          <i className="fa fa-calendar pe-2"></i>
                        </span>{" "}
                        19 Feb 2022
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3  align-items-center"
                >
                  <img src="/assets/images/investor-1.png" alt="" />
                  <div className="card-rc">
                    <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt accusantium velit veniam error maxime accusamus. Dolorum, sapiente? A, facilis, corporis expedita pariatur modi necessitatibus hic accusantium, consequuntur qui corrupti ducimus doloremque nulla quos?
                      <Link to="">read more</Link>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                     
                      <p className="location">
                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                        Delhi, India</p>
                      <p className="view">
                        <span>
                          <i className="fa fa-calendar pe-2"></i>
                        </span>{" "}
                        19 Feb 2022
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="card-content my-1 py-lg-4 py-3 d-flex  gap-lg-4 gap-3  align-items-center"
                >
                  <img src="/assets/images/investor-1.png" alt="" />
                  <div className="card-rc">
                    <h3>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </h3>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit nesciunt accusantium velit veniam error maxime accusamus. Dolorum, sapiente? A, facilis, corporis expedita pariatur modi necessitatibus hic accusantium, consequuntur qui corrupti ducimus doloremque nulla quos?
                      <Link to="">read more</Link>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                     
                      <p className="location">
                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                        Delhi, India</p>
                      <p className="view">
                        <span>
                          <i className="fa fa-calendar pe-2"></i>
                        </span>{" "}
                        19 Feb 2022
                      </p>
                    </div>
                  </div>
                </div>
           
          </div>

          {/* Right ********************* */}
          <div className=" bg-white right px-lg-4 px-3 py-lg-4 py-3">
            <div className="search">
              <h4>Search</h4>
              <form action="">
                <input type="text" placeholder="Search"  />
                <span>
                  <i className="fa fa-search"></i>
                </span>
              </form>
            </div>

            <h4>Past Events</h4>
            <div className="recent-posts">
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
                  <div className="rp-card d-flex gap-lg-4 gap-3 align-items-center" >
                    <img src="/assets/images/investor-1.png" alt="" />
                    <div>
                      <p className="bold-para mb-1">Donec Tellus Nulla Lorem</p>
                      <p className="mb-1 date"><span><i className='fa fa-calendar pe-2'></i></span> Nov 13, 2021 </p>
                      <p className="mb-0 location">
                      <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg></span>
                         Delhi, India</p>
                    </div>
                  </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
