import React, { useState } from "react";
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';
import { NavLink } from "react-router-dom";

const data = [
    {id:1},{id:2},{id:3}
]

export default function SellerFAQ() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div>
            <SellerHeader />
            <section className="breadcrumb-sction mt-2">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/seller-website">Home</NavLink>
              </li>
              <li>Terms and Condition</li>
            </ul>
          </div>
        </section>
            <div className="col-md-12 card-body-blk">
        
                    <div className="product-details-main-block">
                  <div className="card-block">
                    <div className="card-body-blk-con">
                      <div className='seller-info-container'>
                        <span className='seller-card-about'>Welcome to our Store</span>
                      </div>
                    </div>
                    <div className='seller-block card-body-blk'>
                      <div className='mt-2'>
                        <div className="seller-store-content">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                        <div className="seller-store-content mt-2">
                        Nulla auctor mauris ut dui luctus semper. In hac habitasse platea dictumst. Duis pellentesque ligula a risus suscipit dignissim. Nunc non nisl lacus. Integer pharetra lacinia dapibus. Donec eu dolor dui, vel posuere mauris.
                        </div>
                     
                      </div>
                    </div>
                  </div>

                </div>
               
            </div>
            <SellerFooter />
        </div>
    )
}