import React, { useState, useEffect } from "react";
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';
import { DOMAIN_NAME, BASE_URL } from "../../config";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
const DynamicPage = () => {
    const [data, setData] = useState("");
    const [baseImage, setBaseImage] = useState("");
    const {link} = useParams();
    useEffect(() => {
        fetchpage();
      }, [link])
      const fetchpage = async () => {
        await axios.get(BASE_URL + '/seller/getpage/'+link, {
          headers: {
            'Accept': 'application/json',
            'domainname': DOMAIN_NAME
          }
        }).then(({ data }) => {
          setBaseImage(data.image_base_url)
          setData(data.data[0])
        })
      }    
  return (
    <div>
    <SellerHeader />
    <section className="breadcrumb-sction mt-2">
      <div className="container-fluid">
        <ul className="breadcrumb-block">
          <li>
            <NavLink to="/seller-website">Home</NavLink>
          </li>
          <li>{data && data.title}</li>
        </ul>
      </div>
    </section>
    <div className="col-md-12">
      <img src={data && baseImage + data.image} style={{ width: '100%', maxHeight: '500px' }} />
    </div>
    <div className="col-md-12 mt-8 mb-8">
      <div className="row faqbox" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-md-12">
          <div className="product-details-main-block">
            <div className="">
              <div className='seller-block card-body-blk'>
                <div className='mt-2'>
                  <div className="seller-store-content" dangerouslySetInnerHTML={{ __html: data && data.content }}>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
        
      </div>

    </div>
    <SellerFooter />
  </div>
  )
}

export default DynamicPage
