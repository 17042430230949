import React from "react";
import "../../style/Startup.css"


export const Subscribe = () => {
  return (
    <>
      <div className="subscribe">
        {/* Background Images with content  */}
        <div className="bg-img  d-flex position-relative mb-lg-3 mb-md-3 mb-2">
        <div className="bg-color position-absolute top-0 w-100 h-100"></div>
          <div className="subscribe-content text-center px-3">
            <h2>We Publish New Businesses Every Monda</h2>
            <p>Enter Your Email Below to Be the First "In the Know</p>
            <div className="get-notify d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center">
              <img src="assets/icons/email.png" alt="" height={"11px"} width={"12px"} />
              <input
                type="text" className="border-0"
                placeholder="Email Address*"
              />
                
              </div>
              <button className="border-0 btn">Get Notified</button>
            </div>
          </div>
        </div>

        {/* Paragraphs  */}
       
      </div>
    </>
  );
};
