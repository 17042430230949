import React from 'react';
import { Accordion, AccordionItem } from 'react-light-accordion';

const Faq = (props) => {
    console.log();
    return (
        <>
            <div className="container seller-faq-section"  style={{marginTop: "30px"}}>
                <h2>{props?.homepagewidget?.widget8}</h2>
                <h3>{props?.homepagewidget?.widget9}</h3>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 faq-left">
                            {props?.faqQuestions1?.map((item, index) => (
                                <Accordion atomic={true}>
                                    <AccordionItem title={item.question}>
                                        <p>{item.answer}</p>
                                    </AccordionItem>
                                </Accordion>
                            ))}
                        </div>
                        <div className="col-md-6 faq-right">
                            {props?.faqQuestions2?.map((item, index) => (
                                <Accordion atomic={true}>
                                    <AccordionItem title={item.question}>
                                        <p>{item.answer}</p>
                                    </AccordionItem>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Faq;