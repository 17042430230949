import { useContext, useState } from "react";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import axios from 'axios';
import { BASE_URL } from "../../config";
import { toast } from "react-toastify";

const CreateTicket = ({ showAdd, setShowAdd, subjects }) => {
  const [subject, setSubject] = useState('');
  const [details, setDetails] = useState('');
  const [detailsError, setDetailsError] = useState([]);
  const [filesError, setFilesError] = useState([]);
  const [subjectError, setSubjectError] = useState([]);
  const [file, setFile] = useState(null);
  const token = localStorage.getItem('avonToken');

  // Event handler for the file input field
  const handleFileChange = (event) => {
    // Assuming you want to upload a single file
    setFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append('subject', subject);
    formData.append('details', details);
    formData.append('file', file);
    console.log(formData, "formdata");
    axios
      .post(BASE_URL + `/addticket`, formData, {
        headers: {
          Accept: "application/json",
          'Authorization': 'Bearer ' + token
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status == "200") {
          toast.success("Added Succesfully");
          setShowAdd(false);
          console.log("set email called");
        }
      })
      .catch(({response}) => {
        console.log(response?.data?.message, "tickets errro");
        setDetailsError(response?.data?.message?.details);
        setFilesError(response?.data?.message?.file);
        setSubjectError(response?.data?.message?.subject);
      });
  };
  const handleClose = () => {
    setShowAdd(false);
  };

  const subjectList = subjects && subjects;

  console.log("subjectList", subjectList);
  return (
    <Modal
      size="md"
      onHide={handleClose}
      className="ticket-modal-block"
      show={showAdd}
      animation={false}
      centered
    >
      <Modal.Header closeButton>
        <h5 class="modal-title">Create A Ticket</h5>
      </Modal.Header>
      <Modal.Body>
        <Form >
          <div className="row">
            <div className="col-md-4">
              <Form.Label>Subject</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Select onChange={(e) => setSubject(e.target.value)}>
                  {subjectList && subjectList?.map((item, index) => (
                    <option
                      value={item.index}
                     
                    >{item.subject}</option>
                  ))}

                </Form.Select>
              </Form.Group>
              <small style={{color: "red", position: "relative", top: "-20px"}}>{subjectError ? subjectError : ""}</small>
            </div>
            <div className="col-md-4">
              <Form.Label>Provide a detailed description</Form.Label>
            </div>
            <div className="col-md-8">
              <Form.Group className="form-group">
                <Form.Control
                  as="textarea"
                  placeholder="Type your reply"
                  rows={3}
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </Form.Group>
              <small style={{color: "red", position: "relative", top: "-20px"}}>{detailsError ? detailsError : ""}</small>
            </div>
            <div className="col-md-4">
              <Form.Label>Photo</Form.Label>
            </div>
            <div className="col-md-8">
              <InputGroup>
                <Button
                  style={{ backgroundColor: "#17A2B8" }}
                  variant="success"
                >
                  Browse
                </Button>
                <Form.Control
                  type="file"
                  placeholder="Choose File"
                  onChange={handleFileChange}
                />
              </InputGroup>
              <small style={{color: "red",}}>{filesError ? filesError : ""}</small>
            </div>
           
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" style={{ backgroundColor: '#555555' }} onClick={() => setShowAdd(false)}>
          Cancel
        </Button>
        <Button variant="warning" onClick={handleSubmit}>
          Send Ticket
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateTicket;
