import React, { useState, useEffect } from 'react'
import { AboutComp4 } from './AboutComp4'
import AOS from 'aos';
import 'aos/dist/aos.css';


export const AboutComp2 = () => {
    const [actBtn, setActBtn] = useState(1);
    const [currTab, setCurrtab] = useState("our-comp")

    const chageTab = (btnId, comp) => {
        setActBtn(btnId);
        setCurrtab(comp)
    }

    const isActive = (btnId) => {
        return actBtn === btnId ? 'active-btn' : '';
    };



    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);


    return (
        <>
            <div className={`about-comp2 px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3 `} style={{background:(currTab === "our-comp" || currTab === "cvalue" || currTab === "milestone") ?  "#F7F7FD": currTab === "investor" ? "linear-gradient(102deg, #3360B2 20.11%, #274B8D 96.24%)":""}}>

                <div className="abc-body  ps-lg-3 ps-2">
                    <div className="overf-div">
                        <div className="btns d-flex align-items-center gap-lg-3 gap-2 ">
                            <button className={`ps-0 ${isActive(1)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(1, "our-comp")}>Our Company</button>
                            <button className={`${isActive(2)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(2, "journey")}>Our Journey</button>
                            <button className={`${isActive(3)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(3, "cvalue")}>Core Values</button>
                            <button className={`${isActive(4)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(4, "milestone")}>Milestone Reached</button>
                            <button className={""} style={{color: currTab === "investor" ? "#FFAE00" :"", borderBottom: currTab === "investor" ? "2px solid #FFAE00": "",}} onClick={() => chageTab(5, "investor")}>Our Investors</button>
                        </div>
                    </div>

                    {
                        currTab === "our-comp" ?
                            <div className="our-comp d-flex justify-content-between  py-md-4 py-3 p-3"

                            >
                                <div className="left" data-aos="fade-left">
                                    <h5>About Company</h5>
                                    <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut </h6>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/about-2.png" alt="" />
                                </div>
                            </div>
                            :
                            currTab === "journey" ?
                                <div className="our-journey d-flex justify-content-center" >
                                    <img src="/assets/images/our-journey.png" alt="" width={"80%"} />
                                </div>
                                :
                                currTab === "cvalue" ?

                                    <div className="core-value py-md-4 py-3 p-3" >
                                        {/* <h4 className='mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h4> */}
                                        <div className="cards d-flex flex-wrap jutify-content-between gap-3 my-lg-4 my-3">
                                            <div className="card border-0" data-aos = "">
                                                <img src="/assets/icons/customer-obs.png" alt="" />
                                                <div className="card-body ps-0">
                                                    <h5 className="title">
                                                        Customer Obsession
                                                    </h5>
                                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d.</p>
                                                </div>
                                            </div>
                                            <div className="card border-0" >
                                                <img src="/assets/icons/collaborate.png" alt="" style={{background:"#FF7474"}}/>
                                                <div className="card-body ps-0">
                                                    <h5 className="title">
                                                    Collaborate
                                                    </h5>
                                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d.</p>
                                                </div>
                                            </div>
                                            <div className="card border-0" >
                                                <img src="/assets/icons/excellence.png" alt="" style={{background:"#0D2CD2"}}/>
                                                <div className="card-body ps-0">
                                                    <h5 className="title">
                                                    Excellence
                                                    </h5>
                                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d.</p>
                                                </div>
                                            </div>
                                            <div className="card border-0" >
                                                <img src="/assets/icons/integrity.png" alt="" style={{background:"#BD5DD5"}}/>
                                                <div className="card-body ps-0">
                                                    <h5 className="title">
                                                    Integrity
                                                    </h5>
                                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d.</p>
                                                </div>
                                            </div>
                                            <div className="card border-0" >
                                                <img src="/assets/icons/mdeday.png" alt="" style={{background:"#FF8B2A"}}/>
                                                <div className="card-body ps-0">
                                                    <h5 className="title">
                                                    Make a deferent everyday
                                                    </h5>
                                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d.</p>
                                                </div>
                                            </div>
                                            <div className="card border-0" >
                                                <img src="/assets/icons/ownership.png" alt=""  style={{background:"#53C2D2"}}/>
                                                <div className="card-body ps-0">
                                                    <h5 className="title">
                                                    Ownership
                                                    </h5>
                                                    <p>Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    currTab === "milestone" ?
                                        <div className="milestone  pb-md-4 pb-3 mt-5">
                                            <h4>Aha! We have still not achieved what we want, working on the best part of the <span className='spn1'>APANSHAHER.COM</span>, soon you will hear the News for the something Big,
                                                <span className='spn2'>Stay Tuned with us</span> </h4>
                                        </div>
                                        :
                                        currTab === "investor" ?

                                            <div className="investor" >
                                                <AboutComp4 para="none" mbottom={"mb-3"} twhite = {"white"} />
                                            </div>
                                            : null
                    }









                </div>
            </div>
        </>
    )
}
