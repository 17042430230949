import React, { useEffect, useState } from 'react';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Axios from 'axios';
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';
import SellerProductServices from './SellerProductServices';
import Page from '../../components/Page';
import { Form, Accordion, Button } from 'react-bootstrap';
import ReactStars from 'react-stars';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BASE_URL,IMAGE_BASE_URL,DOMAIN_NAME } from '../../config';
// const categories = [
//     { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }
// ]

const priceData = [
    { id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }
]


const Shop = () => {
    const [products, setProducts] = useState([]);
    const [categories, setcategories] = useState([]);
    const [brands, setbrands] = useState([]);
    const [data, setdata] = useState("");
    const ratingChanged = (newRating) => {
        console.log(newRating)
    }
    useEffect(()=>{
        fetchproduct();
    },[])
const fetchproduct =async()=>{
    await axios.get(BASE_URL + '/seller/product', {
        headers: {
            'Accept': 'application/json',
            'domainname': DOMAIN_NAME
        }
    }).then(({ data }) => {
        setProducts(data.data)
        setcategories(data.side_panel_categories)
        setbrands(data.brands)
        setdata(data)
    }).catch(({ response }) => {

    })
}
    return (
        <Page title="Shop">
            <SellerHeader />
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Shop</li>
                        </ul>
                    </div>
                </section>
                <section className="shop-main-section">
                    <Container fluid>
                        <Row>
                            <Col md={3}>
                                <div className="sidebar-block">
                                    <div className="side-head-blk">FILTER BY</div>
                                    <div className="side-accordian">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Category</Accordion.Header>
                                                <Accordion.Body>
                                                    <Form>
                                                        {categories.map((category,i) => (
                                                            <div className="form-group form-check customcheckbox" key={i}>
                                                                <input type="checkbox" className="form-check-input" />
                                                                <label className="form-check-label" htmlFor={category.slug}>
                                                                    {category.name}
                                                                    <span>({category.products_count})</span>
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </Form>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Price</Accordion.Header>
                                                <Accordion.Body>
                                                    <Form.Label>Price Range: <span style={{ color: '#ffb800' }}>₹{data.min_price} - ₹{data.max_price}</span></Form.Label>
                                                    <Form.Range />

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Brand</Accordion.Header>
                                                <Accordion.Body>
                                                    <Form>
                                                        {brands && brands.map((brand,i) => (
                                                            <div className="form-group form-check customcheckbox" key={i}>
                                                                <input type="checkbox" className="form-check-input" />
                                                                <label className="form-check-label" htmlFor={brand.slug}>
                                                                    {brand.name}
                                                                    {/* <span>(2)</span> */}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </Form>

                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </div>
                            </Col>
                            <Col md={9}>
                                <div className="shopsearch-grid-blk">
                                    <Row>
                                        <Col md={6}>
                                            <div className="gridlist-item-blk">
                                                <a href="" className="gridlist-btn active">
                                                    <i className="bi bi-grid"></i>
                                                </a>
                                                <a href="" className="gridlist-btn">
                                                    <i className="bi bi-list-task"></i>
                                                </a>
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className="sortby-block">
                                                <span>Sort By</span>
                                                <Form.Select aria-label="Default select example">
                                                    {/* <option value="0"></option> */}
                                                    <option value="1">Popularity</option>
                                                    <option value="2">Price - Low to High</option>
                                                    <option value="3">Price - High to Low</option>
                                                    <option value="4">Newest First</option>
                                                </Form.Select>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col md={12}>
                                        <div className='row ' style={{ marginLeft: 0, marginRight: 0 }}>
                                            {products.map((item,i) => (
                                                <div className='col-md-4 col-xs-6 center-align mt-4 mb-4' key={i}>
                                                    <div className='card seller-product-width'>
                                                        <img src={item.thumbnail} />
                                                        <div style={{ padding: 10, paddingLeft: 15, paddingRight: 15 }}>
                                                            <h4 style={{ textAlign: 'left' }} title={item.name}>{item.name.length > 20 ? item.name.slice(0,16)+"....": item.name}</h4>
                                                            <div className="rating-section">
                                                                <p>{item.rating}</p>
                                                                <ReactStars
                                                                    count={5}
                                                                    onChange={ratingChanged}
                                                                    size={24}
                                                                    color2={'#ffd700'}
                                                                    className="rating-stars"
                                                                />

                                                            </div>
                                                            <div style={{ fontSize: 22, textAlign: "left" }}> ${item.base_discounted_price} <span style={{ marginLeft: 15, color: 'gray', textDecoration: 'line-through' }}>${item.base_price}</span>
                                                            </div>
                                                            <Button className='mt-2' style={{ width: '100%' }}>Send Enquiry</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <SellerFooter />
        </Page>
    );
};
export default Shop;
