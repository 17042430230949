import React, { useContext, useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DashboardSideMenu from "../components/DashboardSideMenu";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Button, Row } from "react-bootstrap";
import "../style/SellerDashboard.css"


import Page from "../components/Page";
import DashboardOrderList from "../components/dashboard/dashboard-order-list.component";
import DashboardPurchaseList from "../components/dashboard/dashboard-purchase-list.component";
import axios from "axios";
import { BASE_URL } from "../config";
import { Bar } from "react-chartjs-2";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import StateContext from "../context/StateContext";
import DispatchContext from "../context/DispatchContext";
import { Chat } from "../components/user-dashboard/Chat";
import CustomCalendar from "../components/user-dashboard/calendar/Calendar";

const Dashboard = () => {
    const [ban, setban] = useState(0);
    const token = localStorage.getItem("avonToken");
    const navigate = useNavigate("");
    const path = window.location.origin;
    const appState = useContext(StateContext);
    const appDispatch = useContext(DispatchContext);
    const location = useLocation();
    const [viewChat, setViewChat] = useState(false);
    console.log(location)
    useEffect(() => {
        getsellerr();
    }, [])
    const sendemail = async () => {
        const response = await Axios.post("/send-email-verification", {
            'email': appState?.state?.user?.email,
            'url': path + '/verify-email/',
        });
        if (response.data.status === true) {
            toast.success(response.data.message)
            appDispatch({
                type: 'setUser',
                data: {
                    profileImage: appState?.state?.user?.profileImage,
                    step: appState.state.user.step,
                    token: appState.state.user.token,
                    name: appState.state.user.name,
                    email: appState.state.user.email,
                    mobileNumber: appState.state.user.mobileNumber,
                    lastLogin: appState.state.user.lastLogin,
                    is_email_verified: 2,
                },
            });
            localStorage.setItem("is_email_verified", 2)
        }

    }
    const getsellerr = () => {
        if (token) {
            axios.get(BASE_URL + '/get-seller-detail', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }).then(({ data }) => {
                setban(data.user)
            })
        }

    };


    const data1 = {
        labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        datasets: [
            {
                label: 'Weekend Data',
                data: [1, 0.8, 0.6, 1.5, 0.8, 1, 1],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.6)',
                    'rgba(54, 162, 235, 0.6)',
                    'rgba(255, 206, 86, 0.6)',
                    'rgba(75, 192, 192, 0.6)',
                    'rgba(153, 102, 255, 0.6)',
                    'rgba(255, 159, 64, 0.6)',
                    'rgba(106, 90, 205, 0.6)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                    'rgba(106, 90, 205, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        display: false,
                    },
                    gridLines: {
                        display: false, // Remove y-axis grid lines
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        display: false, // Remove x-axis grid lines
                    },
                },
            ],
        },
    };
    const handlenavigatvendor = () => {
        if (appState?.state?.user?.is_email_verified == 0 && appState?.state?.user?.is_email_verified == 2) {
            alert("Kindly Verify Your Email id")
        } else {
            navigate("/vendor-dashboard")
        }

    }
    return (
        <Page title="Dashboard">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Dashboard</li>
                            {/* {ban == 0 &&(
                <Button
                
                onClick={handlenavigatvendor}
                variant="primary"
                className="seller-dashboard-btn"
              >
                Seller Dashboard
              </Button>
              )} */}

                        </ul>
                    </div>

                    {/* <div style={{float: 'right'}}>
              <Button href="/vendor-dashboard" className="toggle-button" variant="warning">
                Seller Info
              </Button>
            </div> */}
                </section>

                <section>
                    <div className="sellerdashboard-content user-das-content">
                        <div className=" d-flex gap-lg-4 gap-3 flex-row px-5 py-4">

                            <DashboardSideMenu />

                            {/* <div className="col-md-9">
                <div className="card-block">
                  <div className="card-head-blk">
                    <h4>Dashboard</h4>
                  </div>
                  <div className="card-body-blk">
                    <h4 className="dashboardHeading">Recent Enquiries</h4>
                    <DashboardOrderList />
                  </div>
                  <div className="card-body-blk">
                    <DashboardPurchaseList />
                  </div>
                </div>
              </div> */}
                            {viewChat === false ? (<div className="col-lg-9 px-2 right ">
                                {appState?.state?.user?.is_email_verified == 0 && (
                                    <div className="top-alert w-100 mb-3 pe-3">
                                        <button className='w-100 fs-6 ' style={{ background: "#FDD" }}>You u have not verified your Email Id, kindly click on Verify Now to verify and get full access to your Dashboard. <Link to="" onClick={sendemail} className='mx-4'>Verify Now <img src="/assets/images/share-icon.png" alt="" width="15px" /></Link> <i className='fa fa-close fs-6'></i></button>
                                    </div>
                                )}
                                {appState?.state?.user?.is_email_verified == 2 && (
                                    <div className="top-alert w-100 mb-3 pe-3">
                                        <button className='w-100 fs-6 ' style={{ background: "#FDD" }}>We have sent email verification link to your email id, Please verify your email to access the Dashboard and if you have not received the email then kindly resend the email by Clicking<Link to="" onClick={sendemail} className='mx-4'>Verify Now <img src="/assets/images/share-icon.png" alt="" width="15px" /></Link> <i className='fa fa-close fs-6'></i></button>
                                    </div>
                                )}
                                {(appState?.state?.user?.is_email_verified == 1 && location?.state?.message) && (
                                    <div className="top-alert w-100 mb-3 pe-3">
                                        <button className='w-100 fs-6 ' style={{ background: "green" }}>Thank You Your Emailis verified succesfully!</button>
                                    </div>
                                )}

                                {/* Right Section1  */}
                                <div className="hpoints-graph  pb-4 d-flex justify-content-between align-items-center">
                                    <div className="col-lg-8 d-flex justify-content-between">
                                        <div className="card px-3 py-3 border-0 rounded-4" style={{ background: "#FFB5B5" }} >
                                            <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                            <h5 className="title">Wishlist</h5>
                                            <p>Last week added</p>
                                            <span className="digit">02</span>
                                            <span className="percent">₹1200</span>
                                        </div>
                                        <div className="card px-3 py-3 border-0 rounded-4" style={{ background: "#CEC7FF" }} >
                                            <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                            <h5 className="title">Inquiries</h5>
                                            <p>Last week</p>
                                            <span className="digit">1.2k</span>
                                            <span className="percent">+15.4%</span>
                                        </div>
                                        <div className="card px-3 py-3 border-0 rounded-4" style={{ background: "#FFE4C5" }} >
                                            <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                            <h5 className="title">Help & Support</h5>
                                            <p>Live Chat</p>
                                            <p>Raise Ticket</p>
                                            <p>FAQ</p>
                                        </div>


                                        <div className="card px-3 py-3  border-0 rounded-4" style={{ background: "#CBD7FF" }} >
                                            <img src="/assets/images/product-d.png" alt="" width={"39px"} />
                                            <h5 className="title">Settings</h5>
                                            <Link to="">Edit Profile</Link>
                                            <Link to="">Change Password</Link>
                                            <Link to="">Deactivate account</Link>
                                        </div>
                                    </div>

                                    {/* Graph ^^^^^^^^ */}
                                    <div className=" mx-3 rounded-4 px-3 py-3 bg-white graph d-flex gap-4">
                                        <div className="graph-content">
                                            <div className='position-relative user-photo'>
                                                <img src="/assets/images/profile-img.png" alt="" width={"86px"} />
                                                <span className='position-absolute'> <i className='fa fa-camera fs-5'></i></span>

                                            </div>
                                            <h5 className='name fs-5 mt-2'>John Dexchlo</h5>

                                        </div>

                                        <div className="bar-chart">
                                            <p className='fs-5 '>Profile status</p>
                                            <img src="/assets/images/profile-pi-chart.png" alt="" />
                                            {/* <Bar data={data} options={options} /> */}
                                        </div>
                                    </div>
                                </div>

                                {/* Right Section2  */}

                                <div className="right-sec2 py-4 d-flex justify-content-between ">
                                    <div className="col-lg-8 bg-white px-3 py-3 rounded-4">
                                        <div className="overfx-div">
                                        <div className="top-btns d-flex gap-2 mb-3" >
                                            <button className='active-db-btn'>Recent Inquiry</button>
                                            <button>Follow-Ups</button>
                                            <button>Confirmed</button>
                                            <button>Not Interested</button>
                                        </div>
                                        </div>
                                        <div className="table-div" style={{ overflowX: "auto" }}>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Date & Time</th>
                                                        <th scope="col">Customer Name</th>
                                                        <th scope="col">Email ID</th>
                                                        <th scope="col">Mobile Number</th>
                                                        <th scope="col">Location</th>
                                                        <th scope="col">Product & Service</th>
                                                        <th scope="col">other1</th>
                                                        <th scope="col">other2</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>
                                                    <tr>
                                                        <td>2008/11/28  10:3</td>
                                                        <td>Cedric Kelly</td>
                                                        <td>cedric@gmail.com</td>
                                                        <td>+91-898989899</td>
                                                        <td>Delhi</td>
                                                        <td>Perfume</td>
                                                        <td>other1</td>
                                                        <td>other 2 se</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="chat-box bg-white px-3 py-3 rounded-4">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h3>Chats</h3>
                                            <Link to="#" onClick={() => setViewChat(true)}>View all chats</Link>
                                        </div>
                                        <div className="searchbar py-2 position-relative">
                                            <input type="text" placeholder='Search...' />
                                            <span><i className='fa fa-search'></i></span>
                                        </div>
                                        <div className="users-chat py-2">
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>
                                            <div className="d-flex py-2 justify-content-between align-items-center">
                                                <div className="d-flex gap-3 align-items-center">
                                                    <img className='rounded-circle' src="/assets/images/profile-img.png" alt="" width="33px" />
                                                    <p className="name mb-0 fs-6 text-secondary">Morgan</p>
                                                </div>
                                                <div className="available pe-3">
                                                    <p className='mb-0 text-secondary'>11:00 am</p>
                                                    <span className=''></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>


                                {/* Right Section3 ^^^^^^^^^^^^^^^^^^^*/}
                                <div className="right-sec3 ">

                                    <div className=" d-flex justify-content-between">


                                        {/* WishList ------------ */}

                                        <div className="col-lg-5 active-products wishlist px-lg-4 px-3 py-lg-4 py-3 bg-white rounded-4">
                                            <div className="flex justify-content-between align-items-center">

                                                <h3>Wishlist</h3>
                                                <Link to="" className='fs-6 text-secondary link-underline-secondary'>View All</Link>
                                            </div>

                                            <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                                <img src="/assets/images/jacket.png" alt="" />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between">
                                                        <div>
                                                            <span className="pd-type">Jackets</span>
                                                            <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>

                                                        <div className='text-danger position-absolute end-0 fs-6 fw-medium'>X</div>

                                                    </div>
                                                    <div className="d-flex py-2 justify-content-between align-items-center">
                                                        <p className="price mb-0"> <span>₹</span>254.99</p>
                                                        <Link to="" className='fs-5 text-secondary'> Contact Seller</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                                <img src="/assets/images/jacket.png" alt="" />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between">
                                                        <div>
                                                            <span className="pd-type">Jackets</span>
                                                            <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>

                                                        <div className='text-danger position-absolute end-0 fs-6 fw-medium'>X</div>

                                                    </div>
                                                    <div className="d-flex py-2 justify-content-between align-items-center">
                                                        <p className="price mb-0">₹254.99</p>
                                                        <Link to="" className='fs-5 text-secondary'> Contact Seller</Link>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex gap-4 position-relative  my-2 align-items-center">
                                                <img src="/assets/images/jacket.png" alt="" />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between">
                                                        <div>
                                                            <span className="pd-type">Jackets</span>
                                                            <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>

                                                        <div className='text-danger position-absolute end-0 fs-6 fw-medium'>X</div>

                                                    </div>
                                                    <div className="d-flex py-2 justify-content-between align-items-center">
                                                        <p className="price mb-0">₹254.99</p>
                                                        <Link to="" className='fs-5 text-secondary'> Contact Seller</Link>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>


                                        {/* ------Calendar---- -------------------*/}
                                        <div className="col-lg-6 analystics px-3 py-3 bg-white rounded-4">
                                            {/* <h3>Calendar</h3> */}
                                            <CustomCalendar />

                                        </div>


                                    </div>

                                    {/* Recent Product &services search */}
                                    <div className=" mt-lg-5 mt-3 mb-lg-4 mb-3  d-flex justify-content-between">

                                        {/* left  */}
                                        <div className="col-lg-5 recp-search  px-lg-4 px-3  py-3 bg-white rounded-4">
                                            <h3>Recent Product Search</h3>
                                            <div className="d-flex gap-4 position-relative  py-3 align-items-center">
                                                <img src="/assets/images/jacket.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between">
                                                        <div>
                                                            <span className="pd-type">Jackets</span>
                                                            <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>
                                                        <div className=' view text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="d-flex gap-4 position-relative  py-3 align-items-center">
                                                <img src="/assets/images/jacket.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between ">
                                                        <div>
                                                            <span className="pd-type">Jackets</span>
                                                            <h5 className="title">Adidas Z.N.E. Fast Release Hoodie</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>

                                                        <div className=' view text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {/* right ----- */}
                                        <div className="col-lg-6 recs-search  px-lg-4 px-3  py-3 bg-white rounded-4">
                                            <h3>Recent Services Search</h3>
                                            <div className="d-flex gap-4 position-relative  py-3 align-items-center">
                                                <img src="/assets/images/rec-service.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between">
                                                        <div>
                                                            <span className="pd-type">UI/UX</span>
                                                            <h5 className="title">Graphics Designer</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>
                                                        <div className='text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="d-flex gap-4 position-relative  py-3 align-items-center">
                                                <img src="/assets/images/jacket.png" alt="" style={{ width: "85px", borderRadius: "0.6rem" }} />

                                                <div className="product-content">
                                                    <div className="d-flex gap-3 justify-contnet-between ">
                                                        <div>
                                                            <span className="pd-type">UI/UX</span>
                                                            <h5 className="title">Graphics Designer</h5>
                                                            <div className="stars-box ">
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                                                                <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span>
                                                            </div>
                                                        </div>

                                                        <div className='text-danger position-absolute pt-4  end-0 fs-6 fw-medium'> <img src="/assets/images/eye-d.png" alt="" /></div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="alert-box gap-3 d-flex mt-4 ">
                                        <button>Your Last Successful Login was 12-11-2023 3:00 PM | Location: Mumbai, India <span><i className='fa fa-close ps-2 fs-6'></i></span></button>
                                        <button style={{ background: "#FFDDDD" }}> Your Last Successful Login was 12-11-2023 3:00 PM | Location: Mumbai, India <i className='fa fa-close ps-2 fs-6'></i></button>
                                    </div>
                                </div>



                            </div>) : (
                                <Chat />

                            )}

                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer />
        </Page>
    );
};
export default Dashboard;
