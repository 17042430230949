import React from "react";
import { Carousel } from 'react-responsive-carousel';
import Dropdown from 'react-bootstrap/Dropdown';
import Slider from "react-slick";
import Form from 'react-bootstrap/Form';
import { ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

const BuySell = () => {

    const slider = [
        { id: 1 }, { id: 2 }, { id: 3 }
    ];

    const Category = [
        {
            img: './assets/images/category-icon.png',
            name: 'For Children',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'Realistic',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'Comic & Cartoon',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'Anime & mango',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'Flat',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'Line Art',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'For Children',
        },
        {
            img: './assets/images/category-icon.png',
            name: 'Realistic',
        }
    ]

    const Products = [
        {
            productImg: './assets/images/product-img.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img-2.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img-3.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img-4.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img-2.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img-4.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        },
        {
            productImg: './assets/images/product-img-3.png',
            sellerImg: './assets/images/seller-img.png',
            sellerName: 'John Flix',
            sellerRating: '#4 Level Seller',
            sellerWords: 'I will do anything in adobe illustration',
            productRating: '3.9',
            totalRating: '208',
            price: "$1299",
            offerPrice: "$999"
        }
    ]


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1
    };

    return (
        <div>
            <div className="menu-cont">
                <div className="row">
                    <div className="col-md-6">
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            interval={2500}
                            showStatus={false}
                            showArrows={false}
                            showIndicators={false}
                            infiniteLoop={true}
                        >
                            {slider.map((data) => (
                                <div><img src="assets/images/banner_interiordesigners 1.png" height={230} /></div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-md-6 row">
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 2.png" /></div>
                        </div>
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 3.png" /></div>
                        </div>
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 4.png" /></div>
                        </div>
                        <div className="col-md-3">
                            <div className="banner-card-img"><img src="assets/images/Frame 5.png" /></div>
                        </div>
                    </div>
                </div>
                <div className="digital-category">
                    <h1>Select Category</h1>
                    <div className="houzey-mt-1 hide-in-mobile" id="our-client-slider" >
                        <Slider {...settings}>
                            {Category.map((item, index) => (
                                <div className="cursor-pointer container-fluid">
                                    <div className="Data-And-Form-Container">
                                        <div className="Form-Content-Container">
                                            <img src={item.img} />
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>

                <div className="dropdown-section">
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Location
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Category
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Budgets
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                            Sorting
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="ad-verified-btn">
                        <button >Ad Verified</button>
                    </div>
                    <div className="ad-verified-btn">
                        <button>Ad Trust</button>
                    </div>
                    <div className="post-free-btn">
                        <button>Post Free Classified</button>
                    </div>
                </div>

                <div>
                    <div className="row">
                        {Products.map((item, index) => (
                            <div className="col-md-3 digital-product-section">

                                <div className="digital-product-section-border">
                                    <img src={item.productImg} />
                                    <div className="paddind-1">
                                        <div className="seller-section">
                                            <div className="seller-info">
                                                <img src={item.sellerImg} />
                                                <div>
                                                    <h6>{item.sellerName}</h6>
                                                    <h5>{item.sellerRating}</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <p className="current-date">12-04-2023</p>
                                                <p className="number-of-view">8888  <img src="./assets/images/grey-eye.png" /></p>
                                            </div>
                                        </div>
                                        <p>{item.sellerWords}</p>
                                    </div>
                                    <hr />
                                    <div className="price-section paddind-1">
                                        <div>
                                            <h3>{item.offerPrice}</h3>
                                        </div>
                                        <div>
                                            <img src="./assets/images/grey-heart.png" width="25px" />
                                        </div>
                                    </div>
                                    <div>
                                        <button className="chat-now-btn"> <img src="./assets/images/white-sms-icon.png" />Chat Now</button>
                                        <button className="call-now-btn"> <img src="./assets/images/white-phone-icon.png" />Call Now</button>
                                    </div>
                                    <div className="send-email-btn2">
                                        <button> <img src="./assets/images/email-icon.png" />Send Email</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BuySell;