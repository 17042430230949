import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { NavLink } from "react-router-dom";

import Page from "../components/Page";
import { Button } from "react-bootstrap";


const Conversations = () => {
  return (
    <Page title="Conversations">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Conversations</li>
            </ul>
            <div className="col-md-12">
            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
              </div>
            {/* <div>
              <Button className="toggle-button" variant="warning">
                Seller Info
              </Button>
            </div> */}
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Conversations</h4>
                <div className="vendor-order-section">
                  <div className="card-block">
                    <div className="order-head-blk">
                      <h5>Conversations</h5>
                    </div>
                    <div className="card-body-blk">
                      
                        Conversations
                      
                    </div>
                  </div>
                </div>

              
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};
export default Conversations;
