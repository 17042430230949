import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import CreateTicket from "../components/ticket-modal/create-ticket.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { BASE_URL, IMAGE_BASE_URL } from "../config";
import { Table, Button, Badge } from "react-bootstrap";
import ViewTickets from "./VIewTickets";

const SupportTicket = () => {
  const [ticket, setTicket] = useState([]);
  const [subject, setSubject] = useState([])
  const [showAdd, setShowAdd] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(false);
  const [ticketDetailsData, setTicketDetailsData] = useState({});
  const token = localStorage.getItem('avonToken');

  function handleAdd() {
    setShowAdd(true);
  }

  function handleTicketDetails(data) {
    setTicketDetails(true);
    setTicketDetailsData(data);
  }

  useEffect(() => {
    fetchTickets();
    fetchSubject();
  }, [])

  const fetchTickets = async () => {
    await axios.get(BASE_URL + `/getticket`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      // setamount(data.amount)
      setTicket(data.data)
    })
  }

  const fetchSubject = async () => {
    await axios.post(BASE_URL + `/getticketsubject`, {
      type: "customer"
    }, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      // setamount(data.amount)
      setSubject(data.data && data.data)
    })
  }

  console.log(ticket, "Tickets");
  
  return (
    <Page title="Support Ticket">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Support Ticket</li>
            </ul>
            <div className="col-md-12">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>
                  Support Ticket
                  <Button
                    onClick={handleAdd}
                    style={{ float: "right", backgroundColor: "#00B987" }}
                    variant="success"
                  >
                    Support Ticket
                  </Button>
                </h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h5>Tickets</h5>
                    </div>
                    <div className="card-body-blk">
                      <Table responsive="md" hover>
                        <thead>
                          <tr>
                            <th>Date & Time</th>
                            <th>Ticket ID</th>
                            {/* <th>Uploaded Image</th> */}

                            <th>Subject</th>
                            <th>Message</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>

                          {ticket && ticket?.map((item, index) => (
                            // <p>{item.id}</p>
                            <tr key={item.index}>
                              <td>{item.created_at}</td>
                              <td>
                                {item.code}
                              </td>
                              {/* <td><img src={IMAGE_BASE_URL + "/" + item?.ticketreplies?.files} width="30px"/></td> */}

                              <td>{item.subject?.subject}</td>
                              <td>{item.details}</td>
                              <td>
                                <Badge bg="success">{item.status}</Badge>
                              </td>
                              <td className="text-center"> <i class="fa fa-eye" style={{ fontSize: "16px" }}
                                onClick={() => handleTicketDetails(item)}
                              ></i></td>
                              {/* <td dangerouslySetInnerHTML={{ __html: item?.ticketreplies?.reply }}>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      
      <CreateTicket showAdd={showAdd} setShowAdd={setShowAdd} subjects={subject && subject} />
      <ViewTickets showAdd={ticketDetails} setShowAdd={setTicketDetails} ticketDetailsData={ticketDetailsData} />
    </Page>
  );
};

export default SupportTicket;
