
import Actions from "./partial/Action"
import CalendarComponent from "./partial/CalendarComp"
import Title from "./partial/Title"
// import { prop } from "cheerio/lib/api/attributes"
const CustomCalendar = (props) => {
  
    const { setDate, date, selectRange, setSelectRange } = props

  return (
    <div style={{ display: "grid", placeItems: "center" }}>
      {/* <Title title={"Calendar"} /> */}
      <CalendarComponent
        setDate={setDate}
        date={date}
        selectRange={selectRange}
      />
      {/* <Actions
        setDate={setDate}
        date={date}
        selectRange={selectRange}
        setSelectRange={setSelectRange}
      /> */}
    </div>
  )
}

export default CustomCalendar
