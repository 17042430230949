import React from 'react'
import "../../style/Investor.css";

export const InvestorBanner = () => {
  return (
    <>
      <div className="investor-banner">

        <div className="container d-flex justify-content-between align-items-center">

          <div className="left">
            <h2>Invest Together</h2>
            <div className="my-lg-3 my-md-3 my-2 d-flex gap-2 align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
             &nbsp;  &nbsp; <p className="mb-0">Invest in startups</p>
            </div>
            <div className="my-lg-3 my-md-3 my-2 d-flex gap-2 align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
              &nbsp;  &nbsp;<p className="mb-0">Co-invest in marquee investor</p>
            </div>
            <div className="my-lg-3 my-md-3 my-2 d-flex gap-2 align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>
              &nbsp;  &nbsp;<p className="mb-0">Co-invest in marquee investor</p>
            </div>

            <div className="mt-4  btns d-flex gap-lg-3 gap-md-3 gap-sm-3 gap-2">
              <button>Invest in Startup</button>&nbsp;  &nbsp;
              <button>Start a syndicates</button>
            </div>
          </div>

          <div className="right">
              <img src="/assets/images/grow-arrow.png" alt="" />
          </div>

        </div>
      </div>

    </>
  )
}
