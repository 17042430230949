import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Page from '../../components/Page';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL, DOMAIN_NAME } from '../../config';

const BlogDetail = () => {

    const { slug } = useParams('slug');
    console.log('slug', slug)
    const [blog, setBlog] = useState();
    const [blogImage, setBlogImage] = useState();

    useEffect(() => {
        FetchBlog();
    }, [])

    const FetchBlog = async () => {

        await axios.get(BASE_URL + `/seller/blog/${slug}`, {
            headers: {
                'Accept': 'application/json',
                'domainname':DOMAIN_NAME
            }
        }).then(({ data }) => {
            setBlog(data.data)
            setBlogImage(data.image_base_url)
        })
    }

    const baseImage = blogImage && blogImage
    const blogImg = blog && blog.image

    return (
        <Page title="Blog Detail">
            <div className="inner-pages-wrapper">
                <section className="breadcrumb-sction">
                    <div className="container-fluid">
                        <ul className="breadcrumb-block">
                            <li>
                                <NavLink to="/">Home</NavLink>
                            </li>
                            <li>Blog Details</li>
                        </ul>
                    </div>
                </section>
                <section className="about-cont-block blog_area single-post-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-9 posts-list">
                                <div className="single-post">
                                    <div className="feature-img">
                                        <img className="img-fluid" src={baseImage+blogImg} alt="" style={{width:'100%', maxHeight:'400px'}} />
                                    </div>
                                    <div className="blog_details">
                                        <h2>{blog && blog.title}</h2>
                                        {blog && blog.content}
                                    </div>
                                </div>

                            </div>
                            <div className="col-lg-3">
                                <div className="blog_right_sidebar">
                                    <aside className="single_sidebar_widget popular_post_widget">
                                        <h3 className="widget_title">Recent Post</h3>
                                        <div className="media post_item">
                                            <img src="nd" alt="post" />
                                            <div className="media-body">
                                                <Link >
                                                    <h3>blog data</h3>
                                                </Link>
                                                <p>Recent Blog</p>
                                            </div>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Page>
    );
};

export default BlogDetail;
