import React, { useState } from "react";
import "../../style/BuildmyStartup.css";
import { Link } from "react-router-dom";

export const BuildmsCart = (props) => {
const[showContent, setShowContent] = useState(0)
  const arrowIcon = document.getElementsByClassName("arrow-icon");

  function removeActiveClass() {
    const tabButtons = document.getElementsByClassName("toggle-link");
    const spanElm = document.querySelectorAll(".toggle-link span")
    
    for (const lis of tabButtons) {
      lis.classList.remove("active-build-ms-stab");
    }

    for(const spans of spanElm) {
      spans.classList.remove("active-build-ms-stab")
    }

    // for (const arrow of arrowIcon) {
    //   arrow.style.display = "none";
    // }
  }

  const handleLinkTab = (event,index ) => {
    // console.log(event)
    // removeActiveClass();
    setShowContent(index)
    // const clickedButton = event.target;
    // clickedButton.classList.add("active-build-ms-stab");

 
    
  };

  return (
    <>
      <div className="build-ms-chart my-lg-5 my-4">
        <div
          className="container d-flex justify-content-between flex-wrap ps-lg-4 ps-3  pe-lg-0 pe-3 pb-lg-0 pb-3 pe-0"
          style={{ backgroundColor: "#F6F6FE" }}
        >
          <div className="col-lg-3 col-12">
            <div className="rounded-div"></div>
            <div className="left-content">
              <ul className="ps-0">
                {props?.data && props?.data.map((item,i)=>(
                  <li
                  className={i == showContent ? "toggle-link d-flex align-items-center justify-content-between active-build-ms-stab" : "toggle-link d-flex align-items-center justify-content-between"}
                  onClick={(event)=> handleLinkTab(event, i)}
                  >
                  <span>{item.title}</span>{" "}
                  <span className="arrow-icon" style={{ color: "#555" }}>
                    <i class="fa fa-arrow-right"></i>
                  </span>
                  </li>
                ))}
               
               
              </ul>
              
            </div>
          </div>

          <div className="col-lg-9  col-12 text-center py-lg-5 py-3 ps-lg-5 ps-3 right-content">
          {props?.data && props?.data.map((item,i)=>(
            <>
            {showContent == i &&(
              <div dangerouslySetInnerHTML={{__html:item.content}}>
              
              </div> 
            )}
            </>
            
          
          ))}
          </div>
        </div>
      </div>
    </>
  );
};
