import React,{ useContext, useState } from 'react'
import  Axios  from 'axios';
import { BASE_URL } from '../config';
import DispatchContext from '../context/DispatchContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
const AdminLogin = () => {
    const appDispatch = useContext(DispatchContext);
    const navigate = useNavigate();
    const {link} = useParams();
    useEffect(()=>{
        adminlogin();
    },[link])
    const adminlogin = ()=>{
        Axios.post(BASE_URL + '/adminlogin', { 'email': atob(link),  }).then(({ data }) => {
            appDispatch({
              type: 'login',
              data: {
                token: data.data.token,
                name: data.data.user.name,
                email: data.data.user.email,
                mobileNumber: data.data.user.mobile_number,
                profileImage: data.data.user.profile_image,
                step: data.data.user.step,
                lastLogin: data.data.user.last_login,
              },
            });
            appDispatch({ type: 'flashMessage', value: 'Login Successful' });
            appDispatch({ type: 'setWishlistCount', value: data.data.wishlistCount });
              navigate('/dashboard');
          }).catch(({response})=>{
            console.log("-----------",response.data)
            if(response.data.status_code == "422"){
              alert(response.data.message.email);
              navigate('/sign-up')
            }
          });
    }
  return (
    <div>
      Please wait ...
    </div>
  )
}

export default AdminLogin
