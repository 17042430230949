import React,{useState,useEffect} from "react";
import "../../style/PostbuyRequirement.css"
import axios from "axios";
import { BASE_URL } from "../../config";
import { ToastContainer,toast } from "react-toastify";
export const PostbuyComp2 = (props) => {
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [isServiceClicked, setIsServiceClicked] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subcategorys, setsubcategorys] = useState([]);
  const [categoriesservices, setCategoriesservices] = useState([]);
  const [subcategorysservices, setsubcategorysservices] = useState([]);
  const [error, seterror] = useState([]);
  const [data, setData] = useState({
    name: null,
    email: null,
    mobile_number: null,
    country: null,
    state: null,
    city: null,
    category_id: null,
    subcategory_id: null,
    message: null,
    term_cond: null,
    type: "products",
  });
  useEffect(()=>{
    getCountry();
    FetchCategories();
    FetchCategoriesservices();
  },[])
  const handledata = (e) => {
    const { name, value,checked  } = e.target;
   setData((prevData) => ({
  ...prevData,
  [name]: name === "mobile_number" ? Number(value) :  value,
}));
  };
  const handleSubmit=()=>{
    seterror("");
    axios
      .post(BASE_URL + `/addtopratedsellerenquiry`, data)
      .then((res) => {
        if (res.status === 201) {
          toast.success("Send Successfully");
        document.getElementsByClassName("topratedform")[0].reset();
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
        
      });
  }
  const FetchCategories = async () => {
    await axios.get(BASE_URL + "/allcategories")
      .then(({ data }) => {
        setCategories(data.data);
      });
  };
  const FetchsubCategories = async (id) => {
    await axios.get(BASE_URL + "/getsubcategories/" + id).then(({ data }) => {
      setsubcategorys(data.data);
    });
  };
  const FetchCategoriesservices = async () => {
    await axios.get(BASE_URL + "/allcategoriesservices")
      .then(({ data }) => {
        setCategoriesservices(data.data);
      });
  };
  const FetchsubCategoriesservices = async (id) => {
    await axios.get(BASE_URL + "/getsubcategoriesservices/" + id).then(({ data }) => {
      setsubcategorysservices(data.data);
    });
  };

  const getCountry = () => {

    axios.get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const handleservice=(type)=>{
    setIsServiceClicked(type)
    if(type === true){
      setData({type:"services"})
    }else{
      setData({type:"products"})
    }
    
  }
  return (
    <>
      <div className="post-buy-comp-2nd my-lg-5 my-md-4 my-3">
        <div className="container d-flex flex-lg-row flex-column justify-content-between ">
          <div className="col-lg-6 left mt-lg-5 content-justify mr-2" dangerouslySetInnerHTML={{__html:props.data.widget3}}>
            
          </div>

          {/* Right ------------------  */}
          <div className="col-lg-6 right-form">
            {/* <div dangerouslySetInnerHTML={{__html:props.data.widget2}}> */}
            <div className="form-heading-top">
              <p>Connect with Top rated Sellers only at APNASHAHER.COM</p>
            </div>
           

            <form action="" className="topratedform">
            <div className="links mt-0 ">
             
             <button onClick={()=>handleservice(false)} style={{backgroundColor:isServiceClicked === true ? "#FFAE00" : ""}}>Product</button>
             <button onClick={()=>handleservice(true)} style={{backgroundColor:isServiceClicked === false ? "#FFAE00" : ""}}>Services</button>
           </div>
              
                <div className="links">
                <input type="text" placeholder="Full Name" name="name" onChange={handledata} />
                {error && error.name &&(
                <div className="text-danger">{error.name}</div>
              )}
                <input type="number" placeholder="Mobile Number" name="mobile_number" onChange={handledata} />
                {error && error.mobile_number &&(
                <div className="text-danger">{error.mobile_number}</div>
              )}
              </div>
              <div className="my-3">
                <input type="email" placeholder="Email ID" name="email" onChange={handledata} />
                {error && error.email &&(
                <div className="text-danger">{error.email}</div>
              )}
              </div>

              <div className="city-data ">
                <select class="form-select" aria-label="Default select example" name="country" onChange={(e) => { handledata(e); getState(e.target.value) }}>
                  <option value="">Select Country</option>
                  {fetchCountry && fetchCountry.map((item,i)=>(
                    <option value={item.id}>{item.name}</option>
                  ))}
                  
                  
                </select>
                {error && error.country &&(
                <div className="text-danger">{error.country}</div>
              )}
                <select class="form-select" aria-label="Default select example" name="state" onChange={(e) => { handledata(e); getCity(e.target.value) }}>
                  <option value="">Select State</option>
                  {fetchState && fetchState.map((item,i)=>(
                    <option value={item.id}>{item.name}</option>
                  ))}
                  
                  
                </select>
                {error && error.state &&(
                <div className="text-danger">{error.state}</div>
              )}
                <select class="form-select" aria-label="Default select example" name="city" onChange={(e) => { handledata(e); }}>
                  <option value="">Select City</option>
                  {fetchCity && fetchCity.map((item,i)=>(
                    <option value={item.id}>{item.name}</option>
                  ))}
                  
                  
                </select>
                {error && error.city &&(
                <div className="text-danger">{error.city}</div>
              )}
              </div>
              {isServiceClicked === false &&(
              <div className=" links mb-3">
              <select class="form-select" aria-label="Default select example" name="category_id" onChange={(e) => { handledata(e); FetchsubCategories(e.target.value) }}>
                  <option selected>Select Category</option>
                  {categories && categories.map((item,i)=>(
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                {error && error.category_id &&(
                <div className="text-danger">{error.category_id}</div>
              )}
                <select class="form-select" aria-label="Default select example" name="subcategory_id" onChange={(e) => { handledata(e) }}>
                  <option selected>Select Sub-Category</option>
                  {subcategorys && subcategorys.map((item,i)=>(
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                {error && error.subcategory_id &&(
                <div className="text-danger">{error.subcategory_id}</div>
              )}
              </div>
              )}
              {isServiceClicked === true &&(
              <div className=" my-3 d-flex gap-3 justify-contnet-between align-items-center">
              <select class="form-select" aria-label="Default select example" name="category_id" onChange={(e) => { handledata(e); FetchsubCategoriesservices(e.target.value) }}>
                  <option selected>Select Category</option>
                  {categoriesservices && categoriesservices.map((item,i)=>(
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                {error && error.category_id &&(
                <div className="text-danger">{error.category_id}</div>
              )}
                <select class="form-select" aria-label="Default select example" name="subcategory_id" onChange={(e) => { handledata(e) }}>
                  <option selected>Select Sub-Category</option>
                  {subcategorysservices && subcategorysservices.map((item,i)=>(
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
                {error && error.subcategory_id &&(
                <div className="text-danger">{error.subcategory_id}</div>
              )}
              </div>
              )}

              <div class="form-floating">
                <textarea
                  className="px-3 py-3 w-100"
                  name="message"
                  id=""
                  cols="60"
                  rows="6"
                  placeholder="Write Message..."
                    onChange={handledata}
                ></textarea>
                {error && error.message &&(
                <div className="text-danger">{error.message}</div>
              )}
              </div>
              <div className="my-3 d-flex flex-row  ">
                <input className="mr-2 " type="checkbox" name="term_cond"  onChange={handledata} />
               
                <p className="mb-0 fs-8 accept-terms">
                  Accept & Acknowledge that my contact information will be
                  shared with the registered sellers at APNASHAHER.COM
                </p>
                
              </div>
              {error && error.term_cond &&(
                <div className="text-danger">{error.term_cond}</div>
              )}
             

              <div className="my-3">
              <button className="submit-button" type="button" onClick={handleSubmit}>Submit</button>
              <p className="imp-text mt-3 text-center">
              Once you post the inquiry you will receive a call from the Expert to understand your requirements
              </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
