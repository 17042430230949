import React from 'react';
import { Link } from 'react-router-dom';
import { MobileFooter } from '../../apna-shaher/components/MobileFooter';

const FooterPage = () => {

    const handleClick = (event) => {
        event.preventDefault();
        window.scrollTo(0, 0);
    };

    return (
        <>
            <MobileFooter />
            <div className='container-fluid footer-bg mt-6'>
                <div className='container footer-section pd-6'>
                    <h1>Popular Categories</h1>
                    <ul className='popular-category'>
                        <li>Artificial Plant Dealer | </li>
                        <li>Artificial Plant Dealer | </li>
                        <li>Artificial Plant Dealer | </li>
                        <li>Artificial Plant Dealer | </li>
                    </ul>
                    <hr />
                    <h1>Popular Cities</h1>
                    <ul className='popular-category'>
                        <li>Delhi | </li>
                        <li>Mumbai | </li>
                        <li>Bihar | </li>
                        <li>Noida | </li>
                    </ul>
                    <hr />
                    <h2 className='social-hide-on-desktop'>Follow us :
                            <span className='mr-2 ml-4'><img src="/assets/Icons/facebook-1.svg" /></span>
                            <span className='mr-2'><img src="/assets/Icons/insta-1.svg" width={"20px"} /></span>
                            <span className='mr-2'><img src="/assets/Icons/youtube-1.svg" width={"20px"} /></span>
                            <span className='mr-2'><img src="/assets/Icons/twitter-icon.png" width={"20px"} /></span>
                            <span className='mr-2'><img src="/assets/Icons/linkedIn-icon-3.png" width={"20px"} /></span>
                        </h2>
                    <div className='footer-cols'>
                      
                        <div>



                            <h2>Explore APNASHAHER</h2>
                            <ul className='footer-links'>
                                <li> <Link to="/products-page">Products</Link></li>
                                <li> <Link to="/services">Services</Link></li>
                                <li><Link to="/directory" >Business Directory</Link></li>
                                <li><Link to="/startup" scroll={true}>Startups</Link></li>
                            </ul>
                            <h2 className='social-hide-on-tab'>Follow us :
                                <span className='mr-2 ml-4'><img src="/assets/Icons/facebook-1.svg" /></span>
                                <span className='mr-2'><img src="/assets/Icons/insta-1.svg" width={"20px"} /></span>
                                <span className='mr-2'><img src="/assets/Icons/youtube-1.svg" width={"20px"} /></span>
                                <span className='mr-2'><img src="/assets/Icons/twitter-icon.png" width={"20px"} /></span>
                                <span className='mr-2'><img src="/assets/Icons/linkedIn-icon-3.png" width={"20px"} /></span>
                            </h2>
                        </div>
                        <div>
                            <h2>Buyer</h2>
                            <ul className='footer-links'>
                                <li><Link to="/post-buy-requirement">Request a Quote</Link></li>
                                <li><Link to="/customer-faq">FAQ</Link></li>
                                <li><Link to="/login">Login / Signup</Link></li>
                                <li><Link to="/">What's New</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h2>Seller</h2>
                            <ul className='footer-links'>
                                <li><Link to="/seller-benefits" scroll={true}>Seller Benefits</Link></li>
                                <li><Link to="/advertise-with-us" scroll={true}> Advertise with Us</Link></li>
                                <li><Link to="/login"> Login / Signup</Link></li>
                                <li><Link to="/seller-faq"> FAQ</Link></li>
                                <li><Link to="/">Learning Centre</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h2>Quick link</h2>
                            <ul className='footer-links'>
                                <li><Link to="/about-us-new">About Us</Link></li>
                                <li><Link to="/our-team">Our Team</Link></li>
                                <li><Link to="/partners-and-investors">Partners & Investors</Link></li>
                                <li><Link to="/join-our-team">Join our Team</Link></li>
                                <li><Link to="/success-story">Success Stories</Link></li>
                                <li><Link to="/blogs1">Blogs</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h2>Important Links</h2>
                            <ul className='footer-links'>
                                <li><Link to="/">Terms of Use</Link></li>
                                <li><Link to="/">Privacy Policy</Link></li>
                                <li><Link to="/">Refund & Cancellations</Link></li>
                                <li><Link to="/">Product Listing Policy</Link></li>
                                <li><Link to="/">Disclaimer</Link></li>
                                <li><Link to="/">Risk Policy</Link></li>
                                <li><Link to="/">Cookies Policy</Link></li>
                                <li><Link to="/">SiteMap</Link></li>
                                <li><Link to="/report-abuse">Report Abuse</Link></li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className='discalimer'>
                        <p><b>DISCLAIMER:</b> Information is provided for general purposes only. We do not guarantee accuracy or reliability. Use at your own risk. We are not liable for any loss or damage incurred.</p>
                    </div>
                    <hr />
                    <p className='copy-right mb-0'>© 2004-2023 Apnasaher.com. All rights reserved.</p>
                </div>
            </div>
        </>
    )
}

export default FooterPage;