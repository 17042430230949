import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { NavLink } from "react-router-dom";
import Loader from "../components/loader/Loader.component";
import Page from "../components/Page";
import { Row, Card, Button } from "react-bootstrap";
import WishOne from "../components/gallery/image6.svg";
import WishTwo from "../components/gallery/image3.svg";
import WishThree from "../components/gallery/image4.svg";
import WishFour from "../components/gallery/image5.svg";

const ProductWishlist = () => {

  return (
    <Page title="Wishlist">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Wishlist</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>
        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9 col-sm-12 col-xs-12">
                <h4>Wishlist</h4>
                <div style={{ paddingTop: "15px" }} className="row">
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="width50">
                      <Card className="wish-card-width mobile-margin-4">
                        <Card.Img
                          className="wish-card-img"
                          src={WishOne}
                        />
                        <Card.Body>
                          <Card.Text>
                            AgriPro 4 Stroke Water Pump 3 Inch APWP3
                          </Card.Text>
                          <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                          <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                            <div className="col-md-12 flex space-between">
                              <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                                <button className="actionbtn delete-btn">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                              <div className="col-md-9 nopadding">
                                <button className="add-cart-btn">ADD TO CART</button>
                              </div>
                            </div>
                          </Card.Footer>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="width50">
                      <Card className="wish-card-width mobile-margin-4">
                        <Card.Img
                          className="wish-card-img"
                          src={WishOne}
                        />
                        <Card.Body>
                          <Card.Text>
                            AgriPro 4 Stroke Water Pump 3 Inch APWP3
                          </Card.Text>
                          <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                          <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                            <div className="col-md-12 flex space-between">
                              <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                                <button className="actionbtn delete-btn">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                              <div className="col-md-9 nopadding">

                                <button className="add-cart-btn">ADD TO CART</button>
                              </div>
                            </div>
                          </Card.Footer>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>

                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="width50">
                      <Card className="wish-card-width mobile-margin-4">
                        <Card.Img
                          className="wish-card-img"
                          src={WishOne}
                        />
                        <Card.Body>
                          <Card.Text>
                            AgriPro 4 Stroke Water Pump 3 Inch APWP3
                          </Card.Text>
                          <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                          <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                            <div className="col-md-12 flex space-between">
                              <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                                <button className="actionbtn delete-btn">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                              <div className="col-md-9 nopadding">

                                <button className="add-cart-btn">ADD TO CART</button>
                              </div>
                            </div>
                          </Card.Footer>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12 col-xs-12">
                    <div className="width50">
                      <Card className="wish-card-width ">
                        <Card.Img
                          className="wish-card-img"
                          src={WishOne}
                        />
                        <Card.Body>
                          <Card.Text>
                            AgriPro 4 Stroke Water Pump 3 Inch APWP3
                          </Card.Text>
                          <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                          <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                            <div className="col-md-12 flex space-between">
                              <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                                <button className="actionbtn delete-btn">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              </div>
                              <div className="col-md-9 nopadding">

                                <button className="add-cart-btn">ADD TO CART</button>
                              </div>
                            </div>
                          </Card.Footer>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  {/* <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishTwo}
                      />
                      <Card.Body>
                        <Card.Text>
                          Honda 850 VA Handy Series Portable Generator EP 1000
                        </Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishThree}
                      />
                      <Card.Body>
                        <Card.Text>
                          Metabo SSD 18 LTX 200 18V Cordless Impact Screw Driver
                        </Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishFour}
                      />
                      <Card.Body>
                        <Card.Text>PowerHouse 355 mm Chopsaw PHCM355</Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                <div style={{ paddingTop: "20px" }} className="row">
                  <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishOne}
                      />
                      <Card.Body>
                        <Card.Text>
                          AgriPro 4 Stroke Water Pump 3 Inch APWP3
                        </Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishTwo}
                      />
                      <Card.Body>
                        <Card.Text>
                          Honda 850 VA Handy Series Portable Generator EP 1000
                        </Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishThree}
                      />
                      <Card.Body>
                        <Card.Text>
                          Metabo SSD 18 LTX 200 18V Cordless Impact Screw Driver
                        </Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="width50">
                    <Card className="wish-card-width mobile-margin-4">
                      <Card.Img
                        className="wish-card-img"
                        src={WishFour}
                      />
                      <Card.Body>
                        <Card.Text>PowerHouse 355 mm Chopsaw PHCM355</Card.Text>
                        <Card.Text>$1605 <span style={{ color: 'rgba(255, 184, 0, 1)', paddingLeft: '8px', textDecoration: 'line-through' }}>$4312</span></Card.Text>
                        <Card.Footer style={{ background: 'none', paddingBottom: 0, paddingTop: 12 }}>
                          <div className="col-md-12 flex space-between">
                            <div className="col-md-3 col-xs-3 col-sm-3 nopadding">
                              <button className="actionbtn delete-btn">
                                <i className="fas fa-trash-alt"></i>
                              </button>
                            </div>
                            <div className="col-md-9 nopadding">

                              <button className="add-cart-btn">ADD TO CART</button>
                            </div>
                          </div>
                        </Card.Footer>
                      </Card.Body>
                    </Card>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default ProductWishlist;
