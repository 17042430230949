import React from 'react';
import Loader from './loader/Loader.component';
import ProductCarouselContainer from './product-carousel/product-carousel-container.component';
import ProductStarRating from '../components/product-star/product-star-rating.component';
import PurseImg from '../components/product/purseimg.svg';
import ShoeImg from '../components/product/shoeimg.svg';
import ClockImg from '../components/product/clockimg.svg';
import DongleImg from '../components/product/dongleimg.svg';
import TorchImg from '../components/product/torchimg.svg';


const RecentlyViewedProductSection = ({ isLoading, product, recentlyViewedProducts }) => {
  const d = localStorage.getItem("recentview");
  
   const d1 = d ? JSON.parse(d) : [];
   console.log(d1)
  return (
    <div className="container-fluid mt-3">
      <div className="feature-product-blk">
        <div className="feature-blkpro-header">
          <h4>Featured Product</h4>
        </div>
        {isLoading ? <Loader /> : <ProductCarouselContainer products={d1} />}
        {/* {isLoading ? <Loader /> : 
        <div className="recently-viewed-section">
          <div className="row">
            <div className='col-md-2'>
               <img src={PurseImg} alt="" />
               <h6 style={{textAlign: 'center', paddingTop: '13px'}}>White Valise</h6>
               <div style={{display: 'flex', justifyContent: 'center'}}>
               <ProductStarRating />
               
               </div>
                    <div style={{display:'flex', justifyContent:'center'}}>
                <p style={{color: 'gray', fontSize: '17px', textDecoration:'line-through', marginTop: '3px',}}>$ 230</p>
                <p style={{color: '#DE2828', fontSize: '20px',  marginLeft: 25}}>$ 230</p>
                </div>
            </div>
          </div>
          </div>
          } */}
      </div>
    </div>
  );
};

export default RecentlyViewedProductSection;
