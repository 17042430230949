import React, { useContext, useEffect, useState } from 'react';

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import Axios from 'axios';

import { Container, Row, Col, Tabs, Tab, Button, Modal, ModalHeader, ModalBody, Form } from 'react-bootstrap';

import Page from '../../../components/Page';
import Loader from '../../../components/loader/Loader.component';
import ProductStarRating from '../../../components/product-star/product-star-rating.component'
import ProductCarouselContainer from '../../../components/product-carousel/product-carousel-container.component';
import ProductListVertical from '../../../components/product/product-list-vertical.component';
import StateContext from '../../../context/StateContext';
import ProductDetailCarousel from '../../../components/product-detail/image-carouser.component';
import DispatchContext from '../../../context/DispatchContext';
import ReactStars from 'react-stars';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import DealPotraitItem from '../../../components/deal/deal-potrait-item.component';
import { IMAGE_BASE_URL } from '../../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../../config';
import { faClose, faEnvelope, faL, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon
} from "react-share";
const data = [{ id: 1, productName: 'Product Name', mrp: '50.00', discount: '50.00' }, { id: 2, productName: 'Product Name', mrp: '50.00', discount: '50.00' }, { id: 3, productName: 'Product Name', mrp: '50.00', discount: '50.00' }]
const TruncHtml = require('trunc-html');

const ProductsDetails = (props) => {
    console.log(props, "details products");
    const shareurl = window.location.href;
    const appDispatch = useContext(DispatchContext);
    const appState = useContext(StateContext);
    const { deviceid, recentlyViewedProducts, setRecenltyViewedProducts, setProductSuggestions, flashDealPotrait, currencysymbol } = useContext(StateContext);
    const { slug } = useParams('slug');
    const token = localStorage.getItem('avonToken');
    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [productDetail, setProductDetail] = useState({});
    const [reviews, setReviews] = useState([]);
    const [relatedProducts, setRelatedProduct] = useState([]);
    const [topSellingProducts, setTopSellingProducts] = useState([]);
    const [seller, setseller] = useState('');
    const [variantdata, setvariantdata] = useState('');
    const [showModal, setShowmodal] = useState(false)
    const baseurl = Axios.defaults.baseURL;
    const [errors, setErr] = useState([])
    const [fetchCountry, setFetchedCountry] = useState([]);
    const [fetchState, setFetchedState] = useState([]);
    const [fetchCity, setFetchedCity] = useState([]);
    const [name, setname] = useState("")
    const [email, setemail] = useState("")
    const [mobile_number, setmobile_number] = useState("")
    const [address, setaddress] = useState("")
    const [country, setcountry] = useState("")
    const [state, setstate] = useState("")
    const [city, setcity] = useState("")
    const [gender, setgender] = useState("")
    const [zipcode, setzipcode] = useState("")
    const [password, setpassword] = useState("")
    const [variantid, setvariantid] = useState("")
    const [businesspofile, setbusinesspofile] = useState("")
    const [password_confirmation, setpassword_confirmation] = useState("")
    const navigate = useNavigate();
    console.log('productDetails', productDetail)
    const ratingChanged = (newRating) => {
        console.log(newRating)
    }
    const [bulkPrice, setbulkPrice] = useState(false)

    useEffect(() => {
        getCountry();
        const categoryIds = recentlyViewedProducts.map((recentlyViewedProduct) => {
            return recentlyViewedProduct.category_id;
        });
        const productRequest = Axios.CancelToken.source();
        async function fetchProductDetail() {
            try {
                setIsLoading(true);
                const response = await Axios.post(`/product/${slug}`, { categoryIds }, { cancelToken: productRequest.token });
                const linkData = response.data;
                const responseData = linkData.data;
                setbusinesspofile(responseData.businessprofile)
                setProduct(responseData.product);
                setProductDetail(responseData.product_detail)
                setseller(responseData.seller);
                setReviews(responseData.product_reviews);
                setRelatedProduct(responseData.related_products);
                setTopSellingProducts(responseData.top_selling_products);
                addRecentViewedProduct(recentlyViewedProducts, responseData.product)
                fetchvariantdata(responseData.product_detail && responseData.product_detail[0].variant);
                setvariantid(responseData.product_detail && responseData.product_detail[0].id)
                // setRecenltyViewedProducts(addRecentViewedProduct(recentlyViewedProducts, responseData.product));
                setProductSuggestions(responseData.suggestion_products);
                setIsLoading(false);
            } catch (e) { }
        }
        fetchProductDetail();
        return () => {
            productRequest.cancel();
        };
    }, [slug]);

    let [count, setCount] = useState(0);

    const addRecentViewedProduct = (recentlyViewedProducts, productToAdd) => {
        // localStorage.removeItem("recentview");
        const d = localStorage.getItem("recentview");
        const d1 = d ? JSON.parse(d) : [];
        console.log("------", d1)
        const existingProduct = d1.find((recentlyViewedProduct) => recentlyViewedProduct.id === productToAdd.id);
        if (!existingProduct) {
            const e = JSON.stringify([productToAdd, ...d1]);
            localStorage.setItem("recentview", e)
            return [productToAdd, ...recentlyViewedProducts];
        } else {
            const e = JSON.stringify(d);
            // localStorage.setItem("recentview",e)
            return recentlyViewedProducts;
            // localStorage.setItem("recentview",[productToAdd, ...recentlyViewedProducts])

        }

    };

    const fetchvariantdata = async (variant) => {
        const response = await Axios.post(`/filter-product-variant`, { product_id: product.id, variant });
        const linkData = response.data;
        const responseData = linkData.data;
        setvariantdata(responseData.products)
        console.log(responseData.products)
    }
    const getCountry = () => {

        axios.get(`${BASE_URL}/country`)
            .then(function (res) {
                setFetchedCountry(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    }
    const getState = (name) => {

        axios.get(`${BASE_URL}/state/${name}`)
            .then(function (res) {
                setFetchedState(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    }
    const getCity = (id) => {
        axios.get(`${BASE_URL}/city/${id}`)
            .then(function (res) {
                setFetchedCity(res.data.data)
            })
            .catch(function (error) {
                // console.log(error);
            })
    };

    const sendenquiry = () => {
        Axios.post('/sendenquiry', {
            "seller_id": product.user_id,
            "product_id": product.id,
            "product_option_id": variantid,
            "quantity": count,
        }, {
            headers: {
                // 'content-type': 'multipart/form-data',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then(({ data }) => {
            toast.success("Enquiry Send Succesfully!")
        }).catch(({ response }) => {

        })
    }
    const addenquiryfinal = () => {
        Axios.post('/addenquiry', {
            "seller_id": product.user_id,
            "product_id": product.id,
            "product_option_id": variantid,
            "name": name,
            "email": email,
            "mobile_number": mobile_number,
            "quantity": count,
            "address": address,
            "country": country,
            "state": state,
            "city": city,
            "gender": gender,
            "zipcode": zipcode,
            "password": password,
            "password_confirmation": password_confirmation
        }).then(({ data }) => {
            appDispatch({
                type: 'login',
                data: {
                    token: data.data.token,
                    name: data.data.user.name,
                    email: data.data.user.email,
                    mobileNumber: data.data.user.mobile_number,
                    profileImage: data.data.user.profile_image,
                    lastLogin: data.data.user.last_login,
                    step: "complete",
                },
            });
            navigate('/dashboard');
        }).catch(({ response }) => {
            setErr(response.data.message)
        })
    }
    const addenquirybusiness = () => {
        Axios.post('/addenquirybusiness', {
            "seller_id": product.user_id,
            "product_id": product.id,
            "product_option_id": variantid,
            "name": name,
            "email": email,
            "mobile_number": mobile_number,
            "quantity": count,
            "address": address,
            "country": country,
            "state": state,
            "city": city,
            // "gender":gender,
            "zipcode": zipcode,
            // "password":password,
            // "password_confirmation":password_confirmation
        }).then(({ data }) => {
            appDispatch({
                type: 'login',
                data: {
                    token: data.data.token,
                    name: data.data.user.name,
                    email: data.data.user.email,
                    mobileNumber: data.data.user.mobile_number,
                    profileImage: data.data.user.profile_image,
                    step: "not complete",
                    lastLogin: data.data.user.last_login,
                },
            });
            setThankModal(true);
            setShowmodal(false)
        }).catch(({ response }) => {
            setErr(response.data.message)
        })
    }
    const addenquiry = () => {
        Axios.post('/addenquiry', {
            "seller_id": product.user_id,
            "product_id": product.id,
            "product_option_id": variantid,
            "name": name,
            "email": email,
            "mobile_number": mobile_number,
            "quantity": count,
            // "address":address,
            // "country":country,
            // "state":state,
            // "city":city,
            // "gender":gender,
            // "zipcode":zipcode,
            // "password":password,
            // "password_confirmation":password_confirmation
        }).then(({ data }) => {
            appDispatch({
                type: 'login',
                data: {
                    token: data.data.token,
                    name: data.data.user.name,
                    email: data.data.user.email,
                    mobileNumber: data.data.user.mobile_number,
                    profileImage: data.data.user.profile_image,
                    step: "not complete",
                    lastLogin: data.data.user.last_login,
                },
            });
            setShowmodal(true);
            setShow(false)
        }).catch(({ response }) => {
            setErr(response.data.message)
        })
    }
    const addToWishlist = (id) => {
        if (appState.state.user.token) {
            async function wishlistProduct() {
                try {
                    const response = await Axios.post(
                        `/add-to-wishlist/${id}`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${appState.state.user.token}`,
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    const responseData = response.data.data;
                    appDispatch({ type: 'setWishlistCount', value: responseData.wishlistCount });
                    appDispatch({ type: 'flashMessage', value: 'Product wishlisted' });
                } catch (e) {
                    if (e.response.status === 401) {
                        appDispatch({ type: 'flashMessage', value: 'Login to wishlist product' });
                    } else {
                        console.log('Error encountered');
                    }
                }
            }
            wishlistProduct();
        } else {
            async function wishlistProduct() {
                try {
                    const response = await Axios.post(
                        `/add-to-wishlist-unauth`,
                        {
                            product_id: id,
                            device_id: deviceid
                        }
                    );
                    const responseData = response.data.data;
                    appDispatch({ type: 'setWishlistCount', value: responseData.wishlistCount });
                    appDispatch({ type: 'flashMessage', value: 'Product wishlisted' });
                } catch (e) {
                    if (e.response.status === 401) {
                        appDispatch({ type: 'flashMessage', value: 'Login to wishlist product' });
                    } else {
                        console.log('Error encountered');
                    }
                }
            }
            wishlistProduct();
        }


    };
    const youtubeVideoURL = 'https://www.youtube.com/embed/dQw4w9WgXcQ';

    function incrementCount() {
        count = count + 1;
        setCount(count);
    }
    function decrementCount() {
        count = count - 1;
        setCount(count);
    }

    const [show, setShow] = useState(false);
    const [thankModal, setThankModal] = useState(false);
    return (
        <>
            <section className="shop-main-section" style={{ marginTop: "30px" }}>
                <Container fluid>
                    <Row>
                        <Col md={9} sm={12}>
                            <div className="product-details-main-block">
                                <div className="card-block">
                                    <div className="card-body-blk">
                                        <Row>
                                            <Col md={5} sm={12} xs={12}>
                                                <div className="product-imgslider">
                                                    {props?.productDetailsData?.photos?.map((item, index) => (
                                                        <img src={item.photo} height="100%" />
                                                    ))}
                                                    {/* {isLoading ? <Loader /> : <ProductDetailCarousel photos={product.photos} name={product.name} />} */}
                                                </div>
                                                <div className='row' style={{marginTop: "10px"}}>
                                                    <div className='col-md-4'>
                                                        <img src={props?.productDetailsData.thumbnail} height="100%" />
                                                    </div>
                                                    <div className='col-md-8'>
                                                        <iframe
                                                            width="100%"
                                                            // src={`https://www.youtube.com/embed/5sVe4m2lc0A?si=mQODyulOEhrMdsr4`}
                                                            src={props?.productDetailsData?.video_link}
                                                            frameBorder="0"
                                                            allowFullScreen
                                                            title="YouTube Video"
                                                        ></iframe>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={7} sm={12} xs={12} sx={12} >
                                                <div className="product-details-cont">
                                                    <div className='product-button-and-name'>
                                                        <div className='product-detail-prodname'>{props?.productDetailsData?.name}</div>
                                                    </div>
                                                    {isLoading || product.reviews_count === 0 ? <div className='product-detail-prodetail mt-2'>Be the first to review this product </div> : <ProductStarRating />}
                                                    <div className='mt-2 mb-2' style={{ borderTop: '2px solid lightgray' }}></div>
                                                    <div className="proprice-blk" >
                                                        {product.product_type == 'range' ?
                                                            <div className='mt-4' style={{ display: 'flex' }}>
                                                                <div>
                                                                    <div className='variant-name'>{variantdata ? variantdata.range1 : product.product_range1} Pieces</div>
                                                                    <div className='mt-2' style={{ display: 'flex' }}>
                                                                        <div className='product-detail-price'>{currencysymbol} {variantdata ? currencysymbol == "$" ? variantdata.saleprice_usd1 : variantdata.saleprice1 : product.saleprice1}</div  >
                                                                        <div className='variant-price-through' style={{ marginLeft: 15, marginTop: 3 }}>{currencysymbol} {variantdata ? currencysymbol == "$" ? variantdata.price_usd1 : variantdata.price : product.unit_price}</div>
                                                                    </div>
                                                                </div>
                                                                {product.purchase_price2 && product.unit_price2 && (
                                                                    <div style={{ marginLeft: 50 }}>
                                                                        <div className='variant-name'>{variantdata ? variantdata.range2 : product.product_range2} Pieces</div>
                                                                        <div className='mt-2' style={{ display: 'flex' }}>
                                                                            <div className='product-detail-price  '>{currencysymbol} {variantdata ? currencysymbol == "$" ? variantdata.saleprice_usd2 : variantdata.saleprice2 : product.saleprice2}</div  >
                                                                            <div className='variant-price-through' style={{ marginLeft: 15, marginTop: 3 }}>{currencysymbol} {variantdata ? currencysymbol == "$" ? variantdata.price_usd2 : variantdata.price2 : product.unit_price2}</div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                {product.purchase_price3 && product.unit_price3 && (
                                                                    <div style={{ marginLeft: 50 }}>
                                                                        <div className='variant-name'>{variantdata ? variantdata.range3 : product.product_range3} Pieces</div>
                                                                        <div className='mt-2' style={{ display: 'flex' }}>
                                                                            <div className='variant-price-range'>{currencysymbol}{variantdata ? currencysymbol == "$" ? variantdata.saleprice_usd3 : variantdata.saleprice3 : product.saleprice3}</div  >
                                                                            <div className='variant-price-through' style={{ marginLeft: 15, marginTop: 3 }}>{currencysymbol} {variantdata ? currencysymbol == "$" ? variantdata.price_usd3 : variantdata.price3 : product.unit_price3}</div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                            </div> :
                                                            <div className='single-product-styling'>
                                                                {variantdata && variantdata ? (
                                                                    <>
                                                                        <div className='hide-in-mobile'>
                                                                            {/* <h1>{currencysymbol}{currencysymbol == "$" ? variantdata.saleprice_usd1 : variantdata.saleprice1}{props.productDetailsData.saleprice1}</h1>
                                                                            <p>{currencysymbol}{props.productDetailsData.base_discounted_price}{currencysymbol == "$" ? variantdata.price_usd1 : variantdata.price}</p>
                                                                            <h4>{variantdata.discount_type == "percentage" && variantdata.discount + " % OFF"}</h4>
                                                                            <h4>{variantdata.discount_type == "amount" && (Math.round(((variantdata.price - variantdata.saleprice1) / variantdata.price) * 100)) + " % OFF"}</h4>
                                                                            <h3> {variantdata.discount_type == "amount" && `You save ${currencysymbol} ${variantdata.price - variantdata.saleprice1}`}</h3>
                                                                            <h3> {variantdata.discount_type == "percentage" && `You save ${currencysymbol} ${Math.round(variantdata.price - variantdata.saleprice1)}`}</h3> */}
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <h1>{currencysymbol} {props.productDetailsData.saleprice1}</h1>
                                                                        <p>{currencysymbol} {props.productDetailsData.base_discounted_price}</p>
                                                                        <h4>{product.discount_type == "percentage" && product.discount + " % OFF"}</h4>
                                                                        <h4>{product.discount_type == "amount" && (Math.round(((product.unit_price - product.saleprice1) / product.unit_price) * 100)) + " % OFF"}</h4>
                                                                        <h3> {product.discount_type == "amount" && `You save ${currencysymbol} ${product.unit_price - product.saleprice1}`}</h3>
                                                                        <h3> {product.discount_type == "percentage" && `You save ${currencysymbol} ${Math.round(product.unit_price - product.saleprice1)}`}</h3>
                                                                    </>
                                                                )}

                                                            </div>
                                                        }
                                                    </div>
                                                    <h4 style={{ borderTop: '2px solid lightgray', paddingTop: 20 }}>
                                                        <span className="stock-blk-new">In stock</span>{' '}
                                                        <span className="sku-blk" >
                                                            <b>SKU</b> {variantdata ? variantdata.sku : product.slug}
                                                        </span>
                                                    </h4>

                                                    <div className="qty-cart-fav-block">
                                                        <div className="qty-plsminus-blk mb-2 mt-2">
                                                            <span className='bold'>QTY</span>
                                                            <div className="plusminus-qty">
                                                                <div className="input-group">
                                                                    <button onClick={decrementCount}>-</button>
                                                                    <input type="number" step="1" max="" value={count} name="quantity" className="quantity-field" disabled />
                                                                    <button onClick={incrementCount}>+</button>
                                                                </div>
                                                            </div>
                                                            <span className='product-detail-pieces'>Pieces</span>
                                                        </div>
                                                        {product.order_type == "ordering" ? (<button className="btn btn-primary ">Add to Cart</button>) : (<button className="btn btn-primary " onClick={() => { variantid ? token ? sendenquiry() : setShow(true) : toast.error("Please Select Variant") }}>Send Inquiry</button>)}

                                                        <span className="btn btn-black span-link" onClick={() => addToWishlist(product.id)}>
                                                            {/* <i className="bi bi-heart" style={{color:'white'}}></i> */}
                                                            <img src="/assets/images/wish_heart.png" />
                                                        </span>
                                                        <span className="btn btn-black span-link">
                                                            {/* <i className="bi bi-shuffle"></i> */}
                                                            <img src="/assets/images/share.png" />
                                                        </span>
                                                    </div>
                                                    <div className="variants">
                                                        <span>VARIANT</span>
                                                        <div className='row'>
                                                            {productDetail.length ? productDetail && productDetail.map((item) => (
                                                                <div onClick={() => { fetchvariantdata(item.variant); setvariantid(item.id) }} active className='col-md-6 mt-2' style={{ marginRight: 5 }}>
                                                                    <div className='variant-block'>
                                                                        <h5 className='var-info'>{item.variant}</h5>
                                                                    </div>
                                                                </div>
                                                            )) : ''}
                                                        </div>
                                                        <div className='mt-2 mb-2' style={{ borderTop: '2px solid lightgray' }}></div>
                                                        <div className='product-detail-block'>
                                                            <h4 style={{ color: '#000', }}>Product Highlights:</h4>
                                                            <p>{product.highlight}</p>
                                                        </div>
                                                        <div className='mt-2 mb-2' style={{ borderTop: '2px solid lightgray' }}></div>

                                                        <div className='product-detail-block'>
                                                            <h4 style={{ color: '#000', }}>Product Information:-</h4>
                                                            <p dangerouslySetInnerHTML={{ __html: product.short_description }}></p>
                                                        </div>
                                                        <div className='mt-2 mb-2' style={{ borderTop: '2px solid lightgray' }}></div>

                                                        <div className='product-detail-block'>
                                                            <h4 style={{ color: '#000', }}>Features:-</h4>
                                                            <p>{product.features}</p>
                                                        </div>
                                                    </div>
                                                    <div className='mt-2 mb-2' style={{ borderTop: '2px solid lightgray' }}></div>

                                                    <div className="share-product">
                                                        <span>Share this</span>
                                                        <ul className="socialshare-blk">
                                                            <li>
                                                                <a href="#">
                                                                    <FacebookShareButton url={window.location.href}><i className="fa fa-facebook"></i></FacebookShareButton>
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <TwitterShareButton url={window.location.href}><i className="fa fa-twitter"></i></TwitterShareButton>

                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <LinkedinShareButton url={window.location.href}><i className="fa fa-linkedin"></i></LinkedinShareButton>

                                                                </a>
                                                            </li>
                                                            <li>
                                                                <a href="#">
                                                                    <WhatsappShareButton url={window.location.href}><i className="fa fa-whatsapp"></i></WhatsappShareButton>

                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className="productsdetails-tab">
                                <div className="card-block">
                                    <div className="card-body-blk">
                                        <Tabs defaultActiveKey="details" transition={false} id="noanim-tab-example">
                                            <Tab eventKey="details" title="Details">
                                                <p dangerouslySetInnerHTML={{ __html: product.description }}></p>
                                            </Tab>
                                            <Tab eventKey="infopolicy" title="Shipping Policy">
                                                <p dangerouslySetInnerHTML={{ __html: product.shipping_policy }}></p>
                                            </Tab>
                                            <Tab eventKey="otherdetails" title="Other Detail">
                                                <p dangerouslySetInnerHTML={{ __html: product.other_detail }}></p>
                                            </Tab>
                                            <Tab eventKey="review" title="Reviews">
                                                <div className='prodrev1'>
                                                    <h6><img width="22" height="22" src="/assets/images/user.jpg" alt="user" /> Customer</h6>
                                                    <ProductStarRating />
                                                    <p className="review-date">Reviewed in India on 15 July 2020</p>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu justo dui. Etiam finibus diam a fringilla sodales. Nunc feugiat, ligula quis feugiat commodo, libero eros vestibulum, lorem ipsum <a href="#">Read More &gt;</a> </p>
                                                </div>
                                                <hr />

                                                <div className='prodrev2'>
                                                    <h6><img width="22" height="22" src="/assets/images/user.jpg" alt="user" /> Customer</h6>
                                                    <ProductStarRating />

                                                    <p className="review-date">Reviewed in India on 7 Nov 2020</p>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eu justo dui. Etiam finibus diam a fringilla sodales. Nunc feugiat, ligula quis feugiat commodo, libero eros vestibulum, lorem ipsum <a href="#">Read More &gt;</a> </p>
                                                </div>
                                                <hr />

                                                <div className='prodrev3'>
                                                    <h6><img width="22" height="22" src="/assets/images/user.jpg" alt="" /> Customer</h6>
                                                    <ProductStarRating />
                                                    <p className="review-date">Reviewed in India on 12 Feb 2021</p>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. elit. Donec eu justo dui. Etiam finibus diam a fringilla sodales. Nunc feugiat, ligula quis feugiat commodo, libero eros vestibulum risus, eu egestas dolor augue at ex. Etiam at neque nisi. Nunc tristique lorem ut nisl sagittis posuere. Integer posuere metus eu dolor iaculis pretium. Cras accumsan nulla vitae gravida vulputate. Proin mollis auctor sagittis. Etiam ante diam, semper efficitur laoreet sit amet, luctus quis nisl. Integer pretium metus sit amet faucibus euismod. Aenean et finibus sapien, eget tempor dolor. </p>
                                                </div>
                                            </Tab>

                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                            <div className="recent-product-block">
                                <div className="card-block">
                                    <div className="card-head-blk">
                                        <h4>Related Products</h4>
                                    </div>
                                    <div className="card-body-blk">{isLoading ? <Loader /> : <ProductCarouselContainer products={relatedProducts} />}</div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3} sm={12}>
                            {/* <DealPotraitItem deal={flashDealPotrait} /> */}
                            <div className="product-details-main-block">
                                <div className="card-block">
                                    <div className="card-body-blk-con">
                                        <div className='seller-info-container'>
                                            <span className='seller-card-body'>SELLERS INFO</span>
                                        </div>
                                    </div>
                                    <div className='seller-block'>
                                        <div className='mt-2'>
                                            <div className='seller-logo-container center' style={{ marginBottom: 0 }}>
                                                <img src="/assets/images/blog-details.jpg" height="100%" />
                                                {/* <img src={IMAGE_BASE_URL + seller.profile_image ? IMAGE_BASE_URL + seller.profile_image : "./assets/images/accountants.png"} style={{ height: "250px", width: '280px' }} /> */}
                                            </div>
                                            <div className='product-seller-container'>

                                                <div className='product-detail-seller-name'>
                                                    <div> {seller.name}</div>
                                                    {/* <img src="/assets/images/flag-new.png" style={{ marginLeft: 10, width: 30 }} /> */}
                                                </div>

                                                <div className='flex' style={{ justifyContent: 'left' }}>
                                                    <ReactStars
                                                        count={5}
                                                        onChange={ratingChanged}
                                                        size={24}
                                                        color2={'#ffd700'}
                                                        className="rating-stars"
                                                    />
                                                    <div className='mt-2'>  (reviews) </div>
                                                </div>
                                                <div className='mt-1'>
                                                    {businesspofile?.trust_seal == "Yes" && (
                                                        <div><img src="/assets/images/trust.png" /></div>
                                                    )}
                                                    {businesspofile?.verified_seller == "Yes" && (
                                                        <div><img src="/assets/images/verify.png" /></div>
                                                    )}
                                                    {/* <img src="/assets/images/turst-verified.png" /> */}
                                                </div>

                                                <div className='product-detail-company-details mt-2'>
                                                    <h6>Established Year:</h6>
                                                    <p>{businesspofile?.estblish_year}</p>
                                                </div>
                                                <div className='product-detail-company-details'>
                                                    <h6>Business Nature:</h6>
                                                    <p>{businesspofile?.business_nature}</p>
                                                </div>
                                                <div className='product-detail-company-details'>
                                                    <h6>Country Origin:</h6>
                                                    <p>{businesspofile?.country?.name}</p>
                                                </div>
                                                <div className='flex mt-1' style={{ justifyContent: 'space-between' }}>
                                                    <a href={"https://api.whatsapp.com/send?phone=" + businesspofile?.whatsapp_number}>
                                                        <button className='product-detail-company-details-call'><img src="/assets/images/shop-btn-sms.png" style={{ marginRight: 10 }} />Chat Now</button>
                                                    </a>
                                                    <a href={"tel:" + seller.mobile_number}>
                                                        <button className='product-detail-company-details-call'>Call Now</button>
                                                    </a>
                                                </div>
                                                <a href={"mailto:" + seller.email}>
                                                    <button className="send-email-btn mt-3" style={{ width: '100%', paddingTop: 8, paddingBottom: 8 }}><img src="/assets/images/email-icon.png" style={{ marginRight: 10 }} />Send Email</button>
                                                </a>
                                                <button className='product-detail-company-details-call mt-3' style={{ width: '100%' }} onClick={() => navigate("/seller-detail/" + businesspofile?.business_slug)}>View Company Profile</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default ProductsDetails;