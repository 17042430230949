import React from 'react'
import "../style/NewLeads.css"
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import { Link } from 'react-router-dom';


export const DigitalMarketingHistory = () => {
    return (
        <>
            <div className="new-leads dm-history py-4" style={{ background: "#0000001a" }}>

                <div className="main-cont d-flex justify-content-between">

                    <VendorDashboardSideMenu />



                    {/* rght side Cont  */}

                    <div className="right px-lg-5 px-md-4 px-3 col-md-9" >
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <h2>Digital Marketing History</h2>
                            <h2>January 2023 - December 2023</h2>
                        </div>


                        <div className="art-1 my-lg-5  my-4">
                            <div>
                                <h6>Digital Marketing</h6>

                            </div>

                            <div className=" cards d-flex justify-content-between align-items-center">
                                <div>
                                    <h5>Facebook</h5>
                                    <span>Total Post: 10/30</span>
                                </div>
                                <div>
                                    <h5>Instagram</h5>
                                    <span>Total Post: 10/30</span>
                                </div>
                                <div>
                                    <h5>Linkedin</h5>
                                    <span>Total Post: 10/30</span>
                                </div>
                                <div>
                                    <h5>Youtube</h5>
                                    <span>Total Post: 10/30</span>
                                </div>
                                <div>
                                    <h5>Twitter X</h5>
                                    <span>Total Post: 10/30</span>
                                </div>

                            </div>
                        </div>

                        <div className="art-2 my-lg-5 my-4">
                            <div>
                                <h6>Digital Marketing History</h6>
                            </div>
                            <div className="overf-div">
                                <div className="table-div">

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Month/Year</th>
                                                <th scope="col">Facebook</th>
                                                <th scope="col" >Instagram</th>
                                                <th scope="col">LinkedIn</th>
                                                <th scope="col">YouTube</th>
                                                <th scope="col">Twitter X</th>
                                                <th scope="col">Total Post</th>
                                                <th scope="col">Published</th>
                                                <th scope="col">Remaining</th>
                                                <th scope="col">View Details</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>Jan 2023</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>10/20</td>
                                                <td>30</td>
                                                <td>30</td>
                                                <td>30</td>

                                                <td>
                                                    <Link to="/social-media">
                                                    <button><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                    <path d="M10.0009 14.4C5.40094 14.4 3.32094 10.36 3.24094 10.16C3.20094 10.04 3.20094 9.91998 3.24094 9.79998C3.32094 9.63998 5.40094 5.59998 10.0009 5.59998C14.6009 5.59998 16.6809 9.63998 16.7609 9.83998C16.8009 9.95998 16.8009 10.08 16.7609 10.2C16.6809 10.36 14.6009 14.4 10.0009 14.4ZM4.04094 9.99998C4.48094 10.76 6.40094 13.6 10.0009 13.6C13.6009 13.6 15.4809 10.76 15.9609 9.99998C15.5209 9.23998 13.6009 6.39998 10.0009 6.39998C6.40094 6.39998 4.52094 9.23998 4.04094 9.99998Z" fill="#00B987" />
                                                    <path d="M10.0004 13.6C8.00039 13.6 6.40039 12 6.40039 10C6.40039 8.00002 8.00039 6.40002 10.0004 6.40002C12.0004 6.40002 13.6004 8.00002 13.6004 10C13.6004 12 12.0004 13.6 10.0004 13.6ZM10.0004 7.20002C8.44039 7.20002 7.20039 8.44002 7.20039 10C7.20039 11.56 8.44039 12.8 10.0004 12.8C11.5604 12.8 12.8004 11.56 12.8004 10C12.8004 8.44002 11.5604 7.20002 10.0004 7.20002Z" fill="#00B987" />
                                                    <path d="M10 12C8.88 12 8 11.12 8 10C8 9.76 8.16 9.6 8.4 9.6C8.64 9.6 8.8 9.76 8.8 10C8.8 10.68 9.32 11.2 10 11.2C10.68 11.2 11.2 10.68 11.2 10C11.2 9.32 10.68 8.8 10 8.8C9.76 8.8 9.6 8.64 9.6 8.4C9.6 8.16 9.76 8 10 8C11.12 8 12 8.88 12 10C12 11.12 11.12 12 10 12Z" fill="#00B987" />
                                                </svg></button>
                                                </Link>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    )
}
