import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState, useRef } from "react";
import ProductStarRating from '../product-star/product-star-rating.component';
import StateContext from '../../context/StateContext';
const ProductItem = ({ product, className1, className2 }) => {
  const { currencysymbol } = useContext(StateContext);
  console.log(product, "related products");
  return (

    <div className={className1}>
      <div className={className2}>
        <Link to={`/product/${product.slug}`}>
          <div className="product-img-blk">
            {product.base_discounted_price < product.base_price ? <span className="sale-blk">Sale</span> : ''}
            <img src={product.thumbnail ? product.thumbnail : 'assets/images/image-13.jpg'} alt={product.name} />
          </div>
          <div className="product-cont-blk">
            <h4>{product.name}</h4>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ProductStarRating rating={product.rating} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: "13px" }}>
              <p className="product-price-home" style={{ fontSize: '20px', marginLeft: "4px", letterSpacing: "-2px" }}>{currencysymbol} {product.saleprice1}</p> &nbsp;&nbsp;
              <p className='ml-2' style={{ color: 'gray', fontSize: '17px', textDecoration: 'line-through', marginTop: '3px', }}>{currencysymbol} {product.unit_price}</p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default ProductItem;
