import React, { useState } from 'react'
import "../../style/JoinOurTeam.css"

export const FormCom = () => {
const[hideForm , setHideForm] = useState(false)
   

    return (
        <>
        {hideForm=== false ?
            <div className="form-comp">

                {/* Form ------- */}
                <div className="form-div">
                    <div className="d-flex justify-content-between align-items-center">
                        <h6>Join Us</h6>
                        <button onClick={() => setHideForm(true)}><svg xmlns="http://www.w3.org/2000/svg" height="16" width="12" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg></button>
                    </div>

                    <p className='bpara'>Apna Shaher</p>

                    <form>
                        <div className="my-3">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                            <p className='mb-0'>Select field Required*</p>
                        </div>

                        <div className="my-3">
                            <input type="text" placeholder='First Name' />
                            <p className="mb-0">First Name Required*</p>
                        </div>

                        <div className="my-3">
                            <input type="text" placeholder='Last Name' />
                            <p className="mb-0">Last Name Required*</p>
                        </div>
                        <div className="my-3">
                            <input type="emial" placeholder=' Email Id' />
                            <p className="mb-0">Email id*</p>
                        </div>
                        <div className="my-3">
                            <input type="number" placeholder='Mobile Number' />
                            <p className="mb-0">Lorem, ipsum.*</p>
                        </div>
                        <div className="my-3">
                            <input type="text" placeholder='Education Qualification' />
                            <p className="mb-0">Lorem, ipsum.*</p>
                        </div>
                        <div className="my-3">
                            <input type="text" placeholder='Work Experience' />
                            <p className="mb-0">Lorem, ipsum.*</p>
                        </div>
                        <div className="my-3">
                            <input type="text" placeholder='Address' />
                            <p className="mb-0">Lorem, ipsum.*</p>
                        </div>
                        <div className="my-3">
                            <div className="dd-input d-flex justify-content-between">
                                <div>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>Country</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <p className="mb-0">Lorem, ipsum.*</p>

                                </div>
                                <div>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected>State</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                    <p className="mb-0">Lorem, ipsum.*</p>
                                </div>


                            </div>
                        </div>
                        <div className="my-3">
                           <div className="dd-input d-flex justify-content-between">
                            <div>
                            <input type="text" placeholder='City' />
                            <p className="mb-0">Lorem, ipsum.*</p>
                            </div>
                            <div>
                            <input type="number" placeholder='Pin Code' />
                            <p className="mb-0">Lorem, ipsum.*</p>
                            </div>
                           </div>
                        </div>
                        <div className="upload-div my-3 ">
                            <div className='position-relative'>
                            <input type="file" />
                            <span>Upload File</span>

                            </div>
                            <p className="mb-0">Lorem, ipsum.*</p>
                        </div>

                        <div className="d-flex mt-4 justify-content-between align-items-center">
                            <button onClick={()=> setHideForm(true)}>Cancle</button>
                            <button className='bg-lblue'>Apply Now</button>
                        </div>



                    </form>

                </div>
            </div>

:null}
        </>
    )
}
