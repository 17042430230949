import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import ListGroup from "react-bootstrap/ListGroup";
import { Button, InputGroup, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Page from "../components/Page";

const bulkData = [
  {
    id: "1",
    data: "Download the skeleton file and fill it with data.",
  },
  {
    id: "2",
    data: "You can download the example file to understand how the data must be filled.",
  },
  {
    id: "3",
    data: "Once you have downloaded and filled the skeleton file, upload it in the form below and submit.",
  },
  {
    id: "4",
    data: "After uploading products you need to edit them and set products images and choices.",
  },
];

const cateBrandData = [
  {
    id: "1",
    data: "Category and Brand should be in numerical id.",
  },
  {
    id: "2",
    data: "You can download the pdf to get Category and Brand id.",
  },
];

const ProductBulkUpload = () => {
  return (
    <Page title="Product Bulk Upload">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Bulk Upload</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Bulk Products Upload</h4>
                <section>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bulk-card-block">
                        <div className="bulk-head-blk">
                          <ListGroup>
                            {bulkData.map((bulk, index) => (
                              <ListGroup.Item
                                style={{
                                  padding: "15px",
                                  borderRadius: 0,
                                  backgroundColor:
                                    index % 2 === 0
                                      ? "rgba(30, 224, 172, 0.2)"
                                      : "rgba(23, 162, 184, 0.4)",
                                }}
                                key={bulk.id}
                              >
                                {bulk.id}. {bulk.data}:
                              </ListGroup.Item>
                            ))}
                          </ListGroup>

                          <div style={{ paddingTop: "15px" }}>
                            <Button variant="warning" className="mobile-screen-button">Download CSV</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="brand-card-block">
                        <div className="bulk-head-blk">
                          <ListGroup>
                            {cateBrandData.map((brand, index) => (
                              <ListGroup.Item
                                style={{
                                  padding: "15px",
                                  borderRadius: 0,
                                  backgroundColor:
                                    index % 2 === 0
                                      ? "rgba(30, 224, 172, 0.2)"
                                      : "rgba(23, 162, 184, 0.4)",
                                }}
                                key={brand.id}
                              >
                                {brand.id}. {brand.data}:
                              </ListGroup.Item>
                            ))}
                          </ListGroup>

                          <div style={{ paddingTop: "15px" }}>
                            <Button variant="warning" className="mobile-screen-button">Download Category</Button>
                            <Button
                              className="mobile-screen-button"
                              style={{ marginLeft: "15px" }}
                              variant="warning"
                            >
                              Download Brand
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="csv-card-block">
                        <div className="csv-head-blk">
                          <h6>Upload CSV File</h6>
                        </div>
                        <div className="card-body-blk">
                          <div className="row">
                            <div className="col-md-2">
                              <p style={{ paddingTop: "7px" }}>CSV</p>
                            </div>
                            <div className="col-md-10">
                              <InputGroup>
                                <Form.Control
                                  type="text"

                                // placeholder="Input group example"
                                // aria-label="Input group example"
                                // aria-describedby="btnGroupAddon"
                                />
                                <Button style={{ backgroundColor: '#17A2B8' }} variant="success">Browse</Button>
                              </InputGroup>
                            </div>
                            <div className="col-md-12">
                              <Button className="mobile-screen-button" style={{ float: 'right', marginTop: '12px' }} variant="warning">Upload CSV</Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default ProductBulkUpload;
