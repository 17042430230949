import React from 'react'

export const Risk = () => {
  return (
    <>
    <div className="risk mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3">
        <div className="">
            <div className="top-content text-center">
                <h3>Risk Migration <span>Factor</span></h3>
                <p>Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

            </div>
                <div className="overf-div">
                    <div className="cards d-flex gap-3 justify-content-between">
                        <div className="cmn-card d-flex align-items-center gap-lg-3 gap-md-3 gap-2 m-2 shadow-sm">
                            <img src="/assets/images/cyber-security1.png" alt="" /> 
                            <div className='ml-2'>
                                <p>Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet.Lorem.</p>
                            </div>
                        </div>
                        <div className="cmn-card d-flex align-items-center gap-lg-3 gap-md-3 gap-2 m-2 shadow-sm">
                            <img src="/assets/images/cyber-security1.png" alt="" />
                            <div className='ml-2'>
                                <p>Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet.Lorem.</p>
                            </div>
                        </div>
                        <div className="cmn-card d-flex align-items-center gap-lg-3 gap-md-3 gap-2 m-2 shadow-sm">
                            <img src="/assets/images/cyber-security1.png" alt="" />
                            <div className='ml-2'>
                                <p>Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet.Lorem.</p>
                            </div>
                        </div>
                    </div>
                </div>

        </div>
    </div>
    
    </>
  )
}
