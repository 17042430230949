import React from 'react';
import { Form, Button, Modal, InputGroup } from "react-bootstrap";

const RechargeDetails = ({ showAdd, setShowAdd, rechargeId }) => {
    const handleClose = () => {
        setShowAdd(false);
    };
    console.log(rechargeId, "rechargeId");
    return (
        <>
            <Modal
                size="xl"
                onHide={handleClose}
                className="recharge-modal-block"
                show={showAdd}
                animation={false}
                centered
            >
                <Modal.Header closeButton>
                    <h5 class="modal-title">Recharge Details</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-3'>
                        <h5>Transaction Detail</h5>
                        <div className='col-md-3'>
                            <h6>Transaction ID</h6>
                            <p>{rechargeId.transaction_id}</p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Date & Time</h6>
                            <p>{rechargeId.created_at}</p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Transaction Type</h6>
                            <p>{rechargeId.transaction_type}</p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Amount</h6>
                            <p>{rechargeId.amount}</p>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <h5>Service Detail</h5>
                        <div className='col-md-3'>
                            <h6>Service Detail</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'><h6></h6>Service Status<h6></h6></div>
                    </div>
                    <div className='row mt-3'>

                        <div className='col-md-3'>
                            <h6>Payment Method</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Reference ID</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Image</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Payment Status</h6>
                            <p></p>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-3'>
                            <h6>Ad Type</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Ad Name</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Number of Views</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Number of Clicks</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Cost per Click</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Cost per 1000 Views</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'><button className='download-invoice'>Download Invoice</button></div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <h6>Membership Package Name</h6>
                            <p></p>
                        </div>
                        <div className='col-md-6'>
                            <h6>Membership Package Cost</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Membership ID</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'>
                            <h6>Membership Expiry</h6>
                            <p></p>
                        </div>
                        <div className='col-md-3'><button className='download-invoice'>Download Invoice</button></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" type="submit">
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RechargeDetails;