import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { Button } from 'react-bootstrap';

const CancelSubscription = () => {
    const states = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <Page className='cancel-subscription'>
                <div className='cancel-subscription-section'>
                    <h3>Cancellation Request ID: #{states.state && states.state.data.request_id} <Button onClick={()=>navigate('/vendor-dashboard')}>Go Back</Button></h3>
                    <p>We are sorry to see you here however we are still here with great offers, please connect our team on Direct Line: <b><a href="tel:393747974194">393747974194</a></b></p>
                    <div className='cancel-subscription-card'>
                        <p>Package Name: <b>{states.state && states.state.data.package_name}</b></p>
                        <p>Cancellation Request Date: <b> {states.state && states.state.data.date}</b></p>
                        <p>Reason for Cancellation: <b> {states.state && states.state.data.subject}</b></p>
                        <p>Refund Request: <b> {states.state && states.state.data.refund=="wallet" ? "Virtual Wallet" : "Source Account"}</b></p>
                        <p>Cancellation Status: <b> Pending Approval</b></p>
                        {/* <h6>{states.state.data.request_id}</h6> */}
                        
                        <p><b>Note:</b> you will receive an email for the confirmation from Aladdin Direct INC</p>
                    </div>
                    <p className='mt-top-1'><b>Have any question about Subscription?</b>We strive for 100% customer satisfaction,</p>
                    <p>Please Call us toll-free <a href="tel:1800-000-000">1800-000-000</a> or email us at <a href='mailto:admin@aladdindirect.com'>admin@aladdindirect.com</a></p>
                    <p>We Promiss to get back to you with in 24 hours</p>
                </div>
                </Page>

        </>
    )
}

export default CancelSubscription