import React, { useEffect } from 'react'
import "../style/SuccessStory.css"
import { SuccessComp1 } from '../components/success-story/SuccessComp1'
import { SuccessComp2 } from '../components/success-story/SuccessComp2'
import { SuccessComp3 } from '../components/success-story/SuccessComp3'
import SeoSuccessstory from '../seo page/SeoSuccessstory'

export const SuccessStory = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
        <SeoSuccessstory/>
            <div className="success-story px-lg-5 px-md-4 px-3" style={{ background: "rgba(0, 91, 255, 0.05)" }}>
                <section>
                    <SuccessComp1 />
                </section>

                <section>
                    <SuccessComp2 />
                </section>

            </div>
        </>
    )
}
