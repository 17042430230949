import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Table, Badge, Form, Button, Tabs, Tab, DropdownButton, Dropdown } from "react-bootstrap";
import { MenuItem } from "@mui/material";
import EyeIcon from "../components/dashboard/ei_eye.jpg";
import DownloadIcon from "../components/dashboard/Download.svg";
import axios from "axios";
import { useDownloadExcel, downloadExcel } from 'react-export-table-to-excel';
import { BASE_URL } from "../config";

//   {
//     id: 1,
//     orderCode: "20220310-10564224",
//     productQty: 10,
//     customerName: "User 1",
//     amount: "$35,867.000",
//     delStatus: "Pending",
//     payStatus: "Unpaid",
//   },
//   {
//     id: 2,
//     orderCode: "20220310-10564224",
//     productQty: 10,
//     customerName: "User 1",
//     amount: "$35,867.000",
//     delStatus: "Pending",
//     payStatus: "Unpaid",
//   },
//   {
//     id: 3,
//     orderCode: "20220310-10564224",
//     productQty: 10,
//     customerName: "User 1",
//     amount: "$35,867.000",
//     delStatus: "Delivered",
//     payStatus: "Paid",
//   },
//   {
//     id: 4,
//     orderCode: "20220310-10564224",
//     productQty: 10,
//     customerName: "User 1",
//     amount: "$35,867.000",
//     delStatus: "Pending",
//     payStatus: "Unpaid",
//   },
//   {
//     id: 5,
//     orderCode: "20220310-10564224",
//     productQty: 10,
//     customerName: "User 1",
//     amount: "$35,867.000",
//     delStatus: "Delivered",
//     payStatus: "Paid",
//   },
// ];

const VendorServiceEnquiry = () => {

  const tableRef = useRef(null);
  const [show, setshow] = useState(false)
  const [id, setid] = useState("")
  const [ordersData, setordersData] = useState([]);
  const token = localStorage.getItem('avonToken');
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdownPopularity = () => {
    setIsOpen(false);
  };

  const handleOptionClick = (option) => {
    // onSelect(option);
    // closeDropdown();
    closeDropdownPopularity();
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // closeDropdown();
      closeDropdownPopularity();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchenquirys();
  }, []);
  const fetchenquirys = async () => {
    await axios.get(BASE_URL + '/getserviceenquiry', {
      headers: {
        // 'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setordersData(data.data)
    }).catch(({ response }) => {

    })
  }
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'Inquiry',
    sheet: 'Inquiry'
  })
  const header = ["Date & Time", "Service Name", "Package", "Customer Name", "Email Id", "Mobile Number", "Country"];
  function handleDownloadExcel(body) {
    downloadExcel({
      fileName: "react-export-table-to-excel -> downloadExcel method",
      sheet: "react-export-table-to-excel",
      tablePayload: {
        header,
        // accept two different data structures
        body: body,
      },
    });
  }
  const handleSelect = async (e, id) => {
    console.log(e, id)
    await axios.post(BASE_URL + '/serviceenquiryupdatestatus', { status: e, id: id }, {
      headers: {
        // 'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      fetchenquirys();
    }).catch(({ response }) => {

    })
  }
  return (
    <Page title="Orders">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Service Inquiry</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>Service Inquiry</h4>
                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="vendor-enquiry-page">
                      <div className="col-md-5 row">
                        {/* Date Range */}
                        <div className="col-md-5">
                          <Form.Control type="date" />
                        </div>
                        <div className="col-md-1 mt-1"> to </div>
                        <div className="col-md-5">
                          <Form.Control type="date" />
                        </div>
                      </div>
                      <div className="col-md-2 mobile-margin-1 mobile-margin-2">
                        <Button>Sort by Latest</Button>
                      </div>
                      <div className="col-md-3">
                        <Form.Control
                          type="text"
                          placeholder="Type order code & hit enter"
                          style={{ width: "90%" }}
                        />
                      </div>
                      <div className="col-md-3 mobile-margin-1">
                        <Button onClick={onDownload}>Export in Excel or PDF</Button>
                      </div>
                    </div>
                    <div className="card-body-blk">
                      <Tabs defaultActiveKey={1} id="uncontrolled-tab-example">
                        <Tab eventKey={1} title="New">
                          <div class="table-responsive">
                            <Table responsive="md" hover ref={tableRef}>
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Service Name</th>
                                  <th>Package</th>
                                  <th>Customer Name</th>
                                  <th>Email Id</th>
                                  <th>Mobile Number</th>
                                  <th>Country</th>
                                  <th>Status</th>
                                  {/* <th>Options</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {ordersData.map((order, index) => (
                                  <>
                                    {order && order.status == "New" && (
                                      <tr key={index} >
                                        <td>{order.created_at}</td>
                                        <td>
                                          {order?.service ? order?.service?.service_name : "NA"}
                                        </td>
                                        <td>{order?.servicepackage ? order?.servicepackage?.package_name : "NA"}</td>
                                        <td>{order.user.name}</td>
                                        <td>{order.user.email}</td>
                                        <td>
                                          {order.user.mobile_number}
                                        </td>
                                        <td>
                                          {order.user.country ? order.user.country.name : "NA"}
                                        </td>
                                        <td>
                                          {/* <div className="custom-dropdown" ref={dropdownRef}>
                                            <div className="dropdown-header-1" onClick={toggleDropdown}>
                                              <i className={`caret ${isOpen ? 'up' : 'down'}`} />
                                            </div>
                                            {isOpen && (
                                              <div className="manufacture-dropdown-3">
                                                <ul className="dropdown-options-list">
                                                  <li
                                                    // onClick={() => filterhightolow()}
                                                  >High to Low</li>
                                                  <li
                                                    // onClick={() => filtertolowhigh()}
                                                  >Low to High</li>
                                                </ul>
                                              </div>
                                            )}
                                          </div> */}
                                          <DropdownButton
                                            visible={show}
                                            bsStyle="Default"
                                            title={order.status}
                                            onClick={() => { setshow(!show) }}
                                            onSelect={(e) => { handleSelect(e, order.id) }}
                                            id={`dropdown-menu-align-right`}
                                          >

                                            <Dropdown.Item eventKey="New">New</Dropdown.Item>
                                            <Dropdown.Item eventKey="Follow-up">Follow-up</Dropdown.Item>
                                            <Dropdown.Item eventKey="Confirmed" >Confirmed</Dropdown.Item>
                                            <Dropdown.Item eventKey="Not Interested">Not Interested</Dropdown.Item>
                                          </DropdownButton>
                                        </td>
                                        {/* <td>
                                  <img src={EyeIcon} alt="" />
                                  <img
                                 
                                    style={{ paddingLeft: "5px" }}
                                    src={DownloadIcon}
                                    alt=""
                                  />
                                </td> */}
                                      </tr>
                                    )}
                                  </>

                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                        <Tab eventKey={2} title="Follow-up">
                          <div class="table-responsive">
                            <Table responsive="md" hover ref={tableRef}>
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Service Name</th>
                                  <th>Package</th>
                                  <th>Customer Name</th>
                                  <th>Email Id</th>
                                  <th>Mobile Number</th>
                                  <th>Country</th>
                                  <th>Status</th>
                                  {/* <th>Options</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {ordersData.map((order, index) => (
                                  <>
                                    {order && order.status == "Follow-up" && (
                                      <tr key={index} >
                                        <td>{order.created_at}</td>
                                        <td>
                                          {order?.service ? order?.service?.service_name : "NA"}
                                        </td>
                                        <td>{order?.servicepackage ? order?.servicepackage?.package_name : "NA"}</td>
                                        <td>{order.user.name}</td>
                                        <td>{order.user.email}</td>
                                        <td>
                                          {order.user.mobile_number}
                                        </td>
                                        <td>
                                          {order.user.country ? order.user.country.name : "NA"}
                                        </td>
                                        <td>
                                          <DropdownButton
                                            open={false}
                                            bsStyle="Default"
                                            title={order.status}
                                            onClick={() => setshow(!show)}
                                            onBlur={() => setshow(!show)}
                                            onSelect={(e) => { handleSelect(e, order.id) }}
                                            id={`dropdown-menu-align-right`}
                                          >

                                            {/* <Dropdown.Item eventKey="New">New</Dropdown.Item> */}
                                            {/* <Dropdown.Item eventKey="Follow-up">Follow-up</Dropdown.Item> */}
                                            <Dropdown.Item eventKey="Confirmed" >Confirmed</Dropdown.Item>
                                            <Dropdown.Item eventKey="Not Interested">Not Interested</Dropdown.Item>



                                          </DropdownButton>
                                        </td>
                                        {/* <td>
                                   <img src={EyeIcon} alt="" />
                                   <img
                                  
                                     style={{ paddingLeft: "5px" }}
                                     src={DownloadIcon}
                                     alt=""
                                   />
                                 </td> */}
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                        <Tab eventKey={3} title="Confirmed">
                          <div class="table-responsive">
                            <Table responsive="md" hover ref={tableRef}>
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Service Name</th>
                                  <th>Package</th>
                                  <th>Customer Name</th>
                                  <th>Email Id</th>
                                  <th>Mobile Number</th>
                                  <th>Country</th>
                                  <th>Status</th>
                                  {/* <th>Options</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {ordersData.map((order, index) => (
                                  <>
                                    {order && order.status == "Confirmed" && (
                                      <tr key={index} >
                                        <td>{order.created_at}</td>
                                        <td>
                                          {order?.service ? order?.service?.service_name : "NA"}
                                        </td>
                                        <td>{order?.servicepackage ? order?.servicepackage?.package_name : "NA"}</td>
                                        <td>{order.user.name}</td>
                                        <td>{order.user.email}</td>
                                        <td>
                                          {order.user.mobile_number}
                                        </td>
                                        <td>
                                          {order.user.country ? order.user.country.name : "NA"}
                                        </td>
                                        <td>
                                          {order.status}
                                          {/* <DropdownButton
                                visible={show}
                                bsStyle="Default"
                                title={order.status}
                                onClick={()=>{setshow(!show)}}
                                onSelect={(e)=>{handleSelect(e,order.id)}}
                                id={`dropdown-menu-align-right`}
                              > */}

                                          {/* <Dropdown.Item eventKey="New">New</Dropdown.Item>
                                <Dropdown.Item eventKey="Follow-up">Follow-up</Dropdown.Item>
                                <Dropdown.Item eventKey="Confirmed" >Confirmed</Dropdown.Item>
                                <Dropdown.Item eventKey="Not Interested">Not Interested</Dropdown.Item> */}



                                          {/* </DropdownButton> */}
                                        </td>
                                        {/* <td>
                                  <img src={EyeIcon} alt="" />
                                  <img
                                 
                                    style={{ paddingLeft: "5px" }}
                                    src={DownloadIcon}
                                    alt=""
                                  />
                                </td> */}
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                        <Tab eventKey={4} title="Not Interested">
                          <div class="table-responsive">
                            <Table responsive="md" hover ref={tableRef}>
                              <thead>
                                <tr>
                                  <th>Date & Time</th>
                                  <th>Service Name</th>
                                  <th>Package</th>
                                  <th>Customer Name</th>
                                  <th>Email Id</th>
                                  <th>Mobile Number</th>
                                  <th>Country</th>
                                  <th>Status</th>

                                </tr>
                              </thead>
                              <tbody>
                                {ordersData.map((order, index) => (
                                  <>
                                    {order && order.status == "Not Interested" && (
                                      <tr key={index} >
                                        <td>{order.created_at}</td>
                                        <td>
                                          {order?.service ? order?.service?.service_name : "NA"}
                                        </td>
                                        <td>{order?.servicepackage ? order?.servicepackage?.package_name : "NA"}</td>
                                        <td>{order.user.name}</td>
                                        <td>{order.user.email}</td>
                                        <td>
                                          {order.user.mobile_number}
                                        </td>
                                        <td>
                                          {order.user.country ? order.user.country.name : "NA"}
                                        </td>
                                        <td>
                                          <DropdownButton
                                            visible={show}
                                            bsStyle="Default"
                                            title={order.status}
                                            onClick={() => { setshow(!show) }}
                                            onSelect={(e) => { handleSelect(e, order.id) }}
                                            id={`dropdown-menu-align-right`}
                                          >

                                            {/* <Dropdown.Item eventKey="New">New</Dropdown.Item> */}
                                            <Dropdown.Item eventKey="Follow-up">Follow-up</Dropdown.Item>
                                            <Dropdown.Item eventKey="Confirmed" >Confirmed</Dropdown.Item>
                                            {/* <Dropdown.Item eventKey="Not Interested">Not Interested</Dropdown.Item> */}



                                          </DropdownButton>
                                        </td>
                                        {/* <td>
                                   <img src={EyeIcon} alt="" />
                                   <img
                                  
                                     style={{ paddingLeft: "5px" }}
                                     src={DownloadIcon}
                                     alt=""
                                   />
                                 </td> */}
                                      </tr>
                                    )}
                                  </>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </Tab>
                      </Tabs>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorServiceEnquiry;
