import React from "react";
import { Carousel } from 'react-responsive-carousel';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link, useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../config";
const Digital = (props) => {
    const recent_options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        loop: true,
        responsive: {
            0: {
                items: 1.4,
            },
            460: {
                items: 1.8,
            },
            650: {
                items: 2,
            },
            700: {
                items: 2.5,
            },
            1000: {
                items: 4,
            },
        },
    };
    const Categories = [
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/digital-1.png"
        },
        {
            name: 'New Books',
            quantity: "2",
            cate1: "/assets/images/digital-2.png"
        },
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/digital-3.png"
        },
        {
            name: 'New Books',
            quantity: "2",
            cate1: "/assets/images/digital-4.png"
        },
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/digital-1.png"
        },
        {
            name: 'New Books',
            quantity: "2",
            cate1: "/assets/images/digital-2.png"
        },
        {
            name: 'Ear buds',
            quantity: "4",
            cate1: "/assets/images/product-2.png"
        }
    ]
    const navigate = useNavigate();

    return (
        <>
            <div className="container mt-lg-5 mt-md-4 mt-3">
                <div className="new-arrivals">
                    <div className="slider-section digital-slider-img">
                        <h6 className="cate-head mb-3">{props.heading}</h6>
                        <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
                            {props?.data?.map((product,i) => (
                                <div className="text-center shadow-sm " key={i} onClick={()=>navigate("/service-categorys/"+product.slug)}>
                                   
                                    <img src={product.banner} />
                                    <h5>{product.name}</h5>
                                    <h4 className="mb-2">{product.product_count} Post</h4>
                                </div>
                            ))}
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
            {/* <div className="container-fluid mt-6 no-padding">
                <img src="/assets/images/home-section-5.png" width="100%"/>
            </div> */}
        </>
    )
}

export default Digital;