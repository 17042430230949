import React from "react";

const FeaturesDeatils = (props) => {
    console.log(props, "featured props");
    return (
        <>
            <section className="about-cont-block blog_area single-post-area">
                <div className="container-fluid" style={{ marginTop: "30px" }}>
                    <div className="row">
                        <div className="col-lg-9 posts-list">
                            <div className="single-post">
                                <div className="feature-img">
                                    <img src={props?.blogs?.image_base_url + props.featureDetailsData.image} height={200} width="100%" />
                                </div>
                                <div className="blog_details">
                                    <h2>{props.featureDetailsData.title}</h2>
                                    <p>{props.featureDetailsData.description}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="blog_right_sidebar">
                                <aside className="single_sidebar_widget popular_post_widget">
                                    <h3 className="widget_title">Recent Post</h3>
                                    <div className="media post_item">
                                        <img src="/assets/images/t1.jpg" alt="" />
                                        {/* <img src={blog.image} alt="post" /> */}
                                        <div className="media-body">
                                            <h3>testing</h3>
                                            {/* <Link to={`/blog/${blog.slug}`}>
                                                <h3>{parse(blog.content)}</h3>
                                            </Link> */}
                                            <p>hello testing content</p>
                                        </div>
                                    </div>
                                    {/* {isLoading ? <Loader /> : recentBlogs.map((recentBlog) => <BlogRecent blog={recentBlog} key={recentBlog.id} />)} */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}


export default FeaturesDeatils;