import React from 'react';
import "./HomePage.css"
import SecondSection from '../products/Components/SecondSection';
import ThirdSection from '../products/Components/ThirdSection';
import ProductsCard from './components/ProductsCard';
import EmpowerBussiness from './components/EmpowerBussiness';
import VerticalStepper from './components/BussinessSteps';
import Help from './components/BecomeSeller';
import BecomeBuyer from './components/BecomeBuyer';
import OurCompetitors from './components/OurCompetitors';
import TopInvestors from './components/TopInvestors';
import BannerPage from './components/Banner';
import { MobileFooter } from './components/MobileFooter';
import { ApnashaherSecond } from './components/ApnashaherSecond';
import { BannerNew } from './components/BannerNew';
import SeoHomepage from '../../seo page/SeoHomepage';


const HomePage = () => {
    return (
        <>
        <SeoHomepage/>
            <MobileFooter />
            <div className="show-desktop-banner">
                <BannerPage />
            </div>
            <div className="show-mobile-banner">
                <BannerNew />
            </div>
            
            <SecondSection />
            <ThirdSection />
            <ProductsCard />
            <EmpowerBussiness />
            <Help />
            <VerticalStepper />
            <ApnashaherSecond />
            {/* <ThirdSection /> */}
            <BecomeBuyer />
            <OurCompetitors />
            <TopInvestors />

        </>
    )
}

export default HomePage;