import React,{useState,useEffect} from 'react'
import "../style/Sellerbenefites.css"
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import { Whysell } from '../components/seller-benefites/Whysell'
import { Spmbanner } from '../components/seller-benefites/Spmbanner'
import { Sbsection4 } from '../components/seller-benefites/Sbsection4'
import { ApnashaherSecond } from '../components/apna-shaher/components/ApnashaherSecond'
import Help from '../components/apna-shaher/components/BecomeSeller'
import { Sellertestimonial } from '../components/seller-benefites/Sellertestimonial'
import { Sellerhelp } from '../components/seller-benefites/Sellerhelp'
 import axios from 'axios';
 import { BASE_URL } from '../config'
import BecomeSellerSellerBenefit from '../components/apna-shaher/components/BecomeSellerSellerBenefit';
import Loader from '../components/loader/Loader.component'
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import SeoSellerBenifitPage from '../seo page/SeoSellerbenifit'


export const Sellerbenefites = () => {
  const[widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    widgetData();
  }, []);
  
  useEffect(() => {
    const handleAnchorClick = (e) => {
      e.preventDefault();
      const href = e.target.getAttribute('href');
      navigate(href);
    };

    // Attach event listener to all anchor tags
    const anchors = document.querySelectorAll('a');
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });

    // Cleanup: Remove event listeners on component unmount
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, [navigate]);
    const widgetData = async () => {
        try {
          const wgtData = await axios.get(BASE_URL + "/sellerbenefitwidget");
          setwidget(wgtData.data.data);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      if (isLoading) {
        return <Loader />;
      }
  return (
    <div style={{ background:"#F5F5F5"}}>
<SeoSellerBenifitPage/>
    
    <section dangerouslySetInnerHTML={{__html: widget.widget1}}></section>
   {/* <section>
    <Sellerbanner/>
   </section>

    <section className="why-sell-parent">
      <Whysell/>
    </section>

    <section className="spm-banner-parent">
      <Spmbanner/>
    </section>

    <section className="sbsection4-parent">
      <Sbsection4/>
    </section> */}

    <section>
      <BecomeSellerSellerBenefit data={widget} />
    </section>

    <section className="testimonial-parent text-center">      
    <Sellertestimonial data = {widget}/>      
    </section>

    {/* <section className="seller-help-parent">
      <Sellerhelp data={widget}/>
    </section> */}

    </div>
  )
}
