import React, { useEffect, useState } from "react";
import { ServicecatComp } from "../components/ServicecatComp";
import ServicesAdds from "../components/Services/Components/ServicesAdds";
import axios from "axios";
import { BASE_URL } from "../config";
export const ServiceCategory = () => {
  const [data, setdata] = useState([]);
  useEffect(() => {
    getservices();
  }, []);
  function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }
  const getservices = () => {
    axios
      .get(BASE_URL + "/getallservices")
      .then(({ data }) => {
        if (data?.data) {
          setdata(chunkArray(data.data, 18));
        }
      })
      .catch(({ response }) => {});
  };
  return (
    <>
      {data &&
        data.map((item, i) => (
          <>
            <section>
              <ServicecatComp data={item} />
            </section>
            {i != data.length - 1 && (
              <section>
                <ServicesAdds />
              </section>
            )}
          </>
        ))}
    </>
  );
};
