import React, { useState } from "react";
import SellerHeader from './SellerHeader';
import SellerFooter from './SellerFooter';
import { NavLink } from "react-router-dom";
import SellerBlogComponent from './SellerBlogComponent'

const data = [
    {id:1},{id:2},{id:3},{ id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }
]

export default function SellerBlog() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
    return (
        <div style={{background:'#F5F5F5'}}>
            <SellerHeader />
            <section className="breadcrumb-sction mt-2">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/seller-website">Home</NavLink>
              </li>
              <li>Blog</li>
            </ul>
          </div>
        </section>
            <div className="col-md-12 card-body-blk">
            <SellerBlogComponent data={data} />               
            </div>
            <SellerFooter />
        </div>
    )
}