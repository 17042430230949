import React,{useState,useEffect} from "react";
import "../../style/Directory.css"
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
export const DirectoryComp2 = (props) => {
  const[directorydata, setdirectorydata] = useState([])
  useEffect(() => {
    getdirectorydata();
  }, []);
    const getdirectorydata = async () => {
        try {
          const dirData = await axios.get(BASE_URL + "/getdirectory");
          setdirectorydata(dirData.data.data);
        } catch (err) {
          console.log(err);
        }
      };
  return (
    <>
      <div className="directory-comp-2nd my-lg-5 my-4">
        <div className="container">
          <div className="top-content text-center my-3 mb-lg-5 mb-4 " dangerouslySetInnerHTML={{__html:props?.data?.widget1}}>
            
          </div>

          <div className="cards-container">
            <div className="cards d-flex justify-content-between gap-3">
              {directorydata && directorydata.map((data,i)=>(
                <div class="card mr-3 shadow-sm">
                <img src={data.image} class="card-img-top" alt="..." />
                <div class="card-body pt-0">
                  <h5 class="card-title">{data.title}</h5>
                  <p class="card-text" dangerouslySetInnerHTML={{__html:data.description}}>
                    
                  </p>
                  <div className="d-flex justify-content-between card-links">
                    <Link to={"/"+data.slug} class="btn">
                    Explore Now
                    </Link>
                    <Link to="/sign-up" class="btn">
                    Register Now
                    </Link>
                  </div>
                </div>
              </div>
              ))}
              
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
