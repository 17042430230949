import React, { useEffect } from 'react'
import "../style/Aboutus.css"
import { AboutBanner } from '../components/about-us/AboutBanner'
import { AboutComp2 } from '../components/about-us/AboutComp2'
import { AboutComp3 } from '../components/about-us/AboutComp3'
import { AboutComp4 } from '../components/about-us/AboutComp4'
import { AboutComp5 } from '../components/about-us/AboutComp5'
import SeoAboutusPage from '../seo page/Seoaboutuspsge'

export const Aboutus = () => {
  useEffect(() =>{
    window.scrollTo(0,0)
  },[])
  return (
    <div className="about-us">
      <SeoAboutusPage/>
      <section>
        <AboutBanner/>
      </section>

      <section>
        <AboutComp2/>
      </section>

      <section>
        <AboutComp3 imgUrl={"/assets/images/coo-user.png"}/>
      </section>

      <section>
        <AboutComp4/>
      </section>

      <section>
        <AboutComp5/>
      </section>
      
    </div>
  )
}
