import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Accordion, AccordionItem } from 'react-light-accordion';
import 'react-light-accordion/demo/css/index.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import Footer from "./Components/Footer";
import axios from "axios";
import { BASE_URL, DOMAIN_NAME, IMAGE_BASE_URL } from "../../config";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import ContactUs from "./Components/ContactUs";
const TruncHtml = require('trunc-html');

const SellerPage = (props) => {
    const [headerMenu, setHeadermenu] = useState("")
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [image, setImage] = useState("");
    const [feedback, setFeedback] = useState("");
    const [homepagewidget, sethomepagewidget] = useState("");
    const [testimonial, settestimonial] = useState("");
    const [testimonials, settestimonials] = useState([]);
    const { slug } = useParams();
    const [menu, setmenu] = useState([]);
    const [product, setProducts] = useState([]);
    const [features, setFeautures] = useState([]);
    const [aboutUs, setAboutUs] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [faq, setFaq] = useState([]);
    const [brands, setBrands] = useState([]);
    const [footer, setFooter] = useState([]);
    const [slider, setSlider] = useState([]);
    const [error, seterror] = useState([]);
    const [compnayIntro, setCompanyIntro] = useState({})
    const [readmoreaboutus, setreadmoreaboutus] = useState(false)
    const [forwardactive, setforwardactive] = useState(false)
    const [backwardactive, setbackwardactive] = useState(false)

    useEffect(() => {
        fetcheader();
        fetchProducts();
        fetchFeatures();
        fetchAboutUs();
        fetchBlogs();
        fetchFaqs();
        fetchBrands();
        fetchSliders();
        fetchCompanyIntro();
    }, []);

    const handleImage = e => {
        setImage(e.target.files[0]);
    }

    const submitEnquiry = () => {
        seterror("")
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobile_number', mobileNumber);
        formData.append('image', image);
        formData.append('country_code', "+91");
        formData.append('feedback', feedback);
        axios.post(BASE_URL + `/seller/addquery`, formData, {
            headers: {
                'slug': slug
            }
        })
            .then((res) => {
                console.log(res);
                if (res.status == '200') {
                    console.log("set enqiury called");
                    toast.success("Query Send Successfully")
                    document.getElementById("reset").reset();
                    setName("");
                    setEmail("");
                    setMobileNumber("");
                    setFeedback("");
                }
            })
            .catch(({ response }) => {
                seterror(response.data.message)
                console.log(error, "status");
            });
    }

    const fetchProducts = async () => {
        await axios.get(BASE_URL + '/seller/product', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setProducts(data.data)
        }).catch(({ response }) => {

        })
    }

    const fetchSliders = async () => {
        await axios.get(BASE_URL + '/seller/getslider-home', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setSlider(data)
        }).catch(({ response }) => {

        })
    }

    const fetcheader = async () => {
        await axios.get(BASE_URL + '/seller/headermenu', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setmenu(data.data)
        }).catch(({ response }) => {

        })
        await axios.get(BASE_URL + '/seller/getheader', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            // setheader(data)
        }).catch(({ response }) => {

        })
    }

    const fetchFeatures = async () => {
        await axios.get(BASE_URL + '/seller/sellerfeature', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setFeautures(data)
        }).catch(({ response }) => {

        })
    }

    const fetchAboutUs = async () => {
        await axios.get(BASE_URL + '/seller/about-us', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setAboutUs(data)
        }).catch(({ response }) => {

        })
    }

    const fetchBlogs = async () => {
        await axios.get(BASE_URL + '/seller/blogs', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setBlogs(data)
        }).catch(({ response }) => {

        })
    }

    const fetchBrands = async () => {
        await axios.get(BASE_URL + '/seller/brandsponsor', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setBrands(data)
        }).catch(({ response }) => {

        })
    }
    const fetchFaqs = async () => {
        await axios.get(BASE_URL + '/seller/faqs', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setFaq(data)
        }).catch(({ response }) => {

        })
    }

    const fetchCompanyIntro = async () => {
        await axios.get(BASE_URL + '/seller/companyintro', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            setCompanyIntro(data?.data[0])
        }).catch(({ response }) => {

        })
        await axios.get(BASE_URL + '/seller/homepagewidget', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            sethomepagewidget(data?.data)
        }).catch(({ response }) => {

        })
        await axios.get(BASE_URL + '/seller/gettestimonial', {
            headers: {
                'Accept': 'application/json',
                'slug': slug
            }
        }).then(({ data }) => {
            settestimonials(data?.data)
            settestimonial(data?.data[0])
            if (data?.data?.length > 1) {
                setforwardactive(true)
            }
        }).catch(({ response }) => {

        })
    }

    const headerState = (slug) => {
        setHeadermenu(slug);
        console.log(slug, "item isd");
    }
    const backtestimonial = () => {
        var g1 = testimonial;
        let index1 = testimonials.indexOf(g1);
        if (index1 === 1) {
            setbackwardactive(false)
        }
        if (testimonials.length > testimonials.length - index1) {
            setforwardactive(true)
            settestimonial((testimonials.slice(index1 - 1, index1))[0]);
        }
    }
    const forwardtestimonial = () => {
        var g1 = testimonial;
        let index1 = testimonials.indexOf(g1);
        console.log(index1)
        if (testimonials.length - index1 === 2) {
            setforwardactive(false)
        }
        if (testimonials.length >= index1 + 2) {
            setbackwardactive(true)
            settestimonial((testimonials.slice(index1 + 1, index1 + 2))[0]);
        }
    }

    const faqQuestions1 = faq?.data?.slice(0, 3);
    const faqQuestions2 = faq?.data?.slice(3, 6);
    console.log("blogs", blogs);
    return (
        <>
            <div>
                <div className="container">
                    <div className="row seller-first-section">
                        <h1>{homepagewidget?.widget1}</h1>
                        <img src={IMAGE_BASE_URL + compnayIntro?.comp_intr_image} />
                        <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: compnayIntro?.comp_intr }}></div>
                    </div>
                </div>
                <div className="container seller-second-section">
                    <h2>{homepagewidget.widget2}</h2>
                    <div className="row">
                        {product.map((item, index) => (
                            <div className="col-md-3 seller-product-section" key={index} onClick={() => props.handleProductDetails(item)}>
                                <div className="digital-product-section-border">
                                    <img src={item.thumbnail} height={170} />
                                    <div className="paddind-1">
                                        <p title={item.name}>{item.name.length > 20 ? item.name.slice(0, 16) + "...." : item.name}</p>
                                        <div>
                                            <button className="product-rating-btn">{item.rating}</button>
                                            <img src="/assets/images/star-icon.png" />
                                        </div>
                                    </div>
                                    <div className="price-section paddind-1">
                                        <div>
                                            <h3>{item.base_discounted_price}<del>{item.base_price}</del></h3>
                                        </div>
                                    </div>
                                    <div className="send-enquiry-btn">
                                        <button>Send Enquire</button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="load-more-btn">
                        <button>Load More</button>
                    </div>
                </div>
                <div className="seller-third-section">
                    <div className="container">
                        <h2>{homepagewidget.widget3}</h2>
                        <p>{homepagewidget.widget4}</p>
                        <div className="row">
                            {features?.data?.map((item, index) => (
                                <div className="col-md-4 seller-feature-section" onClick={() => props.handleFeatureDetails(item)}>
                                    <div className="digital-product-section-border seller-product-section-pd">
                                        <img src={features.image_base_url + item.image} height={250} width="100%" />
                                        <h6>{item.title}</h6>
                                        <h5>{item.description}</h5>
                                        <button>khow more  <img src="/assets/images/arrow-grey.png" /></button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row seller-about-section">
                        <div className="col-md-7 seller-about-pd-right">
                            <h1>{homepagewidget.widget5}</h1>
                            <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: readmoreaboutus ? aboutUs?.data?.content : TruncHtml(aboutUs?.data?.content, 200).html }}></div>
                            <buton className="read-more-btn" onClick={() => setreadmoreaboutus(!readmoreaboutus)}>{readmoreaboutus ? "Read Less" : "Read More"}</buton>
                        </div>
                        <div className="col-md-5">
                            <img src={aboutUs?.image_base_url + aboutUs?.data?.image} />
                        </div>
                    </div>
                </div>
                <div className="container seller-blog-section">
                    <h2>{homepagewidget.widget6}</h2>
                    <p>{homepagewidget.widget7}</p>
                    <div className="row">
                        {blogs?.data?.map((item, index) => (
                            <div className="col-md-3 seller-product-section" onClick={() => props.handleBlogDetails(item)}>
                                <div className="digital-product-section-border">
                                    <img src={blogs.image_base_url + item.image} height={200} width="100%" />
                                    <div className="blog-info">
                                        <h6>{item.title}</h6>
                                        <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: TruncHtml(item.content, 300).html }}></div>
                                        <button>Read more  <img src="/assets/images/arrow-grey.png" /></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="container seller-faq-section">
                    <h2>{homepagewidget.widget8}</h2>
                    <h3>{homepagewidget.widget9}</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 faq-left">
                                {faqQuestions1?.map((item, index) => (
                                    <Accordion atomic={true}>
                                        <AccordionItem title={item.question}>
                                            <p>{item.answer}</p>
                                        </AccordionItem>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="col-md-6 faq-right">
                                {faqQuestions2?.map((item, index) => (
                                    <Accordion atomic={true}>
                                        <AccordionItem title={item.question}>
                                            <p>{item.answer}</p>
                                        </AccordionItem>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <h2>{homepagewidget.widget10}</h2>
                        {brands?.data?.map((item, index) => (
                            <div className="col-md-2">
                                <img src={brands?.image_base_url + item.image} width={200} height={100} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="">
                    <div className="">
                        <div className="seller-fourth-section seller-pd-2">
                            <div className="testimonials-form">
                                <h2 className="testimonials-head-1">{homepagewidget.widget11}</h2>
                                <Form id="reset">
                                    <div className="seller-subscribe-card">
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Control type="text" placeholder="Full Name"
                                                value={name} onChange={(e) => setName(e.target.value)}
                                            />
                                            {error && error.name && (
                                                <span className="text-danger">{error.name}</span>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Control type="email" placeholder="Email"
                                                value={email} onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {error && error.email && (
                                                <span className="text-danger">{error.email}</span>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Control type="number" placeholder="Phone Number"
                                                value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)}
                                            />
                                            {error && error.mobile_number && (
                                                <span className="text-danger">{error.mobile_number}</span>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Control type="file" placeholder="Upload Image"
                                                onChange={handleImage}
                                            />
                                            {error && error.image && (
                                                <span className="text-danger">{error.image}</span>
                                            )}
                                        </Form.Group>
                                        <FloatingLabel
                                            controlId="floatingTextarea"
                                            label="Feedback"
                                            className="mb-3"
                                        >
                                            <Form.Control as="textarea" placeholder="Leave a comment here"
                                                value={feedback} onChange={(e) => setFeedback(e.target.value)}
                                            />
                                            {error && error.feedback && (
                                                <span className="text-danger">{error.feedback}</span>
                                            )}
                                        </FloatingLabel>
                                        <button type="button" className="get-in-touch-btn" onClick={submitEnquiry}>GET IN TOUCH</button>
                                    </div>
                                </Form>
                            </div>
                            <div className="testimonials-bg-img2 seller-testimonials-content">
                                <h2>{homepagewidget.widget12}</h2>
                                <img src="/assets/images/double-quotes.png" />
                                <p>{testimonial?.feedback}</p>
                                <div className="seller-testimonials">
                                    <div className="testimonils-section">
                                        <div>
                                            <img height={192} width={192} src={IMAGE_BASE_URL + testimonial?.image} />
                                        </div>
                                        <div style={{ marginLeft: "15px" }}>
                                            <h6>{testimonial?.name}</h6>
                                            {/* <p>Writer</p> */}
                                        </div>
                                    </div>
                                    <div>{
                                        testimonials.length > 1 && (
                                            <>
                                                {backwardactive ? (
                                                    <img src="/assets/images/r1.png" onClick={backtestimonial} />
                                                ) : (
                                                    <img src="/assets/images/l1.png" onClick={backtestimonial} />
                                                )}
                                                {forwardactive ? (
                                                    <img src="/assets/images/l2.png" onClick={forwardtestimonial} />
                                                ) : (
                                                    <img src="/assets/images/r2.png" onClick={forwardtestimonial} />
                                                )}
                                            </>
                                        )

                                    }




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}

export default SellerPage;