import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import CopyIcon from '../components/dashboard/copy.png';
import EyeIcon from '../components/dashboard/dash-eye.png'
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const productsData = [
  {
    id: 1,
    heading: "Lorem ipsum dolor sit amet, consectetur ",
    shortHeading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...."  
  },
  {
    id: 2,
    heading: "Lorem ipsum dolor sit amet, consectetur ",
    shortHeading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...."  
  },
  {
    id: 3,
    heading: "Lorem ipsum dolor sit amet, consectetur ",
    shortHeading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...."  
  },
  {
    id: 4,
    heading: "Lorem ipsum dolor sit amet, consectetur ",
    shortHeading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...."  
  },
  {
    id: 5,
    heading: "Lorem ipsum dolor sit amet, consectetur ",
    shortHeading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
    content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor...."  
  },
];

const VendorProducts = () => {

  const [blog, setBlog] = useState([]);
  const token = localStorage.getItem('avonToken');
  
  useEffect(() => {
    FetchBlogs();
  }, [])

  const FetchBlogs = async () => {

    await axios.get(BASE_URL + '/getsellerblog', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setBlog(data.data)
    })

  }

  const DeleteBlog = async (id) => {
    await axios.delete(BASE_URL + `/deletesellerblog/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchBlogs();
      console.log('dbghdb', data)
      if (data.status == true) {
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        toast.error(response.message)
      }
    })
  }
 
  return (
    <Page title="Vendor Products">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Blogs</li>
            </ul>
            <div className="col-md-12 ">
            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
              </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4 className="bold">
                  Blog
                  <Link to="/vendor-add-new-blog">
                  <Button style={{ float: "right", backgroundColor: '#00B987' }} variant="success">
                     
                    Add New Blog
                  </Button>
                  </Link>
                </h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h5>Blogs</h5>
                    
                    </div>
                    <div className="card-body-blk">
                      <Table responsive="md" hover>
                        <thead>
                          <tr>
                            <th >Title</th>
                            <th >Content</th>
                            <th >Image</th>
                            <th>Status</th>
                            <th ></th>                         
                          </tr>
                        </thead>
                        <tbody>
                          {blog && blog.map((data) => (
                            <tr key={data.id}>                              
                              <td style={{padding: '13px'}}>{data.title}</td>
                              <td style={{padding: '13px'}}>{data.content}</td>                                                       
                              <td style={{padding: '13px'}}>Thumb Image</td>                                                         
                              <td style={{padding: '13px'}}>{data.status}</td>                                                         
                              <td style={{width:'150px'}}>
                              <Link to={`/vendor-blog/${data.slug}`} state={data.id}>  <img style={{paddingLeft: '5px'}} src={EyeIcon} alt="" /> </Link>
                               <Link to="/vendor-edit-blog" state={data.id}> <img style={{paddingLeft: '5px'}} src={EditIcon} alt="" />  </Link>
                                <img style={{paddingLeft: '5px'}}  src={DeleteIcon} alt="" onClick={() => DeleteBlog(data.id)} />
                                </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorProducts;
