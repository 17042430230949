import React, { useEffect, useState } from "react";
import "../HomePage.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../config";
import { useNavigate, useLocation } from "react-router-dom";

const BannerPage = (props) => {
  const [search, setSearch] = useState("");
  const [product, setproduct] = useState([]);
  const [category, setCategory] = useState("");
  const [productSubLinks, setProductSubLinks] = useState(false);
  const [servicesSubLinks, setServicesSubLinks] = useState(false);
  const [directorySubLinks, setDirectorySubLinks] = useState(false);
  const[allwidget, setAllwidget] = useState([])

  const navigate = useNavigate();
  const location = window.location.pathname;

  // Get widget data from api
  const widgetData = async() => {
    try{
        const wgtData = await axios.get(BASE_URL + "/homepagewidget");
        setAllwidget(wgtData.data.data)
    }
    catch(err) {
        console.log(err)
    } 
}

// console.log("Wedgit", allwidget.widget1)

useEffect(() => {
  widgetData()
},[])


  useEffect(() => {
    if (location === "/products-page") {
      return setProductSubLinks(true);
    }
    if (location === "/services") {
      return setServicesSubLinks(true);
    }
    if (location === "/directory") {
      return setDirectorySubLinks(true);
    }
    
    
  }, [location]);
  
  // Searh Post api
  const handlesearchproduct = (e) => {
    setSearch(e.target.value);
    axios
      .post(BASE_URL + "/searchproduct", {
        search: e.target.value,
        category: category,
      })
      .then((res) => {
        setproduct(res.data.data);
      });
  };
  var businessnature = [
    "Manufacturer",
    "Exporters & Importers",
    "Suppliers",
    "Distributors",
    "Retailers",
    "Service Providers",
    "Educational Institutes",
  ];

  return (
    <>
      <div className="container-fluid main-banner-bg">
        <div className="banner-links">
          <ul className="products-banner flex-wrap">
            <Link to="/">
              <li style={ location ==="/" ? { color:'#ffb800', borderBottom: "2px solid" } :{}}> Home </li>
            </Link>
            <Link to="/products-page">
              <li style={ location ==="/products-page" ? { color:'#ffb800', borderBottom: "2px solid" } :{}} > Products </li>
            </Link>

            <Link to="/services">
              <li style={location ==="/services" ? { color:'#ffb800', borderBottom: "2px solid" }: {}} >Services</li>
            </Link>

            <Link to="/directory">
              <li style={location ==="/directory" ? { color:'#ffb800', borderBottom: "2px solid" }: {}}>Directory</li>
            </Link>
          </ul>
          <div className="search-bar-width ">
            <div className="searchheade-block hide-in-mobile">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Search Products, Services, Directory"
                    onChange={handlesearchproduct}
                  />

                  <div className="hide-in-mobile">
                    <button className="btn-search">
                      <img
                        src="../assets/images/search-icon.png"
                        className="mr-2"
                      />
                    </button>
                  </div>

                  <div className="hide-in-desktop">
                    <button className="btn-search">
                      <img src="../assets/images/search-icon.png" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="hide-in-desktop position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search Products..."
                onChange={handlesearchproduct}
                style={{ borderRadius: "8px" }}
              />
              <button className="btn-search position-absolute end-0 top-0">
                <img
                  src="../assets/images/search-icon.png"
                  width="20px"
                  height="20px"
                  class="mt-2 me-2"
                />
              </button>
            </div>
            {product.length > 0 && search && (
              <div className="avon-search-input">
                {product &&
                  product.map((item, i) => (
                    <div
                      className="search-products"
                      key={i}
                      onClick={() => {
                        navigate("/product/" + item.slug);
                        setproduct("");
                      }}
                    >
                      <div>
                        <img src={item.thumbnail} className="search-img" />
                      </div>
                      <div>
                        <p className="search-product-name">{item.name}</p>
                        <p className="search-product-cate">{item.category}</p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          {/* product sub links */}
          {productSubLinks ? (
            <ul className="banner-services my-lg-3 flex-wrap d-flex  my-md-3 my-2">
              <li className=" border-2 border-white border-end mr-1 pe-1 ">
               <Link to="/categorys/products-page" >All Products</Link> 
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1 ">
              <Link to="/categorys/flash-deals" >Flash Deals</Link>
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1 ">
              <Link to="/categorys/new-arrival" >New Arrival</Link> 
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1 ">
              <Link to="/categorys/stock-clearance" >Stock Clearance</Link>  
              </li>
              <li className=" border-2 border-white  mr-1 pe-1 ">
              <Link to="/Manufacturer" >Manufacturers</Link> {" "}
              </li>
              {/* <li className=" mr-1 pe-1 ">
              <Link to="/export-import" >Importers & Exporters</Link> {" "}
              </li>
              <li className=" mr-1 pe-1 ">
              <Link to="/export-import" >Directory Listing</Link> {"/directory-listing"}
              </li> */}
            </ul>
          ) : null}

          {/* Services Sub Links  */}
          {servicesSubLinks ? (
            <ul className="banner-services my-lg-3 flex-wrap d-flex  my-md-3 my-2">
              <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/service-category">Show All Services</Link>  
              </li>
              {props?.data?.map((item,i)=>(
                <li className={props?.data?.length-1 != i ?" border-2 border-white border-end mr-1 pe-1" :""} key={i}>
                <Link to={"/service-categorys/"+item.slug}>{item.name}</Link>  
                </li>
              ))}
              {/* <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/categorys/digital-services">Digital Services</Link>  
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/categorys/professional-services"> Professional Services</Link> {" "}
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/directory-listing"> Directory Listing</Link> {" "}
              </li>
              <li className="">
                Service Providers
              </li> */}
            </ul>
          ) : null}

          {/* Directory Sub Links */}
          {directorySubLinks ? (
            <ul className="banner-services my-lg-3 flex-wrap d-flex my-md-3 my-2">
              {businessnature && businessnature.map((item,i)=>(
                <li className={businessnature.length -1 != i ? "border-2 border-white border-end mr-1 pe-1" :""} key={i}>
                <Link to={"/directory-listing/"+item}  >{item}</Link>
              </li>
              ))}
              

             
            </ul>
          ) : null}
        </div>
      </div>

      {/* Banner Bottom Part */}
      <div className="banner-bottom-part">
        <div className="banner-bottom-content"  dangerouslySetInnerHTML={{ __html: allwidget.widget1}}>
        {/* <div className="banner-bottom-content"  > */}
          {/* <div className="request-quotes" >
          
            <img src="/assets/Icons/quotes.png" width="50px" />
            <div className="pd-2">
              <h6>Request a Quote</h6>
              <p>One request, multiple quotes</p>
            </div>
          </div>
          <div className="request-quotes">
            <img src="/assets/Icons/sell-on.png" width="50px" />
            <div className="pd-2">
              <h6>Sell on APNASHAHER.COM</h6>
              <p>Reach millions of customer globally</p>
            </div>
          </div>
          <div className="request-quotes">
            <img src="/assets/Icons/your-startup.png" width="50px" />
            <div className="pd-2">
              <h6>Build your STARTUP</h6>
              <p>List your startup to grow more</p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default BannerPage;
