import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import WalletRecharge from "../components/money-modal/wallet-recharge.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from "../config";
import { Table, Button, Row, Col, Card, Badge } from "react-bootstrap";
import { useEffect } from "react";
import RechargeDetails from "./RechargeDetails";

const Wallet = () => {
  const [walletData, setwalletData] = useState([])
  const [amount, setamount] = useState('')
  const token = localStorage.getItem('avonToken');
  const [showAdd, setShowAdd] = useState(false);
  const [showRecharge, setShowRecharge] = useState(false);
  const [rechargeId, setRechargeId] = useState({})
 
  
  const fetchwallet = async () => {
    await axios.get(BASE_URL + `/getwallet`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setamount(data.amount)
      setwalletData(data.data)
    })
  }
  function handleAdd() {
    setShowAdd(true);
  }
  useEffect(() => {
    fetchwallet();
  }, [])
  function handleOpenRechargeModal(data) {
  
    setShowRecharge(true);
    setRechargeId(data);
  }

 
  return (
    <Page title="Wallet">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>My Wallet</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4>My Wallet</h4>
                <div style={{ padding: "8px 0 0 0" }} className="row">
                  <Col xs={12} md={6}>
                    <Card
                      className="money-withdrl-bal"

                    >
                      <Card.Body>
                        <Row>
                          <div className="balance-circle"></div>
                          <Col>
                            <Card.Title
                              style={{ fontSize: "20px" }}
                            >
                              ${amount}
                            </Card.Title>
                            <Col>Wallet Balance</Col>

                            {/* <Card.Subtitle className="mb-2 text-muted">Products</Card.Subtitle> */}
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs={12} md={6}>
                    <Card
                      className="money-withdrl-req mobile-margin-1"
                      onClick={handleAdd}

                    >
                      <Card.Body>
                        <Row>
                          <div className="withdraw-circle"></div>
                          <Col className="">
                            <Card.Title
                              style={{ fontSize: "17px", paddingTop: '12px' }}
                            >
                              Recharge Wallet
                            </Card.Title>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </div>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h5>Withdraw Recharge History</h5>
                    </div>
                    <div className="card-body-blk">
                      <Table responsive="md" hover style={{whiteSpace: "nowrap", overflow: "auto  "}}>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Date</th>
                            <th> Service Detail</th>
                            <th>Transaction Type</th>
                            <th>Amount</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {walletData.map((wallet, key) => (
                            <tr key={key}>
                              <td>{++key}</td>
                              <td>{wallet.created_at}</td>
                              <td>{wallet.amount}</td>
                              <td>{wallet.payment_method}</td>
                              <td>{wallet.amount}</td>
                              <td><Badge bg="warning">{wallet.status}</Badge></td>
                              <td><i class="fa fa-eye" style={{ fontSize: "16px" }} onClick={() => handleOpenRechargeModal(wallet)}></i></td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <WalletRecharge showAdd={showAdd} setShowAdd={setShowAdd} />
      <RechargeDetails showAdd={showRecharge} setShowAdd={setShowRecharge} rechargeId={rechargeId}/>
    </Page>
  );
};

export default Wallet;
