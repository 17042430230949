import React from 'react'
import { Link } from 'react-router-dom'

export const EdComp3 = () => {
  return (
    <>
    <div className="event-detail-comp3  py-lg-5 py-md-4 my-3">
        <div className="container d-flex justify-content-between align-items-cetner gap-4" >
            <div className="left col-lg-6 col-md-6 col-12">
                <h4>Past <span>Events</span></h4>

                <div className="cards d-flex justify-content-between gap-lg-3 gap-2">

                <div className="card">
                    <div className="img-div position-relative">
                    <img src="/assets/images/event-past.png" alt="" />
                    <span>Past</span>
                    </div>
                    <div className="card-body">
                        <div className="date-loc d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-2 ">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"/></svg>
                                <p className='mb-0'>Delhi, India</p>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                                <p className='mb-0'>19 Feb 2022</p>
                            </div>
                        </div>
                        <p className="bold-para">Lorem ipsum dolor sit amet, conse ctetur adipisc</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo... <Link to="">read more</Link></p>
                    </div>
                </div>

               
                <div className="card">
                    <div className="img-div position-relative">
                    <img src="/assets/images/event-past.png" alt="" />
                    <span>Past</span>
                    </div>
                    <div className="card-body">
                        <div className="date-loc d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-2 ">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"/></svg>
                                <p className='mb-0'>Delhi, India</p>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                                <p className='mb-0'>19 Feb 2022</p>
                            </div>
                        </div>
                        <p className="bold-para">Lorem ipsum dolor sit amet, conse ctetur adipisc</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo... <Link to="">read more</Link></p>
                    </div>
                </div>
                </div>
            </div>
            <div className="right col-lg-6 col-md-6 col-12">
            <h4>Past <span>Events</span></h4>

<div className="cards d-flex justify-content-between gap-lg-3 gap-2">

<div className="card">
    <div className="img-div position-relative">
    <img src="/assets/images/event-past.png" alt="" />
    <span>Past</span>
    </div>
    <div className="card-body">
        <div className="date-loc d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"/></svg>
                <p className='mb-0'>Delhi, India</p>
            </div>

            <div className="d-flex align-items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                <p className='mb-0'>19 Feb 2022</p>
            </div>
        </div>
        <p className="bold-para">Lorem ipsum dolor sit amet, conse ctetur adipisc</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo... <Link to="">read more</Link></p>
    </div>
</div>


<div className="card">
    <div className="img-div position-relative">
    <img src="/assets/images/event-past.png" alt="" />
    <span>Past</span>
    </div>
    <div className="card-body">
        <div className="date-loc d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap-2 ">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M429.6 92.1c4.9-11.9 2.1-25.6-7-34.7s-22.8-11.9-34.7-7l-352 144c-14.2 5.8-22.2 20.8-19.3 35.8s16.1 25.8 31.4 25.8H224V432c0 15.3 10.8 28.4 25.8 31.4s30-5.1 35.8-19.3l144-352z"/></svg>
                <p className='mb-0'>Delhi, India</p>
            </div>

            <div className="d-flex align-items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>
                <p className='mb-0'>19 Feb 2022</p>
            </div>
        </div>
        <p className="bold-para">Lorem ipsum dolor sit amet, conse ctetur adipisc</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo... <Link to="">read more</Link></p>
    </div>
</div>
</div>
            </div>

        </div>
    </div>
    
    </>
  )
}
