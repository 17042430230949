import React, { useState } from 'react'
import '../style/JoinOurTeam.css';
import { FormCom } from './join-our-team/FormCom';


export const JobDescription = () => {
    const [isShow, setIsShow] = useState(false)
    return (
        <>
            <div className="job-desc px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">

                {isShow === true ? 
                <FormCom/>
            : null}
                <div className="main-cont d-flex justify-content-between ">

                    <div className="left shadow-sm">
                        <img src="/assets/images/job-desc.png" alt="" />

                        <div className="my-lg-4 my-3 d-flex justify-content-between">


                            <div className="ldiv">
                                <div className="art-1 pb-lg-4 pb-3">
                                    <h4>UI/UX Designer</h4>
                                    <div className='d-flex gap-2 align-items-center'>
                                        <p className="bpara">
                                            Job Location: <span>Noida</span>
                                        </p>
                                    </div>

                                    <p>We have 2 different positions for the Front-end, UX / UI Designer & Front-end Developer.</p>
                                    <p>UX/UI Designer - required with minimum 2 Years of experience with HTML, CSS, Bootstrap, Javascript, JQuery, Figma, Zeplin or familiar with any of the tool to create UX, and Photoshop</p>
                                    <p>UX/UI Designer - required with minimum 2 Years of experience with HTML, CSS, Bootstrap, Javascript, JQuery, Figma, Zeplin or familiar with any of the tool to create UX, and Photoshop</p>
                                    <div className="d-flex align-items-center gap-lg-5 gap-4">
                                        <div className="d-flex align-items-center gap-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M13.5 3.5H11V3C11 2.60218 10.842 2.22064 10.5607 1.93934C10.2794 1.65804 9.89782 1.5 9.5 1.5H6.5C6.10218 1.5 5.72064 1.65804 5.43934 1.93934C5.15804 2.22064 5 2.60218 5 3V3.5H2.5C2.23478 3.5 1.98043 3.60536 1.79289 3.79289C1.60536 3.98043 1.5 4.23478 1.5 4.5V12.5C1.5 12.7652 1.60536 13.0196 1.79289 13.2071C1.98043 13.3946 2.23478 13.5 2.5 13.5H13.5C13.7652 13.5 14.0196 13.3946 14.2071 13.2071C14.3946 13.0196 14.5 12.7652 14.5 12.5V4.5C14.5 4.23478 14.3946 3.98043 14.2071 3.79289C14.0196 3.60536 13.7652 3.5 13.5 3.5ZM6 3C6 2.86739 6.05268 2.74021 6.14645 2.64645C6.24021 2.55268 6.36739 2.5 6.5 2.5H9.5C9.63261 2.5 9.75979 2.55268 9.85355 2.64645C9.94732 2.74021 10 2.86739 10 3V3.5H6V3ZM13.5 4.5V7.10063C11.8124 8.01924 9.92145 8.50034 8 8.5C6.07863 8.50035 4.18776 8.01947 2.5 7.10125V4.5H13.5ZM13.5 12.5H2.5V8.2275C4.21245 9.06512 6.09367 9.50037 8 9.5C9.90637 9.50004 11.7876 9.06459 13.5 8.22688V12.5ZM6.5 7C6.5 6.86739 6.55268 6.74021 6.64645 6.64645C6.74021 6.55268 6.86739 6.5 7 6.5H9C9.13261 6.5 9.25979 6.55268 9.35355 6.64645C9.44732 6.74021 9.5 6.86739 9.5 7C9.5 7.13261 9.44732 7.25979 9.35355 7.35355C9.25979 7.44732 9.13261 7.5 9 7.5H7C6.86739 7.5 6.74021 7.44732 6.64645 7.35355C6.55268 7.25979 6.5 7.13261 6.5 7Z" fill="#555555" />
                                            </svg>
                                            <p className="mb-0 ml-2">Full Time</p>
                                        </div>
                                        <div className="d-flex align-items-center gap-2 ml-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                            </svg>
                                            <p className='mb-0 ml-2'> 4 Vacancy</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="art-2 pb-lg-4 pb-3">
                                    <h4>Job Description</h4>
                                    <div className="d-flex gap-2 align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        <p className="mb-0 ml-2">Creating HTML Pages in HTML5/CSS3 and JavaScript/jQuery.</p>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        <p className="mb-0 ml-2">Maintenance of the sites and updating them.</p>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        <p className="mb-0 ml-2">Can convert PSD To HTML5..</p>
                                    </div>

                                </div>
                                <div className="art-3 pb-lg-4 bp-3">
                                    <h4>Eligible Criteria</h4>
                                    <div className="d-flex gap-2 align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        <p className="mb-0 ml-2">Creating HTML Pages in HTML5/CSS3 and JavaScript/jQuery.</p>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        <p className="mb-0 ml-2">Maintenance of the sites and updating them.</p>
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg>
                                        <p className="mb-0 ml-2">Can convert PSD To HTML5..</p>
                                    </div>

                                </div>



                            </div>

                            <div className="rdiv">
                                <h4>Get in Touch</h4>
                                <div className="my-2 d-flex gap-2 align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" /></svg>
                                    <p className="mb-0 ml-2">admin@gmail.com</p>
                                </div>
                                <div className="my-2 d-flex gap-2 align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                                    <p className="mb-0 ml-2">1800-899889999</p>
                                </div>
                                <button onClick={() => setIsShow(true)}> Apply  Now</button>
                            </div>
                        </div>
                    </div>


                    {/* Right Other Opening ---------- */}
                    <div className=" right px-lg-4 px-md-3 px-sm-3 px-2 py-lg-4 py-md-3 py-sm-3 py-2 bg-white shadow-sm">
                        <div className="search pb-4">
                            <h4>Search</h4>
                            <form action="" className='position-relative w-100'>
                                <input type="text" placeholder="Search" />
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M17.4974 17.5003L13.7591 13.7553M15.8307 8.75033C15.8307 10.6289 15.0845 12.4306 13.7561 13.759C12.4277 15.0874 10.626 15.8337 8.7474 15.8337C6.86878 15.8337 5.06711 15.0874 3.73872 13.759C2.41034 12.4306 1.66406 10.6289 1.66406 8.75033C1.66406 6.87171 2.41034 5.07004 3.73872 3.74165C5.06711 2.41327 6.86878 1.66699 8.7474 1.66699C10.626 1.66699 12.4277 2.41327 13.7561 3.74165C15.0845 5.07004 15.8307 6.87171 15.8307 8.75033Z" stroke="#1351C1" stroke-linecap="round" />
                                    </svg>
                                </span>
                            </form>
                        </div>

                        <h4>Other Opening</h4>
                        <div className="recent-posts">
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                            <div className="rp-card py-3 d-flex gap-lg-4 gap-3 align-items-center" >
                                <img src="/assets/images/investor-1.png" alt="" />
                                <div className='ml-3'>
                                    <p className="bold-para mb-1">Graphics Designer</p>
                                    <p className="mb-1 location">
                                        <span className='pe-2'><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.94582 5.77449L11.6216 1.16767C13.0323 0.495562 14.5044 1.96838 13.833 3.3798L9.22587 13.054C8.59603 14.3758 6.68743 14.2944 6.17294 12.9229L5.32154 10.6501C5.23835 10.4284 5.10867 10.2271 4.94121 10.0596C4.77375 9.89219 4.57238 9.76251 4.35065 9.67933L2.07693 8.82717C0.706061 8.31272 0.623909 6.40427 1.94582 5.77449Z" stroke="#555555" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg></span>
                                        Delhi, India</p>
                                    <p className="mb-1 date">
                                      <span className="pe-2"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M14.4326 13.7497C14.3887 13.8258 14.3256 13.8889 14.2496 13.9328C14.1735 13.9767 14.0873 13.9998 13.9995 13.9997H1.99949C1.91176 13.9997 1.82561 13.9765 1.74967 13.9326C1.67373 13.8886 1.61068 13.8255 1.56686 13.7495C1.52304 13.6735 1.49998 13.5873 1.5 13.4996C1.50002 13.4119 1.52312 13.3257 1.56699 13.2497C2.51886 11.6041 3.98574 10.4241 5.69761 9.86475C4.85084 9.36066 4.19295 8.59255 3.82498 7.67837C3.457 6.76419 3.39929 5.7545 3.6607 4.80435C3.92211 3.85419 4.48819 3.01612 5.272 2.41883C6.05582 1.82153 7.01403 1.49805 7.99949 1.49805C8.98494 1.49805 9.94316 1.82153 10.727 2.41883C11.5108 3.01612 12.0769 3.85419 12.3383 4.80435C12.5997 5.7545 12.542 6.76419 12.174 7.67837C11.806 8.59255 11.1481 9.36066 10.3014 9.86475C12.0132 10.4241 13.4801 11.6041 14.432 13.2497C14.476 13.3257 14.4992 13.4119 14.4993 13.4997C14.4994 13.5874 14.4764 13.6737 14.4326 13.7497Z" fill="#555555" />
                                        </svg> </span> 
                                        4 Vacancy </p>

                                </div>
                            </div>
                           

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
