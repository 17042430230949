import React from 'react'
import "../style/NewLeads.css"
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";

export const NewLeads = () => {

    return (
        <>
            <div className="new-leads py-4" style={{ background: "#0000001a" }}>

                <div className="main-cont d-flex justify-content-between">

                    <VendorDashboardSideMenu />

                    {/* rght side Cont  */}

                    <div className="right px-lg-5 px-md-4 px-3 col-md-9" >

                        <h2>New Lead</h2>

                        <div className="art-1 my-lg-5  my-4">
                            <div className="d-flex justify-content-between align-items-center">
                                <h6>New Leads</h6>
                                <h6>Subscription Expiring:  <span>December 31st 2023</span></h6>
                            </div>

                            <div className=" cards d-flex justify-content-between align-items-center">
                                <div>
                                    <h5>Fresh Inquire</h5>
                                    <span>500k</span>
                                </div>
                                <div>
                                    <h5>Purchased</h5>
                                    <span>100k</span>
                                </div>
                                <div>
                                    <h5>Current Availability</h5>
                                    <span>20/50 Leads</span>
                                </div>
                                <div>
                                    <button>Bulk Buy</button>
                                </div>
                            </div>
                        </div>

                        <div className="art-2 my-lg-5 my-4">
                            <div>
                                <h6>Leads</h6>
                            </div>
                            <div className="overf-div">
                                <div className="table-div">

                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date  & Time</th>
                                                <th scope="col">Business Category</th>
                                                <th scope="col" className='product'>Product/Service</th>
                                                <th scope="col">Budgets</th>
                                                <th scope="col">Customer Name</th>
                                                <th scope="col">Location</th>
                                                <th scope="col">Cost Per Lead</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <tr>

                                                <td>2021-09-28 00:45:08</td>
                                                <td>IT & Softwares</td>
                                                <td>Website Designing & Development</td>
                                                <td>₹100-₹500</td>
                                                <td>John</td>
                                                <td>Delhi</td>
                                                <td>₹122</td>
                                                <td><button>Buy Now</button></td>
                                            </tr>
                                            <tr>

                                                <td>2021-09-28 00:45:08</td>
                                                <td>IT & Softwares</td>
                                                <td>Website Designing & Development</td>
                                                <td>₹100-₹500</td>
                                                <td>John</td>
                                                <td>Delhi</td>
                                                <td>₹122</td>
                                                <td><button>Buy Now</button></td>
                                            </tr>
                                            <tr>

                                                <td>2021-09-28 00:45:08</td>
                                                <td>IT & Softwares</td>
                                                <td>Website Designing & Development</td>
                                                <td>₹100-₹500</td>
                                                <td>John</td>
                                                <td>Delhi</td>
                                                <td>₹122</td>
                                                <td><button>Buy Now</button></td>
                                            </tr>
                                            <tr>

                                                <td>2021-09-28 00:45:08</td>
                                                <td>IT & Softwares</td>
                                                <td>Website Designing & Development</td>
                                                <td>₹100-₹500</td>
                                                <td>John</td>
                                                <td>Delhi</td>
                                                <td>₹122</td>
                                                <td><button>Buy Now</button></td>
                                            </tr>
                                            <tr>

                                                <td>2021-09-28 00:45:08</td>
                                                <td>IT & Softwares</td>
                                                <td>Website Designing & Development</td>
                                                <td>₹100-₹500</td>
                                                <td>John</td>
                                                <td>Delhi</td>
                                                <td>₹122</td>
                                                <td><button>Buy Now</button></td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
