import React,{useState,useEffect} from "react";
import "../style/Directory.css";
import BannerPage from "../components/apna-shaher/components/Banner";
import { DirectoryComp2 } from "../components/directory/DirectoryComp2";
import { DirectoryComp3 } from "../components/directory/DirectoryComp3";
import { Directorybanner } from "../components/directory/Directorybanner";
import { Sellertestimonial } from "../components/seller-benefites/Sellertestimonial";
import axios from "axios";
import { BASE_URL } from "../config";
import Loader from "../components/loader/Loader.component";
import ProfessionalClient from "../components/Services/Components/ProfessionalClient";
import { BannerNew } from "../components/apna-shaher/components/BannerNew";
import SeoDirectoryPage from "../seo page/SeoDirectorypage";
export const Directory = () => {
  const[widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [sellerdata, setsellerdata] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    widgetData();
  }, []);

  useEffect(()=>{
    const timer = setTimeout(() => {
      getprefredsellerdata();
  }, 3000);
  return () => clearTimeout(timer);
},[])
const getprefredsellerdata=()=>{
  axios
  .get(BASE_URL + "/getprefredsellerdata")
  .then(({ data }) => {
    setsellerdata(data);

   
  })
  .catch(({ response }) => { });
}
    const widgetData = async () => {
        try {
          const wgtData = await axios.get(BASE_URL + "/directorywidget");
          setwidget(wgtData.data.data);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      if (isLoading) {
        return <Loader />;
      }
      
  return (
    <>
    <SeoDirectoryPage/>
    <div style={{background:"#ffffff"}}>

 
      <section>
      <div className="show-desktop-banner">
        <BannerPage />
      </div>
        <div className="show-mobile-banner">
        <BannerNew/>
        </div>
      </section>
      
      <section>
        <DirectoryComp2 data={widget}/>
      </section>
      <div dangerouslySetInnerHTML={{__html:widget.widget2}}>

      </div>

      {/* <section> 
        <DirectoryComp3/>
      </section>

      <section>
        <Directorybanner/>
      </section> */}
    <ProfessionalClient data={sellerdata} heading = "Preferred Sellers"/>
      {/* <section>
        <Sellertestimonial data={widget}/>
      </section> */}

      </div>
    </>
  );
};
