import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import EyeIcon from '../components/dashboard/dash-eye.png'
import CopyIcon from '../components/dashboard/copy.png';
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

const VendorOurFeature = () => {

  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [storedData, setStoredData] = useState('');
  const [storedUrl, setStoredUrl] = useState();
  const [newName, setNewName] = useState();
  const [newImage, setNewImage] = useState("");
  const [newStatus, setNewStatus] = useState("active");
  const token = localStorage.getItem('avonToken');
  const [imageUrl, setImageUrl] = useState('')
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [description, setdescription] = useState('');
  const [image, setImage] = useState("");
  // const [status, setStatus] = useState('');
  const [checked, setChecked] = useState("active");
  const toggleChecked = (e) => setChecked(e.target.checked ? "active" : "block");
  const toggleStatus = (e) => setNewStatus(e.target.checked ? "active" : "block");
  const [err, setErr] = useState([]);


  useEffect(() => {
    FetchData();
  }, [])

  const FetchData = async () => {
    await axios.get(BASE_URL + '/getsellerfeature', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setData(data.data)
      setImageUrl(data.image_base_url)
    })
  }

  const FetchStoredBrands = async (id) => {

    await axios.get(BASE_URL + `/editsellerfeature/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setStoredData(data.data)
      setdescription(data.data.description)
      setNewStatus(data.data.status)
      setStoredUrl(data.image_base_url)
      setEdit(true);
    })

  }


  const DeleteData = async (id) => {
    await axios.delete(BASE_URL + `/deletesellerfeature/${id}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      FetchData();
      console.log('dbghdb', data)
      if (data.status == true) {
        toast.success(data.message)
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        toast.error(response.message)
      }
    })
  }

  const handleImage = e => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0])
  }

  const handleImageNew = e => {
    setNewImage(e.target.files[0]);
    console.log(e.target.files[0])
  }

  const AddBrand = async () => {
    const formData = new FormData();
    formData.append('title', name)
    formData.append('description', description)
    formData.append('image', image)
    formData.append('status', checked)
    await axios.post(BASE_URL + `/addupdatesellerfeature`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      console.log('dbghdb', data)
      if (data.status == true) {
        setShow(false)
        toast.success(data.message)
        FetchData()
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        setErr(response.data.message)
      }
    })
  }

  const EditBrand = async (id) => {
    const formData = new FormData();
    formData.append('title', newName ? newName : storedData.title)
    formData.append('image', newImage)
    formData.append('description', description)
    formData.append('status', newStatus ? newStatus : storedData.status)
    formData.append('id', storedData.id)
    await axios.post(BASE_URL + `/addupdatesellerfeature`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      console.log('dbghdb', data)
      if (data.status == true) {
        setEdit(false)
        toast.success(data.message)
        FetchData();
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        setErr(response.data.message)
      }
    })
  }



  return (
    <Page title="Vendor Products">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Our Feature</li>
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </ul>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4 className="bold">
                Our Feature
                  <Button style={{ backgroundColor: '#00B987' }} className="dashboard-btn" variant="success" onClick={() => setShow(true)}>
                    Add New Our Feature
                  </Button>
                </h4>
                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h5>Our Feature</h5>
                    </div>
                    <div className="card-body-blk">
                      <Table responsive="md" hover>
                        <thead>
                          <tr>
                            <th>Date & Time</th>
                            <th >Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Status</th>
                            <th>Options</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data.map((product) => (
                            <tr key={product.id}>
                              <td style={{ padding: '13px' }}>{product.updated_at}</td>
                              <td style={{ padding: '13px' }}><img src={imageUrl + product.image} style={{ maxWidth: 50, maxHeight: 50 }} /></td>
                              <td style={{ padding: '13px' }}>{product.title}</td>
                              <td style={{ padding: '13px' }}>{product.description}</td>
                              <td style={{ padding: '13px' }}>{product.status == 'active' ? <button style={{ color: 'green', background: '#E6F9F4' }}>Active</button> : <button style={{ color: 'red', background: '#E6F9F4' }}>Not Active</button>}</td>
                              <td>
                                <img style={{ paddingLeft: '5px', cursor: 'pointer' }} src={EditIcon} alt="" onClick={() => FetchStoredBrands(product.id)} />
                                <img style={{ paddingLeft: '5px', cursor: 'pointer' }} src={DeleteIcon} alt="" onClick={() => DeleteData(product.id)} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={edit} onHide={() => setEdit(false)}>
        <ModalHeader closeButton>
          Edit Feature
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            Not Active
            <Form.Switch
              type="switch"
              checked={newStatus == "active" ? true : false}
              onChange={toggleStatus}
            />
            Active
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              defaultValue={storedData.title}
              onChange={(e) => setNewName(e.target.value)}
            />
            {err && err.title ? <div className="text-danger"> {err && err.title} </div> : ''}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
               rows={3}
              defaultValue={storedData.description}
              onChange={(e) => setdescription(e.target.value)}
            />
            {err && err.description ? <div className="text-danger"> {err && err.description} </div> : ''}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label className="bold">Image</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImageNew}
            />
            <img height={100} src={imageUrl+storedData.image} />
            {err && err.image ? <div className="text-danger"> {err && err.image} </div> : ''}
          </div>
        </ModalBody>

        <Modal.Footer>
          <Button onClick={() => EditBrand(storedData.id)}>Edit</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={() => setShow(false)}>
        <ModalHeader closeButton>
          Add Feature
        </ModalHeader>
        <ModalBody>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            Not Active
            <Form.Switch
              type="switch"
              checked={checked == "active" ? true : false}
              onChange={toggleChecked}
            />
            Active
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            {err && err.title ? <div className="text-danger"> {err && err.title} </div> : ''}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              as="textarea"
               rows={3}
              onChange={(e) => setdescription(e.target.value)}
            />
            {err && err.description ? <div className="text-danger"> {err && err.description} </div> : ''}
          </div>
          <div className="mt-4 mb-4">
            <Form.Label className="bold">Image</Form.Label>
            <Form.Control
              type="file"
              onChange={handleImage}
            />
            {err && err.image ? <div className="text-danger"> {err && err.image} </div> : ''}


          </div>
        </ModalBody>

        <Modal.Footer>
          <Button onClick={AddBrand}>Add</Button>
        </Modal.Footer>
      </Modal>
    </Page>
  );
};

export default VendorOurFeature;
