import React,{useState,useEffect} from "react";
import "../style/Sellerbenefites.css";
import Slider from "react-slick";
import axios from "axios";
import { BASE_URL } from "../config";
import { IMAGE_BASE_URL } from "../config";

const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    arrows: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 400,
        breakpoint: 800,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false
        }
    }]
};



export const SellertestimonialPost = (props) => {
    const [data,setdata] = useState([]);

    useEffect(() => {
        gettestimonial();
      }, []);

        const gettestimonial = async () => {
            try {
              const tstData = await axios.get(BASE_URL + "/getfeedback");
              setdata(tstData.data.data);
              
            } catch (err) {
              console.log(err);
             
            }
          };
  return (
    <>
      <div className="seller-testimonial py-4 my-lg-5 my-md-4 my-3">

        <div dangerouslySetInnerHTML={{__html:props?.data.widget5}}></div>

        <div className="container text-center my-3">
                        <Slider {...settings} >
                        {data && data.map((item,i)=>(
                                <div className='investory-slider' key={i}>
                                <div className='col-md-6'>
                                    <div className='investor-details'>
                                        <div className='d-flex'>
                                            <img src='/assets/images/comma.png' width="20px" />
                                            <img src='/assets/images/comma.png' width="20px" />
                                        </div>
                                        <p>{item.feedback}</p>
                                        <div className='user-details-1'>
                                            <img src={IMAGE_BASE_URL+item?.user?.profile_image} width="50px" />
                                            <div>
                                                <h4>{item?.user?.name}</h4>
                                                {/* <h3>Owner</h3> */}
                                            </div>
                                        </div>
                                        <div className='user-details-1'>
                                            <img src='/assets/images/comp.png' width="20px" />
                                            <h5>Sill Farm Supplier</h5>
                                            <img src={`https://flagsapi.com/${item?.businessprofile?.countries?.code}/flat/64.png`} width="20px" />
                                            <h5>{item?.businessprofile?.countries?.name}</h5>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='col-md-6'>
                                    <img src={IMAGE_BASE_URL+item?.user?.profile_image} width="100%" />
                                </div>
                            </div>
                            ))}
                        </Slider>
                    </div>
      </div>
    </>
  );
};
