import React from 'react';

const ServicesAdvertize = () => {
    return (
        <>
            <div className="container-fluid mt-lg-6 mt-md-5 mt-3 px-0">
                <img src="/assets/images/home-section-5.png" width="100%"/>
            </div>
        </>
    )
}

export default ServicesAdvertize;