import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import Page from "../components/Page";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Table, Button, Form } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.svg";
import EditIcon from '../components/dashboard/edit.png';
import DeleteIcon from '../components/dashboard/delete-icon.png';
import CopyIcon from '../components/dashboard/copy.png';
import EyeIcon from '../components/dashboard/dash-eye.png'
import { useEffect } from "react";
import { async } from "q";
import axios from "axios";
import { BASE_URL } from "../config";
import { useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const VendorEditBlog = () => {

    const location = useLocation();
    console.log(location)
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [status, setStatus] = useState('');
  const [slug, setSlug] = useState('');
  const token = localStorage.getItem('avonToken');
  const [err, setErr] = useState([]);

  const navigate = useNavigate();

  const handleImage = e => {
    setImage(e.target.files[0]);
    console.log(e.target.files[0])
  }
  const handletitle=(e)=>{
    setTitle(e.target.value);
    setSlug(e.target.value.toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, ''));
  }
  const [blog, setBlog] = useState([]);
  const [baseUrl, setBaseUrl] = useState();
  
  useEffect(() => {
    FetchBlogs();
  }, [])

  const FetchBlogs = async () => {
    if(location.state){
    await axios.get(BASE_URL+`/editsellerblog/${location.state}` , {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setBlog(data.data)
      setBaseUrl(data.image_base_url)
    })
}
  }

  const AddBlog = async () => {
    const formData = new FormData();
    formData.append('title',title ? title : blog.title)
    formData.append('slug',slug ? slug : blog.slug)
    formData.append('image',image ? image : blog.image)
    formData.append('content',content ? content : blog.content)
    formData.append('status',status ? status : blog.status)
    formData.append('id', location.state)
    await axios.post(BASE_URL + `/addupdatesellerblog`, formData, {
      headers: {
        'content-type': 'multipart/form-data',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      console.log('dbghdb', data)
      if (data.status == true) {
        toast.success(data.message)
        navigate('/vendor-blogs')
      }
    }).catch(({ response }) => {
      // console.log(response.data)
      if (response.data.status == 'error') {
        setErr(response.data.message)
      }
    })
  }

  return (
    <Page title="Vendor Products">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Add Blogs</li>
            </ul>
            <div className="col-md-12">
              <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section>
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <h4 className="bold">
                  Blog
                  <Link to="/vendor-blogs">
                    <Button style={{ float: "right", backgroundColor: '#00B987' }} variant="success">

                      Back
                    </Button>
                  </Link>
                </h4>

                <div className="vendor-order-section">
                  <div className="product-card-block">
                    <div className="order-head-blk">
                      <h4>Blogs</h4>

                    </div>
                    <div className="card-body-blk">
                      <div className="col-md-12">
                        <h6>Enter Title</h6>
                        <Form.Control
                          type="text"
                          as="textarea" rows={2}
                          placeholder="Enter Blog Title"
                        onChange={handletitle}
                        defaultValue={blog && blog.title}
                        // disabled={data && data.full_address && !edit ? true : false}
                        />
                        {err.title ? <div className="text-danger">{err.title}</div> : ''}

                      </div>
                      <div className="col-md-12 mt-4">
                        <h6>Enter Blog Content</h6>
                        <Form.Control
                          type="text"
                          as="textarea" rows={4}
                          placeholder="Enter Blog Content"
                        onChange={(e) => setContent(e.target.value)}
                        defaultValue={blog && blog.content}

                        // defaultValue={data && data.full_address == null ? '' : data && data.full_address}
                        // disabled={data && data.full_address && !edit ? true : false}
                        />
                        {err.content ? <div className="text-danger">{err.content}</div> : ''}

                      </div>
                      <div className="row">
                        <div className="col-md-4 mt-4">
                          <h6>Enter Slug</h6>
                          <Form.Control
                            type="text"
                            placeholder="Enter Slug"
                          onChange={(e) => setSlug(e.target.value)}
                        defaultValue={blog && blog.slug}
                          // defaultValue={data && data.full_address == null ? '' : data && data.full_address}
                          // disabled={data && data.full_address && !edit ? true : false}
                          />
                        {err.slug ? <div className="text-danger">{err.slug}</div> : ''}

                        </div>
                        <div className="col-md-4 mt-4">
                          <h6>Select Status</h6>
                          <Form.Select onChange={(e) => setStatus(e.target.value)}>
                            {blog.status ? <option value={blog.status}>{blog.status}</option> :   <option selected disabled>Select Status</option>}
                          
                            <option value="active">Active</option>
                            <option value="noactive">Not Active</option>
                          </Form.Select>
                          {err.status ? <div className="text-danger">{err.status}</div> : ''}

                        </div>
                        <div className="col-md-4 mt-4">
                        {/* <iframe src={baseUrl+blog.image}></iframe> */}

                          <h6>Upload Image</h6>
                          <input
                            type="file"
                            onChange={handleImage}
                          />
                        {err.image ? <div className="text-danger">{err.image}</div> : ''}

                        </div>
                      </div>
                      <div className="col-md-12 mt-4" style={{ textAlign: 'right' }}>
                        <Button onClick={AddBlog}>Add Blog</Button>
                      </div>

                    </div>
                  </div>
                  <ToastContainer />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default VendorEditBlog;
