import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from "../components/address-block/more-vertical.svg";
import AddIcon from "../components/address-block/add-circle.svg";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from "@progress/kendo-react-editor";
import { YearPicker } from "react-dropdown-date";
import Select from "react-select";

const addressDetail = [
  {
    id: "1",
    fullAddress: "My address house no 278",
    postalCode: 226780,
    city: "Lucknow",
    country: "India",
    phone: "+91 0000000000",
  },
];

const ManageProfile = ({ props }) => {
  function handleAdd() {
    setShowAdd(true);
  }
  const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
  } = EditorTools;
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem("avonToken");
  const [aboutUs, setAboutUs] = useState("");
  const [data, setData] = useState("");
  const [category, setCategory] = useState([]);
  const [businessName, setBusinessName] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [whatsappNo, setWhatsapp] = useState("");
  const [address, setAddress] = useState("");
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [edit, setEdit] = useState(false);
  const [resError, setResError] = useState("");
  const [businessSlug, setBusinessSlug] = useState("");
  const [logo, setlogo] = useState("");
  const [file, setfile] = useState("");
  const [fileb, setfileb] = useState("");
  const [website, setWebsite] = useState("");
  const [currn, setcurrn] = useState("");
  const [currnid, setcurrnid] = useState("");
  const [currnname, setcurrnname] = useState("");
  const [currnsymbol, setcurrnsymbol] = useState("");
  const [currncode, setcurrncode] = useState("");
  const [map_url, setmap_url] = useState("");
  const [addMobile, setaddMobile] = useState([{ mobile: "" }]);
  const body1 = React.createRef();
  const body2 = React.createRef();
  const [companyimage, setcompanyimage] = useState("");
  const [companyimagefile, setcompanyimagefile] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [instagram, setInstagram] = useState("");
  const [pinterest, setpinInterest] = useState("");
  const [productserviecs, setproductserviecs] = useState("");
  const [blogUrl, setBlogUrl] = useState();
  const [metaTitle, setMetaTitle] = useState("");
  const [metaKeyword, setMetaKeyword] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [keywordtotal, setkeywordtotal] = useState("");
  const [skilltotal, setskilltotal] = useState("");
  const [banner, setbanner] = useState("");
  const [chip, setChip] = useState([]);
  const [skills, setskills] = useState([]);
  const [statusData, setStatusData] = useState("");
  const [domain, setdomain] = useState("");
  const [subdomain, setsubdomain] = useState("");
  const [selected, setselected] = useState([]);
  const [subdomainstatus, setsubdomainstatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [categorys, setCategorys] = useState([]);
  const [keyword, setKeyword] = useState([]);
  const [natureofbusinessdropdown, setnatureofbusinessdropdown] = useState([]);
  const [businesstypedropdown, setbusinesstypedropdown] = useState([]);
  
  useEffect(() => {
    FetchProfileData();
    getCountry();
    FetchBusinessCategory();
    FetchStatus();
    FetchKeywords();
  }, []);
  useEffect(()=>{
    getbusinesstypedropdown(statusData?.data?.package_type,businessNature);
  },[businessNature,statusData?.data?.package_type])
  const getcategory = (data) => {
    axios
      .post(`${BASE_URL}/getcategory`,{"category_type":data})
      .then(function (res) {
        setCategory(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const FetchKeywords = async () => {
    await axios.get(BASE_URL + "/getmetakeyword").then(({ data }) => {
      setKeyword(data.data);
    });
  };

  const KeyData = keyword.map((item) => item.meta_keyword);

  const FetchStatus = async () => {
    await axios
      .get(BASE_URL + "/get-seller-detail", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setStatusData(data);
        natureofbusines(data?.data?.package_type)
        if(data?.data?.package_type == "Product"){
          getcategory(1)
        }
        if(data?.data?.package_type == "Services"){
          getcategory(2)
        }
        if(data?.data?.package_type == "Directory"){
          getcategory(1)
        }
       
        // if(data.data == null){
        //   navigate('/vendor-dashboard')
        // }
        // if(data.data.payment_status == 'Pending'){
        //   navigate('/manage-profile')
        //   }
        setStatus(
          statusData && statusData.data == null
            ? true
            : statusData && statusData.data.payment_status == "Pending"
            ? true
            : statusData && statusData.data.payment_status == "Active"
            ? false
            : true
        );
      });
  };

  const FetchProfileData = async () => {
    await axios
      .get(BASE_URL + "/getbusinessprofile", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        if (data.data) {
          let d = data.data;
          var datasl = [];

          data.data.business_mobilenumber &&
          data.data.business_mobilenumber.length > 0
            ? data.data.business_mobilenumber.map((item, i) => {
                datasl.push({ mobile: item });
              })
            : datasl.push({ mobile: "" });
          var datas2 = [];
          data.data.business_email && data.data.business_email.length > 0
            ? data.data.business_email.map((items, i) => {
                datas2.push({ email: items });
              })
            : datas2.push({ email: "" });
          setaddEmail(datas2);
          setaddMobile(datasl);
          var d1 = [];
          d?.business_category.map((item) => {
            d1.push(item.value);
          });
          setCategorys(d1);
         
          setBusinessName(d.business_name);
          setBusinessSlug(d.business_slug);
          setBusinessType(d.business_type);
          setBusinessNature(d.business_nature);
          setWhatsapp(d.whatsapp_number);
          setWebsite(d.website_url);
          setEstblish_year(d.estblish_year);
          setCountry(d.country.id);
          getState(d.country.id);
          setState(d.state.id);
          getCity(d.state.id);
          setCity(d.city.id);
          setPincode(d.pincode);
          setAddress(d.full_address);
          setcurrncode(d.business_currency_code);
          setcurrnid(d.business_currency_id);
          setcurrnname(d.business_currency_name);
          setcurrnsymbol(d.business_currency_symbol);
          setmap_url(d.map_url);
          setselected(d.business_category);
          setfile(data.image_url + d.logo);
          setFacebook(data.shop.facebook);
          setTwitter(data.shop.twitter);
          setYoutube(data.shop.youtube);
          setLinkedin(data.shop.linkedin);
          setInstagram(data.shop.instagram);
          setpinInterest(data.shop.pinterest);
          setBlogUrl(data.shop.blog_url);
          setcompanyimagefile(IMAGE_BASE_URL + data.shop.comp_intr_image);
          setfileb(IMAGE_BASE_URL + d.banner);
          setAboutUs(data.shop.comp_intr ? data.shop.comp_intr : "");
          setproductserviecs(
            data.shop.productserviecs ? data.shop.productserviecs : ""
          );
          setMetaTitle(data.shop.meta_title);
          if (data.shop.meta_keyword.length > 0) {
            setChip(data.shop.meta_keyword);
          }
          if(data?.shop?.skills?.length > 0){
            setskills(data.shop.skills);
          }
         
          setMetaDescription(data.shop.meta_description);
          setkeywordtotal(data.keywordtotal);
          setskilltotal(data.skillcanbeadded)
        } else {
          setEdit(true);
        }
        setData(data.data);
        // console.log("profile", data.data)
      });
  };

  const FetchBusinessCategory = async () => {
    await axios
      .get(BASE_URL + "/getbusinesscategory", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        setCategory(data.data);
        // console.log(data.data)
      });
  };

  // console.log(selected)

  // handle input change
  const handleInputMobileChange = (e, index) => {
    const { mobile, value } = e.target;
    const list = [...addMobile];
    list[index]["mobile"] = value;
    setaddMobile(list);
  };

  // handle click event of the Remove button
  const handleRemoveMobileClick = (index) => {
    const list = [...addMobile];
    list.splice(index, 1);
    setaddMobile(list);
  };

  // handle click event of the Add button
  const handleAddMobileClick = () => {
    setaddMobile([...addMobile, { mobile: "" }]);
  };

  const [addEmail, setaddEmail] = useState([{ email: "" }]);
  // console.log(addEmail)

  // handle input change
  const handleInputEmailChange = (e, index) => {
    const { email, value } = e.target;
    const list = [...addEmail];
    list[index]["email"] = value;
    setaddEmail(list);
    // console.log(list)
  };

  // handle click event of the Remove button
  const handleRemoveEmailClick = (index) => {
    const list = [...addEmail];
    list.splice(index, 1);
    setaddEmail(list);
  };

  // handle click event of the Add button
  const handleAddEmailClick = () => {
    setaddEmail([...addEmail, { email: "" }]);
  };

  const getCountry = () => {
    axios
      .get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getState = (name) => {
    axios
      .get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCity = (id) => {
    axios
      .get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getcurrencybycountry = (id) => {
    axios
      .get(`${BASE_URL}/getcurrencybycountry/${id}`)
      .then(function (res) {
        setcurrnname(res.data.data.name);
        setcurrnid(res.data.data.id);
        setcurrnsymbol(res.data.data.symbol);
        setcurrncode(res.data.data.code);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const [pickupList, setPickupList] = useState([
    {
      pickAddress: "",
      pickCountry: "",
      pickstate: "",
      pickCity: "",
      pickPincode: "",
      pickLandmark: "",
    },
  ]);
  //  console.log([pickupList[0].pickAddress])
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...pickupList];
    // const li = [...pickupList.pickAddress]
    // console.log(list[0].pickAddress)
    list[index][name] = value;
    setPickupList(list);
    console.log(e);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...pickupList];
    list.splice(index, 1);
    setPickupList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setPickupList([
      ...pickupList,
      {
        pickAddress: "",
        pickCountry: "",
        pickstate: "",
        pickCity: "",
        pickPincode: "",
        pickLandmark: "",
      },
    ]);
  };

  // console.log(pickupList[0].pickAddress)

  const [countryName, setCountryName] = useState();
  const handleCountyName = (e) => {
    const DataIdWithName = e;
    const countryData = DataIdWithName.split("_");
    setCountryName(countryData[0]);
  };

  const [estblish_year, setEstblish_year] = useState("");

  const UpdateProfile = async () => {
    setResError("");
    const view1 = body1.current.view;
    var aboutUsContent = EditorUtils.getHtml(view1.state);
    const view2 = body2.current.view;
    var productserviceContent = EditorUtils.getHtml(view2.state);
    var mobile = [];
    addMobile.map((item) => {
      mobile.push(item.mobile);
    });
    var email = [];
    addEmail.map((item) => {
      email.push(item.email);
    });

    const datas = {
      business_name: businessName,
      business_slug: businessSlug,
      business_type: businessType,
      business_nature: businessNature,
      estblish_year: estblish_year,
      country: country,
      state: state,
      city: city,
      pincode: pincode,
      full_address: address,
      logo: logo,
    };
    const formdata = new FormData();
    formdata.append("business_name", businessName);
    formdata.append("business_slug", businessSlug);
    formdata.append("business_type", businessType);
    formdata.append("business_nature", businessNature);
    formdata.append("business_category", categorys.length > 0 ? categorys :"");
    formdata.append("estblish_year", estblish_year);
    formdata.append("logo", logo);
    formdata.append("productserviecs", productserviceContent);
    formdata.append("comp_intr", aboutUsContent);
    formdata.append("meta_title", metaTitle);
    formdata.append("meta_description", metaDescription);
    formdata.append("meta_keyword", chip);
    formdata.append("skills", skills);
    formdata.append("banner", banner);
    formdata.append("companyimage", companyimage);
    formdata.append("is_domain", subdomainstatus == true ? "no" : "yes");
    formdata.append(
      "domain_name",
      subdomainstatus == true ? subdomain + ".apnashaher.com" : domain
    );
    await axios
      .post(BASE_URL + "/updatebusinessprofile", formdata, {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then(({ data }) => {
        FetchProfileData();
        setEdit(false);
        if (data.status == "success") {
          toast.success(data.message);
        }
      })
      .catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == "error") {
          setResError(response.data.message);
          if (response.data.message.business_category) {
            toast.error(response.data.message.business_category[0]);
          }
          if (response.data.message.business_category) {
            toast.error(response.data.message.country[0]);
          }
        }
      });
  };
  const handlelogo = (e) => {
    setlogo(e.target.files[0]);
    setfile(URL.createObjectURL(e.target.files[0]));
  };
  const handlebanner = (e) => {
    setbanner(e.target.files[0]);
    setfileb(URL.createObjectURL(e.target.files[0]));
  };
  const handleChangeCategory = (selectedOption) => {
    setselected(selectedOption);
    var d = [];
    selectedOption.map((item) => {
      d.push(item.value);
    });
    setCategorys(d);
  };
  const handleimagecompany = (e) => {
    setcompanyimage(e.target.files[0]);
    setcompanyimagefile(URL.createObjectURL(e.target.files[0]));
  };
  const natureofbusines = (data) => {
    var d = [];
    if (data == "Services") {
      d = ["Service Providers"];
    } else if (data == "Product") {
      d = [
        "Manufacturer",
        "Exporters & Importers",
        "Suppliers",
        "Distributors",
      ];
    } else if (data == "Directory") {
      d = [
        "Manufacturer",
        "Exporters & Importers",
        "Suppliers",
        "Distributors",
        "Retailers",
        "Service Providers",
        "Educational Institutes",
      ];
    }
    setnatureofbusinessdropdown(d);
  };
  const getbusinesstypedropdown = (packagetype, data) => {
    var x = [];
    console.log(packagetype,data)
    if (packagetype == "Directory" && data == "Manufacturer") {
      getcategory(1)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Directory" && data == "Exporters & Importers") {
      getcategory(1)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Directory" && data == "Suppliers") {
      getcategory(1)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
      ]
    }
    if (packagetype == "Directory" && data == "Distributors") {
      getcategory(1)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
      ]
    }
    if (packagetype == "Directory" && data == "Retailers") {
      getcategory(1)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
      ]
    }
    if (packagetype == "Directory" && data == "Service Providers") {
      getcategory(2)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
       "NGO's / Trust",  
       "Microfinance Company",  
      ]
    }
    if (packagetype == "Directory" && data == "Educational Institutes") {
      getcategory(5)
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
       "NGO's / Trust",  
      ]
    }
    if (packagetype == "Product" && data == "Manufacturer") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Product" && data == "Exporters & Importers") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company", 
       "NGO's / Trust", 
      ]
    }
    if (packagetype == "Product" && data == "Suppliers") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
      ]
    }
    if (packagetype == "Product" && data == "Distributors") {
      x = [
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
      ]
    }
    if (packagetype === "Services" && data === "Service Providers") {
      x = [
       "Individual / Freelancer", 
       "Sole Proprietorship", 
       "Private Limited Companies", 
       "Limited Liability Companies (LLC)", 
       "Limited Liability Partnerships (LLP)", 
       "Partnership Firms", 
       "One Person Company (OPC)", 
       "Public Limited Company",
      ]
    }
    

    setbusinesstypedropdown(x);
  };
  const handlechangeChip = (e) => {
    console.log(e);
    if (chip.length < keywordtotal) {
      setChip(e);
    } else {
      alert("You reach Maximum limit to add keyword!");
    }
  };
  const handlechangeskills = (e) => {
    console.log(e);
    if (skills.length < skilltotal) {
      setskills(e);
    } else {
      alert("You reach Maximum limit to add skill!");
    }
  };
  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>Business Profile</li>
            </ul>
            <div className="col-md-12">
              <Button
                className="dashboard-btn"
                href="/dashboard"
                variant="primary"
              >
                Customer Dashboard
              </Button>
            </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu />
              <div className="col-md-9">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h4>
                    Business Profile{" "}
                    <Button
                      onClick={() => {
                        setEdit(true);
                        toast.success("You can now edit your details");
                      }}
                    >
                      {" "}
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </h4>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk">
                    <h5>Business Profile</h5>
                  </div>
                  <div className="card-body-blk">
                    <Form>
                      <div className="row">
                        {/* <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Select Business Category</Form.Label>
                          {data && data?.business_category?.length && !edit ?
                            <div style={{ border: '1px solid lightgray', padding: 10, background: '#F2F3F8' }}>
                              {data.business_category.map((item) => (
                                <div >{item.name}</div>
                              ))} </div>
                            :
                            <MultiSelect
                              options={category}
                              value={selected}
                              // onChange={setSelected}
                              labelledBy={"Select Business Category"}
                              hasSelectAll={false}
                              // closeOnChangedValue={selected.length > 2 ? true : false}
                            // isCreatable={true}
                            />
                          }
                          {resError && resError.business_category[0] ? <div className="text-danger"> {resError && resError.business_category[0]} </div> : ''}
                        </div> */}
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={
                              data && data.business_name && !edit ? true : false
                            }
                            placeholder="Enter Business Name"
                            value={businessName}
                            onChange={(e) => {
                              setBusinessName(e.target.value);
                              setBusinessSlug(
                                e.target.value
                                  .toLowerCase()
                                  .trim()
                                  .replace(/[^\w\s-]/g, "")
                                  .replace(/[\s_-]+/g, "-")
                                  .replace(/^-+|-+$/g, "")
                              );
                            }}
                            // onClick={disabled == true ? alert('Use Edit Mode') : ''}
                          />
                          {resError && resError.business_name ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.business_name}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Nature of Business</Form.Label>
                          <Form.Select
                            style={{ borderRadius: "0%" }}
                            value={businessNature}
                            onChange={(e) => {setBusinessNature(e.target.value); getbusinesstypedropdown(statusData?.data?.package_type,e.target.value)}}
                            disabled={
                              data && data.business_nature && !edit
                                ? true
                                : false
                            }
                          >
                            <option value="">Select Business Nature</option>
                            {natureofbusinessdropdown &&
                                  natureofbusinessdropdown?.map((item, i) => (
                                    <option value={item}>{item}</option>
                                  ))}
                          </Form.Select>
                          {resError && resError.business_nature ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.business_nature}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Business Type</Form.Label>
                          <Form.Select
                            value={businessType}
                            disabled={
                              data && data.business_type && !edit ? true : false
                            }
                            style={{ borderRadius: "0%" }}
                            onChange={(e) => {
                              setBusinessType(e.target.value);
                            }}
                          >
                            <option value="">Select Business Type</option>
                            {businesstypedropdown &&
                                  businesstypedropdown?.map((item, i) => (
                                    <option value={item}>{item}</option>
                                  ))}
                          </Form.Select>
                          {resError && resError.business_type ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.business_type}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        
                        <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Established Year</Form.Label>
                          <YearPicker
                            className={"form-control"}
                            defaultValue={"Select Establish Year"}
                            start={1900} // default is 1900
                            end={new Date().getFullYear()} // default is current year
                            reverse // default is ASCENDING
                            required={true} // default is false
                            disabled={
                              data && data.estblish_year && !edit ? true : false
                            } // default is false
                            value={estblish_year} // mandatory
                            onChange={(year) => {
                              // mandatory
                              setEstblish_year(year);
                            }}
                            id={"year"}
                            name={"year"}
                            classes={"form-control"}
                          />

                          {/* <Form.Control
                            placeholder="Enter Establish Year"
                            type="number"
                             min="1900"
                              max="2099"
                              step="1"
                            onChange={(e) => setEstblish_year(e.target.value)}
                            value={estblish_year}
                            disabled={data && data.estblish_year && !edit ? true : false}
                          /> */}
                          {resError && resError.estblish_year ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.estblish_year}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-4 mb-2 mt-2">
                          <label>Business Category</label>
                          <Select
                            value={selected}
                            options={category}
                            isDisabled={
                              data && data.business_cagegotry && !edit
                                ? true
                                : false
                            }
                            isMulti
                            name="business_cagegotry"
                            onChange={handleChangeCategory}
                          />
                          {resError && resError.business_cagegotry && (
                            <div className="text-danger">
                              {resError.business_cagegotry}
                            </div>
                          )}
                        </div>
                        {statusData &&
                          statusData.data &&
                          statusData.data.personal_domain == "Yes" && (
                            <>
                              <div className="col-md-4 mb-2 mt-2">
                                {subdomainstatus == false ? (
                                  <>
                                    <Form.Label>Domain Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      value={domain}
                                      onChange={(e) =>
                                        setdomain(e.target.value)
                                      }
                                      placeholder="Enter Your Domain Name"
                                      // defaultValue={data && data.facebook == null ? '' : data && data.facebook}
                                      disabled={
                                        data && data.domain && !edit
                                          ? true
                                          : false
                                      }
                                    />
                                    {resError && resError.domain ? (
                                      <div className="text-danger">
                                        {resError && resError.domain}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <Form.Label>SubDomain Name</Form.Label>
                                    <Form.Control
                                      type="text"
                                      onChange={(e) =>
                                        setsubdomain(e.target.value)
                                      }
                                      value={subdomain}
                                      placeholder="Enter Your SubDomain Name"
                                      disabled={
                                        data && data.subdomain && !edit
                                          ? true
                                          : false
                                      }
                                    />
                                    {subdomain}.apnashaher.com
                                    {resError && resError.subdomain ? (
                                      <div className="text-danger">
                                        {resError && resError.subdomain}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="col-md-4 mb-2 mt-2">
                                <Button
                                  className="btn btn-sm"
                                  onClick={() =>
                                    setsubdomainstatus(!subdomainstatus)
                                  }
                                >
                                  {" "}
                                  {subdomainstatus == true
                                    ? "Continue with Domain"
                                    : "Continue with Sub Domain"}
                                </Button>
                              </div>
                            </>
                          )}

                        <h3>Company Details</h3>
                        <Form.Label> Introduction Image</Form.Label>
                        <Form.Control
                          type="file"
                          onChange={handleimagecompany}
                          disabled={data && data.domain && !edit ? true : false}
                        />
                        {resError && resError.image && (
                          <div className="text-danger">
                            {resError && resError.image}
                          </div>
                        )}
                        {companyimagefile && (
                          <img
                            height="100px"
                            width="100px"
                            src={companyimagefile}
                          />
                        )}
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Introduction</Form.Label>
                          <Editor
                            tools={[
                              [Bold, Italic, Underline, Strikethrough],
                              [Subscript, Superscript],
                              [
                                AlignLeft,
                                AlignCenter,
                                AlignRight,
                                AlignJustify,
                              ],
                              [Indent, Outdent],
                              [OrderedList, UnorderedList],
                              FontSize,
                              FontName,
                              FormatBlock,
                              [Undo, Redo],
                              [Link, Unlink, InsertImage, ViewHtml],
                              [InsertTable],
                              [
                                AddRowBefore,
                                AddRowAfter,
                                AddColumnBefore,
                                AddColumnAfter,
                              ],
                              [DeleteRow, DeleteColumn, DeleteTable],
                              [MergeCells, SplitCell],
                            ]}
                            contentStyle={{
                              height: 300,
                            }}
                            value={aboutUs}
                            ref={body1}
                            onChange={(e) => setAboutUs(e.target.value)}
                          />
                          {resError && resError.comp_intr ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.comp_intr}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <Form.Control
                          type="text"
                          placeholder="About Us "
                          onChange={(e) => setAboutUs(e.target.value)}
                          defaultValue={data && data.aboutus == null ? '' : data && data.aboutus}
                          disabled={data && data.aboutus && !edit ? true : false}
                        /> */}
                          {resError && resError.aboutus ? (
                            <div className="text-danger">
                              {resError && resError.aboutus}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-12 mb-2 mt-2">
                          <Form.Label>Products & Services</Form.Label>
                          <Editor
                            tools={[
                              [Bold, Italic, Underline, Strikethrough],
                              [Subscript, Superscript],
                              [
                                AlignLeft,
                                AlignCenter,
                                AlignRight,
                                AlignJustify,
                              ],
                              [Indent, Outdent],
                              [OrderedList, UnorderedList],
                              FontSize,
                              FontName,
                              FormatBlock,
                              [Undo, Redo],
                              [Link, Unlink, InsertImage, ViewHtml],
                              [InsertTable],
                              [
                                AddRowBefore,
                                AddRowAfter,
                                AddColumnBefore,
                                AddColumnAfter,
                              ],
                              [DeleteRow, DeleteColumn, DeleteTable],
                              [MergeCells, SplitCell],
                            ]}
                            contentStyle={{
                              height: 300,
                            }}
                            value={productserviecs}
                            ref={body2}
                            onChange={(e) => setproductserviecs(e.target.value)}
                          />
                          {resError && resError.productserviecs ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.productserviecs}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <Form.Control
                          type="text"
                          placeholder="About Us "
                          onChange={(e) => setAboutUs(e.target.value)}
                          defaultValue={data && data.aboutus == null ? '' : data && data.aboutus}
                          disabled={data && data.aboutus && !edit ? true : false}
                        /> */}
                          {resError && resError.aboutus ? (
                            <div className="text-danger">
                              {resError && resError.aboutus}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* <div className="col-md-4 mb-2 mt-2">
                          <Form.Label>Currency</Form.Label>
                          <Form.Control
                            type="text"
                            value={currnname}
                            // placeholder="Enter Whatsapp Number"
                            disabled={true}
                          />
                          {resError && resError.whatsapp_number ? <div className="text-danger"> {resError && resError.whatsapp_number} </div> : ''}
                        </div> */}

                        <h3>Seo Setting</h3>
                        <div className="col-md-6 mb-2 mt-2">
                          <Form.Label>Business Slug</Form.Label>
                          <Form.Control
                            type="text"
                            disabled={
                              data && data.business_name && !edit ? true : false
                            }
                            placeholder="Enter Business Name"
                            value={businessSlug}
                            onChange={(e) => setBusinessSlug(e.target.value)}
                            // onClick={disabled == true ? alert('Use Edit Mode') : ''}
                          />
                          {resError && resError.business_slug ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.business_slug}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mb-2 mt-2">
                          <Form.Label>Meta Title</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Meta Title "
                            value={metaTitle}
                            onChange={(e) => setMetaTitle(e.target.value)}
                            disabled={
                              data && data.meta_title && !edit ? true : false
                            }
                          />
                          {resError && resError.meta_title ? (
                            <div className="text-danger">
                              {resError && resError.meta_title}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mb-2 mt-2">
                          <div className="d-flex justify-content-between">
                            <Form.Label>Meta Keyword</Form.Label>
                            <div>
                              ({chip.length}/{keywordtotal})
                            </div>
                          </div>
                          {data && data.meta_keyword && !edit ? (
                            <div>
                              <div
                                style={{
                                  border: "1px solid lightgray",
                                  padding: 10,
                                  background: "#F2F3F8",
                                }}
                              >
                                <div>{data && data.meta_keyword.join(",")}</div>
                              </div>
                              <div
                                style={{ textAlign: "end" }}
                                className="mt-1"
                              >
                                ({chip.length}/{keywordtotal})
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Chips
                                value={chip}
                                onChange={handlechangeChip}
                                suggestions={KeyData}
                                placeholder="Enter Keyword"
                                fromSuggestionsOnly={false}
                                createChipKeys={[13]}
                                disabled={
                                  data && data.meta_keyword && !edit
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          )}

                          {resError && resError.meta_keyword ? (
                            <div className="text-danger">
                              {resError && resError.meta_keyword}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {skilltotal > 0 &&(
                          <div className="col-md-6 mb-2 mt-2">
                          <div className="d-flex justify-content-between">
                            <Form.Label>Skills</Form.Label>
                            <div>
                              ({skills.length}/{skilltotal})
                            </div>
                          </div>
                          {data && data.skills && !edit ? (
                            <div>
                              <div
                                style={{
                                  border: "1px solid lightgray",
                                  padding: 10,
                                  background: "#F2F3F8",
                                }}
                              >
                                <div>{data && data.skills.join(",")}</div>
                              </div>
                              <div
                                style={{ textAlign: "end" }}
                                className="mt-1"
                              >
                                ({skills.length}/{skilltotal})
                              </div>
                            </div>
                          ) : (
                            <div>
                              <Chips
                                value={skills}
                                onChange={handlechangeskills}
                                placeholder="Enter Skill"
                                fromSuggestionsOnly={false}
                                createChipKeys={[13]}
                                disabled={
                                  data && data.skills && !edit
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          )}

                          {resError && resError.skills ? (
                            <div className="text-danger">
                              {resError && resError.skills}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        )}
                        <div className="col-md-6 mb-2 mt-2">
                          <Form.Label>Meta Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            type="text"
                            placeholder="Enter Meta Description "
                            onChange={(e) => setMetaDescription(e.target.value)}
                            value={metaDescription}
                            disabled={
                              data && data.meta_description && !edit
                                ? true
                                : false
                            }
                          />
                          {resError && resError.meta_description ? (
                            <div className="text-danger">
                              {resError && resError.meta_description}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-6 mb-2 mt-2">
                          <Form.Label>Business Logo</Form.Label>
                          <Form.Control
                            type="file"
                            // placeholder="Enter Address"
                            onChange={handlelogo}
                          />
                          {resError && resError.logo ? (
                            <div className="text-danger">
                              {" "}
                              {resError && resError.logo}{" "}
                            </div>
                          ) : (
                            ""
                          )}
                          {file && (
                            <img
                              src={file}
                              height={100}
                              width={100}
                              className="mobile-margin-3"
                            />
                          )}
                        </div>
                        {statusData?.data?.package?.custom_banner == "Yes" && (
                          <div className="col-md-6 mb-2 mt-2">
                            <Form.Label>Banner Image</Form.Label>
                            <Form.Control
                              type="file"
                              // placeholder="Enter Address"
                              onChange={handlebanner}
                            />
                            {resError && resError.banner ? (
                              <div className="text-danger">
                                {" "}
                                {resError && resError.banner}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                            {fileb && (
                              <img
                                src={fileb}
                                height={100}
                                width={100}
                                className="mobile-margin-3"
                              />
                            )}
                          </div>
                        )}

                        <div className="col-md-12">
                          <Button
                            style={{
                              float: "right",
                              marginTop: "8px",
                              color: "#fff",
                            }}
                            variant="warning"
                            onClick={UpdateProfile}
                          >
                            Update
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                {/* <div className="product-card-block">
                  <div className="order-head-blk">
                    <h6>Update Email</h6>
                  </div>
                  <div className="card-body-blk">
                    <div className="row">
                      <div className="col-md-2">
                        <p style={{ paddingTop: "7px" }}>Enter Your Email ID</p>
                      </div>
                      <div className="col-md-10">
                        <InputGroup>
                          <Form.Control
                            type="text"

                            // placeholder="Input group example"
                            // aria-label="Input group example"
                            // aria-describedby="btnGroupAddon"
                          />
                          <Button
                            style={{ backgroundColor: "#C3CECF", width: "15%" }}
                            variant="success"
                          >
                            Verify
                          </Button>
                        </InputGroup>
                      </div>

                      <div className="col-md-12">
                        <Button
                          style={{ float: "right", marginTop: "12px" }}
                          variant="warning"
                        >
                          Update Email
                        </Button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />
    </Page>
  );
};

export default ManageProfile;
