import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { BASE_URL, DOMAIN_NAME } from "../../config";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
export default function SllerFooter() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [footer, setFooter] = useState("");
  const [footers, setFooters] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    FetchData();
    fetchFooter();
  }, []);

  const FetchData = async () => {
    await axios
      .get(BASE_URL + "/seller/footermenu", {
        headers: {
          Accept: "application/json",
          domainname: DOMAIN_NAME,
        },
      })
      .then(({ data }) => {
        setData(data.data);
      });
  };
  const fetchFooter = async () => {
    await axios
      .get(BASE_URL + "/seller/getfooter", {
        headers: {
          Accept: "application/json",
          domainname: DOMAIN_NAME,
        },
      })
      .then(({ data }) => {
        setFooter(data?.businessdata);
        setFooters(data);
      })
      .catch(({ response }) => {});
  };
  const EmailSubscribe = () => {
    const obj = {
      email: email,
    };
    axios
      .post(BASE_URL + `/seller/subscribe`, obj,{
        headers: {
          Accept: "application/json",
          domainname: DOMAIN_NAME,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status == "200") {
          console.log("set email called");
          setEmail("");
          toast.success("Subscribed Successfully!");
        }
      })
      .catch((error) => {
        console.log(error, "status");
      });
  };
  return (
    <div className="seller-footer">
      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className="col-md-4 nopadding">
          <div className="footer-logo-text">LOGO</div>
          <div className="footer-working-hours mt-6">WORKING HOURS</div>
          {footers.openingtime &&
            footers.openingtime.map((item, i) => (
              <div className="footer-working-days mt-2">
                {item.first_day == item.last_day ?(
                      <> {item.first_day}:</>
                   ):(
                    <>{item.first_day} & {item.last_day}:</>
                   )}   {item.status == "open" ? (
                <>{item.in_time} - {item.out_time}</>
                    ):(
                      <>
                      {item.status}
                      </>
                    )} 
              </div>
            ))}

          {/* <div className='footer-working-days'>SUNDAY AND HOLIDAYS</div> */}
          <div className="footer-working-hours mt-2">
            FOLLOW US ON :{" "}
            <span>
                <a href={footers?.shop?.facebook} target="_blank">
              <img
                src="/assets/images/footer-facebook.png"
                style={{ marginRight: 10, marginLeft: 40 }}
              />
              </a>
              <a href={footers?.shop?.twitter} target="_blank">
              <img
                src="/assets/images/footer-twitter.png"
                style={{ marginRight: 10 }}
              />
              </a>
              <a href={footers?.shop?.linkedin} target="_blank">
              <img
                src="/assets/images/footer-linkedin.png"
                style={{ marginRight: 10 }}
              />
              </a>
              <a href={footers?.shop?.youtube} target="_blank">
              <img
                src="/assets/images/footer-youtube.png"
                style={{ marginRight: 10 }}
              />
              </a>
              <a href={footers?.shop?.instagram} target="_blank">
              <img
                src="/assets/images/footer-instagram.png"
                style={{ marginRight: 10 }}
              />
              </a>
              <a href={footers?.shop?.pinterest} target="_blank">
              <img
                src="/assets/images/footer-pinterest.png"
                style={{ marginRight: 10 }}
              />
              </a>
            </span>
          </div>
          <div className="footer-working-hours mt-2">
            CHAT WITH US ON :{" "}
            <span>
            <a href={"https://api.whatsapp.com/send?phone="+footer?.whatsapp_number} target="_blank">
              <img
                src="/assets/images/whatsapp-icon.png"
                style={{ marginRight: 10, marginLeft: 20 }}
              />
              </a>
            </span>
          </div>
          <div className="footer-working-hours mt-2">
            CALL US NOW :{" "}
            <span>
                {footer?.business_mobilenumber &&(
                    <a href={"tel:"+footer?.business_mobilenumber[0]}>
              <img
                src="/assets/images/footer-call.png"
                style={{ marginRight: 10, marginLeft: 50 }}
              />
              </a>
                )}
                
            </span>
          </div>
          <div className="seller-footer-copyright mt-3">
            Copyright © 2023 a theme by webmingo.com
          </div>
        </div>
        <div className="col-md-7">
          <div style={{ display: "flex" }}>
            <input
              style={{
                padding: 10,
                background: "#262420",
                width: "50%",
                border: "1px solid #FF9933",
              }}
              value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <button className="seller-subscribe" onClick={EmailSubscribe}>SUBSCRIBE</button>
          </div>
          <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
            <div className="col-md-4 nopadding">
              <div
                onClick={() => navigate("/")}
                className="footer-links-text mt-7"
              >
                HOME
              </div>
              <div
                onClick={() => navigate("/seller/product-list")}
                className="footer-links-text mt-1"
              >
                Product & Services
              </div>
              <div
                onClick={() => navigate("/seller/blogs")}
                className="footer-links-text mt-1"
              >
                Blogs
              </div>
              <div
                onClick={() => navigate("/seller/about-us")}
                className="footer-links-text mt-1"
              >
                {" "}
                FAQ
              </div>
              {/* <div onClick={()=>navigate("/seller/services")}  className='footer-links-text mt-1'> Services</div> */}
            </div>
            <div className="col-md-4 nopadding">
              {/* <div className='footer-links-text mt-7'>Dynamic page 1</div>
                            <div className='footer-links-text mt-1'>Dynamic page 2</div>
                            <div className='footer-links-text mt-1'>Dynamic page 3</div>
                            <div className='footer-links-text mt-1'> Dynamic page 4</div>
                            <div className='footer-links-text mt-1'> Dynamic page 5</div>
                            <div className='footer-links-text mt-1'> Dynamic page 6</div> */}
              <div className="mt-7">
                {data &&
                  data.map((item) => (
                    <div
                      onClick={() => navigate(`/seller/${item.slug}`)}
                      className="footer-links-text mt-1"
                    >
                      {item.menu_name}
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-md-4 nopadding">
              <div className="footer-links-text mt-7">
                Call US{" "}
                <span style={{ marginLeft: 30, marginRight: 10 }}>:</span>{" "}
                {footer?.business_mobilenumber &&
                  footer?.business_mobilenumber[0]}{" "}
              </div>
              <div className="footer-links-text mt-2">
                Email <span style={{ marginLeft: 30, marginRight: 10 }}>:</span>{" "}
                {footer?.business_email && footer?.business_email[0]}{" "}
              </div>
              <div className="footer-links-text mt-2">
                Location{" "}
                <span style={{ marginLeft: 30, marginRight: 10 }}>:</span>{" "}
                {footer?.full_address}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
    
  );
}
