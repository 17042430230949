import { useNavigate } from "react-router-dom";

const BrandItem = ({ brand }) => {
  const navigate = useNavigate()
  return (
    <div className="item" onClick={()=>navigate('/brand/'+brand.slug)} >
      <div className="brand-block" style={{cursor: "pointer"}}>
        <img src={brand.logo} alt={brand.name} />
      </div>
    </div>
  );
};

export default BrandItem;
