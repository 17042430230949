import React from 'react'
import { IMAGE_BASE_URL } from '../../../config'
const GeneralPage = (props) => {
    console.log(props,"generalpage")
  return (
    <div>
      <div className="container seller-pd-1">
                <h1 className="feedback-head">{props?.data?.title}</h1>
                <div className="row">
                    <div className="col-md-6" dangerouslySetInnerHTML={{__html:props.data && props?.data?.content}}>
                        
                    </div>
                    <div className="col-md-6 ">
                        <div className="about-accordian">
                            <img src={IMAGE_BASE_URL+props?.data?.image} />
                            {/* {props?.faq?.data?.map((item, index) => (
                                <Accordion atomic={true}>
                                    <AccordionItem title={item.question}>
                                        <p>{item.answer}</p>
                                    </AccordionItem>
                                </Accordion>
                            ))} */}
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default GeneralPage
