import React, { useEffect } from "react";
import { Banner } from "../components/startup/Banner";
import "../style/Startup.css";
import { AllStartup } from "../components/startup/AllStartup";
import { Subscribe } from "../components/startup/Subscribe";
import { InvertorSlider } from "../components/startup/InvertorSlider";
import { RegisterMarketplace } from "../components/startup/RegisterMarketplace";
import { StartupCategory } from "../components/startup/StartupCategory";
import { KeyFeature } from "../components/startup/KeyFeature";
import { Sbsection4 } from "../components/seller-benefites/Sbsection4";
import SeoStartup from "../seo page/SeoStartup";

export const Startup = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
    <SeoStartup/>
      <div className="startup-parent" style={{background:"#fff"}}>
        <Banner />
        <StartupCategory/>
        <AllStartup />
        <Sbsection4 heading = "Key" hspan = "Features" para = "Explore the recently added Startups at APNASHAHER.COM, if you have one you may List your startup with us by getting the following advantages."/>
        {/* <KeyFeature/> */}
        <RegisterMarketplace/>
        <Subscribe/>
        {/* <InvertorSlider/> */}
      </div>
    </>
  );
};
