import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import VendorDashboardSideMenu from "../components/VendorDashboardSideMenu";
import AddShopAddress from "../components/address-modal/add-shop-address.component";
import Page from "../components/Page";
import { NavLink } from "react-router-dom";
import { Button, Form, InputGroup, Card, Modal } from "react-bootstrap";
import SwitchIcon from "../components/product/ProductSwitch.jpg";
import MoreVerticalIcon from '../components/address-block/more-vertical.svg';
import AddIcon from '../components/address-block/add-circle.svg';
import axios from "axios";
import { BASE_URL } from "../config";
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import Chips, { Chip } from "react-chips";
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';


const addressDetail = [
  {
    id: '1',
    fullAddress: 'My address house no 278',
    postalCode: 226780,
    city: 'Lucknow',
    country: 'India',
    phone: '+91 0000000000',
  }
];

const ManageProfile = ({ props }) => {

  function handleAdd() {
    setShowAdd(true);
  }
  const [showAdd, setShowAdd] = useState(false);
  const token = localStorage.getItem('avonToken');
  const [heading, setHeading] = useState();
  const [sub_heading, setSub_Heading] = useState();
  const [image, setImage] = useState();
  const [modal, setModal] = useState(false);
  const [sliderData, setSliderData] = useState([]);
  const [baseUrl, setBaseUrl] = useState();


  const [chip, setChip] = useState();

  const handlechangeChip = (e) => {
    setChip(e)
  }

  const handleImage = e => {
    setImage(e.target.files[0]);
  }

  const handleNewImage = e => {
    setNewImage(e.target.files[0]);
  }

  const [edit, setEdit] = useState(false);
  const [errorImg, setErrorImg] = useState();
  const [pickAd, setPickAdd] = useState([]);
  const [statusData, setStatusData] = useState();
  const [status, setStatus] = useState(false)


  
  useEffect(() => {   
FetchSlider();
    FetchStatus();
  }, [])

  const FetchStatus = async () => {
    
    await axios.get(BASE_URL + '/get-seller-detail', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setStatusData(data) 

      // if(data.data == null){
      //   navigate('/vendor-dashboard')
      // }
      // if(data.data.payment_status == 'Pending'){
      //   navigate('/manage-profile')
      //   }
        setStatus(statusData && statusData.data == null ? true : statusData && statusData.data.payment_status == 'Pending' ? true : statusData && statusData.data.payment_status == 'Active' ? false : true)
    })
  
  }

  const FetchSlider = async () => {
    await axios.get(BASE_URL + '/getslider', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setSliderData(data.data)
      setBaseUrl(data.image_base_url)
    })
  }


  const AddSlider = async () => {

    const formData = new FormData();
    formData.append('image', image)
    formData.append('heading', heading);
    formData.append('subheading', sub_heading);
    await axios.post(BASE_URL + '/addslider', formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        if (data.message == 'Add Succesfully') {
          toast.success(data.message)
          setModal(false)
          setImage("");
          setHeading("");
          setSub_Heading("");
          FetchSlider();
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setErrorImg(response.data.message.image[0])
          //  alert(response.data.message.image[0])
        }
      })
  }

  const handleDelete = async (id) => {
    await axios.get(BASE_URL + '/deleteslider/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      toast.success(data.message)
      FetchSlider();
    })
  }

  const [editDetail, setEditDetail] = useState();
  const [editModal, setEditModal] = useState();
  const [newHeading, setNewHeading] = useState("");
  const [newSubHeading, setNewSubHeading] = useState("");
  const [newImage, setNewImage] = useState("");

  const EditSlider = async (id) => {

    await axios.get(BASE_URL + '/editslider/' + id, {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setEditModal(true)
      setEditDetail(data.data)
    })
  }

  const UpdateSlider = async (id) => {
    const formData = new FormData();
    formData.append('image',  newImage)
    formData.append('heading', newHeading == null ? editDetail.heading : newHeading);
    formData.append('subheading', newSubHeading);
    await axios.post(BASE_URL + '/updateslider/' + id, formData,
      {
        headers: {
          'content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then(({ data }) => {
        if (data.message == 'Update Succesfully') {
          toast.success(data.message)
          setEditModal(false);
          setImage("")
          setNewHeading("")
          setNewSubHeading("")
          FetchSlider();
        }
      }).catch(({ response }) => {
        // console.log(response.data)
        if (response.data.status == 'error') {
          setErrorImg(response.data.message.image[0])
          //  alert(response.data.message.image[0])
        }
      })
  }


  return (
    <Page title="Manage Profile">
      <div className="inner-pages-wrapper">
        <section className="breadcrumb-sction">
          <div className="container-fluid">
            <ul className="breadcrumb-block">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>   Sliders & Banners</li>
            </ul>
            <div className="col-md-12 ">
            <Button className="dashboard-btn" href="/dashboard" variant="primary">
                Customer Dashboard
              </Button>
              </div>
          </div>
        </section>

        <section className="mt-4">
          <div className="container-fluid">
            <div className="row">
              <VendorDashboardSideMenu PurchaseHis={status}  />
              <div className="col-md-9">
                <div className="slider-banner-vendor">
                  <h4>
                  Sliders & Banners <Button onClick={() => { setEdit(true); toast.success("You can now edit your details") }}> <FontAwesomeIcon icon={faEdit}  /></Button>
                  </h4>
                </div>
                <div className="product-card-block">
                  <div className="order-head-blk-new mb-3">
                    <div className="slider-heading">
                      <h4>
                        Sliders & Banners
                      </h4>
                      <Button onClick={() => setModal(true)} className="add-new-slider-btn">Add New Slider</Button>
                    </div>
                    <div className="card-body-blk pd-zero">
                      <div className="row">
                        {sliderData && sliderData.map((item) => (
                          <div className="col-md-6">
                            <div className="vendor-heading">
                              <h5>{item.heading}</h5>
                              <div className="vendor-slider-section">
                                <FontAwesomeIcon icon={faEdit} height="20px" width="40px" fontSize={20} style={{cursor:'pointer'}} onClick={() => EditSlider(item.id)} />
                                <FontAwesomeIcon icon={faTrash} height="20px" width="40px" fontSize={20} color="red" style={{cursor:'pointer'}} onClick={() => handleDelete(item.id)} />
                              </div>
                            </div>
                            <h6>{item.subheading}</h6>
                            <img src={baseUrl + item.image} height="150px" width="300px" className="mt-2" />
                          </div>

                        ))}
                      </div>
                    </div>


                  </div></div>
              </div>
            </div>
          </div>
          <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
              <h5> Add Slider & Banner </h5></Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setHeading(e.target.value)}
                  placeholder="Enter Heading"
                />
                {/* {error && error.heading ? <div>bdhb</div> : ''} */}
              </div>
              <div className="mt-4">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setSub_Heading(e.target.value)}
                  placeholder="Enter Sub Heading"
                />
              </div>
              <div className="mt-4 mb-4">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleImage}
                  placeholder="Image "
                />
                {errorImg && errorImg ? <div className="text-danger">{errorImg}</div> : ''}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={AddSlider}>Add Slider</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={editModal} onHide={() => setEditModal(false)}>
            <Modal.Header closeButton>
              <h5> Edit Slider & Banner </h5></Modal.Header>
            <Modal.Body>
              <div className="mt-2">
                <Form.Label>Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewHeading(e.target.value)}
                  placeholder="Enter Heading"
                  defaultValue={editDetail && editDetail.heading ? editDetail && editDetail.heading : ''}
                />
                {/* {error && error.heading ? <div>bdhb</div> : ''} */}
              </div>
              <div className="mt-4">
                <Form.Label>Sub Heading</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setNewSubHeading(e.target.value)}
                  defaultValue={editDetail && editDetail.subheading ? editDetail && editDetail.subheading : ''}
                  placeholder="Enter Sub Heading"
                />
              </div>
              <div className="mt-4 mb-4">
                <Form.Label>Upload Image</Form.Label>
                <Form.Control
                  type="file"
                  onChange={handleNewImage}
                  placeholder="Image "
                />
                {errorImg && errorImg ? <div className="text-danger">{errorImg}</div> : ''}
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => UpdateSlider(editDetail.id)}>Update Slider</Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
      <AddShopAddress showAdd={showAdd} setShowAdd={setShowAdd} />

      <ToastContainer />
    </Page>
  );
};

export default ManageProfile;
