import React, { useEffect, useState } from "react";
import "../HomePage.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Link } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../../../config";
import { useNavigate, useLocation } from "react-router-dom";

export const BannerNew = (props) => {
  const [search, setSearch] = useState("");
  const [product, setproduct] = useState([]);
  const [category, setCategory] = useState("");
  const [productSubLinks, setProductSubLinks] = useState(false);
  const [servicesSubLinks, setServicesSubLinks] = useState(false);
  const [directorySubLinks, setDirectorySubLinks] = useState(false);
  const [count, setCount] = useState(1)
  const [allwidget, setAllwidget] = useState([])

  const navigate = useNavigate();
  const location = window.location.pathname;

  // Get widget data from api
  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  // console.log("Wedgit", allwidget.widget1)

  useEffect(() => {
    widgetData()
  }, [])


  useEffect(() => {
    if (location === "/products-page" || location  === "/categorys/products-page" || location === "/categorys/flash-deals" || location === "/categorys/new-arrival" || location === "/categorys/stock-clearance" ) {
      return setProductSubLinks(true);
    }
    if (location === "/services") {
      return setServicesSubLinks(true);
    }
    if (location === "/directory") {
      return setDirectorySubLinks(true);
    }


  }, [location]);

  // Searh Post api
  const handlesearchproduct = (e) => {
    setSearch(e.target.value);
    axios
      .post(BASE_URL + "/searchproduct", {
        search: e.target.value,
        category: category,
      })
      .then((res) => {
        setproduct(res.data.data);
      });
  };
  var businessnature = [
    "Manufacturer",
    "Exporters & Importers",
    "Suppliers",
    "Distributors",
    "Retailers",
    "Service Providers",
    "Educational Institutes",
  ];


  return (
    <>
      
        {/* main-banner-bg */}
        <div className="container-fluid mobile-banner-bg mobile-banner">
          <div className="banner-links">
            {
              (!productSubLinks && !servicesSubLinks && !directorySubLinks) ?
                <div className="overf-div2">
                  <ul className="products-banner">
                    <Link to="/products-page" className="lcard1">
                      <li >
                        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          <img src="/assets/Icons/mb-product.png" alt="" width={"30px"} />
                          Products
                        </div>
                      </li>
                    </Link>

                    <Link to="/services">
                      <li className="lcard2">
                        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          <img src="/assets/Icons/mb-services.png" alt="" width={"30px"} />
                          Services
                        </div>
                      </li>
                    </Link>

                    <Link to="/directory">
                      <li className="lcard3">
                        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          <img src="/assets/Icons/mb-directory.png" alt="" width={"30px"} />
                          Directory
                        </div>
                      </li>
                    </Link>
                    <Link to="/">
                      <li className="lcard4">
                        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          <img src="/assets/Icons/mb-startup.png" alt="" width={"30px"} />
                          Startup
                        </div>
                      </li>
                    </Link>
                  </ul>
                </div>
                :
                null
            }



            {/* <div className="search-bar-width ">
            <div className="searchheade-block hide-in-mobile">
              <form>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Search Products, Services, Directory"
                    onChange={handlesearchproduct}
                  />

                  <div className="hide-in-mobile">
                    <button className="btn-search">
                      <img
                        src="../assets/images/search-icon.png"
                        className="mr-2"
                      />
                    </button>
                  </div>

                  <div className="hide-in-desktop">
                    <button className="btn-search">
                      <img src="../assets/images/search-icon.png" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="hide-in-desktop position-relative">
              <input
                type="text"
                className="form-control"
                placeholder="Search Products..."
                onChange={handlesearchproduct}
                style={{ borderRadius: "8px" }}
              />
              <button className="btn-search position-absolute end-0 top-0">
                <img
                  src="../assets/images/search-icon.png"
                  width="20px"
                  height="20px"
                  class="mt-2 me-2"
                />
              </button>
            </div>
            {product.length > 0 && search && (
              <div className="avon-search-input">
                {product &&
                  product.map((item, i) => (
                    <div
                      className="search-products"
                      key={i}
                      onClick={() => {
                        navigate("/product/" + item.slug);
                        setproduct("");
                      }}
                    >
                      <div>
                        <img src={item.thumbnail} className="search-img" />
                      </div>
                      <div>
                        <p className="search-product-name">{item.name}</p>
                        <p className="search-product-cate">{item.category}</p>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div> */}

            {(productSubLinks || servicesSubLinks || directorySubLinks) ?
              <div className="back-btn">
                <button className="d-flex gap-2 align-items-center" onClick={() => window.history.back()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20 11H7.83L13.42 5.41L12 4L4 12L12 20L13.41 18.59L7.83 13H20V11Z" fill="white" />
                  </svg>
                  Go back to category
                </button>

              </div>
              : null}

            {/* product sub links */}
            {productSubLinks ? (
              <div className="overf-div2">
                <ul className="banner-services my-lg-3 d-flex gap-3  my-md-3 my-2 justify-content-between">

                  <Link to="/categorys/products-page" >
                    <li className="slcard1">
                      <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                        {/* <img src="/assets/Icons/mb-startup.png" alt="" width={"30px"} /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
                          <g clip-path="url(#clip0_3540_233)">
                            <path d="M15.0938 0.0224609L23 3.97559V12.7915L21.5625 12.0728V5.58154L15.8125 8.45654V11.354L14.375 12.0728V8.45654L8.625 5.58154V8.13086L7.1875 7.41211V3.97559L15.0938 0.0224609ZM15.0938 7.20996L17.0815 6.21045L11.8818 3.23438L9.51221 4.4248L15.0938 7.20996ZM18.6313 5.44678L20.6753 4.4248L15.0938 1.62842L13.4204 2.4707L18.6313 5.44678ZM12.9375 12.7915L11.5 13.5103V13.499L7.1875 15.6553V20.7651L11.5 18.5977V20.2148L6.46875 22.7305L0 19.4849V11.8931L6.46875 8.65869L12.9375 11.8931V12.7915ZM5.75 20.7651V15.6553L1.4375 13.499V18.5977L5.75 20.7651ZM6.46875 14.4087L10.6128 12.3423L6.46875 10.2646L2.32471 12.3423L6.46875 14.4087ZM12.9375 14.3975L17.9688 11.8818L23 14.3975V20.3159L17.9688 22.8315L12.9375 20.3159V14.3975ZM17.25 20.8662V17.4409L14.375 16.0034V19.4287L17.25 20.8662ZM21.5625 19.4287V16.0034L18.6875 17.4409V20.8662L21.5625 19.4287ZM17.9688 16.1943L20.6753 14.8354L17.9688 13.4878L15.2622 14.8354L17.9688 16.1943Z" fill="white" />
                          </g>
                          <defs>
                            <clipPath id="clip0_3540_233">
                              <rect width="23" height="23" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        All Products
                      </div>
                    </li>

                  </Link>
                  <Link to="/categorys/flash-deals" >
                    <li className="slcard2">
                      <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                        {/* <img src="/assets/Icons/mb-startup.png" alt="" width={"30px"} /> */}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M15.1874 3.09912C14.3044 4.54662 11.6874 6.04662 8.45937 6.89612C2.83437 8.37512 2.23438 11.9701 2.23438 14.2936C2.23438 18.4396 6.42137 20.8686 9.08787 20.8686C11.7544 20.8686 11.9629 19.9611 13.0379 19.9611C14.1129 19.9611 14.9079 20.2541 16.4994 20.2541C18.0909 20.2541 21.7344 18.5791 21.7344 15.1591C21.7344 11.7391 17.8499 7.64912 17.0299 7.64912C15.9834 7.64912 15.4509 9.14512 15.4509 9.14512C15.4509 9.14512 15.3129 5.82112 15.1874 3.09912Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M13.5894 7.29346L13.6729 12.3885C14.9504 12.3885 16.2659 10.595 16.7404 10.595C16.8579 10.595 17.1464 11.295 17.9024 11.6905C19.2424 12.392 19.8559 13.2365 19.8559 14.815C19.8715 15.2363 19.8007 15.6565 19.648 16.0495C19.4952 16.4426 19.2637 16.8002 18.9676 17.1005C18.6716 17.4008 18.3172 17.6373 17.9264 17.7956C17.5355 17.9539 17.1164 18.0306 16.6949 18.021C16.0253 17.9649 15.3766 17.7604 14.7959 17.4224C14.2152 17.0843 13.7172 16.6211 13.3379 16.0665C12.3469 17.386 11.5579 18.321 9.54787 18.321C7.53787 18.321 5.10938 16.8555 5.10938 14.071C5.10938 11.2865 6.95937 10.246 9.45737 9.46446C10.955 8.99752 12.3552 8.26186 13.5894 7.29346Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M16.7344 16.2606C17.5521 16.2606 18.2149 15.5977 18.2149 14.7801C18.2149 13.9624 17.5521 13.2996 16.7344 13.2996C15.9167 13.2996 15.2539 13.9624 15.2539 14.7801C15.2539 15.5977 15.9167 16.2606 16.7344 16.2606Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M11.4737 13.9235C11.4737 14.4457 11.2662 14.9466 10.8969 15.3159C10.5276 15.6852 10.0267 15.8927 9.50441 15.8927C8.98213 15.8927 8.48124 15.6852 8.11194 15.3159C7.74263 14.9466 7.53516 14.4457 7.53516 13.9235C7.53516 12.836 8.30866 12.0635 9.42766 11.8015C10.1568 11.5806 10.8471 11.2474 11.4737 10.814V13.9235Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Flash Deals
                      </div>
                    </li>

                  </Link>
                  <Link to="/categorys/new-arrival" >
                    <li className="slcard3">
                      <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M22.5 14.25C22.5 14.5625 22.4414 14.8555 22.3242 15.1289C22.207 15.4023 22.0469 15.6406 21.8438 15.8438C21.6406 16.0469 21.4023 16.207 21.1289 16.3242C20.8555 16.4414 20.5625 16.5 20.25 16.5H1.5L0 10.5H4.5L4.875 12H7.875L6 4.5H9.75L13.5 12H20.25C20.5625 12 20.8555 12.0586 21.1289 12.1758C21.4023 12.293 21.6406 12.4531 21.8438 12.6562C22.0469 12.8594 22.207 13.0977 22.3242 13.3711C22.4414 13.6445 22.5 13.9375 22.5 14.25ZM2.67188 15H20.25C20.4531 15 20.6289 14.9258 20.7773 14.7773C20.9258 14.6289 21 14.4531 21 14.25C21 14.0469 20.9258 13.8711 20.7773 13.7227C20.6289 13.5742 20.4531 13.5 20.25 13.5H12.5742C11.9648 12.2344 11.3477 10.9844 10.7227 9.75C10.0977 8.51562 9.46484 7.26562 8.82422 6H7.92188C8.23438 7.25781 8.54297 8.50781 8.84766 9.75C9.15234 10.9922 9.46875 12.2422 9.79688 13.5H3.70312L3.51562 12.75L3.32812 12H1.92188L2.67188 15ZM24 19.5V21H7.5V19.5H24Z" fill="white" />
                        </svg>
                        New Arrival
                      </div>
                    </li>
                  </Link>
                  <Link to="/categorys/stock-clearance" >
                    <li className="slcard4">
                      <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M1.414 16.432L0 15.018L7.071 7.94696L13.435 14.311L17.678 10.068L15.935 8.32596L22.627 6.53296L20.834 13.225L19.092 11.483L13.435 17.139L7.071 10.775L1.414 16.432Z" fill="white" />
                        </svg>
                        Stock Clearance
                      </div>
                    </li>
                  </Link>



                </ul>
              </div>

            ) : null}

            {/* Services Sub Links  */}
            {servicesSubLinks ? (
              <div className="overf-div2">
                <ul className="banner-services my-lg-3  d-flex  my-md-3 my-2  gap-2 justify-content-between">
                  <Link to="/service-category" >
                    <li className="lcard4">
                      <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                        <img src="/assets/Icons/mb-startup.png" alt="" width={"30px"} />
                        All Services
                      </div>
                    </li>
                  </Link>


                  {props?.data?.map((item, i) => (

                    <Link to={"/service-categorys/" + item.slug} className={props?.data?.length - 1 != i ? " border-2 border-white border-end mr-1 pe-1" : ""} key={i}>
                      <li className="lcard4" >
                        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          <img src="/assets/Icons/mb-startup.png" alt="" width={"30px"} />
                          {item.name}
                        </div>
                      </li>
                    </Link>


                  ))}
                  {/* <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/categorys/digital-services">Digital Services</Link>  
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/categorys/professional-services"> Professional Services</Link> {" "}
              </li>
              <li className=" border-2 border-white border-end mr-1 pe-1">
              <Link to="/directory-listing"> Directory Listing</Link> {" "}
              </li>
              <li className="">
                Service Providers
              </li> */}
                </ul>
              </div>
            ) : null}

            {/* Directory Sub Links */}
            {directorySubLinks ? (
              <div className="overf-div2">
                <ul className="banner-services my-lg-3 gap-2 d-flex my-md-3 my-2 justify-content-between">
                  {businessnature && businessnature.map((item, i) => (
                    <>
                   
                    <Link to={"/directory-listing/" + item} className={businessnature.length - 1 != i ? " mr-1 pe-1" : ""} key={i}>
                      <li className={`lcard${count}`} >
                       
                        <div className="d-flex flex-column gap-1 justify-content-center align-items-center">
                          {/* <img src="/assets/Icons/mb-startup.png" alt="" width={"30px"} /> */}
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M22.5 14.25C22.5 14.5625 22.4414 14.8555 22.3242 15.1289C22.207 15.4023 22.0469 15.6406 21.8438 15.8438C21.6406 16.0469 21.4023 16.207 21.1289 16.3242C20.8555 16.4414 20.5625 16.5 20.25 16.5H1.5L0 10.5H4.5L4.875 12H7.875L6 4.5H9.75L13.5 12H20.25C20.5625 12 20.8555 12.0586 21.1289 12.1758C21.4023 12.293 21.6406 12.4531 21.8438 12.6562C22.0469 12.8594 22.207 13.0977 22.3242 13.3711C22.4414 13.6445 22.5 13.9375 22.5 14.25ZM2.67188 15H20.25C20.4531 15 20.6289 14.9258 20.7773 14.7773C20.9258 14.6289 21 14.4531 21 14.25C21 14.0469 20.9258 13.8711 20.7773 13.7227C20.6289 13.5742 20.4531 13.5 20.25 13.5H12.5742C11.9648 12.2344 11.3477 10.9844 10.7227 9.75C10.0977 8.51562 9.46484 7.26562 8.82422 6H7.92188C8.23438 7.25781 8.54297 8.50781 8.84766 9.75C9.15234 10.9922 9.46875 12.2422 9.79688 13.5H3.70312L3.51562 12.75L3.32812 12H1.92188L2.67188 15ZM24 19.5V21H7.5V19.5H24Z" fill="white" />
                        </svg>
                          {item}
                        </div>
                      </li>
                    </Link>
                    </>

                  ))}



                </ul>
              </div>
            ) : null}
          </div>
        </div>

        {/* Banner Bottom Part */}
        <div className="banner-bottom-part">
          <div className="banner-bottom-content" dangerouslySetInnerHTML={{ __html: allwidget.widget1 }}>
            {/* <div className="banner-bottom-content"  > */}
            {/* <div className="request-quotes" >
          
            <img src="/assets/Icons/quotes.png" width="50px" />
            <div className="pd-2">
              <h6>Request a Quote</h6>
              <p>One request, multiple quotes</p>
            </div>
          </div>
          <div className="request-quotes">
            <img src="/assets/Icons/sell-on.png" width="50px" />
            <div className="pd-2">
              <h6>Sell on APNASHAHER.COM</h6>
              <p>Reach millions of customer globally</p>
            </div>
          </div>
          <div className="request-quotes">
            <img src="/assets/Icons/your-startup.png" width="50px" />
            <div className="pd-2">
              <h6>Build your STARTUP</h6>
              <p>List your startup to grow more</p>
            </div>
          </div> */}
          </div>
        </div>

    </>
  )
}
