import React,{useState,useEffect} from 'react'
import "../style/SellerDetails.css"
import { SdComp2 } from '../components/seller-detail/SdComp2'
import { Sdbanner } from '../components/seller-detail/Sdbanner'
import { SdComp3 } from '../components/seller-detail/SdComp3'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../config'
import Loader from '../components/loader/Loader.component'
export const SellerDetails = () => {
  const { slug } = useParams();
  const [data,setdata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
useEffect(()=>{
  fetchdata();
},[])


  const fetchdata = async () => {
    await axios.get(BASE_URL + '/seller/getheader', {
        headers: {
            'Accept': 'application/json',
            'slug': slug
        }
    }).then(({ data }) => {
      setdata(data)
      setIsLoading(false)
    }).catch(({ response }) => {
      setIsLoading(false)
    })
}
if (isLoading) {
  return <Loader />;
}
  return (
    <>
    <section>
        <Sdbanner data={data}/>
    </section>
    <section>
        <SdComp2  data={data} />
    </section>

    <section>
      <SdComp3 data={data}/>
    </section>

    </>
  )
}
