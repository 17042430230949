import React from 'react'
import { Link } from 'react-router-dom'
export const MediaPress = () => {
    return (

        <>
            <div className="media-press">
                <div className="mb-body px-lg-5 px-md-4 px-3 py-lg-5 py-lg-4 py-3  d-flex flex-wrap gap-lg-4 gap-3 justify-content-center">
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    <div class="card" >
                        <img src="assets/images/press.png" class="card-img-top" alt="..." />
                            <div class="card-body">
                                <p class="card-text"> <span><i className='fa fa-calendar pe-lg-3 pe-2'></i></span>19 Feb 2023</p>
                                <Link to =""><h5 class="card-title">Lorem ipsum dolor sit amet, conse ctetur adipisc</h5></Link>
                            </div>
                    </div>
                    
                </div>
            </div>

        </>
    )
}
