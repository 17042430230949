import React from 'react'
import { Carousel } from 'react-responsive-carousel';
import "../../style/Startup.css"

export const Banner = () => {
    const slider = [
        { id: 1 }, { id: 2 }, { id: 3 }
    ];


  return (
    <>
     <div className="row startup-banner px-2">
                    <div className="col-lg-6 col-12">
                        <Carousel
                            showThumbs={false}
                            autoPlay={true}
                            interval={2500}
                            showStatus={false}
                            showArrows={false}
                            showIndicators={false}
                            infiniteLoop={true}
                        >
                            {slider.map((data) => (
                                <div><img src="assets/images/banner_interiordesigners 1.png" height={230} /></div>
                            ))}
                        </Carousel>
                    </div>
                    <div className="col-lg-6 col-12 text-center flex-wrap row">
                        <div className="col-md-3 col-sm-3 col-6">
                            <div className="banner-card-img"><img src="assets/images/Frame 2.png" /></div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-6">

                            <div className="banner-card-img"><img src="assets/images/Frame 3.png" /></div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-6">

                            <div className="banner-card-img"><img src="assets/images/Frame 4.png" /></div>
                        </div>
                        <div className="col-md-3 col-sm-3 col-6">
                            <div className="banner-card-img"><img src="assets/images/Frame 5.png" /></div>
                        </div>
                    </div>
                </div>
    </>
  )
}
