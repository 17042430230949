import React from "react";
import "../../style/Sellerbenefites.css";

export const Sbsection4 = ({heading, hspan, para}) => {
  return (
    <>
      <div className="sb-section4 my-lg-5 my-4">
        <div className="container">
            
          <div className="top-content text-center mb-4">
            <h3>{heading}  <span>{hspan}</span></h3>
            {/* <h3>Lorem ipsum dolor sit amet, <span>love selling on Apna Shaher?</span></h3> */}
            <p className="w-75" style={{margin:"auto"}}>
             {para}
            </p>
          </div>

          <div className="cards-parent">         
          <div className="cards d-flex flex-wrap gap-3 justify-content-between mb-2">
                <div className="card-item text-center bg-white px-3 py-3 rounded mb-4 shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-3 rounded mb-4 shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-3 rounded mb-4 shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-3 rounded mb-4 shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-4 rounded shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-4 rounded shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-4 rounded shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
                <div className="card-item text-center bg-white px-3 py-4 rounded shadow-sm">
                    <img src="/assets/images/wy-sell-growth.png" alt="" />
                    <h5>Predictable returns</h5>
                    <p>Lorem ipsum dolor sit ametLorem ipsum</p>
                </div>
              
          </div>
          </div>
        </div>
      </div>
    </>
  );
};
