import React from 'react'
import { Link } from 'react-router-dom'
import "../../style/Startup.css"


export const OpenStartup = () => {
    return (
        <>
            <div className="open-startup my-lg-5 my-md-4 my-3">
                <div className="container d-flex gap-3  flex-lg-row flex-column-reverse justify-content-between align-items-center">
                    <div className="col-lg-7  col-12 left-contnet">
                        <h3 className='mb-3'><span>Open</span> your Startup on <span>Apna Shaher</span>
                            and sell to 45+Crore customers</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing ..</p>
                            <div className="links d-flex flex-column  ">
                                <Link to ="/startup-form">List my Startup on APNASHAHER.COM</Link>
                                <Link to ="/build-my-startup" style={{backgroundColor:"#ffb800", color:"#000"}}>I have an startup Idea want to build it</Link>
                            </div>
                    </div>
                    <div className="col-lg-5 col-12 right-img text-center">
                    <img src="assets/images/open-startup1.png" alt="" style={{maxHeight:"449px"}}/>
                    </div>
                </div>
            </div>
        </>
    )
}
