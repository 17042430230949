import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import DispatchContext from '../context/DispatchContext';
import StateContext from '../context/StateContext';
import axios from 'axios';
import { BASE_URL } from '../config';
import { useNavigate } from 'react-router-dom';
import Collapsible from 'react-collapsible'


import Axios from 'axios';

const DashboardSideMenu = () => {
  const [show, setShow] = useState(false);
  const [showd, setShowd] = useState(false);
  const [showweb, setShowweb] = useState(false);
  const [productDrop, setProductDrop] = useState(false);
  const [serviceDrop, setServiceDrop] = useState(false);
  const appState = useContext(StateContext);
  const [openDashboard, setOpenDashboard] = useState(false)
  const appDispatch = useContext(DispatchContext);
  const pathname = useLocation();
  const pageLocation = window.location.pathname;
  const token = localStorage.getItem('avonToken');
  const [modal, setModal] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [statusData, setStatusData] = useState();
  const [status, setStatus] = useState(false)
  const [orderMenu, setOrderMenu] = useState(false)
  const [walletMenu, setWalletMenu] = useState(false);
  const [helpsupport, sethelpsupport] = useState(false);
  const [subMenu, setSubMenu] = useState(false)
  const [banned, setbanned] = useState(0)
  const [isShow, setIsShow] = useState(false);

  console.log('statusData', statusData)




  const FetchStatus = async () => {

    await axios.get(BASE_URL + '/get-seller-detail', {
      headers: {
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }).then(({ data }) => {
      setStatusData(data)
      setbanned(data.user)
      if (data && data.data && data.data.payment_status == 'Active') {
        setStatus(data.user == 1 ? false : data.data.payment_status == "Active" ? true : false)
      }

    })
  }

  const handleOpenMobileDropdown = () => {
    setOpenDashboard(!openDashboard);
  }

  useEffect(() => {
    FetchStatus();
  }, [])
  useEffect(() => {

    if (pageLocation == '/business-profile' || pageLocation == '/tax-and-bank-info' || pageLocation == '/vendor-contact-us') {
      setShow(true)
    } else if (pageLocation == '/homepage-widget' || pageLocation == '/sliders-and-banners' || pageLocation == '/other-pages' || pageLocation == '/our-feature' || pageLocation == '/brand-and-sponsors' || pageLocation == '/vendor-Faqs' || pageLocation == '/vendor-blogs') {
      setShowweb(true)
    }else if(pageLocation == '/digital-marketing-history' || pageLocation == '/social-media'){
      setShowd(true)
    } else {
      setShow(false)
      setProductDrop(false)
    }
  }, [pageLocation])
  async function handleLogOut(e) {
    e.preventDefault();
    try {
      await Axios.get('/log-out', {
        headers: {
          Authorization: `Bearer ${appState.state.user.token}`,
        },
      });
      navigate('/')
      appDispatch({ type: 'logout' });
      localStorage.removeItem('avonToken');
      localStorage.removeItem('avonName');
      localStorage.removeItem('avonEmail');
      localStorage.removeItem('avonMobileNumber');
      localStorage.removeItem('avonProfileImage');
      localStorage.removeItem('avonLastLogin');
    } catch (error) {
      // console.log('error encountered');
    }
  }
  const prevent1 = (e) => {
    e.preventDefault();
    return false;
  }
  return (
    // <div className="col-md-3" style={{ pointerEvents: status === true ? '' : 'none' }}>
    <div className="col-md-3" >
      <div className="sidebar-dashboard">
        <div className="user-details-side col-md-12 col-sm-12 col-xs-12 hide-in-mobile">
          {/* <div className="user-img col-md-3 col-sm-3 col-xs-3 nopadding"> */}
          <div className="user-img  nopadding">
            <img src={appState.state.user.profileImage ? appState.state.user.profileImage : 'assets/images/avtar.png'} alt="" />
          </div>
          <div className="user-info col-md-9 col-sm-9 col-xs-9 nopadding">
            <h4 title={appState.state.user.name}>{appState.state.user.name && appState.state.user.name.length > 17 ? appState.state.user.name.slice(0, 17) + "..." : appState.state.user.name}</h4>
            <p title={appState.state.user.mobileNumber}>
              <i className="fa fa-phone"></i> {appState.state.user.mobileNumber}
            </p>
            <p title={appState.state.user.email}>
              <i className="fas fa-envelope-square"></i> {appState.state.user.email && appState.state.user.email.length > 17 ? appState.state.user.email.slice(0, 17) + "...." : appState.state.user.email}
            </p>
          </div>
        </div>

        <div className='hide-in-mobile'>
          <ul>
            <li>
              <NavLink to="/vendor-dashboard" disabled>
                <button>
                  <i className="fas fa-home"></i> Dashboard
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/vendor-account">
                <i className="fas fa-address-book"></i> My Profile
              </NavLink>
            </li>

            <li>
              <NavLink onClick={() => setShow(!show)} style={{ display: 'flex', justifyContent: 'space-between' }} disabled>
                <button>
                  <i className="fas fa-user-cog"></i> Business Setup
                </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {show &&
              <>
                <li>
                  <NavLink to="/business-profile">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-user"></i> Business Profile
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/tax-and-bank-info">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-landmark"></i> Tax & Documents
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-contact-us">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-landmark"></i> Contact Us
                    </button>
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink to="/store-setup">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-store"></i> Store & Website Setup
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sliders-and-banners">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-sliders-h"></i> Sliders & Banners
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-about">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-info-circle"></i> About Us Page
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-Faqs">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-question-circle"></i> FAQ
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-blogs">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-blog"></i> Blogs
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/other-pages">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-file"></i> Other Pages
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/homepage-widget">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-file"></i>HomePage Widget
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/brand-and-sponsors">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-donate"></i> Brands & Sponsores
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/our-feature">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-donate"></i> Our Features
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/pickup-address">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-address-card"></i> Pickup Addresses
                    </button>
                  </NavLink>
                </li> */}
              </>}
            {(statusData?.data?.package_type === "Services" || statusData?.data?.package_type === "Product") && statusData?.data?.customer_cancelled === "no" && (
              <>
                <li>
                  <NavLink onClick={() => setShowweb(!showweb)} style={{ display: 'flex', justifyContent: 'space-between' }} disabled>
                    <button>
                      <i className="fas fa-user-cog"></i> Website & Store Setup
                    </button>
                    <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                  </NavLink>
                </li>
                {showweb &&
                  <>
                    <li>
                      <NavLink to="/homepage-widget">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-file"></i>HomePage Widget
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/sliders-and-banners">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-sliders-h"></i> Sliders & Banners
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/other-pages">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-file"></i> Company Profile
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/our-feature">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-donate"></i> Features & Highlights
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/brand-and-sponsors">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-donate"></i> Our Brands & Sponsors
                        </button>
                      </NavLink>
                    </li>





                    <li>
                      <NavLink to="/vendor-Faqs">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-question-circle"></i> FAQ
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-blogs">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-blog"></i> Blogs
                        </button>
                      </NavLink>
                    </li>

                    {/* <li>
                  <NavLink to="/homepage-widget">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-file"></i>HomePage Widget
                    </button>
                  </NavLink>
                </li> */}


                    {/* <li>
                  <NavLink to="/pickup-address">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-address-card"></i> Pickup Addresses
                    </button>
                  </NavLink>
                </li> */}
                  </>}
              </>
            )}

            {(statusData?.data?.package_type === "Product" && statusData?.data?.customer_cancelled === "no") && (
              <>
                <li>
                  <NavLink onClick={() => setProductDrop(!productDrop)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button>
                      <i className="fas fa-user-cog"></i> Product Catalogue
                    </button>
                    <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                  </NavLink>
                </li>
                {productDrop &&
                  <>
                    <li>
                      <NavLink to="/vendor-products">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-shopping-bag"></i> Manage Products
                        </button>
                      </NavLink>
                    </li>
                    {/* <li>
                  <NavLink to="/digital-services">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-shopping-bag"></i> Digital Products
                    </button>
                  </NavLink>
                </li> */}
                    <li>
                      <NavLink to="/bulk-upload">
                        <i className='fas fa-angle-right'></i>
                        <button >
                          <i className="fas fa-shopping-basket"></i> Bulk Uploads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/product-download">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-download"></i> Downloads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-product-analytics">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-comment"></i> Analytics
                        </button>
                      </NavLink>
                    </li>
                  </>
                }
              </>
            )}
            {(statusData?.data?.package_type === "Services" && statusData?.data?.customer_cancelled === "no") && (
              <>
                <li>
                  <NavLink onClick={() => setServiceDrop(!serviceDrop)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button>
                      <i className="fas fa-user-cog"></i> Service Catalogue
                    </button>
                    <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                  </NavLink>
                </li>
                {serviceDrop &&
                  <>
                    <li>
                      <NavLink to="/manage-services">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-shopping-bag"></i> Manage Services
                        </button>
                      </NavLink>
                    </li>
                    {/* <li>
                  <NavLink to="/digital-services">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-shopping-bag"></i> Digital Products
                    </button>
                  </NavLink>
                </li> */}
                    <li>
                      <NavLink to="/vendor-services-enquiry">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-shopping-bag"></i> Manage Services Enquiry
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-bulk-upload-services">
                        <i className='fas fa-angle-right'></i>
                        <button >
                          <i className="fas fa-shopping-basket"></i> Bulk Uploads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-services-download">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-download"></i> Downloads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-services-analytics">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-comment"></i>Analytics
                        </button>
                      </NavLink>
                    </li>
                  </>
                }

              </>
            )}

            <li>
              <NavLink to="/vendor-inquiries">

                <button>
                  <i className="fas fa-gift"></i> All Inquries
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/manage-startup">
                <button>
                  <i className="fas fa-gift"></i> Manage Startup
                </button>
              </NavLink>
            </li>
            <li >
              <NavLink to="/vendor-review-feedback" >
                <button> <i className="fas fa-gift"></i> Manage Review & Feedback </button>
              
              </NavLink>
            </li>
            <li >
              <NavLink onClick={() => setSubMenu(!subMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button> <i className="fas fa-gift"></i> Business Leads </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {subMenu &&
              <>
                <li>
                  <NavLink to="/new-leads">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Fresh Enquiries
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/lead-purchage-history">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Followups
                    </button>
                  </NavLink>
                </li>
              </>
            }

            <li>

              <NavLink to="/manage-quote">

                <button>
                  <i className="fas fa-gift"></i> Manage Quote
                </button>
              </NavLink>
            </li>
            {/* <li>
            <NavLink to="/manage-edit-request">
                   
                    <button>
                      <i className="fas fa-gift"></i> Manage Edit Request
                    </button>
                  </NavLink>
            </li> */}
            <li>
              <NavLink to="/live-chat">

                <button>
                  <i className="fas fa-comment-dots"></i> Live Chat
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => setSubMenu(!subMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-gift"></i> Subscription
                </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {subMenu &&
              <>
                <li>
                  <NavLink to="/subscription">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> My Subscription
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/packages">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Vendor Package
                    </button>
                  </NavLink>
                </li>
              </>
            }

            {/* <li>
              <NavLink onClick={() => setOrderMenu(!orderMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-gift"></i> Orders & Inquiries
                </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {orderMenu &&
              <>
                <li>
                  <NavLink to="/vendor-orders">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-gift"></i> All Orders
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-inquiries">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-gift"></i> All Inquries
                    </button>
                  </NavLink>
                </li>
              </>
            } */}
            {/* <li>

              <NavLink to="/purchase-history">
                <button >
                  <i className="fas fa-history"></i> Purchase History
                </button>
              </NavLink>
            </li> */}
            <li>
              <NavLink style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-wallet"></i> Wallet
                </button>
                <i className="fas fa-angle-down" onClick={() => setWalletMenu(!walletMenu)} style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {walletMenu &&
              <>
                <li>
                  <NavLink to="/wallet">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-wallet"></i> My Wallet
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payment-history">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-history"></i> Transaction History
                    </button>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/money-withdraw">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Money Withdraw
                    </button>
                  </NavLink>
                </li> */}
              </>
            }
            <li>
              <NavLink to="/manage-edit-request">
                <button >
                  <i className="fas fa-comment-dots"></i> Edit Request
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/product-review">
                <button >
                  <i className="fas fa-comment-dots"></i> Rating & Review
                </button>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/wishlist">
                <button >
                  <i className="fas fa-heart"></i> Wishlist
                </button>
              </NavLink>
            </li> */}

            <li>
              <NavLink to="/support-ticket" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-home"></i>Help and Support
                </button>
                <i className="fas fa-angle-down" onClick={() => sethelpsupport(!helpsupport)} style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {helpsupport &&
              <>
                <li>
                  <NavLink to="/vendor-Faqs">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-wallet"></i> FAQ
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/support-ticket">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-history"></i> Contact Support
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-learn">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Learn
                    </button>
                  </NavLink>
                </li>
              </>
            }
            <li>
              <NavLink onClick={() => setShowd(!showd)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button>
                  <i className="fas fa-home"></i>Digital Marketing
                </button>
                <i className="fas fa-angle-down" onClick={() => setShowd(!showd)} style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {showd &&
                <>
                  <li>
                    <NavLink to="/graphic">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-user"></i>  Graphics
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/social-media">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-landmark"></i> Social Media Post
                      </button>
                    </NavLink>
                  </li>
              
              
                </>}
            <li>

              <NavLink to="/vendor-settings">
                <button >
                  <i className="fas fa-history"></i> Settings
                </button>
              </NavLink>
            </li>


            <li>
              <NavLink to="/log-out" onClick={handleLogOut}>
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </li>
          </ul>
          <div className="last-login">
            <b>Last login:</b> {appState.state.user.lastLogin}
          </div>
        </div>

        <div className='dashboard-dropdown-toggle'>
          <div onClick={handleOpenMobileDropdown} className='d-flex justify-content-between' style={{ padding: "15px" }}>
            {/* <h2 >Dashboard Menu</h2> */}
            <i className="fas fa-bars" style={{ fontSize: "28px" }}></i>
          </div>
        </div>
        {
          openDashboard &&
          <div className=''>
             <ul>
            <li>
              <NavLink to="/vendor-dashboard" disabled>
                <button>
                  <i className="fas fa-home"></i> Dashboard
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/vendor-account">
                <i className="fas fa-address-book"></i> My Profile
              </NavLink>
            </li>

            <li>
              <NavLink onClick={() => setShow(!show)} style={{ display: 'flex', justifyContent: 'space-between' }} disabled>
                <button>
                  <i className="fas fa-user-cog"></i> Business Setup
                </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {show &&
              <>
                <li>
                  <NavLink to="/business-profile">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-user"></i> Business Profile
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/tax-and-bank-info">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-landmark"></i> Tax & Documents
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-contact-us">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-landmark"></i> Contact Us
                    </button>
                  </NavLink>
                </li>

                {/* <li>
                  <NavLink to="/store-setup">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-store"></i> Store & Website Setup
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/sliders-and-banners">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-sliders-h"></i> Sliders & Banners
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-about">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-info-circle"></i> About Us Page
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-Faqs">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-question-circle"></i> FAQ
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-blogs">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-blog"></i> Blogs
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/other-pages">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-file"></i> Other Pages
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/homepage-widget">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-file"></i>HomePage Widget
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/brand-and-sponsors">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-donate"></i> Brands & Sponsores
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/our-feature">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-donate"></i> Our Features
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/pickup-address">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-address-card"></i> Pickup Addresses
                    </button>
                  </NavLink>
                </li> */}
              </>}
            {(statusData?.data?.package_type === "Services" || statusData?.data?.package_type === "Product") && statusData?.data?.customer_cancelled === "no" && (
              <>
                <li>
                  <NavLink onClick={() => setShowweb(!showweb)} style={{ display: 'flex', justifyContent: 'space-between' }} disabled>
                    <button>
                      <i className="fas fa-user-cog"></i> Website & Store Setup
                    </button>
                    <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                  </NavLink>
                </li>
                {showweb &&
                  <>
                    <li>
                      <NavLink to="/homepage-widget">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-file"></i>HomePage Widget
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/sliders-and-banners">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-sliders-h"></i> Sliders & Banners
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/other-pages">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-file"></i> Company Profile
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/our-feature">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-donate"></i> Features & Highlights
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/brand-and-sponsors">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-donate"></i> Our Brands & Sponsors
                        </button>
                      </NavLink>
                    </li>





                    <li>
                      <NavLink to="/vendor-Faqs">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-question-circle"></i> FAQ
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-blogs">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-blog"></i> Blogs
                        </button>
                      </NavLink>
                    </li>

                    {/* <li>
                  <NavLink to="/homepage-widget">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-file"></i>HomePage Widget
                    </button>
                  </NavLink>
                </li> */}


                    {/* <li>
                  <NavLink to="/pickup-address">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-address-card"></i> Pickup Addresses
                    </button>
                  </NavLink>
                </li> */}
                  </>}
              </>
            )}

            {(statusData?.data?.package_type === "Product" && statusData?.data?.customer_cancelled === "no") && (
              <>
                <li>
                  <NavLink onClick={() => setProductDrop(!productDrop)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button>
                      <i className="fas fa-user-cog"></i> Product Catalogue
                    </button>
                    <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                  </NavLink>
                </li>
                {productDrop &&
                  <>
                    <li>
                      <NavLink to="/vendor-products">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-shopping-bag"></i> Manage Products
                        </button>
                      </NavLink>
                    </li>
                    {/* <li>
                  <NavLink to="/digital-services">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-shopping-bag"></i> Digital Products
                    </button>
                  </NavLink>
                </li> */}
                    <li>
                      <NavLink to="/bulk-upload">
                        <i className='fas fa-angle-right'></i>
                        <button >
                          <i className="fas fa-shopping-basket"></i> Bulk Uploads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/product-download">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-download"></i> Downloads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-product-analytics">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-comment"></i> Analytics
                        </button>
                      </NavLink>
                    </li>
                  </>
                }
              </>
            )}
            {(statusData?.data?.package_type === "Services" && statusData?.data?.customer_cancelled === "no") && (
              <>
                <li>
                  <NavLink onClick={() => setServiceDrop(!serviceDrop)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button>
                      <i className="fas fa-user-cog"></i> Service Catalogue
                    </button>
                    <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                  </NavLink>
                </li>
                {serviceDrop &&
                  <>
                    <li>
                      <NavLink to="/manage-services">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-shopping-bag"></i> Manage Services
                        </button>
                      </NavLink>
                    </li>
                    {/* <li>
                  <NavLink to="/digital-services">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-shopping-bag"></i> Digital Products
                    </button>
                  </NavLink>
                </li> */}
                    <li>
                      <NavLink to="/vendor-services-enquiry">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-shopping-bag"></i> Manage Services Enquiry
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-bulk-upload-services">
                        <i className='fas fa-angle-right'></i>
                        <button >
                          <i className="fas fa-shopping-basket"></i> Bulk Uploads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-services-download">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-download"></i> Downloads
                        </button>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vendor-services-analytics">
                        <i className='fas fa-angle-right'></i>
                        <button>
                          <i className="fas fa-comment"></i>Analytics
                        </button>
                      </NavLink>
                    </li>
                  </>
                }

              </>
            )}

            <li>
              <NavLink to="/vendor-inquiries">

                <button>
                  <i className="fas fa-gift"></i> All Inquries
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/manage-startup">
                <button>
                  <i className="fas fa-gift"></i> Manage Startup
                </button>
              </NavLink>
            </li>
            <li >
              <NavLink to="/vendor-review-feedback" >
                <button> <i className="fas fa-gift"></i> Manage Review & Feedback </button>
              
              </NavLink>
            </li>
            <li >
              <NavLink onClick={() => setSubMenu(!subMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button> <i className="fas fa-gift"></i> Business Leads </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {subMenu &&
              <>
                <li>
                  <NavLink to="/new-leads">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Fresh Enquiries
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/lead-purchage-history">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Followups
                    </button>
                  </NavLink>
                </li>
              </>
            }

            <li>

              <NavLink to="/manage-quote">

                <button>
                  <i className="fas fa-gift"></i> Manage Quote
                </button>
              </NavLink>
            </li>
            {/* <li>
            <NavLink to="/manage-edit-request">
                   
                    <button>
                      <i className="fas fa-gift"></i> Manage Edit Request
                    </button>
                  </NavLink>
            </li> */}
            <li>
              <NavLink to="/live-chat">

                <button>
                  <i className="fas fa-comment-dots"></i> Live Chat
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink onClick={() => setSubMenu(!subMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-gift"></i> Subscription
                </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {subMenu &&
              <>
                <li>
                  <NavLink to="/subscription">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> My Subscription
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/packages">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Vendor Package
                    </button>
                  </NavLink>
                </li>
              </>
            }

            {/* <li>
              <NavLink onClick={() => setOrderMenu(!orderMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-gift"></i> Orders & Inquiries
                </button>
                <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {orderMenu &&
              <>
                <li>
                  <NavLink to="/vendor-orders">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-gift"></i> All Orders
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-inquiries">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-gift"></i> All Inquries
                    </button>
                  </NavLink>
                </li>
              </>
            } */}
            {/* <li>

              <NavLink to="/purchase-history">
                <button >
                  <i className="fas fa-history"></i> Purchase History
                </button>
              </NavLink>
            </li> */}
            <li>
              <NavLink style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-wallet"></i> Wallet
                </button>
                <i className="fas fa-angle-down" onClick={() => setWalletMenu(!walletMenu)} style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {walletMenu &&
              <>
                <li>
                  <NavLink to="/wallet">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-wallet"></i> My Wallet
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/payment-history">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-history"></i> Transaction History
                    </button>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink to="/money-withdraw">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Money Withdraw
                    </button>
                  </NavLink>
                </li> */}
              </>
            }
            <li>
              <NavLink to="/manage-edit-request">
                <button >
                  <i className="fas fa-comment-dots"></i> Edit Request
                </button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/product-review">
                <button >
                  <i className="fas fa-comment-dots"></i> Rating & Review
                </button>
              </NavLink>
            </li>

            {/* <li>
              <NavLink to="/wishlist">
                <button >
                  <i className="fas fa-heart"></i> Wishlist
                </button>
              </NavLink>
            </li> */}

            <li>
              <NavLink to="/support-ticket" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-home"></i>Help and Support
                </button>
                <i className="fas fa-angle-down" onClick={() => sethelpsupport(!helpsupport)} style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {helpsupport &&
              <>
                <li>
                  <NavLink to="/vendor-Faqs">
                    <i className='fas fa-angle-right'></i>
                    <button>
                      <i className="fas fa-wallet"></i> FAQ
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/support-ticket">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-history"></i> Contact Support
                    </button>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/vendor-learn">
                    <i className='fas fa-angle-right'></i>
                    <button >
                      <i className="fas fa-money-check"></i> Learn
                    </button>
                  </NavLink>
                </li>
              </>
            }
            <li>
              <NavLink onClick={() => setShowd(!showd)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button >
                  <i className="fas fa-home"></i>Digital Marketing
                  
                </button>
                <i className="fas fa-angle-down" onClick={() => setShowd(!showd)} style={{ fontSize: 20 }}></i>
              </NavLink>
            </li>
            {showd &&
                <>
                  <li>
                    <NavLink to="/digital-marketing-history">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-user"></i>  Graphics
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/social-media">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-landmark"></i> Social Media Post
                      </button>
                    </NavLink>
                  </li>
              
              
                </>}
            <li>

              <NavLink to="/vendor-settings">
                <button >
                  <i className="fas fa-history"></i> Settings
                </button>
              </NavLink>
            </li>


            <li>
              <NavLink to="/log-out" onClick={handleLogOut}>
                <i className="fas fa-sign-out-alt"></i> Logout
              </NavLink>
            </li>
          </ul>
            {/* <ul>
              <li>
                <NavLink to="/vendor-dashboard" disabled>
                  <button>
                    <i className="fas fa-home"></i> Dashboard
                  </button>
                </NavLink>
              </li>

              <li>
                <NavLink onClick={() => setShow(!show)} style={{ display: 'flex', justifyContent: 'space-between' }} disabled>
                  <button>
                    <i className="fas fa-user-cog"></i> Business & Website
                  </button>
                  <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                </NavLink>
              </li>
              {show &&
                <>
                  <li>
                    <NavLink to="/business-profile">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-user"></i> Business Profile
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/tax-and-bank-info">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-landmark"></i> Tax & Bank Detail
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/store-setup">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-store"></i> Store & Website Setup
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/sliders-and-banners">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-sliders-h"></i> Sliders & Banners
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/vendor-about">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-info-circle"></i> About Us Page
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/vendor-Faqs">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-question-circle"></i> FAQ
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/vendor-blogs">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-blog"></i> Blogs
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/other-pages">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-file"></i> Other Pages
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/homepage-widget">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-file"></i>HomePage Widget
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/brand-and-sponsors">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-donate"></i> Brands & Sponsores
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/pickup-address">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-address-card"></i> Pickup Addresses
                      </button>
                    </NavLink>
                  </li>
                </>}
              <li>
                <NavLink onClick={() => setProductDrop(!productDrop)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button>
                    <i className="fas fa-user-cog"></i> Products & Reviews
                  </button>
                  <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                </NavLink>
              </li>
              {productDrop &&
                <>
                  <li>
                    <NavLink to="/vendor-products">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-shopping-bag"></i> Manage Products
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/digital-services">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-shopping-bag"></i> Digital Products
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/bulk-upload">
                      <i className='fas fa-angle-right'></i>
                      <button >
                        <i className="fas fa-shopping-basket"></i> Product Bulk Upload
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product-download">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-download"></i> Downloads
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product-review">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-comment"></i> Product Reviews
                      </button>
                    </NavLink>
                  </li>
                </>
              }

              <li>
                <NavLink onClick={() => setOrderMenu(!orderMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button >
                    <i className="fas fa-gift"></i> Orders & Inquiries
                  </button>
                  <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                </NavLink>
              </li>
              {orderMenu &&
                <>
                  <li>
                    <NavLink to="/vendor-orders">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-gift"></i> All Orders
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/vendor-inquiries">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-gift"></i> All Inquries
                      </button>
                    </NavLink>
                  </li>
                </>
              }
              <li>

                <NavLink to="/purchase-history">
                  <button >
                    <i className="fas fa-history"></i> Purchase History
                  </button>
                </NavLink>
              </li>
              <li>
                <NavLink style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button >
                    <i className="fas fa-wallet"></i> Wallet & Payments
                  </button>
                  <i className="fas fa-angle-down" onClick={() => setWalletMenu(!walletMenu)} style={{ fontSize: 20 }}></i>
                </NavLink>
              </li>
              {walletMenu &&
                <>
                  <li>
                    <NavLink to="/wallet">
                      <i className='fas fa-angle-right'></i>
                      <button>
                        <i className="fas fa-wallet"></i> My Wallet
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/payment-history">
                      <i className='fas fa-angle-right'></i>
                      <button >
                        <i className="fas fa-history"></i> Payment History
                      </button>
                    </NavLink>
                  </li>
                 
                </>
              }
              <li>
                <NavLink to="/conversations">
                  <button >
                    <i className="fas fa-comment-dots"></i> Conversations
                  </button>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={() => setSubMenu(!subMenu)} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <button >
                    <i className="fas fa-gift"></i> Subscriptions
                  </button>
                  <i className="fas fa-angle-down" style={{ fontSize: 20 }}></i>
                </NavLink>
              </li>
              {subMenu &&
                <>
                  <li>
                    <NavLink to="/subscription">
                      <i className='fas fa-angle-right'></i>
                      <button >
                        <i className="fas fa-money-check"></i> My Subscription
                      </button>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/packages">
                      <i className='fas fa-angle-right'></i>
                      <button >
                        <i className="fas fa-money-check"></i> Vendor Package
                      </button>
                    </NavLink>
                  </li>
                </>
              }
              <li>
                <NavLink to="/wishlist">
                  <button >
                    <i className="fas fa-heart"></i> Wishlist
                  </button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/support-ticket">
                  <button >
                    <i className="fas fa-home"></i>Help and Support
                  </button>
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-account">
                  <i className="fas fa-address-book"></i> My Profile
                </NavLink>
              </li>
              <li>
                <NavLink to="/log-out" onClick={handleLogOut}>
                  <i className="fas fa-sign-out-alt"></i> Logout
                </NavLink>
              </li>
            </ul> */}
            <div className="last-login">
              <b>Last login:</b> {appState.state.user.lastLogin}
            </div>
          </div>
        }
      </div>
    </div>
  );
};
export default DashboardSideMenu;
