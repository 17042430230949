import React from 'react'
import DashboardSideMenu from "../components/DashboardSideMenu";
import { Chat } from '../components/user-dashboard/Chat';

const LiveChat = () => {
  return (
    <>
      <div className="live-chat">
        <div className=" d-flex gap-lg-4 gap-3 flex-row px-5 py-4">

          <DashboardSideMenu />

          <div className="right w-100">
            <Chat/>
          </div>

          </div>
        </div>

      </>
      )
}

      export default LiveChat