import React, { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./headerPage.css";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../../../config";
import StateContext from "../../../context/StateContext";
import DispatchContext from "../../../context/DispatchContext";
import { MobileSidebar } from "./MobileSidebar";
import Accordion from 'react-bootstrap/Accordion';


export const MobileHeader = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [seller, setseller] = useState("");
  const [ban, setban] = useState(1);
  const [logged, setLogged] = useState(null);
  const [servicedata, setservicedata] = useState([]);
  const pageLocation = window.location.pathname;
  const navigate = useNavigate();
  useEffect(() => {
    pageLocation === "/dashboard"
      ? setLogged(1)
      : pageLocation === "/vendor-dashboard"
        ? setLogged(2)
        : setLogged(null);

  }, [pageLocation]);
  useEffect(() => {
    getheader();
  }, [appState?.state?.user?.token])
  const getheader = () => {
    if (appState?.state?.user?.token) {
      axios
        .get(BASE_URL + "/get-seller-detail", {
          headers: {
            Accept: "application/json",
            Authorization: "Bearer " + appState?.state?.user?.token,
          },
        })
        .then(({ data }) => {
          setseller(data.data);
          setban(data.user);
          if (data.user == 1) {
            setLogged(1);
          } else if (data.user == 0) {
            setLogged(2);
          } else {
            setLogged(null);
          }
        })
        .catch(({ response }) => {
          if (response.status === 401) {
            handleLogOut();
            console.log("------log2", response);
          }
        });
    }
  };
  async function handleLogOut() {
    // e.preventDefault();
    try {
      await axios.get(BASE_URL + "/log-out", {
        headers: {
          Authorization: `Bearer ${appState.state.user.token}`,
        },
      });
    } catch (error) {
      // console.log('error encountered');
    }
    navigate("/");
    appDispatch({ type: "logout" });
    localStorage.removeItem("avonToken");
    localStorage.removeItem("avonName");
    localStorage.removeItem("avonEmail");
    localStorage.removeItem("avonMobileNumber");
    localStorage.removeItem("avonProfileImage");
    localStorage.removeItem("avonLastLogin");
    setLogged(null);
  }
  const openSidebar = () => {
    const sbar = document.querySelector(".msbar-parent");
    sbar.style.left = "0";
    document.querySelector(".bg-color").style.display = "block";
  };

  const closeMenu = () => {
    const sbar = document.querySelector(".msbar-parent");
    sbar.style.left = "-100%";
    document.querySelector(".bg-color").style.display = "none";
  };

  // service category
  const getservices = () => {
    axios
      .get(BASE_URL + "/servicecategoryheader")
      .then(({ data }) => {
        setservicedata(data.data);
      })
      .catch(({ response }) => { });
  };

  useEffect(() => {
    getservices();

  }, [])

  return (
    <>
      <div className="mobile-header d-flex justify-content-between align-items-center py-3 px-1">
        <div className="bg-color"></div>
        <button onClick={openSidebar}>
          <img src="/assets/Icons/menu-icon.png" alt="menu-icon" />
        </button>
        <Link to="/">
          <img src="/assets/Icons/apna-logo.svg" alt="logo" width="150px" />
        </Link>

        {/* right-content----------   */}
        <div className="right-links d-flex gap-2 align-items-center">
          <div class="dropdown login">
            {/* <button
              class="btn  dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src="/assets/Icons/user.svg" alt="Login" />
            </button> */}

            {logged == 1 ? (
              <>
                <button
                  class="btn  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={appState?.state?.user?.profileImage
                    ? appState?.state?.user?.profileImage
                    : "/assets/images/user.jpg"} width={"30px"} alt="Login" />

                  &nbsp; <img src="/assets/Icons/down-arrow.svg" alt="" />

                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <h6>User</h6>
                  <li>
                    <Link to="/my-account">
                      <img
                        src="/assets/Icons/login-icon.svg"
                        className="mr-3"
                      />
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link to="/my-enquiry">
                      <img src="/assets/Icons/post-buy.svg" className="mr-3" />
                      All Inquiries
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img
                        src="/assets/Icons/search-dictory.svg"
                        className="mr-3"
                      />
                      Post Buy Requirements
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Sell on APNASHAHER.COM
                    </Link>
                  </li>
                  <li>
                    <Link to="/log-out">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Logout
                    </Link>
                  </li>
                </ul>
              </>
            ) : logged === 2 ? (
              <>
                <button
                  class="btn  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={appState?.state?.user?.profileImage
                    ? appState?.state?.user?.profileImage
                    : "/assets/images/user.jpg"} width={"30px"} alt="Login" />
                  &nbsp;   <img src="/assets/Icons/down-arrow.svg" alt="" />

                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <h6>Seller</h6>
                  <li>
                    <Link to="/vendor-dashboard">
                      <img
                        src="/assets/Icons/login-icon.svg"
                        className="mr-3"
                      />
                      Seller Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/all-query">
                      <img src="/assets/Icons/post-buy.svg" className="mr-3" />
                      All Inquiries
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img
                        src="/assets/Icons/search-dictory.svg"
                        className="mr-3"
                      />
                      Post Product / Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Customer Dashboard
                    </Link>
                  </li>
                  <li>
                    <Link to="/">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Logout
                    </Link>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <button
                  class="btn  dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={appState?.state?.user?.profileImage
                    ? appState?.state?.user?.profileImage
                    : "/assets/images/user.jpg"} width={"30px"} alt="Login" />
                  &nbsp;  <img src="/assets/Icons/down-arrow.svg" alt="" />

                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton2"
                >
                  <h6>For Buyer</h6>
                  <li>
                    <Link to="/login">
                      <img
                        src="/assets/Icons/login-icon.svg"
                        className="mr-3"
                      />
                      Login / Signup
                    </Link>
                  </li>
                  <li>
                    <Link to="/post-buy-requirement">
                      <img src="/assets/Icons/post-buy.svg" className="mr-3" />
                      Post Buy Requirements
                    </Link>
                  </li>
                  <li>
                    <Link to="/directory">
                      <img
                        src="/assets/Icons/search-dictory.svg"
                        className="mr-3"
                      />
                      Search Directory
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Buyers FAQ
                    </Link>
                  </li>

                  {/* ))} */}
                  <h6 className="mt-2">For Seller</h6>
                  <li>
                    <Link to="/login">
                      <img
                        src="/assets/Icons/login-icon.svg"
                        className="mr-3"
                      />
                      Login / Signup
                    </Link>
                  </li>
                  <li>
                    <Link to="/seller-benefits">
                      <img src="/assets/Icons/sell-apna.svg" className="mr-3" />
                      Sell on APNASHAHER
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/">
                      <img
                        src="/assets/Icons/contact-apna.svg"
                        className="mr-3"
                      />
                      Sellers Benefits
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/contact-us">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Contact APNASHAHER
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <img src="/assets/Icons/buyer-faq.svg" className="mr-3" />
                      Seller FAQ
                    </Link>
                  </li>
                </ul>
              </>
            )}
          </div>

          {/* language--------------- */}
          <select class="form-select" aria-label="Default select example">
            <option selected>Language </option>
            <option value="1">English - INR</option>
            <option value="2">English - USD</option>
          </select>
        </div>
      </div>

      {/* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
| Mobile Sidebar
^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/}
      <div className="msbar-parent">
        <div className="mobile-sidebar">
          <div className="sb-header">
            <div className="d-flex justify-content-between">
              <div className="user d-flex gap-3 align-items-center">
                {appState?.state?.user?.token ? (
                  <>
                    <img
                      src={appState?.state?.user?.profileImage}
                      alt=""
                      className="rounded-circle"
                      width="55px"
                      height="55px"
                    />
                    <div className="d-flex flex-column gap-1">
                      <h3>{appState?.state?.user?.name}</h3>
                      <Link to="/dashboard" onClick={closeMenu}>My Account</Link>
                    </div>

                  </>
                ) : (
                  <div className="d-flex flex-column gap-1">

                    <Link to="/login" onClick={closeMenu}>

                      Login / Signup
                    </Link>
                  </div>

                )}


              </div>
              <div className="close">
                <button onClick={closeMenu}>
                  <img src="/assets/Icons/close-icon.png" alt="Close" />
                </button>
              </div>
            </div>

            {/* Ship to dropdown  ----- */}
            <div class="dropdown mt-2 ship-to">
              <button
                class="btn dropdown-toggle ps-0 border-0"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ship to : <img src="/assets/images/flag-new.png" alt="" />
              </button>
              <ul
                className="dropdown-menu px-3"
                aria-labelledby="dropdownMenuButton1"
              >
                <h6 className="p-0 mt-2 mb-3">Specify your location</h6>
                <select class="form-select" aria-label="Default select example">
                  <option selected>Select Your location</option>
                  <option value="1">
                    {" "}
                    India{" "}
                    <img src="/assets/images/flag-new.png" className="ml-1" />
                  </option>
                  <option value="2">America</option>
                  <option value="3">UAE</option>
                </select>

                <div className="or-block2 my-2">
                  <span>OR</span>
                </div>
                <input
                  className=""
                  type="text"
                  placeholder="Enter City Name to Search"
                />
                <button className="save-btn w-100 py-1 my-2">Save</button>
              </ul>
            </div>
          </div>

          <div className=" my-4 sb-hero">
            <p>
              Your business is just one <span>Click</span> away from getting
              global exposure.
            </p>
            {appState?.state?.user?.token ? (

              <button className="sell-btn  px-5 w-100" onClick={() => { navigate('/vendor-dashboard'); closeMenu() }}>{seller ? "Business Dashboard" : "Sell on Apnashaher"}</button>

            ) : (
              <button className="sell-btn  px-5 w-100" onClick={() => { navigate('/seller-benefits'); closeMenu() }}>Sell on Apnashaher</button>

            )}
          </div>

          {/* Sidebar main content / Links   */}


          <div className="sb-main">

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Explore Us</Accordion.Header>
                <Accordion.Body>
                  <ul class="accordian-links">


                    <Link to="/products-page" onClick={closeMenu}>
                      <li>
                        <img
                          src="/assets/images/pro-cat-1.svg"
                          class="mr-2"
                        />
                        All Products
                      </li>
                    </Link>
                    <Link to="/flash-deals" onClick={closeMenu}>
                      <li>
                        <img
                          src="/assets/images/pro-cat-1.svg"
                          class="mr-2"
                        />
                        Flash Deals
                      </li>
                    </Link>
                    <Link to="/new-arrival" onClick={closeMenu}>
                      {" "}
                      <li>
                        <img
                          src="/assets/images/pro-cat-2.svg"
                          class="mr-2"
                        />
                        New Arrivals
                      </li>
                    </Link>
                    <Link to="/stock-clearance" onClick={closeMenu}>
                      <li>
                        <img
                          src="/assets/images/pro-cat-3.svg"
                          class="mr-2"
                        />
                        Stock Clearance
                      </li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Browse Services</Accordion.Header>
                <Accordion.Body>
                <ul class="accordian-links">
                      <Link to="/service-category" onClick={closeMenu}>
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          Show All Services
                        </li>
                      </Link>

                      {servicedata &&
                        servicedata.map((item, i) => (
                          <Link
                            to={"/service-categorys/" + item.slug}
                            onClick={closeMenu}
                          >
                            <li> <img
                              src="/assets/images/pro-cat-4.svg"
                              class="mr-2"
                            /> {item.name}</li>
                          </Link>
                        ))}
                      

                    </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Browse Directory</Accordion.Header>
                <Accordion.Body>
                <ul class="accordian-links">
                      <Link to="/startup" onClick={closeMenu}>
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          All Startup
                        </li>
                      </Link>
                      <Link to="/directory-listing/Manufacturer" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Manufactures
                        </li>
                      </Link>
                      <Link to="/directory-listing/export-import" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Export & Importers
                        </li>
                      </Link>
                      <Link to="/directory-listing/digital-services" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Suppliers
                        </li>
                      </Link>
                      <Link to="/directory-listing/Distributors" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Distributors
                        </li>
                      </Link>
                      <Link to="/directory-listing/Retailers" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Retailers
                        </li>
                      </Link>
                      <Link to="/directory-listing/Service%20Providers" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Service Providers
                        </li>
                      </Link>
                      <Link to="/directory-listing/Educational%20Institutes" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Educational Institutes
                        </li>
                      </Link>

                    </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Help Center</Accordion.Header>
                <Accordion.Body>
                <ul class="accordian-links">
                      <Link to="/" onClick={closeMenu} >
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          Help Center1
                        </li>
                      </Link>
                      <Link to="/" onClick={closeMenu} >
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Help Center2
                        </li>
                      </Link>

                    </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div class="accordion border-0 bg-white" id="accordionExample">
              {/* <div class="accordion-item border-0 bg-white">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button bg-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Explore Us
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <ul class="accordian-links">


                      <Link to="/products-page" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-1.svg"
                            class="mr-2"
                          />
                          All Products
                        </li>
                      </Link>
                      <Link to="/flash-deals" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-1.svg"
                            class="mr-2"
                          />
                          Flash Deals
                        </li>
                      </Link>
                      <Link to="/new-arrival" onClick={closeMenu}>
                        {" "}
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          New Arrivals
                        </li>
                      </Link>
                      <Link to="/stock-clearance" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-3.svg"
                            class="mr-2"
                          />
                          Stock Clearance
                        </li>
                      </Link>

                    </ul>
                  </div>
                </div>
              </div> */}

              {/* <div class="accordion-item border-0 bg-white">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button bg-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Browse Products
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <ul class="accordian-links">
                      <Link to="/categorys/products-page">
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          All Products
                        </li>
                      </Link>
                      <Link to="/categorys/flash-deals">
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Flash Deals
                        </li>
                      </Link>
                      <Link to="/categorys/new-arrival">
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          New Arrivals
                        </li>
                      </Link>
                      
                    </ul>
                  </div>
                </div>
              </div> */}


              {/* <div class="accordion-item border-0 bg-white">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button bg-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Browse Services
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <ul class="accordian-links">
                      <Link to="/service-category" onClick={closeMenu}>
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          Show All Services
                        </li>
                      </Link>

                      {servicedata &&
                        servicedata.map((item, i) => (
                          <Link
                            to={"/service-categorys/" + item.slug}
                            onClick={closeMenu}
                          >
                            <li> <img
                              src="/assets/images/pro-cat-4.svg"
                              class="mr-2"
                            /> {item.name}</li>
                          </Link>
                        ))}
                      {/* <Link to="/categorys/digital-services">
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          Digital Services
                        </li>
                      </Link> */}
                      {/* <Link to="/categorys/professional-services">
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Professional Services
                        </li>
                      </Link>
                      <Link to="/directory-listing">
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Directory Listing
                        </li>
                      </Link>

                    </ul>
                  </div>
                </div>
              </div>

              <div class="accordion-item border-0 bg-white">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button bg-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Browse Directory
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <ul class="accordian-links">
                      <Link to="/startup" onClick={closeMenu}>
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          All Startup
                        </li>
                      </Link>
                      <Link to="/directory-listing/Manufacturer" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Manufactures
                        </li>
                      </Link>
                      <Link to="/directory-listing/export-import" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Export & Importers
                        </li>
                      </Link>
                      <Link to="/directory-listing/digital-services" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Suppliers
                        </li>
                      </Link>
                      <Link to="/directory-listing/Distributors" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Distributors
                        </li>
                      </Link>
                      <Link to="/directory-listing/Retailers" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Retailers
                        </li>
                      </Link>
                      <Link to="/directory-listing/Service%20Providers" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Service Providers
                        </li>
                      </Link>
                      <Link to="/directory-listing/Educational%20Institutes" onClick={closeMenu}>
                        <li>
                          <img
                            src="/assets/images/pro-cat-2.svg"
                            class="mr-2"
                          />
                          Educational Institutes
                        </li>
                      </Link>

                    </ul>
                  </div>
                </div>
              </div>
              <div class="accordion-item border-0 bg-white">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button bg-white collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Help Center
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <ul class="accordian-links">
                      <Link to="/" onClick={closeMenu} >
                        <li>
                          {" "}
                          <img
                            src="/assets/images/pro-cat-4.svg"
                            class="mr-2"
                          />
                          Help Center1
                        </li>
                      </Link>
                      <Link to="/" onClick={closeMenu} >
                        <li>
                          <img
                            src="/assets/images/pro-cat-5.svg"
                            class="mr-2"
                          />
                          Help Center2
                        </li>
                      </Link>

                    </ul>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
