import React, { useContext, useState,useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight, faBars } from "@fortawesome/free-solid-svg-icons";
import {Offcanvas,NavDropdown} from "react-bootstrap";
import { MenuItem } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL,DOMAIN_NAME } from "../../config";
const Header = ({ websiteSetting }) => {
  const [menu,setmenu] = useState([]);
  const [header,setheader] = useState('');
  const navigate = useNavigate();
useEffect(()=>{
  fetcheader();
},[]);

const fetcheader =async()=>{
await axios.get(BASE_URL+'/seller/headermenu',{
  headers: {
    'Accept': 'application/json',
    'domainname': DOMAIN_NAME
  }
}).then(({data})=>{
  setmenu(data.data)
}).catch(({response})=>{

})
await axios.get(BASE_URL+'/seller/getheader',{
  headers: {
    'Accept': 'application/json',
    'domainname': DOMAIN_NAME
  }
}).then(({data})=>{
  setheader(data)
}).catch(({response})=>{

})
}
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
    <div className="seller-header">
      <div className="row">
        <div className="col-md-6 col-sm-6 col-xs-12">
          {/* <div className="col-md-4 "> */}
           <Link to={header && header.data && "https://wa.me/"+header.data.whatsapp_number}><button className="seller-header-topmenu" ><img src="/assets/images/whatsapp-icon.png" style={{ marginRight: 5 }} />Chat with Us</button></Link> 
          {/* </div> */}
          {/* <div className="col-md-4 "> */}
           <Link to={header && header?.data && header?.data?.business_mobilenumber && "tel:"+header?.data?.business_mobilenumber[0]}><button className="seller-header-topmenu"><img src="/assets/images/call.png" style={{ marginRight: 5 }} />Call Us Now</button></Link> 
          {/* </div> */}
          {/* <div className="col-md-4 "> */}
            <Link to={header && header?.data && header?.data?.business_email && "mailto:"+header?.data?.business_email[0]}><button><img src="/assets/images/mail.png" style={{ marginRight: 5 }} />Send Enquire On Email</button></Link>
          {/* </div> */}
        </div>
        <div className="col-md-6 col-sm-6 col-xs-12" style={{ textAlign: 'end' }}>
          <Link to={ header && header.shop.facebook}><img src="/assets/images/avon-facebook.png" className="seller-header-margin" /> </Link>
          <Link to={ header && header.shop.twitter}><img src="/assets/images/avon-twitter.png" className="seller-header-margin" /></Link>
          <Link to={ header && header.shop.linkedin}><img src="/assets/images/avon-linkedin.png" className="seller-header-margin" /></Link>
          <Link to={ header && header.shop.youtube}><img src="/assets/images/avon-youtube.png" className="seller-header-margin" /></Link>
          <Link to={ header && header.shop.instagram}><img src="/assets/images/avon-instagram.png" className="seller-header-margin" /></Link>
          <Link to={ header && header.shop.pinterest}><img src="/assets/images/avon-pinterest.png" className="seller-header-margin" /></Link>
          
          {/* <img src="assets/images/avon-linkedin.png" className="seller-header-margin" />
          <img src="assets/images/avon-youtube.png" className="seller-header-margin" />
          <img src="assets/images/avon-instagram.png" className="seller-header-margin" />
          <img src="assets/images/avon-pinterest.png" className="seller-header-margin" /> */}
        </div>
      </div>
    </div>
    <div className="seller-menu-header seller-header-desktop">
      <div className="row">
        <div className="col-md-2 col-sx-2">
          <div className="seller-logo"><img style={{height:100}} src={header && header.image_base_url+header.data.logo} /></div>
        </div>
        <div className="col-md-10 seller-header-desktop">
          <div className="" style={{display:'flex'}}>
          <Link to="/" className="seller-menu-name">Home</Link>
          <Link to="/seller/about-us" className="seller-menu-name">About Us</Link>
          <Link to="/seller/product-list" className="seller-menu-name">Product & Services</Link>
          <Link to="/seller/contact-us" className="seller-menu-name">Contact Us</Link>
          <Link to="/seller/blogs" className="seller-menu-name">Blog</Link>
          {/* <Link to="/seller/faqs" className="seller-menu-name">Faq</Link> */}
          <NavDropdown eventKey={6} className="seller-menu-name" title="Other Information" id="basic-nav-dropdown">
          {menu && menu.map((item,i)=>(
            <MenuItem eventKey={i} key={i} onClick={()=>navigate("/seller/"+item.slug)}>{item.menu_name}</MenuItem>
            ))}
    </NavDropdown>
          {/* <ul>Other Information
          {menu && menu.map((item)=>(
              <li><Link className="seller-menu-name" to={"/seller/"+item.slug}>{item.menu_name}</Link></li>
            ))}
            
          </ul> */}
            
          
          
          {/* <div className="seller-menu-name">Our Product & Services</div>
          <div className="seller-menu-name">Feedbacks</div>
          <div className="seller-menu-name">Contact Us</div>
          <div className="seller-menu-name">Send Enquiry</div>
          <div className="seller-menu-name">Download Brochure</div> */}
          </div>
        </div>
      </div>
    </div>
    <div className="seller-menu-header seller-header-mobile">
      <div style={{display:'flex', justifyContent:'space-between'}}>
        {/* <div className="col-md-2 col-sx-2"> */}
          <div className="seller-logo">LOGO</div>
        {/* </div> */}
        {/* <div className="col-md-10 "> */}
        <FontAwesomeIcon icon={faBars} height="20px" width="40px" fontSize={30} style={{marginTop:10, marginRight:20}} onClick={handleShow} />
        {/* </div> */}
      </div>
    </div>

    <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>

        </Offcanvas.Header>
        <Offcanvas.Body>
        <div  className="seller-menu-mobile">Home</div>
          <div  className="seller-menu-mobile">About Us</div>
          <div className="seller-menu-mobile">Our Product & Services</div>
          <div className="seller-menu-mobile">Feedbacks</div>
          <div className="seller-menu-mobile">Contact Us</div>
          <div className="seller-menu-mobile">Send Enquiry</div>
          <div className="seller-menu-mobile">Download Brochure</div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default Header;
