import React from 'react'

export const AboutComp3 = ({imgUrl}) => {
  return (
    <>
    <div className="about-comp3 mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3">

        <div className="d-flex justify-content-between align-items-center">
            <div className="left">

                <h3>Have done some Coo Stuff with common users</h3>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                <div className="d-flex justify-content-between">
                    <div className='shadow-sm p-3 bg-white'>
                        <img src="/assets/images/clients.png" alt="" />
                        <p className='bpara'>90k+ Clients</p>
                        <p>Believe in our service & Care</p>
                    </div>

                    <div className='shadow-sm p-3 bg-white'>
                        <img src="/assets/images/orders.png" alt="" />
                        <p className='bpara'>90k+ Orders</p>
                        <p>Believe in our service & Care</p>
                    </div>
                </div>
            </div>
            <div className="right position-relative">
                <img src={imgUrl} alt="" />
                <div className='r-content'>
                   <p> <span>12 Year</span> <br />Experience </p>
                </div>
            </div>
        </div>

    </div>
    </>
  )
}
