import { Col, Form, Row } from "react-bootstrap";
import Loader from "../components/loader/Loader.component";
import ProductGridItem from "../components/product/product-grid-item.component";
import { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faArrowDown,
    faCaretDown,
    faCog,
    faL,
    faLocation,
    faLocationPin,
} from "@fortawesome/free-solid-svg-icons";
import StateContext from "../context/StateContext";
const DirectoryGridContainer = ({
    isLoading,
    title,
    products,
    productCount,
    setSortBy,
    setlocaion,
}) => {
    const [locationname, setlocationname] = useState("");
    const [locationsearch, setlocationsearch] = useState([]);
    const [popularcity, setpopularcity] = useState([]);
    const [drop, setDrop] = useState(false);
    const { locationdata, setlocationdata, countrycity, setcountrycity } = useContext(StateContext);
    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const closeLocation = () => {
        setDrop(false);
    };

    const handleOptionClick = (option) => {
        closeLocation();
        setDrop(false)
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            closeLocation();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };


    useEffect(() => {
        getpopularcity();
       
    }, [locationdata]);
    function setSort(e) {
        setSortBy(e.target.value);
    }
    const [toggleViewMode, setToggleViewMode] = useState(false);
    const listToggle = () => {
        setToggleViewMode(!toggleViewMode);
    };
    const getlocationsearch = () => {
        axios
            .post(BASE_URL + "/getlocation", { search: locationname })
            .then(({ data }) => {
                setlocationsearch(data.data);
            })
            .catch(({ response }) => { });
    };
    // const getlocation = ()=>{
    //   axios.get("https://ipapi.co/json/").then(({data})=>{
    //     setlocationdata(data);
    //     getpopularcity(data.country_name)
    //   })
    // }
    const getpopularcity = () => {
        axios
            .post(BASE_URL + "/getpopularcity", { name: locationdata.country_name })
            .then(({ data }) => {
                setpopularcity(data.data);
            })
            .catch(({ response }) => { });
    };

    const topThreeCity = popularcity.slice(0, 1)
    const otherCities = popularcity.slice(3);
    const topTwoCity = popularcity.slice(0, 1)
    const MobileMoreCities = popularcity.slice(1);

    return (
        <div className="leftside-block">
            <div className="hide-in-mobile">
                <div className="shopsearch-grid-blk">
                    <Row>

                        <Col md={7}>
                            <div
                                // key={toggleViewMode}
                                onClick={listToggle}
                                className="gridlist-item-blk"
                            >
                                <div className="shopheader-blk" ref={dropdownRef}>
                                    <div className="search">
                                        <div
                                            onClick={(e) => { setDrop(!drop); }}
                                            className=""
                                        >
                                            <div className="select-location">
                                                <button onClick={() => setDrop(!drop)} style={{ border: '1px solid lightgray', borderRadius: 5 }}>
                                                    <img
                                                        src="../assets/images/location-icon.png"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                                    Select Location
                                                    <span>
                                                        <img src="../assets/images/search-icon.png" />
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            className="search-content"
                                            style={{ display: drop ? "block" : "none" }}
                                        >
                                            <div className="prefer-location center">
                                                <div className="prefer-location-button">
                                                    <input
                                                        placeholder="search country or city"
                                                        className="location-input"
                                                        onChange={(e) => setlocationname(e.target.value)}
                                                        onMouseEnter={getlocationsearch}
                                                    />

                                                    <span onClick={getlocationsearch}><img src="../assets/images/search-icon.png" /></span>
                                                </div>
                                            </div>
                                            <div className="prefer-location-container">
                                                {locationsearch &&
                                                    locationsearch.map((item, i) => (
                                                        <div className="pop-locations" key={i} onClick={() => {setcountrycity(item.cnname); handleOptionClick(item)}}>
                                                            <img
                                                                src="../assets/images/location-icon.png"
                                                                style={{ marginRight: "5px" }}
                                                            />{" "}
                                                            {item.cnname}
                                                        </div>
                                                    ))}
                                            </div>
                                            <div className="pop-location">
                                                Popoular Location in {locationdata.country_name}
                                            </div>
                                            <div className="prefer-location-container">
                                                {popularcity &&
                                                    popularcity.map((item, i) => (
                                                        <div className="pop-locations" key={i} onClick={() => {setcountrycity(item.name); handleOptionClick(item)}}>
                                                            <img
                                                                src="../assets/images/location-icon.png"
                                                                style={{ marginRight: "5px" }}
                                                            />{" "}
                                                            {item.name}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hide-in-mobile helllo">
                                    <div className="pop-cities">
                                        <div className="pop-city" onClick={() => setcountrycity(locationdata.country_name)}>
                                            All {locationdata.country_name}
                                        </div>
                                        {topThreeCity &&
                                            topThreeCity.map((item, i) => (
                                                <div className="pop-city" key={i} onClick={() => setcountrycity(item.name)}>
                                                    {item.name}
                                                </div>
                                            ))}
                                        <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <button className="dropdown-button">More <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                                            {isDropdownVisible && (
                                                <div className="dropdown-menu-1">
                                                    {otherCities.map((item, index) => (
                                                        <ul>
                                                            <li key={index} onClick={() => setcountrycity(item.name)}>{item.name}</li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="hide-in-desktop">
                                    <div className="pop-cities">
                                        <div className="pop-city" onClick={() => setcountrycity(locationdata.country_name)}>
                                            All {locationdata.country_name}
                                        </div>
                                        {topTwoCity &&
                                            topTwoCity.map((item, i) => (
                                                <div className="pop-city" key={i} onClick={() => setcountrycity(item.name)}>
                                                    {item.name}

                                                </div>
                                            ))}
                                        <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <button className="dropdown-button">More <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                                            {isDropdownVisible && (
                                                <div className="dropdown-menu">
                                                    {MobileMoreCities.map((item, index) => (
                                                        <ul>
                                                            <li>{item.name}</li>
                                                        </ul>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="hide-in-mobile hello">
                                <div className="sortby-block">
                                    <Form.Select
                                        aria-label="Default select example"
                                        onChange={(e) => setSort(e)}
                                    >
                                        <option >Sort By</option>
                                        <option value="1">Newest First</option>
                                        <option value="2">Premium Listing</option>
                                        <option value="3">Top Rated</option>
                                        <option value="4">A - Z</option>
                                        <option value="5">Z - A</option>
                                    </Form.Select>
                                    <a href="" className="gridlist-btn active" style={{ marginLeft: 10 }}>
                                        <i className="bi bi-grid"></i>
                                    </a>
                                    <a href="" className="gridlist-btn">
                                        <i className="bi bi-list-task"></i>
                                    </a>
                                    <span>{productCount}</span>
                                    {toggleViewMode}
                                </div>
                            </div>
                            <div className="hide-in-desktop">
                                <div className="sortby-block">
                                    <div>
                                        <Form.Select
                                            aria-label="Default select example"
                                            onChange={(e) => setSort(e)}
                                        >
                                            <option >Sort By</option>
                                            <option value="1">Newest First</option>
                                        <option value="2">Premium Listing</option>
                                        <option value="3">Top Rated</option>
                                        <option value="4">A - Z</option>
                                        <option value="5">Z - A</option>
                                        </Form.Select>
                                    </div>
                                    <div>
                                        <a href="" className="gridlist-btn active" style={{ marginLeft: 10 }}>
                                            <i className="bi bi-grid"></i>
                                        </a>
                                        <a href="" className="gridlist-btn">
                                            <i className="bi bi-list-task"></i>
                                        </a>
                                        <span>{productCount}</span>
                                        {toggleViewMode}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="section-media-900" style={{ background: "#fff", padding: "15px", alignItems: "baseline", marginBottom: "20px" }}>
                <div className="d-flex justify-content-between" style={{ alignItems: "center" }}>
                    <div className="search">
                        <div
                            onClick={(e) => {
                                setDrop(!drop);
                            }}
                            className=""
                        >
                            <div className="select-location">
                                <button onClick={() => setDrop(!drop)} style={{ border: '1px solid lightgray', borderRadius: 5 }}>
                                    <img
                                        src="../assets/images/location-icon.png"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Select Location
                                    <span>
                                        <img src="../assets/images/search-icon.png" />
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div
                            className="search-content"
                            style={{ display: drop ? "block" : "none" }}
                        >
                            {/* <div className="prefer-location center">
                                <div className="prefer-location-button">
                                    <searchInput />
                                    <span
                                    // onClick={getlocationsearch}
                                    ><img src="../assets/images/search-icon.png" /></span>
                                </div>
                            </div> */}
                            <div className="prefer-location-container">
                                {locationsearch &&
                                    locationsearch.map((item, i) => (
                                        <div className="pop-locations" key={i} onClick={() => {setcountrycity(item.name); handleOptionClick(item)}}>
                                            <img
                                                src="../assets/images/location-icon.png"
                                                style={{ marginRight: "5px" }}
                                            />{" "}
                                            {item.cnname}
                                        </div>
                                    ))}
                            </div>
                            <div className="pop-location">
                                Popoular Location in {locationdata.country_name}
                            </div>
                            <div className="prefer-location-container" >
                                {popularcity &&
                                    popularcity.map((item, i) => (
                                        <div className="pop-locations" key={i} onClick={() => {setcountrycity(item.cnname); handleOptionClick(item)}}>
                                            <img
                                                src="../assets/images/location-icon.png"
                                                style={{ marginRight: "5px" }}
                                            />{" "}
                                            {item.name}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                    <div className="pop-cities">
                        <div className="pop-city" onClick={() => setcountrycity(locationdata.country_name)}>
                            All {locationdata.country_name}
                        </div>
                        {topThreeCity &&
                            topThreeCity.map((item, i) => (
                                <div className="pop-city" key={i} onClick={() => setcountrycity(item.name)}>
                                    {item.name}

                                </div>
                            ))}
                        <div className="dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <button className="dropdown-button">More <i class="fa fa-caret-down" aria-hidden="true"></i></button>
                            {isDropdownVisible && (
                                <div className="dropdown-menu">
                                    {otherCities.map((item, index) => (
                                        <ul>
                                            <li>{item.name}</li>
                                        </ul>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between" style={{ alignItems: "center", marginTop: "20px" }}>
                    <div className="sortby-block">
                        <Form.Select
                            aria-label="Default select example"
                            onChange={(e) => setSort(e)}
                        >
                            <option >Sort By</option>
                            <option value="1">Newest First</option>
                                        <option value="2">Premium Listing</option>
                                        <option value="3">Top Rated</option>
                                        <option value="4">A - Z</option>
                                        <option value="5">Z - A</option>
                        </Form.Select>
                    </div>
                    <div>
                        <a href="" className="gridlist-btn active" style={{ marginLeft: 10 }}>
                            <i className="bi bi-grid"></i>
                        </a>
                        <a href="" className="gridlist-btn">
                            <i className="bi bi-list-task"></i>
                        </a>
                        <span>{productCount}</span>
                        {toggleViewMode}
                    </div>
                </div>
            </div>

            <div className="shop-product-blk">
                <Row>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        products.map((product) => (
                            <ProductGridItem
                                image="assets/images/pro-1.png"
                                product={product}
                                key={product.id}
                            />
                        ))
                    )}
                </Row>
            </div>
        </div>
    );
};

export default DirectoryGridContainer;

const searchInput = () => {
    return (
        <>
            <input
                placeholder="search country or city"
                className="location-input"
            // onChange={(e) => setlocationname(e.target.value)}
            />
        </>
    )
}

